import React, { useState } from "react";
import { useDispatch } from "react-redux";
// Icons  
import EyeIcon from "../../assets/icons/eye.svg";
import EyeOffIcon from "../../assets/icons/eye-off.svg";
// Actions
import { updateUserPassword } from "../../store/settings/actions";
import { createNotification } from "../../store/app/actions";
import ResetPassword from "./ResetPassword";


const UpdatePassword = () => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showResetPasswordScreen, setShowResetPasswordScreen] = useState(false);

  const onError = async (error) => {
    setSubmitting(false);
    dispatch(createNotification("error-toast", error));
  };

  const onSuccess = async () => {
    setSubmitting(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const values = {
      old_password: e.target.old_password.value.trim(),
      new_password: e.target.new_password.value.trim()
    }
    dispatch(updateUserPassword(values, onSuccess, onError));
  };

  return (
    <div className="flex column alignStart justifyStart w-100">
      {/* Update password form */}
      {showResetPasswordScreen ?
        <ResetPassword /> :
        <div className="settings-main update_password">
          <p className="main-title">Update password</p>
          <form onSubmit={(e) => onSubmit(e)} className="flex column alignCenter justifyCenter w-100" style={{ marginTop: 44 }}  >
            {/* Old password */}
            <div className="flex column alignStart justifyStart settings-form-item border-bottom">
              <label htmlFor="old_password" className="settings-label">Old Password</label>
              <input
                name="old_password"
                id="old_password"
                type={showPassword ? "text" : "password"}
                required
                placeholder="Old Password"
                className="settings-value"
                onKeyUp={(e) => {
                  if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
                    document.getElementById('old_password').value = e.target.value.trim();
                    dispatch(createNotification("error-toast", "Spaces are not allowed in password field"));
                  }
                }}
              />
              <img className="absolute" src={showPassword ? EyeIcon : EyeOffIcon}
                alt={showPassword ? "eye on icon" : "eye off icon"} height={18} width={18}
                onClick={() => setShowPassword(!showPassword)}
                style={{ right: 0, bottom: 12 }}
              />
            </div>

            {/* New password */}
            <div className="flex column alignStart justifyStart settings-form-item border-bottom">
              <label htmlFor="new_password" className="settings-label">New Password</label>
              <input
                name="new_password"
                id="new_password"
                type={showPassword ? "text" : "password"}
                required
                placeholder="New Password"
                className="settings-value"
                minLength={8}
                maxLength={56}
                onKeyUp={(e) => {
                  if (e.key === " " || e.code === "Space" || e.keyCode === 32) {
                    document.getElementById('new_password').value = e.target.value.trim();
                    dispatch(createNotification("error-toast", "Spaces are not allowed in password field"));
                  }
                }}
              />
            </div>

            {/* Update password */}
            <div className="flex justifyBetween alignCenter w-100" style={{ marginTop: 32 }}>
              <p className="pointer" style={{ borderBottom: "1px solid #ffffff" }} onClick={() => setShowResetPasswordScreen(!showResetPasswordScreen)}> Forgot Password? </p>
              <button htmltype="submit" className="white-button" style={{ maxWidth: 320 }}>
                {submitting ?
                  <div className="flex justifyCenter alignCenter">
                    <div className="custom_button_loader" />
                  </div>
                  : <p className="button_label">UPDATE</p>
                }
              </button>
            </div>
          </form>
        </div>}
    </div>
  );
};
export default UpdatePassword;
