import { call, put, takeEvery } from "redux-saga/effects";
import { getErrorMessage } from "../../_helpers/Requests";
import api from "../../services/api/ApiService";
import { createNotification } from "../app/actions";
import {
  DELETE_INVITED_ATHLETE,
  DELETE_INVITED_COACH,
  DELETE_MEMBER,
  FETCH_MANAGEMENT_TEAM,
  setManagementTeam,
  setManagementTeams
} from "./actions";

// Delete invited athlete
function deleteInvitedAthlete(team_id, email_address) {
  const resp = api.delete(`coach/team/${team_id}/invite/athletes`, { data: { email: email_address } });
  return resp;
}

function* deleteInviteForAthlete({ payload: { team_id, email_address, onSuccess, onError } }) {
  try {
    const response = yield call(deleteInvitedAthlete, team_id, email_address);
    const { data: { success, details } } = response;
    if (success) {
      yield put(createNotification("success-toast", "Athlete has been deleted."));
      yield call(onSuccess);
    } else if (details) {
      yield call(onError, details);
    }
  } catch (err) {
    if (err.response) {
      const { data: { details } } = err.response;
      yield call(onError, details);
    }
  }
}


// Delete invited coaches
function deleteInvitedCoach(team_id, email_address) {
  const resp = api.delete(`coach/team/${team_id}/invite/coaches`, { data: { email: email_address } });
  return resp;
}

function* deleteInviteForCoach({ payload: { team_id, email_address, onSuccess, onError } }) {
  try {
    const response = yield call(deleteInvitedCoach, team_id, email_address);
    const { data: { success, details } } = response;
    if (success) {
      yield put(createNotification("success-toast", "Coach has been deleted."));
      yield call(onSuccess);
    } else if (details) {
      yield call(onError, details);
    }
  } catch (err) {
    if (err.response) {
      const { data: { details } } = err.response;
      yield call(onError, details);
    }
  }
}

async function getTeam(team_id) {
  try {
    return await api.get(`/coach/team/management/${team_id}`);
  } catch ({ response }) {
    return response;
  }
}

async function allTeams() {
  try {
    return await api.get(`/coach/team/management`);
  } catch ({ response }) {
    return response;
  }
}

function* fetchManagementTeam({ payload: { team_id, onSuccess, onError } }) {
  try {
    const teamsResponse = yield call(allTeams);
    if (teamsResponse['status'] === 200) {
      const sortedTeams = teamsResponse['data'] && teamsResponse['data'].sort((a, b) => (a.pk > b.pk ? 1 : -1));
      if (sortedTeams && sortedTeams.length) {
        yield put(setManagementTeams(sortedTeams));
        const teamResponse = yield call(getTeam, team_id);
        if (teamResponse['status'] === 200) {
          if (teamResponse['data']) {
            yield put(setManagementTeam(teamResponse['data']));
            yield call(onSuccess);
          } else {
            yield call(onError, "Apologies, but we couldn't find any details for the team you are referring to.");
          }
        } else {
          yield call(onSuccess);
          yield call(onError, getErrorMessage(teamResponse));
        }
      } else {
        yield call(onError, "Apologies, but we couldn't locate a team associated with your account.");
      }
    } else {
      yield call(onSuccess);
      yield call(onError, getErrorMessage(teamsResponse));
    }
  } catch (e) {
    yield call(onError, getErrorMessage(e.response));
  }
}

function deleteThisMember(team_id, member_type, id) {
  const resp = api.post(`/coach/team/management/${team_id}/remove/${member_type}/${id}`);
  return resp;
}

function* deleteMember({ payload: { id, member_type, onSuccess, onError } }) {
  try {
    const team_id = window.localStorage.getItem("team_Id");
    yield call(deleteThisMember, team_id, member_type, id);
    yield put(createNotification("success-toast", "Member has been deleted."));
    yield call(onSuccess);
  } catch (err) {
    if (err.response) {
      const { data: { details } } = err.response;
      yield call(onError, details);
    }
  }
}

function* managementSaga() {
  yield takeEvery(FETCH_MANAGEMENT_TEAM, fetchManagementTeam);
  yield takeEvery(DELETE_INVITED_ATHLETE, deleteInviteForAthlete);
  yield takeEvery(DELETE_INVITED_COACH, deleteInviteForCoach);
  yield takeEvery(DELETE_MEMBER, deleteMember);
}

export default managementSaga;
