import React, { useEffect, useRef, useState } from "react";
// Provider
import { split } from "lodash";
import { useDispatch, useSelector } from "react-redux";
// Icons
import BackwardIcon from "../../assets/icons/backward-black.svg";
import CrossTab from "../../assets/icons/closs-tab.svg";
import DeleteIconLight from "../../assets/icons/delete-bin-light.svg";
import DeleteIcon from "../../assets/icons/delete-bin.svg";
import ArrowDownIcon from "../../assets/icons/down.svg";
import EditIconLight from "../../assets/icons/edit-pen-light.svg";
import EditIcon from "../../assets/icons/edit-pen.svg";
import InfoIcon from "../../assets/icons/information.svg";
import WhiteClose from "../../assets/icons/light-cross.svg";
import PlayButtonIcon from "../../assets/icons/play-button.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import ShadedArrow from "../../assets/icons/shaded-arrow.svg";
import ThreeDotsIcon from "../../assets/icons/three-dots-horizontal.svg";
import ArrowUpIcon from "../../assets/icons/up.svg";
// Components
import ReactPlayer from "react-player";
import PlanTags from "../../components/inputs/PlanTags";
// Actions
import { getShortDescription, secondsToHm } from "../../_helpers/chunks";
import { history } from "../../index";
import api from "../../services/api/ApiService";
import { createNotification } from "../../store/app/actions";
import { createPlanSession, deletePlan, deletePlanSession, fetchSessionSportTypes, fetchSportTypes, fetchTrainingIntensities, fetchTrainingLevels, updatePlanSession, updatePlanSessions, updatePlanTags, updatePlanWithPOST } from "../../store/trainings/actions";

const PlanDetail = () => {
    var { sport_types, training_levels, session_sport_types, training_intensities } = useSelector((state) => state.training_plans);
    const dispatch = useDispatch();
    const { location: { search } } = history;
    const planId = search.replace("?id=", "");
    let currency_list = ["USD", "EUR", "JPY", "GBP"];
    // States
    const [plan_detail, setPlanDetail] = useState(null);
    const [training_plan_sessions, setTrainingPlanSessions] = useState([]);
    const [infos, setInfos] = useState({});
    const [loading, setLoading] = useState({
        plan_loading: false,
        sessions_loading: false
    });
    let [view_popup, showViewPopup] = useState({
        session_popup: false,
        exercise_popup: false,
        exercise_index: 0
    })
    let [update_popup, showUpdatePopup] = useState({
        plan_popup: false,
        session_popup: false,
        new_session_for_week_no: 0,
        new_session_popup: false,
        new_week_popup: false,
        exercise_popup: false,
        exercise_index: 0,
        new_exercise_popup: false,
        new_exercise_index: 0,
    });
    let [updateForm, onUpdateForm] = useState({
        plan_loading: false,
        session_loding: false,
        exercise_loading: false
    });
    let [delete_popup, showDeletePopup] = useState({
        delete_plan_popup: false,
        delete_session_popup: false,
        delete_session_id: 0,
        delete_exercise_popup: false,
        delete_exercise_index: 0,
        plan_loading: false,
        session_loading: false,
        exercise_loading: false
    });
    let [training_plan_data, set_training_plan_data] = useState({
        name: "",
        short_title: "",
        sport_type: null,
        level: null,
        plan_length_weeks: 4,
        coach_bio: "",
        summary: "",
        description: "",
        image: null,
        video: "",
        equipment_required: "",
        tags: null,
        price: 0.00,
        currency: "",
    });
    let [submit_plan, submitPlanPopup] = useState({
        review_popup: false,
        loading_state: false
    });
    let [open_week_no, setWeekNo] = useState(0);
    var [edited_session_details, setEditedSessionDetails] = useState(null);
    let [image_for_preview, set_image_for_preview] = useState("");
    let [show_preview, set_preview] = useState(true);
    let [video_error, setVideoError] = useState(false);
    let [updatedImage, setUpdatedImage] = useState(null);
    let [weekly_sessions_list, setWeeklySessionsList] = useState([]);
    const [can_edit, set_can_edit] = useState(false);
    let [tags_collection, setTagsCollector] = useState([]);
    const [rows_length, set_rows_length] = useState({
        summary: 0,
        description: 0,
        coach_bio: 0
    });
    const [charc_count, set_charc_count] = useState({
        summary: 0,
        description: 0,
        coach_bio: 0
    });
    // Actions
    function resetPops() {
        setEditedSessionDetails(null);
        showUpdatePopup({
            plan_popup: false,
            session_popup: false,
            new_session_for_week_no: 0,
            new_session_popup: false,
            new_week_popup: false,
            exercise_popup: false,
            exercise_index: 0,
            new_exercise_popup: false,
            new_exercise_index: 0,
        });
        onUpdateForm({
            plan_loading: false,
            session_loding: false,
            exercise_loading: false
        });
        showDeletePopup(({
            delete_plan_popup: false,
            delete_session_popup: false,
            delete_session_id: 0,
            delete_exercise_popup: false,
            delete_exercise_index: 0,
            session_loading: false,
            exercise_loading: false
        }));
        submitPlanPopup({
            review_popup: false,
            loading_state: false
        });
        showViewPopup({
            session_popup: false,
            exercise_popup: false,
            exercise_index: 0,
        });
    }
    function checkForCategoryExistance(list, category_name) {
        let filtered_list = list.filter(function (item) { return item['category'] === category_name });
        if (filtered_list.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    function convertSecondsToHm(d) {
        d = Number(d);
        var hr = Math.floor(d / 3600);
        var min = Math.floor(d % 3600 / 60);
        return { hours: hr, minutes: min }
    }
    function calculate_no_of_seconds(hr, mm) {
        let seconds = 0;
        seconds = hr * 60 * 60 + mm * 60;
        return seconds;
    }
    function checkCharcCount(field, str) {
        set_charc_count({ ...charc_count, [field]: str });
        if (str >= 0 && str < 150) {
            set_rows_length({ ...rows_length, [field]: 3 });
        } else if (str >= 150 && str < 300) {
            set_rows_length({ ...rows_length, [field]: 6 });
        } else if (str >= 300 && str < 450) {
            set_rows_length({ ...rows_length, [field]: 9 });
        } else if (str >= 450 && str < 600) {
            set_rows_length({ ...rows_length, [field]: 12 });
        } else if (str >= 600) {
            set_rows_length({ ...rows_length, [field]: 17 });
        }
    }
    function getRowLength(str) {
        if (str.length >= 0 && str.length < 150) {
            return 3;
        } else if (str.length >= 150 && str.length < 300) {
            return 6;
        } else if (str.length >= 300 && str.length < 450) {
            return 9;
        } else if (str.length >= 450 && str.length < 600) {
            return 12;
        } else if (str.length >= 600) {
            return 17;
        }
    }
    function filterName(list, id) {
        if (list && list.length > 0) {
            let res = list.filter((item) => item['pk'] === id && item)[0];
            if (res) {
                return res['display_name'];
            }
            return "";
        }
    };
    function generateRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    // Get charcters count
    const getCharcCount = (str) => str.length;
    // Check two arrays of objects are equal or not
    const arraysEqual = (a1, a2) => JSON.stringify(a1) === JSON.stringify(a2);
    // Tags operations
    const setBackendTags = (value) => {
        let collector = [];
        let tags_collector = [];
        if (value !== null && value !== "") {
            const tags = split(value, ",");
            if (tags.length > 0) {
                for (var i = 0; i < tags.length; i++) {
                    const splittedTag = split(tags[i], " ");
                    for (var j = 0; j < splittedTag.length; j++) {
                        collector.push(splittedTag[j])
                    }
                }
            }
            if (collector.length > 0) {
                for (let j = 0; j < collector.length; j++) {
                    if (collector[j] !== "") {
                        tags_collector.push({
                            tag: collector[j].trim(),
                            status: 'existing',
                            color: generateRandomColor()
                        });

                    }
                }
            }
        }
        setTagsCollector(tags_collector);
        return tags_collector;
    };
    const setPastedTags = (value) => {
        if (value !== null && value !== "") {
            const tags = split(value, " ");
            if (tags.length > 0) {
                for (let k = 0; k < tags.length; k++) {
                    if (tags[k] !== "") {
                        tags_collection.push({
                            tag: tags[k].trim(),
                            status: 'new',
                            color: generateRandomColor()
                        });
                    }
                }
                setTagsCollector([...tags_collection]);
                document.getElementById("tagInputField")["value"] = "";
            }
        }
    };
    const onInsertTag = (event) => {
        if (event.target.value !== "") {
            tags_collection.push({
                tag: event.target.value.trim(),
                status: 'new',
                color: generateRandomColor()
            });
            setTagsCollector([...tags_collection]);
            event.target.value = "";
        }
    };
    const onRemoveTag = (indexToRemove) => {
        if (indexToRemove > -1) {
            setTagsCollector([...tags_collection.filter((_, index) => index !== indexToRemove)]);
        }
    };
    // On update plan image
    const onImageUpdate = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            var totalBytes = files[0].size;
            if (totalBytes <= 2000000) {
                if (files[0].type === "image/jpeg" || files[0].type === "image/png") {
                    set_image_for_preview(reader.result);
                    setUpdatedImage(files[0]);
                }
            } else {
                dispatch(
                    createNotification(
                        "error-toast",
                        "Please upload image file of size 2MB or below"
                    )
                );
            }
        };
        reader.readAsDataURL(files[0]);
    };
    // Form Actions
    // Save plan as draft
    const onSavePlan = async (e) => {
        e.preventDefault();
        var formData = new FormData();
        var tags_updated = false;
        var form_updated = false;
        const { name, short_title, sport_type, level, plan_length_weeks, coach_bio, summary, description, image, video, price, currency, equipment_required } = e.target.elements;
        var submit_value = {};
        // name check
        if ((plan_detail['name'] !== name['value']) && (name['value'] !== "")) {
            formData.append('name', name['value']);
            form_updated = true;
        } else if (name['value'] === "") {
            dispatch(createNotification("error-toast", "Please enter a name for your training plan."));
            return;
        }

        // short title check
        if ((plan_detail['short_title'] !== short_title['value']) && (short_title['value'] !== "")) {
            formData.append('short_title', short_title['value']);
            form_updated = true;
        } else if (short_title['value'] === "") {
            dispatch(createNotification("error-toast", "Please enter a short title for your training plan."));
            return;
        }

        // summary check
        if ((plan_detail['summary'] !== summary['value']) && (summary['value'] !== "")) {
            formData.append('summary', summary['value']);
            form_updated = true;
        } else if (summary['value'] === "") {
            dispatch(createNotification("error-toast", "Please enter a description of your training plan. This could include how the plan progresses and/or what type of training it includes."));
            return;
        }

        // sport type check
        if (plan_detail['sport_type'] !== Number(sport_type['value'])) {
            formData.append('sport_type', sport_type['value']);
            form_updated = true;
        }

        // level check
        if (plan_detail['level'] !== Number(level['value'])) {
            formData.append('level', level['value']);
            form_updated = true;
        }

        // plan length check
        if ((plan_detail['plan_length'] !== Number(plan_length_weeks['value'])) && (Number(plan_length_weeks['value']) !== 0)) {
            formData.append('plan_length', plan_length_weeks['value']);
            form_updated = true;
        } else if (Number(plan_length_weeks['value']) === 0) {
            dispatch(createNotification("error-toast", "Please enter a duration (in weeks) your plan is intended to run for."));
            return;
        }

        // image check
        if ((plan_detail['plan_image'] !== image['value']) && (image['value'] !== "")) {
            formData.append('plan_image', updatedImage);
            form_updated = true;
        }

        // video check
        if ((plan_detail['promo_video'] !== video['value']) && (video['value'] !== "")) {
            formData.append('promo_video', video['value']);
            form_updated = true;
        } else if (video['value'] === "") {
            dispatch(createNotification("error-toast", "Please enter a URL of a video for this movement. "));
            return;
        }

        if ((plan_detail['equipment_required'] !== equipment_required['value']) && (equipment_required['value'] !== "")) {
            formData.append('equipment_required', equipment_required['value']);
            form_updated = true;
        }

        // description check
        if ((plan_detail['description'] !== description['value']) && (description['value'] !== "")) {
            formData.append('description', description['value']);
            form_updated = true;
        } else if (description['value'] === "") {
            dispatch(createNotification("error-toast", "Please enter a description of your training plan. This could include how the plan progresses and/or what type of training it includes."));
            return;
        }

        // tags check
        if (tags_collection && tags_collection.length > 0) {
            let tags_list = [];
            for (let i in tags_collection) {
                tags_list.push(tags_collection[i].tag);
            }
            const tags = split(plan_detail['tags'], ",");
            if (!arraysEqual(tags_list, tags)) {
                submit_value['tags'] = tags_list;
                tags_updated = true;
            }
        } else {
            dispatch(createNotification("error-toast", "Please add at least one tag that best describe your plan. To add a tag, enter the words separated by a space."));
            return;
        }

        // check coach_bio
        if ((plan_detail['coach_bio'] !== coach_bio['value']) && (coach_bio['value'] !== "")) {
            formData.append('coach_bio', coach_bio['value']);
            form_updated = true;
        } else if (coach_bio['value'] === "") {
            dispatch(createNotification("error-toast", "Please add a description of yourself, your qualifications and experience. "));
            return;
        }

        // price check
        if (plan_detail['price'] !== Number(price['value'])) {
            formData.append('price', price['value']);
            form_updated = true;
        }

        // currency check
        if (plan_detail['currency'] !== currency['value']) {
            formData.append('currency', currency['value']);
            form_updated = true;
        }

        onUpdateForm({ ...updateForm, plan_loading: true });

        const onSuccessUpload = () => dispatch(updatePlanWithPOST(planId, formData, onSuccess, onError));
        if (tags_updated && form_updated) {
            dispatch(updatePlanTags(planId, submit_value, onSuccessUpload, onError));
        } else if (form_updated && !tags_updated) {
            dispatch(updatePlanWithPOST(planId, formData, onSuccess, onError));
        } else if (!form_updated && tags_updated) {
            dispatch(updatePlanTags(planId, submit_value, onSuccess, onError));
        } else if (!form_updated && !tags_updated) {
            dispatch(updatePlanWithPOST(planId, formData, onSuccess, onError));
        }
    };
    // On delete plan
    const onDeletePlan = async () => {
        showDeletePopup({ ...delete_popup, plan_loading: true });
        dispatch(deletePlan(planId, onSuccessDeletePlan, onError));
    }
    // Save new week
    const onSaveWeek = async (e) => {
        e.preventDefault();
        const storageValues = JSON.parse(localStorage.getItem(`plan_${planId}_week_details`));
        const { week_no, block, week_focus } = e.target.elements;
        var submit_value = {};
        submit_value.week_no = Number(week_no['value']);
        submit_value.block = block['value'];
        submit_value.week_focus = week_focus['value'];
        submit_value.planId = planId;
        if (Number(week_no['value']) === 1) {
            pushInWeekList(submit_value, 0, 0);
        } else {
            if (training_plan_sessions.length > 0) {
                let arr = training_plan_sessions;
                arr.push(submit_value);
                prepareWeeklySessionsList(arr);
            } else {
                if (storageValues) {
                    let newArr = storageValues;
                    newArr.push(submit_value);
                    prepareWeeklySessionsList(newArr);
                }
            }
        }

        if (storageValues) {
            let newArr = storageValues;
            newArr.push(submit_value);
            localStorage.setItem(`plan_${planId}_week_details`, JSON.stringify(newArr));
            window.localStorage.setItem("open_week_tab", Number(week_no['value']));
            setWeekNo(Number(week_no['value']));
            showUpdatePopup({ ...updateForm, new_week_popup: false });
        } else {
            localStorage.setItem(`plan_${planId}_week_details`, JSON.stringify([submit_value]));
            window.localStorage.setItem("open_week_tab", Number(week_no['value']));
            setWeekNo(Number(week_no['value']));
            showUpdatePopup({ ...updateForm, new_week_popup: false });
        }
    }
    // Remove week details from local storage after creating first session for that week
    const RemoveWeekDetailsOnceSessionCreated = (weekNo) => {
        const storageValues = JSON.parse(localStorage.getItem(`plan_${planId}_week_details`));
        if (storageValues) {
            if (storageValues.length > 1) {
                let newArr = storageValues;
                newArr = newArr.filter(item => item.week_no !== weekNo);
                localStorage.setItem(`plan_${planId}_week_details`, JSON.stringify(newArr));
            } else if (storageValues.length === 1) {
                localStorage.removeItem(`plan_${planId}_week_details`);
            }
        }
    }
    // Save new session
    const onSaveNewSession = async (e) => {
        e.preventDefault();
        const { name, notes, sport_type, intensity, hours, minutes } = e.target.elements;
        var submit_value = {};
        // name check
        if (name['value'] !== "") {
            submit_value['name'] = name['value'];
        } else {
            dispatch(createNotification("error-toast", "Please enter name for your training session."));
            return;
        }

        // Week no
        submit_value['week_no'] = update_popup['new_session_for_week_no'];
        if (training_plan_sessions && training_plan_sessions.length > 0) {
            const week_details = training_plan_sessions.filter(item => item.week_no === update_popup['new_session_for_week_no'])[0];
            // Block
            submit_value['block'] = week_details['block'];
            // Week focus check
            submit_value['week_focus'] = week_details['week_focus'];
        } else {
            const storageValues = JSON.parse(localStorage.getItem(`plan_${planId}_week_details`));
            if (storageValues) {
                const week_details = storageValues.filter(item => item.week_no === update_popup['new_session_for_week_no'])[0];
                // Block
                submit_value['block'] = week_details['block'];
                // Week focus check
                submit_value['week_focus'] = week_details['week_focus'];
            }
        }
        // exercises
        submit_value['description_dict'] = [];

        // notes check
        if (notes['value'] !== "") {
            submit_value['notes'] = notes['value'];
        }

        // sport type check
        if (Number(sport_type['value']) !== 0) {
            submit_value['sport_type'] = Number(sport_type['value']);
        }

        // intensity check
        if (Number(intensity['value']) !== 0) {
            submit_value['intensity'] = Number(intensity['value']);
        } else {
            dispatch(createNotification("error-toast", "Please select intensity level for your training session."));
            return;
        }

        // duration
        if (Number(hours['value']) === 0 && Number(minutes['value']) === 0) {
            dispatch(createNotification("error-toast", "Please select a duration for your training session (above 0 minutes)!"));
            return;
        } else {
            submit_value['duration'] = calculate_no_of_seconds(Number(hours['value']), Number(minutes['value']));
        }

        onUpdateForm({ ...updateForm, session_loding: true });

        if (Object.keys(submit_value).length > 0) {
            dispatch(createPlanSession(planId, submit_value, onSuccessSessions, onError));
            RemoveWeekDetailsOnceSessionCreated(submit_value['week_no']);
        } else {
            resetPops();
        }
    }
    // Update session changes
    const onSaveSession = async (e) => {
        e.preventDefault();
        const { name, notes, sport_type, intensity, hours, minutes } = e.target.elements;
        var submit_value = {};
        // name check
        if ((edited_session_details['name'] !== name['value']) && (name['value'] !== "")) {
            submit_value['name'] = name['value'];
        } else if (name['value'] === "") {
            dispatch(createNotification("error-toast", "Please enter name for your training session."));
            return;
        }

        // notes check
        if ((edited_session_details['notes'] !== notes['value']) && (notes['value'] !== "")) {
            submit_value['notes'] = notes['value'];
        }

        // sport type check
        if (edited_session_details['sport_type'] !== Number(sport_type['value'])) {
            submit_value['sport_type'] = Number(sport_type['value']);
        }

        // intensity check
        if ((edited_session_details['intensity'] !== Number(intensity['value']))) {
            submit_value['intensity'] = Number(intensity['value']);
        }

        // duration
        if (Number(hours['value']) === 0 && Number(minutes['value'] === 0)) {
            dispatch(createNotification("error-toast", "Please select a duration for your training session (above 0 minutes)!"));
            return;
        } else if (edited_session_details['duration'] !== calculate_no_of_seconds(Number(hours['value']), Number(minutes['value']))) {
            submit_value['duration'] = calculate_no_of_seconds(Number(hours['value']), Number(minutes['value']));
        }

        onUpdateForm({ ...updateForm, session_loding: true });
        if (Object.keys(submit_value).length > 0) {
            dispatch(updatePlanSession(planId, edited_session_details['pk'], submit_value, onSuccessSessions, onError));
        } else {
            resetPops();
        }
    }
    // Delete session
    const onDeleteSession = () => {
        const { delete_session_id } = delete_popup;
        showDeletePopup({ ...delete_popup, session_loading: true });
        dispatch(deletePlanSession(planId, delete_session_id, onSuccessSessions, onError));
    }
    // Save exercise changes
    const onSaveChangesExercise = async (e) => {
        e.preventDefault();
        const { category, video, focus, movement, sets, reps, tempo, load, rest_period, key, exercise_importance, description } = e.target.elements;
        var submit_value = { description_dict: null };
        const updated_exercise = {
            category: category['value'],
            video: video['value'],
            focus: focus['value'],
            movement: movement['value'],
            sets: sets['value'],
            reps: reps['value'],
            tempo: tempo['value'],
            load: load['value'],
            rest_period: rest_period['value'],
            key: key['value'],
            exercise_importance: exercise_importance['value'],
            description: description['value'],
        };
        edited_session_details['exercise_details'][update_popup['exercise_index']] = updated_exercise;
        submit_value['description_dict'] = edited_session_details['exercise_details'];

        onUpdateForm({ ...updateForm, exercise_loading: true });

        if (Object.keys(submit_value).length > 0) {
            dispatch(updatePlanSession(planId, edited_session_details['session_details']['pk'], submit_value, onSuccessSessions, onError));
        } else {
            resetPops();
        }
    }
    // Add new exercise to session
    const onAddExercise = async (e) => {
        e.preventDefault();
        const { category, video, focus, movement, sets, reps, tempo, load, rest_period, exercise_importance, description } = e.target.elements;
        var submit_value = { description_dict: null };
        if (edited_session_details['exercise_details'] === null) {
            edited_session_details['exercise_details'] = [];
        }
        if (Object.keys(submit_value).length > 0) {
            if (checkForCategoryExistance(edited_session_details['exercise_details'], category['value'])) {
                dispatch(createNotification("error-toast", `Category ${category['value']} already exists in your current training session.`));
                return;
            } else {
                onUpdateForm({ ...updateForm, exercise_loading: true });
                const updated_exercise = {
                    category: `${category['value']}`,
                    video: video['value'],
                    focus: focus['value'],
                    movement: movement['value'],
                    sets: sets['value'],
                    reps: reps['value'],
                    tempo: tempo['value'],
                    load: load['value'],
                    rest_period: rest_period['value'],
                    exercise_importance: exercise_importance['value'],
                    description: description['value'],
                };
                edited_session_details['exercise_details'][update_popup['new_exercise_index']] = updated_exercise;
                submit_value['description_dict'] = edited_session_details['exercise_details'];
                dispatch(updatePlanSession(planId, edited_session_details['session_details']['pk'], submit_value, onSuccessSessions, onError));
            }
        } else {
            resetPops();
        }
    }
    // Delete exercise
    const onDeleteExercise = () => {
        var submit_value = { description_dict: null }
        edited_session_details['exercise_details'].splice(delete_popup['delete_exercise_index'], 1);
        edited_session_details['exercise_details'].filter(n => n);
        submit_value['description_dict'] = edited_session_details['exercise_details'];
        showDeletePopup({ ...delete_popup, exercise_loading: true });
        dispatch(updatePlanSession(planId, edited_session_details['session_details']['pk'], submit_value, onSuccessSessions, onError));
    }
    // On Success
    const onSuccess = (message) => {
        resetPops();
        dispatch(createNotification("success-toast", message));
        api.get(`coach/plan/${planId}`).then((response) => {
            if (response.status === 200) {
                setPlanDetail(response.data);
            }
        }).catch((error) => {
            onError(error);
        });
    };
    // On Success - Sessions
    const onSuccessSessions = (message) => {
        resetPops();
        dispatch(createNotification("success-toast", message));
        api.get(`coach/plan/${planId}/sessions`).then((response) => {
            if (response.status === 200) {
                setTrainingPlanSessions(response.data);
                stopLoading();
            }
        }).catch((error) => {
            onError(error);
        });
    }
    // On Success - Delete Plans
    const onSuccessDeletePlan = () => {
        resetPops();
        dispatch(createNotification("success-toast", "Training plan has been delete."));
        history.push("/dashboard/plans");
    };
    // Success message toast
    const onSuccessMessage = (message) => {
        resetPops();
        dispatch(createNotification("success-toast", message));
        history.push(`/dashboard/plan/view?id=${planId}`)
    };
    // On Error
    const onError = (error) => {
        resetPops();
        dispatch(createNotification("error-toast", error));
    }
    // Push in specific location in list
    const pushInWeekList = (data, index, subindex) => {
        var record_list = [];
        record_list[index] = [];
        record_list[index][subindex] = {
            week_details: {
                week_no: data['week_no'],
                block: data['block'],
                week_focus: data['week_focus']
            },
            session_details: null,
            exercise_details: []
        };
        setWeeklySessionsList(record_list);
    }
    // Prepare Weekly Session List structure for frontend
    const prepareWeeklySessionsList = (sessions) => {
        var week_nos = sessions.map(({ week_no }) => week_no);
        week_nos = week_nos.filter(function (item, pos) {
            return week_nos.indexOf(item) === pos;
        });
        var new_record_list = [];
        for (var i = 0; i < week_nos.length; i++) {
            // eslint-disable-next-line
            var record = sessions.filter((item) => item['week_no'] === week_nos[i]);
            new_record_list[i] = [];
            for (var j = 0; j < record.length; j++) {
                var item = record[j];
                var new_record_detail = {};
                new_record_detail.week_details = {
                    week_no: item['week_no'],
                    block: item['block'],
                    week_focus: item['week_focus']
                }
                if (item['name']) {
                    new_record_detail.session_details = {
                        name: item['name'],
                        notes: item['notes'],
                        duration: item['duration'],
                        focus: item['focus'],
                        image: item['image'],
                        video: item['video'],
                        pk: item['pk'],
                        priority_no: item['priority_no'],
                        sport_type: item['sport_type'],
                        intensity: item['intensity'],
                    }
                    new_record_detail.exercise_details = item['description_dict'];
                } else {
                    new_record_detail.session_details = null;
                    new_record_detail.exercise_details = [];
                }
                new_record_list[i][j] = new_record_detail;
            }
        }
        setWeeklySessionsList(new_record_list);
    }
    // Training plan weekly sessions list
    useEffect(() => {
        if (training_plan_sessions && training_plan_sessions.length > 0) {
            const values = JSON.parse(localStorage.getItem(`plan_${planId}_week_details`));
            if (values) {
                if (values.length > 1) {
                    let arr = training_plan_sessions.concat(values);
                    arr.sort((a, b) => a.week_no - b.week_no);
                    prepareWeeklySessionsList(arr);
                } else if (values.length === 1) {
                    let obj = values[0];
                    delete obj.planId;
                    let arr = training_plan_sessions;
                    arr.push(obj);
                    arr.sort((a, b) => a.week_no - b.week_no);
                    prepareWeeklySessionsList(arr);
                }
            } else {
                prepareWeeklySessionsList(training_plan_sessions);
            }
        } else {
            const values = JSON.parse(localStorage.getItem(`plan_${planId}_week_details`));
            if (values) {
                if (values.length > 1) {
                    prepareWeeklySessionsList(values);
                } else if (values.length === 1) {
                    let obj = values[0];
                    delete obj.planId;
                    pushInWeekList(obj, 0, 0);
                }
            }
        }

        const openWeek = window.localStorage.getItem("open_week_tab");
        if (openWeek)
            setWeekNo(Number(openWeek));
        else
            setWeekNo(0);

    }, [training_plan_sessions]);

    // Training plan details
    useEffect(() => {
        if (plan_detail) {
            setLoading({ ...loading, plan_loading: false });
            const tags_collection = setBackendTags(plan_detail['tags']);
            set_training_plan_data({
                image: plan_detail['plan_image'] || null,
                video: plan_detail['promo_video'] || "",
                name: plan_detail['name'] || "",
                short_title: plan_detail['short_title'] || "",
                coach_bio: plan_detail['coach_bio'] || "",
                summary: plan_detail['summary'] || "",
                description: plan_detail['description'] || "",
                plan_length_weeks: plan_detail['plan_length'] || 4,
                sport_type: plan_detail['sport_type'] || null,
                level: plan_detail['level'] || null,
                equipment_required: plan_detail['equipment_required'] || "",
                tags: tags_collection,
                price: plan_detail['price'] || 0.00,
                currency: plan_detail['currency'] || currency_list[0],
                moderator_comments: plan_detail['moderator_comments']
            });
            set_image_for_preview(plan_detail['plan_image'] || null);
            set_can_edit(plan_detail['is_draft'] || false);
        }
    }, [plan_detail]);

    function myFunction() {
        if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
            if (document.getElementById("header")) {
                // document.getElementById("header").style.boxShadow = "rgb(0 0 0 / 30%) 0px 0px 8px";
                document.getElementById("header").style.padding = "16px 24px";
            }
        } else {
            if (document.getElementById("header")) {
                // document.getElementById("header").style.boxShadow = "none";
                document.getElementById("header").style.padding = "16px 24px";
            }
        }
    }
    function stopLoading() { setLoading({ ...loading, sessions_loading: false }) }
    useEffect(() => {
        window.localStorage.removeItem("inviteResponse");
        window.onscroll = function () { myFunction() };
        if (planId) {
            setLoading({ plan_loading: true, sessions_loading: true });
            dispatch(fetchSportTypes(onError));
            dispatch(fetchTrainingLevels(onError));
            dispatch(fetchSessionSportTypes(onError));
            dispatch(fetchTrainingIntensities(onError));
            api.get(`coach/plan/${planId}`).then((response) => {
                if (response.status === 200) {
                    setPlanDetail(response.data);
                }
            }).catch((error) => {
                onError(error);
            });
            api.get(`coach/plan/${planId}/sessions`).then((response) => {
                if (response.status === 200) {
                    setTrainingPlanSessions(response.data);
                    stopLoading();
                }
            }).catch((error) => {
                onError(error);
            });
        }
        
    }, []);

    function useOutsideAlerter(ref, closeSelector) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    closeSelector();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, closeSelector]);
    }
    const closeSelector = () => setInfos({});
    const sessionRef = useRef(null);
    const exerciseRef = useRef(null);
    useOutsideAlerter(sessionRef, closeSelector);
    useOutsideAlerter(exerciseRef, closeSelector);

    return loading['plan_loading'] ?
        <div className="flex column justifyCenter alignCenter w-100 h-100vh">
            <div className="custom_loader" />
            <p style={{ marginTop: 32 }}>
                {`Please wait a moment`}
            </p>
        </div>
        :
        <div className="flex column w-100 h-100" style={{ color: '#000000' }}>
            {/* Header */}
            <div id="header" className="flex column justifyCenter alignCenter"
                style={{
                    position: 'fixed',
                    top: 0,
                    height: 80,
                    zIndex: 10,
                    background: 'rgba(1, 33, 58, 0.7)',
                    backdropFilter: 'blur(25px)',
                    width: 'calc(100vw - 150px)',
                    padding: 24
                }}>
                <div className="flex alignCenter justifyBetween w-100">
                    <div className="flex justifyStart alignCenter">
                        <img className="pointer" src={BackwardIcon} alt="back_icon" height={20} width={20} onClick={() => history.push("/dashboard/plans")} />
                        <h3 style={{ marginLeft: 16, fontSize: 24, color: '#FFFFFF' }}>
                            {training_plan_data && training_plan_data['name']}
                        </h3>
                    </div>

                    {can_edit ?
                        <div className="flex justifyEnd alignCenter">
                            <div className="green-button" style={{ maxWidth: 200, width: 200 }} onClick={() => submitPlanPopup({ ...submit_plan, review_popup: true })} >
                                <p className="button_label">Submit for review</p>
                            </div>
                            <div className="flex justifyCenter alignCenter pointer" style={{ margin: "0 16px" }}>
                                <img src={EditIconLight} alt="edit_icon" height={24} width={24} onClick={() => showUpdatePopup({ ...update_popup, plan_popup: true })} />
                            </div>
                            <div className="flex justifyCenter alignCenter pointer" >
                                <img src={DeleteIconLight} alt="delete icon" height={16} width={16} onClick={() => showDeletePopup({ ...delete_popup, delete_plan_popup: true })} />
                            </div>
                        </div>
                        : null}
                </div>
            </div>

            <div className="flex column justifyStart alignStart w-100" style={{ overflow: 'auto', margin: "120px 0 0", padding: "0 32px 0 60px" }}>
                <div className="w-100" style={{ minWidth: 1500 }}>
                    {/* Content Area for training plan */}
                    <div className="flex justifyBetween alignStart w-100">
                        {/* Left section */}
                        <div className="flex column justifyStart" style={{ minWidth: 350, maxWidth: 520, paddingRight: 56, color: '#FFFFFF' }}>
                            {/* Field: Moderator comments */}
                            {training_plan_data['moderator_comments'] !== "" ?
                                <div className="flex column justifyStart w-100" style={{ marginBottom: 16 }}>
                                    <label className="settings-label">Moderator comments</label>
                                    <p style={{ marginTop: 8 }}> {training_plan_data['moderator_comments']}  </p>
                                </div>
                                : null}
                            {/* Field: sport_types */}
                            <div className="flex column justifyStart w-100">
                                <label className="settings-label">Sport Type</label>
                                {(sport_types && sport_types.length > 0) &&
                                    sport_types.map((option, index) => option.pk === Number(training_plan_data['sport_type']) ?
                                        <p key={index + 1} style={{ marginTop: 8 }}>{option.display_name}</p>
                                        : null)}
                            </div>
                            {/* Field: level */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Level</label>
                                {training_levels && training_levels.length > 0 &&
                                    training_levels.map((option, index) => option.pk === Number(training_plan_data['level']) ?
                                        <p key={index + 1} style={{ marginTop: 8 }}>{option.display_name}</p>
                                        : null)}
                            </div>
                            {/* Field: Plan length */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Plan length in weeks</label>
                                <p style={{ marginTop: 8 }}>{`${training_plan_data['plan_length_weeks'] > 1 ? `${training_plan_data['plan_length_weeks']} weeks` : `${training_plan_data['plan_length_weeks']} week`}`}</p>
                            </div>
                            {/* Equipment */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Equipment</label>
                                <p style={{ marginTop: 8 }}>{training_plan_data['equipment_required']}</p>
                            </div>
                            {/* Field: summary */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Summary</label>
                                <p style={{ marginTop: 8 }}>{training_plan_data['summary']}  </p>
                            </div>
                            {/* Field: description */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Description</label>
                                <p style={{ marginTop: 8 }}>{training_plan_data['description']}  </p>
                            </div>

                        </div>
                        {/* Right section */}
                        <div className="flex column justifyStart" style={{ minWidth: 656 }}>
                            <div className="flex justifyStart alignCenter">
                                {/* Plan video */}
                                <div className="flex column justifyStart w-100">
                                    <label className="settings-label">Promo video</label>
                                    <div className="flex relative" style={{ marginTop: 8 }}>
                                        {(!video_error && show_preview) ? (
                                            <div className="flex justifyCenter alignCenter relative w-100" style={{ height: 200, width: "100%", borderRadius: 6 }}>
                                                <img src="https://media.wild.ai/assets/sport_images/strength_and_conditioning.jpeg" alt="plan_image" height={200} width="100%" style={{ borderRadius: 6 }} />
                                                <div className="flex column justifyCenter alignCenter h-100 absolute w-100">
                                                    <div className="custom_loader" />
                                                </div>
                                            </div>
                                        ) : null}

                                        {video_error ? (
                                            <div className="flex justifyCenter alignCenter relative w-100" style={{ height: 200, width: "100%", borderRadius: 6 }}>
                                                <img src="https://wild-blog-strapi.s3.amazonaws.com/Screen_Shot_2022_12_21_at_3_23_00_PM_f6bbd06d05.png" alt="plan_image" height={200} width="100%" style={{ borderRadius: 6 }} />
                                            </div>
                                        ) : null}
                                        <ReactPlayer
                                            width="100%"
                                            height={200}
                                            url={training_plan_data['video']}
                                            controls={true}
                                            style={{ display: show_preview ? 'none' : 'block', borderRadius: 6, }}
                                            onReady={() => set_preview(false)}
                                            onError={() => setVideoError(true)}
                                        />
                                    </div>
                                </div>
                                {/* Plan image */}
                                <div className="flex column justifyStart w-100" style={{ marginLeft: 16 }}>
                                    <label className="settings-label">Plan image</label>
                                    {image_for_preview ? (
                                        <img src={image_for_preview} alt="plan_image"
                                            height={200} width="100%" style={{ borderRadius: 6, marginTop: 8, objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <div className="flex column alignCenter justifyCenter" style={{ background: '#e6e6e6', marginTop: 8, height: 200, width: "100%", borderRadius: 6, }}>
                                            <p>No Image Uploaded</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Field: Coach Bio */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Coach Bio</label>
                                <p style={{ marginTop: 8, color: '#FFFFFF' }}>{training_plan_data['coach_bio']}  </p>
                            </div>
                            {/* Price + Currency */}
                            <div className="flex justifyStart alignCenter" style={{ margin: "24px 0" }}>
                                <div className="flex column justifyStart">
                                    <label className="settings-label">Price</label>
                                    <div style={{ background: '#FFF', borderRadius: 4, marginTop: 12, width: 320, padding: "8px 12px" }}>
                                        <p style={{ marginTop: 4 }}>{training_plan_data['price']}  </p>
                                    </div>
                                </div>
                                <div className="flex column justifyStart" style={{ marginLeft: 16 }}>
                                    <label className="settings-label">Currency</label>
                                    <div style={{ background: '#FFF', borderRadius: 4, marginTop: 12, width: 320, padding: "8px 12px" }}>
                                        <p style={{ marginTop: 4 }}>{training_plan_data['currency']}  </p>
                                    </div>
                                </div>
                            </div>

                            {/* Tags */}
                            <div className="flex column justifStart">
                                <label className="settings-label">Tags</label>
                                <div id="tags">
                                    {(training_plan_data['tags'] && training_plan_data['tags'].length > 0) ? training_plan_data['tags'].map((item, index) => item['status'] === "existing" ?
                                        <li key={`key-${index + 1}`} className="tag" style={{ background: `${item.color}50` || '#ffffff', color: '#FFFFFF' }}><span className="tag-title">{item.tag}</span> </li>
                                        : null) : <p style={{ marginTop: 8 }}>No tags added </p>}
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Content area for sessions list */}
                    {loading['sessions_loading'] ?
                        <div className="flex column justifyCenter alignCenter w-100vw" style={{ height: 425 }}  >
                            <div className="custom_loader" />
                            <p style={{ marginTop: 32 }}>
                                {`Loading sessions list, please wait…`}
                            </p>
                        </div>
                        : weekly_sessions_list.length > 0 ?
                            <div className="flex column alignStart w-100 relative" style={{ background: '#FFFFFF', borderRadius: 10, margin: '56px 0', padding: "32px 32px 56px" }}>
                                {weekly_sessions_list.map((item, index) => {
                                    return (index + 1 === open_week_no ? (
                                        <div className="flex column alignStart w-100" style={{ borderTop: index + 1 !== 1 ? '1px solid #e6e6e6' : 0 }}>
                                            {/* Week no */}
                                            <div className="flex justifyBetween alignCenter w-100" style={{ height: 56, padding: "0 8px" }}>
                                                <p style={{ fontSize: 18, fontWeight: 600 }}>Week {item[0]['week_details']['week_no']}</p>
                                                <div className="flex alignCenter">
                                                    <img src={ArrowUpIcon} alt="arrow up icon" height={24} width={24} className="pointer" />
                                                    {/* {can_edit && <img src={EditIcon} alt="edit icon" height={24} width={24} className="pointer" style={{ margin: "0 16px" }} />} */}
                                                    {/* {can_edit && <img src={DeleteIcon} alt="delete icon" height={18} width={18} className="pointer" />} */}
                                                </div>
                                            </div>

                                            {/* This session creation card will appear before every week session's start */}
                                            {can_edit &&
                                                <div className="flex column w-100 gray_box">
                                                    {/* Columns area */}
                                                    <div className="flex justifyBetween w-100">
                                                        <div className="flex justifyBetween alignCenter w-100" style={{ height: 32 }}>
                                                            <p style={{ fontSize: 14, width: 220 }}>Session name</p>
                                                            <p style={{ fontSize: 14, width: 200 }}>Sport type</p>
                                                            <p style={{ fontSize: 14, width: 120 }}>Intensity</p>
                                                            <p style={{ fontSize: 14, width: 120 }}>Duration</p>
                                                            {/* <p style={{ fontSize: 14, width: 100 }}>Notes</p> */}
                                                        </div>
                                                        <div className="transparent_separator" />
                                                        <div className="flex justifyCenter alignCenter" style={{ width: 160 }} />
                                                    </div>
                                                    {/* Message area */}
                                                    <div className="flex justifyBetween alignCenter w-100" style={{ margin: '32px 0 16px' }}>
                                                        <div style={{ width: 120 }} />
                                                        <div className="flex column justifyCenter alignCenter" style={{ width: 425 }}>
                                                            <h5 style={{ fontSize: 16, lineHeight: '140%', color: '#737373' }}>Add session</h5>
                                                            <p style={{ fontSize: 12, lineHeight: '150%', color: '#737373', textAlign: 'center', marginTop: 8 }}>You can add session that will run chronologically. Don’t worry if you get them out of order they can be moved around later.</p>
                                                        </div>
                                                        <div className="flex justifyEnd alignCenter" style={{ marginRight: 32 }}>
                                                            <img src={ShadedArrow} alt="shaded arrow down" height={68} width={28} />
                                                        </div>
                                                    </div>
                                                    {/* Button area */}
                                                    <div className="flex justifyEnd alignCenter w-100" style={{ height: 50 }}>
                                                        <div className="gray_button" onClick={() => showUpdatePopup({ ...update_popup, new_session_popup: true, new_session_for_week_no: item[0]['week_details']['week_no'] })}>
                                                            <img src={PlusIcon} alt="plus icon" height={10} width={10} />
                                                            <p>Session</p>
                                                        </div>
                                                    </div>
                                                </div>}

                                            {/* Sessions + Exercises */}
                                            {item.map((session, session_index) => {
                                                return session.session_details && (
                                                    <div className="flex column w-100 gray_box" key={session_index + 1} style={{ margin: "24px 0" }}>
                                                        {/* 1- Session detail */}
                                                        <div className="flex justifyBetween w-100" style={{ margin: "8px 0" }}>
                                                            <div className="flex justifyBetween alignCenter w-100" style={{ height: 32 }}>
                                                                <p style={{ fontSize: 14, width: 220 }}>{session['session_details']['name']}</p>
                                                                <p style={{ fontSize: 14, width: 200 }}>{filterName(session_sport_types, session['session_details']['sport_type'])}</p>
                                                                <p style={{ fontSize: 14, width: 120 }}>{`${filterName(training_intensities, session['session_details']['intensity'])} intensity`}</p>
                                                                <p style={{ fontSize: 14, width: 120 }}>{secondsToHm(session['session_details']['duration'])}</p>
                                                                {/* <p style={{ fontSize: 14, width: 100 }}>{getShortDescription(session['session_details']['notes'], 10)}</p> */}
                                                            </div>
                                                            {can_edit && <div className="separator" />}
                                                            {can_edit && <div className="flex justifyCenter alignCenter" style={{ width: 160 }}>
                                                                <img className="pointer" src={EditIcon} alt="edit icon" height={24} width={24} onClick={() => {
                                                                    setEditedSessionDetails(session.session_details);
                                                                    showUpdatePopup({ ...update_popup, session_popup: true })
                                                                }} />
                                                                <img className="pointer" src={DeleteIcon} alt="delete icon" height={16} width={16} style={{ margin: "0 16px" }} onClick={() =>
                                                                    showDeletePopup({ ...delete_popup, delete_session_popup: true, delete_session_id: session.session_details['pk'] })} />
                                                                <img className="pointer" src={ThreeDotsIcon} alt="dots icon" height={18} width={18} onClick={() => {
                                                                    setEditedSessionDetails(session.session_details); showViewPopup({ ...view_popup, session_popup: true });
                                                                }} />
                                                            </div>}
                                                        </div>
                                                        {/* 2- Add exercise box before every session's exercises list */}
                                                        {can_edit &&
                                                            <div className="flex column w-100 dark_gray_box">
                                                                <div className="flex justifyBetween alignCenter w-100" style={{ margin: '32px 0 16px' }}>
                                                                    <div style={{ width: 120 }} />
                                                                    <div className="flex column justifyCenter alignCenter" style={{ width: 425 }}>
                                                                        <h5 style={{ fontSize: 16, lineHeight: '140%', color: '#737373' }}>Add exercises</h5>
                                                                        <p style={{ fontSize: 12, lineHeight: '150%', color: '#737373', textAlign: 'center', marginTop: 8 }}>You can add exercises that will run chronologically. Don’t worry if you get them out of order they can be moved around later.</p>
                                                                    </div>
                                                                    <div className="flex justifyEnd alignCenter" style={{ marginRight: 32 }}>
                                                                        <img src={ShadedArrow} alt="shaded arrow down" height={68} width={28} />
                                                                    </div>
                                                                </div>
                                                                <div className="flex justifyEnd alignCenter w-100" style={{ height: 50 }}>
                                                                    <div className="light_gray_button" onClick={() => {
                                                                        setEditedSessionDetails(session);
                                                                        showUpdatePopup({ ...update_popup, new_exercise_popup: true, new_exercise_index: session['exercise_details'] ? session['exercise_details'].length : 0 })
                                                                    }}>
                                                                        <img src={PlusIcon} alt="plus icon" height={10} width={10} />
                                                                        <p>Exercise</p>
                                                                    </div>
                                                                </div>
                                                            </div>}

                                                        {/* 3- Exerices list */}
                                                        {/* a- Headers */}
                                                        {(session['exercise_details'].length > 0 && can_edit) &&
                                                            <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0", padding: '11px 24px 11px 32px' }}>
                                                                <div className="flex alignCenter w-100" style={{ height: 32 }}>
                                                                    <p style={{ fontSize: 14, width: 80 }}>Category</p>
                                                                    <p style={{ fontSize: 14, width: 120, padding: 20 }}>Video</p>
                                                                    <p style={{ fontSize: 14, width: 140 }}>Movement</p>
                                                                    <p style={{ fontSize: 14, width: 60 }}>Sets</p>
                                                                    <p style={{ fontSize: 14, width: 140 }}>Reps</p>
                                                                    <p style={{ fontSize: 14, width: 80 }}>Tempo</p>
                                                                    <p style={{ fontSize: 14, width: 140 }}>Load</p>
                                                                    <p style={{ fontSize: 14, width: 180 }}>Exercise Importance</p>
                                                                    <p style={{ fontSize: 14, width: 220 }}>Description</p>
                                                                </div>
                                                                <div className="transparent_separator" />
                                                                <div className="flex justifyCenter alignCenter" style={{ width: 140 }} />
                                                            </div>}
                                                        {/* b- items */}
                                                        {(session['exercise_details'].length > 0)
                                                            && session['exercise_details'].map((exercise, exercise_index) => exercise &&
                                                                <div className="exercise_card" key={exercise_index + 1}>
                                                                    <div className="flex alignCenter w-100" style={{ height: 32 }}>
                                                                        <p style={{ fontSize: 14, width: 80 }}>{exercise['category']}</p>
                                                                        {(exercise['video'] && exercise['video'] !== "") ?
                                                                            <div onClick={() => window.open(exercise['video'], "_blank")} className="flex justifyCenter alignCenter pointer w-100" style={{ minWidth: 120, maxWidth: 120 }}>
                                                                                <img src={PlayButtonIcon} alt="play button icon" height={40} width={40} style={{ marginLeft: -44 }} />
                                                                            </div>
                                                                            : <div className="flex justifyStart alignCenter pointer" style={{ minWidth: 120, maxWidth: 120 }} />}
                                                                        <p style={{ fontSize: 14, width: 140 }}>{getShortDescription(exercise['movement'], 24)}</p>
                                                                        <p style={{ fontSize: 14, width: 60 }}>{exercise['sets']}</p>
                                                                        <p style={{ fontSize: 14, width: 140 }}>{getShortDescription(exercise['reps'], 14)}</p>
                                                                        <p style={{ fontSize: 14, width: 80 }}>{exercise['tempo']}</p>
                                                                        <p style={{ fontSize: 14, width: 140, paddingRight: 16 }}>{exercise['load']}</p>
                                                                        <p style={{ fontSize: 14, width: 180 }}>{exercise['exercise_importance']}</p>
                                                                        <p style={{ fontSize: 14, width: 220 }}>{getShortDescription(exercise['description'], 28)}</p>
                                                                    </div>
                                                                    {can_edit && <div className="separator" />}
                                                                    {can_edit && <div className="flex justifyCenter alignCenter" style={{ width: 160 }}>
                                                                        <img className="pointer" src={EditIcon} alt="edit icon" height={24} width={24} onClick={() => {
                                                                            setEditedSessionDetails(session);
                                                                            showUpdatePopup({ ...update_popup, exercise_popup: true, exercise_index: exercise_index })
                                                                        }} />
                                                                        <img className="pointer" src={DeleteIcon} alt="delete icon" height={16} width={16} style={{ margin: "0 24px" }} onClick={() => {
                                                                            setEditedSessionDetails(session);
                                                                            showDeletePopup({ ...delete_popup, delete_exercise_popup: true, delete_exercise_index: exercise_index })
                                                                        }} />
                                                                        <img className="pointer" src={ThreeDotsIcon} alt="dots icon" height={18} width={18} onClick={() => {
                                                                            setEditedSessionDetails(session);
                                                                            showViewPopup({ ...view_popup, exercise_popup: true, exercise_index: exercise_index });
                                                                        }} />
                                                                    </div>}
                                                                </div>
                                                            )}
                                                    </div>
                                                )
                                            })}


                                            {/* Week button */}
                                            <div style={{ marginTop: 16, position: 'absolute', bottom: 12, right: 28 }}>
                                                <div className="gray_button" style={{ height: 44, width: 120 }} onClick={() => showUpdatePopup({ ...update_popup, new_week_popup: true })}>
                                                    <img src={PlusIcon} alt="plus icon" height={10} width={10} />
                                                    <p>{`Week ${weekly_sessions_list.length + 1}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                        <div className="flex justifyBetween alignCenter w-100" style={{ height: 56, padding: "16px 8px 0", margin: "8px 0", borderTop: index + 1 !== 1 ? '1px solid #e6e6e6' : 0 }}>
                                            <p style={{ fontSize: 18, fontWeight: 600 }}>Week {item[0]['week_details']['week_no']}</p>
                                            <img src={ArrowDownIcon} alt="arrow down icon" height={16} width={16} className="pointer" onClick={() => { setWeekNo(index + 1); window.localStorage.setItem("open_week_tab", index + 1) }} />
                                        </div>)
                                })}
                            </div>
                            : weekly_sessions_list.length === 0 ?
                                <div className="flex column w-100 gray_box" style={{ background: '#FFFFFF', margin: "64px 0" }}>
                                    <div className="flex justifyBetween alignCenter w-100" style={{ margin: '32px 0 16px' }}>
                                        <div style={{ width: 120 }} />
                                        <div className="flex column justifyCenter alignCenter" style={{ width: 425 }}>
                                            <h5 style={{ fontSize: 16, lineHeight: '140%', color: '#737373' }}>Add week</h5>
                                            <p style={{ fontSize: 12, lineHeight: '150%', color: '#737373', textAlign: 'center', marginTop: 8 }}>You can add session that will run chronologically or add a sessions. Don’t worry if you get them out of order they can be moved around later.</p>
                                        </div>
                                        <div className="flex justifyEnd alignCenter" style={{ marginRight: 32 }}>
                                            <img src={ShadedArrow} alt="shaded arrow down" height={68} width={28} />
                                        </div>
                                    </div>
                                    <div className="flex justifyEnd alignCenter w-100" style={{ height: 50 }}>
                                        <div className="gray_button" onClick={() => showUpdatePopup({ ...update_popup, new_week_popup: true })}>
                                            <img src={PlusIcon} alt="plus icon" height={10} width={10} />
                                            <p>Week</p>
                                        </div>
                                    </div>
                                </div>
                                : null}
                </div>
            </div>

            {/* ------------------------- TRAINING PLAN START ------------------------- */}
            {/* Update  */}
            {(update_popup['plan_popup'] && training_plan_data) ?
                <div className="model_popup_sidebar">
                    <div className="container">
                        {/* Header */}
                        <div className="flex justifyBetween alignCenter w-100">
                            <h3>Edit Plan </h3>
                            <img className="pointer" src={CrossTab} alt="close icon" height={24} width={24} onClick={() => showUpdatePopup({ ...update_popup, plan_popup: false })} />
                        </div>

                        {/* Form Area */}
                        <div className="flex column justifyStart alignCenter w-100" onSubmit={(e) => onSavePlan(e)} style={{ marginTop: 40 }}>
                            <form className="flex column alignCenter justifyCenter w-100">
                                {/* Your title */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Your title</label>
                                    <input
                                        id="short_title"
                                        disabled={!can_edit}
                                        type="text"
                                        required
                                        defaultValue={training_plan_data['short_title']}
                                        maxLength={250}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Coach bio */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    {can_edit ?
                                        <div className="flex justifyBetween alignCenter w-100 relative">
                                            <label className="settings-label">Your Bio (max 1500 characters)</label>
                                            <label className="settings-label">({charc_count['coach_bio'] || getCharcCount(training_plan_data['coach_bio'])} / 1500)</label>
                                        </div>
                                        : <label className="settings-label">Your Bio</label>}
                                    <textarea
                                        id="coach_bio"
                                        required
                                        type="text"
                                        disabled={!can_edit}
                                        rows={rows_length['coach_bio'] || getRowLength(training_plan_data['coach_bio'])}
                                        maxLength={1500}
                                        defaultValue={training_plan_data['coach_bio']}
                                        className="settings-value settings-background"
                                        onChange={(e) => checkCharcCount('coach_bio', e.target.value.length)}
                                    />
                                </div>
                                {/* Field: Plan name  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Plan name</label>
                                    <input
                                        id="name"
                                        disabled={!can_edit}
                                        type="text"
                                        required
                                        defaultValue={training_plan_data['name']}
                                        maxLength={250}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Sport type */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Sport Type</label>
                                    {can_edit ?
                                        <select id="sport_type" required className="model_popup_selector">
                                            <option value="none" disabled className="settings-value settings-background">
                                                Select to change sport type
                                            </option>
                                            {(sport_types && sport_types.length > 0) ? sport_types.map((option, index) => option.pk === Number(training_plan_data['sport_type']) ?
                                                <option selected value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                                : <option value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                            ) : null}
                                        </select>
                                        :
                                        <input type="text" disabled className="settings-value settings-background"
                                            value={filterName(sport_types, training_plan_data['sport_type'])}
                                        />
                                    }
                                </div>
                                {/* Level */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Level</label>
                                    {can_edit ?
                                        <select id="level" required className="model_popup_selector" >
                                            <option value={null} disabled className="settings-value settings-background">
                                                Select to change training level
                                            </option>
                                            {(training_levels && training_levels.length > 0) ? training_levels.map((option, index) => option.pk === training_plan_data['level'] ?
                                                <option selected value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                                : <option value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                            ) : null}

                                        </select>
                                        :
                                        <input type="text" disabled className="settings-value settings-background"
                                            value={filterName(training_levels, training_plan_data['level'])}
                                        />
                                    }
                                </div>
                                {/* Plan length in weeks */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Plan length in weeks</label>
                                    <input id="plan_length_weeks"
                                        disabled={!can_edit}
                                        type="number"
                                        defaultValue={training_plan_data['plan_length_weeks']}
                                        min={1}
                                        max={56}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Description */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    {can_edit ?
                                        <div className="flex justifyBetween alignCenter w-100 relative">
                                            <label className="settings-label">Description (max 1500 characters)</label>
                                            <label className="settings-label">({charc_count['description'] || getCharcCount(training_plan_data['description'])} / 1500)</label>
                                        </div>
                                        : <label className="settings-label">Description </label>}
                                    <textarea
                                        id="description"
                                        required
                                        type="text"
                                        disabled={!can_edit}
                                        rows={rows_length['description'] || getRowLength(training_plan_data['description'])}
                                        maxLength={1500}
                                        defaultValue={training_plan_data['description']}
                                        className="settings-value settings-background"
                                        onChange={(e) => checkCharcCount('description', e.target.value.length)}
                                    />
                                </div>
                                {/* Summary */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    {can_edit ?
                                        <div className="flex justifyBetween alignCenter w-100 relative">
                                            <label className="settings-label">Summary (max 1000 characters)</label>
                                            <label className="settings-label">({charc_count['summary'] || getCharcCount(training_plan_data['summary'])} / 1000)</label>
                                        </div>
                                        : <label className="settings-label">Summary</label>}
                                    <textarea
                                        id="summary"
                                        required
                                        type="text"
                                        disabled={!can_edit}
                                        rows={rows_length['summary'] || getRowLength(training_plan_data['summary'])}
                                        maxLength={1000}
                                        defaultValue={training_plan_data['summary']}
                                        className="settings-value settings-background"
                                        onChange={(e) => checkCharcCount('summary', e.target.value.length)}
                                    />
                                </div>
                                {/* Field: equipment_required  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Equipment Required</label>
                                    </div>
                                    <input id="equipment_required"
                                        disabled={!can_edit}
                                        type="text"
                                        defaultValue={training_plan_data['equipment_required']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Plan tags */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Tags</label>
                                    <PlanTags
                                        className="plan_tags"
                                        submitting={can_edit}
                                        list={tags_collection}
                                        placeholderText="Add tag"
                                        onPasteTags={(tags) => setPastedTags(tags)}
                                        onAddTag={(tagFieldEvent) => onInsertTag(tagFieldEvent)}
                                        onDeleteTag={(index) => onRemoveTag(index)}
                                        onError={(message) => dispatch(createNotification("error-toast", message))}
                                    />
                                </div>
                                {/* Plan price */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Price</label>
                                    <input
                                        id="price"
                                        type="number"
                                        disabled={!can_edit}
                                        defaultValue={training_plan_data['price']}
                                        min={0}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Plan currency */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Currency</label>
                                    {can_edit ?
                                        <select id="currency" required className="model_popup_selector">
                                            <option value="none" disabled className="settings-value">
                                                Select to change currency
                                            </option>
                                            {currency_list.map((option, index) => option === training_plan_data['currency'] ?
                                                <option selected value={option} key={`option-${index + 1}`} className="settings-value">
                                                    {option}
                                                </option>
                                                : <option value={option} key={`option-${index + 1}`} className="settings-value">
                                                    {option}
                                                </option>
                                            )}
                                        </select>
                                        :
                                        <input type="text" disabled defaultValue={training_plan_data['currency']} className="settings-value settings-background" />}
                                </div>
                                {/* Plan image */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Plan image</label>
                                    {image_for_preview ?
                                        <div className="flex relative" style={{ marginTop: 12 }}>
                                            <img src={image_for_preview} alt="plan_image" height={200} width={425} style={{ borderRadius: 12, boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 8px', objectFit: 'cover' }} />
                                        </div>
                                        : <div className="name_letter" style={{ borderRadius: 12, height: 200, width: 425, marginTop: 12, background: 'rgba(0,0,0,0.5)' }}>
                                            No image selected
                                        </div>}

                                    {can_edit ?
                                        <input id="image" type="file" accept="image/x-png,image/jpeg" className="settings-value pointer"
                                            onChange={onImageUpdate} />
                                        : null}
                                </div>
                                {/* Promo video */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Plan promo video</label>
                                    <div className="flex relative react_player" style={{ marginTop: 12 }}>
                                        {training_plan_data['video'] !== "" ?
                                            <ReactPlayer width={425} height={200} controls={true} url={training_plan_data['video']} />
                                            :
                                            <div className="name_letter" style={{ borderRadius: 12, height: 200, width: 425, marginTop: 12, background: 'rgba(0,0,0,0.5)' }}>
                                                No video selected
                                            </div>}
                                    </div>
                                    {can_edit ?
                                        <input
                                            id="video"
                                            type="text"
                                            defaultValue={training_plan_data['video']}
                                            required
                                            className="settings-value settings-background"
                                        />
                                        : null}
                                </div>
                                {/* Buttons */}
                                <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0" }}>
                                    {/* Cancel */}
                                    <button className="white-button" onClick={() => showUpdatePopup({ ...update_popup, plan_popup: false })} style={{ maxWidth: 180 }}>
                                        <p className="button_label">Cancel</p>
                                    </button>

                                    {/* Save and Submit */}
                                    <button className="white-button" htmltype="submit" style={{ maxWidth: 180 }}>
                                        {updateForm['plan_loading'] ?
                                            <div className="flex justifyCenter alignCenter">
                                                <div className="custom_button_loader" />
                                            </div>
                                            : <p className="button_label">Save changes</p>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : null}

            {/* Delete */}
            {delete_popup['delete_plan_popup'] ?
                <div className="flex column justifyCenter alignCenter w-100vw h-100vh"
                    style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'rgba(0,0,0,0.3)' }}>
                    <div className="flex column alignCenter justifyCenter" style={{ background: '#ffffff', height: 220, width: 576, borderRadius: 12, padding: 32 }} >
                        <h3>Are you sure?</h3>
                        <div style={{ marginTop: 16 }}>
                            <p>The action will delete the training plan.</p>
                        </div>
                        {/* Button: Submit form */}
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "32px 0 0" }}>
                            {/* Delete button */}
                            <div className="white-button pointer" style={{ maxWidth: 160 }}
                                onClick={() => showDeletePopup({ ...delete_popup, delete_plan_popup: false })}>
                                <p className="button_label">Cancel</p>
                            </div>

                            <div style={{ margin: "0 32px" }} />
                            <div className="delete-button" style={{ maxWidth: 222 }} onClick={() => onDeletePlan()}>
                                {delete_popup['plan_loading'] ?
                                    <div className="flex justifyCenter alignCenter">
                                        <div className="custom_button_loader" />
                                    </div>
                                    : <p className="button_label">Delete Plan</p>}
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            {/* ------------------------- TRAINING PLAN END ------------------------- */}


            {/* *************************************************************
                *************************************************************
                **************************************************************/}

            {/* ------------------------- WEEK START ------------------------- */}
            {/* Add new week popup */}
            {update_popup['new_week_popup'] ?
                <div className="model_popup_sidebar">
                    <div className="container">
                        {/* Header */}
                        <div className="flex justifyBetween alignCenter w-100">
                            <h3>Add new week </h3>
                            <img className="pointer" src={CrossTab} alt="close icon" height={24} width={24}
                                onClick={() => showUpdatePopup({ ...update_popup, new_week_popup: false })} />
                        </div>

                        {/* Form Area */}
                        <div className="flex column justifyStart alignCenter w-100" onSubmit={(e) => onSaveWeek(e)} style={{ marginTop: 40 }}>
                            <form className="flex column alignCenter justifyCenter w-100">
                                {/* Field: Week number  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Week number</label>
                                    <input
                                        id="week_no"
                                        type="text"
                                        name="week_no"
                                        disabled
                                        value={weekly_sessions_list.length + 1}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Block */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Block</label>
                                    <input
                                        id="block"
                                        type="text"
                                        name="block"
                                        maxLength={250}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Week focus  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Week focus</label>
                                    <textarea
                                        id="week_focus"
                                        type="text"
                                        name="week_focus"
                                        rows={5}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Buttons */}
                                <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0" }}>
                                    {/* Cancel */}
                                    <button className="white-button" onClick={() => showUpdatePopup({ ...update_popup, new_week_popup: false })} style={{ maxWidth: 180 }}>
                                        <p className="button_label">Cancel</p>
                                    </button>

                                    {/* Save and Submit */}
                                    <button className="white-button" htmltype="submit" style={{ maxWidth: 180 }}>
                                        {updateForm['session_loding'] ?
                                            <div className="flex justifyCenter alignCenter">
                                                <div className="custom_button_loader" />
                                            </div>
                                            : <p className="button_label">Save</p>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : null}
            {/* ------------------------- WEEK END ------------------------- */}


            {/* *************************************************************
                *************************************************************
                **************************************************************/}

            {/* ------------------------- SESSIONS START ------------------------- */}
            {/* View */}
            {(view_popup['session_popup'] && edited_session_details) ?
                <div className="model_popup_sidebar">
                    <div className="container">
                        {/* Header */}
                        <div className="flex justifyBetween alignCenter w-100">
                            <h3>View Session </h3>
                            <img className="pointer" src={CrossTab} alt="close icon" height={24} width={24} onClick={() => {
                                setEditedSessionDetails(null); showViewPopup({ ...view_popup, session_popup: false })
                            }} />
                        </div>

                        {/* Form Area */}
                        <div className="flex column justifyStart alignCenter w-100" style={{ marginTop: 40 }}>
                            <div className="flex column alignCenter justifyCenter w-100">
                                {/* Field: Session name  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Session name</label>
                                    <textarea
                                        disabled
                                        type="text"
                                        rows={3}
                                        value={edited_session_details['name']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Notes */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Session notes {can_edit ? '(max 250 characters)' : null}</label>
                                    <textarea
                                        disabled
                                        type="text"
                                        rows={5}
                                        value={edited_session_details['notes']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Sport type */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Sport Type</label>
                                    <input type="text" disabled className="settings-value settings-background"
                                        value={filterName(session_sport_types, edited_session_details['sport_type'])}
                                    />
                                </div>
                                {/* Intensity */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Intensity</label>
                                    <input type="text" disabled className="settings-value settings-background"
                                        value={filterName(training_intensities, edited_session_details['intensity'])}
                                    />
                                </div>
                                {/* Duration */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Duration (HH: MM)</label>
                                    <div className="flex justifyBetween alignCenter w-100">
                                        <input disabled type="text" className="settings-value settings-background" value={convertSecondsToHm(edited_session_details['duration'])['hours'] <= 9 ? `0${convertSecondsToHm(edited_session_details['duration'])['hours']}` : convertSecondsToHm(edited_session_details['hours'])['hours']} />
                                        <div className="flex column justifyCenter alignCenter h-100" style={{ width: 32, margin: "12px 8px 0" }}>:</div>
                                        <input disabled type="text" className="settings-value settings-background" value={convertSecondsToHm(edited_session_details['duration'])['minutes'] <= 9 ? `0${convertSecondsToHm(edited_session_details['duration'])['minutes']}` : convertSecondsToHm(edited_session_details['duration'])['minutes']} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            {/* Create */}
            {update_popup['new_session_popup'] ?
                <div className="model_popup_sidebar" ref={sessionRef}>
                    <div className="container">
                        {/* Header */}
                        <div className="flex justifyBetween alignCenter w-100">
                            <h3>Add new session </h3>
                            <img className="pointer" src={CrossTab} alt="close icon" height={24} width={24}
                                onClick={() => showUpdatePopup({ ...update_popup, new_session_popup: false })} />
                        </div>

                        {/* Form Area */}
                        <div className="flex column justifyStart alignCenter w-100" onSubmit={(e) => onSaveNewSession(e)} style={{ marginTop: 40 }}>
                            <form className="flex column alignCenter justifyCenter w-100">
                                {/* Field: Session name  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Session name</label>
                                    <textarea
                                        id="name"
                                        name="name"
                                        type="text"
                                        rows={3}
                                        required
                                        maxLength={250}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Notes */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Session notes (max 250 characters)</label>
                                    <textarea
                                        id="notes"
                                        type="text"
                                        name="notes"
                                        rows={5}
                                        maxLength={250}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Sport type */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Sport Type</label>
                                        <img className="pointer" onClick={() => setInfos({ sport_type: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.sport_type ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Please enter a short title for your training plan </p>
                                                <img className="pointer" onClick={() => setInfos({ sport_type: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <select id="sport_type" className="model_popup_selector">
                                        {(session_sport_types && session_sport_types.length > 0) ?
                                            session_sport_types.map((option, index) =>
                                                <option value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                            ) : null}
                                    </select>
                                </div>
                                {/* Level */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Intensity</label>
                                        <img className="pointer" onClick={() => setInfos({ intensity: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.intensity ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Enter an overall estimated intensity for this session. This should be low, Med or high </p>
                                                <img className="pointer" onClick={() => setInfos({ intensity: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <select id="intensity" required className="model_popup_selector" >
                                        <option value={0} className="settings-value settings-background">
                                            Select to change intensity level
                                        </option>
                                        {(training_intensities && training_intensities.length > 0) ?
                                            training_intensities.map((option, index) =>
                                                <option value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                            ) : null}
                                    </select>

                                </div>
                                {/* Duration */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Duration (HH: MM)</label>
                                        <img className="pointer" onClick={() => setInfos({ duration: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.duration ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Enter the estimated total duration of the session (in minutes) </p>
                                                <img className="pointer" onClick={() => setInfos({ duration: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <div className="flex justifyBetween alignCenter w-100">
                                        {/* Hours selector */}
                                        <select id="hours" required className="model_popup_selector" >
                                            {[...Array(12).keys()].map((option, index) =>
                                                <option value={option} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option <= 9 ? `0${option}` : option}
                                                </option>
                                            )}
                                        </select>
                                        <div className="flex column justifyCenter alignCenter h-100" style={{ width: 32, margin: "12px 8px 0" }}>:</div>
                                        {/* Minutes selector */}
                                        <select id="minutes" required className="model_popup_selector" >
                                            {[...Array(60).keys()].map((option, index) =>
                                                <option value={option} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option <= 9 ? `0${option}` : option}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                {/* Buttons */}
                                <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0" }}>
                                    {/* Cancel */}
                                    <button className="white-button" onClick={() => showUpdatePopup({ ...update_popup, new_session_popup: false })} style={{ maxWidth: 180 }}>
                                        <p className="button_label">Cancel</p>
                                    </button>

                                    {/* Save and Submit */}
                                    <button className="white-button" htmltype="submit" style={{ maxWidth: 180 }}>
                                        {updateForm['session_loding'] ?
                                            <div className="flex justifyCenter alignCenter">
                                                <div className="custom_button_loader" />
                                            </div>
                                            : <p className="button_label">Save</p>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : null}

            {/* Update */}
            {(update_popup['session_popup'] && edited_session_details) ?
                <div className="model_popup_sidebar">
                    <div className="container">
                        {/* Header */}
                        <div className="flex justifyBetween alignCenter w-100">
                            <h3>Edit Session </h3>
                            <img className="pointer" src={CrossTab} alt="close icon" height={24} width={24} onClick={() => {
                                setEditedSessionDetails(null); showUpdatePopup({ ...update_popup, session_popup: false })
                            }} />
                        </div>

                        {/* Form Area */}
                        <div className="flex column justifyStart alignCenter w-100" onSubmit={(e) => onSaveSession(e)} style={{ marginTop: 40 }}>
                            <form className="flex column alignCenter justifyCenter w-100">
                                {/* Field: Session name  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Session name</label>
                                    <textarea
                                        id="name"
                                        name="name"
                                        disabled={!can_edit}
                                        type="text"
                                        rows={can_edit ? 3 : 1}
                                        required
                                        defaultValue={edited_session_details['name']}
                                        maxLength={250}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Notes */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Session notes {can_edit ? '(max 250 characters)' : null}</label>
                                    <textarea
                                        id="notes"
                                        type="text"
                                        name="notes"
                                        rows={5}
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['notes']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Sport type */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Sport Type</label>
                                    {can_edit ?
                                        <select id="sport_type" required className="model_popup_selector">
                                            {(session_sport_types && session_sport_types.length > 0) ? session_sport_types.map((option, index) => option.pk === Number(edited_session_details['sport_type']) ?
                                                <option selected value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                                : <option value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                            ) : null}
                                        </select>
                                        :
                                        <input type="text" disabled className="settings-value settings-background"
                                            value={filterName(session_sport_types, edited_session_details['sport_type'])}
                                        />
                                    }
                                </div>
                                {/* Level */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Intensity</label>
                                    {can_edit ?
                                        <select id="intensity" required className="model_popup_selector" >
                                            {(training_intensities && training_intensities.length > 0) ? training_intensities.map((option, index) => option.pk === edited_session_details['intensity'] ?
                                                <option selected value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                                : <option value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                                    {option.display_name}
                                                </option>
                                            ) : null}

                                        </select>
                                        :
                                        <input type="text" disabled className="settings-value settings-background"
                                            value={filterName(training_intensities, edited_session_details['intensity'])}
                                        />
                                    }
                                </div>
                                {/* Duration */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Duration (HH: MM)</label>
                                    <div className="flex justifyBetween alignCenter w-100">
                                        {can_edit ?
                                            <select id="hours" required className="model_popup_selector" >
                                                {[...Array(12).keys()].map((option, index) => (
                                                    <option selected={option === convertSecondsToHm(edited_session_details['duration'])['hours'] ? true : false} value={option} key={`option-${index + 1}`} className="settings-value settings-background">
                                                        {option <= 9 ? `0${option}` : option}
                                                    </option>
                                                ))}
                                            </select>
                                            :
                                            <input disabled type="text" className="settings-value settings-background" value={convertSecondsToHm(edited_session_details['duration'])['hours'] <= 9 ? `0${convertSecondsToHm(edited_session_details['duration'])['hours']}` : convertSecondsToHm(edited_session_details['hours'])['hours']} />}
                                        <div className="flex column justifyCenter alignCenter h-100" style={{ width: 32, margin: "12px 8px 0" }}>:</div>

                                        {can_edit ?
                                            <select id="minutes" required className="model_popup_selector" >
                                                {[...Array(60).keys()].map((option, index) => (
                                                    <option selected={option === convertSecondsToHm(edited_session_details['duration'])['minutes'] ? true : false} value={option} key={`option-${index + 1}`} className="settings-value settings-background">
                                                        {option <= 9 ? `0${option}` : option}
                                                    </option>
                                                ))}
                                            </select>
                                            :
                                            <input disabled type="text" className="settings-value settings-background" value={convertSecondsToHm(edited_session_details['duration'])['minutes'] <= 9 ? `0${convertSecondsToHm(edited_session_details['duration'])['minutes']}` : convertSecondsToHm(edited_session_details['duration'])['minutes']} />}
                                    </div>
                                </div>

                                {/* Buttons */}
                                <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0" }}>
                                    {/* Cancel */}
                                    <button className="white-button" onClick={() => {
                                        setEditedSessionDetails(null); showUpdatePopup({ ...update_popup, session_popup: false })
                                    }} style={{ maxWidth: 180 }}>
                                        <p className="button_label">Cancel</p>
                                    </button>

                                    {/* Save and Submit */}
                                    <button className="white-button" htmltype="submit" style={{ maxWidth: 180 }}>
                                        {updateForm['session_loding'] ?
                                            <div className="flex justifyCenter alignCenter">
                                                <div className="custom_button_loader" />
                                            </div>
                                            : <p className="button_label">Save changes</p>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : null}

            {/* Delete */}
            {delete_popup['delete_session_popup'] ?
                <div className="flex column justifyCenter alignCenter w-100vw h-100vh"
                    style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'rgba(0,0,0,0.3)' }}>
                    <div className="flex column alignCenter justifyCenter" style={{ background: '#ffffff', height: 220, width: 576, borderRadius: 12, padding: 32 }} >
                        <h3>Are you sure?</h3>
                        <div style={{ marginTop: 16 }}>
                            <p>The action will delete the training session.</p>
                        </div>
                        {/* Button: Submit form */}
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "32px 0 0" }}>
                            {/* Delete button */}
                            <div className="white-button pointer" style={{ maxWidth: 160 }}
                                onClick={() => showDeletePopup({ ...delete_popup, delete_session_popup: false })}>
                                <p className="button_label">Cancel</p>
                            </div>

                            <div style={{ margin: "0 32px" }} />
                            <div className="delete-button" style={{ maxWidth: 222 }} onClick={() => onDeleteSession()}>
                                {delete_popup['session_loading'] ?
                                    <div className="flex justifyCenter alignCenter">
                                        <div className="custom_button_loader" />
                                    </div>
                                    : <p className="button_label">Delete session</p>}
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            {/* ------------------------- SESSIONS END ------------------------- */}


            {/* *************************************************************
                *************************************************************
                **************************************************************/}


            {/* ------------------------- EXERCISE START ------------------------- */}
            {/* Update */}
            {(update_popup['exercise_popup'] && edited_session_details) ?
                <div className="model_popup_sidebar">
                    <div className="container">
                        {/* Header */}
                        <div className="flex justifyBetween alignCenter w-100">
                            <h3>Edit Exercise </h3>
                            <img className="pointer" src={CrossTab} alt="close icon" height={24} width={24} onClick={() => {
                                setEditedSessionDetails(null); showUpdatePopup({ ...update_popup, exercise_popup: false, exercise_index: 0 })
                            }} />
                        </div>

                        {/* Form Area */}
                        <div className="flex column justifyStart alignCenter w-100" onSubmit={(e) => onSaveChangesExercise(e)} style={{ marginTop: 40 }}>
                            <form className="flex column alignCenter justifyCenter w-100">
                                {/* Category */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Category</label>
                                        <img className="pointer" onClick={() => setInfos({ category: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.category ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Session order
                                                    If you'd like to have supersets within the session, add it this way:
                                                    A1
                                                    A2
                                                    B1
                                                    B2
                                                    where A's are done as a superset, and then B's.
                                                    If you'd like to have a circuit, label the order as
                                                    A1
                                                    A2
                                                    A3
                                                    A4
                                                    A5.. and so on
                                                </p>
                                                <img className="pointer" onClick={() => setInfos({ category: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        required
                                        id="category"
                                        type="text"
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['category']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Focus */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Focus</label>
                                        <img className="pointer" onClick={() => setInfos({ focus: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.focus ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The focus of the movement can be one, or a combination of key terms such as (separate by a ',' if using more than one word:
                                                    strength
                                                    mobility
                                                    activation
                                                    hypertrophy
                                                    accessory
                                                    isolation
                                                    eccentric
                                                    isometric
                                                    lower body push
                                                    lower body pull
                                                    upper body push
                                                    upper body pull
                                                    core
                                                    etc.
                                                </p>
                                                <img className="pointer" onClick={() => setInfos({ focus: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="focus"
                                        type="text"
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['focus']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Movement */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Movement</label>
                                        <img className="pointer" onClick={() => setInfos({ movement: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.movement ?
                                            <div className="absolute_info flex alignStart">
                                                <p>As simple as the name of the exercise. This will be added to your library of movements along with the video URL. If you are adding a CV exercise, its best to add some details in the movement name as it will save in your library. </p>
                                                <img className="pointer" onClick={() => setInfos({ movement: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="movement"
                                        type="text"
                                        required
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['movement']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Sets */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Sets</label>
                                        <img className="pointer" onClick={() => setInfos({ sets: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.sets ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The number of sets of the movement </p>
                                                <img className="pointer" onClick={() => setInfos({ sets: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="sets"
                                        type="text"
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['sets']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Reps */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Reps</label>
                                        <img className="pointer" onClick={() => setInfos({ reps: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.reps ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The number of repetitions to do for that movement</p>
                                                <img className="pointer" onClick={() => setInfos({ reps: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="reps"
                                        type="text"
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['reps']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Tempo */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Tempo</label>
                                        <img className="pointer" onClick={() => setInfos({ tempo: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.tempo ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The tempo of the movement in seconds. This works best for time based movements or time under tempo
                                                    Eg. 1. Mountain climbers x 3 sets x 30 seconds of work
                                                    Eg. 2. Eccentric push up x 3 sets x 5 repetitions x 2 seconds of lowering per rep </p>
                                                <img className="pointer" onClick={() => setInfos({ tempo: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="tempo"
                                        type="text"
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['tempo']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Load */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Load</label>
                                        <img className="pointer" onClick={() => setInfos({ load: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.load ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Load (in kilograms). This is optional. You may want to describe how the athlete or client should feel in terms of intensity in the description or cues.
                                                    Eg. Do 8 reps where you feel you could have pushed for 2 more.</p>
                                                <img className="pointer" onClick={() => setInfos({ load: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="load"
                                        type="text"
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['load']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Rest Period */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Rest Period</label>
                                    <input
                                        id="rest_period"
                                        type="text"
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['rest_period']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Exercise Importance */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Exercise Importance</label>
                                        <img className="pointer" onClick={() => setInfos({ exercise_importance: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.exercise_importance ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Enter how important each exercise is for the session so that users can prioritise which ones to definitely do. Enter Low, Med, or High</p>
                                                <img className="pointer" onClick={() => setInfos({ exercise_importance: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    {can_edit ?
                                        <select id="exercise_importance" required className="model_popup_selector" defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['exercise_importance']}>
                                            <option value="Low" className="settings-value">Low</option>
                                            <option value="Medium" className="settings-value">Medium</option>
                                            <option value="High" className="settings-value">High</option>
                                        </select>
                                        :
                                        <input id="exercise_importance" type="text" disabled className="settings-value settings-background"
                                            value={edited_session_details['exercise_details'][update_popup['exercise_index']]['exercise_importance']}
                                        />
                                    }
                                </div>
                                {/* Description  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Description</label>
                                        <img className="pointer" onClick={() => setInfos({ description: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.description ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Enter a cue or a description for this exercise. This is optional, especially if your video has a voiceover.</p>
                                                <img className="pointer" onClick={() => setInfos({ description: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <textarea
                                        id="description"
                                        type="text"
                                        required
                                        rows={5}
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['description']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Video URL */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Video URL</label>
                                        <img className="pointer" onClick={() => setInfos({ video: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.video ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The URL of the video you would like to use for this movement. </p>
                                                <img className="pointer" onClick={() => setInfos({ video: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="video"
                                        type="text"
                                        disabled={!can_edit}
                                        defaultValue={edited_session_details['exercise_details'][update_popup['exercise_index']]['video']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Buttons */}
                                <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0" }}>
                                    {/* Cancel */}
                                    <button className="white-button" onClick={() => {
                                        setEditedSessionDetails(null); showUpdatePopup({ ...update_popup, exercise_popup: false, exercise_index: 0 })
                                    }} style={{ maxWidth: 180 }}>
                                        <p className="button_label">Cancel</p>
                                    </button>

                                    {/* Save and Submit */}
                                    <button className="white-button" htmltype="submit" style={{ maxWidth: 180 }}>
                                        {updateForm['exercise_loading'] ?
                                            <div className="flex justifyCenter alignCenter">
                                                <div className="custom_button_loader" />
                                            </div>
                                            : <p className="button_label">Save changes</p>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : null}

            {/* View */}
            {(view_popup['exercise_popup'] && edited_session_details) ?
                <div className="model_popup_sidebar">
                    <div className="container">
                        {/* Header */}
                        <div className="flex justifyBetween alignCenter w-100">
                            <h3>View Exercise </h3>
                            <img className="pointer" src={CrossTab} alt="close icon" height={24} width={24} onClick={() => {
                                setEditedSessionDetails(null); showViewPopup({ ...view_popup, exercise_popup: false, exercise_index: 0 })
                            }} />
                        </div>

                        {/* Form Area */}
                        <div className="flex column justifyStart alignCenter w-100" style={{ marginTop: 40 }}>
                            <div className="flex column alignCenter justifyCenter w-100">
                                {/* Category */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Category</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['category']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Focus */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Focus</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['focus']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Movement */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Movement</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['movement']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Sets */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Sets</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['sets']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Reps */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Reps</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['reps']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Tempo */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Tempo</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['tempo']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Load */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Load</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['load']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Rest Period */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Rest Period</label>
                                    <input
                                        type="text"
                                        disabled
                                        defaultValue={edited_session_details['exercise_details'][view_popup['exercise_index']]['rest_period']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Exercise importance */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Exercise Importance</label>
                                    <input id="exercise_importance" type="text" disabled className="settings-value settings-background"
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['exercise_importance']}
                                    />
                                </div>
                                {/* Description  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Description</label>
                                    <textarea
                                        type="text"
                                        rows={5}
                                        disabled
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['description']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Video URL */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Video Url</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={edited_session_details['exercise_details'][view_popup['exercise_index']]['video']}
                                        className="settings-value settings-background"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }

            {/* Create */}
            {update_popup['new_exercise_popup'] ?
                <div className="model_popup_sidebar">
                    <div className="container">
                        {/* Header */}
                        <div className="flex justifyBetween alignCenter w-100">
                            <h3>Add New Exercise </h3>
                            <img className="pointer" src={CrossTab} alt="close icon" height={24} width={24} onClick={() => {
                                setEditedSessionDetails(null); showUpdatePopup({ ...update_popup, new_exercise_popup: false, new_exercise_index: 0 })
                            }} />
                        </div>

                        {/* Form Area */}
                        <div className="flex column justifyStart alignCenter w-100" ref={exerciseRef} onSubmit={(e) => onAddExercise(e)} style={{ marginTop: 40 }}>
                            <form className="flex column alignCenter justifyCenter w-100">
                                {/* Category */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Category</label>
                                        <img className="pointer" onClick={() => setInfos({ category: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.category ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Session order
                                                    If you'd like to have supersets within the session, add it this way:
                                                    A1
                                                    A2
                                                    B1
                                                    B2
                                                    where A's are done as a superset, and then B's.
                                                    If you'd like to have a circuit, label the order as
                                                    A1
                                                    A2
                                                    A3
                                                    A4
                                                    A5.. and so on
                                                </p>
                                                <img className="pointer" onClick={() => setInfos({ category: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        required
                                        id="category"
                                        type="text"
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Focus */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Focus</label>
                                        <img className="pointer" onClick={() => setInfos({ focus: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.focus ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The focus of the movement can be one, or a combination of key terms such as (separate by a ',' if using more than one word:
                                                    strength
                                                    mobility
                                                    activation
                                                    hypertrophy
                                                    accessory
                                                    isolation
                                                    eccentric
                                                    isometric
                                                    lower body push
                                                    lower body pull
                                                    upper body push
                                                    upper body pull
                                                    core
                                                    etc.
                                                </p>
                                                <img className="pointer" onClick={() => setInfos({ focus: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="focus"
                                        type="text"
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Movement */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Movement</label>
                                        <img className="pointer" onClick={() => setInfos({ movement: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.movement ?
                                            <div className="absolute_info flex alignStart">
                                                <p>As simple as the name of the exercise. This will be added to your library of movements along with the video URL. If you are adding a CV exercise, its best to add some details in the movement name as it will save in your library. </p>
                                                <img className="pointer" onClick={() => setInfos({ movement: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        required
                                        id="movement"
                                        type="text"
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Sets */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Sets</label>
                                        <img className="pointer" onClick={() => setInfos({ sets: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.sets ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The number of sets of the movement </p>
                                                <img className="pointer" onClick={() => setInfos({ sets: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="sets"
                                        type="text"
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Reps */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Reps</label>
                                        <img className="pointer" onClick={() => setInfos({ reps: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.reps ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The number of repetitions to do for that movement</p>
                                                <img className="pointer" onClick={() => setInfos({ reps: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="reps"
                                        type="text"
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Tempo */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Tempo</label>
                                        <img className="pointer" onClick={() => setInfos({ tempo: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.tempo ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The tempo of the movement in seconds. This works best for time based movements or time under tempo
                                                    Eg. 1. Mountain climbers x 3 sets x 30 seconds of work
                                                    Eg. 2. Eccentric push up x 3 sets x 5 repetitions x 2 seconds of lowering per rep </p>
                                                <img className="pointer" onClick={() => setInfos({ tempo: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="tempo"
                                        type="text"
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Load */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Load</label>
                                        <img className="pointer" onClick={() => setInfos({ load: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.load ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Load (in kilograms). This is optional. You may want to describe how the athlete or client should feel in terms of intensity in the description or cues.
                                                    Eg. Do 8 reps where you feel you could have pushed for 2 more.</p>
                                                <img className="pointer" onClick={() => setInfos({ load: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="load"
                                        type="text"
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Rest Period */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <label className="settings-label">Rest Period</label>
                                    <input
                                        id="rest_period"
                                        type="text"
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Exercise importance */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Exercise Importance</label>
                                        <img className="pointer" onClick={() => setInfos({ exercise_importance: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.exercise_importance ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Enter how important each exercise is for the session so that users can prioritise which ones to definitely do. Enter Low, Med, or High</p>
                                                <img className="pointer" onClick={() => setInfos({ exercise_importance: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <select id="exercise_importance" defaultValue={null} className="model_popup_selector">
                                        <option value="Low" className="settings-value">Low</option>
                                        <option value="Medium" className="settings-value">Medium</option>
                                        <option value="High" className="settings-value">High</option>
                                    </select>
                                </div>
                                {/* Description  */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Description</label>
                                        <img className="pointer" onClick={() => setInfos({ description: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.description ?
                                            <div className="absolute_info flex alignStart">
                                                <p>Enter a cue or a description for this exercise. This is optional, especially if your video has a voiceover.</p>
                                                <img className="pointer" onClick={() => setInfos({ description: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <textarea
                                        id="description"
                                        type="text"
                                        rows={5}
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Video URL */}
                                <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                    <div className="flex justifyBetween alignCenter w-100 relative">
                                        <label className="settings-label">Video URL</label>
                                        <img className="pointer" onClick={() => setInfos({ video: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                        {infos.video ?
                                            <div className="absolute_info flex alignStart">
                                                <p>The URL of the video you would like to use for this movement. </p>
                                                <img className="pointer" onClick={() => setInfos({ video: false })} style={{ marginLeft: 16, marginTop: 4 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                            </div>
                                            : null}
                                    </div>
                                    <input
                                        id="video"
                                        type="text"
                                        className="settings-value settings-background"
                                    />
                                </div>
                                {/* Buttons */}
                                <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0" }}>
                                    {/* Cancel */}
                                    <button className="white-button" onClick={() => {
                                        setEditedSessionDetails(null); showUpdatePopup({ ...update_popup, new_exercise_popup: false, new_exercise_index: 0 })
                                    }} style={{ maxWidth: 180 }}>
                                        <p className="button_label">Cancel</p>
                                    </button>

                                    {/* Save and Submit */}
                                    <button className="white-button" htmltype="submit" style={{ maxWidth: 180 }}>
                                        {updateForm['exercise_loading'] ?
                                            <div className="flex justifyCenter alignCenter">
                                                <div className="custom_button_loader" />
                                            </div>
                                            : <p className="button_label">Add exercise</p>}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                : null
            }

            {/* Delete */}
            {delete_popup['delete_exercise_popup'] ?
                <div className="flex column justifyCenter alignCenter w-100vw h-100vh"
                    style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'rgba(0,0,0,0.3)' }}>
                    <div className="flex column alignCenter justifyCenter" style={{ background: '#ffffff', height: 220, width: 576, borderRadius: 12, padding: 32 }} >
                        <h3>Are you sure?</h3>
                        <div style={{ marginTop: 16 }}>
                            <p>The action will delete the exercise from this sessions.</p>
                        </div>
                        {/* Button: Submit form */}
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "32px 0 0" }}>
                            {/* Delete button */}
                            <div className="white-button pointer" style={{ maxWidth: 160 }}
                                onClick={() => showDeletePopup({ ...delete_popup, delete_exercise_popup: false })}>
                                <p className="button_label">Cancel</p>
                            </div>

                            <div style={{ margin: "0 32px" }} />
                            <div className="delete-button" style={{ maxWidth: 222 }} onClick={() => onDeleteExercise()}  >
                                {delete_popup['exercise_loading'] ?
                                    <div className="flex justifyCenter alignCenter">
                                        <div className="custom_button_loader" />
                                    </div>
                                    : <p className="button_label">Delete exercise</p>}
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            {/* ------------------------------------ EXERCISE END ------------------------------------ */}


            {/* *************************************************************
                *************************************************************
                **************************************************************/}


            {/* Confirm review popup for submitting plan with all of its sessions into review */}
            {submit_plan['review_popup'] ?
                <div className="flex column justifyCenter alignCenter w-100vw h-100vh" style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'rgba(0,0,0,0.3)' }}>
                    <div className="flex column alignCenter justifyCenter"
                        style={{
                            background: '#ffffff',
                            height: 220,
                            width: 576,
                            borderRadius: 12,
                            padding: 32
                        }}
                    >
                        <h3>Are you sure?</h3>
                        <div style={{ marginTop: 16 }}>
                            <p style={{ margin: "0 auto", width: '70%', textAlign: 'center' }}>The training plan session will be submitted for review and you will not be able to edit.</p>
                        </div>
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "32px 0 0" }}>
                            <div className="white-button pointer"
                                onClick={() => submitPlanPopup({ ...submit_plan, review_popup: false })}
                                style={{ maxWidth: 160 }}>
                                <p className="button_label">Cancel</p>
                            </div>

                            <div style={{ margin: "0 32px" }} />
                            <div className="white-button pointer"
                                style={{ maxWidth: 222 }}
                                onClick={() => {
                                    submitPlanPopup({ ...submit_plan, loading_state: true })
                                    dispatch(updatePlanSessions(planId, onSuccessMessage, onError));
                                }}
                            >
                                {submit_plan['loading_state'] ?
                                    <div className="flex justifyCenter alignCenter">
                                        <div className="custom_button_loader" />
                                    </div>
                                    : <p className="button_label">Save and Submit</p>}
                            </div>
                        </div>
                    </div>

                </div>
                : null}
        </div>
};

export default PlanDetail;
