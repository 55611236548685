import React, { useEffect, useState } from "react";
// Providers
import Moment from "moment";
import { cyclePhasesInformation } from "../../_helpers/constants";
// Icons
import NextIcon from "../../assets/icons/chevron-right-light.svg";
import ArrowDown from "../../assets/icons/down-light.svg";
import Minus from "../../assets/icons/minus.svg";
// PHASES ICONS - INACTIVE
import Phase1 from "../../assets/cyclephases/1.svg";
import Phase9 from "../../assets/cyclephases/10.svg";
import Phase2 from "../../assets/cyclephases/2.svg";
import Phase3 from "../../assets/cyclephases/3.svg";
import Phase4 from "../../assets/cyclephases/4.svg";
import Phase5 from "../../assets/cyclephases/5.svg";
import Phase6 from "../../assets/cyclephases/6.svg";
import Phase7 from "../../assets/cyclephases/7.svg";
import Phase8 from "../../assets/cyclephases/8.svg";
import Phase10 from "../../assets/cyclephases/9.svg";

// PHASES ICONS - ACTIVE
import Phase9Active from "../../assets/cyclephases/10active.svg";
import Phase1Active from "../../assets/cyclephases/1active.svg";
import Phase2Active from "../../assets/cyclephases/2active.svg";
import Phase3Active from "../../assets/cyclephases/3active.svg";
import Phase4Active from "../../assets/cyclephases/4active.svg";
import Phase5Active from "../../assets/cyclephases/5active.svg";
import Phase6Active from "../../assets/cyclephases/6active.svg";
import Phase7Active from "../../assets/cyclephases/7active.svg";
import Phase8Active from "../../assets/cyclephases/8active.svg";
import Phase10Active from "../../assets/cyclephases/9active.svg";

// Components
import NoData from "../common/NoData";
// Actions
import { history } from "../../index";

const AthletesTable = ({
  data_list,
  searchStatus,
  searchedText,
  loadingState,
  onError,
}) => {
  let [dataSource, setDataSource] = useState([]);
  let [ascending_index, set_ascending_index] = useState(0);
  let [ascending_highlight, set_ascending_highlight] = useState(true);
  let [decensing_index, set_decensing_index] = useState(-1);
  let [decensing_highlight, set_decensing_highlight] = useState(false);
  let [suggestions, showSuggestions] = useState(false);
  let [selectedPhase, setSelectedPhase] = useState(0);
  let [suggestionsData, setSuggestions] = useState(null);
  let [suggestionActiveTabs, setSuggestionActiveTabs] = useState({
    preTraining: false,
    traning: false,
    postTraining: false,
    cycleInfo: false,
    athleticPerformance: false,
    exerciseFocus: false,
    recommendations: false,
    raceDayDuringPhase: false,
  });

  const resetSuggestions = () => {
    setCyclePhases([
      {
        id: 1,
        label: "Early Follicular",
        logo: Phase1,
        activeLogo: Phase1Active,
        active: false,
        information: cyclePhasesInformation[1],
      },
      {
        id: 2,
        label: "Mid Follicular",
        logo: Phase2,
        activeLogo: Phase2Active,
        active: false,
        information: cyclePhasesInformation[2],
      },
      {
        id: 3,
        label: "Ovulatory",
        logo: Phase3,
        activeLogo: Phase3Active,
        active: false,
        information: cyclePhasesInformation[3],
      },
      {
        id: 4,
        label: "Mid Luteal",
        logo: Phase4,
        activeLogo: Phase4Active,
        active: false,
        information: cyclePhasesInformation[4],
      },
      {
        id: 5,
        label: "Late Luteal",
        logo: Phase5,
        activeLogo: Phase5Active,
        active: false,
        information: cyclePhasesInformation[5],
      },
      {
        id: 6,
        label: "Peri Menopause",
        logo: Phase6,
        activeLogo: Phase6Active,
        active: false,
        information: cyclePhasesInformation[6],
      },
      {
        id: 7,
        label: "Menopause",
        logo: Phase7,
        activeLogo: Phase7Active,
        active: false,
        information: cyclePhasesInformation[7],
      },
      {
        id: 8,
        label: "Active Phase",
        logo: Phase8,
        activeLogo: Phase8Active,
        active: false,
        information: cyclePhasesInformation[8],
      },
      {
        id: 9,
        label: "Inactive Phase",
        logo: Phase9,
        activeLogo: Phase9Active,
        active: false,
        information: cyclePhasesInformation[9],
      },
      {
        id: 10,
        label: "Progestin Only",
        logo: Phase10,
        activeLogo: Phase10Active,
        active: false,
        information: cyclePhasesInformation[10],
      },
    ]);
    showSuggestions(false);
    setSelectedPhase(0);
    setSuggestions(null);
    setSuggestionActiveTabs({
      preTraining: false,
      traning: false,
      postTraining: false,
      cycleInfo: false,
      athleticPerformance: false,
      exerciseFocus: false,
      recommendations: false,
      raceDayDuringPhase: false,
    });
  };

  const getFLFromNames = (name) => {
    const spiltName = name.split(" ");
    if (spiltName.length > 1) {
      return spiltName[0].charAt(0) + spiltName[1].charAt(0);
    } else if (spiltName.length === 1) {
      return spiltName[0].charAt(0);
    }
  };

  const convertDateFormat = (trainingDate) => {
    const date = Moment(new Date(trainingDate)).utc().format("DD.MM");
    return date;
  };

  const displayDate = (trainingDate) => {
    if (trainingDate !== "--") {
      const day = Moment(new Date(trainingDate)).utc().format("ddd");
      const date = Moment(trainingDate).utc().format("DD.MM");
      const formatedDate = `${day}, ${date}`;
      return formatedDate;
    } else {
      return trainingDate;
    }
  };

  const setPath = (id) => history.push("/dashboard/athlete?id=" + id);

  const athletesColumns = [
    {
      title: "Athlete",
      field: "athlete",
      type: "alphabet",
      replace_action: false,
      width: 220,
      sorting: true,
    },
    {
      title: "Cycle Length",
      field: "cycleLength",
      type: "number",
      replace_action: true,
      width: 100,
      sorting: true,
    },
    {
      title: "Next Training",
      field: "nextTraining",
      type: "number",
      replace_action: false,
      width: 100,
      sorting: true,
    },
    {
      title: "Check-ins",
      field: "checkins",
      type: "checkins",
      replace_action: false,
      width: 100,
      sorting: true,
    },
    {
      title: "Readiness",
      field: "readiness",
      type: "number",
      replace_action: true,
      width: 100,
      sorting: true,
    },
    { title: "", width: 32 },
  ];

  const sortList = (list, filterOn, type, method, action) => {
    if (type === "alphabet") {
      if (method === "ascending") {
        list.sort().reverse();
      } else if (method === "descending") {
        list.sort((a, b) => b[filterOn].localeCompare(a[filterOn]));
      }
    }

    if (type === "number") {
      if (method === "ascending") {
        if (action && filterOn === "cycleLength") {
          list.sort(
            (a, b) =>
              Number(a[filterOn].replace(" days", "")) -
              Number(b[filterOn].replace(" days", ""))
          );
        } else if (action && filterOn === "readiness") {
          list.sort(
            (a, b) =>
              Number(a[filterOn].replace("%", "")) -
              Number(b[filterOn].replace("%", ""))
          );
        } else {
          list.sort((a, b) => a[filterOn] - b[filterOn]);
        }
      }

      if (method === "descending") {
        if (action && filterOn === "cycleLength") {
          list.sort(
            (a, b) =>
              Number(b[filterOn].replace(" days", "")) -
              Number(a[filterOn].replace(" days", ""))
          );
        } else if (action && filterOn === "readiness") {
          list.sort(
            (a, b) =>
              Number(b[filterOn].replace("%", "")) -
              Number(a[filterOn].replace("%", ""))
          );
        } else {
          list.sort((a, b) => b[filterOn] - a[filterOn]);
        }
      }
    }

    if (type === "checkins") {
      if (method === "ascending") {
        list.sort((a, b) => b[filterOn].localeCompare(a[filterOn]));
      }
      if (method === "descending") {
        list.sort((a, b) => a[filterOn].localeCompare(b[filterOn]));
      }
    }
  };

  useEffect(() => {
    if (data_list && data_list.length > 0) {
      if (selectedPhase) {
        if (selectedPhase === 1) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "early follicular phase"
          );
          setDataSource(data_list);
        } else if (selectedPhase === 2) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "mid follicular phase"
          );
          setDataSource(data_list);
        } else if (selectedPhase === 3) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "ovulatory phase"
          );
          setDataSource(data_list);
        } else if (selectedPhase === 4) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "mid luteal phase"
          );
          setDataSource(data_list);
        } else if (selectedPhase === 5) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "late luteal phase"
          );
          setDataSource(data_list);
        } else if (selectedPhase === 6) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "perimenopause"
          );
          setDataSource(data_list);
        } else if (selectedPhase === 7) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "menopause"
          );
          setDataSource(data_list);
        } else if (selectedPhase === 8) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "active phase"
          );
          setDataSource(data_list);
        } else if (selectedPhase === 9) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "inactive phase"
          );
          setDataSource(data_list);
        } else if (selectedPhase === 10) {
          data_list = data_list.filter(
            (item) =>
              item.cyclePhase.toLowerCase().trimStart().trimEnd() ===
              "progestin-only contraceptive phase"
          );
          setDataSource(data_list);
        }
      } else {
        setDataSource(data_list);
      }
    } else {
      setDataSource([]);
    }
  }, [data_list, selectedPhase]);

  const [cyclePhases, setCyclePhases] = useState([
    {
      id: 1,
      label: "Early Follicular",
      logo: Phase1,
      activeLogo: Phase1Active,
      active: false,
      information: cyclePhasesInformation[1],
    },
    {
      id: 2,
      label: "Mid Follicular",
      logo: Phase2,
      activeLogo: Phase2Active,
      active: false,
      information: cyclePhasesInformation[2],
    },
    {
      id: 3,
      label: "Ovulatory",
      logo: Phase3,
      activeLogo: Phase3Active,
      active: false,
      information: cyclePhasesInformation[3],
    },
    {
      id: 4,
      label: "Mid Luteal",
      logo: Phase4,
      activeLogo: Phase4Active,
      active: false,
      information: cyclePhasesInformation[4],
    },
    {
      id: 5,
      label: "Late Luteal",
      logo: Phase5,
      activeLogo: Phase5Active,
      active: false,
      information: cyclePhasesInformation[5],
    },
    {
      id: 6,
      label: "Peri Menopause",
      logo: Phase6,
      activeLogo: Phase6Active,
      active: false,
      information: cyclePhasesInformation[6],
    },
    {
      id: 7,
      label: "Menopause",
      logo: Phase7,
      activeLogo: Phase7Active,
      active: false,
      information: cyclePhasesInformation[7],
    },
    {
      id: 8,
      label: "Active Phase",
      logo: Phase8,
      activeLogo: Phase8Active,
      active: false,
      information: cyclePhasesInformation[8],
    },
    {
      id: 9,
      label: "Inactive Phase",
      logo: Phase9,
      activeLogo: Phase9Active,
      active: false,
      information: cyclePhasesInformation[9],
    },
    {
      id: 10,
      label: "Progestin Only",
      logo: Phase10,
      activeLogo: Phase10Active,
      active: false,
      information: cyclePhasesInformation[10],
    },
  ]);

  const handleSuggestionButton = () => {
    if (selectedPhase) {
      showSuggestions(!suggestions);
      const phase = cyclePhases.filter((item) => item.id === selectedPhase);
      setSuggestions(phase.length > 0 ? phase[0]["information"] : null);
    } else {
      onError("Please select a phase to view suggestions");
    }
  };

  function getSelectedPhaseName() {
    const phase = cyclePhases.filter((item) => item.id === selectedPhase);
    return phase.length > 0 ? phase[0]["label"] : "";
  }

  function setTabActiveStatus(id, status) {
    let barTabsUpdatedStatus = cyclePhases.map((tab) => {
      return { ...tab, active: id === tab["id"] ? status : false };
    });
    setCyclePhases(barTabsUpdatedStatus);
    setSelectedPhase(status ? id : 0);
    const phase = cyclePhases.filter((item) => item.id === id);
    setSuggestions(phase.length > 0 ? phase[0]["information"] : null);
    showSuggestions(status);
  }

  return (
    <div className="w-100" style={{ padding: "0 32px" }}>
      {/* Table Header */}
      <div
        className="flex alignCenter justifyBetween w-100"
        style={{ height: 60, marginBottom: 20 }}
      >
        {suggestions ? (
          <p
            style={{
              cursor: "pointer",
              color: "white",
              textTransform: "uppercase",
              wordSpacing: 4,
            }}
            onClick={() => {
              resetSuggestions();
            }}
          >
            View All Athletes
          </p>
        ) : (
          <div />
        )}
        {suggestions ? (
          <div
            className="white-button"
            style={{ maxWidth: 220 }}
            onClick={handleSuggestionButton}
          >
            <p>View Suggestions</p>
          </div>
        ) : (
          <div
            className="pointer"
            style={{
              width: 220,
              background: "rgba(255, 255, 255, 0.15)",
              borderRadius: 100,
              padding: 16,
              textAlign: "center",
            }}
            onClick={handleSuggestionButton}
          >
            <p style={{ textTransform: "uppercase", wordSpacing: 4 }}>
              {" "}
              View Suggestions
            </p>
          </div>
        )}
      </div>
      {/* CYCLE PHASES TABS */}
      <div className="flex alignCenter justifyBetween w-100">
        {cyclePhases.map((phase, index) => (
          <div
            className="flex column alignCenter justifyBetween w-100 pointer"
            key={index * 65}
            onClick={() => setTabActiveStatus(index + 1, !phase["active"])}
            style={{
              height: 164,
              minWidth: 104,
              margin: "0 4px",
              padding: 16,
              border: phase["active"] ? "3px solid #08AFA8" : null,
              borderRadius: 12,
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
              background: phase["active"]
                ? "linear-gradient(142.27deg, #01213A 0%, #000000 100%)"
                : "rgba(255, 255, 255, 0.15)",
            }}
          >
            <img
              src={phase["active"] ? phase["activeLogo"] : phase["logo"]}
              alt={phase["label"]}
              height={68}
              width={68}
            />
            <br />
            <p
              style={{
                fontSize: 14,
                color: phase["active"] ? "#08AFA8" : "#FFFFFF",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              {phase["label"]}
            </p>
          </div>
        ))}
      </div>

      {/* LIST VIEW of ATHLETS */}
      <div
        className="flex column alignCenter w-100"
        style={{
          minWidth: dataSource.length > 0 ? "100%" : "100%",
          padding: "0 4px 28px",
          marginTop: 56,
        }}
      >
        {/* Columns */}
        {dataSource && dataSource.length > 0 && (
          <div
            className="flex justifyBetween alignCenter w-100"
            style={{ marginLeft: 16 }}
          >
            {athletesColumns.map((column, index) =>
              index === 0 ? (
                <div
                  className="flex justifyStart alignCenter"
                  key={index + 1}
                  style={{ minWidth: column.width }}
                >
                  <div className="flex justifyStart alignCenter">
                    <p style={{ fontSize: 13 }}>{column.title}</p>
                    {column.sorting && dataSource && dataSource.length > 0 && (
                      <div className="flex column sorting_arrows">
                        <span
                          onClick={() => {
                            sortList(
                              dataSource,
                              column.field,
                              column.type,
                              "ascending",
                              column.replace_action
                            );
                            set_ascending_index(index);
                            set_decensing_index(-1);
                            set_ascending_highlight(true);
                          }}
                        >
                          <svg
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="caret-up"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill={
                                ascending_index === index && ascending_highlight
                                  ? "#ffffff"
                                  : "#ffffff50"
                              }
                              d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                            ></path>
                          </svg>
                        </span>
                        <span
                          onClick={() => {
                            sortList(
                              dataSource,
                              column.field,
                              column.type,
                              "descending",
                              column.replace_action
                            );
                            set_ascending_index(-1);
                            set_decensing_index(index);
                            set_decensing_highlight(true);
                          }}
                        >
                          <svg
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="caret-down"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill={
                                decensing_index === index && decensing_highlight
                                  ? "#ffffff"
                                  : "#ffffff50"
                              }
                              d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className="flex justifyStart alignCenter"
                    style={{ marginLeft: 20 }}
                  >
                    <p style={{ fontSize: 13 }}>Cycle Phase</p>
                    <div className="flex column sorting_arrows">
                      <span
                        onClick={() => {
                          sortList(
                            dataSource,
                            "cyclePhase",
                            "alphabet",
                            "ascending",
                            column.replace_action
                          );
                          set_ascending_index(6);
                          set_decensing_index(-1);
                          set_ascending_highlight(true);
                        }}
                      >
                        <svg
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="caret-up"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill={
                              ascending_index === 6 && ascending_highlight
                                ? "#ffffff"
                                : "#ffffff50"
                            }
                            d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                          ></path>
                        </svg>
                      </span>
                      <span
                        onClick={() => {
                          sortList(
                            dataSource,
                            "cyclePhase",
                            "alphabet",
                            "descending",
                            column.replace_action
                          );
                          set_ascending_index(-1);
                          set_decensing_index(6);
                          set_decensing_highlight(true);
                        }}
                      >
                        <svg
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="caret-down"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill={
                              decensing_index === 6 && decensing_highlight
                                ? "#ffffff"
                                : "#ffffff50"
                            }
                            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="flex justifyStart alignCenter"
                  key={index + 1}
                  style={{ minWidth: column.width }}
                >
                  <p style={{ fontSize: 13 }}>{column.title}</p>
                  {column.sorting && dataSource && dataSource.length > 0 && (
                    <div className="flex column sorting_arrows">
                      <span
                        onClick={() => {
                          sortList(
                            dataSource,
                            column.field,
                            column.type,
                            "ascending",
                            column.replace_action
                          );
                          set_ascending_index(index);
                          set_decensing_index(-1);
                          set_ascending_highlight(true);
                        }}
                      >
                        <svg
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="caret-up"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill={
                              ascending_index === index && ascending_highlight
                                ? "#ffffff"
                                : "#ffffff50"
                            }
                            d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                          ></path>
                        </svg>
                      </span>
                      <span
                        onClick={() => {
                          sortList(
                            dataSource,
                            column.field,
                            column.type,
                            "descending",
                            column.replace_action
                          );
                          set_ascending_index(-1);
                          set_decensing_index(index);
                          set_decensing_highlight(true);
                        }}
                      >
                        <svg
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="caret-down"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill={
                              decensing_index === index && decensing_highlight
                                ? "#ffffff"
                                : "#ffffff50"
                            }
                            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        )}

        {/* Rows */}
        {dataSource &&
          dataSource.length > 0 &&
          dataSource.map((item, index) => (
            <div
              key={index + 1}
              className="flex justifyBetween athlete_list_item w-100"
              style={{ marginTop: 16 }}
              onClick={() => setPath(item.id)}
            >
              {/* Field: profile_image & name */}
              <div
                className="flex justifyStart alignCenter pointer"
                style={{ minWidth: 220, maxWidth: 220 }}
              >
                {item.profile_image &&
                item.profile_image.endsWith("default_profile_pic.png") ? (
                  <p className="name_letter">
                    {" "}
                    {getFLFromNames(item.athlete[0])}
                  </p>
                ) : (
                  <img
                    src={item.profile_image}
                    alt="overview-icon"
                    height={50}
                    width={50}
                    className="athletes-profile-image"
                  />
                )}
                {/* Field: name + cycle Phase */}
                <div
                  className="flex column justifyCenter alignStart pointer"
                  style={{ marginLeft: 20 }}
                >
                  <p className="athlete-name" style={{ marginBottom: 8 }}>
                    {item.athlete}
                  </p>
                  <p className="athlete-cycle-length" style={{ marginLeft: 0 }}>
                    {item.cyclePhase}
                  </p>
                </div>
              </div>
              {/* Field: cycle_length */}
              <div
                className="flex justifyCenter alignCenter pointer"
                style={{ minWidth: 100, maxWidth: 100 }}
              >
                <span className="athlete-cycle-length">{item.cycleLength}</span>
              </div>
              {/* next_training */}
              <div
                className="flex justifyCenter alignCenter pointer"
                style={{ minWidth: 100, maxWidth: 100 }}
              >
                {convertDateFormat(item.nextTraining) !== "Invalid Date" ? (
                  <span className="athlete-next-training">
                    {displayDate(item.nextTraining)}
                  </span>
                ) : (
                  <span className="athlete-next-training">-</span>
                )}
              </div>
              {/* Field: checkins */}
              <div
                className="flex justifyCenter alignCenter pointer"
                style={{ minWidth: 100, maxWidth: 100 }}
              >
                <span className="athlete-checkins">{item.checkins}</span>
              </div>
              {/* Field: readiness_score */}
              <div
                className="flex justifyCenter alignCenter pointer"
                style={{ minWidth: 100, maxWidth: 100 }}
              >
                <span
                  className="athlete-readiness"
                  style={{
                    borderRadius: 100,
                    height: 40,
                    /* eslint-disable no-useless-escape */
                    border:
                      Number(item["readiness"].replace(/\%/g, "")) === 0
                        ? `3px solid rgba(255, 255, 255, 0.6)`
                        : Number(item["readiness"].replace(/\%/g, "")) > 0 &&
                          Number(item["readiness"].replace(/\%/g, "")) <= 33
                        ? `3px solid #F8484E`
                        : Number(item["readiness"].replace(/\%/g, "")) > 33 &&
                          Number(item["readiness"].replace(/\%/g, "")) <= 66
                        ? `3px solid #EFE47F`
                        : Number(item["readiness"].replace(/\%/g, "")) > 65 &&
                          Number(item["readiness"].replace(/\%/g, "")) <= 100
                        ? `3px solid #70C089`
                        : null,
                    background:
                      Number(item["readiness"].replace(/\%/g, "")) === 0
                        ? "rgba(255, 255, 255, 0.2)"
                        : Number(item["readiness"].replace(/\%/g, "")) > 0 &&
                          Number(item["readiness"].replace(/\%/g, "")) <= 33
                        ? `#F8484E20`
                        : Number(item["readiness"].replace(/\%/g, "")) > 33 &&
                          Number(item["readiness"].replace(/\%/g, "")) <= 66
                        ? `#EFE47F20`
                        : Number(item["readiness"].replace(/\%/g, "")) > 66 &&
                          Number(item["readiness"].replace(/\%/g, "")) <= 100
                        ? `#70C08920`
                        : null,
                  }}
                >
                  {Number(item.readiness) === 0 ? `N/A` : item.readiness}
                </span>
              </div>
              {/* Field: forward_arrow */}
              <div
                className="flex justifyCenter alignCenter pointer"
                style={{ minWidth: 32, maxWidth: 32 }}
              >
                <img src={NextIcon} alt="next-icon" height={16} width={16} />
              </div>
            </div>
          ))}

        {/* If source is empty after searching for input entered, show this message below  */}
        {searchStatus && dataSource && dataSource.length === 0 && (
          <NoData loadingState={loadingState} searchedText={searchedText} />
        )}
      </div>

      {suggestions && (
        <div className="flex column w-100">
          <h2 style={{ color: "#FFFFFF" }}>
            Suggestions for {getSelectedPhaseName()}
          </h2>
          <div
            className="flex justifyBetween alignStart w-100"
            style={{ margin: "32px 0 56px" }}
          >
            <div
              className="flex column alignCenter justifyStart w-100"
              style={{ width: "65%" }}
            >
              {/* Pre Training */}
              <div
                className="flex column justifyStart alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 20,
                  alignSelf: "stretch",
                }}
              >
                <div
                  className="flex justifyBetween alignCenter w-100 pointer"
                  onClick={() =>
                    setSuggestionActiveTabs({
                      ...suggestionActiveTabs,
                      preTraining: !suggestionActiveTabs["preTraining"],
                    })
                  }
                >
                  <p style={{ fontSize: 16 }}>Pre-Training</p>
                  {suggestionActiveTabs["preTraining"] ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      width={24}
                      height={24}
                      className="pointer"
                    />
                  ) : (
                    <img
                      src={ArrowDown}
                      alt="arrow up icon"
                      width={16}
                      height={16}
                      className="pointer"
                    />
                  )}
                </div>
                {suggestionActiveTabs["preTraining"] && (
                  <div
                    className="flex column w-100"
                    style={{
                      margin: "16px 0 0",
                      borderTop: "1px solid #8F8F8F",
                    }}
                  >
                    {suggestionsData["preTraining"].map((item, idx) => (
                      <div
                        key={idx + 1}
                        className="flex column alignStart justifyStart w-100"
                        style={{
                          padding: 20,
                          marginTop: 16,
                          borderRadius: 8,
                          background: "rgba(255, 255, 255, 0.1)",
                        }}
                      >
                        <p style={{ fontSize: 22 }}>{item.title}</p>
                        <br />
                        {item.how && <p style={{ fontSize: 18 }}>How</p>}
                        {item.how && <p style={{ marginTop: 8 }}>{item.how}</p>}
                        {item.why && <br />}
                        {item.why && <p style={{ fontSize: 18 }}>Why</p>}
                        {item.why && <p style={{ marginTop: 8 }}>{item.why}</p>}
                        {item.why && <br />}
                        {item.description && <p>{item.description}</p>}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <br />
              {/* Training */}
              <div
                className="flex column justifyStart alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 20,
                  alignSelf: "stretch",
                }}
              >
                <div
                  className="flex justifyBetween alignCenter w-100 pointer"
                  onClick={() =>
                    setSuggestionActiveTabs({
                      ...suggestionActiveTabs,
                      traning: !suggestionActiveTabs["traning"],
                    })
                  }
                >
                  <p style={{ fontSize: 16 }}>Training</p>
                  {suggestionActiveTabs["traning"] ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      width={24}
                      height={24}
                      className="pointer"
                    />
                  ) : (
                    <img
                      src={ArrowDown}
                      alt="arrow up icon"
                      width={16}
                      height={16}
                      className="pointer"
                    />
                  )}
                </div>
                {suggestionActiveTabs["traning"] && (
                  <div
                    className="flex column w-100"
                    style={{
                      margin: "16px 0 0",
                      borderTop: "1px solid #8F8F8F",
                    }}
                  >
                    {suggestionsData["traning"].map((item, idx) => (
                      <div
                        key={idx + 1}
                        className="flex column alignStart justifyStart w-100"
                        style={{
                          padding: 20,
                          marginTop: 16,
                          borderRadius: 8,
                          background: "rgba(255, 255, 255, 0.1)",
                        }}
                      >
                        <p style={{ fontSize: 22 }}>{item.title}</p>
                        {item.description && (
                          <p style={{ marginTop: 8 }}>{item.description}</p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <br />
              {/* Post Training */}
              <div
                className="flex column justifyStart alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 20,
                  alignSelf: "stretch",
                }}
              >
                <div
                  className="flex justifyBetween alignCenter w-100 pointer"
                  onClick={() =>
                    setSuggestionActiveTabs({
                      ...suggestionActiveTabs,
                      postTraining: !suggestionActiveTabs["postTraining"],
                    })
                  }
                >
                  <p style={{ fontSize: 16 }}>Post-Training</p>
                  {suggestionActiveTabs["postTraining"] ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      width={24}
                      height={24}
                      className="pointer"
                    />
                  ) : (
                    <img
                      src={ArrowDown}
                      alt="arrow up icon"
                      width={16}
                      height={16}
                      className="pointer"
                    />
                  )}
                </div>
                {suggestionActiveTabs["postTraining"] && (
                  <div
                    className="flex column w-100"
                    style={{
                      margin: "16px 0 0",
                      borderTop: "1px solid #8F8F8F",
                    }}
                  >
                    {suggestionsData["postTraining"].map((item, idx) => (
                      <div
                        key={idx + 1}
                        className="flex column alignStart justifyStart w-100"
                        style={{
                          padding: 20,
                          marginTop: 16,
                          borderRadius: 8,
                          background: "rgba(255, 255, 255, 0.1)",
                        }}
                      >
                        <p style={{ fontSize: 22 }}>{item.title}</p>
                        {item.description && (
                          <p style={{ marginTop: 8 }}>{item.description}</p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* More Information */}
            <div
              className="flex column alignCenter justifyStart w-100"
              style={{ width: "32%" }}
            >
              <p
                className="w-100"
                style={{
                  fontSize: 22,
                  textAlign: "left",
                  marginBottom: 12,
                  marginLeft: 4,
                }}
              >
                More Information
              </p>
              {/* Cycle Info */}
              <div
                className="flex column justifyStart alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 20,
                  alignSelf: "stretch",
                }}
              >
                <div
                  className="flex justifyBetween alignCenter w-100 pointer"
                  onClick={() =>
                    setSuggestionActiveTabs({
                      ...suggestionActiveTabs,
                      cycleInfo: !suggestionActiveTabs["cycleInfo"],
                    })
                  }
                >
                  <p style={{ fontSize: 16 }}>Cycle Phase Info</p>
                  {suggestionActiveTabs["cycleInfo"] ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      width={24}
                      height={24}
                      className="pointer"
                    />
                  ) : (
                    <img
                      src={ArrowDown}
                      alt="arrow up icon"
                      width={16}
                      height={16}
                      className="pointer"
                    />
                  )}
                </div>
                {suggestionActiveTabs["cycleInfo"] && (
                  <div
                    className="flex column w-100"
                    style={{
                      margin: "16px 0 0",
                      borderTop: "1px solid #8F8F8F",
                    }}
                  >
                    {suggestionsData["cycleInfo"].map((item, idx) =>
                      item.title !== "Hormone Levels" ? (
                        <div
                          key={idx + 1}
                          className="flex alignStart justifyBetween w-100"
                          style={{
                            padding: 16,
                            marginTop: 16,
                            borderRadius: 8,
                            background: "rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <p style={{ fontSize: 16, color: "#3BF6EE" }}>
                            {item.title}
                          </p>
                          <br />
                          {item.description && <p>{item.description}</p>}
                        </div>
                      ) : (
                        <div
                          key={idx + 1}
                          className="flex column alignStart justifyStart w-100"
                          style={{
                            padding: 16,
                            marginTop: 16,
                            borderRadius: 8,
                            background: "rgba(255, 255, 255, 0.1)",
                          }}
                        >
                          <p style={{ fontSize: 16, color: "#3BF6EE" }}>
                            {item.title}
                          </p>
                          <br />
                          {item.description && <p>{item.description}</p>}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
              <br />
              {/* Impact on Athletic Performance */}
              <div
                className="flex column justifyStart alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 20,
                  alignSelf: "stretch",
                }}
              >
                <div
                  className="flex justifyBetween alignCenter w-100 pointer"
                  onClick={() =>
                    setSuggestionActiveTabs({
                      ...suggestionActiveTabs,
                      athleticPerformance:
                        !suggestionActiveTabs["athleticPerformance"],
                    })
                  }
                  style={{
                    borderBottom: suggestionActiveTabs["athleticPerformance"]
                      ? "1px solid #8F8F8F"
                      : null,
                    paddingBottom: suggestionActiveTabs["athleticPerformance"]
                      ? 16
                      : 0,
                  }}
                >
                  <p style={{ fontSize: 16 }}>
                    Impact on Athletic Performance{" "}
                  </p>
                  {suggestionActiveTabs["athleticPerformance"] ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      width={24}
                      height={24}
                      className="pointer"
                    />
                  ) : (
                    <img
                      src={ArrowDown}
                      alt="arrow up icon"
                      width={16}
                      height={16}
                      className="pointer"
                    />
                  )}
                </div>
                {suggestionActiveTabs["athleticPerformance"] && (
                  <div
                    className="flex column w-100"
                    style={{
                      margin: "16px 0 0",
                      padding: 16,
                      borderRadius: 8,
                      background: "rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    {suggestionsData["athleticPerformanceDesc"] && (
                      <p style={{ marginBottom: 16 }}>
                        {suggestionsData["athleticPerformanceDesc"]}
                      </p>
                    )}
                    {suggestionsData["athleticPerformance"].map((item, idx) => (
                      <div
                        key={idx + 1}
                        className="flex column alignStart justifyBetween w-100"
                        style={{ marginTop: idx === 0 ? 0 : 16 }}
                      >
                        <p style={{ fontSize: 16, color: "#3BF6EE" }}>
                          {item.title}
                        </p>
                        <p style={{ marginTop: 8 }}>{item.description}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <br />
              {/* Exercise Focus */}
              <div
                className="flex column justifyStart alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 20,
                  alignSelf: "stretch",
                }}
              >
                <div
                  className="flex justifyBetween alignCenter w-100 pointer"
                  onClick={() =>
                    setSuggestionActiveTabs({
                      ...suggestionActiveTabs,
                      exerciseFocus: !suggestionActiveTabs["exerciseFocus"],
                    })
                  }
                  style={{
                    borderBottom: suggestionActiveTabs["exerciseFocus"]
                      ? "1px solid #8F8F8F"
                      : null,
                    paddingBottom: suggestionActiveTabs["exerciseFocus"]
                      ? 16
                      : 0,
                  }}
                >
                  <p style={{ fontSize: 16 }}>Exercise Focus</p>
                  {suggestionActiveTabs["exerciseFocus"] ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      width={24}
                      height={24}
                      className="pointer"
                    />
                  ) : (
                    <img
                      src={ArrowDown}
                      alt="arrow up icon"
                      width={16}
                      height={16}
                      className="pointer"
                    />
                  )}
                </div>
                {suggestionActiveTabs["exerciseFocus"] && (
                  <div
                    className="flex column w-100"
                    style={{
                      margin: "16px 0 0",
                      padding: 16,
                      borderRadius: 8,
                      background: "rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    {suggestionsData["exerciseFocusDesc"] && (
                      <p style={{ marginBottom: 16 }}>
                        {suggestionsData["exerciseFocusDesc"]}
                      </p>
                    )}
                    {suggestionsData["exerciseFocus"].map((item, idx) => (
                      <div
                        key={idx + 1}
                        className="flex column alignStart justifyBetween w-100"
                        style={{ marginTop: idx === 0 ? 0 : 16 }}
                      >
                        <p style={{ fontSize: 16, color: "#3BF6EE" }}>
                          {item.title}
                        </p>
                        <p style={{ marginTop: 8 }}>{item.description}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <br />
              {/* Recommendations */}
              <div
                className="flex column justifyStart alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 20,
                  alignSelf: "stretch",
                }}
              >
                <div
                  className="flex justifyBetween alignCenter w-100 pointer"
                  onClick={() =>
                    setSuggestionActiveTabs({
                      ...suggestionActiveTabs,
                      recommendations: !suggestionActiveTabs["recommendations"],
                    })
                  }
                  style={{
                    borderBottom: suggestionActiveTabs["recommendations"]
                      ? "1px solid #8F8F8F"
                      : null,
                    paddingBottom: suggestionActiveTabs["recommendations"]
                      ? 16
                      : 0,
                  }}
                >
                  <p style={{ fontSize: 16 }}>
                    Nutrition & Supplement Recommendations
                  </p>
                  {suggestionActiveTabs["recommendations"] ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      width={24}
                      height={24}
                      className="pointer"
                    />
                  ) : (
                    <img
                      src={ArrowDown}
                      alt="arrow up icon"
                      width={16}
                      height={16}
                      className="pointer"
                    />
                  )}
                </div>
                {suggestionActiveTabs["recommendations"] && (
                  <div
                    className="flex column w-100"
                    style={{
                      margin: "16px 0 0",
                      padding: 16,
                      borderRadius: 8,
                      background: "rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    {suggestionsData["recommendations"].map((item, idx) => (
                      <div
                        key={idx + 1}
                        className="flex column alignStart justifyBetween w-100"
                        style={{ marginTop: idx === 0 ? 0 : 16 }}
                      >
                        <p style={{ fontSize: 16, color: "#3BF6EE" }}>
                          {item.title}
                        </p>
                        <p style={{ marginTop: 8 }}>{item.description}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <br />
              {/* Race Day During Phase */}
              <div
                className="flex column justifyStart alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 20,
                  alignSelf: "stretch",
                }}
              >
                <div
                  className="flex justifyBetween alignCenter w-100 pointer"
                  onClick={() =>
                    setSuggestionActiveTabs({
                      ...suggestionActiveTabs,
                      raceDayDuringPhase:
                        !suggestionActiveTabs["raceDayDuringPhase"],
                    })
                  }
                  style={{
                    borderBottom: suggestionActiveTabs["raceDayDuringPhase"]
                      ? "1px solid #8F8F8F"
                      : null,
                    paddingBottom: suggestionActiveTabs["raceDayDuringPhase"]
                      ? 16
                      : 0,
                  }}
                >
                  <p style={{ fontSize: 16 }}>Managing Race Day during phase</p>
                  {suggestionActiveTabs["raceDayDuringPhase"] ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      width={24}
                      height={24}
                      className="pointer"
                    />
                  ) : (
                    <img
                      src={ArrowDown}
                      alt="arrow up icon"
                      width={16}
                      height={16}
                      className="pointer"
                    />
                  )}
                </div>
                {suggestionActiveTabs["raceDayDuringPhase"] && (
                  <div
                    className="flex column w-100"
                    style={{
                      margin: "16px 0 0",
                      padding: 16,
                      borderRadius: 8,
                      background: "rgba(255, 255, 255, 0.1)",
                    }}
                  >
                    {suggestionsData["raceDayDuringPhaseDesc"] && (
                      <p style={{ marginBottom: 16 }}>
                        {suggestionsData["raceDayDuringPhaseDesc"]}
                      </p>
                    )}
                    {suggestionsData["raceDayDuringPhase"].map((item, idx) => (
                      <div
                        key={idx + 1}
                        className="flex column alignStart justifyBetween w-100"
                        style={{ marginTop: idx === 0 ? 0 : 16 }}
                      >
                        <p style={{ fontSize: 16, color: "#3BF6EE" }}>
                          {item.title}
                        </p>
                        <p style={{ marginTop: 8 }}>{item.description}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AthletesTable;
