import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Sidebar from "./Sidebar";
import AllSteps from "./DashboardSteps";
import { removeNotification } from "../store/app/actions";
import Notification from "./common/Notification";
import MobileAlert from "./MobileAlert";

const Dashboard = (props) => {
  const { pathname } = useSelector((state) => state.router.location);
  const { notification } = useSelector((state) => state.app);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 1400) {
      if (window.localStorage.hasOwnProperty("is_mobile")) {
        setIsMobile(window.localStorage.getItem("is_mobile") === "false" ? false : true)
      } else {
        setIsMobile(true)
      }
    } else {
      setIsMobile(false)
    }
  }
  let step = {};
  const dispatch = useDispatch();

  step = AllSteps.find((data) => data.step.path === pathname);
  if (step === undefined || step === null || !step) {
    return <Redirect to="/404" />;
  }

  const { Component } = step.step;

  useEffect(() => {
    if (window.innerWidth < 1400) {
      if (window.localStorage.hasOwnProperty("is_mobile")) {
        setIsMobile(window.localStorage.getItem("is_mobile") === "false" ? false : true)
      } else {
        setIsMobile(true)
      }
    } else {
      setIsMobile(false)
    }
    window.addEventListener("resize", handleResize)
  }, []);

  return isMobile ? <MobileAlert setMobileStatus={() => setIsMobile(false)} /> :
    <>
      <Notification {...notification} onClose={() => dispatch(removeNotification())} />
      <Sidebar {...props} />
      <div className="content">
        <Component {...props} />
      </div>
    </>
};
export default Dashboard;
