import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { initializeApp } from 'firebase/app';


const firebaseConfig = {
  apiKey: "AIzaSyDp4D_vkzYYxLN8UcQ_ObOYfAHcxdJwcrs",
  authDomain: "coach-ba0b8.firebaseapp.com",
  projectId: "coach-ba0b8",
  storageBucket: "coach-ba0b8.appspot.com",
  messagingSenderId: "868113863436",
  appId: "1:868113863436:web:da14354396876f7f52ef29",
  measurementId: "G-GFS30S7Z5M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

let actions = {
  identify: (id) => setUserId(analytics, id),
  track: (name, properties) => logEvent(analytics, name, properties),
};

export let trackGAEvent = actions;
