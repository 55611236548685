import initialState from "../initialState";
import { SET_TRAINING_PLANS, SET_ADMIN_PLAN, SET_UPLOAD_FAILED_ERROR_MESSAGE, SET_UPLOAD_FAILED_PLAN_ID, SET_SESSION_SPORT_TYPES, SAVE_SESSIONS_COUNT, SET_TRAINING_PLAN_SESSIONS, SET_SPORT_TYPES, SET_TRAINING_LEVELS, SET_TRAINING_INTENSITIES, SET_TRAINING_PLAN, SET_TRAINING_PLAN_SESSION } from "./actions";

export default function overviewReducer(
  state = initialState.app,
  { type, payload }
) {
  switch (type) {
    case SET_TRAINING_PLANS: {
      return { ...state, training_plans: payload };
    }
    case SET_UPLOAD_FAILED_ERROR_MESSAGE: {
      return { ...state, errorMessage: payload };
    }
    case SET_ADMIN_PLAN: {
      return { ...state, admin_plans: payload };
    }
    case SET_UPLOAD_FAILED_PLAN_ID: {
      return { ...state, deletePlanId: payload };
    }
    case SET_TRAINING_PLAN_SESSIONS: {
      return { ...state, training_plan_sessions: payload };
    }
    case SET_SPORT_TYPES: {
      return { ...state, sport_types: payload };
    }
    case SET_SESSION_SPORT_TYPES: {
      return { ...state, session_sport_types: payload };
    }
    case SET_TRAINING_LEVELS: {
      return { ...state, training_levels: payload };
    }
    case SET_TRAINING_INTENSITIES: {
      return { ...state, training_intensities: payload };
    }
    case SET_TRAINING_PLAN: {
      return { ...state, plan_detail: payload };
    }
    case SET_TRAINING_PLAN_SESSION: {
      return { ...state, plan_session_detail: payload };
    }
    case SAVE_SESSIONS_COUNT: {
      return { ...state, sessions_saved_count: payload };
    }
    default:
      return state;
  }
}
