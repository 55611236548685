import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// POSTER
import AlertIcon from "../../assets/icons/alerts.svg";
import NoDataPick from "../../assets/icons/no_data.svg";
// Components
import InviteAthletes from "../../components/InviteAthletes";
import PieChart from "../../components/PieChart";
import DateView from "../../components/common/DateView";
import LoadingTable from "../../components/common/LoadingTable";
import TeamsSelector from "../../components/dropdowns/TeamsSelector";
import AthletesSearch from "../../components/searches/AthletesSearch";
import AthletesTable from "../../components/tables/AthletesTable";
import CreateTeamManagement from "../management/CreateTeamManagement";

// Actions
import { history } from "../../index";
import { trackGAEvent } from "../../services/analytics/GoogleAnalytics";
import { Mixpanel } from "../../services/analytics/Mixpanel";
import { createNotification } from "../../store/app/actions";
import {
  fetchAthletes,
  setAthletes,
  setTeams,
} from "../../store/athletes/actions";
import MissingSubscriptionPage from "../../components/common/MissingSubscriptionPage";

export const colors = ["#F8484E", "#EFE47F", "#70C089", "#ffce56"];

const Athletes = () => {
  const { user } = useSelector((state) => state.app);
  const team_id = Number(window.localStorage.getItem("team_Id"));
  const { team_list, athletes, overview_details } = useSelector(
    (state) => state.athletes
  );
  const dispatch = useDispatch();
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [formatted_date, setFormattedDate] = useState();
  const [noDataScreen, showNoDataScreen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [barChartValues, setBarChartValues] = useState([]);
  const [barsEmpty, setBarsEmpty] = useState(false);
  const [pieEmpty, setPieEmpty] = useState(false);
  const [barFilteredId, setBarFilteredId] = useState(0);
  const [alertsData, setAlertsData] = useState({
    red: [],
    yellow: [],
    green: [],
  });
  const [createTeamForm, showCreateTeamForm] = useState(false);
  const [addAthletesButton, showAddAthletesButton] = useState(false);
  const [mouseOverBar, setMouseOverBar] = useState(null);

  const [checkinsCount, setCheckinsCount] = useState({
    count: 0,
    total: 0,
  });

  // COLORS
  const barsBGOpacity = 20;
  // Donut Chart Model
  const donutChartModel = [
    {
      label: "Has not checked in",
      background: "rgba(255, 255, 255, 0.6)",
      borderColor: "#ffffff",
      value: 0,
    },
    {
      label: "High Readiness",
      background: colors[2],
      borderColor: colors[2],
      value: 0,
    },
    {
      label: "Medium Readiness",
      background: colors[1],
      borderColor: colors[1],
      value: 0,
    },
    {
      label: "Low Readiness",
      background: colors[0],
      borderColor: colors[0],
      value: 0,
    },
  ];
  let [pieChartValues, setPieChartValues] = useState(donutChartModel);

  // Calendar area
  const [choosed_date, setDate] = useState();
  // Search bar
  const [isSearchActive, setSearchActive] = useState(false);
  const [searchedText, setSearchText] = useState("");
  const [searchedDataSource, setSearchedDataSource] = useState([]);
  const [showCross, setShowCross] = useState(false);
  const [showLoader, setLoader] = useState(false);

  const onFailure = (error) => {
    if (
      error ===
      "Something went wrong. You either don't have a subscription or haven't created any teams."
    ) {
      setIsSubscriptionActive(false);
    }
    setLoading(false);
    setInitLoading(false);
    dispatch(createNotification("error-toast", error));
  }; // when request failed
  const onSuccess = () => {
    setLoading(false);
    setInitLoading(false);
  }; // when request got success
  const onSuccessWithoutData = () => {
    setLoading(false);
    setInitLoading(false);
    dispatch(setTeams([]));
    showCreateTeamForm(true);
  };

  // Actions
  const fetchOverviewData = () => {
    setLoading(true);
    let has_team_id = window.localStorage.hasOwnProperty("team_Id");
    let has_date = window.localStorage.hasOwnProperty("calendar_date");
    const date = moment(new Date().toUTCString()).format("DD/MM/YYYY");
    if (has_team_id && has_date) {
      setFormattedDate(
        moment(window.localStorage.getItem("calendar_date")).format(
          "DD/MM/YYYY"
        )
      );
      setDate(window.localStorage.getItem("calendar_date"));
      dispatch(
        fetchAthletes(
          Number(window.localStorage.getItem("team_Id")),
          moment(window.localStorage.getItem("calendar_date")).format(
            "DD/MM/YYYY"
          ),
          onSuccess,
          onSuccessWithoutData,
          onFailure
        )
      );
    } else if (has_team_id && !has_date) {
      setFormattedDate(date);
      setDate(new Date().toUTCString());
      dispatch(
        fetchAthletes(
          Number(window.localStorage.getItem("team_Id")),
          date,
          onSuccess,
          onSuccessWithoutData,
          onFailure
        )
      );
    } else {
      setInitLoading(true);
      setFormattedDate(date);
      setDate(new Date().toUTCString());
      dispatch(
        fetchAthletes(null, null, onSuccess, onSuccessWithoutData, onFailure)
      );
    }
  };

  // onChange Team name
  function onTeamChange(teamId) {
    Mixpanel.identify(user.coach.email);
    Mixpanel.track("Athletes Overview", {
      coach: user.coach.seg_id,
      team: team_id,
      timestamp: moment().format("YYYY-MM-DDTHH:mm:ss"),
    });
    trackGAEvent.identify(user.coach.email);
    trackGAEvent.track("Athletes Overview", {
      coach: user.coach.seg_id,
      team: team_id,
      timestamp: moment().format("YYYY-MM-DDTHH:mm:ss"),
    });
    setLoading(true);
    dispatch(
      setAthletes({
        athletes: [],
        barChart: [],
        pieChart: [],
        barMin: 0,
        barMax: 0,
      })
    );
    dispatch(
      fetchAthletes(
        teamId,
        formatted_date,
        onSuccess,
        onSuccessWithoutData,
        onFailure
      )
    );
  }

  // Actional effects: On Fetched/Update athletes list and Other details
  useEffect(() => {
    if (athletes && athletes.length > 0) {
      setSearchedDataSource(getStructuredData(athletes));
      setAlertsData(getAletrsStructuredData(athletes));
      if (overview_details) {
        if (overview_details["barChart"]) {
          setBarChartValues(
            overview_details["barChart"].map((bar) => {
              return {
                ...bar,
                id:
                  Number(bar["readiness"]) <= 33
                    ? 1
                    : Number(bar["readiness"]) > 33 &&
                      Number(bar["readiness"]) <= 66
                    ? 2
                    : 3,
              };
            })
          );
          setBarsEmpty(overview_details["barMax"] === 0 ? true : false);
        }
        if (overview_details["pieChart"]) {
          const totalAthletes = athletes.length;
          const hasNotCheckedIn =
            (Number(overview_details["pieChart"][0]["Has not checked in"]) /
              totalAthletes) *
            100;
          const highReadinessScore =
            (Number(overview_details["pieChart"][0]["High Readiness"]) /
              totalAthletes) *
            100;
          const mediumReadinessScore =
            (Number(overview_details["pieChart"][0]["Medium Readiness"]) /
              totalAthletes) *
            100;
          const lowReadinessScore =
            (Number(overview_details["pieChart"][0]["Low Readiness"]) /
              totalAthletes) *
            100;
          setCheckinsCount({
            count:
              Number(overview_details["pieChart"][0]["High Readiness"]) +
              Number(overview_details["pieChart"][0]["Medium Readiness"]) +
              Number(overview_details["pieChart"][0]["Low Readiness"]),
            total: athletes.length,
          });
          if (
            !totalAthletes &&
            !highReadinessScore &&
            !mediumReadinessScore &&
            !lowReadinessScore
          ) {
            setPieEmpty(true);
            setPieChartValues([0, 0, 0, 0]);
          } else if (
            totalAthletes &&
            !highReadinessScore &&
            !mediumReadinessScore &&
            !lowReadinessScore
          ) {
            setPieChartValues([
              {
                label: "Has not checked in",
                background: "rgba(255, 255, 255, 0.6)",
                borderColor: "#ffffff",
                value: 99.999,
              },
              {
                label: "High Readiness",
                background: "rgba(255, 255, 255, 0.6)",
                borderColor: colors[2],
                value: 0,
              },
              {
                label: "Medium Readiness",
                background: "rgba(255, 255, 255, 0.6)",
                borderColor: colors[1],
                value: 0,
              },
              {
                label: "Low Readiness",
                background: "rgba(255, 255, 255, 0.6)",
                borderColor: colors[0],
                value: 0.001,
              },
            ]);
          } else if (
            totalAthletes &&
            highReadinessScore &&
            !mediumReadinessScore &&
            !lowReadinessScore
          ) {
            if (
              !Number(overview_details["pieChart"][0]["Has not checked in"])
            ) {
              // if high readiness is 100%
              setPieChartValues([
                {
                  label: "Has not checked in",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: "#ffffff",
                  value: 0.001,
                },
                {
                  label: "High Readiness",
                  background: "rgb(112, 192, 137)",
                  borderColor: colors[2],
                  value: 99.999,
                },
                {
                  label: "Medium Readiness",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: colors[1],
                  value: 0,
                },
                {
                  label: "Low Readiness",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: colors[0],
                  value: 0,
                },
              ]);
            } else {
              setPieChartValues([
                {
                  label: "Has not checked in",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: "#ffffff",
                  value:
                    100 -
                    highReadinessScore -
                    mediumReadinessScore -
                    lowReadinessScore,
                },
                {
                  label: "High Readiness",
                  background: colors[2],
                  borderColor: colors[2],
                  value: highReadinessScore,
                },
                {
                  label: "Medium Readiness",
                  background: colors[1],
                  borderColor: colors[1],
                  value: mediumReadinessScore,
                },
                {
                  label: "Low Readiness",
                  background: colors[0],
                  borderColor: colors[0],
                  value: lowReadinessScore,
                },
              ]);
            }
          } else if (
            totalAthletes &&
            !highReadinessScore &&
            mediumReadinessScore &&
            !lowReadinessScore
          ) {
            if (
              !Number(overview_details["pieChart"][0]["Has not checked in"])
            ) {
              // if medium readiness is 100%
              setPieChartValues([
                {
                  label: "Has not checked in",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: "#ffffff",
                  value: 0.001,
                },
                {
                  label: "High Readiness",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: colors[2],
                  value: 0,
                },
                {
                  label: "Medium Readiness",
                  background: "rgb(239, 228, 127)",
                  borderColor: colors[1],
                  value: 99.999,
                },
                {
                  label: "Low Readiness",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: colors[0],
                  value: 0,
                },
              ]);
            } else {
              setPieChartValues([
                {
                  label: "Has not checked in",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: "#ffffff",
                  value:
                    100 -
                    highReadinessScore -
                    mediumReadinessScore -
                    lowReadinessScore,
                },
                {
                  label: "High Readiness",
                  background: colors[2],
                  borderColor: colors[2],
                  value: highReadinessScore,
                },
                {
                  label: "Medium Readiness",
                  background: colors[1],
                  borderColor: colors[1],
                  value: mediumReadinessScore,
                },
                {
                  label: "Low Readiness",
                  background: colors[0],
                  borderColor: colors[0],
                  value: lowReadinessScore,
                },
              ]);
            }
          } else if (
            totalAthletes &&
            !highReadinessScore &&
            !mediumReadinessScore &&
            lowReadinessScore
          ) {
            if (
              !Number(overview_details["pieChart"][0]["Has not checked in"])
            ) {
              // if low readiness is 100%
              setPieChartValues([
                {
                  label: "Has not checked in",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: "#ffffff",
                  value: 0.001,
                },
                {
                  label: "High Readiness",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: colors[2],
                  value: 0,
                },
                {
                  label: "Medium Readiness",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: colors[1],
                  value: 0,
                },
                {
                  label: "Low Readiness",
                  background: "rgb(248, 72, 78)",
                  borderColor: colors[0],
                  value: 99.999,
                },
              ]);
            } else {
              setPieChartValues([
                {
                  label: "Has not checked in",
                  background: "rgba(255, 255, 255, 0.6)",
                  borderColor: "#ffffff",
                  value:
                    100 -
                    highReadinessScore -
                    mediumReadinessScore -
                    lowReadinessScore,
                },
                {
                  label: "High Readiness",
                  background: colors[2],
                  borderColor: colors[2],
                  value: highReadinessScore,
                },
                {
                  label: "Medium Readiness",
                  background: colors[1],
                  borderColor: colors[1],
                  value: mediumReadinessScore,
                },
                {
                  label: "Low Readiness",
                  background: colors[0],
                  borderColor: colors[0],
                  value: lowReadinessScore,
                },
              ]);
            }
          } else {
            // if all values exists
            setPieChartValues([
              {
                label: "Has not checked in",
                background: "rgba(255, 255, 255, 0.6)",
                borderColor: "#ffffff",
                value: hasNotCheckedIn,
              },
              {
                label: "High Readiness",
                background: colors[2],
                borderColor: colors[2],
                value: highReadinessScore,
              },
              {
                label: "Medium Readiness",
                background: colors[1],
                borderColor: colors[1],
                value: mediumReadinessScore,
              },
              {
                label: "Low Readiness",
                background: colors[0],
                borderColor: colors[0],
                value: lowReadinessScore,
              },
            ]);
          }
        }
      }
    } else {
      setSearchedDataSource([]);
    }
  }, [athletes, overview_details]);

  useEffect(() => {
    fetchOverviewData();
    Mixpanel.identify(user.coach.email);
    Mixpanel.track("Open Coach Team Overview", {
      coach: user.coach.seg_id,
      team: team_id,
      timestamp: moment().format("YYYY-MM-DDTHH:mm:ss"),
    });
  }, []);

  useEffect(() => {
    if (team_list && team_list.length > 0) {
      const filteredTeam = team_list.filter(
        (item) => item.pk === Number(window.localStorage.getItem("team_Id"))
      );
      if (filteredTeam && filteredTeam.length) {
        const user_email = user["coach"]["email"];
        const primary_coach_email = filteredTeam[0]["primary_coach"]["email"];
        if (user_email === primary_coach_email) {
          showAddAthletesButton(true);
        } else {
          showAddAthletesButton(false);
        }
      } else {
        showAddAthletesButton(false);
      }
    }
  }, [team_list]);

  function getAletrsStructuredData(list) {
    if (list && list.length > 0) {
      list = list.filter((item) => item.alerts.length > 0);
      let restructured_alerts_data = list.map((source, index) => {
        const max_severity = Math.max(...source.alerts.map((o) => o.severity));
        // Based on readiness score, separate the alerts into red, yellow and green colors
        return {
          key: `athlete ${index + 1} `,
          id: source.athlete.pk,
          profile_image: source.athlete.profile_img_url,
          name: `${source.athlete.first_name} ${source.athlete.last_name} `,
          border:
            Number(source["readiness"].replace(/%/g, "")) > 0 &&
            Number(source["readiness"].replace(/%/g, "")) <= 33
              ? `3px solid ${colors[0]} `
              : Number(source["readiness"].replace(/%/g, "")) > 33 &&
                Number(source["readiness"].replace(/%/g, "")) <= 66
              ? `3px solid ${colors[1]} `
              : Number(source["readiness"].replace(/%/g, "")) > 66 &&
                Number(source["readiness"].replace(/%/g, "")) <= 100
              ? `3px solid ${colors[2]} `
              : null,
          readiness_score: Number(source["readiness"].replace(/%/g, "")),
          checkinCompliance: source["check_ins"]["compliance"],
          alert:
            source.alerts && source.alerts.length > 0
              ? source.alerts.filter((item) => item.severity === max_severity)
              : null,
          strokeColor:
            Number(source["readiness"].replace(/%/g, "")) > 0 &&
            Number(source["readiness"].replace(/%/g, "")) <= 33
              ? colors[0]
              : Number(source["readiness"].replace(/%/g, "")) > 33 &&
                Number(source["readiness"].replace(/%/g, "")) <= 66
              ? colors[1]
              : Number(source["readiness"].replace(/%/g, "")) > 66 &&
                Number(source["readiness"].replace(/%/g, "")) <= 100
              ? colors[2]
              : null,
        };
      });
      restructured_alerts_data = restructured_alerts_data.filter(
        (item) => item
      );
      if (restructured_alerts_data && restructured_alerts_data.length) {
        let redAlerts = restructured_alerts_data.filter(
          (item) => item.readiness_score > 0 && item.readiness_score <= 33
        );
        let yellowAlerts = restructured_alerts_data.filter(
          (item) => item.readiness_score > 33 && item.readiness_score <= 66
        );
        let greenAlerts = restructured_alerts_data.filter(
          (item) => item.readiness_score > 66 && item.readiness_score <= 100
        );
        return { red: redAlerts, yellow: yellowAlerts, green: greenAlerts };
      } else {
        return null;
      }
    }
    return null;
  }

  function getStructuredData(list) {
    let restructured_athletes_data = list.map((source, index) => ({
      key: `athlete ${index + 1} `,
      id: source.athlete.pk,
      profile_image: source.athlete.profile_img_url,
      athlete: `${source.athlete.first_name} ${source.athlete.last_name}`,
      status: source.alerts,
      cyclePhase:
        source.cycle.phase === "Period Phase"
          ? source.cycle.display_text
          : source.cycle.phase,
      cycleLength:
        source.cycle.cycle_length !== null
          ? `${source.cycle.cycle_length} days`
          : "0 day",
      readiness:
        source.readiness && source.readiness !== "N/A"
          ? `${source.readiness} `
          : 0,
      nextTraining: source.next_training || `--`,
      checkins: `${source.check_ins.completed_checkins} /${source.check_ins.total_checkins}`,
    }));
    return restructured_athletes_data;
  }

  const disableTabColor = "#8F8F8F";

  const [barTabs, setBarTabs] = useState([
    { name: "Hold Back", color: colors[0], width: 100, id: 1, active: false },
    { name: "Caution", color: colors[1], width: 100, id: 2, active: false },
    {
      name: "Push Forward",
      color: colors[2],
      width: 130,
      id: 3,
      active: false,
    },
  ]);

  function setTabActiveStatus(id, status) {
    let barTabsUpdatedStatus = barTabs.map((tab) => {
      return { ...tab, active: id === tab["id"] ? status : false };
    });
    setBarTabs(barTabsUpdatedStatus);
    setBarFilteredId(status ? id : 0);
  }

  // Get Bars based on Filter
  function getBars(list) {
    if (barFilteredId === 1) {
      return list.filter((item) => item.id === 1);
    } else if (barFilteredId === 2) {
      return list.filter((item) => item.id === 2);
    } else if (barFilteredId === 3) {
      return list.filter((item) => item.id === 3);
    } else {
      return list;
    }
  }

  if (!isSubscriptionActive) {
    return <MissingSubscriptionPage />;
  }

  return (
    <div className="flex column relative w-100 h-100 athletes_container">
      {/* Top Header */}
      <div className="flex justifyBetween alignCenter header">
        <TeamsSelector
          isLoading={initLoading}
          teams={team_list && team_list.length > 0 ? team_list : []}
          callAction={onTeamChange}
        />
        <p>Home</p>
      </div>

      {/* Table Loading */}
      {loading && (
        <div className="flex column alignCenter justifyCenter w-100 athletes_loading_table">
          <div
            className="flex justifyBetween alignCenter w-100"
            style={{ marginBottom: 56 }}
          >
            {/* Tabs */}
            <div
              className="flex justifyBetween alignCenter"
              style={{ minWidth: 360, maxWidth: 360 }}
            >
              {barTabs.map((tab, index) => (
                <div
                  className="flex alignCenter justifyCenter"
                  key={index * 0.9}
                  style={{
                    border: `2px solid ${disableTabColor}`,
                    padding: "10px 16px",
                    borderRadius: 100,
                    minWidth: tab["width"],
                  }}
                >
                  <p
                    style={{
                      color: disableTabColor,
                      fontSize: 12,
                      textTransform: "uppercase",
                      wordSpacing: 4,
                    }}
                  >
                    {tab["name"]}
                  </p>
                </div>
              ))}
            </div>
            {/* Calendar */}
            <DateView date={choosed_date} status={false} />
            {/* Search */}
            <div style={{ minWidth: 320 }}>
              <AthletesSearch status={false} />
            </div>
          </div>
          <LoadingTable screenName="athletes" />
        </div>
      )}

      {/* Table Data */}
      {!loading && athletes && athletes.length > 0 && (
        <>
          <div
            className="flex column justifyStart alignCenter w-100 h-100"
            style={{ margin: "140px auto 0" }}
          >
            {noDataScreen ? (
              <div className="flex column alignCenter justifyCenter w-100">
                <div
                  className="flex justifyBetween alignCenter w-100"
                  style={{ margin: "16px auto 0", padding: "0 32px" }}
                >
                  {/* Tabs */}
                  <div
                    className="flex justifyBetween alignCenter"
                    style={{ minWidth: 360, maxWidth: 360 }}
                  >
                    {barTabs.map((tab, index) => (
                      <div
                        className="flex alignCenter justifyCenter"
                        key={index * 0.9}
                        style={{
                          border: `2px solid ${disableTabColor}`,
                          padding: "10px 16px",
                          borderRadius: 100,
                          minWidth: tab["width"],
                        }}
                      >
                        <p
                          style={{
                            color: disableTabColor,
                            fontSize: 12,
                            textTransform: "uppercase",
                            wordSpacing: 4,
                          }}
                        >
                          {tab["name"]}
                        </p>
                      </div>
                    ))}
                  </div>
                  {/* Calendar */}
                  <DateView
                    date={choosed_date}
                    status={true}
                    callAction={(new_date) => {
                      // Block future dates record fetching operation
                      setDate(new_date);
                      window.localStorage.setItem("calendar_date", new_date);
                      if (moment(new_date).isAfter(new Date())) {
                        showNoDataScreen(true);
                      } else {
                        setLoading(true);
                        showNoDataScreen(false);
                        dispatch(
                          setAthletes({
                            athletes: [],
                            barChart: [],
                            pieChart: [],
                            barMin: 0,
                            barMax: 0,
                          })
                        );
                        dispatch(
                          fetchAthletes(
                            window.localStorage.getItem("team_Id"),
                            moment(new_date).format("DD/MM/YYYY"),
                            onSuccess,
                            onSuccessWithoutData,
                            onFailure
                          )
                        );
                      }
                    }}
                  />
                  {/* Search */}
                  <div style={{ minWidth: 320 }}>
                    <AthletesSearch status={false} />
                  </div>
                </div>
                {/* Container */}
                <div
                  className="flex column alignCenter absolute justifyCenter w-100"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    margin: "80px auto 0",
                  }}
                >
                  <img
                    src={NoDataPick}
                    alt="no data dalaxy"
                    height={212}
                    width={288}
                  />
                  <br />
                  <h4 style={{ color: "white" }}>Ooops!</h4>
                  <p style={{ margin: "16px auto 8px", textAlign: "center" }}>
                    Time travel to the future is not possible at this moment.
                  </p>
                  <p style={{ margin: "0 auto", textAlign: "center" }}>
                    We’ll keep trying, though!
                  </p>
                  <br />
                  <div
                    className="white-button"
                    style={{ maxWidth: 220 }}
                    onClick={() => {
                      const todayDate = new Date().toUTCString();
                      setDate(todayDate);
                      showNoDataScreen(false);
                      window.localStorage.setItem("calendar_date", todayDate);
                      setFormattedDate(moment(todayDate).format("DD/MM/YYYY"));
                      setLoading(true);
                      dispatch(
                        fetchAthletes(
                          Number(window.localStorage.getItem("team_Id")),
                          moment(todayDate).format("DD/MM/YYYY"),
                          onSuccess,
                          onSuccessWithoutData,
                          onFailure
                        )
                      );
                    }}
                  >
                    <p>Get me Back Home</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex column justifyStart alignCenter w-100 h-100">
                <div
                  className="flex column justifyStart alignCenter w-100 h-100"
                  style={{
                    padding: "0 16px 0 32px",
                    marginBottom: 56,
                    width: "calc(100vw - 150px)",
                  }}
                >
                  {/* Top Header */}
                  <div className="flex justifyBetween alignStart w-100">
                    {/* Left Area*/}
                    <div
                      className="flex column justifyStart alignStart w-100 h-100"
                      style={{ margin: "16px 0 0", width: "65%" }}
                    >
                      {/* Readiness buttons + Calendar + Search */}
                      <div className="flex justifyBetween alignCenter w-100">
                        {/* Readiness Chart Tabs */}
                        <div
                          className="flex justifyBetween alignCenter"
                          style={{ minWidth: 360, maxWidth: 360 }}
                        >
                          {barTabs.map((tab, index) => (
                            <div
                              className="flex alignCenter justifyCenter pointer"
                              key={index * 0.9}
                              onClick={() =>
                                setTabActiveStatus(index + 1, !tab["active"])
                              }
                              style={{
                                background: tab["active"]
                                  ? `${tab["color"]}${barsBGOpacity}`
                                  : null,
                                border: tab["active"]
                                  ? `2px solid ${tab["color"]}`
                                  : `2px solid ${disableTabColor}`,
                                padding: "10px 16px",
                                borderRadius: 100,
                                minWidth: tab["width"],
                              }}
                            >
                              <p
                                style={{
                                  color: tab["active"]
                                    ? tab["color"]
                                    : disableTabColor,
                                  fontSize: 12,
                                  textTransform: "uppercase",
                                  wordSpacing: 4,
                                }}
                              >
                                {tab["name"]}
                              </p>
                            </div>
                          ))}
                        </div>
                        {/* Calendar */}
                        <DateView
                          date={choosed_date}
                          status={
                            athletes && athletes.length > 0 ? true : false
                          }
                          callAction={(new_date) => {
                            // Block future dates record fetching operation
                            setDate(new_date);
                            window.localStorage.setItem(
                              "calendar_date",
                              new_date
                            );
                            if (moment(new_date).isAfter(new Date())) {
                              showNoDataScreen(true);
                            } else {
                              setLoading(true);
                              dispatch(
                                setAthletes({
                                  athletes: [],
                                  barChart: [],
                                  pieChart: [],
                                  barMin: 0,
                                  barMax: 0,
                                })
                              );
                              dispatch(
                                fetchAthletes(
                                  window.localStorage.getItem("team_Id"),
                                  moment(new_date).format("DD/MM/YYYY"),
                                  onSuccess,
                                  onSuccessWithoutData,
                                  onFailure
                                )
                              );
                            }
                          }}
                        />
                        {/* Search */}
                        <div style={{ minWidth: 260 }}>
                          <AthletesSearch
                            searchFor="athletes-screen"
                            setSearch={(status) => setSearchActive(status)}
                            searchedData={(source) =>
                              setSearchedDataSource(getStructuredData(source))
                            }
                            dataSource={athletes}
                            searchedText={searchedText}
                            setText={(text) => setSearchText(text)}
                            status={
                              athletes && athletes.length > 0 ? true : false
                            }
                            showCross={showCross}
                            setCrossStatus={(status) => setShowCross(status)}
                            showLoader={showLoader}
                            setLoaderStatus={(flag) => setLoader(flag)}
                            is_margin={false}
                          />
                        </div>
                      </div>
                      {/* Bar Chart */}
                      {barsEmpty && (
                        <div
                          className="flex alignCenter justifyCenter w-100"
                          style={{
                            height: 120,
                            margin: "32px 0 -32px",
                            background: "rgba(255, 255, 255, 0.1)",
                            borderRadius: 12,
                          }}
                        >
                          <h2 style={{ color: "#FFFFFF" }}>
                            No athlete had a readiness score above 0.
                          </h2>
                        </div>
                      )}
                      {!barsEmpty && barChartValues.length > 0 && (
                        <div
                          className="scrollable_horizontal flex alignEnd justifyStart w-100"
                          style={{
                            height: 430,
                            overflowX: "scroll",
                            margin: "32px 0 0",
                          }}
                        >
                          {getBars(barChartValues).map((bar, index) => (
                            <div className="flex column alignCenter justifyEnd relative">
                              <div
                                key={index + 1}
                                style={{
                                  height: 3.2 * Number(bar["readiness"]),
                                  minWidth: 57,
                                  cursor:
                                    Number(bar["readiness"]) > 10
                                      ? "pointer"
                                      : "default",
                                  background:
                                    Number(bar["readiness"]) > 0 &&
                                    Number(bar["readiness"]) <= 33
                                      ? `${colors[0]}${barsBGOpacity}`
                                      : Number(bar["readiness"]) > 33 &&
                                        Number(bar["readiness"]) < 66
                                      ? `${colors[1]}${barsBGOpacity}`
                                      : Number(bar["readiness"]) >= 66 &&
                                        Number(bar["readiness"]) <= 100
                                      ? `${colors[2]}${barsBGOpacity}`
                                      : null,
                                  border:
                                    Number(bar["readiness"]) > 0 &&
                                    Number(bar["readiness"]) <= 33
                                      ? `3px solid ${colors[0]}`
                                      : Number(bar["readiness"]) > 33 &&
                                        Number(bar["readiness"]) < 66
                                      ? `3px solid ${colors[1]}`
                                      : Number(bar["readiness"]) >= 66 &&
                                        Number(bar["readiness"]) <= 100
                                      ? `3px solid ${colors[2]}`
                                      : null,
                                  margin: "0 8px",
                                }}
                                onClick={() =>
                                  setMouseOverBar({ ...bar, idx: index })
                                }
                              />
                              <div
                                className="flex column alignCenter justifyCenter"
                                style={{
                                  margin: "16px 0 0",
                                  maxWidth: 40,
                                  minWidth: 40,
                                }}
                              >
                                <img
                                  src={bar["athlete"]["profile_img_url"]}
                                  alt={bar["athlete"]["first_name"]}
                                  height={32}
                                  width={32}
                                  style={{
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                                <p
                                  style={{
                                    fontSize: 12,
                                    margin: "8px 0 0",
                                    textAlign: "center",
                                  }}
                                >{`${bar["athlete"]["first_name"]} ${bar["athlete"]["last_name"]}`}</p>
                              </div>

                              {mouseOverBar &&
                                mouseOverBar["athlete"]["pk"] ===
                                  bar["athlete"].pk &&
                                Number(bar["readiness"]) > 10 && (
                                  <div
                                    className="flex column justifyCenter alignStart absolute pointer"
                                    style={{
                                      width: 320,
                                      background: "rgba(255, 255, 255, 0.2)",
                                      top: 0,
                                      left:
                                        mouseOverBar["idx"] === 0
                                          ? "250%"
                                          : mouseOverBar["idx"] === 1
                                          ? "200%"
                                          : "50%",
                                      transform: "translate(-50%, 0px)",
                                      borderRadius: 12,
                                      zIndex: 10,
                                      padding: 8,
                                      backdropFilter: "blur(40.775px)",
                                      border:
                                        Number(bar["readiness"]) > 0 &&
                                        Number(bar["readiness"]) <= 33
                                          ? `3px solid ${colors[0]}`
                                          : Number(bar["readiness"]) > 33 &&
                                            Number(bar["readiness"]) <= 66
                                          ? `3px solid ${colors[1]}`
                                          : Number(bar["readiness"]) > 66 &&
                                            Number(bar["readiness"]) <= 100
                                          ? `3px solid ${colors[2]}`
                                          : null,
                                    }}
                                  >
                                    <div className="flex justifyBetween alignCenter w-100">
                                      <div
                                        className="flex alignCenter"
                                        onClick={() =>
                                          history.push(
                                            "/dashboard/athlete?id=" +
                                              mouseOverBar["athlete"]["pk"]
                                          )
                                        }
                                      >
                                        <img
                                          src={
                                            mouseOverBar["athlete"][
                                              "profile_img_url"
                                            ]
                                          }
                                          alt={
                                            mouseOverBar["athlete"][
                                              "first_name"
                                            ]
                                          }
                                          height={60}
                                          width={60}
                                          style={{
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                          }}
                                        />
                                        <div
                                          className="flex column alignStart justifyCenter"
                                          style={{ marginLeft: 16 }}
                                        >
                                          <p style={{ fontSize: 12 }}>
                                            {window.localStorage.getItem(
                                              "team_name"
                                            )}
                                          </p>
                                          <p
                                            style={{ textAlign: "center" }}
                                          >{`${mouseOverBar["athlete"]["first_name"]} ${mouseOverBar["athlete"]["last_name"]}`}</p>
                                        </div>
                                      </div>
                                      <div className="flex column alignEnd justifyEnd">
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 12 13"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          onClick={() => setMouseOverBar(null)}
                                          style={{
                                            marginTop: 8,
                                            cursor: "pointer",
                                          }}
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M6.03602 7.62385L10.9129 12.5008L11.8557 11.5579L6.97883 6.68104L11.8557 1.80414L10.9129 0.861328L6.03602 5.73823L1.15912 0.861328L0.216309 1.80414L5.09321 6.68104L0.216309 11.5579L1.15912 12.5008L6.03602 7.62385Z"
                                            fill="#FFFFFF"
                                          />
                                        </svg>
                                        <div
                                          className="flex column alignCenter justifyCenter relative progressCircle"
                                          style={{ marginTop: 8 }}
                                          onClick={() =>
                                            history.push(
                                              "/dashboard/athlete?id=" +
                                                mouseOverBar["athlete"]["pk"]
                                            )
                                          }
                                        >
                                          <div className="outer">
                                            <div className="flex alignCenter justifyCenter inter">
                                              <p style={{ color: "#FFFFFF" }}>
                                                {Number(
                                                  bar["readiness"]
                                                ).toFixed(0)}
                                              </p>
                                            </div>
                                            <svg
                                              xmlns="https://w3.org/2000/svg"
                                              version="1.1"
                                              width="80px"
                                              height="80px"
                                            >
                                              <circle
                                                cx="30"
                                                cy="30"
                                                r="25"
                                                strokeLinecap="round"
                                                style={{
                                                  stroke:
                                                    Number(bar["readiness"]) >
                                                      0 &&
                                                    Number(bar["readiness"]) <=
                                                      33
                                                      ? colors[0]
                                                      : Number(
                                                          bar["readiness"]
                                                        ) > 33 &&
                                                        Number(
                                                          bar["readiness"]
                                                        ) <= 66
                                                      ? colors[1]
                                                      : Number(
                                                          bar["readiness"]
                                                        ) > 66 &&
                                                        Number(
                                                          bar["readiness"]
                                                        ) <= 100
                                                      ? colors[2]
                                                      : null,
                                                  strokeDashoffset:
                                                    150 -
                                                    (bar["readiness"] * 150) /
                                                      100 -
                                                    2,
                                                  opacity:
                                                    150 -
                                                      (bar["readiness"] * 150) /
                                                        100 ===
                                                    150
                                                      ? 0
                                                      : 1,
                                                }}
                                              ></circle>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Pie Chart */}
                      <div
                        className="flex justifyCenter alignCenter w-100"
                        style={{ margin: "56px auto 0" }}
                      >
                        {/* Chart */}
                        <PieChart data={pieChartValues} isEmpty={pieEmpty} />
                        {/* Labels */}
                        <div
                          className="column alignCenter justifyStart"
                          style={{ marginLeft: 56 }}
                        >
                          {donutChartModel.map((item, index) => (
                            <div
                              className="flex alignCenter justifyStart"
                              style={{ margin: "12px 0", cursor: "pointer" }}
                              key={index * 0.09878}
                              onClick={() => history.push("/dashboard/alerts")}
                            >
                              <div
                                style={{
                                  background: item["background"],
                                  height: 20,
                                  width: 20,
                                  borderRadius: "50%",
                                }}
                              />
                              <p style={{ marginLeft: 16 }}>{item["label"]}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* Right Area: (Alerts) */}
                    <div
                      className="flex column justifyBetween alignStart h-100"
                      style={{
                        background: "rgba(255, 255, 255, 0.15)",
                        borderRadius: 12,
                        width: "35%",
                        marginLeft: 24,
                        padding: "32px 24px 24px",
                      }}
                    >
                      <div className="flex justifyBetween alignCenter w-100">
                        <div className="flex alignCenter">
                          <img
                            src={AlertIcon}
                            alt="alerts"
                            height={24}
                            width={24}
                          />
                          <p style={{ marginLeft: 8 }}>Alerts</p>
                        </div>
                        {alertsData && checkinsCount["total"] !== 0 && (
                          <p style={{ borderBottom: "1px solid #FFFFFF" }}>
                            {checkinsCount["count"]} of {checkinsCount["total"]}{" "}
                            athletes checked in
                          </p>
                        )}
                      </div>
                      {alertsData ? (
                        <div className="flex column alignCenter w-100">
                          {/* Red Alerts */}
                          {alertsData["red"] &&
                            alertsData["red"].length > 0 && (
                              <div
                                className="flex column alignCenter w-100"
                                style={{
                                  background: "rgba(0, 0, 0, 0.2)",
                                  padding: 12,
                                  margin: "16px 0",
                                  borderRadius: 12,
                                }}
                              >
                                {alertsData["red"].map(
                                  (redAlert, index) =>
                                    index + 1 <= 3 && (
                                      <div
                                        className="flex justifyBetween alignCenter w-100"
                                        key={index + 1}
                                        style={{
                                          border: redAlert["border"],
                                          margin: index === 0 ? 0 : "8px 0 0",
                                          borderRadius: 12,
                                          padding: 8,
                                        }}
                                      >
                                        <div className="flex alignCenter">
                                          <img
                                            src={redAlert["profile_image"]}
                                            alt="profile pick"
                                            height={60}
                                            width={60}
                                            style={{
                                              borderRadius: "50%",
                                              objectFit: "cover",
                                            }}
                                          />
                                          <div
                                            className="flex column alignStart justifyCenter"
                                            style={{ marginLeft: 16 }}
                                          >
                                            <p>
                                              {window.localStorage.getItem(
                                                "team_name"
                                              )}
                                            </p>
                                            <p style={{ margin: "0 0 8px" }}>
                                              {redAlert["name"]}
                                            </p>
                                            {redAlert["alert"] &&
                                              redAlert["alert"].length > 0 && (
                                                <p>{`${
                                                  redAlert["alert"][0][
                                                    "severity"
                                                  ] === 1
                                                    ? "Mild"
                                                    : redAlert["alert"][0][
                                                        "severity"
                                                      ] === 3
                                                    ? "Moderate"
                                                    : redAlert["alert"][0][
                                                        "severity"
                                                      ] === 5
                                                    ? "Severe"
                                                    : ""
                                                } ${
                                                  redAlert["alert"][0]["name"]
                                                }`}</p>
                                              )}
                                          </div>
                                        </div>
                                        <div className="flex column alignCenter justifyEnd">
                                          <div className="flex column alignCenter justifyCenter relative progressCircle">
                                            <div className="outer">
                                              <div className="flex alignCenter justifyCenter inter">
                                                <p style={{ color: "#FFFFFF" }}>
                                                  {redAlert[
                                                    "readiness_score"
                                                  ].toFixed(0)}
                                                </p>
                                              </div>
                                              <svg
                                                xmlns="https://w3.org/2000/svg"
                                                version="1.1"
                                                width="60px"
                                                height="60px"
                                              >
                                                <circle
                                                  cx="30"
                                                  cy="30"
                                                  r="25"
                                                  strokeLinecap="round"
                                                  style={{
                                                    stroke:
                                                      redAlert["strokeColor"],
                                                    strokeDashoffset:
                                                      150 -
                                                      (redAlert[
                                                        "readiness_score"
                                                      ] *
                                                        150) /
                                                        100 -
                                                      2,
                                                    opacity:
                                                      150 -
                                                        (redAlert[
                                                          "readiness_score"
                                                        ] *
                                                          150) /
                                                          100 ===
                                                      150
                                                        ? 0
                                                        : 1,
                                                  }}
                                                ></circle>
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                )}
                              </div>
                            )}

                          {/* Yellow Alerts */}
                          {alertsData["yellow"] &&
                            alertsData["yellow"].length > 0 && (
                              <div
                                className="flex column alignCenter w-100"
                                style={{
                                  background: "rgba(0, 0, 0, 0.2)",
                                  padding: 12,
                                  margin: "16px 0",
                                  borderRadius: 12,
                                }}
                              >
                                {alertsData["yellow"].map(
                                  (yellowAlert, index) =>
                                    index + 1 <= 2 && (
                                      <div
                                        className="flex justifyBetween alignCenter w-100"
                                        key={index + 1}
                                        style={{
                                          border: yellowAlert["border"],
                                          margin: index === 0 ? 0 : "8px 0 0",
                                          borderRadius: 12,
                                          padding: 8,
                                        }}
                                      >
                                        <div className="flex alignCenter">
                                          <img
                                            src={yellowAlert["profile_image"]}
                                            alt="profile pick"
                                            height={60}
                                            width={60}
                                            style={{
                                              borderRadius: "50%",
                                              objectFit: "cover",
                                            }}
                                          />
                                          <div
                                            className="flex column alignStart justifyCenter"
                                            style={{ marginLeft: 16 }}
                                          >
                                            <p>
                                              {window.localStorage.getItem(
                                                "team_name"
                                              )}
                                            </p>
                                            <p style={{ margin: "0 0 8px" }}>
                                              {" "}
                                              {yellowAlert["name"]}
                                            </p>
                                            {yellowAlert["alert"] &&
                                              yellowAlert["alert"].length >
                                                0 && (
                                                <p>{`${
                                                  yellowAlert["alert"][0][
                                                    "severity"
                                                  ] === 1
                                                    ? "Mild"
                                                    : yellowAlert["alert"][0][
                                                        "severity"
                                                      ] === 3
                                                    ? "Moderate"
                                                    : yellowAlert["alert"][0][
                                                        "severity"
                                                      ] === 5
                                                    ? "Severe"
                                                    : ""
                                                } ${
                                                  yellowAlert["alert"][0][
                                                    "name"
                                                  ]
                                                }`}</p>
                                              )}
                                          </div>
                                        </div>
                                        <div className="flex column alignCenter justifyEnd">
                                          <div className="flex column alignCenter justifyCenter relative progressCircle">
                                            <div className="outer">
                                              <div className="flex alignCenter justifyCenter inter">
                                                <p style={{ color: "#FFFFFF" }}>
                                                  {yellowAlert[
                                                    "readiness_score"
                                                  ].toFixed(0)}
                                                </p>
                                              </div>
                                              <svg
                                                xmlns="https://w3.org/2000/svg"
                                                version="1.1"
                                                width="80px"
                                                height="80px"
                                              >
                                                <circle
                                                  cx="30"
                                                  cy="30"
                                                  r="25"
                                                  strokeLinecap="round"
                                                  style={{
                                                    stroke:
                                                      yellowAlert[
                                                        "strokeColor"
                                                      ],
                                                    strokeDashoffset:
                                                      150 -
                                                      (yellowAlert[
                                                        "readiness_score"
                                                      ] *
                                                        150) /
                                                        100 -
                                                      2,
                                                    opacity:
                                                      150 -
                                                        (yellowAlert[
                                                          "readiness_score"
                                                        ] *
                                                          150) /
                                                          100 ===
                                                      150
                                                        ? 0
                                                        : 1,
                                                  }}
                                                ></circle>
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                )}
                              </div>
                            )}

                          {/* Green Alerts */}
                          {alertsData["green"] &&
                            alertsData["green"].length > 0 && (
                              <div
                                className="flex column alignCenter w-100"
                                style={{
                                  background: "rgba(0, 0, 0, 0.2)",
                                  padding: 12,
                                  margin: "16px 0",
                                  borderRadius: 12,
                                }}
                              >
                                {alertsData["green"].map(
                                  (greenAlert, index) =>
                                    index + 1 <= 2 && (
                                      <div
                                        className="flex justifyBetween alignCenter w-100"
                                        key={index + 1}
                                        style={{
                                          border: greenAlert["border"],
                                          margin: index === 0 ? 0 : "8px 0 0",
                                          borderRadius: 12,
                                          padding: 8,
                                        }}
                                      >
                                        <div className="flex alignCenter">
                                          <img
                                            src={greenAlert["profile_image"]}
                                            alt="profile pick"
                                            height={60}
                                            width={60}
                                            style={{
                                              borderRadius: "50%",
                                              objectFit: "cover",
                                            }}
                                          />
                                          <div
                                            className="flex column alignStart justifyCenter"
                                            style={{ marginLeft: 16 }}
                                          >
                                            <p>
                                              {window.localStorage.getItem(
                                                "team_name"
                                              )}
                                            </p>
                                            <p style={{ margin: "0 0 8px" }}>
                                              {greenAlert["name"]}
                                            </p>
                                            {greenAlert["alert"] &&
                                              greenAlert["alert"].length >
                                                0 && (
                                                <p>{`${
                                                  greenAlert["alert"][0][
                                                    "severity"
                                                  ] === 1
                                                    ? "Mild"
                                                    : greenAlert["alert"][0][
                                                        "severity"
                                                      ] === 3
                                                    ? "Moderate"
                                                    : greenAlert["alert"][0][
                                                        "severity"
                                                      ] === 5
                                                    ? "Severe"
                                                    : ""
                                                } ${
                                                  greenAlert["alert"][0]["name"]
                                                }`}</p>
                                              )}
                                          </div>
                                        </div>
                                        <div className="flex column alignCenter justifyEnd">
                                          <div className="flex column alignCenter justifyCenter relative progressCircle">
                                            <div className="outer">
                                              <div className="flex alignCenter justifyCenter inter">
                                                <p style={{ color: "#FFFFFF" }}>
                                                  {greenAlert[
                                                    "readiness_score"
                                                  ].toFixed(0)}
                                                </p>
                                              </div>
                                              <svg
                                                xmlns="https://w3.org/2000/svg"
                                                version="1.1"
                                                width="80px"
                                                height="80px"
                                              >
                                                <circle
                                                  cx="30"
                                                  cy="30"
                                                  r="25"
                                                  strokeLinecap="round"
                                                  style={{
                                                    stroke:
                                                      greenAlert["strokeColor"],
                                                    strokeDashoffset:
                                                      150 -
                                                      (greenAlert[
                                                        "readiness_score"
                                                      ] *
                                                        150) /
                                                        100 -
                                                      2,
                                                    opacity:
                                                      150 -
                                                        (greenAlert[
                                                          "readiness_score"
                                                        ] *
                                                          150) /
                                                          100 ===
                                                      150
                                                        ? 0
                                                        : 1,
                                                  }}
                                                ></circle>
                                              </svg>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div
                          className="h-100 w-100"
                          style={{ margin: "32px auto" }}
                        >
                          <p className="w-100" style={{ textAlign: "center" }}>
                            No checkin alerts exist at the moment!
                          </p>
                        </div>
                      )}
                      {alertsData &&
                        ((alertsData["red"] && alertsData["red"].length > 0) ||
                          (alertsData["yellow"] &&
                            alertsData["yellow"].length > 0) ||
                          (alertsData["green"] &&
                            alertsData["green"].length > 0)) && (
                          <p
                            className="pointer w-100"
                            onClick={() => history.push("/dashboard/alerts")}
                            style={{
                              textAlign: "center",
                              textTransform: "uppercase",
                            }}
                          >
                            See More
                          </p>
                        )}
                    </div>
                  </div>
                </div>

                {/* Table */}
                <AthletesTable
                  data_list={searchedDataSource}
                  searchStatus={isSearchActive}
                  searchedText={searchedText}
                  loadingState={loading}
                  onError={(error) =>
                    dispatch(createNotification("error-toast", error))
                  }
                />
              </div>
            )}
          </div>
        </>
      )}

      {!loading && athletes && athletes.length === 0 && !isSearchActive && (
        <div
          className="flex column alignCenter justifyCenter"
          style={{ minHeight: "100vh" }}
        >
          {showForm ? (
            <div
              className="settings-main"
              style={{ maxWidth: 520, margin: "0 auto" }}
            >
              <h3
                style={{
                  fontSize: 32,
                  lineHeight: "120%",
                  color: "inherit",
                  textAlign: "center",
                }}
              >
                Invite Athletes to {window.localStorage.getItem("team_name")}
              </h3>
              <div
                className="flex column alignCenter justifyCenter w-100"
                style={{ margin: "32px auto 0" }}
              >
                <InviteAthletes
                  teamId={
                    team_list && team_list.length > 0
                      ? Number(window.localStorage.getItem("team_Id"))
                      : 0
                  }
                  screenName="athletes"
                />
              </div>
            </div>
          ) : (
            <div className="flex column alignCenter justifyCenter">
              {team_list && team_list.length !== 0 && (
                <h1 style={{ color: "inherit" }}>
                  Hey {user["coach"].first_name}!
                </h1>
              )}
              {addAthletesButton ? (
                <div className="flex column alignCenter justifyCenter">
                  <p
                    style={{
                      margin: "24px auto",
                      width: 380,
                      textAlign: "center",
                    }}
                  >
                    Looking pretty empty over here! <br /> Let’s add some
                    athletes to your dashboard.
                  </p>
                  <div
                    className="white-button"
                    style={{ maxWidth: 220 }}
                    onClick={() => setShowForm(true)}
                  >
                    <p>Add Athletes</p>
                  </div>
                  <div
                    className="white-button"
                    style={{ maxWidth: 280, marginTop: 24 }}
                    onClick={() => {
                      window.localStorage.setItem(
                        "location",
                        JSON.stringify({ management_tab: 1 })
                      );
                      history.push("/dashboard/management");
                    }}
                  >
                    <p>See Invited Athletes</p>
                  </div>
                </div>
              ) : (
                <div className="flex">
                  {team_list && team_list.length === 0 && createTeamForm ? (
                    <CreateTeamManagement />
                  ) : (
                    <p
                      style={{
                        margin: "24px auto",
                        width: 380,
                        textAlign: "center",
                      }}
                    >
                      There are no Athletes currently on the team
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Athletes;
