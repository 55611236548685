export const FETCH_CALENDAR = "calendar/FETCH_CALENDAR";
export const FETCH_CALENDAR_SUCCESS = "calendar/FETCH_CALENDAR_SUCCESS";
export const SET_CALENDAR = "calendar/SET_CALENDAR";

export const fetchCalendar = (athleteId, startDate, endDate, onError) => ({
  type: FETCH_CALENDAR,
  payload: { athleteId, startDate, endDate, onError },
});

export const setCalendar = (calData, key) => ({
  type: SET_CALENDAR,
  payload: { calData, key },
});
