export const FETCH_MANAGEMENT_TEAM = "management/FETCH_MANAGEMENT_TEAM";
export const SET_MANAGEMENT_TEAMS = "management/SET_MANAGEMENT_TEAMS";
export const SET_MANAGEMENT_TEAM = "management/SET_MANAGEMENT_TEAM";
export const DELETE_MEMBER = "management/DELETE_MEMBER";
export const DELETE_INVITED_ATHLETE = "management/DELETE_INVITED_ATHLETE";
export const DELETE_INVITED_COACH = "management/DELETE_INVITED_COACH";

export const fetchManagementTeam = (team_id, onSuccess, onError) => ({
  type: FETCH_MANAGEMENT_TEAM,
  payload: { team_id, onSuccess, onError },
});

export const setManagementTeams = (payload) => ({
  type: SET_MANAGEMENT_TEAMS,
  payload,
});

export const setManagementTeam = (payload) => ({
  type: SET_MANAGEMENT_TEAM,
  payload,
});

export const deleteMember = (id, member_type, onSuccess, onError) => ({
  type: DELETE_MEMBER,
  payload: { id, member_type, onSuccess, onError },
});

export const deleteInvitedAthlete = (team_id, email_address, onSuccess, onError) => ({
  type: DELETE_INVITED_ATHLETE,
  payload: { team_id, email_address, onSuccess, onError },
});

export const deleteInvitedForCoach = (team_id, email_address, onSuccess, onError) => ({
  type: DELETE_INVITED_COACH,
  payload: { team_id, email_address, onSuccess, onError },
});