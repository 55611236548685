import React, { useState } from "react";
// Providers
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";
// Logo
import WildLogo from "../../assets/logo-light.png";
// Icons
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right-white.svg";

import EyeOffIcon from "../../assets/icons/eye-off.svg";
import EyeIcon from "../../assets/icons/eye.svg";
// Components
import Popup403 from "../../components/Popup403";
import ResendVerificationPopup from "../../components/ResendVerificationPopup";
import ErrorDialog from "../../components/common/ErrorDialog";
// Actions
import { validateEmail } from "../../_helpers/validation";
import api from "../../services/api/ApiService";
import { signup } from "../../store/app/actions";

const SignUp = () => {
  const dispatch = useDispatch();
  // Redux values
  // States
  const [verified_captcha, set_verified_captcha] = useState(false);
  const [show_resend, setShowResend] = useState(false);
  const [popup403, setShowPopup403] = useState(false);
  const [signup_values, setSignupValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    show_password: false,
    checked: false,
  });

  const [submitting, setSubmitting] = useState({ signup_submit: false });

  // Error handlers
  let [error_toast, setErrorToast] = useState({
    type: "",
    status: false,
    message: "",
  });

  // Form submitting actions
  // on resend popup
  const onShowResendPopup = () => {
    setSubmitting({ ...submitting, signup_submit: false });
    setShowResend(true);
  };
  const showPpup403Message = () => {
    setSubmitting({ ...submitting, signup_submit: false });
    setShowPopup403(true);
  };

  // on failure
  const onFinishFailed = (error) => {
    setSubmitting({ ...submitting, signup_submit: false });
    setErrorToast({
      type: "error-toast",
      status: true,
      message: error,
    });
  };
  // on success
  const onFinishSuccess = () => {
    setSubmitting({ ...submitting, signup_submit: false });
    setSignupValues({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      show_password: false,
      checked: false,
    });
  };
  // on resend fail
  const onFinishResendFail = (error) => {
    setShowResend(false);
    setErrorToast({
      type: "error-toast",
      status: true,
      message: error,
    });
  };
  // on resend success
  const onFinishResendSuccess = () => {
    setShowResend(false);
    setErrorToast({
      type: "success-toast",
      status: true,
      message: `Email sent. Please check your inbox, including junk folders!`,
    });
  };
  // sign up submit
  const onFinishSignup = async () => {
    if (verified_captcha) {
      if (signup_values["first_name"] === "") {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message: "Please enter first name to continue.",
        });
        return;
      } else if (signup_values["last_name"] === "") {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message: "Please enter last name to continue.",
        });
        return;
      } else if (
        signup_values["email"] === "" &&
        signup_values["password"] === ""
      ) {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message: "Please enter email address and password to continue.",
        });
        return;
      } else if (!validateEmail(signup_values["email"])) {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message: "Please enter correct email address to continue.",
        });
        return;
      } else if (signup_values["password"].length < 6) {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message: "Please enter password of minimum 6 characters to continue.",
        });
        return;
      } else if (
        signup_values["password"] !== signup_values["confirm_password"]
      ) {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message: "The two passwords, you entered do not match.",
        });
        return;
      } else if (!signup_values["checked"]) {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message:
            "Please read and accept our Terms of service and privacy policy.",
        });
        return;
      } else {
        setSubmitting({ ...submitting, signup_submit: true });
        dispatch(
          signup(
            signup_values,
            onFinishSuccess,
            onFinishFailed,
            onShowResendPopup,
            showPpup403Message
          )
        );
      }
    }
  };
  // Actions
  const handleVerify = async (token) => {
    if (token !== null && token !== undefined) {
      if (!verified_captcha) {
        const response = await api.post(`/api/captcha_request`, {
          recaptcha: token,
          version: "v3",
        });
        if (response.status === 200) {
          set_verified_captcha(response.data.success);
        }
      }
    }
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <GoogleReCaptcha onVerify={handleVerify} />
      <div className="flex w-100vw h-100">
        <div
          className="flex column justifyCenter alignCenter form_container relative"
          style={{ padding: "56px 0" }}
        >
          {!verified_captcha && (
            <div
              className="flex column justifyCenter alignCenter h-100 w-100"
              style={{
                zIndex: 100,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%",
              }}
            >
              <div className="custom_placeholder_loader" />
            </div>
          )}

          <a
            href={process.env.REACT_APP_WEBSITE_URL}
            className="flex alignCenter justifyStart w-100"
            style={{
              marginBottom: 32,
              textDecoration: "none",
              maxWidth: "90%",
            }}
          >
            <img
              className="pointer"
              src={ArrowLeft}
              alt="arrow-left"
              height={20}
              width={20}
              style={{ marginRight: 16 }}
            />
            <p style={{ color: "rgb(255, 255, 255)" }}>
              Back to Wild’s homepage
            </p>
          </a>

          <img src={WildLogo} alt="wild.ai_logo" width={107} height={124} />
          <h2 className="flex alignCenter label">Coach Sign up</h2>

          {/* Signup Form */}
          <div
            className="input_form"
            style={{ opacity: !verified_captcha ? 0.2 : 1, maxWidth: 400 }}
          >
            {/* Field: first_name  + last_name*/}
            <div className="flex justifyBetween alignStart w-100">
              <div className="flex column justifyStart alignStart w-100">
                <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                  <input
                    type="text"
                    value={signup_values["first_name"]}
                    required
                    placeholder="First name"
                    className="settings-value"
                    onKeyUp={(event) => {
                      if (event.keyCode === 13) {
                        setSignupValues({
                          ...signup_values,
                          first_name: event.target.value.trim(),
                        });
                        onFinishSignup();
                      }
                    }}
                    onChange={(event) => {
                      if (event.target.value.length > 0) {
                        setSignupValues({
                          ...signup_values,
                          first_name: event.target.value.trim(),
                        });
                      } else {
                        setSignupValues({ ...signup_values, first_name: "" });
                      }
                    }}
                  />
                </div>
              </div>
              {/* gap */}
              <div style={{ width: 32 }} />
              <div className="flex column justifyStart alignStart w-100">
                <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                  <input
                    type="text"
                    value={signup_values["last_name"]}
                    required
                    placeholder="Last name"
                    className="settings-value"
                    onKeyUp={(event) => {
                      if (event.keyCode === 13) {
                        setSignupValues({
                          ...signup_values,
                          last_name: event.target.value.trim(),
                        });
                        onFinishSignup();
                      }
                    }}
                    onChange={(event) => {
                      if (event.target.value.length > 0) {
                        setSignupValues({
                          ...signup_values,
                          last_name: event.target.value.trim(),
                        });
                      } else {
                        setSignupValues({ ...signup_values, last_name: "" });
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {/* email */}
            <div className="settings-form-item border-bottom">
              <input
                type="email"
                placeholder="Email"
                className="settings-value"
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    setSignupValues({
                      ...signup_values,
                      email: event.target.value.trim(),
                    });
                    onFinishSignup();
                  }
                }}
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    setSignupValues({
                      ...signup_values,
                      email: event.target.value.trim(),
                    });
                  } else {
                    setSignupValues({ ...signup_values, email: "" });
                  }
                }}
              />
            </div>
            {/* password */}
            <div className="settings-form-item border-bottom">
              <input
                type={signup_values["show_password"] ? "text" : "password"}
                placeholder="Password"
                className="settings-value password"
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    setSignupValues({
                      ...signup_values,
                      password: event.target.value.trim(),
                    });
                    onFinishSignup();
                  }
                }}
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    setSignupValues({
                      ...signup_values,
                      password: event.target.value.trim(),
                    });
                  } else {
                    setSignupValues({ ...signup_values, password: "" });
                  }
                }}
              />
              {signup_values["show_password"] ? (
                <img
                  className="absolute"
                  src={EyeIcon}
                  alt="eye_icon"
                  height={18}
                  width={18}
                  onClick={() =>
                    setSignupValues({
                      ...signup_values,
                      show_password: !signup_values["show_password"],
                    })
                  }
                  style={{ right: 0, bottom: 12 }}
                />
              ) : (
                <img
                  className="absolute"
                  src={EyeOffIcon}
                  alt="eye_icon"
                  height={18}
                  width={18}
                  onClick={() =>
                    setSignupValues({
                      ...signup_values,
                      show_password: !signup_values["show_password"],
                    })
                  }
                  style={{ right: 0, bottom: 12 }}
                />
              )}
            </div>
            {/* confirm_password */}
            <div className="settings-form-item border-bottom">
              <input
                type={signup_values["show_password"] ? "text" : "password"}
                placeholder="Confirm password"
                className="settings-value password"
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    setSignupValues({
                      ...signup_values,
                      confirm_password: event.target.value.trim(),
                    });
                    onFinishSignup();
                  }
                }}
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    setSignupValues({
                      ...signup_values,
                      confirm_password: event.target.value.trim(),
                    });
                  } else {
                    setSignupValues({ ...signup_values, confirm_password: "" });
                  }
                }}
              />
            </div>
            {/* Accept checkbox */}
            <div
              className="flex justifyStart alignStart w-100"
              style={{ marginTop: 24 }}
            >
              <input
                type="checkbox"
                checked={signup_values["checked"]}
                onChange={() =>
                  setSignupValues({
                    ...signup_values,
                    checked: !signup_values["checked"],
                  })
                }
                style={{
                  minHeight: 28,
                  height: 28,
                  maxHeight: 28,
                  minWidth: 28,
                  width: 20,
                  maxWidth: 20,
                }}
              />
              <p
                className={
                  signup_values["checked"]
                    ? "small-decription"
                    : "small-decription-disabled"
                }
              >
                {`Check here to indicate that you have read and agree to our `}
                <span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit" }}
                    href="https://wild.ai/terms-of-services"
                  >
                    Terms of service
                  </a>
                </span>
                {` and `}
                <span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit" }}
                    href="https://wild.ai/privacy-policy"
                  >
                    Privacy policy
                  </a>
                </span>
              </p>
            </div>

            <div
              onClick={() => onFinishSignup()}
              className="white-button"
              style={{ marginTop: 24 }}
            >
              {submitting["signup_submit"] ? (
                <div className="flex justifyCenter alignCenter">
                  <div className="custom_button_loader" />
                </div>
              ) : (
                <p
                  style={{
                    margin: 0,
                    color: "#ffffff",
                    fontSize: 12,
                    maxWidth: 320,
                    textTransform: "uppercase",
                    lineHeight: "120%",
                    cursor: "pointer",
                  }}
                >
                  Sign Up
                </p>
              )}
            </div>

            <div
              className="flex column w-100"
              style={{ maxWidth: 336, margin: "32px 0 0", textAlign: "center" }}
            >
              <p className="sign-link">Already have an account?</p>
              <p
                className="pointer"
                onClick={() => (window.location.href = "/login")}
                style={{ textDecoration: "underline" }}
              >
                {" "}
                Sign in
              </p>
            </div>

            <div
              className="flex justifyCenter alignCenter w-100"
              style={{ maxWidth: 336, margin: "32px 0 0", textAlign: "center" }}
            >
              <p className="small-decription">
                <span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit" }}
                    href="https://wild.ai/terms-of-services"
                  >
                    Terms of service
                  </a>
                </span>
                {` | `}
                <span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit" }}
                    href="https://wild.ai/privacy-policy"
                  >
                    Privacy policy
                  </a>
                </span>
              </p>
            </div>
          </div>

          {/* Error Dialog */}
          <ErrorDialog
            open={error_toast["status"]}
            type={error_toast["type"]}
            message={error_toast["message"]}
            onClose={() =>
              setErrorToast({
                type: "",
                status: false,
                message: "",
              })
            }
          />
        </div>

        <div className="flex column justifyCenter alignCenter w-100 desktop">
          <img
            src="https://wild-blog-strapi.s3.amazonaws.com/image_72_e4e5bd6e8c.jpg?610431.8000000007"
            alt="wild.ai_poster"
            style={{
              width: "50vw",
              height: "100%",
              minHeight: "100vh",
              objectFit: "cover",
              opacity: 0.35,
            }}
          />
          <div className="absolute">
            <p
              style={{
                textTransform: "uppercase",
                fontSize: "24px",
                width: "400px",
                fontWeight: 600,
              }}
            >
              Why you'll love training your athletes with wild.ai
            </p>
            <div
              className="flex column"
              style={{ gap: "10px", marginTop: "16px" }}
            >
              <div className="flex row alignCenter">
                <img
                  src={ArrowRight}
                  alt="arrow-right"
                  height={20}
                  width={20}
                  style={{ marginRight: 16 }}
                />
                <p style={{ fontSize: "16px" }}>
                  See your athletes by cycle phase
                </p>
              </div>
              <div className="flex row alignCenter">
                <img
                  src={ArrowRight}
                  alt="arrow-right"
                  height={20}
                  width={20}
                  style={{ marginRight: 16 }}
                />
                <p style={{ fontSize: "16px" }}>
                  Analyze your athlete's data over time
                </p>
              </div>
              <div className="flex row alignCenter">
                <img
                  src={ArrowRight}
                  alt="arrow-right"
                  height={20}
                  width={20}
                  style={{ marginRight: 16 }}
                />
                <p style={{ fontSize: "16px" }}>
                  Predict their hormonal cycles
                </p>
              </div>
              <div className="flex row alignCenter">
                <img
                  src={ArrowRight}
                  alt="arrow-right"
                  height={20}
                  width={20}
                  style={{ marginRight: 16 }}
                />
                <p style={{ fontSize: "16px" }}>
                  Receive tailored recommendations
                </p>
              </div>
              <div className="flex row alignCenter">
                <img
                  src={ArrowRight}
                  alt="arrow-right"
                  height={20}
                  width={20}
                  style={{ marginRight: 16 }}
                />
                <p style={{ fontSize: "16px" }}>
                  Remind your athletes to check-in
                </p>
              </div>
            </div>
          </div>
        </div>

        <ResendVerificationPopup
          show={show_resend}
          onCancel={() => setShowResend(false)}
          onError={(message) => onFinishResendFail(message)}
          onSuccess={() => onFinishResendSuccess()}
        />

        <Popup403 show={popup403} onCancel={() => setShowPopup403(false)} />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default SignUp;
