import React, { useEffect, useState } from "react";
import "./Billing.css";
import InvoiceTable from "./InvoiceTable";
import LeftArrow from "../../assets/icons/chevron-left-light.svg";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import PricingPopup from "./PricingPopup";
import api from "../../services/api/ApiService";
import { getErrorMessage } from "../../_helpers/Requests";
import Slider from "./Slider";
import Loading from "../../components/ui/Loading";
import ContactFormPopup from "./ContactFormPopup";
import moment from "moment";
import SingleInvoice from "./SingleInvoice";

const Billing = () => {
  const [singleInvoiceScreen, setSingleInvoiceScreen] = useState({
    show: false,
    id: "",
  });
  const [showPricingPopup, setShowPricingPopup] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showContactFormPopup, setShowContactFormPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const subscriptionDetailsInitialState = {
    coachSince: "",
    nextInvoice: "",
    athleteSeats: 0,
    coachSeats: 0,
    betaSeats: 0,
  };
  const paymentInformationInitialState = {
    last4digits: 0,
    expirationDate: "",
    billingStreet: "",
    postalCode: 0,
    billingCity: "",
    url: "",
  };

  const [subscriptionDetails, setSubsriptionDetails] = useState(
    subscriptionDetailsInitialState
  );
  const [paymentInformation, setPaymentInformation] = useState(
    paymentInformationInitialState
  );
  const [invoices, setInvoices] = useState([]);
  const [detailedInvoices, setDetailedInvoices] = useState([]);

  const hidePopup = () => {
    if (showPricingPopup) {
      setShowPricingPopup(false);
    }
    if (showContactFormPopup) {
      setShowContactFormPopup(false);
    }
  };

  const getSubscription = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/coach/subscription/details`);
      if (response["status"] === 200) {
        if (response["data"]) {
          setLoading(false);
          setDetailedInvoices(response.data.invoices);
          const arrayOfInvoices = response.data.invoices.map((invoice) => {
            return {
              invoiceNumber: invoice.number,
              id: invoice.id,
              date: moment.unix(invoice.created).format("YYYY MMM DD"),
              amount: invoice.amount_paid,
              status: invoice.status,
              downloadLink: invoice.invoice_pdf,
              currency: invoice.currency,
            };
          });
          setInvoices(arrayOfInvoices);
          setSubsriptionDetails({
            ...subscriptionDetails,
            coachSince: response.data.coach.created,
            athleteSeats: response.data.wild_subscription_details.athlete_seats,
            coachSeats: response.data.wild_subscription_details.coach_seats,
            betaSeats: response.data.wild_subscription_details.beta_seats,
            subscriptionId: response.data.wild_subscription_details.id,
            nextInvoice:
              response.data.stripe_subscription_details.billing_cycle_anchor,
          });
          setPaymentInformation({
            ...paymentInformation,
            last4digits:
              response.data.payment_details.payment_card_details.last4,
            expirationDate: `${response.data.payment_details.payment_card_details.exp_month} ${response.data.payment_details.payment_card_details.exp_year}`, // TODO: Format this in the right way
            billingStreet:
              response.data.payment_details.billing_details.address.line1,
            postalCode:
              response.data.payment_details.billing_details.address.postal_code,
            billingCity:
              response.data.payment_details.billing_details.address.city,
            url: response.data.subscription_management_portal_url,
            email: response.data.payment_details.billing_details.email,
            name: response.data.payment_details.billing_details.name,
          });
        }
      } else {
        getErrorMessage(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  if (loading) {
    return (
      <div id="billing-page">
        <Loading />
      </div>
    );
  }

  return (
    <div id="billing-page" className={showPricingPopup && "popup-overlay"}>
      {/* <div className="header-container flex justifyBetween alignCenter">
        <div className="flex alignCenter" style={{ gap: "10px" }}>
          <img
            src={LeftArrow}
            alt="chevron-left"
            height={32}
            width={32}
            className="pointer"
          />
          <span style={{ fontSize: "32px" }}>Account Billing</span>
        </div>
        <Breadcrumbs
          pagesNames={["Home", "Account", "Billing"]}
          fontSize="18px"
        />
      </div> */}
      {singleInvoiceScreen.show ? (
        <SingleInvoice
          goBack={() => setSingleInvoiceScreen({ show: false, id: "" })}
          invoiceDetails={detailedInvoices.find(
            (invoice) => invoice.id === singleInvoiceScreen.id
          )}
          paymentInformation={paymentInformation}
        />
      ) : (
        <>
          <div
            className="billing-container flex"
            style={{ marginBottom: "32px" }}
          >
            <div className="cards-wrapper">
              <span className="headline">Subscription Details</span>
              <div className="cards-container">
                {/* Card */}
                <div className="card">
                  <div
                    className="flex row"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      Coach Since
                    </span>
                    <span>
                      {subscriptionDetails.coachSince
                        ? moment(subscriptionDetails.coachSince).format(
                            "MMM DD YYYY"
                          )
                        : "Unknown"}
                    </span>
                  </div>
                  <div className="content-divider" />
                  <div
                    className="flex row"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      Next Invoice
                    </span>
                    <span>
                      {invoices.length > 0
                        ? moment(invoices[invoices.length - 1].date)
                            .add(1, "months")
                            .format("MMM DD YYYY")
                        : "Unknown"}
                    </span>
                  </div>
                </div>
                {/* Card */}
                {/* Card */}
                <div className="card">
                  <div
                    className="flex row alignCenter"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      Athlete Seats:
                    </span>
                    <span>{subscriptionDetails.athleteSeats}</span>
                    <div
                      className="white-button upgrade-btn"
                      onClick={() => setShowPricingPopup(true)}
                    >
                      Add Seats
                    </div>
                  </div>
                  <div className="content-divider" />
                  <div
                    className="flex row"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      Coach Seats:
                    </span>
                    <span>{subscriptionDetails.coachSeats}</span>
                  </div>
                  <div className="content-divider" />
                  <div
                    className="flex row"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      Beta Seats:
                    </span>
                    <span>{subscriptionDetails.betaSeats}</span>
                  </div>
                </div>
                {/* Card */}
              </div>
            </div>
            <div className="vertical-divider" />
            <div className="cards-wrapper">
              <span className="headline">Payment Information</span>
              <div className="cards-container">
                {/* Card */}
                <div className="card">
                  <div
                    className="flex row"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      Payment Method
                    </span>
                  </div>
                  <div className="content-divider" />
                  <div
                    className="flex row"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      {paymentInformation.last4digits
                        ? `💳 •••• •••• •••• ${paymentInformation.last4digits}`
                        : "No payment method set"}
                    </span>
                  </div>
                  <div
                    className="flex row justifyBetween"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      {paymentInformation.expirationDate &&
                        `Expires in ${paymentInformation.expirationDate}`}
                    </span>
                    <a
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        textDecoration: "underline",
                        color: "white",
                      }}
                      className="pointer"
                      href={paymentInformation.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Edit
                    </a>
                  </div>
                </div>
                {/* Card */}
                {/* Card */}
                <div className="card">
                  <div
                    className="flex row"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      Billing Address
                    </span>
                  </div>
                  <div className="content-divider" />
                  <div
                    className="flex row"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      {paymentInformation.billingStreet
                        ? paymentInformation.billingStreet
                        : "No billing address set"}
                    </span>
                  </div>
                  <div
                    className="flex row justifyBetween"
                    style={{ gap: "16px", padding: "8px 16px" }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      {paymentInformation.postalCode
                        ? paymentInformation.postalCode
                        : ""}
                      {paymentInformation.billingCity
                        ? paymentInformation.billingCity
                        : ""}
                    </span>
                    <a
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        textDecoration: "underline",
                        color: "white",
                      }}
                      className="pointer"
                      href={paymentInformation.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Edit
                    </a>
                  </div>
                </div>
                {/* Card */}
              </div>
            </div>
          </div>
          <span className="headline">Invoices</span>
          <InvoiceTable
            data={invoices}
            selectedInvoices={selectedInvoices}
            setSelectedInvoices={setSelectedInvoices}
            showInvoiceDetails={(id) =>
              setSingleInvoiceScreen({ show: true, id })
            }
          />
          {/* {selectedInvoices.length > 0 && (
        <div
          className="transparent-button"
          onClick={handleDownloadClick}
          style={{
            width: "10%",
            textTransform: "none",
            letterSpacing: "0",
            marginTop: "10px",
          }}
        >
          Download
          <img
            src={DownloadIcon}
            alt="download-icon"
            height={24}
            width={24}
            className="pointer"
          />
        </div>
      )} */}
          <div
            className={
              showPricingPopup || showContactFormPopup ? `popup-overlay` : ""
            }
            onClick={hidePopup}
          >
            <PricingPopup
              show={showPricingPopup}
              hide={() => setShowPricingPopup(false)}
              initialAthletesNumber={subscriptionDetails.athleteSeats}
              initialCoachesNumber={subscriptionDetails.coachSeats}
              initialBetaSeatsNumber={subscriptionDetails.betaSeats}
              showContactFormPopup={() => setShowContactFormPopup(true)}
              subscriptionId={subscriptionDetails.subscriptionId}
              onSuccess={getSubscription}
            />
            <ContactFormPopup
              show={showContactFormPopup}
              hide={() => setShowContactFormPopup(false)}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default Billing;
