import React, { useEffect, useState } from "react";
import api from "../../services/api/ApiService";
import "./ExercisePopup.css";
import Dropdown from "../../components/ui/Dropdown";
import { useDispatch } from "react-redux";
import { createNotification } from "../../store/app/actions";

const ExercisePopup = ({
  show,
  hide,
  initialName,
  isExerciseEdit,
  editExerciseInitialValues,
  onExerciseAdd,
  parentModuleId,
  isNewExercise = false,
  onNewExerciseAdd,
  onExerciseEdit,
}) => {
  const detailsInitialState = {
    name: "",
    reps: 0,
    intensity: "",
    duration: 0,
  };
  const dispatch = useDispatch();
  const onError = (error) => dispatch(createNotification("error-toast", error));

  const [details, setDetails] = useState(detailsInitialState);
  const [newExercise, setNewExercise] = useState("");
  const [exerciseType, setExerciseType] = useState("reps");
  const isRest = details.name === "Rest";
  const submitNewExercise = async () => {
    if (newExercise) {
      try {
        const { data } = await api.post("training-sessions/exercise-library", {
          name: newExercise,
        });
        if (data) {
          dispatch(
            createNotification(
              "success-toast",
              "New exercise has been added successfully."
            )
          );
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          onError(
            "This route is not exist. please contact with support@wild.ai"
          );
        } else {
          if (err.response) {
            const {
              data: { details },
            } = err.response;
            onError(details);
          }
        }
      }
    }
  };

  const onDetailChange = (detail, value) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [detail]: value,
    }));
  };

  const onExerciseTypeChange = (prevType, newType) => {
    setExerciseType(newType);
    const updatedDetails = { ...details, [newType]: 0 };
    delete updatedDetails[prevType];
    setDetails(updatedDetails);
  };

  const addExercise = () => {
    let exercise = {};
    if (details.name === "Rest") {
      exercise = { name: details.name, duration: details.duration };
    } else {
      exercise = details;
    }
    onExerciseAdd(parentModuleId, exercise);
    setDetails(detailsInitialState);
    hide();
  };

  const editExercise = () => {
    onExerciseEdit(parentModuleId, details);
    setExerciseType("reps");
    setDetails(detailsInitialState);
    hide();
  };

  const addNewExercise = () => {
    submitNewExercise().then(() => {
      onNewExerciseAdd(newExercise);
      setNewExercise("");
      hide();
    });
  };

  useEffect(() => {
    setDetails({
      ...details,
      name: initialName,
    });
  }, [initialName]);

  useEffect(() => {
    setDetails({
      ...details,
      duration: details.duration,
    });
  }, [details.duration]);

  useEffect(() => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      [exerciseType]: 0,
    }));
  }, [exerciseType]);

  useEffect(() => {
    if (isExerciseEdit) {
      setDetails(editExerciseInitialValues);
    }
  }, [isExerciseEdit, editExerciseInitialValues]);

  const disableAddNewExercise = !newExercise;

  const disabledAddExercise = () => {
    if (isRest) {
      return !details.name || !details.duration;
    } else {
      return (
        !details.name ||
        (!details.reps && !details.laps && !details.duration) ||
        !details.intensity ||
        !details.duration
      );
    }
  };

  useEffect(() => {
    if (details.name === "Rest") {
      onExerciseTypeChange(exerciseType, "duration");
      setDetails({ ...details, name: "Rest" });
    }
  }, [details.name]);

  if (!show) {
    return <></>;
  }

  return (
    <div
      className="sessionBuilder_popupContainer"
      onClick={(e) => e.stopPropagation()}
    >
      {isNewExercise ? (
        <div
          className="flex column alignEnd justifyEnd"
          style={{ gap: "24px", padding: "16px 0" }}
        >
          <input
            type="text"
            placeholder="Exercise Name"
            className="sesionBuilder_exerciseInput"
            onChange={(e) => setNewExercise(e.target.value)}
          />
          <div
            className={`white-button ${disableAddNewExercise && "disabledDiv"}`}
            style={{
              fontWeight: 600,
              width: "258px",
              height: "40px",
              border: "none",
            }}
            onClick={(e) => {
              disableAddNewExercise ? e.stopPropagation() : addNewExercise();
            }}
          >
            <p>Save Exercise</p>
          </div>
        </div>
      ) : (
        <div
          className="flex column alignEnd"
          style={{ padding: "16px 0", gap: "24px" }}
        >
          <input
            type="text"
            className="sesionBuilder_exerciseInput"
            value={details.name ? details.name : initialName}
            disabled
          />
          <div className="flex w-100 alignCenter" style={{ gap: "24px" }}>
            <Dropdown
              options={isRest ? ["duration"] : ["reps", "duration", "laps"]}
              selectedOption={isRest ? "duration" : exerciseType}
              onOptionChange={(value) => {
                onExerciseTypeChange(exerciseType, value);
              }}
            />
            <input
              type="number"
              className="sesionBuilder_exerciseInput"
              onChange={(e) =>
                onDetailChange(exerciseType, parseInt(e.target.value))
              }
              value={details[exerciseType]}
            />
          </div>
          {!isRest && (
            <>
              <Dropdown
                options={["Low", "Medium", "High", "All out", "N/A"]}
                defaultTitle="Intensity"
                selectedOption={details.intensity}
                onOptionChange={(value) => onDetailChange("intensity", value)}
              />
              {exerciseType !== "duration" && (
                <input
                  type="number"
                  className="sesionBuilder_exerciseInput"
                  onChange={(e) =>
                    onDetailChange("duration", parseInt(e.target.value))
                  }
                  placeholder="Estimated duration for the whole exercise"
                  value={details.duration ? details.duration : ""}
                />
              )}
            </>
          )}
          <div
            className={`white-button ${disabledAddExercise() && "disabledDiv"}`}
            style={{
              fontWeight: 600,
              width: "258px",
              height: "40px",
              border: "none",
            }}
            onClick={() =>
              !disabledAddExercise() &&
              (isExerciseEdit ? editExercise() : addExercise())
            }
          >
            <p>
              {isExerciseEdit ? "Save" : "Add"} {isRest ? "rest" : "exercise"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExercisePopup;
