import React, { useState, useEffect } from "react";
import TickIcon from "../../assets/icons/done.svg";
import AlertIcon from "../../assets/icons/alert.svg";

const Notification = ({ open, onClose, type, message, timeout }) => {
  const [error_message, setErrorMessage] = useState(null);

  useEffect(() => {
    if (typeof (message) === "string") {
      setErrorMessage(error_message);
    } else if (typeof (message) === "object") {
      const values = Object.values(message);
      setErrorMessage(values.join(' | '));
    }
  }, [message]);
  useEffect(() => {
    if (open && timeout > 0) {
      setTimeout(onClose, timeout);
    }
  }, [open]);

  return (
    open && (
      <div className={`notification_popup ${type}`}>
        <img height={24} width={type === "success-toast" ? 24 : 32} alt="notification-icon"
          src={type === "success-toast" ? TickIcon : AlertIcon}
        />
        <p> {message} </p>
      </div>
    )
  );
};

export default Notification;
