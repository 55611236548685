import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackGAEvent } from "../../services/analytics/GoogleAnalytics";
import { Mixpanel } from "../../services/analytics/Mixpanel";
import { createNotification } from "../../store/app/actions";
import { getUser } from "../../store/app/selectors";
import { fetchCalendar } from "../../store/calendar/actions";

const AthleteCalendar = ({ athlete, padding }) => {
  // let eventGuid = 0;
  const calendar = useRef(null);
  // let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
  // const createEventId = () => String(eventGuid++);
  // let [state, setState] = useState({
  //   currentEvents: [
  //     {
  //       id: createEventId(),
  //       title: "Full day session",
  //       start: todayStr,
  //       end: todayStr,
  //     },
  //   ],
  // });

  // Extract the number after "team="
  // Check if the match is found and get the team number

  function isPastorCurrentDate(date) {
    if (
      moment(moment(date).format("YYYY-MM-DD")).isAfter(
        moment().format("YYYY-MM-DD")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  const [cacheKey, setCacheKey] = useState("");
  let [calendarData, setCalendarData] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const calendarDataSelector = useSelector(
    (state) => state.calendarData.calendarData
  );
  const coach = useSelector(getUser).coach;
  const team =
    window.location.href.match(/(?<=team=)\d+/) !== null
      ? parseInt(window.location.href.match(/(?<=team=)\d+/)[0])
      : Number(window.localStorage.getItem("team_Id"));

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // const handleDateSelect = (selectInfo) => {
  //   let title = prompt("Please enter a new title for your event");
  //   let calendarApi = selectInfo.view.calendar;
  //   calendarApi.unselect(); // clear date selection
  //   if (title) {
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: selectInfo.startStr,
  //       end: selectInfo.endStr,
  //       allDay: selectInfo.allDay,
  //     });
  //   }
  // };

  // const handleEventClick = (clickInfo) => clickInfo.event;

  // const handleEvents = (events) => setState({ currentEvents: events });
  // const renderEventContent = (eventInfo) => {
  //   return (
  //     <div className="flex alignCenter w-100">
  //       <p className="w-100" style={{ textAlign: "center" }}>
  //         {eventInfo.event.title}
  //       </p>
  //     </div>
  //   );
  // };

  const populateCalendarBlocks = (dates) => {
    const populatePeriod = (date, color) => {
      const dashesHtml = document.createElement("span");
      dashesHtml.classList.add(isPastorCurrentDate(date) ? "dash" : "solid");
      dashesHtml.style.borderColor = color;
      return dashesHtml;
    };

    const populateSymbol = (color, symbol) => {
      const symbolHtml = document.createElement("span");
      symbolHtml.classList.add("symbol");
      symbolHtml.innerText = symbol;
      symbolHtml.style.backgroundColor = color;
      symbolHtml.style.color = "black";
      return symbolHtml;
    };

    for (const dateObj in dates) {
      const { date, phase_start, cycle_phase_short } = dates[dateObj];
      const currentDate = document.querySelector(
        `[data-date="${date.substring(0, 10)}"]`
      );
      if (currentDate) {
        currentDate.style.position = "relative";

        // Symbols
        if (phase_start && cycle_phase_short === "P ") {
          currentDate.appendChild(populateSymbol("#FE8AD6", "EF")); // Early Follicular
        } else if (phase_start && cycle_phase_short === "MF") {
          currentDate.appendChild(populateSymbol("#EBCDCD", "MF")); // Mid Follicular
        } else if (phase_start && cycle_phase_short === "OV") {
          currentDate.appendChild(populateSymbol("#98F2F8", "OV")); // Ovulatory
        } else if (phase_start && cycle_phase_short === "ML") {
          currentDate.appendChild(populateSymbol("#CDFAAB", "ML")); // Mid Luteal
        } else if (phase_start && cycle_phase_short === "LL") {
          currentDate.appendChild(populateSymbol("#FFD089", "LL")); // Late Luteal
        } else if (phase_start && cycle_phase_short === "AP") {
          currentDate.appendChild(populateSymbol("#98A1F8", "AP")); // Active Phase
        } else if (phase_start && cycle_phase_short === "IP") {
          currentDate.appendChild(populateSymbol("#CDD2EB", "IP")); // Inactive Phase
        } else if (phase_start && cycle_phase_short === "MPO") {
          currentDate.appendChild(populateSymbol("#F6FF89", "MPO")); // Progestin Only
        } else if (phase_start && cycle_phase_short === "PE") {
          currentDate.appendChild(populateSymbol("#EBE3B7", "PE")); // Perimenopause
        } else if (phase_start && cycle_phase_short === "ME") {
          currentDate.appendChild(populateSymbol("#B7EED4", "ME")); // Menopause
        }

        // Dashes
        if (cycle_phase_short === "P " && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#FE8AD6")); // Early Follicular
        } else if (cycle_phase_short === "MF" && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#EBCDCD")); // Mid Follicular
        } else if (cycle_phase_short === "OV" && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#98F2F8")); // Ovulatory
        } else if (cycle_phase_short === "ML" && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#CDFAAB")); // Mid Luteal
        } else if (cycle_phase_short === "LL" && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#FFD089")); // Late Luteal
        } else if (cycle_phase_short === "AP" && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#98A1F8")); // Active Phase
        } else if (cycle_phase_short === "IP" && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#CDD2EB")); // Inactive Phase
        } else if (cycle_phase_short === "MPO" && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#F6FF89")); // Progestin Only
        } else if (cycle_phase_short === "PE" && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#EBE3B7")); // Perimenopause
        } else if (cycle_phase_short === "ME" && !phase_start) {
          currentDate.appendChild(populatePeriod(date, "#B7EED4")); // Menopause
        }
      }
    }
  };

  // Set the cache key on every month change
  useEffect(() => {
    if (athlete && startDate && endDate) {
      setCacheKey(`${athlete}_${startDate}_${endDate}`);
    }
  }, [athlete, endDate]);

  const onError = (error) => {
    setLoading(false);
    dispatch(createNotification("error-toast", error));
  };

  // Fetch data on mount
  useEffect(() => {
    if (athlete && startDate && endDate && !calendarData) {
      Mixpanel.identify(coach.email);
      Mixpanel.track("View Calendar", {
        athlete,
        coach: coach.seg_id,
        team,
        timestamp: moment().format("YYYY-MM-DDTHH:mm:ss"),
      });
      trackGAEvent.identify(coach.email);
      trackGAEvent.track("View Calendar", {
        athlete,
        coach: coach.seg_id,
        team,
        timestamp: moment().format("YYYY-MM-DDTHH:mm:ss"),
      });
      setLoading(true);
      dispatch(fetchCalendar(athlete, startDate, endDate, onError));
    }
  }, [startDate]);

  // Fetch data on month change
  useEffect(() => {
    if (calendarData && !calendarDataSelector[cacheKey]) {
      setLoading(true);
      dispatch(fetchCalendar(athlete, startDate, endDate, onError));
    }
  }, [cacheKey]);

  useEffect(() => {
    if (calendarDataSelector[cacheKey])
      setCalendarData(calendarDataSelector[cacheKey].data);
  }, [calendarDataSelector, cacheKey]);

  // Set dates on mount
  useEffect(() => {
    if (calendar.current) {
      const calendarApi = calendar.current.getApi();
      const start = moment(
        calendarApi.currentData.dateProfile.activeRange.start
      ).format("DD/MM/YYYY");
      const end = moment(
        calendarApi.currentData.dateProfile.activeRange.end
      ).format("DD/MM/YYYY");
      setStartDate(start);
      setEndDate(end);
    }
  }, []);

  // Set dates on month change
  useEffect(() => {
    if (calendar.current) {
      const calendarApi = calendar.current.getApi();
      const datesSetHandler = (arg) => {
        if (arg.view.type === "dayGridMonth") {
          const start = moment(
            calendarApi.currentData.dateProfile.activeRange.start
          ).format("DD/MM/YYYY");
          const end = moment(
            calendarApi.currentData.dateProfile.activeRange.end
          ).format("DD/MM/YYYY");
          setStartDate(start);
          setEndDate(end);
        }
      };
      calendarApi.on("datesSet", datesSetHandler);
    }
  }, [calendar.current]);

  useEffect(() => {
    if (calendarData) {
      populateCalendarBlocks(calendarData);
      setLoading(false);
    }
  }, [calendarData]);

  return (
    <div
      className="flex column w-100 h-100 relative athleteCalendar"
      style={{ padding }}
    >
      <div className={`calendar-main relative ${loading && "calendarOverlay"}`}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev",
            center: "title",
            right: "next",
          }}
          initialView="dayGridMonth"
          // editable={true}
          // selectable={true}
          // selectMirror={true}
          dayMaxEvents={true}
          initialEvents={[]}
          // initialEvents={state["currentEvents"]}
          // select={handleDateSelect}
          // eventContent={renderEventContent}
          // eventClick={handleEventClick}
          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          ref={calendar}
        />
      </div>

      {loading && (
        <div className="flex column justifyCenter alignCenter w-100 h-100vh loadingOverlay">
          <div className="custom_loader" />
          <p style={{ marginTop: 32 }}>{`Please wait a moment`}</p>
        </div>
      )}
    </div>
  );
};

export default AthleteCalendar;
