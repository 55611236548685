export const checkins = [
  {
    id: "acne",
    label: "Acne",
    backgroundColor: "#53E5CB",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "anxiety",
    label: "Anxiety",
    backgroundColor: "#D875FF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "bloating",
    label: "Bloating",
    backgroundColor: "#FFEE57",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "brain_fog",
    label: "Brain fog",
    backgroundColor: "#7AF8FF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "breast_tenderness",
    label: "Breast tenderness",
    backgroundColor: "#5B18E3",
    model_name: "menstrual",
    textcolor: "#FFFFFF",
  },
  {
    id: "breath_rate_morning",
    label: "Breathlessness",
    backgroundColor: "#5BBAFF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "cold_flashes",
    label: "Cold flush",
    backgroundColor: "#F404EB",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "constipation",
    label: "Constipation",
    backgroundColor: "#FFE600",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "cravings",
    label: "Cravings",
    backgroundColor: "#FFF6A7",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "depression",
    label: "Depression",
    backgroundColor: "#83018E",
    model_name: "morning",
    textcolor: "#FFFFFF",
  },
  {
    id: "diarrhea",
    label: "Diarrhea",
    backgroundColor: "#FFFCE2",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "dyspareunia",
    label: "Dyspareunia",
    backgroundColor: "#9747FF",
    model_name: "menstrual",
    textcolor: "#FFFFFF",
  },
  {
    id: "energy_levels",
    label: "Energy levels",
    backgroundColor: "#5BBAFF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "faintness",
    label: "Faintness",
    backgroundColor: "#6EF404",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "fatigue",
    label: "Fatigue",
    backgroundColor: "#84D3FF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "gas",
    label: "Gas",
    backgroundColor: "#FFED47",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "headache",
    label: "Headache",
    backgroundColor: "#06DFEB",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "concussion",
    label: "Head injury",
    backgroundColor: "#03CDFF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "histamine",
    label: "Histamine-related symptoms",
    backgroundColor: "#04F4BB",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "hot_flashes",
    label: "Hot flush",
    backgroundColor: "#F404EB",
    model_name: "menstrual",
    textcolor: "#FFFFFF",
  },
  {
    id: "ibs",
    label: "IBS",
    backgroundColor: "#FFF6A7",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "illness",
    label: "Illness",
    backgroundColor: "#04F4BB",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "injury",
    label: "Injury",
    backgroundColor: "#F95151",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "joints_pain",
    label: "Joint pain",
    backgroundColor: "#02BF92",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "back_pain",
    label: "Lower back pain",
    backgroundColor: "#02BF92",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "migraine",
    label: "Migraine",
    backgroundColor: "#03CDFF",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "mood",
    label: "Mood",
    backgroundColor: "#E39DFF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "mood_swings",
    label: "Mood swings",
    backgroundColor: "#E39DFF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "motivation",
    label: "Motivation",
    backgroundColor: "#F1CDFF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "nausea",
    label: "Nausea",
    backgroundColor: "#FFEE57",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "night_sweats",
    label: "Night sweats",
    backgroundColor: "#84D3FF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "ovulation_pain",
    label: "Ovulation pain",
    backgroundColor: "#B379FF",
    model_name: "menstrual",
    textcolor: "#FFFFFF",
  },
  {
    id: "pelvic_floor_strength",
    label: "Pelvic floor strength",
    backgroundColor: "#6416FF",
    model_name: "menstrual",
    textcolor: "#FFFFFF",
  },
  {
    id: "pelvic_floor_problems",
    label: "Pelvic floor weakness",
    backgroundColor: "#D1ACFF",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "sibo",
    label: "SIBO",
    backgroundColor: "#FFF6A7",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "muscle_soreness",
    label: "Soreness",
    backgroundColor: "#04F4BB",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "stomach_cramps",
    label: "Stomach pain",
    backgroundColor: "#FFF6A7",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "life_stress",
    label: "Stress",
    backgroundColor: "#F1CDFF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "cramps",
    label: "Uterine cramps",
    backgroundColor: "#B379FF",
    model_name: "menstrual",
    textcolor: "#FFFFFF",
  },
  {
    id: "vaginal_dryness",
    label: "Vaginal dryness",
    backgroundColor: "#6416FF",
    model_name: "menstrual",
    textcolor: "#FFFFFF",
  },
  {
    id: "water_retention",
    label: "Water retention",
    backgroundColor: "#04F4BB",
    model_name: "menstrual",
    textcolor: "#000000",
  },
  {
    id: "readiness_score",
    label: "Readiness Score",
    backgroundColor: "#83018E",
    model_name: "morning",
    textcolor: "#FFFFFF",
  },
  {
    id: "hydration",
    label: "Hydration",
    backgroundColor: "#80FF00",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "sleep_hours",
    label: "Sleep Hours",
    backgroundColor: "#4ABF02",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "sleep_quality",
    label: "Sleep Quality",
    backgroundColor: "#FF00FF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "alcohol",
    label: "Alcohol",
    backgroundColor: "#84FFE9",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "nutrition",
    label: "Nutrition",
    backgroundColor: "#FFEE57",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "rhr",
    label: "RHR",
    backgroundColor: "#849DFF",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "insomnia",
    label: "Insomnia",
    backgroundColor: "#6EF404",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "appetite",
    label: "Appetite",
    backgroundColor: "#FFED47",
    model_name: "morning",
    textcolor: "#000000",
  },
  {
    id: "body_image",
    label: "Body image",
    backgroundColor: "#E39DFF",
    model_name: "morning",
    textcolor: "#000000",
  },
];

export const cyclePhasesInformation = {
  1: {
    label: "Early Follicular",
    preTraining: [
      {
        title: "Adequate Rest and Recovery",
        how: "Prioritize sufficient rest and recovery periods between workouts, ensuring athletes have enough time to recharge and restore their energy levels.",
        why: "During the early follicular phase, hormone levels are low, and energy may be slightly diminished. By allowing for ample rest, athletes can optimize their energy stores and reduce the risk of overtraining or fatigue.",
      },
      {
        title: "Focus on Aerobic Exercises",
        how: "Emphasize aerobic exercises such as steady-state running, cycling, or swimming during the early follicular phase.",
        why: "Hormone levels are still low, and the body may not have fully recovered from the menstrual period. Aerobic exercises are less taxing on the body and help to improve cardiovascular fitness, endurance, and oxygen utilization without placing excessive stress on the system.",
      },
      {
        title: "Incorporate Technique Work",
        how: "Dedicate time to fine-tuning technique and skill development during the early follicular phase.",
        why: "With energy levels beginning to rise, athletes can focus on refining their movements and skills. By emphasizing technique work during this phase, athletes can improve their motor patterns, coordination, and overall performance. This also allows them to maximize their training time and lay a solid foundation for future training blocks.",
      },
    ],
    traning: [
      {
        title: "Workout Style",
        description:
          "Focus on high-intensity interval training (HIIT) or strength training to take advantage of the increase in energy levels during this phase.",
      },
      {
        title: "Nutrition",
        description:
          "Prioritize a balanced diet with a mix of complex carbohydrates, lean proteins, and healthy fats to provide sustained energy. Include foods rich in iron and vitamin C to support blood production.",
      },
      {
        title: "Supplements",
        description:
          "Consider iron supplements if blood tests indicate low iron levels. Iron is essential for oxygen transport and energy production.\n During the early follicular phase, estrogen levels are low but gradually rising. This hormonal environment, coupled with increased energy levels and improved endurance, makes it an opportune time to engage in high-intensity workouts and cardiovascular exercises. Strength training can take advantage of the rising estrogen levels to promote muscle strength and power.",
      },
    ],
    postTraining: [
      {
        title: "Active Recovery Exercises",
        description:
          "Include active recovery exercises, such as light jogging, cycling, or swimming, to promote blood flow, reduce muscle soreness, and aid in recovery. During the early follicular phase, hormone levels are lower, and energy may be slightly diminished. Active recovery helps stimulate circulation, which can support the replenishment of nutrients to the muscles, enhance recovery, and improve overall performance.",
      },
      {
        title: "Focus on Nutrient Timing",
        description:
          "Pay attention to nutrient timing, particularly within the first hour post-workout. During the early follicular phase, the body may be more sensitive to nutrient uptake and utilization. Aim to consume a balanced post-workout meal or snack that includes a combination of carbohydrates and protein. Carbohydrates help replenish glycogen stores, while protein supports muscle repair and growth. Consuming these nutrients within the first hour after exercise can optimize recovery and enhance performance during this phase.",
      },
      {
        title: "Prioritize Quality Sleep",
        description:
          "Place emphasis on getting quality sleep as part of the post-workout routine. Adequate rest and sleep are crucial for overall recovery and performance optimization. During the early follicular phase, when hormone levels are lower and energy may be slightly diminished, quality sleep becomes even more important to support hormonal balance, muscle repair, and mental well-being. Encourage athletes to establish a consistent sleep schedule and create a conducive sleep environment to maximize the benefits of restorative sleep.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Early Follicular" },
      { title: "When", description: "Up to day 7-10" },
      {
        title: "Hormone Levels",
        description:
          "During the Early Follicular phase, hormone levels are relatively low. Both estrogen and progesterone are at their lowest levels, which can have several effects on the body.",
      },
    ],
    athleticPerformance: [
      {
        title: "Energy Levels",
        description:
          "Due to lower hormone levels, energy may be slightly diminished during the Early Follicular phase. Athletes might feel less energetic compared to other phases of the menstrual cycle.",
      },
      {
        title: "Recovery",
        description:
          "Since hormone levels are low, recovery from intense workouts or competitions might be slightly slower during this phase.",
      },
      {
        title: "Mood and Motivation",
        description:
          "Some athletes may experience a dip in motivation or mood during the Early Follicular phase.",
      },
    ],
    exerciseFocusDesc:
      "During the Early Follicular phase, it is recommended to focus on the following types of exercises:",
    exerciseFocus: [
      {
        title: "Technique Work",
        description:
          "This phase is ideal for refining technique and skill development. Lower-intensity training sessions can provide an opportunity to fine-tune movements and optimize form.",
      },
      {
        title: "Aerobic Exercises",
        description:
          "Engaging in aerobic exercises, such as jogging, swimming, or cycling, can help maintain cardiovascular fitness without placing excessive strain on the body.",
      },
    ],
    recommendations: [
      {
        title: "Iron",
        description:
          "Iron is an essential mineral that supports the production of red blood cells and helps transport oxygen to muscles and organs.",
      },
      {
        title: "B Vitamins",
        description:
          "B vitamins play a vital role in energy metabolism and help convert food into usable energy.",
      },
      {
        title: "Omega-3 Fatty Acids",
        description:
          "Omega-3 fatty acids, such as those found in fish oil, have anti-inflammatory properties and support cardiovascular health.",
      },
      {
        title: "Vitamin C",
        description:
          "Vitamin C is a powerful antioxidant that supports the immune system, helps with collagen synthesis, and enhances iron absorption.",
      },
    ],
    raceDayDuringPhaseDesc:
      "If an important athletic event, such as a race, falls within the Early Follicular phase, consider the following suggestions for energy boost towards optimal performance:",
    raceDayDuringPhase: [
      {
        title: "Adequate Rest",
        description:
          "Prioritize getting enough sleep in the days leading up to the race to ensure optimal energy levels.",
      },
      {
        title: "Carbohydrate Loading",
        description:
          "Increase carbohydrate intake in the days preceding the event to maximize glycogen stores and provide sustained energy during the race.",
      },
      {
        title: "Mental Preparation",
        description:
          "Implement positive visualization techniques, goal setting, and pre-race routines to enhance focus and motivation.",
      },
      {
        title: "Warm-Up",
        description:
          "Engage in a comprehensive warm-up routine, including dynamic stretching and activation exercises, to prepare the body for the race.",
      },
      {
        title: "Proper Fueling",
        description:
          "Consume a balanced pre-race meal 2-3 hours before the event, focusing on easily digestible carbohydrates and a small amount of protein.",
      },
    ],
  },
  2: {
    label: "Mid Follicular",
    preTraining: [
      {
        title: "Implement High-Intensity Interval Training (HIIT)",
        how: "Incorporate intervals of intense exercise followed by short recovery periods.",
        why: "During the mid-follicular phase, estrogen levels gradually increase, leading to improved energy and endurance. HIIT workouts capitalize on these hormonal changes by challenging the cardiovascular system, improving aerobic capacity, and promoting fat burning.",
      },
      {
        title: "Focus on Strength Training",
        how: "Include compound exercises that target multiple muscle groups, such as squats, deadlifts, and bench presses.",
        why: "With rising estrogen levels during the mid-follicular phase, women experience a greater anabolic response, making it an optimal time for strength training. This hormone supports muscle protein synthesis, leading to gains in strength and muscle mass.",
      },
      {
        title: "Optimize Nutrient Timing",
        how: "Consume a balanced meal or snack containing carbohydrates and protein 1-2 hours before the workout.",
        why: "Estrogen enhances insulin sensitivity during the mid-follicular phase, allowing for efficient nutrient uptake by muscle cells. Consuming carbohydrates before exercise provides readily available energy, while protein supports muscle repair and growth, maximizing performance and recovery.",
      },
    ],
    traning: [
      {
        title: "Workout Style",
        description:
          "Emphasize endurance exercises such as steady-state cardio, long-distance running, or cycling to benefit from improved endurance capabilities.",
      },
      {
        title: "Nutrition",
        description:
          "Continue consuming a balanced diet with an emphasis on whole grains, fruits, and vegetables to support sustained energy. Include foods rich in antioxidants to aid in muscle recovery.",
      },
      {
        title: "Supplements",
        description:
          "Omega-3 fatty acids, such as fish oil, may provide anti-inflammatory benefits and support cardiovascular health.",
        reasoning:
          "Estrogen levels are rising during the mid follicular phase, contributing to increased endurance capacity. Endurance exercises can be better tolerated, and a diet rich in whole foods and antioxidants helps support muscle recovery and overall health.",
      },
    ],
    postTraining: [
      {
        title: "Protein-Rich Recovery Meal",
        description:
          "Consuming a protein-rich meal or snack post-workout is particularly beneficial during the mid-follicular phase. During this phase, estrogen levels are increasing, which supports protein synthesis and muscle repair. Including an adequate amount of protein in the recovery meal helps optimize muscle recovery, promote tissue repair, and enhance overall performance.",
      },
      {
        title: "Dynamic Stretching and Mobility Exercises",
        description:
          "ncorporating dynamic stretching and mobility exercises as part of the post-workout routine during the mid-follicular phase can help maximize performance. Estrogen levels are rising, which enhances joint flexibility and muscle elasticity. Dynamic stretching and mobility exercises capitalize on these hormonal advantages, improving range of motion, promoting optimal muscle function, and reducing the risk of injury.",
      },
      {
        title: "High-Glycemic Carbohydrates",
        description:
          "Including high-glycemic carbohydrates in the post-workout nutrition plan can be beneficial during the mid-follicular phase. Estrogen levels have a positive effect on insulin sensitivity during this phase, meaning the body can more efficiently utilize carbohydrates for energy replenishment and glycogen restoration. Incorporating high-glycemic carbohydrates, such as fruits, white rice, or potatoes, assists in replenishing muscle glycogen stores quickly, facilitating recovery, and preparing the body for subsequent training sessions.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Mid Follicular" },
      { title: "When", description: "After day 7-10" },
      {
        title: "Hormone Levels",
        description:
          "During the mid-follicular phase, estrogen levels gradually increase, leading to improved energy, mood, and focus. Estrogen plays a crucial role in enhancing athletic performance by increasing muscle protein synthesis, improving endurance, and promoting optimal recovery. Additionally, estrogen has an anti-inflammatory effect, which can reduce exercise-induced muscle damage and enhance overall performance.",
      },
    ],
    athleticPerformanceDesc:
      "The rise in estrogen during the mid-follicular phase positively affects athletic performance in several ways:",
    athleticPerformance: [
      {
        title: "Increased Endurance",
        description:
          "Estrogen enhances the delivery and utilization of oxygen by increasing the number of red blood cells and improving blood flow. This can result in improved endurance capacity and aerobic performance.",
      },
      {
        title: "Enhanced Strength",
        description:
          "Estrogen facilitates neuromuscular function, promoting better muscle activation and strength gains.",
      },
      {
        title: "Improved Recovery",
        description:
          "The anti-inflammatory properties of estrogen help reduce muscle soreness and inflammation, leading to faster recovery between training sessions.",
      },
    ],
    exerciseFocusDesc:
      "The rise in estrogen during the mid-follicular phase positively affects athletic performance in several ways:",
    exerciseFocus: [
      {
        title: "Increased Endurance",
        description:
          "Estrogen enhances the delivery and utilization of oxygen by increasing the number of red blood cells and improving blood flow. This can result in improved endurance capacity and aerobic performance.",
      },
      {
        title: "Enhanced Strength",
        description:
          "Estrogen facilitates neuromuscular function, promoting better muscle activation and strength gains.",
      },
      {
        title: "Improved Recovery",
        description:
          "The anti-inflammatory properties of estrogen help reduce muscle soreness and inflammation, leading to faster recovery between training sessions.",
      },
    ],
    recommendations: [
      {
        title: "Iron",
        description:
          "Estrogen increases iron absorption, making it important to maintain adequate iron levels.",
      },
      {
        title: "B Vitamins",
        description: "B vitamins play a crucial role in energy metabolism.",
      },
      {
        title: "Vitamin D",
        description:
          "Adequate vitamin D levels are essential for muscle function and bone health during recovery.",
      },
    ],
    raceDayDuringPhase: [
      {
        title: "Proper Fueling",
        description:
          "Ensure the athlete consumes a balanced meal 2-3 hours before the event, focusing on complex carbohydrates, lean proteins, and healthy fats. This will provide sustained energy and support endurance.",
      },
      {
        title: "Hydration",
        description:
          "Maintain proper hydration throughout the event. Encourage regular fluid intake and consider sports drinks or electrolyte-enhanced water to replenish electrolytes lost through sweat.",
      },
      {
        title: "Warm-Up",
        description:
          "Include a dynamic warm-up routine to activate muscles and prepare the body for the event. Incorporate sport-specific movements and dynamic stretches.",
      },
      {
        title: "Mental Focus",
        description:
          "Take advantage of the heightened mental focus during this phase. Encourage positive visualization techniques and focus on the athlete's strengths and abilities.",
      },
      {
        title: "Glycogen Recovery",
        description:
          "After the event, prioritize proper cool-down exercises, stretching, and refueling with a post-race meal to support muscle recovery and replenish glycogen stores.",
      },
    ],
  },
  3: {
    label: "Ovulatory",
    preTraining: [
      {
        title: "Prioritize Power-Based Exercises",
        how: "Incorporate exercises that focus on explosive power, such as plyometrics, Olympic lifts, and sprints.",
        why: "During the ovulation phase, estrogen levels peak, leading to improved coordination, strength, and faster recovery. Power-based exercises take advantage of these hormonal changes, maximizing performance and enhancing power output.",
      },
      {
        title: "Optimize Nutrient Timing:",
        how: "onsume a balanced meal or snack containing carbohydrates and protein approximately 1-2 hours before the workout.",
        why: "Estrogen, which is elevated during the ovulation phase, enhances glycogen synthesis and muscle protein synthesis. By providing the body with adequate fuel before exercise, athletes can optimize energy levels, support muscle recovery, and promote optimal performance.",
      },
      {
        title: "Incorporate Agility and Speed Training",
        how: " Include drills that focus on agility, change of direction, and speed, such as ladder drills, cone drills, and shuttle runs.",
        why: "Estrogen promotes ligament laxity and joint mobility during the ovulation phase, making it an ideal time to work on agility and speed. Athletes may experience improved coordination, faster reaction times, and enhanced athletic performance in these areas.",
      },
    ],
    traning: [
      {
        title: "Workout Style",
        description:
          "Focus on power-based exercises, plyometrics, or speed training to take advantage of the peak in estrogen and testosterone levels, which can enhance strength and power.",
      },
      {
        title: "Nutrition",
        description:
          "Prioritize protein-rich foods to support muscle repair and recovery. Include foods high in calcium and vitamin D for bone health.",
      },
      {
        title: "Supplements",
        description:
          "Calcium and vitamin D supplements may be beneficial if dietary intake is inadequate, as these nutrients are important for bone health and muscle function.",
        reasoning:
          "The surge in estrogen and testosterone during ovulation enhances strength and power. Power-based exercises and speed training can help capitalize on these hormonal advantages, while proper nutrition supports muscle repair and bone health.",
      },
    ],
    postTraining: [
      {
        title: "Foam Rolling and Stretching",
        description:
          "During the ovulation phase, estrogen levels are at their highest, which can enhance flexibility and joint mobility. Foam rolling and stretching help maintain and improve range of motion, preventing muscle imbalances and reducing the risk of injuries. After a workout, encourage your female athlete to engage in foam rolling and stretching exercises, focusing on the major muscle groups used during the training session.",
      },
      {
        title: "Protein-Rich Recovery Snack",
        description:
          "During the ovulation phase, estrogen stimulates protein synthesis, making it an optimal time for muscle repair and growth. Consuming a protein-rich snack immediately after exercise helps replenish glycogen stores, supports muscle recovery, and enhances adaptation to training. Provide your athlete with a protein-rich snack within 30 to 60 minutes post-workout, such as a protein shake, Greek yogurt with nuts, or a chicken salad.",
      },
      {
        title: "Focus on Sleep and Recovery",
        description:
          "The ovulation phase is associated with increased energy levels and improved sleep quality due to the rise in estrogen levels. Quality sleep is crucial for muscle repair, hormone balance, cognitive function, and overall recovery. Prioritizing rest and recovery during this phase ensures optimal performance in subsequent training sessions. Emphasize the importance of quality sleep and adequate recovery time between workouts. Encourage your athlete to prioritize rest, create a sleep-friendly environment, and establish a consistent sleep schedule.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Ovulation" },
      { title: "When", description: "mid-cycle" },
      {
        title: "Hormone Levels",
        description:
          "During the ovulation phase, which typically occurs around the middle of the menstrual cycle, estrogen levels reach their peak. This surge in estrogen stimulates the release of an egg from the ovary. Additionally, luteinizing hormone (LH) levels rise, triggering ovulation. The increased estrogen levels can have several effects on athletic performance.",
      },
    ],
    athleticPerformance: [
      {
        title: "Energy and Endurance",
        description:
          "Estrogen has been linked to increased energy levels and enhanced endurance during this phase. Athletes may experience improved stamina and sustained performance.",
      },
      {
        title: "Coordination and Accuracy",
        description:
          "Estrogen can enhance neuromuscular coordination and fine motor skills. Athletes may see improvements in accuracy and agility.",
      },
      {
        title: "Recovery and Injury Prevention",
        description:
          "Estrogen promotes tissue repair and can reduce the risk of injury. Athletes may experience faster recovery times during this phase.",
      },
    ],
    exerciseFocusDesc:
      "During the ovulation phase, athletes can capitalize on the hormonal advantages to optimize their training and performance.",
    exerciseFocus: [
      {
        title: "Power-Based Exercises",
        description:
          "Focus on explosive movements, such as plyometrics, Olympic lifts, and sprints. Take advantage of the heightened coordination and energy levels to improve power output.",
      },
      {
        title: "Speed and Agility Training",
        description:
          "Incorporate drills and exercises that challenge speed, agility, and quickness. This phase provides an opportunity to work on refining these skills.",
      },
      {
        title: "Competition Preparation",
        description:
          "If your athlete has an important event or race during this phase, schedule high-intensity practice sessions or mock competitions. This allows the athlete to perform at their best and simulate race conditions.",
      },
    ],
    recommendations: [
      {
        title: "Omega-3 Fatty Acids",
        description:
          "Omega-3 fatty acids, particularly EPA and DHA, are known for their anti-inflammatory properties and potential benefits for hormonal balance. They can be obtained through fish oil supplements or plant-based alternatives like algae-based omega-3 supplements.",
      },
      {
        title: "Calcium and Vitamin D",
        description:
          "Calcium and vitamin D are important for bone health and muscle function. During ovulation, it can be beneficial to ensure adequate intake of these nutrients. While dietary sources are preferred, supplements can be considered if dietary intake is insufficient.",
      },
      {
        title: "Multivitamin",
        description:
          "A high-quality multivitamin can help ensure you're meeting your daily requirements for essential vitamins and minerals. Look for a formulation specifically designed for women that includes adequate amounts of B vitamins, vitamin C, vitamin E, magnesium, and zinc.",
      },
      {
        title: "Adaptogenic Herbs",
        description:
          "Adaptogens like maca root, ashwagandha, and rhodiola rosea are believed to help the body adapt to stress and balance hormone levels. These herbs may provide support during the hormonal fluctuations of the menstrual cycle, including ovulation. However, it's important to consult with a healthcare professional before adding any adaptogenic supplements to your routine.",
      },
    ],
    raceDayDuringPhase: [
      {
        title: "Adjust Training Schedule",
        description:
          "If possible, plan race days during the ovulation phase to take advantage of the hormonal benefits. This phase is characterized by increased energy, coordination, and improved endurance. Aligning race day with this phase can enhance performance potential.",
      },
      {
        title: "Capitalize on Hormonal Advantages",
        description:
          "During the ovulation phase, estrogen levels are elevated, leading to improved energy, coordination, and recovery. Utilize this phase to incorporate high-intensity training, power-based exercises, and speed and agility drills. Take advantage of the hormonal advantages to maximize performance gains.",
      },
    ],
  },
  4: {
    label: "Mid Luteal",
    preTraining: [
      {
        title: "Plan Recovery Days",
        description:
          "Incorporate regular recovery days or active rest periods during the mid-luteal phase to allow for optimal recovery and adaptation. This helps prevent overtraining and reduces the risk of injury.",
      },
      {
        title: "Modify Intensity and Volume:",
        description:
          "Adjust training intensity and volume during the mid-luteal phase to accommodate potential fatigue and reduced coordination. This may involve reducing high-intensity exercises, focusing more on technique and skill development, or incorporating lower-intensity workouts to maintain fitness levels.",
      },
      {
        title: "Address Bloating and Discomfort",
        description:
          "Be sensitive to any bloating or discomfort experienced by the athlete during this phase. Modify training exercises or equipment if necessary to alleviate discomfort and support optimal performance.",
      },
      {
        title: "Supportive Coaching",
        description:
          "Provide emotional support and open communication channels with the athlete. Recognize that the hormonal changes during the mid-luteal phase may affect mood and motivation. Encourage dialogue, provide encouragement, and create a positive training environment.",
      },
    ],
    traning: [
      {
        title: "Workout Style",
        description:
          "Shift towards more endurance-based training and incorporate activities like steady-state cardio, long runs, or cycling. Progesterone levels are elevated during this phase, which can slightly reduce coordination and fine motor skills.",
      },
      {
        title: "Nutrition",
        description:
          "Consume complex carbohydrates, lean proteins, and ample fruits and vegetables to support energy levels and replenish glycogen stores.",
      },
      {
        title: "Supplements",
        description:
          "Magnesium supplementation may help alleviate premenstrual symptoms and support muscle relaxation.",
      },
    ],
    postTraining: [
      {
        title: "Incorporate Active Recovery",
        description:
          "During the mid-luteal phase, when progesterone levels are high, athletes may experience fatigue and reduced coordination. Including active recovery exercises as part of the post-workout routine can be particularly beneficial during this phase. Active recovery involves performing low-intensity exercises such as light jogging, cycling, or swimming - helps promote blood flow and circulation, which can aid in reducing muscle soreness and promoting faster recovery. Additionally, engaging in low-intensity exercise during this phase helps to counteract fatigue and stiffness, keeping the body active without placing excessive stress on the muscles.",
      },
      {
        title: "Focus on Proper Nutrition",
        description: `After a workout session, it is crucial to replenish the body with the necessary nutrients to support recovery and maximize performance during the mid-luteal phase. Proper nutrition helps optimize recovery by providing essential nutrients for tissue repair, reducing muscle soreness, and supporting hormonal balance during this phase. Pay attention to your athlete's nutritional intake by emphasizing the following aspects:
            Protein Intake: Ensure an adequate amount of protein, which plays a vital role in muscle repair and recovery. Opt for lean sources such as chicken, fish, tofu, or Greek yogurt.
            Carbohydrates: Consume complex carbohydrates such as whole grains, fruits, and vegetables to replenish glycogen stores and provide sustained energy levels.
            Anti-inflammatory Foods: Incorporate foods rich in antioxidants and anti-inflammatory properties, such as berries, leafy greens, turmeric, and fatty fish, to support recovery and reduce inflammation.`,
      },
      {
        title: "Prioritize Rest and Sleep",
        description:
          "Rest and sleep are essential components of recovery for any athlete, and they become even more crucial during the mid-luteal phase. Increased progesterone levels during this phase can lead to feelings of fatigue and tiredness. Coaches should encourage their athletes to prioritize rest and ensure sufficient sleep duration and quality.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Mid Luteal" },
      { title: "When", description: "up to the last 7-10 days" },
      {
        title: "Hormone Levels",
        description:
          "During the mid-luteal phase, which occurs approximately 7-10 days before menstruation, the hormone progesterone reaches its peak. This phase is characterized by increased levels of progesterone and its effects on the body, which can impact athletic performance in several ways.",
      },
    ],
    athleticPerformance: [
      {
        title: "Fatigue",
        description:
          "Progesterone can contribute to feelings of fatigue and reduced energy levels. Athletes may experience a decrease in overall endurance and feel more fatigued during high-intensity exercises.",
      },
      {
        title: "Bloating",
        description:
          "Some women may experience water retention and bloating during the mid-luteal phase. This can lead to feelings of discomfort and impact performance, particularly in activities that require agility and coordination.",
      },
      {
        title: "Reduced Coordination",
        description:
          "Elevated progesterone levels may affect fine motor skills and coordination, potentially impacting precision-based sports or activities that require precise movements.",
      },
    ],
    exerciseFocus: [
      {
        title: "Low-Intensity Endurance Exercises",
        description:
          "Focus on low-intensity aerobic exercises, such as steady-state running or cycling. These activities can help maintain cardiovascular fitness while accommodating the potential fatigue experienced during this phase.",
      },
      {
        title: "Yoga and Stretching",
        description:
          "Incorporate yoga or gentle stretching exercises to promote flexibility, mobility, and relaxation. These activities can help alleviate bloating and reduce muscle tension.",
      },
      {
        title: "Recovery Activities",
        description:
          "Emphasize recovery strategies such as foam rolling, massage, and active recovery workouts to support muscle repair and prevent excessive fatigue.",
      },
      {
        title: "Mind-Body Connection",
        description:
          "Encourage activities like meditation, visualization, or breathing exercises to help athletes manage stress and improve focus during this phase.",
      },
    ],
    recommendations: [
      {
        title: "Magnesium",
        description:
          "Magnesium is known for its calming properties and can help reduce mood swings, irritability, and bloating associated with the mid luteal phase. It also supports muscle relaxation and can aid in reducing menstrual cramps.",
      },
      {
        title: "Vitamin B6",
        description:
          "Vitamin B6 plays a crucial role in hormone regulation and can help alleviate premenstrual symptoms such as mood swings, irritability, and breast tenderness. It supports the production of serotonin, a neurotransmitter that promotes a sense of well-being.",
      },
      {
        title: "Evening Primrose Oil",
        description:
          "Evening primrose oil contains gamma-linolenic acid (GLA), an omega-6 fatty acid that helps regulate hormone production. It may help reduce breast tenderness, bloating, and mood swings during the mid luteal phase.",
      },
      {
        title: "Chasteberry (Vitex)",
        description:
          "Chasteberry is a traditional herbal remedy that has been used for centuries to support female hormonal balance. It may help reduce symptoms such as breast tenderness, mood swings, and bloating by supporting the regulation of progesterone levels.",
      },
      {
        title: "Calcium",
        description:
          "Calcium is essential for overall bone health and muscle function. It may also help reduce premenstrual symptoms, including mood swings, food cravings, and fatigue.",
      },
    ],
    raceDayDuringPhase: [
      {
        title: "Adequate Rest",
        description:
          "Prioritize quality sleep and ensure athletes are well-rested before the event to mitigate the potential fatigue experienced during this phase.",
      },
      {
        title: "Nutrition Planning",
        description:
          "Optimize pre-event nutrition by consuming easily digestible carbohydrates, lean protein, and a moderate amount of healthy fats to provide sustained energy.",
      },
      {
        title: "Warm-up Routine",
        description:
          "Implement a thorough warm-up routine that includes dynamic stretching, activation exercises, and mental preparation to enhance coordination and focus.",
      },
      {
        title: "PMS Mitigation",
        description:
          "Encurage athletes to get plently of rest, hydrate, increase Iron and Magnesium.",
      },
    ],
  },
  5: {
    label: "Late Luteal",
    preTraining: [
      {
        title: "Light Snack",
        description:
          "Consuming a light snack before a workout can provide the necessary fuel to perform optimally during the late luteal phase. Choose a snack that includes complex carbohydrates, such as a small serving of whole-grain toast or a banana, which provide sustained energy. Including a source of protein, like a handful of nuts or Greek yogurt, will aid in muscle repair and recovery.",
        how: "The combination of carbohydrates and protein in the pre-workout snack helps stabilize blood sugar levels, preventing energy crashes and promoting sustained energy throughout the training session. This can counteract the fatigue that may be experienced during the late luteal phase, allowing the athlete to maintain performance and focus.",
        why: "The high progesterone levels during the late luteal phase can lead to increased fatigue and decreased energy levels. Providing the body with a light snack before a workout helps to mitigate these effects and supports optimal performance during training sessions.",
      },
      {
        title: "Proper Hydration",
        description:
          "Ensuring adequate hydration is essential before any workout, including during the late luteal phase. Adequate hydration helps maintain blood volume, which supports cardiovascular function and nutrient delivery to muscles.",
        how: "Hydration plays a crucial role in optimizing exercise performance by maintaining body temperature, lubricating joints, and facilitating muscle contractions. It can also help alleviate symptoms such as bloating and water retention that may be experienced during the late luteal phase.",
        why: "Progesterone, the dominant hormone during the late luteal phase, can cause water retention and potentially lead to feelings of bloating. By maintaining proper hydration levels, athletes can help alleviate these symptoms and enhance overall comfort during workouts.",
      },
      {
        title: "Dynamic Warm-up",
        description:
          "Engaging in a dynamic warm-up routine is particularly beneficial during the late luteal phase to improve coordination, enhance circulation, and prepare the body for exercise.",
        how: "A dynamic warm-up routine involves incorporating active movements that mimic the exercises or activities to be performed during the workout. This helps increase muscle temperature, flexibility, and blood flow, which can improve performance, reduce the risk of injury, and counteract any potential coordination challenges experienced during this phase.",
        why: "The rise in progesterone during the late luteal phase can lead to decreased coordination and fine motor skills. A dynamic warm-up routine stimulates the nervous system and helps improve neuromuscular connections, enhancing coordination and ensuring smooth movement patterns during the workout.",
      },
    ],
    traning: [
      {
        title: "Workout Style",
        description:
          "Incorporate low-impact exercises such as yoga, Pilates, or gentle stretching to promote relaxation, improve flexibility, and manage any discomfort or fatigue.",
      },
      {
        title: "Nutrition",
        description:
          "Consume nutrient-dense foods, including leafy greens, fruits, and lean proteins, while managing portion sizes to support energy levels and minimize bloating or discomfort.",
      },
      {
        title: "Supplements",
        description:
          "Consider supplements like evening primrose oil or chasteberry to support hormone balance and manage symptoms.",
      },
    ],
    postTraining: [
      {
        title: "Incorporate Active Recovery",
        description:
          "Active recovery helps promote blood flow, reduces muscle soreness, and aids in the removal of metabolic waste products. It can also alleviate symptoms such as bloating and water retention, allowing the body to recover more efficiently. After intense workouts, include active recovery sessions during the late luteal phase. This can involve low-impact exercises such as swimming, cycling, or light jogging.",
      },
      {
        title: "Focus on Adequate Protein Intake",
        description:
          "Protein is crucial for muscle repair and recovery. During the late luteal phase, when hormonal changes may increase muscle breakdown, prioritizing protein intake can help support muscle regeneration and minimize muscle soreness. Additionally, protein-rich foods can provide a sense of satiety and help stabilize blood sugar levels, which may fluctuate during this phase. Consume a post-workout meal or snack rich in high-quality protein sources such as lean meats, poultry, fish, tofu, or legumes.",
      },
      {
        title: "Prioritize Hydration and Electrolyte Balance",
        description:
          "Proper hydration is vital for maintaining optimal performance and overall well-being. During the late luteal phase, the body may experience water retention and increased body temperature, making it even more important to stay adequately hydrated. Electrolytes, such as sodium, potassium, and magnesium, help maintain fluid balance, support muscle function, and prevent cramping. Drink plenty of fluids during and after workouts, and consider incorporating electrolyte-rich beverages or snacks such as coconut water, sports drinks, or electrolyte-infused fruits.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Late Luteal" },
      { title: "When", description: "last 7 days" },
      {
        title: "Hormone Levels",
        description:
          "During the late luteal phase, which occurs as the last stage of the cycle, characterized by menses, progesterone levels are at their highest, while estrogen levels start to decline. This hormonal shift can affect athletic performance in several ways",
      },
    ],
    athleticPerformance: [
      {
        title: "Fatigue",
        description:
          "The rise in progesterone can lead to increased fatigue and reduced energy levels.",
      },
      {
        title: "Bloating and Water Retention",
        description:
          "Some athletes may experience bloating and water retention, which can impact comfort and coordination.",
      },
      {
        title: "Decreased Coordination",
        description:
          "The combination of hormonal changes and fluid retention may affect fine motor skills and coordination.",
      },
      {
        title: "Increased Body Temperature",
        description:
          "Progesterone can slightly elevate basal body temperature, potentially impacting exercise tolerance.",
      },
    ],
    exerciseFocus: [
      {
        title: "Low-Intensity Endurance",
        description:
          "Engage in longer duration, low-intensity cardio exercises like steady-state running, cycling, or swimming to maintain cardiovascular fitness without excessive stress on the body.",
      },
      {
        title: "Yoga and Stretching",
        description:
          "Incorporate gentle yoga flows, mobility exercises, and stretching routines to improve flexibility and alleviate muscle tension.",
      },
      {
        title: "Stability and Balance Training",
        description:
          "Enhance stability and balance through exercises like single-leg movements, bosu ball exercises, and stability training to counteract any coordination challenges.",
      },
    ],
    recommendations: [
      {
        title: "Magnesium",
        description:
          "Magnesium supplementation may help reduce symptoms such as muscle tension, irritability, and anxiety commonly experienced during the late luteal phase. It can also support relaxation and promote better sleep quality.",
      },
      {
        title: "Vitamin B6",
        description:
          "Vitamin B6 has been shown to help alleviate symptoms associated with premenstrual syndrome (PMS), including mood swings, bloating, and breast tenderness. It can also support hormone regulation.",
      },
      {
        title: "Evening Primrose Oil",
        description:
          "Evening primrose oil is rich in gamma-linolenic acid (GLA), an omega-6 fatty acid. It may help reduce breast pain, bloating, and mood swings associated with the late luteal phase.",
      },
      {
        title: "Chasteberry (Vitex agnus-castus)",
        description:
          "Chasteberry is an herb that has been traditionally used to help balance hormones and alleviate PMS symptoms. It may help reduce breast tenderness, irritability, and mood swings.",
      },
      {
        title: "Omega-3 Fatty Acids",
        description:
          "Omega-3 fatty acids, commonly found in fish oil supplements, have anti-inflammatory properties and may help reduce symptoms of PMS, including bloating and mood disturbances.",
      },
    ],
    raceDayDuringPhase: [
      {
        title: "Adequate Rest and Sleep",
        description:
          "During the late luteal phase, fatigue may be more pronounced. Prioritize getting sufficient rest and aim for quality sleep in the days leading up to the race. This will help optimize energy levels and support overall performance.",
      },
      {
        title: "Manage Fluid Retention",
        description:
          "Bloating and water retention are common symptoms during the late luteal phase. Take steps to manage fluid retention by avoiding excessive sodium intake, staying well-hydrated, and incorporating natural diuretic foods such as cucumber, watermelon, and asparagus into your pre-race meals.",
      },
      {
        title: "Mindful Nutrition",
        description: `Pay attention to your pre-race nutrition to support optimal performance and alleviate potential digestive discomfort. Consider these strategies:
            a. Moderate Fiber Intake: While fiber is generally beneficial, reducing high-fiber foods in the immediate pre-race period can help minimize gastrointestinal distress.
            b. Balanced Carbohydrates: Consume easily digestible carbohydrates such as white rice, pasta, or bananas to provide energy without causing bloating or discomfort.
            c. Adequate Protein and Healthy Fats: Include lean protein sources and healthy fats to support muscle recovery and satiety without compromising digestion.`,
      },
      {
        title: "Warm-up and Mobility",
        description:
          "Prioritize a thorough warm-up routine that includes dynamic stretches and mobility exercises to improve blood flow, enhance joint range of motion, and promote coordination. This can help counteract any potential stiffness or reduced coordination associated with the late luteal phase.",
      },
    ],
  },
  6: {
    label: "Peri Menopause",
    preTraining: [
      {
        title: "Hormone Regulation",
        description:
          "Implement stress-reducing techniques such as deep breathing exercises, meditation, or yoga before workouts. During the perimenopause phase, hormonal fluctuations can lead to increased stress levels. By managing stress, you can help regulate hormone levels, leading to improved performance and overall well-being. Encourage athletes to dedicate a few minutes before their workouts to engage in deep breathing exercises or a brief mindfulness session. This can help activate the parasympathetic nervous system, promoting relaxation and hormone balance.",
      },
      {
        title: "Adequate Hydration",
        description:
          " Encourage athletes to prioritize hydration before, during, and after workouts to optimize performance and alleviate potential symptoms associated with perimenopause, such as hot flashes and night sweats. Provide guidance on the importance of regular hydration and establish individualized fluid intake recommendations based on activity level, duration, and environmental conditions. Encourage athletes to carry water bottles and sip water consistently throughout the day.",
      },
    ],
    traning: [
      {
        title: "Targeted Workout: Incorporate Strength Training",
        description:
          "Include strength training exercises that focus on compound movements such as squats, deadlifts, and push-ups. Use challenging weights or resistance bands to progressively overload the muscles.Strength training during perimenopause helps counteract the natural decline in muscle mass and bone density. It improves overall strength, enhances metabolism, and supports healthy hormone balance. Additionally, strength training can alleviate symptoms like joint pain, mood swings, and sleep disturbances commonly experienced during perimenopause.",
      },
      {
        title: "Nutrition: Prioritize Balanced and Nutrient-Dense Meals",
        description:
          "Encourage a diet that includes a variety of nutrient-dense foods such as lean proteins, fruits, vegetables, whole grains, and healthy fats. During perimenopause, there is a tendency for metabolic changes, including decreased insulin sensitivity and potential weight gain. A balanced and nutrient-dense diet helps manage weight, supports hormonal balance, reduces inflammation, and provides essential nutrients for overall health and performance.",
      },
      {
        title: "Supplement: Omega-3 Fatty Acids",
        description:
          "Consider incorporating omega-3 fatty acids through supplementation or consuming foods rich in these healthy fats, such as fatty fish (salmon, sardines), chia seeds, flaxseeds, and walnuts.Omega-3 fatty acids possess anti-inflammatory properties and support brain health, cardiovascular function, and joint health. During perimenopause, they can help alleviate symptoms like joint pain, mood swings, and cognitive changes, while also supporting overall well-being and optimal athletic performance.",
      },
    ],
    postTraining: [
      {
        title: "Foam Rolling and Stretching",
        description:
          "ncorporate foam rolling and stretching exercises targeting areas prone to tightness and muscle soreness.\n\nDuring the perimenopause phase, hormonal fluctuations can contribute to increased muscle stiffness and decreased flexibility. Foam rolling and stretching help alleviate muscle tension, improve range of motion, and reduce the risk of injuries.",
      },
      {
        title: "Balanced Post-Workout Meal",
        description:
          "Consume a balanced post-workout meal that includes a combination of carbohydrates, lean protein, and healthy fats.Perimenopause is characterized by metabolic changes, including a decline in estrogen levels. A balanced post-workout meal provides essential nutrients to support muscle recovery, replenish glycogen stores, and maintain a healthy metabolism.",
      },
      {
        title: "Mind-Body Recovery Techniques",
        description:
          "Encourage mind-body recovery techniques such as meditation, deep breathing exercises, or yoga. Perimenopause can be accompanied by increased stress levels and fluctuations in mood. Mind-body recovery techniques help promote relaxation, reduce stress, and improve mental well-being. They also support hormonal balance and enhance recovery from workouts.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Perimenopause" },
      {
        title: "Hormone Levels",
        description:
          "During perimenopause, hormone levels, particularly estrogen and progesterone, fluctuate irregularly. Estrogen levels gradually decline, while follicle-stimulating hormone (FSH) and luteinizing hormone (LH) levels may increase. These hormonal changes contribute to menstrual irregularities and various physical and emotional symptoms experienced during this transitional phase.",
      },
    ],
    athleticPerformance: [
      {
        title: "Hormonal Changes",
        description:
          "During perimenopause, there is a gradual decline in estrogen and progesterone levels. These hormonal fluctuations can affect energy levels, muscle strength, and recovery. Lower estrogen levels may contribute to reduced muscle mass and strength, while fluctuating hormone levels can lead to fatigue and decreased exercise tolerance.",
      },
      {
        title: "Metabolic Changes",
        description:
          "Metabolic changes occur during perimenopause, including a decrease in insulin sensitivity and a potential increase in body fat accumulation, particularly around the midsection. These changes can impact body composition, body weight, and overall metabolism, potentially affecting athletic performance and endurance.",
      },
      {
        title: "Menstrual Irregularities",
        description:
          "Perimenopause is characterized by menstrual cycle irregularities, including skipped periods, longer or shorter cycles, and heavier or lighter flow. These irregularities can make it challenging to predict and plan training cycles, which may affect performance and training consistency. Additionally, irregular periods can be associated with symptoms like bloating, breast tenderness, and mood swings, which can impact an athlete's comfort and mental well-being.",
      },
    ],
    exerciseFocus: [
      {
        title: "Strength Training",
        description: `Compound Exercises: Include compound movements that engage multiple muscle groups simultaneously, such as squats, deadlifts, lunges, and push-ups. These exercises help maintain and build muscle mass, improve bone density, and support overall strength and functional fitness.
            Resistance Training: Incorporate resistance training with free weights, resistance bands, or weight machines to progressively challenge the muscles and promote strength gains.`,
      },
      {
        title: "Cardiovascular Exercises",
        description: `Low-Impact Cardio: Engage in low-impact cardiovascular activities that minimize joint stress, such as brisk walking, cycling, swimming, or using an elliptical machine. These exercises support cardiovascular health, burn calories, and improve endurance without excessive impact on the joints.
            High-Intensity Interval Training (HIIT): Incorporate HIIT workouts with short bursts of intense exercise alternated with periods of active recovery. HIIT can enhance cardiovascular fitness, boost metabolism, and promote fat loss.`,
      },
      {
        title: "Flexibility and Balance Training",
        description: `Yoga or Pilates: Include regular sessions of yoga or Pilates to improve flexibility, joint mobility, and core strength. These exercises also promote relaxation, reduce stress, and aid in maintaining a healthy posture.
            Balance Exercises: Incorporate balance exercises like single-leg movements, heel-to-toe walks, and standing on unstable surfaces (e.g., foam pads or balance boards). Improving balance helps prevent falls, maintain stability, and support functional movements.`,
      },
    ],
    recommendations: [
      {
        title: "Calcium and Vitamin D",
        description:
          "During perimenopause, there is a decline in estrogen levels, which can lead to decreased bone density and increased risk of osteoporosis. Adequate calcium and vitamin D intake supports bone health and reduces the risk of fractures.",
      },
      {
        title: "Magnesium",
        description:
          "Magnesium plays a crucial role in muscle function, energy production, and sleep regulation. It can help reduce muscle cramps, support relaxation, alleviate mood swings, and aid in quality sleep, which can be disrupted during perimenopause.",
      },
      {
        title: "Omega-3 Fatty Acids",
        description:
          "Omega-3 fatty acids possess anti-inflammatory properties, support cardiovascular health, and help manage symptoms like joint pain and inflammation commonly experienced during perimenopause. They also support cognitive function and mood stability.",
      },
      {
        title: "Probiotics",
        description:
          "Perimenopause is associated with hormonal fluctuations that can impact gut health and digestion. Probiotics support gut microbiota balance, aid in nutrient absorption, and promote digestive health. They can also help alleviate symptoms like bloating and irregular bowel movements.",
      },
    ],
    raceDayDuringPhase: [
      {
        title: "Hydration and Nutrition",
        description:
          "Pay attention to hydration and fueling strategies. Ensure adequate fluid intake before, during, and after the race. Consume a balanced meal or snack containing carbohydrates, protein, and healthy fats to provide sustained energy and support muscle function.",
      },
      {
        title: "Symptom Management",
        description:
          "Address any perimenopause symptoms that may affect performance, such as hot flashes, mood swings, or sleep disturbances. Employ relaxation techniques, cooling measures, and strategies to manage stress and anxiety before the race.",
      },
      {
        title: "Warm-up and Stretching",
        description:
          "Prioritize a thorough warm-up routine that includes dynamic movements to increase blood flow, warm up the muscles, and prepare the body for the demands of the race. Include stretching exercises to maintain flexibility and reduce the risk of injury.",
      },
      {
        title: "Pace and Energy Management",
        description:
          "Be mindful of energy levels and pacing. Perimenopause athletes may experience fluctuations in energy and fatigue. Plan a race strategy that accounts for potential variations in endurance and adjust pace accordingly.",
      },
    ],
  },
  7: {
    label: "Menopause",
    preTraining: [
      {
        title: "Proper Hydrationc",
        description:
          "Ensure adequate hydration before exercise by drinking water or consuming hydrating beverages like electrolyte-rich drinks or coconut water. Menopause can increase the risk of dehydration due to hormonal changes, so staying hydrated before a workout is important for optimal performance and temperature regulation.",
      },
      {
        title: "Dynamic Warm-up",
        description:
          "Engage in a dynamic warm-up routine that includes dynamic stretches and mobility exercises to prepare the body for exercise. Focus on movements that target major muscle groups and joints, helping to improve flexibility, increase blood flow, and reduce the risk of injury. This can also help alleviate joint stiffness and discomfort that may be associated with menopause.",
      },
    ],
    traning: [
      {
        title: "Incorporate Strength Training",
        description:
          "During menopause, incorporating regular strength training exercises is crucial. Focus on compound movements such as squats, lunges, deadlifts, and push-ups to build and maintain muscle mass, support bone density, and enhance metabolism. Aim for at least two to three strength training sessions per week, gradually increasing weights and resistance over time.",
      },
      {
        title: "Include High-Intensity Interval Training (HIIT)",
        description:
          "Incorporating HIIT workouts can be highly effective during menopause. HIIT involves short bursts of intense exercise followed by brief recovery periods. It helps improve cardiovascular fitness, boost metabolism, and promote fat burning. Engage in activities such as sprinting, cycling, or bodyweight exercises performed at maximum effort for a set duration, followed by active recovery. Start with one to two HIIT sessions per week and gradually increase intensity and duration as fitness improves.",
      },
    ],
    postTraining: [
      {
        title: "Proper Hydration and Replenishment",
        description:
          "After training, ensure adequate hydration by consuming water or electrolyte-rich beverages to replenish fluids lost during exercise. Additionally, focus on consuming a post-workout meal or snack that includes a balance of carbohydrates and protein to support muscle recovery and replenish energy stores. Examples include a protein smoothie with fruits and Greek yogurt or a balanced meal with lean protein, whole grains, and vegetables.",
      },
      {
        title: "Cool-Down and Stretching",
        description:
          "Engage in a cool-down routine that includes light aerobic exercise or stretching to gradually lower heart rate and promote muscle recovery. This can involve walking or cycling at a slower pace, followed by gentle stretching exercises targeting major muscle groups. Cooling down and stretching can help reduce post-workout muscle soreness, improve flexibility, and promote relaxation and overall well-being.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Menopause" },
      {
        title: "Hormone Levels",
        description:
          "During menopause, estrogen and progesterone levels decline, while follicle-stimulating hormone (FSH) levels increase. These hormonal changes contribute to the symptoms and physical changes experienced during menopause, such as hot flashes, mood swings, and changes in bone density.",
      },
    ],
    athleticPerformance: [
      {
        title: "Estrogen",
        description:
          "Menopause can lead to a decline in estrogen levels, which may result in reduced muscle mass, strength, and energy levels, affecting athletic performance.",
      },
      {
        title: "Hormonal Changes",
        description:
          "The hormonal changes during menopause can increase the risk of osteoporosis and bone fractures, potentially impacting an athlete's ability to train and compete.",
      },
      {
        title: "Vasomotor",
        description:
          "Vasomotor symptoms like hot flashes and night sweats can disrupt thermoregulation during exercise, affecting endurance and concentration in menopausal athletes.",
      },
    ],
    exerciseFocusDesc:
      "During menopause, it is beneficial for athletes to focus on exercises that support overall health, address specific changes associated with menopause, and promote strength, flexibility, and cardiovascular fitness.",
    exerciseFocus: [
      {
        title: "Strength Training",
        description: `Compound Movements: Include exercises that target multiple muscle groups simultaneously, such as squats, deadlifts, lunges, and push-ups. These exercises promote muscle strength and endurance, enhance bone density, and support functional movement patterns.
            Resistance Training: Incorporate resistance bands, dumbbells, or weight machines to progressively challenge the muscles and promote strength gains.`,
      },
      {
        title: "Weight-Bearing Exercises",
        description: `Walking or Jogging: Engage in weight-bearing activities like brisk walking or jogging, which help maintain bone density, improve cardiovascular fitness, and support overall weight management.
            Hiking or Stair Climbing: Incorporate activities that involve inclines or stairs to further challenge the lower body muscles and provide cardiovascular benefits.`,
      },
      {
        title: "Flexibility and Balance",
        description: `Yoga or Pilates: Participate in yoga or Pilates classes that focus on flexibility, balance, and core strength. These exercises can improve posture, enhance mobility, and promote relaxation and stress reduction.
            Tai Chi: Consider practicing Tai Chi, a gentle form of exercise that incorporates flowing movements, deep breathing, and mindfulness. Tai Chi enhances balance, coordination, and body awareness.`,
      },
    ],
    recommendations: [
      {
        title: "Calcium and Vitamin D",
        description: `Ensure sufficient intake of calcium-rich foods or consider supplements to support bone health during menopause.
            Iron: Include iron-rich foods in the diet or take supplements if needed to prevent anemia and support energy levels.`,
      },
      {
        title: "Omega-3 Fatty Acids",
        description:
          "Incorporate omega-3-rich foods or supplements to reduce inflammation, support heart health, and enhance cognitive function.",
      },
      {
        title: "Probiotics",
        description:
          "Consume fermented foods or probiotic supplements to promote a healthy gut microbiome and support overall health and immune function during menopause.",
      },
    ],
    raceDayDuringPhase: [
      {
        title: "Hydration and Cooling",
        description:
          "Pay extra attention to hydration, as menopause can increase the risk of hot flashes and sweating. Drink plenty of fluids before, during, and after the race to stay properly hydrated. Utilize cooling strategies such as wearing breathable clothing and using cold towels or ice packs to manage body temperature during the event.",
      },
      {
        title: "Energy and Nutrition",
        description:
          "Ensure adequate fueling before the race by consuming a balanced meal or snack rich in carbohydrates for sustained energy. Consider incorporating foods that help manage menopause symptoms, such as those containing phytoestrogens (e.g., soy products, flaxseeds), which may provide mild relief. Pay attention to any dietary triggers for hot flashes or digestive discomfort and plan accordingly.",
      },
      {
        title: "Symptom Management:",
        description:
          "Be prepared to manage symptoms that may arise during the race. For example, if hot flashes occur, find ways to cool down, such as seeking shaded areas or using handheld fans. Maintain a positive mindset and use relaxation techniques or focused breathing exercises to manage any anxiety or stress that may arise.",
      },
      {
        title: "Recovery and Self-Care",
        description:
          "After the race, prioritize recovery and self-care. Incorporate gentle stretching or foam rolling to alleviate muscle tension and support recovery. Ensure proper nutrition and hydration during the post-race period to replenish energy stores and promote muscle repair. Pay attention to rest and quality sleep, as menopause can disrupt sleep patterns, and prioritize adequate rest days in the training schedule.",
      },
    ],
  },
  8: {
    label: "Active Phase",
    preTraining: [
      {
        title: "Focus on Strength Training",
        description:
          "During the active birth control phase, hormone levels are more stable, which can contribute to improved strength and muscle development. Incorporating strength training exercises can maximize performance by taking advantage of this hormonal balance and promoting gains in muscle strength and power.",
      },
      {
        title: "Optimize Nutrient Timing:",
        description:
          "Consume a balanced meal or snack containing carbohydrates and protein approximately 1-2 hours before the workout. This timing allows for proper digestion and absorption of nutrients, providing the necessary fuel to enhance performance during the active birth control phase.",
      },
      {
        title: "Engage in Dynamic Warm-up",
        description:
          "Prioritize a dynamic warm-up routine that includes joint mobilization exercises and dynamic stretches. This helps increase blood flow, activates major muscle groups, and improves range of motion, preparing the body for optimal performance during the active birth control phase.",
      },
    ],
    traning: [
      {
        title: "Incorporate High-Intensity Interval Training (HIIT)",
        description:
          "HIIT workouts are effective during the active phase of birth control because they promote cardiovascular fitness, improve metabolic efficiency, and enhance overall athletic performance in a time-efficient manner.",
      },
      {
        title: "Focus on Strength Training with Progressive Overload",
        description:
          "Strength training with progressive overload during the active phase of birth control can maximize performance by increasing muscle strength and power, improving bone density, and enhancing overall body composition.",
      },
      {
        title: "Implement Sport-Specific Skill Drills",
        description:
          "Engaging in sport-specific skill drills during the active phase of birth control helps athletes refine their technique, enhance coordination, and improve sports-specific performance, ultimately optimizing their overall athletic abilities.",
      },
    ],
    postTraining: [
      {
        title: "Proper Replenishment of Electrolytes",
        description:
          "Replenishing electrolytes after training can help maintain fluid balance, enhance muscle function, and support optimal performance during the active phase of birth control.",
      },
      {
        title: "High-Quality Protein Intake",
        description:
          "Consuming high-quality protein sources post-training aids in muscle repair and growth, which can be particularly beneficial during the active phase of birth control to support muscle recovery and enhance athletic performance.",
      },
      {
        title: "Adequate Sleep and Recovery",
        description:
          "Prioritizing adequate sleep and recovery after training sessions during the active phase of birth control promotes hormonal balance, muscle repair, and overall athletic performance optimization.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Active Phase" },
      {
        title: "Hormone Levels",
        description:
          "Active Phase refers to the phase of the menstrual cycle when hormone levels, particularly estrogen, begin to rise. This phase includes the follicular phase and ovulation. Estrogen is associated with improved energy, mood, and exercise performance. Many athletes tend to experience improved performance and higher energy levels during this phase.",
      },
    ],
    athleticPerformance: [
      {
        title: "Stability",
        description:
          "Active phase birth control can positively impact athletic performance by providing hormonal stability, enhancing recovery, and reducing menstrual symptoms.",
      },
      {
        title: "Scheduling Convenience",
        description:
          " Controlling the menstrual cycle through active phase birth control allows athletes to manipulate and optimize their training and competition schedules.",
      },
      {
        title: "Varying Results",
        description:
          "Potential side effects of active phase birth control include fluid retention, cardiovascular considerations, and individual variations in response, which should be carefully monitored and discussed with healthcare professionals.",
      },
    ],
    exerciseFocusDesc:
      "For an athlete using active phase birth control, which typically involves continuous hormone intake without the hormone-free week, the hormone levels remain relatively stable throughout the menstrual cycle. As a result, there may be fewer notable fluctuations in energy levels, metabolism, and strength compared to a natural menstrual cycle. Therefore, the focus of exercises can be more consistent throughout the cycle",
    exerciseFocus: [
      {
        title: "Strength Training",
        description: `Compound Movements: Include exercises such as squats, deadlifts, lunges, bench presses, and rows. These movements engage multiple muscle groups simultaneously, promoting overall strength and muscle development.
            Progressive Overload: Gradually increase the resistance or intensity over time to continually challenge the muscles and promote strength gains.`,
      },
      {
        title: "Cardiovascular Exercises",
        description: `Interval Training: Alternate between periods of high-intensity effort and active recovery. This type of training can improve cardiovascular fitness, boost metabolism, and enhance endurance.
            High-Intensity Interval Training (HIIT): Engage in short bursts of intense exercise followed by short recovery periods. HIIT workouts can be time-efficient and effective for improving cardiovascular fitness and fat burning.`,
      },
    ],
    recommendations: [
      {
        title: "Iron",
        description:
          "Active phase birth control may slightly increase the risk of iron deficiency. Iron is crucial for oxygen transport and energy production, making it essential for athletic performance. Consider including iron-rich foods in the diet, such as lean red meats, poultry, fish, beans, lentils, spinach, and fortified cereals. If needed, a healthcare professional can assess iron levels and recommend iron supplements.",
      },
      {
        title: "Vitamin D",
        description:
          "Some birth control methods, especially those containing progestin, may slightly decrease vitamin D levels. Vitamin D plays a crucial role in bone health, immune function, and muscle strength. Adequate sun exposure, fortified dairy products, fatty fish, and egg yolks are dietary sources of vitamin D. If necessary, a healthcare professional can assess vitamin D levels and recommend supplementation.",
      },
      {
        title: "B-vitamins",
        description:
          "Active phase birth control use can deplete certain B-vitamins, including vitamin B6, B9 (folate), and B12. B-vitamins are involved in energy metabolism and red blood cell production. Incorporate foods such as whole grains, legumes, leafy greens, eggs, and lean meats to obtain these essential vitamins. If required, a healthcare professional can evaluate B-vitamin levels and suggest appropriate supplementation.",
      },
      {
        title: "Omega-3 Fatty Acids",
        description:
          "Omega-3 fatty acids offer anti-inflammatory properties, support cardiovascular health, and aid in muscle recovery. They can be obtained from fatty fish (e.g., salmon, mackerel), chia seeds, flaxseeds, and walnuts. If the diet falls short, omega-3 supplements such as fish oil or algae-based supplements can be considered, following guidance from a healthcare professional.",
      },
    ],
    raceDayDuringPhase: [
      {
        title: "Timing of Medication",
        description:
          "If the athlete is taking active phase birth control pills, it's important to maintain consistent timing of medication. Ensure that the athlete takes the prescribed pill at the usual time to maintain hormonal stability and avoid potential disruptions.",
      },
      {
        title: "Hydration and Nutrition",
        description:
          "Proper hydration and nutrition are essential for optimal performance. Encourage the athlete to stay hydrated before, during, and after the race. Additionally, provide guidance on pre-race meals or snacks that include a balance of carbohydrates, protein, and healthy fats to fuel performance.",
      },
      {
        title: "Menstrual Symptoms",
        description:
          "Depending on the individual, some athletes on active phase birth control may still experience menstrual symptoms. If the athlete is experiencing symptoms like bloating, breast tenderness, or mood changes, consider strategies to alleviate discomfort and support overall well-being, such as wearing comfortable clothing, using heat packs for pain relief, and practicing relaxation techniques.",
      },
      {
        title: "Communication with Healthcare Provider",
        description:
          "It's advisable for athletes on active phase birth control to maintain open communication with their healthcare provider. They can discuss any concerns or specific needs related to race day, such as potential adjustments to medication timing or addressing any side effects that might affect performance.",
      },
    ],
  },
  9: {
    label: "Inactive Phase",
    preTraining: [
      {
        title: "Adequate Carbohydrate Intake",
        description:
          "Consume a meal or snack rich in carbohydrates before workouts to provide a readily available source of energy. This is particularly important during the inactive birth control phase when hormone levels are lower, as it helps optimize glycogen stores and supports sustained exercise performance.",
      },
      {
        title: "Focus on Strength Training:",
        description:
          "Prioritize strength training exercises during the inactive birth control phase to take advantage of potential increases in muscle strength and power. Hormone fluctuations during this phase can contribute to improved neuromuscular function, making it an ideal time to focus on resistance training and maximize gains in strength and muscle development.",
      },
      {
        title: "Optimize Recovery Strategies",
        description:
          "Implement effective post-workout recovery strategies, such as foam rolling, stretching, and adequate rest, to support muscle repair and minimize fatigue. During the inactive birth control phase, the body may require additional recovery and repair time, making proper post-workout recovery crucial for maintaining optimal performance.",
      },
    ],
    traning: [
      {
        title: "Focus on Strength and Power Training",
        description:
          "During the inactive phase of birth control, hormone levels are relatively stable. This provides an opportunity to prioritize strength and power training, as the body is less likely to be affected by hormonal fluctuations that could impact performance. Engaging in compound movements and explosive exercises can help improve muscular strength and power output.",
      },
      {
        title: "Implement High-Intensity Interval Training (HIIT)",
        description:
          "HIIT workouts can be particularly beneficial during the inactive phase of birth control. HIIT combines short bursts of intense exercise with periods of active recovery, allowing for an efficient and effective workout. This type of training can enhance cardiovascular fitness, metabolic function, and overall endurance.",
      },
      {
        title: "Incorporate Active Recovery and Mobility Work",
        description:
          "While the inactive phase of birth control generally has fewer hormonal fluctuations, it is still essential to prioritize recovery and mobility. Incorporating active recovery exercises, such as foam rolling, light stretching, and low-intensity activities, can help reduce muscle soreness, enhance flexibility, and promote overall well-being.",
      },
    ],
    postTraining: [
      {
        title: "Adequate Recovery",
        description:
          "Emphasize sufficient rest and recovery after training sessions during the inactive phase of birth control. This allows the body to repair and rebuild muscle tissue, optimizing performance by reducing the risk of overtraining and promoting muscular adaptation.",
      },
      {
        title: "Nutrient Timing",
        description:
          "Pay attention to post-workout nutrition timing, aiming to consume a balanced meal or snack within 30-60 minutes after training. This helps replenish glycogen stores, supports muscle recovery, and enhances overall performance during the inactive phase of birth control.",
      },
      {
        title: "Sleep Quality",
        description:
          "Prioritize quality sleep as a crucial aspect of recovery during the inactive phase of birth control. Optimal sleep duration and quality promote hormone regulation, tissue repair, and cognitive function, contributing to improved athletic performance and overall well-being.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Inactive Phase" },
      { title: "When", description: 'aka "Placebo Week"' },
      {
        title: "Hormone Levels",
        description:
          "Inactive Phase refers to the phase of the menstrual cycle when hormone levels are low, particularly estrogen and progesterone. It is typically the time when menstruation occurs. During this phase, some athletes may experience fatigue or reduced energy levels, which can impact athletic performance.",
      },
    ],
    athleticPerformance: [
      {
        title: "Hormones",
        description:
          "Inactive phase birth control may disrupt hormonal balance, potentially affecting energy levels and overall athletic performance.",
      },
      {
        title: "Hydration",
        description:
          "Certain forms of birth control can contribute to fluid retention and temporary weight gain during the inactive phase, which may impact an athlete's comfort and performance.",
      },
      {
        title: "Varying Results",
        description:
          "The impact of inactive phase birth control on athletic performance can vary among individuals, highlighting the importance of monitoring individual responses and collaborating with healthcare professionals to find the most suitable birth control method.",
      },
    ],
    exerciseFocus: [
      {
        title: "Strength Training",
        description: `Compound Movements: Incorporate exercises that target multiple muscle groups simultaneously, such as squats, deadlifts, lunges, bench presses, and overhead presses. These exercises build overall strength and promote muscle balance.
            Resistance Training: Use free weights, resistance bands, or weight machines to provide resistance and challenge the muscles. Aim for a variety of exercises targeting different muscle groups, including upper body, lower body, and core.`,
      },
      {
        title: "Cardiovascular Exercise",
        description: `Low-Impact Options: Engage in cardio exercises that are gentle on the joints, such as brisk walking, cycling, swimming, or using an elliptical machine. These exercises provide cardiovascular benefits without excessive impact or strain.
            Interval Training: Incorporate high-intensity interval training (HIIT) sessions to improve cardiovascular fitness and burn calories efficiently. Alternate between periods of intense effort and active recovery to challenge the body and increase endurance.
            `,
      },
      {
        title: "Flexibility and Mobility Training",
        description: `Stretching: Include static stretching exercises to improve flexibility and prevent muscle imbalances. Focus on major muscle groups, including the hips, hamstrings, quadriceps, shoulders, and chest.
            Yoga or Pilates: Participate in yoga or Pilates classes to improve core strength, balance, and flexibility. These practices also promote relaxation and stress reduction, which can be beneficial for overall well-being.`,
      },
    ],
    recommendations: [
      {
        title: "Vitamin D",
        description:
          "Adequate intake of vitamin D supports bone health, immune function, and muscle strength, and it can be obtained through sunlight exposure and certain food sources or supplementation if needed.",
      },
      {
        title: "Calcium",
        description:
          "Calcium is essential for bone health, muscle function, and nerve transmission, and it can be obtained from dairy products, leafy green vegetables, and fortified plant-based milk, with supplementation as necessary.",
      },
      {
        title: "Iron",
        description:
          "Iron is crucial for oxygen transport, energy production, and immune function, and it can be obtained from sources such as red meat, legumes, and fortified cereals, with supplementation guided by professional advice and blood tests if needed.",
      },
      {
        title: "Omega-3 Fatty Acids",
        description:
          "Omega-3 fatty acids, found in fatty fish, chia seeds, flaxseeds, and walnuts, have anti-inflammatory properties and support cardiovascular health and brain function, with supplementation as an option if dietary intake is insufficient.",
      },
    ],
    raceDayDuringPhase: [
      {
        title: "Menstrual Symptoms",
        description:
          "If the inactive phase coincides with race day, athletes may experience menstrual symptoms such as bloating, breast tenderness, or mood swings. It's important to anticipate and manage these symptoms with strategies such as wearing comfortable clothing, using supportive undergarments, and practicing stress-reduction techniques.",
      },
      {
        title: "Hydration and Nutrition",
        description:
          "Pay attention to hydration and nutrition leading up to and during the race. Adequate fluid intake and consuming balanced meals/snacks can help maintain energy levels and support performance, particularly if there is potential water retention or changes in appetite due to hormonal fluctuations.",
      },
      {
        title: "Medication Timing",
        description:
          "If taking birth control pills, ensure consistent timing of medication administration, even during the inactive phase. Deviating from the prescribed schedule could impact the effectiveness of the contraceptive method.",
      },
      {
        title: "Mental Focus",
        description:
          "Recognize the influence of hormonal fluctuations on mood and mental focus. Implementing pre-race routines, visualization techniques, and positive self-talk can help athletes maintain focus and confidence.",
      },
      {
        title: "Menstrual Flow",
        description:
          "If an athlete experiences bleeding during the inactive phase, consider using appropriate menstrual products that provide comfort and protection during physical activity.",
      },
    ],
  },
  10: {
    label: "Progestin Only",
    preTraining: [
      {
        title: "Adequate Warm-up",
        description:
          "Engage in a comprehensive warm-up routine that includes dynamic stretching and activation exercises to improve blood flow and enhance muscle readiness, as progestin-only birth control may slightly increase body temperature and affect exercise tolerance.",
      },
      {
        title: "Hydration",
        description:
          "Ensure optimal hydration before workouts, as progestin-only birth control can potentially contribute to fluid retention. Proper hydration supports performance, prevents discomfort, and aids in maintaining electrolyte balance.",
      },
      {
        title: "Focus on Strength Training",
        description:
          "Emphasize strength training exercises during the progestin-only birth control phase. Progestin may have a minimal impact on muscle protein synthesis and metabolism, making strength training beneficial for building and maintaining muscle mass and strength during this phase.",
      },
    ],
    traning: [
      {
        title: "Focus on Strength Training",
        description:
          "Incorporate strength training exercises during the progestin-only birth control phase to maximize performance. Strength training can help maintain muscle mass, enhance bone density, and improve overall strength and power, which can be particularly beneficial during this phase when hormone levels may be impacting energy levels.",
      },
      {
        title: "Prioritize Recovery and Rest",
        description:
          "Emphasize adequate recovery and rest during the progestin-only birth control phase to optimize performance. Hormonal changes during this phase may contribute to increased fatigue and reduced energy levels. Allowing for sufficient rest and recovery between training sessions can help prevent overtraining, reduce the risk of injuries, and support overall performance.",
      },
      {
        title: "Maintain Consistency and Adaptability",
        description:
          "Stay consistent with training routines but be adaptable to individual needs and energy levels during the progestin-only birth control phase. Some athletes may experience fluctuations in energy and motivation, so adjusting training intensity or volume based on how they feel can help optimize performance while still allowing for appropriate recovery and adaptation.",
      },
    ],
    postTraining: [
      {
        title: "Incorporate Adequate Recovery Time",
        description:
          "Allow for sufficient rest and recovery between training sessions to optimize performance. This is particularly important during the progestin-only birth control phase, as the elevated progesterone levels may contribute to increased fatigue and potentially impact exercise tolerance.",
      },
      {
        title: "Focus on Hydration",
        description:
          "Hydrate adequately post-training to support recovery and minimize the potential fluid retention associated with progestin-only birth control. Proper hydration can help alleviate bloating and promote optimal physiological function.",
      },
      {
        title: "Optimize Nutrition",
        description:
          "Consume nutrient-dense foods after training to aid in muscle recovery and replenish energy stores. Pay attention to adequate protein intake to support muscle repair and consider incorporating magnesium-rich foods, as progestin-only birth control can affect magnesium levels. These strategies can help maximize performance and mitigate potential hormonal influences during this phase.",
      },
    ],
    cycleInfo: [
      { title: "Cycle Phase", description: "Progestin Only" },
      {
        title: "Hormone Levels",
        description:
          "Progestin-Only Birth Control methods such as mini-pills or hormonal IUDs, contain only synthetic progesterone (progestin) and do not contain estrogen. Progestin can have varying effects on athletic performance. Some athletes may experience increased fatigue, decreased energy, or mood changes as a side effect of progestin-only birth control. However, the impact can vary between individuals, and some athletes may not experience any significant performance changes.",
      },
    ],
    athleticPerformance: [
      {
        title: "Energy & Strength",
        description:
          "Progestin-only birth control methods, lacking estrogen, may result in slight decreases in muscle strength and endurance due to hormonal changes.",
      },
      {
        title: "Water Retention",
        description:
          "Some individuals using progestin-only birth control may experience water retention or bloating, potentially affecting comfort and body weight perception during athletic performance.",
      },
      {
        title: "Varying Results",
        description:
          "The impact of progestin-only birth control on athletic performance varies individually, with some athletes experiencing minimal changes while others may notice differences in energy levels, recovery, and overall performance.",
      },
    ],
    exerciseFocus: [
      {
        title: "Strength Training",
        description:
          "Strength training exercises can be highly beneficial for athletes regardless of their birth control method. Focus on compound exercises that target major muscle groups, such as squats, deadlifts, lunges, bench presses, and overhead presses. Strength training helps build muscle mass, increase strength and power, improve bone density, and support overall athletic performance.",
      },
      {
        title: "Cardiovascular Exercises",
        description:
          "Engaging in cardiovascular exercises is important for cardiovascular health, endurance, and maintaining overall fitness. Opt for exercises that align with fitness goals, such as running, cycling, swimming, rowing, or high-intensity interval training (HIIT). Cardiovascular exercise improves heart and lung health, boosts energy levels, and can contribute to weight management.",
      },
      {
        title: "Flexibility and Mobility Training",
        description:
          "Incorporate exercises that enhance flexibility and mobility, such as yoga, Pilates, or dynamic stretching routines. These exercises improve joint range of motion, muscular flexibility, and balance, which can enhance overall athletic performance and reduce the risk of injuries.",
      },
      {
        title: "Cross-Training",
        description:
          "Consider incorporating cross-training into your routine. This involves participating in a variety of activities to challenge different muscle groups, prevent overuse injuries, and maintain overall fitness. Incorporate activities like dance classes, hiking, recreational sports, or group fitness classes to add diversity and enjoyment to your training regimen.",
      },
    ],
    recommendations: [
      {
        title: "Calcium",
        description:
          "Ensure sufficient intake of calcium-rich foods or consider a calcium supplement to support bone health while on progestin-only birth control.",
      },
      {
        title: "Vitamin D",
        description:
          "Obtain vitamin D from sunlight exposure and vitamin D-rich foods, or consider a supplement to address potential deficiencies caused by progestin-only birth control.",
      },
      {
        title: "Iron",
        description:
          "Consume iron-rich foods and consider an iron supplement under the guidance of a healthcare professional to prevent iron deficiency anemia associated with increased menstrual bleeding.",
      },
      {
        title: "B-vitamins (B6 and B12)",
        description:
          "Include foods high in B-vitamins in your diet or consider a B-vitamin complex supplement to support energy production and overall well-being while on progestin-only birth control.",
      },
    ],
    raceDayDuringPhase: [
      {
        title: "Hydration",
        description:
          "Progestin-only birth control can potentially contribute to increased water retention and bloating. It's important for athletes to ensure proper hydration leading up to the race, monitoring their fluid intake and electrolyte balance to minimize any discomfort or performance impact.",
      },
      {
        title: "Temperature Regulation",
        description:
          "Progestin-only birth control may affect the body's temperature regulation. Athletes should be mindful of race conditions, particularly if they are participating in endurance events or in hot and humid environments. Adequate cooling strategies, such as hydration, wearing appropriate clothing, and utilizing cooling aids, can help manage body temperature during the race.",
      },
      {
        title: "Recovery and Rest",
        description:
          "Progestin-only birth control can influence fatigue levels and recovery. Athletes should prioritize proper post-race recovery, including adequate rest, nutrition, and sleep. Listening to the body's signals and allowing for sufficient recovery time after the race is crucial to optimize performance and minimize the risk of overtraining or injury.",
      },
    ],
  },
};

export const sessionBulderAccordeonContent = [
  {
    headline: "Workout style",
    description:
      "Focus on power-based exercises, plyometrics, or speed training to take advantage of the peak in estrogen and testosterone levels, which can enhance strength and power.",
  },
  {
    headline: "Nutrition",
    description:
      "Prioritize protein-rich foods to support muscle repair and recovery. Include foods high in calcium and vitamin D for bone health.",
  },
  {
    headline: "Supplements",
    description:
      "Calcium and vitamin D supplements may be beneficial if dietary intake is inadequate, as these nutrients are important for bone health and muscle function.",
  },
  {
    description:
      "Reasoning: The surge in estrogen and testosterone during ovulation enhances strength and power. Power-based exercises and speed training can help capitalize on these hormonal advantages, while proper nutrition supports muscle repair and bone health.",
  },
];

export const weekdays = ["M", "T", "W", "T", "F", "S", "S"];
export const weekdayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
