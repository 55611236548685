import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Camera from "../../assets/icons/camera.svg";
// Actions
import { deleteTeam } from "../../_helpers/Requests";
import { history } from "../../index";
import { createNotification } from "../../store/app/actions";
import { fetchTeamDetails, transferOwnership, updateTeamLogo, updateTeamName } from "../../store/settings/actions";



const UpdateTeam = ({ tabName }) => {
  const dispatch = useDispatch();
  const { teamDetails } = useSelector((state) => state.settings);
  const [profile_data, set_profile_data] = useState({
    name: "",
    primary_coach: null,
    coaches: [],
    logo_url: "",
  });

  const teamId = window.localStorage.getItem("team_Id");
  const [image_uploading, setImageUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [enabled, setEnabled] = useState({
    name: false,
    primary_coach: false,
    delete_primary_coach: false
  });

  // Common Loading button
  const LoadingBlackButton = ({ label, submitting, className, status }) => (
    <button htmltype="submit" disabled={status} className={className}>
      {submitting ? (
        <div className="flex justifyCenter alignCenter">
          <div className="custom_button_loader" />
        </div>
      ) : (
        <p style={{ margin: 0 }}>{label}</p>
      )}
    </button>
  );

  // Error Handler
  const onError = async (error) => {
    setSubmitting(false);
    setImageUploading(false);
    dispatch(createNotification("error-toast", error));
  };

  // Success Handler
  const onSuccess = async (field, value) => {
    setSubmitting(false);
    if (field === "name") {
      window.localStorage.setItem("team_name", value);
    }
    if (field === "logo_url") {
      setImageUploading(false);
    }
    set_profile_data({ ...profile_data, [field]: value });
    history.push("/dashboard/management");
  };

  // Update team image
  const onUpdateImage = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      var totalBytes = files[0].size;
      if (files[0].type === "image/jpeg" || files[0].type === "image/png") {
        if (totalBytes <= 2000000) {
          setImageUploading(true);
          dispatch(updateTeamLogo(reader.result, teamId, onSuccess, onError));
        } else {
          dispatch(createNotification("error-toast", "Please upload image file of size 2MB or below"));
        }
      } else {
        dispatch(createNotification("error-toast", "Please select an image file"))
      }
    };
    reader.readAsDataURL(files[0]);
  }

  // Update team name
  const onUpdateTeamName = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const values = {};
    if (enabled.name) {
      values.name = profile_data.name;
    }
    values.team_id = teamId;
    dispatch(updateTeamName(values, onSuccess, onError));
  };

  // Transfer ownership to other coach
  const onTransferOwnership = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const values = {};
    for (var i = 0; i < profile_data.coaches.length; i++) {
      if (profile_data.primary_coach === teamDetails.coaches[i].email) {
        values.coach_id = teamDetails.coaches[i].pk;
        dispatch(transferOwnership(values, onSuccess, onError));
      }
    }
  };

  // Delete team
  const onDeleteTeam = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    deleteTeam(teamId, onError);
  };

  useEffect(() => {
    if (teamDetails) {
      set_profile_data({
        name: teamDetails['name'],
        logo_url: teamDetails['logo_url'],
        coaches: teamDetails['coaches'],
        primary_coach: teamDetails['primary_coach'].email,
      });
    }
  }, [teamDetails]);

  const setFieldValues = (field_name, value, status) => {
    setEnabled({ ...enabled, [field_name]: status });
    set_profile_data({ ...profile_data, [field_name]: value });
  }

  useEffect(() => {
    dispatch(fetchTeamDetails(teamId, onError));
  }, []);


  return profile_data && (
    <div className="flex column w-100 h-100">

      {tabName === "edit" ?
        <div className="settings-main" style={{ maxWidth: 376, height: "80vh", margin: '0 auto' }}>
          {/* Team picture */}
          <div className="flex relative" style={{ margin: "0 auto" }}>
            {(profile_data['logo_url'] && profile_data['logo_url'] !== "" && profile_data['logo_url'].toLowerCase() !== "none") ?
              <img src={profile_data.logo_url} alt="profile-icon" height={200} width={200}
                style={{ padding: 4, borderRadius: "50%", objectFit: 'cover' }} />
              :
              <div style={{ width: 200, height: 200, background: "#ffffff", borderRadius: "50%" }}>
                {image_uploading ?
                  <div className="flex column justifyCenter alignCenter h-100 w-100">
                    <div className="screen_custom_loader" />
                  </div>
                  :
                  <h1 className="flex column justifyCenter alignCenter h-100 w-100"
                    style={{ fontSize: 72, color: "#000000" }} >
                    MT
                  </h1>
                }
              </div>
            }

            <div
              style={{
                position: "absolute",
                right: 8,
                marginTop: 16,
                width: 51.26,
                height: 51.26,
                bottom: 0,
                background: "#08AFA8",
                borderRadius: "50%",
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.25)",
              }}
            >
              <input
                type="file"
                id="actual-btn"
                accept="image/x-png,image/jpeg"
                onChange={onUpdateImage}
                hidden
              />
              <label htmlFor="actual-btn" className="flex column justifyCenter alignCenter pointer h-100">
                <img src={Camera} alt="camera-icon" height={24} width={24} />
              </label>
            </div>
          </div>

          {/* Team name */}
          <form className="flex column alignCenter justifyCenter w-100" style={{ marginTop: 30 }}
            onSubmit={(e) => onUpdateTeamName(e)}>
            <div className="flex justifyBetween alignCenter w-100">
              <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                <label className="settings-label">Team name</label>
                <input type="text"
                  value={profile_data['name']}
                  required
                  className="settings-value"
                  onChange={(e) => {
                    e.target.value.localeCompare(teamDetails.name) !== 0
                      ? setFieldValues('name', e.target.value, true)
                      : setFieldValues('name', teamDetails.name, false);
                  }}
                />
              </div>
            </div>


            <div className="flex justifyCenter alignCenter w-100" style={{ marginTop: 24 }} >
              <LoadingBlackButton
                submitting={submitting}
                status={!enabled['name']}
                label="Save Changes"
                className={!enabled['name'] ? "disable-button" : "white-button"}
              />
            </div>
          </form>
        </div>
        : tabName === "transfer" ?
          <div className="settings-main" style={{ maxWidth: 444, height: "80vh", margin: '0 auto' }}>
            <p className="main-title">Transfer ownership</p>
            <p className="main-decription">
              You can transfer the ownership of
              <strong> {` ${teamDetails && teamDetails !== null && teamDetails.name} `}</strong>
              to any coach inside the team.
            </p>
            <div style={{ width: '100%', height: 1, background: '#FFFFFF', margin: "32px 0 0px" }} />
            {/* Transfer ownership form */}
            <form className="flex column alignCenter justifyCenter w-100" onSubmit={(e) => onTransferOwnership(e)} >

              <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                <label className="settings-label">Transfer ownership to</label>
                <select className="coach-types"
                  onChange={(e) => {
                    e.target.value.localeCompare(teamDetails.primary_coach.email) !== 0
                      ? setFieldValues('primary_coach', e.target.value, true)
                      : setFieldValues('primary_coach', teamDetails.primary_coach.email, false);
                  }}
                  defaultValue={profile_data['primary_coach']}
                >
                  {Array.isArray(profile_data['coaches']) ?
                    profile_data['coaches'].map((coach, index) =>
                      <option className="settings-value" value={coach.email} key={`option-${index + 1}`}>
                        {coach.email}
                      </option>
                    ) : null}
                </select>
              </div>

              <div className="flex justifyCenter alignCenter w-100" style={{ marginTop: 24 }} >
                <LoadingBlackButton
                  submitting={submitting}
                  status={!enabled['primary_coach']}
                  label="Transfer"
                  className={!enabled['primary_coach'] ? "disable-button" : "white-button"}
                />
              </div>
              <br /><br />
              <h4 style={{ color: '#F8484E', lineHeight: 1.4 }}>You can only transfer your team to a coach inside your team.</h4>
            </form>
          </div>
          : (tabName === "delete" && teamDetails) ?
            <div className="settings-main" style={{ maxWidth: 444, height: "80vh", margin: '0 auto' }}>
              <p className="main-title">{`Delete ${teamDetails.name}`}</p>
              <p className="main-decription">
                {`You are about to delete ${teamDetails.name}. All user will be automatically removed and won’t be able to access the team anymore.`}
              </p>
              <p className="main-decription">
                Please confirm with
                <strong> {` “DELETE ${teamDetails.name}”`} </strong>
              </p>

              <form className="flex column alignCenter justifyCenter w-100" style={{ width: "100%", marginTop: 30 }}
                onSubmit={(e) => onDeleteTeam(e)}
              >

                <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                  <label className="settings-label">Confirm deletion</label>
                  <input type="text"
                    required
                    placeholder="DELETE [MY TEAM NAME]"
                    className="settings-value"
                    onChange={(e) => {
                      e.target.value === `DELETE ${teamDetails.name}`
                        ? setFieldValues('delete_primary_coach', e.target.value, true)
                        : setFieldValues('delete_primary_coach', teamDetails.name, false);
                    }}
                    defaultValue=""
                  />
                </div>

                <div className="flex justifyStart alignCenter w-100" style={{ marginTop: 24 }}>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    style={{ alignItems: "center", height: 24, width: 24 }}
                  />
                  <p className={checked ? "small-decription" : "small-decription-disabled"}>
                    I aknowledge that all data will be removed and can’t be
                    accessed anymore
                  </p>
                </div>


                <div className="flex justifyCenter alignCenter w-100" style={{ marginTop: 24 }} >
                  <LoadingBlackButton
                    submitting={submitting}
                    status={!enabled['delete_primary_coach'] && !checked}
                    label="DELETE"
                    className={(enabled['delete_primary_coach'] && checked) ? "white-button" : "disable-button"}
                  />
                </div>
              </form>
            </div>
            : null}

    </div>
  );
};
export default UpdateTeam;
