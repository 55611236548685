import React, { useEffect, useState } from "react";
import api from "../../services/api/ApiService";
import { isArray } from "lodash";
import { getErrorMessage, mixpanelEventLog } from "../../_helpers/Requests";
import AthleteTabs from "../../components/tabs/TabLayout";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import SearchIcon from "../../assets/icons/search-icon.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import Dropdown from "../../components/ui/Dropdown";
import EmptySession from "../../assets/icons/empty-session.svg";
import Accordion from "../../components/ui/Accordion";
import "./SessionBuilder.css";
import Module from "./Module";
import ExercisePopup from "./ExercisePopup";
import SchedulingSessionPopup from "./SchedulingSessionPopup";
import { useDispatch } from "react-redux";
import { createNotification } from "../../store/app/actions";
import Loading from "../../components/ui/Loading";
import { isValidJSON } from "../../_helpers/validation";
import {
  sessionBulderAccordeonContent,
  weekdayNames,
} from "../../_helpers/constants";
import moment from "moment";
import TagInput from "../../components/ui/TagInput";
import MissingSubscriptionPage from "../../components/common/MissingSubscriptionPage";

const SessionBuilder = () => {
  const dispatch = useDispatch();
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [exercisesLoading, setExercisesLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [athletes, setAthletes] = useState([]);
  const [allAthletes, setAllAthletes] = useState([]);
  const [athleteOptions, setAthleteOptions] = useState([]);
  const [availableCyclePhases, setAvailableCyclePhases] = useState([]);
  const [availabelReadinessScores, setAvailableReadinessScores] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [adaptiveSession, setAdaptiveSession] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [modules, setModules] = useState([]);
  const [pickedTeam, setPickedTeam] = useState("");
  const exercisePopupInitialState = {
    show: false,
    isNewExercise: false,
    name: "",
    estimatedSeconds: 0,
    moduleId: 0,
  };
  const scheduleSessionPopupInitialState = {
    show: false,
    recurring: false,
    startDate: moment().format("YYYY-MM-DD"),
    days: [],
    weeksBetweenEvents: 0,
    endDate: null,
    initial_weekdays: [],
  };
  const sessionInfoInitialState = {
    name: "",
    description: "",
    tags: [],
    assigned_athletes: [],
    assigned_team_id: null,
    start_date: moment().format("YYYY-MM-DD"),
    end_date: null,
    start_time: "12:00:00",
    end_time: "14:00:00",
    occurrence_days: 0,
    initial_weekdays: [],
    modules: [],
    cycle_phase_filter: "",
    readiness_score_filter: "",
  };
  const editExercisePopupInitialState = {
    name: "",
    type: "",
    count: 0,
    intensity: "",
    estimatedDuration: 0,
  };
  const [isExerciseEdit, setIsExerciseEdit] = useState(false);
  const [editExerciseDetails, setEditExerciseDetails] = useState(
    editExercisePopupInitialState
  );
  const [exercisePopup, setExercisePopup] = useState(exercisePopupInitialState);
  const [scheduleSessionPopup, setScheduleSessionPopup] = useState(
    scheduleSessionPopupInitialState
  );

  const [sessionInfo, setSessionInfo] = useState(sessionInfoInitialState);
  const [exerciseLibrary, setExerciseLibrary] = useState([]);

  const onError = (error, timeout = 6000) =>
    dispatch(createNotification("error-toast", error, timeout));
  const filteredData =
    exerciseLibrary.length > 0 &&
    exerciseLibrary.filter((exercise) => {
      return exercise.toLowerCase().includes(searchQuery.toLowerCase());
    });
  const getTeams = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/coach/team/management`);
      if (response["status"] === 200) {
        if (response["data"] && isArray(response["data"])) {
          if (response["data"].length > 0) {
            response["data"] = response["data"].sort((a, b) =>
              a.pk > b.pk ? 1 : -1
            );
            setTeams(response["data"]);
            setLoading(false);
          }
        }
      } else {
        getErrorMessage(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setIsSubscriptionActive(false);
      onError(
        "Something went wrong. You either don't have a subscription or haven't created any teams.",
        30000
      );
    }
  };

  const getExercises = async () => {
    if (isSubscriptionActive) {
      setExercisesLoading(true);
      try {
        const response = await api.get(`/training-sessions/exercise-library`);
        if (response["status"] === 200) {
          if (response["data"] && isArray(response["data"])) {
            if (response["data"].length > 0) {
              const exerciseLibraryResponse = response["data"].map(
                (exercise) => exercise.name
              );
              setExerciseLibrary(exerciseLibraryResponse);
              setExercisesLoading(false);
            }
            setExercisesLoading(false);
          }
        } else {
          getErrorMessage(response);
          setExercisesLoading(false);
        }
      } catch (error) {
        onError(error.message);
        setExercisesLoading(false);
      }
    }
  };

  useEffect(() => {
    getTeams();
    getAllAthletes();
  }, []);

  useEffect(() => {
    if (isSubscriptionActive) {
      getExercises();
    }
  }, [isSubscriptionActive]);

  const getTeamAthletes = async (team_id) => {
    if (team_id) {
      setLoading(true);
      if (sessionInfo.athlete) setSessionInfo({ ...sessionInfo, athlete: "" });
      try {
        const response = await api.get(`coach/team/${team_id}/blocks/overview`);
        if (response["status"] === 200) {
          if (response["data"]) {
            if (response["data"]["overview"].length > 0) {
              setLoading(false);
              setAthletes(response["data"]["overview"]);
            } else {
              setLoading(false);
              dispatch(
                createNotification(
                  "error-toast",
                  "No athletes found in your selected team!"
                )
              );
            }
          }
          setLoading(false);
        } else {
          getErrorMessage(response);
          setLoading(false);
        }
      } catch (error) {
        onError(error.message);
        setLoading(false);
      }
    }
  };
  const getAllAthletes = async () => {
    setLoading(true);
    if (sessionInfo.athlete) setSessionInfo({ ...sessionInfo, athlete: "" });
    try {
      const response = await api.get("/coach/user/teams");
      if (response["status"] === 200) {
        if (response["data"].length > 0) {
          const allAthletesResponse = [];
          response["data"].forEach((team) =>
            allAthletesResponse.push(...team.athletes)
          );
          setAllAthletes(allAthletesResponse);
          setLoading(false);
        }
      } else {
        getErrorMessage(response);
        setLoading(false);
      }
    } catch (error) {
      onError(
        "Something went wrong. You either don't have a subscription or haven't created any teams.",
        30000
      );
      setLoading(false);
    }
  };

  const submitTrainingSessions = async () => {
    if (sessionInfo) {
      let finalSessionInfo = {};
      if (
        sessionInfo.assigned_team_id === null ||
        sessionInfo.assigned_athletes.length > 0
      ) {
        const updatedSessionInfo = { ...sessionInfo };
        delete updatedSessionInfo["assigned_team_id"];
        if (updatedSessionInfo.end_date === null) {
          updatedSessionInfo.end_date = updatedSessionInfo.start_date;
        }
        finalSessionInfo = updatedSessionInfo;
      } else {
        const updatedSessionInfo = { ...sessionInfo };
        delete updatedSessionInfo["assigned_athletes"];
        if (updatedSessionInfo.end_date === null) {
          updatedSessionInfo.end_date = updatedSessionInfo.start_date;
        }
        finalSessionInfo = updatedSessionInfo;
      }

      try {
        const { data } = await api.post(
          "coach/training-sessions",
          finalSessionInfo
        );
        if (data) {
          dispatch(
            createNotification(
              "success-toast",
              "New training session has been created."
            )
          );
          setSessionInfo(sessionInfoInitialState);
          hidePopup();
          setTimeout(() => window.location.reload(), 3000);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          onError(
            "This route does not exist. please contact with support@wild.ai"
          );
        } else {
          if (err.response) {
            onError(
              "Something went wrong. Make sure you've filled in all the fields."
            );
          }
        }
      }
    }
  };

  const getAthleteIds = (names) => {
    const listOfAthleteIds = [];
    if (names) {
      if (sessionInfo.assigned_team_id) {
        names.forEach((name) => {
          const [first_name, last_name] = name.split(" ");
          const foundAthleteId = athletes.find(
            (athlete) =>
              first_name === athlete.athlete.first_name &&
              last_name === athlete.athlete.last_name
          ).athlete.pk;
          listOfAthleteIds.push(foundAthleteId);
        });
        setSessionInfo({ ...sessionInfo, assigned_team_id: null });
      } else {
        names.forEach((name) => {
          const [first_name, last_name] = name.split(" ");
          const foundAthleteId = allAthletes.find(
            (athlete) =>
              first_name === athlete.first_name &&
              last_name === athlete.last_name
          ).athlete_id;
          listOfAthleteIds.push(foundAthleteId);
        });
      }
    }
    return listOfAthleteIds;
  };

  useEffect(() => {
    if (teams && pickedTeam && pickedTeam !== "All Teams") {
      const teamId = teams.find((team) => team.name === pickedTeam).pk;
      setSessionInfo({
        ...sessionInfo,
        assigned_team_id: teamId,
        assigned_athletes: [],
      });
      getTeamAthletes(teamId);
    }
    if (pickedTeam === "All Teams") {
      setAthletes([]);
      setSessionInfo({
        ...sessionInfo,
        assigned_team_id: null,
        assigned_athletes: [],
      });
    }
  }, [teams, pickedTeam]);

  useEffect(() => {
    if (athletes) {
      let readinessScores = new Set();
      let cyclePhases = [];
      athletes.forEach((athlete) => {
        if (cyclePhases.indexOf(athlete.cycle.phase) === -1) {
          cyclePhases.push(athlete.cycle.phase);
        }
        const readinessPercentage = athlete.readiness;
        if (readinessPercentage < 33) {
          readinessScores.add("Low");
        } else if (readinessPercentage >= 34 && readinessPercentage <= 66) {
          readinessScores.add("Medium");
        } else {
          readinessScores.add("High");
        }
      });
      setAvailableCyclePhases(cyclePhases);
      setAvailableReadinessScores(Array.from(readinessScores));
    }
  }, [athletes]);

  useEffect(() => {
    setSessionInfo({
      ...sessionInfo,
      start_date: scheduleSessionPopup.startDate,
      end_date: scheduleSessionPopup.endDate
        ? scheduleSessionPopup.endDate
        : null,
      occurrence_days: scheduleSessionPopup.weeksBetweenEvents * 7,
      initial_weekdays: scheduleSessionPopup.initial_weekdays.map(
        (weekdayIndex) => weekdayNames[weekdayIndex]
      ),
    });
  }, [scheduleSessionPopup]);

  useEffect(() => {
    const moduleDetailsArr = modules.map((module) => module.moduleDetails);
    setSessionInfo({ ...sessionInfo, modules: moduleDetailsArr });
  }, [modules]);

  const addExerciseToModule = (id, newExercise) => {
    setModules((prevState) => {
      return prevState.map((module) => {
        if (module.id === id) {
          return {
            ...module,
            moduleDetails: {
              ...module.moduleDetails,
              exercises: [...module.moduleDetails.exercises, newExercise],
            },
          };
        }
        return module;
      });
    });
  };

  const addNewExercise = (name) => {
    setExerciseLibrary([...exerciseLibrary, name]);
  };

  const removeModule = (id) => {
    const updatedModules = modules.filter((module) => module.id !== id);
    setModules(updatedModules);
  };

  const handleModuleDragStart = (e, item) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(item));
  };

  const handleExerciseDragStart = (e, item) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(item));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleExerciseDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleModuleDrop = (e) => {
    e.preventDefault();
    if (isValidJSON(e.dataTransfer.getData("text/plain"))) {
      const isModule = JSON.parse(e.dataTransfer.getData("text/plain")).module;
      if (isModule) {
        if (
          (e.target.id === "module-container" && !modules.length) ||
          e.target.id === "module-drop-container"
        ) {
          const newModuleId =
            modules.length > 0 ? modules[modules.length - 1].id + 1 : 1;
          setIsDraggingOver(false);
          setModules([...modules, { id: newModuleId, moduleDetails: {} }]);
        }
      }
    }
  };

  const handleExerciseDrop = (e, id) => {
    e.preventDefault();
    if (isValidJSON(e.dataTransfer.getData("text/plain"))) {
      const data = JSON.parse(e.dataTransfer.getData("text/plain"));
      const isExercise = !data.module;
      if (isExercise) {
        setExercisePopup({
          ...exercisePopup,
          show: true,
          name: data.name,
          estimatedSeconds: data.estimatedSeconds,
          moduleId: id,
        });
      }
    }
  };

  const hidePopup = () => {
    if (exercisePopup.show) {
      setExercisePopup(exercisePopupInitialState);
    }
    if (scheduleSessionPopup.show) {
      setScheduleSessionPopup(scheduleSessionPopupInitialState);
    }
  };

  const startExerciseEdit = (exerciseDetails, exerciseIndex, moduleId) => {
    setIsExerciseEdit(true);
    setExercisePopup({ ...exercisePopup, show: true, moduleId });
    const findTypeOfExercise = (obj) => {
      let foundKey = null;
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && ["laps", "reps"].includes(key)) {
          if (foundKey !== null) {
            return null;
          }
          foundKey = key;
        }
      }
      return foundKey;
    };
    const exerciseType = findTypeOfExercise(exerciseDetails);
    if (exerciseDetails.name === "Rest") {
      setEditExerciseDetails({
        name: exerciseDetails.name,
        duration: exerciseDetails.duration,
        exerciseIndex,
        moduleId,
      });
    } else {
      setEditExerciseDetails({
        name: exerciseDetails.name,
        [exerciseType]: exerciseDetails[exerciseType],
        intensity: exerciseDetails.intensity,
        duration: exerciseDetails.duration,
        exerciseIndex,
        moduleId,
      });
    }
  };

  const finishExerciseEdit = (moduleId, exerciseDetails) => {
    const exerciseIndex = exerciseDetails.exerciseIndex;
    const moduleToUpdate = modules.find((module) => module.id === moduleId);
    moduleToUpdate.moduleDetails.exercises[exerciseIndex] = exerciseDetails;
    delete moduleToUpdate.moduleDetails.exercises[exerciseIndex].exerciseIndex;
    delete moduleToUpdate.moduleDetails.exercises[exerciseIndex].moduleId;
    if (exerciseDetails.name === "Rest") {
      delete moduleToUpdate.moduleDetails.exercises[exerciseIndex].laps;
      delete moduleToUpdate.moduleDetails.exercises[exerciseIndex].reps;
      delete moduleToUpdate.moduleDetails.exercises[exerciseIndex].intensity;
    }
    const updatedModulesArray = modules.map((module) => {
      if (module.id === moduleId) {
        return moduleToUpdate;
      }
      return module;
    });
    setModules(updatedModulesArray);
    setIsExerciseEdit(false);
    setEditExerciseDetails(editExercisePopupInitialState);
  };

  const renderExercises = (exercises) => {
    return exercises.map((exercise, i) => (
      <div
        style={{
          padding: "8px 12px",
          pointerEvents: modules.length ? "auto" : "none",
        }}
        onDragStart={(e) =>
          handleExerciseDragStart(e, {
            id: i,
            name: exercise,
          })
        }
        draggable
        className="pointer"
      >
        {exercise}
      </div>
    ));
  };

  const updateExercises = (moduleId, newExercises) => {
    setModules((prevModules) =>
      prevModules.map((module) => {
        if (module.id === moduleId) {
          return {
            ...module,
            moduleDetails: {
              ...module.moduleDetails,
              exercises: newExercises,
            },
          };
        }
        return module;
      })
    );
  };

  useEffect(() => {
    if (sessionInfo.assigned_team_id && athletes) {
      setAthleteOptions(
        athletes.map(
          (athlete) =>
            `${athlete.athlete.first_name} ${athlete.athlete.last_name}`
        )
      );
    } else if (allAthletes.length > 0) {
      setAthleteOptions(
        allAthletes.map(
          (athlete) => `${athlete.first_name} ${athlete.last_name}`
        )
      );
    }
  }, [sessionInfo.assigned_team_id, allAthletes, athletes]);

  const areAllFieldsFilled = () => {
    const {
      name,
      description,
      tags,
      assigned_athletes,
      assigned_team_id,
      start_date,
      end_date,
      occurrence_days,
      initial_weekdays,
      modules,
      intensity,
      cycle_phase_filter,
      readiness_score_filter,
    } = sessionInfo;
    return (
      name !== "" &&
      description !== "" &&
      tags.length > 0 &&
      (assigned_athletes.length > 0 || assigned_team_id) &&
      start_date !== "" &&
      end_date !== "" &&
      modules.length > 0 &&
      (adaptiveSession
        ? (cycle_phase_filter !== "" || readiness_score_filter !== "") &&
          intensity !== ""
        : true) &&
      (scheduleSessionPopup.recurring
        ? occurrence_days !== 0 && initial_weekdays.length > 0
        : true)
    );
  };
  const areAllModulesValid = () => {
    if (!sessionInfo.modules || sessionInfo.modules.length === 0) {
      return false;
    }
    const criteriaForValidModule = (module) => {
      const {
        activity,
        description,
        equipment,
        exercises,
        intensity,
        name,
        repeat,
      } = module;
      return (
        typeof activity === "string" &&
        activity.trim() !== "" &&
        typeof description === "string" &&
        description.trim() !== "" &&
        typeof equipment === "string" &&
        equipment.trim() !== "" &&
        Array.isArray(exercises) &&
        exercises.length > 0 &&
        typeof intensity === "string" &&
        intensity.trim() !== "" &&
        typeof name === "string" &&
        name.trim() !== "" &&
        typeof repeat === "number" &&
        isFinite(repeat) &&
        repeat > 0
      );
    };

    return (
      sessionInfo.modules &&
      sessionInfo.modules.every((module) => criteriaForValidModule(module))
    );
  };

  const isSesssionValid = areAllFieldsFilled() && areAllModulesValid();
  const invalidSessionErrorMessage = () =>
    dispatch(
      createNotification(
        "error-toast",
        "Fill in all fields before scheduling a session."
      )
    );

  if (!isSubscriptionActive) {
    return <MissingSubscriptionPage />;
  }

  return (
    <div>
      {/* Header */}
      <div
        className="flex w-100 column alignStart h-100"
        style={{ padding: "0 24px" }}
      >
        <div
          className="flex w-100 justifyBetween"
          style={{ padding: "48px 24px 32px 24px" }}
        >
          <p style={{ fontSize: "32px" }}>Create New Session</p>
          <Breadcrumbs
            pagesNames={["Home", "Session Builder"]}
            fontSize="18px"
          />
        </div>
        <div className="flex alignCenter justifyBetween w-100">
          <AthleteTabs
            activeIndex={tabIndex}
            tabs={[
              "New Session",
              "Session Templates",
              "Exercises",
              "Past Sessions",
            ]}
            callAction={(tab_index) => setTabIndex(tab_index)}
            activeColor="#08AFA8"
            disableAllTabsExceptFirst
          />
          <div
            className="flex row justifyEnd"
            style={{ gap: "12px", width: "800px" }}
          >
            <div className="relative">
              <input
                className="sesionBuilder_searchHeader"
                type="text"
                placeholder="Search..."
                disabled
                style={{ color: "black" }}
              />
              <img
                src={SearchIcon}
                alt="search-icon"
                className="sesionBuilder_searchIcon"
              />
            </div>
            <div className="disable-button" style={{ maxWidth: 180 }}>
              <p>Save as template</p>
            </div>
            <div
              className="white-button"
              style={{ maxWidth: 180 }}
              onClick={() =>
                setScheduleSessionPopup({
                  ...scheduleSessionPopup,
                  startDate: moment().format("YYYY-MM-DD"),
                  show: true,
                })
              }
            >
              <p>Schedule session</p>
            </div>
          </div>
        </div>
      </div>
      {/* End of header */}
      <div
        className={`flex row ${loading && "sessionBuilder_fixedHeight"}`}
        style={{
          padding: "24px",
          gap: "14px",
        }}
      >
        <div
          className={`flex column sesionBuilder_newSessionContainer scrollable_vertical ${
            loading && "justifyCenter"
          }`}
        >
          {loading ? (
            <Loading />
          ) : (
            <>
              <span style={{ fontSize: "19px" }}>New Session</span>
              {/* <SwitchButton
                isChecked={adaptiveSession}
                onChange={() => setAdaptiveSession(!adaptiveSession)}
                label="Adaptive Session"
              /> */}
              <input
                type="text"
                placeholder="Session Name"
                className="sesionBuilder_textInput"
                value={sessionInfo.name}
                onChange={(e) =>
                  setSessionInfo({ ...sessionInfo, name: e.target.value })
                }
              />
              {adaptiveSession && (
                <Dropdown
                  options={["Low", "Medium", "High", "All out", "N/A"]}
                  defaultTitle="Intensity"
                  selectedOption={sessionInfo.intensity}
                  onOptionChange={(intensity) =>
                    setSessionInfo({ ...sessionInfo, intensity })
                  }
                />
              )}
              <div className="sesionBuilder_separator" />
              <span style={{ fontSize: "19px" }}>Who</span>
              <Dropdown
                options={["All Teams", ...teams.map((team) => team.name)]}
                defaultTitle="All Teams"
                selectedOption={pickedTeam}
                onOptionChange={(team) => setPickedTeam(team)}
              />
              {adaptiveSession && (
                <>
                  <Dropdown
                    options={availableCyclePhases}
                    defaultTitle="Cycle Phase"
                    selectedOption={sessionInfo.cycle_phase_filter}
                    onOptionChange={(cycle_phase_filter) =>
                      setSessionInfo({ ...sessionInfo, cycle_phase_filter })
                    }
                  />
                  <Dropdown
                    options={availabelReadinessScores}
                    defaultTitle="Readiness Score"
                    selectedOption={sessionInfo.readiness_score_filter}
                    onOptionChange={(readiness_score_filter) =>
                      setSessionInfo({ ...sessionInfo, readiness_score_filter })
                    }
                  />
                </>
              )}
              {!adaptiveSession && (
                <Dropdown
                  options={athleteOptions}
                  defaultTitle="Athletes"
                  selectedOptions={sessionInfo.assigned_athletes}
                  onOptionChange={(athletes) =>
                    setSessionInfo({
                      ...sessionInfo,
                      assigned_athletes: getAthleteIds(athletes),
                    })
                  }
                  multipleChoices
                />
              )}
              <div className="sesionBuilder_separator" />
              <span style={{ fontSize: "19px" }}>Session Information</span>
              <TagInput
                setTags={(tags) => setSessionInfo({ ...sessionInfo, tags })}
                tags={sessionInfo.tags}
              />
              <textarea
                placeholder="Description"
                rows={adaptiveSession ? 3 : 11}
                cols="50"
                value={sessionInfo.description}
                onChange={(e) =>
                  setSessionInfo({
                    ...sessionInfo,
                    description: e.target.value,
                  })
                }
                className="sesionBuilder_textareaInput"
              />
            </>
          )}
        </div>
        <div className="flex column" style={{ gap: "21px", width: "12.5%" }}>
          <div
            className="flex justifyCenter alignCenter sesionBuilder_moduleContainer pointer"
            onDragStart={(e) =>
              handleModuleDragStart(e, { id: 1, module: true })
            }
            draggable
          >
            <span style={{ fontSize: "19px" }}>Module</span>
          </div>
          <div className="flex column justifyCenter sesionBuilder_exerciseLibraryContainer ">
            {exercisesLoading ? (
              <Loading />
            ) : (
              <>
                <div className="flex column" style={{ gap: "22px" }}>
                  <span style={{ fontSize: "19px" }}>Exercise Library</span>
                  <input
                    type="search"
                    placeholder="Search..."
                    className="sesionBuilder_exerciseSearch"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      backgroundImage: `url("${SearchIcon}")`,
                    }}
                  />
                  <div
                    className="flex column scrollable_vertical"
                    style={{ overflowY: "auto", height: "464px", gap: "22px" }}
                  >
                    {searchQuery !== "" ? <></> : <></>}
                    {renderExercises(
                      searchQuery !== "" ? filteredData : exerciseLibrary
                    )}
                  </div>
                </div>
                <div
                  className="flex alignCenter justifyCenter sesionBuilder_arrowButton"
                  onClick={() =>
                    setExercisePopup({
                      ...exercisePopup,
                      show: true,
                      isNewExercise: true,
                    })
                  }
                >
                  <span style={{ fontWeight: "600" }}>ADD EXERCISE</span>
                  <img src={PlusIcon} alt="plus-icon" height={18} width={18} />
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className="flex column"
          style={{ width: "70%", gap: "13px", overflow: "hidden" }}
        >
          <div
            className={`flex column sesionBuilder_emptySession ${
              modules.length ? "" : "justifyCenter alignCenter"
            }`}
            id="module-container"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleModuleDrop}
          >
            {modules.length ? (
              <>
                {modules.map((module) => (
                  <Module
                    id={module.id}
                    exercises={module.moduleDetails.exercises || []}
                    onExerciseContainerDrop={handleExerciseDrop}
                    onExerciseDragOver={handleExerciseDragOver}
                    onModuleDelete={removeModule}
                    setModules={setModules}
                    startExerciseEdit={startExerciseEdit}
                    updateExercises={updateExercises}
                  />
                ))}
                <div
                  className="flex justifyCenter alignCenter sessionBuilder_dropModuleContainer"
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleModuleDrop}
                  id="module-drop-container"
                >
                  Drop New Module Here
                </div>
              </>
            ) : isDraggingOver ? (
              <div style={{ pointerEvents: "none" }}>
                <span style={{ fontSize: "22px" }}>Drop your Module here</span>
              </div>
            ) : (
              <>
                <img
                  src={EmptySession}
                  alt="empty-session"
                  height={195}
                  width={175}
                  style={{ pointerEvents: "none" }}
                />
                <span style={{ fontSize: "22px" }}>
                  Currently your session is empty!
                </span>
                <span className="sesionBuilder_description">
                  To start creating your session, drag and drop a module into
                  this workspace. Modules can be nested inside each other to
                  create sets.
                </span>
                <span className="sesionBuilder_instructions">
                  Name your module “Running” to be one activity, or drag & drop
                  different exercises into it to create a circuit.
                </span>
              </>
            )}
          </div>
          <div className="relative w-100">
            <Accordion
              sections={[
                {
                  header: "Training",
                  content: (
                    <div className="flex column" style={{ gap: "25px" }}>
                      {sessionBulderAccordeonContent.map((section) => (
                        <div className="flex column" style={{ gap: "12px" }}>
                          {section.headline && (
                            <span style={{ fontSize: "18px" }}>
                              {section.headline}
                            </span>
                          )}
                          <span style={{ fontSize: "16px" }}>
                            {section.description}
                          </span>
                        </div>
                      ))}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div
        className={
          exercisePopup.show || scheduleSessionPopup.show
            ? `sessionBuilder_overlay`
            : ""
        }
        onClick={hidePopup}
      >
        <ExercisePopup
          show={exercisePopup.show}
          hide={() =>
            setExercisePopup({
              ...exercisePopup,
              show: false,
              name: "",
              moduleId: 0,
              isNewExercise: false,
            })
          }
          isExerciseEdit={isExerciseEdit}
          editExerciseInitialValues={editExerciseDetails}
          isNewExercise={exercisePopup.isNewExercise}
          parentModuleId={exercisePopup.moduleId}
          initialName={exercisePopup.name}
          estimatedSeconds={exercisePopup.estimatedSeconds}
          onExerciseAdd={(moduleId, newExercise) =>
            addExerciseToModule(moduleId, newExercise)
          }
          onExerciseEdit={finishExerciseEdit}
          onNewExerciseAdd={addNewExercise}
        />
        <SchedulingSessionPopup
          scheduleSessionPopup={scheduleSessionPopup}
          setScheduleSessionPopup={setScheduleSessionPopup}
          submitTrainingSessions={submitTrainingSessions}
          isSesssionValid={isSesssionValid}
          errorToast={invalidSessionErrorMessage}
        />
      </div>
    </div>
  );
};
export default SessionBuilder;
