import React from "react";
import "./ConfirmationPopup.css";
import WhiteCross from "../../assets/icons/white-cross.svg";

const ConfirmationPopup = ({ show, hide, onConfirm }) => {
  if (!show) {
    return <></>;
  }
  return (
    <div className="confirmationPopup">
      <img
        src={WhiteCross}
        style={{ alignSelf: "flex-end", cursor: "pointer" }}
        onClick={hide}
      />
      <div className="flex column alignCenter" style={{ gap: "16px" }}>
        <span className="headline">Are you sure?</span>
        <span>This action cannot be undone</span>
      </div>
      <div className="flex column w-100 alignCenter" style={{ gap: "8px" }}>
        <div
          className="white-button"
          style={{ maxWidth: 180 }}
          onClick={() => {
            onConfirm();
            hide();
          }}
        >
          <p>Delete module</p>
        </div>
        <div
          className="transparent-button"
          style={{ maxWidth: 180, border: "none" }}
          onClick={hide}
        >
          <p>Cancel</p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
