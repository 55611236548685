import React, { useState, useEffect } from "react";
// Providers 
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from 'xlsx/xlsx.mjs';
import { split } from "lodash";
// Icons 
import FileUpload from "../../assets/icons/file-upload.svg";
// Components 
import SearchCard from "../../components/common/SearchCard";
import LoadingTable from "../../components/common/LoadingTable";
import PlansTable from "../../components/tables/PlansTable";
import CreatePlan from "../../components/CreatePlan";
// Actions
import { createNotification } from "../../store/app/actions";
// import { history } from "../../index";
import { fetchTrainingPlans, setTrainingPlans, onFailedDeletePlan, fetchSportTypes, fetchSessionSportTypes, fetchTrainingLevels, fetchTrainingIntensities, setTrainingIntensities, setSportTypes, setTrainingLevels, setSessionSportTypes, setErrorMessage, setPlanId } from "../../store/trainings/actions";
import api from "../../services/api/ApiService";


const TrainingPlans = () => {
    const { training_plans, sport_types, errorMessage, deletePlanId, session_sport_types, training_levels, training_intensities } = useSelector((state) => state.training_plans);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    // Search bar
    const [isSearchActive, setSearchActive] = useState(false);
    const [searchedText, setSearchText] = useState("");
    const [searchedDataSource, setSearchedDataSource] = useState([]);
    const [showCross, setShowCross] = useState(false);
    const [showLoader, setLoader] = useState(false);
    // States
    var [createPlanPopup, showCreatePlanPopup] = useState(false);
    const [training_plan, set_training_plan] = useState(null);
    let [training_plan_sessions, set_training_plan_sessions] = useState([]);
    const [loading_screen, set_loading_screen] = useState(false);
    const [progress_loader_1, set_progress_loader_1] = useState(false);
    const [progress_loader_2, set_progress_loader_2] = useState(false);
    const [error_popup, show_error_popup] = useState(false);
    const [saved_message, set_saved_status] = useState("");
    let [sessions_request_count, setSessionsRequestCount] = useState(0);

    const onSuccess = (id) => {
        set_progress_loader_1(false);
        set_progress_loader_2(true);
        if (id) {
            onSaveSession(id, training_plan_sessions);
        }
    };

    function deletePlan(id) {
        try {
            api.delete(`coach/plan/${id}`);
        } catch (err) {
            if (err.response && err.response.status === 404) {
                onError("This route is not exist. please contact with support@wild.ai");
            } else {
                if (err.response) {
                    const { data: { details } } = err.response;
                    onError(details);
                }
            }
        }
    }

    async function submitSession(id, values) {
        try {
            const { data } = await api.post(`coach/plan/${id}/sessions`, values);
            if (data) {
                return true;
            }
        } catch (err) {
            if (err.response && err.response.status === 404) {
                onError("This route is not exist. please contact with support@wild.ai");
            } else {
                const { data: { details } } = err.response;
                if (err.response) {
                    if (err.response.status === 400) {
                        deletePlan(id);
                        if (typeof details === 'string') {
                            onError(details);
                        } else if (typeof details === 'object') {
                            let errorsKeys = Object.keys(details);
                            let errors = Object.values(details);
                            if (typeof errors[0] === 'string') {
                                onError(errors[0]);
                            } else {
                                onError(`${errorsKeys[0]}: ${errors[0][0]}`);
                            }
                        }
                    } else {
                        onError(details);
                    }
                }
            }
            return false;
        }
    }

    async function submitPlan(values) {
        try {
            const { data } = await api.post("coach/plans", values);
            if (data) {
                onSuccess(data.pk);
            }
        } catch (err) {
            if (err.response && err.response.status === 404) {
                onError("This route is not exist. please contact with support@wild.ai");
            } else {
                if (err.response) {
                    const { data: { details } } = err.response;
                    onError(details);
                }
            }
        }
    }

    useEffect(() => {
        if (errorMessage) {
            if (saved_message === '') {
                set_saved_status(errorMessage);
            }
            set_loading_screen(false);
            set_progress_loader_1(false);
            set_progress_loader_2(false);
            show_error_popup(true);
        }
    }, [errorMessage]);

    const onSuccessDelete = () => {
        window.localStorage.removeItem('delete_plan_id');
        show_error_popup(false);
        dispatch(setErrorMessage(null));
        dispatch(setPlanId(0));
        setLoading(true);
        dispatch(setTrainingPlans([]));
        dispatch(fetchTrainingPlans(onSucess, onError));
    }
    const onCloseErrorPopup = () => dispatch(onFailedDeletePlan(deletePlanId, onError, onSuccessDelete));

    const make_group_of_sessions_with_respect_to_unique_week_number_and_unique_session_number = (sessions_list) => {
        let sub_grouped = [];
        const grouped = Object.values(sessions_list.reduce((acc, item) => {
            acc[item['Week Number']] = [...(acc[item['Week Number']] || []), item];
            return acc;
        }, {}));

        if (grouped) {
            for (var i = 0; i < grouped.length; i++) {
                let list = grouped[i];
                sub_grouped[i] = Object.values(list.reduce((acc, item) => {
                    acc[item['Session Number']] = [...(acc[item['Session Number']] || []), item];
                    return acc;
                }, {}));
            }
        }
        return sub_grouped;
    }
    const onSaveSession = async (plan_pk, sessions_list) => {
        let grouped_list = [];
        grouped_list = make_group_of_sessions_with_respect_to_unique_week_number_and_unique_session_number(sessions_list);
        if (grouped_list) {
            let results = [];
            for (var j = 0; j < grouped_list.length; j++) {
                if (errorMessage) {
                    break;
                } else {
                    for (var k = 0; k < grouped_list[j].length; k++) {
                        let array_list = grouped_list[j][k]
                        let session_object = {};
                        let dict_list = [];
                        for (var x = 0; x < array_list.length; x++) {
                            let dict = {};
                            var sport_type = findIdOf(session_sport_types, array_list[0]['Sport type'], 'display_name');
                            if (sport_type && sport_type.length > 0) {
                                sport_type = sport_type[0].pk
                            } else {
                                onError(`The sport type '${array_list[0]['Sport type']}' you provided for training plan session not exist in our datbase. please contact with support@wild.ai`);
                                return;
                            }
                            var intensity = findIdOf(training_intensities, array_list[0]['Overall Intensity'], 'display_name');
                            if (intensity && intensity.length > 0) {
                                intensity = intensity[0].pk;
                            } else {
                                onError(`The intensity level '${array_list[0]['Overall Intensity']}' you provided for training plan not exist in our datbase. please contact with support@wild.ai`);
                                return;
                            }

                            session_object['name'] = array_list[0]['Session name'];
                            session_object['block'] = array_list[0]['Block'];
                            session_object['week_focus'] = array_list[0]['Week Focus'];
                            session_object['week_no'] = array_list[0]['Week Number'];
                            session_object['priority_no'] = array_list[0]['Session Number'];
                            session_object['sport_type'] = sport_type;
                            session_object['notes'] = array_list[0]['Session notes'];
                            session_object['intensity'] = intensity;
                            session_object['duration'] = array_list[0]['Total duration'] * 60;
                            dict = {
                                category: array_list[x]['Category'],
                                focus: array_list[x]['Focus'],
                                movement: array_list[x]['Movement'],
                                sets: array_list[x]['Sets'] || 0,
                                reps: array_list[x]['Reps'] || 0,
                                tempo: array_list[x]['Tempo'] || "",
                                load: array_list[x]['Load'],
                                key: array_list[x]['Key'],
                                video: array_list[x]['Video URL'],
                                description: array_list[x]['Description']
                            }
                            dict_list.push(dict);
                        }
                        session_object['description_dict'] = dict_list;
                        let response = await submitSession(plan_pk, session_object);
                        if (response) {
                            results.push(response)
                            continue;
                        } else {
                            break;
                        }
                    }
                }
            }

            if (sessions_request_count === results.length) {
                set_loading_screen(false);
                set_progress_loader_1(false);
                set_progress_loader_2(false);
                dispatch(createNotification("success-toast", "Successfully saved your plan and sessions"));
                setSessionsRequestCount(0);
                setLoading(true);
                dispatch(setTrainingPlans([]));
                dispatch(fetchTrainingPlans(onSucess, onError));
            }
        }
    }

    const findIdOf = (list, name, field) => {
        const object = list.filter((item) => item[field].toLowerCase() === name.toLowerCase() && item);
        return object;
    }

    const onSavePlan = (plan) => {
        var sport_type = findIdOf(sport_types, plan['Sport Type'], 'display_name');
        if (sport_type && sport_type.length > 0) {
            sport_type = sport_type[0].pk
        } else {
            onError(`The sport type '${plan['Sport Type']}' you provided for training plan not exist in our datbase. please contact with support@wild.ai`);
            return;
        }
        var level = findIdOf(training_levels, plan['Level'], 'display_name');
        if (level && level.length > 0) {
            level = level[0].pk;
        } else {
            onError(`The intensity level '${plan['Level']}' you provided for training plan not exist in our datbase. please contact with support@wild.ai`);
            return;
        }


        if (sport_type && level) {
            let plan_data = {}
            plan_data['short_title'] = plan['Coach title'];
            plan_data['coach_bio'] = plan['Coach Description'];
            plan_data['name'] = plan['Plan Name']
            plan_data['plan_length'] = plan['Plan length'];
            plan_data['sport_type'] = sport_type;
            plan_data['level'] = level;
            plan_data['summary'] = plan['Plan Summary'];
            plan_data['description'] = plan['Plan Description'];
            plan_data['price'] = plan['Price'];
            plan_data['currency'] = plan['Currency'];
            plan_data['promo_video'] = plan['Plan Promo video'];
            let tags = split(plan['Plan Tags'], " ");
            if (tags && tags.length > 0) {
                var filtered = tags.filter(function (el) {
                    return el !== "";
                });
                plan_data['tags'] = filtered;
            }
            // tags
            if (plan['Plan image']) {
                plan_data['image_url'] = plan['Plan image']
            }
            submitPlan(plan_data);
        }
    }

    const check_dataset_for_training_plan = (training_plan_data) => {
        if (!training_plan_data['Coach title'] || training_plan_data['Coach title'] === "") {
            dispatch(createNotification("error-toast", "Please enter a coach title of your training plan."));
            return false;
        }

        if (!training_plan_data['Coach Description'] || training_plan_data['Coach Description'] === "") {
            dispatch(createNotification("error-toast", "Please add a description of yourself, your qualifications and experience."));
            return false;
        }

        if (!training_plan_data['Plan Name'] || training_plan_data['Plan Name'] === "") {
            dispatch(createNotification("error-toast", "Please enter a name for your training plan"));
            return false;
        }
        if (!training_plan_data['Plan length'] || training_plan_data['Plan length'] === 0) {
            dispatch(createNotification("error-toast", "Please enter a duration (in weeks) your plan is intended to run for."));
            return false;
        }

        if (!training_plan_data['Sport Type'] || training_plan_data['Sport Type'] === "") {
            dispatch(createNotification("error-toast", "Please enter a sport type that best describes your training plan."));
            return false;
        }
        if (!training_plan_data['Level'] || training_plan_data['Level'] === "") {
            dispatch(createNotification("error-toast", "Please choose a training level your plan is most suited to."));
            return false;
        }

        if (!training_plan_data['Plan Summary'] || training_plan_data['Plan Summary'] === "") {
            dispatch(createNotification("error-toast", "Please enter a summary of your training plan. This could include how the plan progresses and/or what type of training it includes."));
            return false;
        }

        if (!training_plan_data['Plan Description'] || training_plan_data['Plan Description'] === "") {
            dispatch(createNotification("error-toast", "Please enter a description of your training plan. This could include how the plan progresses and/or what type of training it includes."));
            return false;
        }

        if (!training_plan_data['Plan Tags'] || training_plan_data['Plan Tags'] === "") {
            dispatch(createNotification("error-toast", "Please add at least one tag that best describe your plan. To add a tag, enter the words separated by a space."));
            return false;
        }

        if (!training_plan_data['Price']) {
            dispatch(createNotification("error-toast", "Please enter a total price for your plan."));
            return false;
        }

        if (!training_plan_data['Plan Promo video'] || training_plan_data['Plan Promo video'] === "") {
            dispatch(createNotification("error-toast", "Please enter a link to a promo video url for your plan."));
            return false;
        }

        if (!training_plan_data['Currency'] || training_plan_data['Currency'] === "") {
            training_plan_data['Currency'] = "USD"
        }

        return true;
    }

    function getSessionsCount(list) {
        if (list && list.length > 0) {
            list.forEach((item) => {
                sessions_request_count += item.length
                setSessionsRequestCount(sessions_request_count);
            });
        }
    }

    useEffect(() => {
        let plan_status;
        if (training_plan) {
            plan_status = check_dataset_for_training_plan(training_plan);
            if (plan_status) {
                if (training_plan_sessions && training_plan_sessions.length > 0) {
                    training_plan_sessions = training_plan_sessions.filter(obj => Object.keys(obj).includes("Week Number"));
                    if (training_plan_sessions) {
                        let sessions_grouped_list = [];
                        sessions_grouped_list = make_group_of_sessions_with_respect_to_unique_week_number_and_unique_session_number(training_plan_sessions);
                        let sessions_check = false;
                        getSessionsCount(sessions_grouped_list);
                        if (sessions_grouped_list) {
                            for (var a = 0; a < sessions_grouped_list.length; a++) {
                                for (var b = 0; b < sessions_grouped_list[a].length; b++) {
                                    let array_list = sessions_grouped_list[a][b]
                                    for (var c = 0; c < array_list.length; c++) {
                                        let session_data = array_list[0];
                                        if (!sessions_check) {
                                            if (!session_data['Week Number'] || session_data['Week Number'] === 0) {
                                                dispatch(createNotification("error-toast", `Week number of your training plan starting from 1`));
                                                sessions_check = true;
                                                break;
                                            }
                                            if (!session_data['Session Number'] || session_data['Session Number'] === 0) {
                                                dispatch(createNotification("error-toast", `Enter the number of the session in the week. We've prefilled these for you for the first few weeks`));
                                                sessions_check = true;
                                                break;
                                            }

                                            if (!session_data['Sport type'] || session_data['Sport type'] === "") {
                                                dispatch(createNotification("error-toast", `Enter a sport type for this session (optional).`));
                                                sessions_check = true;
                                                break;
                                            }
                                            if (!session_data['Overall Intensity'] || session_data['Overall Intensity'] === "") {
                                                dispatch(createNotification("error-toast", `Enter an overall estimated intensity for this session. This should be low, Med or high`));
                                                sessions_check = true;
                                                break;
                                            }
                                            if (!session_data['Total duration'] || session_data['Total duration'] === 0) {
                                                dispatch(createNotification("error-toast", `Enter the estimated total duration of the session (in minutes)`));
                                                sessions_check = true;
                                                break;
                                            }
                                            if (!array_list[c]['Movement'] || array_list[c]['Movement'] === "") {
                                                dispatch(createNotification("error-toast", `As simple as the name of the exercise. This will be added to your library of movements along with the video URL. If you are adding a CV exercise, its best to add some details in the movement name as it will save in your library.`));
                                                sessions_check = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (!sessions_check) {
                            set_loading_screen(true);
                            set_progress_loader_1(true);
                            onSavePlan(training_plan);
                        } else {
                            set_loading_screen(false);
                        }
                    }
                }
            } else {
                set_loading_screen(false);
            }
        }
    }, [training_plan, training_plan_sessions]);

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            var data = reader.result;
            var workbook = XLSX.read(data, { type: 'binary' });
            workbook.SheetNames.forEach(function (sheetName) {
                var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                var json_object = JSON.stringify(XL_row_object);
                if (sheetName === "Training Plan Overview") {
                    set_training_plan(JSON.parse(json_object)[0]);
                }
                if (sheetName === "Sessions") {
                    set_training_plan_sessions(JSON.parse(json_object));
                }
            });
        };
        reader.onerror = function (ex) {
            dispatch(
                createNotification("error-toast", ex)
            );
        };
        reader.readAsBinaryString(files[0]);
        e.target.value = '';
    };

    const onError = (error) => {
        setLoading(false);
        if (typeof error === "object") {
            const value = Object.values(error);
            dispatch(createNotification("error-toast", value[0]));
            set_loading_screen(false);
        } else {
            dispatch(createNotification("error-toast", error));
            set_loading_screen(false);
        }
    };

    const onSucess = () => setLoading(false);

    useEffect(() => {
        if (training_plans && training_plans.length > 0) {
            setSearchedDataSource(training_plans);
        }
    }, [training_plans]);

    // useEffects
    // 1- onLoad
    useEffect(() => {
        setLoading(true);
        dispatch(setTrainingPlans([]));
        dispatch(setSportTypes([]));
        dispatch(setSessionSportTypes([]));
        dispatch(setTrainingLevels([]));
        dispatch(setTrainingIntensities([]));
        dispatch(fetchSportTypes());
        dispatch(fetchTrainingLevels());
        dispatch(fetchSessionSportTypes());
        dispatch(fetchTrainingIntensities());
        if (deletePlanId) {
            dispatch(onFailedDeletePlan(deletePlanId, onError));
        } else if (window.localStorage.getItem('delete_plan_id')) {
            const id = window.localStorage.getItem('delete_plan_id');
            dispatch(onFailedDeletePlan(id, onError));
        } else {
            dispatch(fetchTrainingPlans(onSucess, onError));
            setSearchedDataSource(training_plans);
        }

    }, []);


    return (
        <div className="flex column w-100 h-100">
            {/* Header + Breadcrumb */}
            <div className="flex column justifyBetween alignCenter"
                style={{
                    position: 'fixed',
                    top: 0,
                    height: 120,
                    zIndex: 10,
                    background: 'rgba(1, 33, 58, 0.7)',
                    backdropFilter: 'blur(25px)',
                    padding: '40px 24px 16px',
                    width: 'calc(100vw - 150px)'
                }}>
                {/* Header */}
                <div className="flex justifyStart alignCenter w-100">
                    <SearchCard
                        is_margin={false}
                        searchFor="training_plans_screen"
                        setSearch={(status) => setSearchActive(status)}
                        searchedData={(source) => setSearchedDataSource(source)}
                        dataSource={training_plans}
                        searchedText={searchedText}
                        setText={(text) => setSearchText(text)}
                        status={training_plans && training_plans.length > 0 ? true : false}
                        showCross={showCross}
                        setCrossStatus={(status) => setShowCross(status)}
                        showLoader={showLoader}
                        setLoaderStatus={(flag) => setLoader(flag)}
                    />

                    {(searchedDataSource && searchedDataSource.length > 0) ?
                        <div className="white-button pointer" style={{ marginLeft: 24, padding: "10px 12px", maxWidth: 220 }}
                            onClick={() => showCreatePlanPopup(true)} >
                            <p className="button_label">Create Training Plan</p>
                        </div>
                        : null}

                    {(searchedDataSource && searchedDataSource.length > 0) ?
                        <div className="flex alignCenter green-button pointer"
                            style={{ margin: "0 8px 0 16px", padding: "10px 12px", maxWidth: 200 }}>
                            <input type="file" accept=".xlsx, .xls" onChange={onChange} hidden id="file_button" />
                            <label htmlFor="file_button" className="flex alignCenter justifyCenter pointer h-100 w-100">
                                <img src={FileUpload} alt="file-upload-icon" height={24} width={24} />
                                <p className="button_label" style={{ marginLeft: 8 }}>
                                    Upload File
                                </p>
                            </label>
                        </div>
                        : null}
                </div>
            </div>

            <div style={{ padding: "0 32px", marginTop: 160 }}>
                {error_popup ?
                    <div className="flex column justifyCenter alignCenter w-100vw h-100vh" style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'rgba(0,0,0,0.3)' }}>
                        <div className="flex column alignCenter justifyCenter"
                            style={{
                                background: '#ffffff',
                                height: 320,
                                width: 576,
                                padding: 32,
                                borderRadius: 8,
                                boxShadow: "0 0 10px rgba(0,0,0,0.3)"
                            }}
                        >
                            <h3>The upload of your file failed!</h3>
                            <div style={{ marginTop: 24 }}>
                                <p style={{ color: "#EB5757", lineHeight: 1.6 }}>{saved_message}</p>
                            </div>
                            <div className="flex justifyEnd alignCenter w-100" style={{ margin: "32px 0 0" }}>
                                <div className="white-button pointer"
                                    onClick={() => onCloseErrorPopup()}
                                    style={{ maxWidth: 160 }}>
                                    <p className="button_label">OK</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    : null}

                {loading && <LoadingTable screenName="training_plans" />}

                {(!loading && training_plans && training_plans.length > 0) ?
                    <PlansTable
                        data_list={searchedDataSource}
                        searchStatus={isSearchActive}
                        searchedText={searchedText}
                        loadingState={loading}
                    />
                    : null}

                {loading_screen ?
                    <div className="flex column justifyCenter alignCenter w-100 h-100" style={{ position: "fixed", top: 0, left: 96, zIndex: 99 }}>

                        {loading_screen && progress_loader_1 && !progress_loader_2 && (
                            <div className="flex column justifyCenter alignCenter" style={{ height: 220, width: 456, background: "#ffffff", borderRadius: 8, boxShadow: "0 0 10px rgba(0,0,0,0.3)" }}>
                                <br />
                                <div className="progress_loader">
                                    <div className="loaderBar"></div>
                                </div>
                                <p style={{ marginTop: 20 }}>
                                    {`Creating plan, please hold on...`}
                                </p>
                            </div>
                        )}

                        {loading_screen && !progress_loader_1 && progress_loader_2 && (
                            <div className="flex column justifyCenter alignCenter" style={{ height: 220, width: 456, background: "#ffffff", borderRadius: 8, boxShadow: "0 0 10px rgba(0,0,0,0.3)" }}>
                                <br />
                                <div className="progress_loader">
                                    <div className="loaderBar"></div>
                                </div>
                                <p style={{ marginTop: 20 }}>
                                    {`Creating sessions, please wait ... `}
                                </p>
                            </div>
                        )}
                    </div>
                    : null}

                {(!loading && training_plans.length === 0) ?
                    <div className="no_record_found" style={{ margin: "72px 0 0" }}>
                        <div className="flex column alignCenter justifyCenter">
                            <h1>No Training Plans to show!</h1>
                            <p>  You haven't created any training plans yet. Click on the button to create your first training plan for Wild AI. </p>
                            <button
                                className="white-button"
                                style={{ maxWidth: 260 }}
                                onClick={() => showCreatePlanPopup(true)}>
                                <p className="button_label">
                                    <span style={{ fontSize: 18, lineHeight: "140%", color: "#ffffff", marginRight: 8 }}>+</span>
                                    Create Training Plan
                                </p>
                            </button>
                            <div className="green-button pointer"
                                style={{
                                    marginTop: 24,
                                    width: 222,
                                    marginLeft: -24
                                }}>
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={onChange}
                                    hidden
                                    id="file_button"
                                />
                                <label htmlFor="file_button" className="flex alignCenter justifyCenter pointer h-100 w-100">
                                    <img src={FileUpload} alt="file-upload-icon" height={24} width={24} />
                                    <p className="button_label" style={{ marginLeft: 8, color: '#ffffff' }}>
                                        Upload File
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    : null}
                <CreatePlan show={createPlanPopup} closePopup={() => showCreatePlanPopup(false)} />

            </div>
        </div >
    );
};

export default TrainingPlans;
