import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// Icons + images
import ArrowRight from "../../assets/icons/forward.svg";
import Camera from "../../assets/icons/camera.svg";
import Placeholder from "../../assets/icons/placeholder-default.svg";
import EditIcon from "../../assets/icons/edit-circle-dark.svg";
import EditActiveIcon from "../../assets/icons/edit-circle-active.svg";
import ChangePasswordIcon from "../../assets/icons/ChangePassword.svg";
import ChangePasswordActiveIcon from "../../assets/icons/ChangePasswordActive.svg";
import BinIcon from "../../assets/icons/delete-circle-dark.svg";
import BinActiveIcon from "../../assets/icons/delete-circle-active.svg";

// Actions
import { createNotification } from "../../store/app/actions";
import { updateProfileSettings } from "../../store/settings/actions";
import {
  fetchSettings,
  setSettings,
  updateProfilePicture,
} from "../../store/settings/actions";
import { validateEmail } from "../../_helpers/validation";

// Components
import UpdatePassword from "./UpdatePassword";
import DeleteAccount from "./DeleteAccount";
import Billing from "../billing/Billing";
import { useLocation } from "react-router-dom";

const Settings = () => {
  const { search } = useLocation();
  const { settings } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [updateImageButton, setUpdateImageButton] = useState(false);

  const [profile_data, set_profile_data] = useState({
    first_name: "",
    last_name: "",
    coach_type: "",
    email: "",
    profile: "profile",
  });

  let [settings_tab, setSettingsTab] = useState({
    show: false,
    tab_name: "",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get("tab");
    if (tab) {
      setSettingsTab({ show: true, tab_name: tab });
    }
  }, []);

  useEffect(() => {
    const url = new URL(window.location);
    url.searchParams.set("tab", settings_tab.tab_name);
    window.history.pushState({}, "", url);
  }, [settings_tab.tab_name]);

  const [submitting, setSubmitting] = useState(false);
  const [enabled, setEnabled] = useState({
    first_name: false,
    last_name: false,
    coach_type: false,
    email: false,
  });
  const [errors, setErrors] = useState({});
  const setFieldValues = (field_name, value, status) => {
    set_profile_data({ ...profile_data, [field_name]: value });
    setEnabled({ ...enabled, [field_name]: status });
  };

  const enablingStatus = (enablerObject, value) => {
    if (
      enablerObject.first_name &&
      value["first_name"] !== settings["first_name"]
    ) {
      return false;
    }
    if (
      enablerObject.last_name &&
      value["last_name"] !== settings["last_name"]
    ) {
      return false;
    }
    if (enablerObject.coach_type) {
      return false;
    }
    if (enablerObject.email && value["email"] !== settings["email"]) {
      return false;
    }
    return true;
  };

  // on Error
  const onError = (error) => {
    setSubmitting(false);
    setUploadingImage(false);
    dispatch(createNotification("error-toast", error));
  };

  // on Success
  const onSuccess = () => {
    setSubmitting(false);
    setUploadingImage(false);
    setImageFile(null);
    setUpdateImageButton(false);
  };

  // Update profile piciture
  const onUpdateImage = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      var totalBytes = files[0].size;
      if (files[0].type === "image/jpeg" || files[0].type === "image/png") {
        if (totalBytes <= 2000000) {
          setUpdateImageButton(true);
          setImageFile(reader.result);
        } else {
          dispatch(
            createNotification(
              "error-toast",
              "Please upload image file of size 2MB or below."
            )
          );
        }
      } else {
        dispatch(
          createNotification("error-toast", "Please select an image file")
        );
      }
    };
    reader.readAsDataURL(files[0]);
  };

  // Update profile
  const onUpdateProfile = async (e) => {
    e.preventDefault();
    let values = profile_data;

    if (!enabled.first_name) {
      delete profile_data.first_name;
    }
    if (!enabled.last_name) {
      delete profile_data.last_name;
    }
    if (!enabled.coach_type) {
      delete profile_data.coach_type;
    }
    if (!enabled.email) {
      delete profile_data.email;
    }

    setSubmitting(true);
    dispatch(updateProfileSettings(values, onSubmitSuccess, onSubmitError));
  };

  const onSubmitError = (error) => {
    setSubmitting(false);
    dispatch(createNotification("error-toast", error));
  };

  const onSubmitSuccess = () => {
    setSubmitting(false);
    setEnabled({
      first_name: false,
      last_name: false,
      coach_type: false,
      email: false,
    });
  };

  const onFailure = (error) => {
    setSubmitting(false);
    dispatch(createNotification("error-toast", error));
  };

  const validateInputs = (e) => {
    if (e.target.value === "") setErrors({ ...errors, [e.target.name]: true });
    else setErrors({ ...errors, [e.target.name]: false });
  };

  useEffect(() => {
    if (settings) {
      set_profile_data({
        first_name: settings["first_name"] || "",
        last_name: settings["last_name"] || "",
        coach_type: settings["coach_type"] || null,
        email: settings["email"] || "",
        profile:
          settings["profile_pic"]["profile_img_url"] ===
          "https://staging.api.wild.ai/static/default_profile_pic.png"
            ? Placeholder
            : settings["profile_pic"]["profile_img_url"],
      });
    }
  }, [settings]);

  useEffect(() => {
    dispatch(setSettings(null));
    dispatch(fetchSettings(performance.now(), onFailure));
  }, []);

  return (
    settings && (
      <div
        className="flex column w-100 h-100"
        style={{
          margin: "40px 40px 0",
          width: "calc(100vw - 240px)",
          maxHeight: "100vh",
        }}
      >
        <div className="flex alignCenter justifyBetween w-100">
          <p className="main-title" style={{ cursor: "default" }}>
            Hi, {settings["first_name"]}
          </p>
          {/* Breadcrumb */}
          <div className="flex justifyEnd alignCenter" style={{ width: 160 }}>
            <p>Home</p>
            <img
              src={ArrowRight}
              alt="arrow right"
              height={12}
              width={12}
              style={{ margin: "0 8px" }}
            />
            <p>Profile</p>
          </div>
        </div>

        {/* <div className="flex alignCenter justifyEnd w-100" style={{ marginTop: 16 }}>
        <div className="flex alignCenter" style={{ border: '1px solid #E8494E', borderRadius: 100, padding: '4px 12px' }}>
          <p className="flex justifyCenter alignCenter" style={{ background: '#E8494E', width: 36, height: 36, borderRadius: '50%', marginRight: 16 }}>0</p>
          <p>Notifications</p>
        </div>
      </div> */}

        <div
          className="flex column alignCenter justifyCenter w-100"
          style={{
            height: settings_tab.show ? "100%" : "calc(100vh - 220px)",
            marginTop: settings_tab.show ? 36 : 0,
          }}
        >
          <div className="flex alignCenter justifyCenter w-100">
            <div
              onClick={() =>
                setSettingsTab({ show: true, tab_name: "edit_profile" })
              }
              className="flex column alignCenter justifyCenter pointer"
              style={{
                width: 300,
                height: 200,
                background:
                  settings_tab.tab_name === "edit_profile"
                    ? "none"
                    : "rgba(255, 255, 255, 0.15)",
                borderRadius: 12,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                border:
                  settings_tab.tab_name === "edit_profile"
                    ? "2px solid #08AFA8"
                    : null,
              }}
            >
              <img
                src={
                  settings_tab.tab_name === "edit_profile"
                    ? EditActiveIcon
                    : EditIcon
                }
                alt="edit icon"
                height={100}
                width={100}
              />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: 1.25,
                  wordSpacing: 2,
                  fontWeight: 600,
                  color:
                    settings_tab.tab_name === "edit_profile"
                      ? "#08AFA8"
                      : "#FFFFFF",
                }}
              >
                Edit Profile
              </p>
            </div>
            <div
              onClick={() =>
                setSettingsTab({ show: true, tab_name: "subscription" })
              }
              className="flex column alignCenter justifyCenter pointer"
              style={{
                width: 300,
                height: 200,
                margin: "0 0 0 8px",
                background:
                  settings_tab.tab_name === "subscription"
                    ? "none"
                    : "rgba(255, 255, 255, 0.15)",
                borderRadius: 12,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                border:
                  settings_tab.tab_name === "subscription"
                    ? "2px solid #08AFA8"
                    : null,
              }}
            >
              <img
                src={
                  settings_tab.tab_name === "subscription"
                    ? EditActiveIcon
                    : EditIcon
                }
                alt="edit icon"
                height={100}
                width={100}
              />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: 1.25,
                  wordSpacing: 2,
                  fontWeight: 600,
                  color:
                    settings_tab.tab_name === "change_password"
                      ? "#08AFA8"
                      : "#FFFFFF",
                }}
              >
                Subscription Management
              </p>
            </div>
            <div
              onClick={() =>
                setSettingsTab({ show: true, tab_name: "change_password" })
              }
              className="flex column alignCenter justifyCenter pointer"
              style={{
                width: 300,
                height: 200,
                margin: "0 8px",
                background:
                  settings_tab.tab_name === "change_password"
                    ? "none"
                    : "rgba(255, 255, 255, 0.15)",
                borderRadius: 12,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                border:
                  settings_tab.tab_name === "change_password"
                    ? "2px solid #08AFA8"
                    : null,
              }}
            >
              <img
                src={
                  settings_tab.tab_name === "change_password"
                    ? ChangePasswordActiveIcon
                    : ChangePasswordIcon
                }
                alt="edit icon"
                height={100}
                width={100}
              />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: 1.25,
                  wordSpacing: 2,
                  fontWeight: 600,
                  color:
                    settings_tab.tab_name === "change_password"
                      ? "#08AFA8"
                      : "#FFFFFF",
                }}
              >
                Change Password
              </p>
            </div>
            <div
              onClick={() =>
                setSettingsTab({ show: true, tab_name: "delete_account" })
              }
              className="flex column alignCenter justifyCenter pointer"
              style={{
                width: 300,
                height: 200,
                background:
                  settings_tab.tab_name === "delete_account"
                    ? "none"
                    : "rgba(255, 255, 255, 0.15)",
                borderRadius: 12,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                border:
                  settings_tab.tab_name === "delete_account"
                    ? "2px solid #E8494E"
                    : null,
              }}
            >
              <img
                src={
                  settings_tab.tab_name === "delete_account"
                    ? BinActiveIcon
                    : BinIcon
                }
                alt="edit icon"
                height={100}
                width={100}
              />
              <br />
              <p
                style={{
                  textTransform: "uppercase",
                  letterSpacing: 1.25,
                  wordSpacing: 2,
                  fontWeight: 600,
                  color:
                    settings_tab.tab_name === "delete_account"
                      ? "#E8494E"
                      : "#FFFFFF",
                }}
              >
                Delete Account
              </p>
            </div>
          </div>
          {settings_tab.show && settings_tab.tab_name === "subscription" && (
            <Billing />
          )}
          {/* Update Profile */}
          {settings_tab.show && settings_tab.tab_name === "edit_profile" && (
            <div className="settings-main alignCenter w-100">
              {/* Profile image */}
              <div className="flex relative">
                {uploadingImage ? (
                  <div className="uploading_image_state">
                    <div className="custom_small_loader" />
                  </div>
                ) : imageFile ? (
                  <div className="uploading_image_state">
                    <img
                      src={imageFile}
                      alt="profile-icon"
                      height="100%"
                      width="100%"
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                  </div>
                ) : (
                  <div className="uploading_image_state">
                    <img
                      src={`${profile_data["profile"]}?${performance.now()}`}
                      alt="profile-icon"
                      height="100%"
                      width="100%"
                      style={{ borderRadius: "50%", objectFit: "cover" }}
                    />
                  </div>
                )}
                <div className="camera_btn">
                  <input
                    type="file"
                    id="actual-btn"
                    accept="image/x-png,image/jpeg"
                    onChange={onUpdateImage}
                    hidden
                  />
                  <label
                    className="flex column alignCenter justifyCenter pointer h-100"
                    htmlFor="actual-btn"
                  >
                    <img
                      src={Camera}
                      alt="camera-icon"
                      height={22}
                      width={22}
                    />
                  </label>
                </div>
              </div>

              {/* Form */}
              <form
                className="flex column alignCenter justifyCenter w-100"
                onSubmit={(e) => onUpdateProfile(e)}
                style={{ marginTop: 44 }}
              >
                {/* Field: first_name  + last_name*/}
                <div className="flex justifyBetween alignStart w-100">
                  <div className="flex column justifyStart alignStart w-100">
                    <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                      <label className="settings-label">First name</label>
                      <input
                        type="text"
                        value={profile_data["first_name"]}
                        required
                        placeholder="First name"
                        className="settings-value"
                        onChange={(e) =>
                          setFieldValues(
                            "first_name",
                            e.target.value,
                            e.target.value.trim() === settings["first_name"]
                              ? false
                              : true
                          )
                        }
                      />
                    </div>
                    {errors.first_name && profile_data["first_name"] === "" && (
                      <span
                        style={{ color: "#EB5757", marginTop: 8, fontSize: 12 }}
                      >
                        Please enter your first name.
                      </span>
                    )}
                  </div>
                  {/* gap */}
                  <div style={{ width: 32 }} />
                  <div className="flex column justifyStart alignStart w-100">
                    <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                      <label className="settings-label">Last name</label>
                      <input
                        type="text"
                        value={profile_data["last_name"]}
                        required
                        placeholder="Last name"
                        className="settings-value"
                        onChange={(e) =>
                          setFieldValues(
                            "last_name",
                            e.target.value,
                            e.target.value.trim() === settings["last_name"]
                              ? false
                              : true
                          )
                        }
                      />
                    </div>
                    {errors.last_name && profile_data["last_name"] === "" && (
                      <span
                        style={{ color: "#EB5757", marginTop: 8, fontSize: 12 }}
                      >
                        Please enter your last name.
                      </span>
                    )}
                  </div>
                </div>

                {/* Field: coach_type */}
                <div className="flex justifyBetween alignCenter w-100">
                  <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                    <label className="settings-label">Coach type</label>
                    <select
                      className="coach-types"
                      onChange={(e) => {
                        e.target.value.localeCompare(settings.coach_type) !== 0
                          ? setFieldValues("coach_type", e.target.value, true)
                          : setFieldValues(
                              "coach_type",
                              profile_data["coach_type"],
                              false
                            );
                      }}
                      defaultValue={profile_data["coach_type"]}
                    >
                      {Array.isArray(settings.coach_type_options) &&
                        settings.coach_type_options.map(
                          (coachTypeOption, index) => (
                            <option
                              selected={
                                coachTypeOption === settings.coach_type
                                  ? true
                                  : false
                              }
                              value={coachTypeOption}
                              key={`option-${index + 1}`}
                              className="settings-value"
                            >
                              {coachTypeOption}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>

                {/* Field: email_address */}
                <div className="flex column justifyStart alignStart w-100">
                  <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                    <label className="settings-label">Email</label>
                    <input
                      type="email"
                      value={profile_data["email"]}
                      required
                      name="email"
                      placeholder="Email address"
                      className="settings-value"
                      onBlur={validateInputs}
                      onChange={(e) => {
                        if (validateEmail(e.target.value)) {
                          if (e.target.value === settings["email"]) {
                            setFieldValues("email", e.target.value, false);
                          } else {
                            setFieldValues("email", e.target.value, true);
                          }
                          setErrors({ ...errors, email: false });
                        } else {
                          setFieldValues("email", e.target.value, false);
                          setErrors({ ...errors, email: true });
                        }
                      }}
                    />
                  </div>
                  {errors.email && (
                    <span
                      style={{ color: "#EB5757", marginTop: 8, fontSize: 12 }}
                    >
                      Please enter a valid email address.
                    </span>
                  )}
                </div>

                {/* Update password link + update profile settings button */}
                <div
                  className="flex justifyBetween alignCenter w-100"
                  style={{ marginTop: 24 }}
                >
                  {updateImageButton ? (
                    <div
                      className="white-button"
                      onClick={() => {
                        setUploadingImage(true);
                        dispatch(
                          updateProfilePicture(imageFile, onSuccess, onError)
                        );
                      }}
                    >
                      {uploadingImage ? (
                        <div className="flex justifyCenter alignCenter">
                          <div className="custom_button_loader" />
                        </div>
                      ) : (
                        <p className="button_label">UPDATE IMAGE</p>
                      )}
                    </div>
                  ) : (
                    <button
                      htmltype="submit"
                      disabled={enablingStatus(enabled, profile_data)}
                      className={
                        enablingStatus(enabled, profile_data)
                          ? "disable-button"
                          : "white-button"
                      }
                    >
                      {submitting ? (
                        <div className="flex justifyCenter alignCenter">
                          <div className="custom_button_loader" />
                        </div>
                      ) : (
                        <p className="button_label">UPDATE</p>
                      )}
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
          {/* Update Password */}
          {settings_tab.show && settings_tab.tab_name === "change_password" && (
            <UpdatePassword />
          )}
          {/* Reset Password */}
          {settings_tab.show && settings_tab.tab_name === "delete_account" && (
            <DeleteAccount />
          )}
        </div>
      </div>
    )
  );
};

export default Settings;
