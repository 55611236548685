import React from "react";
import Skeleton from "react-loading-skeleton";

const LoadingTable = ({ screenName }) => {
  const teamColumns = [
    { title: "Team Name", width: 240 },
    { title: "Athletes", width: 120 },
    { title: "Owner", width: 200 },
    { title: "Coaches", width: 120 },
    { title: "", width: 120 },
  ]

  const athletesColumns = [
    { title: "Athlete", width: 200 },
    { title: "Cycle Length", width: 100 },
    { title: "Next Training", width: 100 },
    { title: "Check-ins", width: 80 },
    { title: "Readiness", width: 200 },
    { title: "", width: 40 },
  ];

  const managementAthletesColumns = [
    { title: "Athlete", width: 200 },
    { title: "Email", width: 240 },
    { title: "Invited", width: 100 },
    { title: "Status", width: 100 },
    { title: "", width: 40 },
  ];

  const managementCoachesColumn = [
    { title: "Coach", width: 200 },
    { title: "Email", width: 240 },
    { title: "Invited", width: 100 },
    { title: "Status", width: 100 },
    { title: "", width: 40 },
  ];

  const trainingsColumns = [
    { title: "Plan name", width: 80 },
    { title: "Author", width: 60 },
    { title: "Promo video", width: 80 },
    { title: "Sport type", width: 100 },
    { title: "Created", width: 80 },
    { title: "Reviewed", width: 80 },
    { title: "Draft", width: 60 },
    { title: "Published", width: 80 },
    { title: "Views", width: 60 },
    { title: "Purchases", width: 80 },
    { title: "Athletes", width: 80 },
    { title: "", width: 48 },
  ];

  const adminPlansColumns = [
    { title: "Plan name", width: 80 },
    { title: "Author", width: 60 },
    { title: "Promo video", width: 80 },
    { title: "Sport type", width: 80 },
    { title: "Created", width: 80 },
    { title: "Level", width: 80 },
    { title: "Plan length", width: 80 },
    { title: "Price", width: 60 },
    { title: "Tags", width: 60 },
    { title: "", width: 48 },
  ];

  const sessionsColumns = [
    { title: "Session name", width: 160 },
    { title: "Week", width: 80 },
    { title: "Sport type", width: 100 },
    { title: "Intensity", width: 80 },
    { title: "Duration", width: 60 },
    { title: "Focus", width: 160 },
    { title: "Description", width: 160 },
    { title: "Video", width: 80 },
    { title: "", width: 48 },
  ];


  const getColumnsForScreen = () => {
    if (screenName === "athletes") {
      return athletesColumns;
    } else if (screenName === "teams") {
      return teamColumns;
    } else if (screenName === "management-athletes") {
      return managementAthletesColumns;
    } else if (screenName === "management-coaches") {
      return managementCoachesColumn;
    } else if (screenName === "training_plans") {
      return trainingsColumns
    } else if (screenName === "training_plan_sessions") {
      return sessionsColumns;
    } else if (screenName === "admin_training_plans") {
      return adminPlansColumns;
    }
  };

  return (
    <div className="w-100">
      <div className="flex justifyBetween alignCenter">
        {getColumnsForScreen().map((column, index) => (
          <div key={index + 1} style={{ textAlign: index === 0 ? 'left' : 'center', minWidth: column.width }}>
            <p style={{ fontSize: 13 }}>{column.title}</p>
          </div>
        ))}
      </div>

      <div style={{ margin: "16px 0 0", }}>
        <Skeleton height={90} style={{ borderRadius: 10, background: 'rgba(255, 255, 255, 0.1)' }} />
      </div>
      <div style={{ margin: "8px 0 0" }}>
        <Skeleton height={90} style={{ borderRadius: 10, background: 'rgba(255, 255, 255, 0.1)' }} />
      </div>
      <div style={{ margin: "8px 0 0" }}>
        <Skeleton height={90} style={{ borderRadius: 10, background: 'rgba(255, 255, 255, 0.1)' }} />
      </div>
      <div style={{ margin: "8px 0 0" }}>
        <Skeleton height={90} style={{ borderRadius: 10, background: 'rgba(255, 255, 255, 0.1)' }} />
      </div>
    </div>
  );

};
export default LoadingTable;
