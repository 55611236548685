import React, { useEffect, useState } from "react";
import MinusIcon from "../../../assets/icons/minus.svg";
import UserIcon from "../../../assets/icons/user.svg";
import "./Congrats.css";
import { history } from "../../../index";
import BasicPricing from "./BasicPricing";
import SliderPricing from "./SliderPricing";
import { useSelector } from "react-redux";

const Congrats = () => {
  const { user } = useSelector((state) => state.app);

  const [coachSeats, setCoachSeats] = useState(1);
  const [athleteSeats, setAthleteSeats] = useState(0);
  const [isSliderPricing, setIsSliderPricing] = useState(false);
  const [areAthletesHavingSubscription, setAreAthletesHavingSubscription] =
    useState(false);
  // after creating team --> go to congrats (only 1 screen) --> then after you pay ---> to Invite athletes page ---> to Team settings
  return (
    <div className="flex row" style={{ margin: "40px 80px", gap: "120px" }}>
      <div className="flex column justifyStart" style={{ gap: "40px" }}>
        <div className="flex column " style={{ gap: "40px" }}>
          <div
            className="flex column"
            style={{ gap: "16px", margin: "24px 0" }}
          >
            <span style={{ fontSize: "32px" }}>
              Almost there, {user.coach.fist_name}!
            </span>
            <span style={{ fontSize: "16px" }}>
              Now, let’s add a plan to your account. <br />
              You’ll be able to add as many teams as you want.
            </span>
          </div>
        </div>
        <BasicPricing
          coachSeats={coachSeats}
          setCoachSeats={setCoachSeats}
          athleteSeats={athleteSeats}
          setAthleteSeats={setAthleteSeats}
        />
      </div>
      {isSliderPricing && (
        <>
          {!areAthletesHavingSubscription ? (
            <div className="flex column justifyCenter">
              <div className="user-circle">
                <img src={UserIcon} width={26} height={26} />
              </div>
              <span style={{ fontSize: "28px", marginTop: "24px" }}>
                We sent your athletes an invite
              </span>
              <span style={{ fontSize: "40px" }}>to join Wild.AI</span>
              <span>
                Your athletes will appear in your dashboard as soon as <br />{" "}
                they create an account
              </span>
            </div>
          ) : (
            <div className="flex column justifyCenter">
              <div className="flex row">
                <div className="user-circle">
                  <img src={UserIcon} width={26} height={26} />
                </div>
                <div className="user-circle" style={{ marginLeft: "-25px" }}>
                  <img src={UserIcon} width={26} height={26} />
                </div>
                <div className="user-circle" style={{ marginLeft: "-25px" }}>
                  <img src={UserIcon} width={26} height={26} />
                </div>
              </div>
              <span style={{ fontSize: "28px", marginTop: "24px" }}>
                Your athletes
              </span>
              <span style={{ fontSize: "40px" }}>Claire and Samantha</span>
              <span style={{ fontSize: "28px" }}>
                are waiting for your instructions!
              </span>
              <span style={{ marginTop: "24px" }}>
                It also looks like at least one of your athletes don’t have an
                account. <br /> We have sent them an invite to join.
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default Congrats;
