import React, { useEffect, useState } from "react";
// Provider
import { split } from "lodash";
import { useDispatch, useSelector } from "react-redux";
// Icons
import BackwardIcon from "../../assets/icons/backward-black.svg";
import PlayButtonIcon from "../../assets/icons/play-button.svg";
// Components
import ReactPlayer from "react-player";
// Actions
import { getShortDescription, secondsToHm } from "../../_helpers/chunks";
import { history } from "../../index";
import { createNotification } from "../../store/app/actions";
import { acceptPlan, fetchSessionSportTypes, fetchSportTypes, fetchTrainingIntensities, fetchTrainingLevels, getAdminTrainingPlan, getAdminTrainingPlanSessions, publishPlan, rejectPlan } from "../../store/trainings/actions";

const AdminPlanDetail = () => {
    const { sport_types, training_levels, plan_detail, training_plan_sessions, session_sport_types, training_intensities } = useSelector((state) => state.training_plans);
    const dispatch = useDispatch();
    const { location: { search } } = history;
    const planId = search.replace("?id=", "");
    let currency_list = ["USD", "EUR", "JPY", "GBP"];

    // States
    const [loading, setLoading] = useState({
        plan_loading: false,
        sessions_loading: false
    });
    let [training_plan_data, set_training_plan_data] = useState({
        name: "",
        short_title: "",
        sport_type: null,
        level: null,
        plan_length_weeks: 4,
        coach_bio: "",
        summary: "",
        description: "",
        image: null,
        video: "",
        tags: null,
        price: 0.00,
        currency: "",
    });
    let [popup_card, showPopupCard] = useState({
        reject_plan_popup: false,
        accept_plan_popup: false,
        publish_plan_popup: false,
        loading_state: false,
    })
    let [image_for_preview, set_image_for_preview] = useState("");
    let [show_preview, set_preview] = useState(true);
    let [weekly_sessions_list, setWeeklySessionsList] = useState(null);
    // Actions
    function resetPops() {
        showPopupCard({
            reject_plan_popup: false,
            accept_plan_popup: false,
            publish_plan_popup: false,
            loading_state: false,
        });
    }

    function filterName(list, id) {
        if (list && list.length > 0) {
            let res = list.filter((item) => item['pk'] === id && item)[0];
            if (res) {
                return res['display_name'];
            }
            return "";
        }
    };
    function generateRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    // Tags operations
    const setBackendTags = (value) => {
        let collector = [];
        let tags_collector = [];
        if (value !== null && value !== "") {
            const tags = split(value, ",");
            if (tags.length > 0) {
                for (var i = 0; i < tags.length; i++) {
                    const splittedTag = split(tags[i], " ");
                    for (var j = 0; j < splittedTag.length; j++) {
                        collector.push(splittedTag[j])
                    }
                }
            }
            if (collector.length > 0) {
                for (let j = 0; j < collector.length; j++) {
                    if (collector[j] !== "") {
                        tags_collector.push({
                            tag: collector[j].trim(),
                            status: 'existing',
                            color: generateRandomColor()
                        });

                    }
                }
            }
        }
        return tags_collector;
    };



    //  On Reject
    const onRejectPlan = (comments) => {
        const values = {
            accept: false,
            comments: comments,
        }
        showPopupCard({ ...popup_card, loading_state: true });
        dispatch(rejectPlan(planId, values, onSuccess, onError));
    }
    // On Accept
    const onAcceptPlan = (comments) => {
        const values = {
            accept: true,
            comments: comments,
        }
        showPopupCard({ ...popup_card, loading_state: true });
        dispatch(acceptPlan(planId, values, onSuccess, onError));
    }
    // On Publish
    const onPublishPlan = () => {
        showPopupCard({ ...popup_card, loading_state: true });
        dispatch(publishPlan(planId, onSuccess, onError));
    }


    // On Error
    const onSuccess = (message) => {
        resetPops();
        dispatch(createNotification("success-toast", message));
        history.push("/dashboard/admin/plans");
    }
    const onError = (error) => {
        if (error === "Plan does not exist") {
            history.push("/dashboard/admin/plans");
        }
        resetPops();
        dispatch(createNotification("error-toast", error));
    }

    // Training plan weekly sessions list
    useEffect(() => {
        if (training_plan_sessions && training_plan_sessions.length > 0) {
            var week_nos = training_plan_sessions.map(({ week_no }) => week_no);
            week_nos = week_nos.filter(function (item, pos) {
                return week_nos.indexOf(item) === pos;
            });
            var sessions_list = [];
            for (var i = 0; i < week_nos.length; i++) {
                // eslint-disable-next-line
                sessions_list[i] = training_plan_sessions.filter(function (item) {
                    return item['week_no'] === week_nos[i];
                });
            }
            setWeeklySessionsList(sessions_list);
        }
    }, [training_plan_sessions]);

    // Training plan details
    useEffect(() => {
        if (plan_detail) {
            setLoading({ ...loading, plan_loading: false });
            const tags_collection = setBackendTags(plan_detail['tags']);
            set_training_plan_data({
                image: plan_detail['plan_image'] || null,
                video: plan_detail['promo_video'] || "",
                name: plan_detail['name'] || "",
                short_title: plan_detail['short_title'] || "",
                coach_bio: plan_detail['coach_bio'] || "",
                summary: plan_detail['summary'] || "",
                description: plan_detail['description'] || "",
                plan_length_weeks: plan_detail['plan_length'] || 4,
                sport_type: plan_detail['sport_type'] || null,
                level: plan_detail['level'] || null,
                tags: tags_collection,
                price: plan_detail['price'] || 0.00,
                currency: plan_detail['currency'] || currency_list[0],
                moderator_comments: plan_detail['moderator_comments']
            });
            set_image_for_preview(plan_detail['plan_image'] || null);
        }
    }, [plan_detail]);

    function myFunction() {
        if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
            if (document.getElementById("header")) {
                // document.getElementById("header").style.boxShadow = "rgb(0 0 0 / 30%) 0px 0px 8px";
                document.getElementById("header").style.padding = "16px 24px";
            }
        } else {
            if (document.getElementById("header")) {
                // document.getElementById("header").style.boxShadow = "none";
                document.getElementById("header").style.padding = "16px 24px";
            }
        }
    }
    function stopLoading() { setLoading({ ...loading, sessions_loading: false }) }
    useEffect(() => {
        window.localStorage.removeItem("inviteResponse");
        window.onscroll = function () { myFunction() };
        if (planId) {
            setLoading({ plan_loading: true, sessions_loading: true });
            dispatch(fetchSportTypes(onError));
            dispatch(fetchTrainingLevels(onError));
            dispatch(fetchSessionSportTypes(onError));
            dispatch(fetchTrainingIntensities(onError));

            dispatch(getAdminTrainingPlan(planId, onError));
            dispatch(getAdminTrainingPlanSessions(planId, stopLoading, onError));
        }
        
    }, []);

    return loading['plan_loading'] ?
        <div className="flex column justifyCenter alignCenter w-100 h-100vh">
            <div className="custom_loader" />
            <p style={{ marginTop: 32 }}>
                {`Please wait a moment`}
            </p>
        </div>
        :
        <div className="flex column w-100 h-100" style={{ color: '#000000' }}>
            {/* Header */}
            <div id="header" className="flex column justifyCenter alignCenter"
                style={{
                    position: 'fixed',
                    top: 0,
                    height: 80,
                    zIndex: 10,
                    background: 'rgba(1, 33, 58, 0.7)',
                    backdropFilter: 'blur(25px)',
                    width: 'calc(100vw - 150px)',
                    padding: '24px 24px 0 24px'
                }}>
                <div className="flex alignCenter justifyBetween w-100">
                    <div className="flex justifyStart alignCenter">
                        <img className="pointer" src={BackwardIcon} alt="back_icon" height={20} width={20} onClick={() => history.push("/dashboard/admin/plans")} />
                        <h3 style={{ marginLeft: 16, fontSize: 24, color: '#FFFFFF' }}>
                            {training_plan_data && training_plan_data['name']}
                        </h3>
                    </div>


                    <div className="flex justifyEnd alignCenter">
                        {window.localStorage.getItem("tab") === "Review" ?
                            <div className="green-button" style={{ maxWidth: 200, width: 200 }} onClick={() => showPopupCard({ ...popup_card, accept_plan_popup: true })} >
                                <p className="button_label">Accept plan</p>
                            </div>
                            : null}

                        {window.localStorage.getItem("tab") === "Review" ?
                            <div className="delete-button" style={{ maxWidth: 200, width: 200, margin: "0 16px" }} onClick={() => showPopupCard({ ...popup_card, reject_plan_popup: true })} >
                                <p className="button_label">Reject plan</p>
                            </div>
                            : null}

                        {window.localStorage.getItem("tab") === "Publish" ?
                            <div className="green-button" style={{ maxWidth: 200, width: 200, margin: "0 16px" }} onClick={() => showPopupCard({ ...popup_card, publish_plan_popup: true })} >
                                <p className="button_label">Publish plan</p>
                            </div>
                            : null}
                    </div>
                </div>
            </div>

            <div className="flex column justifyStart alignStart w-100" style={{ overflow: 'auto', margin: "120px 0 0", padding: "0 32px 0 60px" }}>
                <div className="w-100" style={{ minWidth: 1680 }}>
                    {/* Content Area for training plan */}
                    <div className="flex justifyBetween alignStart w-100">
                        {/* Left section */}
                        <div className="flex column justifyStart" style={{ minWidth: 350, maxWidth: 520, paddingRight: 56, color: '#FFFFFF' }}>
                            {/* Field: Moderator comments */}
                            {training_plan_data['moderator_comments'] !== "" ?
                                <div className="flex column justifyStart w-100" style={{ marginBottom: 16 }}>
                                    <label className="settings-label">Moderator comments</label>
                                    <p style={{ marginTop: 8 }}> {training_plan_data['moderator_comments']}  </p>
                                </div>
                                : null}
                            {/* Field: sport_types */}
                            <div className="flex column justifyStart w-100">
                                <label className="settings-label">Sport Type</label>
                                {(sport_types && sport_types.length > 0) &&
                                    sport_types.map((option, index) => option.pk === Number(training_plan_data['sport_type']) ?
                                        <p key={index + 1} style={{ marginTop: 8 }}>{option.display_name}</p>
                                        : null)}
                            </div>
                            {/* Field: level */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Level</label>
                                {training_levels && training_levels.length > 0 &&
                                    training_levels.map((option, index) => option.pk === Number(training_plan_data['level']) ?
                                        <p key={index + 1} style={{ marginTop: 8 }}>{option.display_name}</p>
                                        : null)}
                            </div>
                            {/* Field: summary */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Summary</label>
                                <p style={{ marginTop: 8 }}>{training_plan_data['summary']}  </p>
                            </div>
                            {/* Field: description */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Description</label>
                                <p style={{ marginTop: 8 }}>{training_plan_data['description']}  </p>
                            </div>

                        </div>
                        {/* Right section */}
                        <div className="flex column justifyStart" style={{ minWidth: 656 }}>
                            <div className="flex justifyStart alignCenter">
                                {/* Plan video */}
                                <div className="flex column justifyStart w-100">
                                    <label className="settings-label">Promo video</label>
                                    <div className="flex relative" style={{ marginTop: 8 }}>
                                        {(show_preview && training_plan_data['video'] !== "") ? (
                                            <div className="flex justifyCenter alignCenter relative w-100" style={{ height: 200, width: "100%", borderRadius: 6 }}>
                                                <img src="https://media.wild.ai/assets/sport_images/strength_and_conditioning.jpeg" alt="plan_image" height={200} width="100%" style={{ borderRadius: 6 }} />
                                                <div className="flex column justifyCenter alignCenter h-100 absolute w-100">
                                                    <div className="custom_loader" />
                                                </div>
                                            </div>
                                        ) : null}

                                        {(training_plan_data['video'] === null || training_plan_data['video'] === "") ?
                                            <div className="flex column alignCenter justifyCenter" style={{ background: '#e6e6e6', marginTop: 8, height: 200, width: "100%", borderRadius: 6, }}>
                                                <p>No Video Uploaded</p>
                                            </div>
                                            : null}
                                        <ReactPlayer
                                            width="100%"
                                            height={200}
                                            url={training_plan_data['video']}
                                            controls={true}
                                            style={{ display: show_preview ? 'none' : 'block', borderRadius: 6, }}
                                            onReady={() => set_preview(false)}
                                        />
                                    </div>
                                </div>
                                {/* Plan image */}
                                <div className="flex column justifyStart w-100" style={{ marginLeft: 16 }}>
                                    <label className="settings-label">Plan image</label>
                                    {image_for_preview ? (
                                        <img src={image_for_preview} alt="plan_image"
                                            height={200} width="100%" style={{ borderRadius: 6, marginTop: 8, objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <div className="flex column alignCenter justifyCenter" style={{ background: '#e6e6e6', marginTop: 8, height: 200, width: "100%", borderRadius: 6, }}>
                                            <p>No Image Uploaded</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Field: Coach Bio */}
                            <div className="flex column justifyStart w-100" style={{ marginTop: 16 }}>
                                <label className="settings-label">Coach Bio</label>
                                <p style={{ marginTop: 8, color: '#FFFFFF' }}>{training_plan_data['coach_bio']}  </p>
                            </div>
                            {/* Price + Currency */}
                            <div className="flex justifyStart alignCenter" style={{ margin: "24px 0" }}>
                                <div className="flex column justifyStart">
                                    <label className="settings-label">Price</label>
                                    <div style={{ background: '#FFF', borderRadius: 4, marginTop: 12, width: 320, padding: "8px 12px" }}>
                                        <p style={{ marginTop: 4, color: '#000000' }}>{training_plan_data['price']}  </p>
                                    </div>
                                </div>
                                <div className="flex column justifyStart" style={{ marginLeft: 16 }}>
                                    <label className="settings-label">Currency</label>
                                    <div style={{ background: '#FFF', borderRadius: 4, marginTop: 12, width: 320, padding: "8px 12px" }}>
                                        <p style={{ marginTop: 4, color: '#000000' }}>{training_plan_data['currency']}  </p>
                                    </div>
                                </div>
                            </div>

                            {/* Tags */}
                            <div className="flex column justifStart">
                                <label className="settings-label">Tags</label>
                                <div id="tags">
                                    {(training_plan_data['tags'] && training_plan_data['tags'].length > 0) ? training_plan_data['tags'].map((item, index) => item['status'] === "existing" ?
                                        <li key={`key-${index + 1}`} className="tag" style={{ background: `${item.color}50` || '#ffffff', color: '#FFFFFF' }}><span className="tag-title">{item.tag}</span> </li>
                                        : null) : <p style={{ marginTop: 8 }}>No tags added </p>}
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Content area for sessions list */}
                    {loading['sessions_loading'] ?
                        <div className="flex column justifyCenter alignCenter w-100vw" style={{ height: 425 }}  >
                            <div className="custom_loader" />
                            <p style={{ marginTop: 32 }}>
                                {`Loading sessions list, please wait…`}
                            </p>
                        </div>
                        : weekly_sessions_list ?
                            <div className="flex column alignStart w-100" style={{ background: '#FFFFFF', borderRadius: 10, margin: '56px 0', padding: 32 }}>
                                {weekly_sessions_list.map((item, index) => (
                                    <div className="flex column alignStart w-100">
                                        <div className="flex justifyBetween alignCenter w-100" style={{ height: 56, padding: "0 8px" }}>
                                            <p style={{ fontSize: 18, fontWeight: 600 }}>Week {index + 1}</p>
                                        </div>

                                        {item.map((session, session_index) =>
                                            <div className="flex column w-100 gray_box" key={session_index + 1} style={{ margin: "24px 0" }}>
                                                {/* 1- Session detail */}
                                                <div className="flex justifyBetween w-100" style={{ margin: "8px 0" }}>
                                                    <div className="flex justifyBetween alignCenter w-100" style={{ height: 32 }}>
                                                        <p style={{ fontSize: 14, width: 220 }}>{session['name']}</p>
                                                        <p style={{ fontSize: 14, width: 120 }}>{session['block']}</p>
                                                        <p style={{ fontSize: 14, width: 200 }}>{filterName(session_sport_types, session['sport_type'])}</p>
                                                        <p style={{ fontSize: 14, width: 120 }}>{`${filterName(training_intensities, session['intensity'])} intensity`}</p>
                                                        <p style={{ fontSize: 14, width: 120 }}>{secondsToHm(session['duration'])}</p>
                                                    </div>
                                                </div>

                                                <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0", padding: '11px 24px 11px 32px', borderTop: '1px solid #000' }}>
                                                    <div className="flex alignCenter w-100" style={{ height: 32, }}>
                                                        <p style={{ fontSize: 14, width: 80 }}>Category</p>
                                                        <p style={{ fontSize: 14, width: 120, padding: 20 }}>Video</p>
                                                        <p style={{ fontSize: 14, width: 120 }}>Focus</p>
                                                        <p style={{ fontSize: 14, width: 140 }}>Movement</p>
                                                        <p style={{ fontSize: 14, width: 60 }}>Sets</p>
                                                        <p style={{ fontSize: 14, width: 140 }}>Reps</p>
                                                        <p style={{ fontSize: 14, width: 80 }}>Tempo</p>
                                                        <p style={{ fontSize: 14, width: 140 }}>Load</p>
                                                        <p style={{ fontSize: 14, width: 140 }}>Rest Period</p>
                                                        <p style={{ fontSize: 14, width: 100 }}>Key</p>
                                                        <p style={{ fontSize: 14, width: 180 }}>Exercise Importance</p>
                                                        <p style={{ fontSize: 14, width: 220 }}>Description</p>
                                                    </div>
                                                    <div className="transparent_separator" />
                                                    <div className="flex justifyCenter alignCenter" style={{ width: 140 }} />
                                                </div>
                                                {/* 3- Exerices list */}
                                                {(session['description_dict'][0] !== null)
                                                    && session['description_dict'].map((exercise, exercise_index) =>
                                                        <div className="exercise_card" key={exercise_index + 1}>
                                                            <div className="flex alignCenter w-100" style={{ height: 32 }}>
                                                                <p style={{ fontSize: 14, width: 80 }}>{exercise['category']}</p>
                                                                {(exercise['video'] && exercise['video'] !== "") ?
                                                                    <div onClick={() => window.open(exercise['video'], "_blank")} className="flex justifyCenter alignCenter pointer w-100" style={{ minWidth: 120, maxWidth: 120 }}>
                                                                        <img src={PlayButtonIcon} alt="play button icon" height={40} width={40} style={{ marginLeft: -44 }} />
                                                                    </div>
                                                                    : <div className="flex justifyStart alignCenter pointer" style={{ minWidth: 120, maxWidth: 120 }} />}
                                                                <p style={{ fontSize: 14, width: 120 }}>{exercise['focus']}</p>
                                                                <p style={{ fontSize: 14, width: 140 }}>{getShortDescription(exercise['movement'], 24)}</p>
                                                                <p style={{ fontSize: 14, width: 60 }}>{exercise['sets']}</p>
                                                                <p style={{ fontSize: 14, width: 140 }}>{getShortDescription(exercise['reps'], 12)}</p>
                                                                <p style={{ fontSize: 14, width: 80 }}>{exercise['tempo']}</p>
                                                                <p style={{ fontSize: 14, width: 140, paddingRight: 16 }}>{getShortDescription(exercise['load'], 20)}</p>
                                                                <p style={{ fontSize: 14, width: 140 }}>{exercise['rest_period']}</p>
                                                                <p style={{ fontSize: 14, width: 100 }}>{exercise['key']}</p>
                                                                <p style={{ fontSize: 14, width: 180 }}>{exercise['exercise_importance']}</p>
                                                                <p style={{ fontSize: 14, width: 220 }}>{getShortDescription(exercise['description'], 28)}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            : weekly_sessions_list === null ?
                                <div className="flex column w-100 gray_box" style={{ background: '#FFFFFF', margin: "64px 0" }}>
                                    <div className="flex justifyBetween w-100">
                                        <div className="flex justifyBetween alignCenter w-100" style={{ height: 32 }}>
                                            <p style={{ fontSize: 14, width: 220 }}>Session name</p>
                                            <p style={{ fontSize: 14, width: 100 }}>Block</p>
                                            <p style={{ fontSize: 14, width: 200 }}>Sport type</p>
                                            <p style={{ fontSize: 14, width: 120 }}>Intensity</p>
                                            <p style={{ fontSize: 14, width: 120 }}>Duration</p>
                                        </div>
                                        <div className="transparent_separator" />
                                        <div className="flex justifyCenter alignCenter" style={{ width: 140 }} />
                                    </div>
                                    <div className="flex column justifyCenter alignCenter" style={{ height: 120 }}>
                                        <h5 style={{ fontSize: 16, lineHeight: '140%', color: '#737373' }}>No session exists</h5>
                                        <p style={{ fontSize: 12, lineHeight: '150%', color: '#737373', textAlign: 'center', marginTop: 8 }}> You haven't created any training plan sessions.</p>
                                    </div>
                                </div>
                                : null}
                </div>
            </div>


            {popup_card['reject_plan_popup'] ?
                <div className="flex column justifyCenter alignCenter w-100vw h-100vh" style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'rgba(0,0,0,0.3)' }}>
                    <div className="flex column alignCenter justifyCenter"
                        style={{
                            background: '#ffffff',
                            height: 456,
                            width: 576,
                            borderRadius: 12,
                            padding: 32
                        }}
                    >
                        <h3>Are you sure?</h3>
                        <div style={{ margin: "16px 0 32px" }}>
                            <p style={{ margin: "0 auto", width: '70%', textAlign: 'center' }}>The training plan will be sent back to the coach to be edited.</p>
                        </div>
                        <div className="flex column alignStart justifyStart sidebar-settings-form-item w-100">
                            <label className="settings-label">Please leave your comment before submitting</label>
                            <textarea
                                id="rejected_comment"
                                type="text"
                                rows={5}
                                required
                                className="settings-value settings-background"
                            />
                        </div>
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "16px 0 0" }}>
                            <div className="white-button pointer"
                                onClick={() => showPopupCard({ ...popup_card, reject_plan_popup: false })}
                                style={{ maxWidth: 160 }}>
                                <p className="button_label">Cancel</p>
                            </div>
                            <div style={{ margin: "0 32px" }} />
                            <div className="white-button pointer" style={{ maxWidth: 222 }}
                                onClick={() => {
                                    if (document.getElementById("rejected_comment")['value'] === "") {
                                        dispatch(createNotification("error-toast", "Please leave your comment before submitting the plan"));
                                    } else {
                                        onRejectPlan(document.getElementById("rejected_comment")['value'])
                                    }
                                }}>
                                {popup_card['loading_state'] ?
                                    <div className="flex justifyCenter alignCenter">
                                        <div className="custom_button_loader" />
                                    </div>
                                    : <p className="button_label">Save and Submit</p>}
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            {popup_card['accept_plan_popup'] ?
                <div className="flex column justifyCenter alignCenter w-100vw h-100vh" style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'rgba(0,0,0,0.3)' }}>
                    <div className="flex column alignCenter justifyCenter"
                        style={{
                            background: '#ffffff',
                            height: 456,
                            width: 576,
                            borderRadius: 12,
                            padding: 32
                        }}
                    >
                        <h3>Are you sure?</h3>
                        <div style={{ margin: "16px 0 32px" }}>
                            <p style={{ margin: "0 auto", width: '70%', textAlign: 'center' }}>The training plan will be moved to the ready to publish queue.</p>
                        </div>
                        <div className="flex column alignStart justifyStart sidebar-settings-form-item w-100">
                            <label className="settings-label">Please leave your comment before submitting</label>
                            <textarea
                                id="accepted_comment"
                                type="text"
                                rows={5}
                                required
                                className="settings-value settings-background"
                            />
                        </div>
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "16px 0 0" }}>
                            <div className="white-button pointer"
                                onClick={() => showPopupCard({ ...popup_card, accept_plan_popup: false })}
                                style={{ maxWidth: 160 }}>
                                <p className="button_label">Cancel</p>
                            </div>
                            <div style={{ margin: "0 32px" }} />
                            <div className="white-button pointer" style={{ maxWidth: 222 }}
                                onClick={() => {
                                    if (document.getElementById("accepted_comment")['value'] === "") {
                                        dispatch(createNotification("error-toast", "Please leave your comment before submitting the plan"));
                                    } else {
                                        onAcceptPlan(document.getElementById("accepted_comment")['value'])
                                    }
                                }}>
                                {popup_card['loading_state'] ?
                                    <div className="flex justifyCenter alignCenter">
                                        <div className="custom_button_loader" />
                                    </div>
                                    : <p className="button_label">Save and Submit</p>}
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            {popup_card['publish_plan_popup'] ?
                <div className="flex column justifyCenter alignCenter w-100vw h-100vh" style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, background: 'rgba(0,0,0,0.3)' }}>
                    <div className="flex column alignCenter justifyCenter"
                        style={{
                            background: '#ffffff',
                            height: 220,
                            width: 576,
                            borderRadius: 12,
                            padding: 32
                        }}
                    >
                        <h3>Are you sure?</h3>
                        <div style={{ marginTop: 16 }}>
                            <p style={{ margin: "0 auto", width: '70%', textAlign: 'center' }}>The training plan will be published to the Wild App and be available for purchase.</p>
                        </div>
                        <div className="flex justifyCenter alignCenter w-100" style={{ margin: "32px 0 0" }}>
                            <div className="white-button pointer"
                                onClick={() => showPopupCard({ ...popup_card, publish_plan_popup: false })}
                                style={{ maxWidth: 160 }}>
                                <p className="button_label">Cancel</p>
                            </div>
                            <div style={{ margin: "0 32px" }} />
                            <div className="white-button pointer" style={{ maxWidth: 222 }} onClick={() => onPublishPlan()}  >
                                {popup_card['loading_state'] ?
                                    <div className="flex justifyCenter alignCenter">
                                        <div className="custom_button_loader" />
                                    </div>
                                    : <p className="button_label">Publish plan</p>}
                            </div>
                        </div>
                    </div>

                </div>
                : null}

        </div >
};

export default AdminPlanDetail;
