import React from "react";
import ArrowRight from "../../assets/icons/forward-black.svg";

const Breadcrumbs = ({ pagesNames, fontSize = "16px" }) => {
  return (
    <div className="flex alignCenter">
      {pagesNames.map((pageName, i) => {
        const isLastElement = i === pagesNames.length - 1;
        return (
          <>
            <p style={{ fontSize: fontSize }}>{pageName}</p>
            {!isLastElement && (
              <img
                src={ArrowRight}
                alt="arrow right"
                height={12}
                width={12}
                style={{ margin: "0 4px" }}
              />
            )}
          </>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
