import React from "react";
import CrossIcon from "../../assets/icons/window-close-light.svg";
import SearchIcon from "../../assets/icons/search-icon.svg";
import SearchIconDisbale from "../../assets/icons/search_icon.svg";

const AthletesSearch = ({
  setSearch,
  searchedData,
  dataSource,
  searchedText,
  setText,
  showCross,
  setCrossStatus,
  showLoader,
  setLoaderStatus,
  is_margin,
  customStyles,
  status,
}) => {
  // clear search input and hide cross icon
  const clearSearch = () => {
    setSearch(false);
    setCrossStatus(false);
    setText("");
    searchedData(dataSource);
  };

  const searchItem = (items, query) => {
    var result = items.filter((item) => {
      return Object.values(item).some(function (value) {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some(function (subValue) {
            return String(subValue).toLowerCase().includes(query.toLowerCase());
          });
        } else {
          return String(value).toLowerCase().includes(query.toLowerCase());
        }
      });
    });
    return result;
  };

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setLoaderStatus(true);
      setCrossStatus(false);
      const searchText = e.target.value;
      setSearch(true);
      setText(searchText);
      const data = dataSource;
      const rs = searchItem(data, searchText);
      setTimeout(() => {
        setLoaderStatus(false);
        searchedData(rs);
        setCrossStatus(true);
      }, 500);
    } else {
      clearSearch();
    }
  };

  return status ? (
    <div className="athletes-search-field">
      <input
        type="text"
        value={searchedText}
        onChange={handleChange}
        placeholder="Search..."
      />
      {showLoader ? (
        <div className="custom_search_loader" />
      ) : showCross ? (
        <img
          src={CrossIcon}
          alt="cross-icon"
          className="pointer absolute"
          height={22}
          width={22}
          onClick={clearSearch}
          style={{
            right: "16px",
            top: "16px",
            zIndex: 100,
          }}
        />
      ) : (
        <img
          src={SearchIcon}
          alt="search-icon"
          className="absolute"
          style={{ right: "16px", top: "18px" }}
          height={18}
          width={18}
        />
      )}
    </div>
  ) : (
    <div
      className="search-field"
      style={{ margin: is_margin ? " 0 1.6rem 0 1rem" : 0 }}
    >
      <input
        type="text"
        className="pointer_not_allowed"
        value={searchedText}
        disabled
        placeholder="Search..."
        style={{
          borderRadius: 100,
          border: "none",
          padding: "10px 48px 10px 24px",
          ...customStyles,
        }}
      />
      <img
        src={SearchIconDisbale}
        alt="search-icon"
        className="absolute"
        height={18}
        width={18}
        onClick={clearSearch}
        style={{
          right: 22,
          top: 2,
          opacity: 0.5,
          marginLeft: "0.75rem",
          marginTop: "0.75rem",
        }}
      />
    </div>
  );
};

export default AthletesSearch;
