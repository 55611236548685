export const FETCH_TRAINING_PLANS = "app/FETCH_TRAINING_PLANS";
export const FETCH_PLANS = "app/FETCH_PLANS";
export const SET_TRAINING_PLANS = "app/SET_TRAINING_PLANS";
export const FETCH_TRAINING_PLAN_SESSIONS = "app/FETCH_TRAINING_PLAN_SESSIONS";
export const SET_TRAINING_PLAN_SESSIONS = "app/SET_TRAINING_PLAN_SESSIONS";
export const FETCH_SPORT_TYPES = "app/FETCH_SPORT_TYPES";
export const SET_SPORT_TYPES = "app/SET_SPORT_TYPES";
export const FETCH_TRAINING_INTENSITIES = "app/FETCH_TRAINING_INTENSITIES";
export const SET_TRAINING_INTENSITIES = "app/SET_TRAINING_INTENSITIES";
export const FETCH_TRAINING_LEVELS = "app/FETCH_TRAINING_LEVELS";
export const SET_TRAINING_LEVELS = "app/SET_TRAINING_LEVELS";
export const EDIT_TRAINING_PLAN = "app/EDIT_TRAINING_PLAN";
export const SET_TRAINING_PLAN = "app/SET_TRAINING_PLAN";
export const EDIT_TRAINING_PLAN_SESSION = "app/EDIT_TRAINING_PLAN_SESSION";
export const SET_TRAINING_PLAN_SESSION = "app/SET_TRAINING_PLAN_SESSION";
export const SUBMIT_TRAINING_PLAN = "app/SUBMIT_TRAINING_PLAN";
export const SUBMIT_TRAINING_PLAN_SESSION = "app/SUBMIT_TRAINING_PLAN_SESSION";
export const UPDATE_TRAINING_PLAN = "app/UPDATE_TRAINING_PLAN";
export const UPDATE_TRAINING_PLAN_SESSION = "app/UPDATE_TRAINING_PLAN_SESSION";
export const UPDATE_TRAINING_PLAN_SESSION_WITH_POST = "app/UPDATE_TRAINING_PLAN_SESSION_WITH_POST";
export const UPDATE_TRAINING_PLAN_WITH_POST = "app/UPDATE_TRAINING_PLAN_WITH_POST";
export const UPDATE_TRAINING_PLAN_SESSIONS = "app/UPDATE_TRAINING_PLAN_SESSIONS";
export const DELETE_TRAINING_PLAN = "app/DELETE_TRAINING_PLAN";
export const DELETE_TRAINING_PLAN_SESSIONS = "app/DELETE_TRAINING_PLAN_SESSIONS";
export const DELETE_TRAINING_PLAN_SESSION = "app/DELETE_TRAINING_PLAN_SESSION";
export const SAVE_SESSIONS_COUNT = "app/SAVE_SESSIONS_COUNT";
export const SUBMIT_TRAINING_PLAN_FOR_SPREADSHEET = "app/SUBMIT_TRAINING_PLAN_FOR_SPREADSHEET";
export const SUBMIT_TRAINING_PLAN_SESSION_FOR_SPREADSHEET = "app/SUBMIT_TRAINING_PLAN_SESSION_FOR_SPREADSHEET";
export const FETCH_SESSION_SPORT_TYPES = "app/FETCH_SESSION_SPORT_TYPES";
export const SET_SESSION_SPORT_TYPES = "app/SET_SESSION_SPORT_TYPES";
export const SET_UPLOAD_FAILED_ERROR_MESSAGE = "app/SET_UPLOAD_FAILED_ERROR_MESSAGE";
export const SET_UPLOAD_FAILED_PLAN_ID = "app/SET_UPLOAD_FAILED_PLAN_ID";
export const ON_FAILED_DELETE_TRAINING_PLAN = "app/ON_FAILED_DELETE_TRAINING_PLAN";
export const UPDATE_PLAN_TAGS = "app/UPDATE_PLAN_TAGS";

// admin endpoints
export const FETCH_REVIEW_PLANS = "app/FETCH_REVIEW_PLANS";
export const FETCH_REJECTED_PLANS = "app/FETCH_REJECTED_PLANS";
export const FETCH_PUBLISH_PLANS = "app/FETCH_PUBLISH_PLANS";
export const FETCH_PUBLISHED_PLANS = "app/FETCH_PUBLISHED_PLANS";
export const SET_ADMIN_PLAN = "SET_ADMIN_PLAN";
export const GET_ADMIN_TRAINING_PLAN = "app/GET_ADMIN_TRAINING_PLAN";
export const GET_ADMIN_TRAINING_SESSIONS = "app/GET_ADMIN_TRAINING_SESSIONS";
export const GET_ADMIN_TRAINING_SESSION = "app/GET_ADMIN_TRAINING_SESSION";
export const SEND_REJECT_PLAN_CALL = "app/SEND_REJECT_PLAN_CALL";
export const SEND_ACCEPT_PLAN_CALL = "app/SEND_ACCEPT_PLAN_CALL";
export const SEND_PUBLISH_PLAN_CALL = "app/SEND_PUBLISH_PLAN_CALL";


export const fetchTrainingPlans = (onSuccess, onError) => ({
  type: FETCH_TRAINING_PLANS,
  payload: { onSuccess, onError }
});

export const fetchPlans = (onError) => ({
  type: FETCH_PLANS,
  payload: { onError }
});

export const setTrainingPlans = (payload) => ({
  type: SET_TRAINING_PLANS,
  payload
});

export const fetchTrainingPlanSessions = (id, onSuccess, onError) => ({
  type: FETCH_TRAINING_PLAN_SESSIONS,
  payload: { id, onSuccess, onError }
});

export const setTrainingPlanSessions = (payload) => ({
  type: SET_TRAINING_PLAN_SESSIONS,
  payload
});

export const fetchSportTypes = (onError) => ({
  type: FETCH_SPORT_TYPES,
  payload: { onError }
});

export const setSportTypes = (payload) => ({
  type: SET_SPORT_TYPES,
  payload
});

export const fetchSessionSportTypes = (onError) => ({
  type: FETCH_SESSION_SPORT_TYPES,
  payload: { onError }
});

export const setSessionSportTypes = (payload) => ({
  type: SET_SESSION_SPORT_TYPES,
  payload
});

export const fetchTrainingIntensities = (onError) => ({
  type: FETCH_TRAINING_INTENSITIES,
  payload: { onError }
});

export const setTrainingIntensities = (payload) => ({
  type: SET_TRAINING_INTENSITIES,
  payload
});

export const fetchTrainingLevels = (onError) => ({
  type: FETCH_TRAINING_LEVELS,
  payload: { onError }
});

export const setTrainingLevels = (payload) => ({
  type: SET_TRAINING_LEVELS,
  payload
});

export const editTrainingPlan = (id, onError) => ({
  type: EDIT_TRAINING_PLAN,
  payload: { id, onError }
});


export const setTrainingPlan = (payload) => ({
  type: SET_TRAINING_PLAN,
  payload
});

export const editTrainingPlanSession = (plan_pk, id, onError) => ({
  type: EDIT_TRAINING_PLAN_SESSION,
  payload: { plan_pk, id, onError }
});

export const setTrainingPlanSession = (payload) => ({
  type: SET_TRAINING_PLAN_SESSION,
  payload
});

export const createPlan = (values, onSuccess, onError) => ({
  type: SUBMIT_TRAINING_PLAN,
  payload: { values, onSuccess, onError }
});

export const createPlanSession = (id, values, onSuccess, onError) => ({
  type: SUBMIT_TRAINING_PLAN_SESSION,
  payload: { id, values, onSuccess, onError }
});

export const createPlanSpreadsheet = (values, onSuccess, onError) => ({
  type: SUBMIT_TRAINING_PLAN_FOR_SPREADSHEET,
  payload: { values, onSuccess, onError }
});

export const createPlanSessionSpreadsheet = (id, values, onSuccess, onError, index) => ({
  type: SUBMIT_TRAINING_PLAN_SESSION_FOR_SPREADSHEET,
  payload: { id, values, onSuccess, onError, index }
});

export const updatePlan = (id, values, onSuccess, onError) => ({
  type: UPDATE_TRAINING_PLAN,
  payload: { id, values, onSuccess, onError }
});

export const updatePlanSession = (plan_id, session_id, values, onSuccess, onError) => ({
  type: UPDATE_TRAINING_PLAN_SESSION,
  payload: { plan_id, session_id, values, onSuccess, onError }
});

export const updatePlanWithPOST = (id, values, onSuccess, onError) => ({
  type: UPDATE_TRAINING_PLAN_WITH_POST,
  payload: { id, values, onSuccess, onError }
});

export const updatePlanSessionWithPOST = (plan_id, id, values, onSuccess, onError) => ({
  type: UPDATE_TRAINING_PLAN_SESSION_WITH_POST,
  payload: { plan_id, id, values, onSuccess, onError }
});

export const updatePlanSessions = (id, onSuccess, onError) => ({
  type: UPDATE_TRAINING_PLAN_SESSIONS,
  payload: { id, onSuccess, onError },
});

export const deletePlan = (id, onSuccess, onError) => ({
  type: DELETE_TRAINING_PLAN,
  payload: { id, onSuccess, onError },
});

export const deletePlanSessions = (id, onSuccess, onError) => ({
  type: DELETE_TRAINING_PLAN_SESSIONS,
  payload: { id, onSuccess, onError },
});

export const deletePlanSession = (plan_id, id, onSuccess, onError) => ({
  type: DELETE_TRAINING_PLAN_SESSION,
  payload: { plan_id, id, onSuccess, onError },
});


export const setErrorMessage = (payload) => ({
  type: SET_UPLOAD_FAILED_ERROR_MESSAGE,
  payload
});

export const setPlanId = (payload) => ({
  type: SET_UPLOAD_FAILED_PLAN_ID,
  payload
});

export const onFailedDeletePlan = (id, onError, onSuccess) => ({
  type: ON_FAILED_DELETE_TRAINING_PLAN,
  payload: { id, onError, onSuccess },
});

export const fetchAdminReviewPlans = (onSuccess, onError) => ({
  type: FETCH_REVIEW_PLANS,
  payload: { onSuccess, onError }
});

export const fetchAdminRejectPlans = (onSuccess, onError) => ({
  type: FETCH_REJECTED_PLANS,
  payload: { onSuccess, onError }
});

export const fetchAdminPublishPlans = (onSuccess, onError) => ({
  type: FETCH_PUBLISH_PLANS,
  payload: { onSuccess, onError }
});

export const fetchAdminPublishedPlans = (onSuccess, onError) => ({
  type: FETCH_PUBLISHED_PLANS,
  payload: { onSuccess, onError }
});

export const setAdminPlans = (payload) => ({
  type: SET_ADMIN_PLAN,
  payload
});

export const rejectPlan = (id, values, onSuccess, onError) => ({
  type: SEND_REJECT_PLAN_CALL,
  payload: { id, values, onSuccess, onError },
});

export const acceptPlan = (id, values, onSuccess, onError) => ({
  type: SEND_ACCEPT_PLAN_CALL,
  payload: { id, values, onSuccess, onError },
});

export const publishPlan = (id, onSuccess, onError) => ({
  type: SEND_PUBLISH_PLAN_CALL,
  payload: { id, onSuccess, onError },
});

export const updatePlanTags = (id, values, onSuccess, onError) => ({
  type: UPDATE_PLAN_TAGS,
  payload: { id, values, onSuccess, onError },
});


export const getAdminTrainingPlan = (id, onError) => ({
  type: GET_ADMIN_TRAINING_PLAN,
  payload: { id, onError }
});

export const getAdminTrainingPlanSessions = (id, onSuccess, onError) => ({
  type: GET_ADMIN_TRAINING_SESSIONS,
  payload: { id, onSuccess, onError }
});
