import React, { useState } from "react";
import BinIcon from "../../assets/icons/black-cross.svg";
import "./TagInput.css";

const TagInput = ({ tags, setTags }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter tags"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyPress}
      />
      <div className="flex" style={{ flexWrap: "wrap", marginTop: "5px" }}>
        {tags.map((tag, index) => (
          <span key={index} className="tag">
            {tag}
            <img
              onClick={() => handleRemoveTag(tag)}
              src={BinIcon}
              style={{ cursor: "pointer", marginLeft: "10px" }}
              alt="plus-icon"
              height={9}
              width={9}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagInput;
