import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// Icons + images 
import ArrowRight from "../../assets/icons/forward.svg";

// Actions
import { getErrorMessage } from "../../_helpers/Requests";
import { chunk } from "../../_helpers/chunks";
import { history } from "../../index";
import api from "../../services/api/ApiService";
import { createNotification } from "../../store/app/actions";


const AthletesList = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { location: { search } } = history;
    const teamId = Number(search.replace("?team=", ""));
    const [athletes, setAthletes] = useState([]);
    // on Error
    const onError = (error) => dispatch(createNotification("error-toast", error));


    async function getTeamAthletes(team_id) {
        try {
            const response = await api.get(`coach/team/${team_id}/blocks/overview`);
            if (response['status'] === 200) {
                if (response['data']) {
                    if (response['data']['overview'].length > 0) {
                        setLoading(false);
                        setAthletes(chunk(response['data']['overview'], 4));
                    }else{
                        setLoading(false);
                        dispatch(createNotification("success-toast", 'No athletes found in your selected team!'));
                    }
                }
            } else { getErrorMessage(response); setLoading(false); }
        } catch (error) { onError(error.message); setLoading(false); }

    }

    useEffect(() => { setLoading(true); if (teamId) { getTeamAthletes(teamId) } }, [teamId]);


    return loading ?
        <div className="flex column justifyCenter alignCenter w-100 h-100vh">
            <div className="custom_loader" />
            <p style={{ marginTop: 32 }}>
                {`Please wait a moment`}
            </p>
        </div> :
        <div className="flex column w-100 h-100" style={{ margin: '40px 40px 0', width: 'calc(100vw - 240px)', maxHeight: '100vh' }}>
            {/* Breadcrumb */}
            <div className="flex alignCenter justifyBetween w-100">
                <p>Athletes</p>
                <div className="flex justifyEnd alignCenter">
                    <div className="flex alignCenter pointer" onClick={() => history.push('/dashboard/calendar/teams')} >
                        <p>Teams</p>
                    </div>
                    <div className="flex alignCenter pointer" onClick={() => history.push(`/dashboard/calendar/athletes?team=${teamId}`)} >
                        <img src={ArrowRight} alt="arrow right" height={12} width={12} style={{ margin: '0 8px' }} />
                        <p>Athletes</p>
                    </div>
                </div>
            </div>

            {athletes.length > 0 &&
                <div className="flex column justifyCenter alignCenter w-100 h-100" style={{ marginTop: 40, marginBottom: 80 }}>
                    {athletes.map((item, index) =>
                        <div className="flex alignCenter w-100" key={index + 1}>
                            {item.length > 0 && item.map((athleteObj, idx) =>
                                <div key={idx + 1} className="flex column alignCenter justifyCenter w-100 pointer" style={{ width: 270, height: 150, background: 'rgba(255, 255, 255, 0.15)', margin: 8, borderRadius: 12, boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 10px' }}
                                    onClick={() => { window.localStorage.setItem("is_calendar", true); window.localStorage.setItem("team_Id", teamId); history.push(`/dashboard/calendar?id=${athleteObj.athlete.pk}`); }}>
                                    <p style={{ fontSize: 20 }}>{`${athleteObj['athlete']['first_name']} ${athleteObj['athlete']['last_name']}`}</p>
                                    <p style={{ margin: '8px 0 0', fontSize: 16 }}>{athleteObj.cycle.cycle_stage}</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>}
        </div>
};

export default AthletesList;
