import React, { useState } from "react";
// Providers
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { createNotification } from "../../store/app/actions";
// Logo
import WildLogo from "../../assets/logo-light.png";
// Icons
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import EyeOffIcon from "../../assets/icons/eye-off.svg";
import EyeIcon from "../../assets/icons/eye.svg";
import GoogleIcon from "../../assets/icons/google.svg";
// Components
import ForgetPaswoord from "../../components/ForgetPaswoord";
import Popup403 from "../../components/Popup403";
import ResendVerificationPopup from "../../components/ResendVerificationPopup";
import ErrorDialog from "../../components/common/ErrorDialog";
// Actions
import { validateEmail } from "../../_helpers/validation";
import api from "../../services/api/ApiService";
import { login, loginGoogle } from "../../store/app/actions";

const SignIn = () => {
  const dispatch = useDispatch();
  // States
  const [verified_captcha, set_verified_captcha] = useState(false);
  const [show_resend, setShowResend] = useState(false);
  const [popup403, setShowPopup403] = useState(false);
  const [visible, setVisible] = useState(false);
  const [login_values, setLoginValues] = useState({
    username: "",
    password: "",
    show_password: false,
  });

  const [submitting, setSubmitting] = useState({
    login_submit: false,
    forget_submit: false,
  });

  // Error handlers
  let [error_toast, setErrorToast] = useState({
    type: "",
    status: false,
    message: "",
  });
  const [submittingAppleAuth, setSubmittingAppleAuth] = useState(false);
  const [submittingGoogleAuth, setSubmittingGoogleAuth] = useState(false);

  // Form submitting actions
  // on resend popup
  const onShowResendPopup = () => {
    setSubmitting({ ...submitting, login_submit: false });
    setShowResend(true);
  };
  // on subscription popup
  const showPpup403Message = () => {
    setSubmitting({ ...submitting, login_submit: false });
    setShowPopup403(true);
  };
  // on failure
  const onFinishFailed = (error) => {
    setSubmitting({ ...submitting, login_submit: false, forget_submit: false });
    setErrorToast({
      type: "error-toast",
      status: true,
      message: error,
    });
  };
  // on success
  const onFinishSuccess = () => {
    setSubmitting({ ...submitting, login_submit: false, forget_submit: false });
    setLoginValues({
      username: "",
      password: "",
      show_password: false,
    });
  };
  // login submit
  const onFinishLogin = async () => {
    if (verified_captcha) {
      if (login_values["username"] === "" && login_values["password"] === "") {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message: "Please enter email address and password to continue.",
        });
      } else if (!validateEmail(login_values["username"])) {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message: "Please enter correct email address to continue.",
        });
      } else if (login_values["password"].length < 6) {
        setErrorToast({
          ...error_toast,
          type: "error-toast",
          status: true,
          message: "Please enter password of minimum 6 characters to continue.",
        });
      } else {
        setSubmitting({ ...submitting, login_submit: true });
        dispatch(
          login(
            login_values,
            onFinishSuccess,
            onFinishFailed,
            onShowResendPopup,
            showPpup403Message
          )
        );
      }
    }
  };

  const onFinishForgetFailed = (error) => {
    setVisible(false);
    setErrorToast({
      type: "error-toast",
      status: true,
      message: error,
    });
  };

  const onFinishForgetSuccess = (email) => {
    setVisible(false);
    setErrorToast({
      type: "success-toast",
      status: true,
      message: `An email with instructions to reset your password has been sent to ${email}`,
    });
  };

  const onFinishResendFail = (error) => {
    setErrorToast({
      type: "error-toast",
      status: true,
      message: error,
    });
  };

  const onFinishResendSuccess = () => {
    setErrorToast({
      type: "success-toast",
      status: true,
      message: `Email sent. Please check your inbox, including junk folders!`,
    });
  };

  // Actions
  const handleVerify = async (token) => {
    if (token !== null && token !== undefined) {
      if (!verified_captcha) {
        const response = await api.post(`/api/captcha_request`, {
          recaptcha: token,
          version: "v3",
        });
        if (response.status === 200) {
          set_verified_captcha(response.data.success);
        }
      }
    }
  };

  const onAuthFailed = (error) => {
    dispatch(createNotification("error-toast", error));
    setSubmittingAppleAuth(false);
    setSubmittingGoogleAuth(false);
  };

  const onAuthSuccess = () => {
    setSubmittingAppleAuth(false);
    setSubmittingGoogleAuth(false);
  };

  const onGoogleError = (error) =>
    dispatch(createNotification("error-toast", error));

  const onGoogleSuccess = async (response) => {
    setSubmittingGoogleAuth(true);
    const { access_token } = response;
    const values = {
      access_token,
      // id_token: 'abcdefghijklmnopqrstuvwxyz0123456789'
    };
    dispatch(loginGoogle(values, onAuthSuccess, onAuthFailed));
  };

  const GoogleButton = ({ onSuccess, onError }) => {
    const login = useGoogleLogin({
      flow: "implicit",
      ux_mode: "popup",
      onSuccess,
      onError,
      onNonOAuthError: onError,
      scope: [
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/userinfo.email",
      ].join(" "),
    });

    const handleClick = () => login();

    return !verified_captcha ? (
      <div className="flex alignCenter justifyCenter login_round_box disabled_light_bg">
        <img src={GoogleIcon} alt="google_icon" height={28} width={28} />
        <p style={{ cursor: "not-allowed" }}>Sign in with Google</p>
      </div>
    ) : (
      <div
        onClick={handleClick}
        className="flex alignCenter justifyCenter login_round_box light_bg"
      >
        {submittingGoogleAuth ? (
          <div className="login_card_loader" />
        ) : (
          <img src={GoogleIcon} alt="google_icon" height={28} width={28} />
        )}
        <p>{submittingGoogleAuth ? "Processing ..." : "Sign in with Google"}</p>
      </div>
    );
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <GoogleReCaptcha onVerify={handleVerify} />
      <div className="flex w-100vw h-100">
        <div
          className="flex column justifyCenter alignCenter form_container relative"
          style={{ padding: "56px 0" }}
        >
          {!verified_captcha && (
            <div
              className="flex column justifyCenter alignCenter h-100 w-100"
              style={{
                zIndex: 100,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%",
              }}
            >
              <div className="custom_placeholder_loader" />
            </div>
          )}

          <a
            href={process.env.REACT_APP_WEBSITE_URL}
            className="flex alignCenter justifyStart w-100 absolute"
            style={{ textDecoration: "none", top: "4%", left: "4%" }}
          >
            <img
              className="pointer"
              src={ArrowLeft}
              alt="arrow-left"
              height={20}
              width={20}
              style={{ marginRight: 16 }}
            />
            <p style={{ color: "rgb(255, 255, 255)" }}>
              Back to Wild’s homepage
            </p>
          </a>

          <img src={WildLogo} alt="wild.ai_logo" width={107} height={124} />
          <h2 className="flex alignCenter label">Coach Login</h2>

          {/* Login Form */}
          <div
            className="input_form"
            style={{ opacity: !verified_captcha ? 0.2 : 1 }}
          >
            <div className="settings-form-item border-bottom">
              <input
                type="email"
                autoFocus={true}
                placeholder="Email"
                className="settings-value"
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    setLoginValues({
                      ...login_values,
                      username: event.target.value.trim(),
                    });
                    onFinishLogin();
                  }
                }}
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    setLoginValues({
                      ...login_values,
                      username: event.target.value.trim(),
                    });
                  } else {
                    setLoginValues({ ...login_values, username: "" });
                  }
                }}
              />
            </div>
            <div className="settings-form-item border-bottom">
              <input
                type={login_values["show_password"] ? "text" : "password"}
                placeholder="Password"
                className="settings-value password"
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    setLoginValues({
                      ...login_values,
                      password: event.target.value.trim(),
                    });
                    onFinishLogin();
                  }
                }}
                onChange={(event) => {
                  if (event.target.value.length > 0) {
                    setLoginValues({
                      ...login_values,
                      password: event.target.value.trim(),
                    });
                  } else {
                    setLoginValues({ ...login_values, password: "" });
                  }
                }}
              />
              {login_values["show_password"] ? (
                <img
                  className="absolute pointer"
                  src={EyeIcon}
                  alt="eye_icon"
                  height={18}
                  width={18}
                  onClick={() =>
                    setLoginValues({
                      ...login_values,
                      show_password: !login_values["show_password"],
                    })
                  }
                  style={{ right: 0, bottom: 12 }}
                />
              ) : (
                <img
                  className="absolute pointer"
                  src={EyeOffIcon}
                  alt="eye_icon"
                  height={18}
                  width={18}
                  onClick={() =>
                    setLoginValues({
                      ...login_values,
                      show_password: !login_values["show_password"],
                    })
                  }
                  style={{ right: 0, bottom: 12 }}
                />
              )}
            </div>

            <p
              onClick={() => setVisible(true)}
              style={{
                marginLeft: "5rem",
                marginTop: "4rem",
                color: "rgb(255, 255, 255)",
                fontSize: 15,
                lineHeight: "150%",
                margin: "20px 0 24px",
                fontWeight: 400,
                cursor: "pointer",
              }}
            >
              Forgot password?
            </p>

            <div onClick={() => onFinishLogin()} className="white-button">
              {submitting["login_submit"] ? (
                <div className="flex justifyCenter alignCenter">
                  <div className="custom_button_loader" />
                </div>
              ) : (
                <p
                  style={{
                    margin: 0,
                    color: "#ffffff",
                    fontSize: 12,
                    maxWidth: 320,
                    textTransform: "uppercase",
                    lineHeight: "120%",
                    cursor: "pointer",
                  }}
                >
                  Sign In
                </p>
              )}
            </div>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              onScriptLoadError={onGoogleError}
            >
              <GoogleButton
                onSuccess={onGoogleSuccess}
                onError={onGoogleError}
              />
            </GoogleOAuthProvider>
            <div
              className="flex column w-100"
              style={{
                maxWidth: 336,
                margin: "32px 0 0",
                textAlign: "center",
              }}
            >
              <p className="sign-link">Don’t have an account yet?</p>
              <p
                className="pointer"
                onClick={() => (window.location.href = "/signup")}
                style={{ textDecoration: "underline" }}
              >
                Register now!
              </p>
            </div>

            <div
              className="flex justifyCenter alignCenter w-100"
              style={{ maxWidth: 336, margin: "32px 0 0", textAlign: "center" }}
            >
              <p className="small-decription">
                <span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit" }}
                    href="https://wild.ai/terms-of-services"
                  >
                    Terms of service
                  </a>
                </span>
                {` | `}
                <span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "inherit" }}
                    href="https://wild.ai/privacy-policy"
                  >
                    Privacy policy
                  </a>
                </span>
              </p>
            </div>
          </div>

          {/* Error Dialog */}
          <ErrorDialog
            open={error_toast["status"]}
            type={error_toast["type"]}
            message={error_toast["message"]}
            onClose={() =>
              setErrorToast({
                type: "",
                status: false,
                message: "",
              })
            }
          />
        </div>

        <div className="flex column justifyCenter alignCenter w-100 desktop">
          <img
            src="https://wild-blog-strapi.s3.amazonaws.com/image_72_e4e5bd6e8c.jpg?610431.8000000007"
            alt="wild.ai_poster"
            style={{
              width: "50vw",
              height: "100%",
              minHeight: "100vh",
              objectFit: "cover",
            }}
          />
        </div>

        <ForgetPaswoord
          show={visible}
          onCancel={() => setVisible(false)}
          onError={(message) => onFinishForgetFailed(message)}
          onSuccess={(message) => onFinishForgetSuccess(message)}
        />

        <ResendVerificationPopup
          show={show_resend}
          onCancel={() => setShowResend(false)}
          onError={(message) => onFinishResendFail(message)}
          onSuccess={() => onFinishResendSuccess()}
        />

        <Popup403 show={popup403} onCancel={() => setShowPopup403(false)} />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default SignIn;
