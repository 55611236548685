import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Icons
import CurlPointingArrowLeft from "../../assets/icons/CurlPointingArrowLeft.svg";
import GreenArrowRight from "../../assets/icons/GreenArrowRight.svg";
import Runner from "../../assets/icons/Runner.svg";
import GreenCrossIcon from "../../assets/icons/cross-green.svg";
import ArrowRight from "../../assets/icons/forward-black.svg";
// Components
import InviteAthletes from "../../components/InviteAthletes";
// Actions
import { history } from "../../index";
import api from "../../services/api/ApiService";
import { createNotification } from "../../store/app/actions";

const AddAthletes = () => {
  const { user } = useSelector((state) => state.app);
  const [team_list, setTeamList] = useState([]);
  const [show_form, setShowForm] = useState(true);
  const [inviteForm, setInviteForm] = useState(false);
  const dispatch = useDispatch();
  const onFailure = (error) =>
    dispatch(createNotification("error-toast", error));

  useEffect(() => {
    api
      .get(`coach/blocks/teams`)
      .then((result) => {
        if (result.status === 200) {
          const { teams } = result.data;
          if (teams && teams.length > 0) {
            setTeamList(teams);
            setInviteForm(true);
          } else {
            onFailure("Please Create a Team First!");
            history.push("/onboarding/createTeam");
          }
        } else {
          onFailure("Something went wrong, please contact to support@wild.ai");
        }
      })
      .catch((error) => {
        onFailure(error.message);
      });
  }, []);

  return (
    <div className="flex column w-100 h-100">
      {/* Header */}
      <div
        className="flex justifyBetween alignCenter"
        style={{
          position: "fixed",
          top: 0,
          zIndex: 10,
          margin: "40px 40px 0",
          width: "calc(100vw - 240px)",
        }}
      >
        <div className="team_selector">
          <div
            className="flex column justifyBetween alignStart"
            style={{ minWidth: 224, marginTop: "96px" }}
          >
            <p style={{ cursor: "default", fontSize: "32px", fontWeight: 500 }}>
              Welcome{" "}
              {team_list.length ? team_list[0].primary_coach.first_name : ""}!
            </p>
            <p
              style={{
                cursor: "default",
                fontSize: "16px",
                fontWeight: "normal",
              }}
            >
              Let's add some athletes to your team!
            </p>
          </div>
        </div>

        {/* Breadcrumb */}
        <div className="flex alignCenter">
          <p>Home</p>
          <img
            src={ArrowRight}
            alt="arrow right"
            height={12}
            width={12}
            style={{ margin: "0 4px" }}
          />
          <p>Teams</p>
          <img
            src={ArrowRight}
            alt="arrow right"
            height={12}
            width={12}
            style={{ margin: "0 4px" }}
          />
          <p>{team_list && team_list.length > 0 && team_list[0].name}</p>
        </div>
      </div>

      {/* Add Athletes */}

      {!inviteForm ? (
        <div
          className="flex column alignCenter justifyCenter relative"
          style={{ minHeight: "100vh" }}
        >
          {/* {show_form && (
            <div className="absolute" style={{ top: 132, left: 96 }}>
              <div
                className="flex column relative"
                style={{
                  width: 348,
                  background: "#08AFA8",
                  border: "1px solid #01213A",
                  borderRadius: 12,
                  padding: 16,
                }}
              >
                <img
                  className="absolute"
                  src={Runner}
                  alt="runner"
                  height={116}
                  width={149}
                  style={{ top: -90, right: 8 }}
                />
                <div className="flex alignCenter justifyBetween w-100">
                  <h3 style={{ color: "#01213A", fontSize: 18 }}>Congrats!</h3>
                  <img
                    className="pointer"
                    src={GreenCrossIcon}
                    alt="cross icon"
                    height={16}
                    width={16}
                    onClick={() => setShowForm(false)}
                  />
                </div>
                <h3 style={{ color: "#01213A", fontSize: 18, marginTop: 12 }}>
                  You have created your first team
                </h3>
                <p style={{ color: "#01213A", marginTop: 16 }}>
                  Let’s add some athletes to your team. You can always create
                  more teams from your Dashboard.
                </p>
                <div
                  className="flex alignCenter justifyEnd w-100"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowForm(!show_form)}
                >
                  <p
                    style={{
                      color: "#01213A",
                      textTransform: "uppercase",
                      fontWeight: 600,
                    }}
                  >
                    Add Athletes
                  </p>
                  <img
                    src={GreenArrowRight}
                    alt="cross icon"
                    height={20}
                    width={20}
                    style={{ marginLeft: 12 }}
                  />
                </div>
                <img
                  src={CurlPointingArrowLeft}
                  alt="curl pointing arrow"
                  height={60.13}
                  width={60.13}
                  style={{ position: "absolute", bottom: "-20%", left: "-20%" }}
                />
              </div>
            </div>
          )} */}
          <h1 style={{ color: "inherit" }}>Hey {user["coach"].first_name}!</h1>
          <p style={{ margin: "24px auto", width: 380, textAlign: "center" }}>
            Looking pretty empty over here! <br /> Let’s add some athletes to
            your dashboard.
          </p>
          <div
            className="white-button"
            style={{ maxWidth: 220 }}
            onClick={() => setInviteForm(true)}
          >
            <p>Add Athletes</p>
          </div>
        </div>
      ) : (
        <div
          className="settings-main"
          style={{ maxWidth: 520, height: "100vh", margin: "0 auto" }}
        >
          <h3
            style={{
              fontSize: 32,
              lineHeight: "120%",
              color: "inherit",
              textAlign: "center",
            }}
          >
            Invite Athletes to Team {team_list.length ? team_list[0].name : ""}
          </h3>
          <div
            className="flex column alignCenter justifyCenter w-100"
            style={{ margin: "32px auto 0" }}
          >
            <InviteAthletes teamId={team_list[0].pk} />
          </div>
        </div>
      )}
    </div>
  );
};
export default AddAthletes;
