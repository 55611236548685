import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, createNotification } from "../../store/app/actions";

const ResetPassword = () => {
  const { user } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const onError = async (error) => {
    setSubmitting(false);
    dispatch(createNotification("error-toast", error, true));
  };

  const onSuccess = async () => setSubmitting(false);

  const onResetPassword = async () => {
    setSubmitting(true);
    const { coach: { email } } = user;
    if (email !== "") {
      dispatch(forgotPassword(email, onSuccess, onError));
    }
  };

  return (
    <div className="settings-main">
      <h1 style={{color: '#ffffff'}}> Reset password </h1>
      <p style={{
        color: "#ffffff",
        lineHeight: "180%",
        margin: "20px 0",
      }}
      >
        You can reset your password if you forgot it. We gonna send a reset
        link to your email address: <strong>{user["coach"].email}</strong>
      </p>

      {/* Submit button */}
      <div className="white-button" style={{ maxWidth: 270 }} onClick={() => onResetPassword()}>
        {submitting ? (
          <div className="flex justifyCenter alignCenter">
            <div className="custom_button_loader" />
          </div>
        ) : (
          <p className="button_label">RESET PASSWORD</p>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
