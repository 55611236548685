import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
// Icons
import Placeholder from "../assets/icons/placeholder.svg";
import WildLogo from "../assets/logo-light.png";
// Enabled Icons
import RunIcon from "../assets/icons/Run.svg";
import PresentationLineIcon from "../assets/icons/presentation-line.svg";
import CheckBoxListIcon from "../assets/icons/checkbox-list-line.svg";
import CalendarIcon from "../assets/icons/calendar-line.svg";
// Active Icons
import RunActiveIcon from "../assets/icons/RunActive.svg";
import PresentationLineActiveIcon from "../assets/icons/presentation-active-icon.svg";
import CheckBoxListActiveIcon from "../assets/icons/checkbox-list-line-active.svg";
import CalendarActiveIcon from "../assets/icons/calendar-line-active.svg";
// Disabled Icons
import RunDisableIcon from "../assets/icons/RunDisable.svg";
import PresentationLineDisableIcon from "../assets/icons/presentation-line-disable.svg";
import CheckBoxListDisableIcon from "../assets/icons/checkbox-list-line-disable.svg";
import CalendarDisableIcon from "../assets/icons/calendar-line-disable.svg";
// ....Actions
import { history } from "../index";
import { logout } from "../store/app/actions";

export const StyledLink = styled(NavLink)`
  width: 100%;
  text-decoration: none !important;
  cursor: pointer !important;
  font-weight: normal;
  &:hover {
    width: 100%;
    text-decoration: none !important;
    cursor: pointer !important;
  }
  &.active {
    width: 100%;
    text-decoration: none !important;
  }
`;

const Sidebar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.app);
  const { settings } = useSelector((state) => state.settings);
  const {
    location: { pathname },
  } = history;
  const [onBoarding, setOnboarding] = useState(false);
  const [profile_image, set_profile_image] = useState(null);
  // const [show_plan_button, set_show_plan_button] = useState(false);
  // const [permission_status, set_permission_status] = useState(false);

  useEffect(() => {
    if (settings) {
      if (
        settings.profile_pic["profile_img_url"] ===
        "https://staging.api.wild.ai/static/default_profile_pic.png"
      ) {
        set_profile_image(Placeholder);
      } else {
        set_profile_image(settings.profile_pic["profile_img_url"]);
      }
    }

    if (user) {
      const {
        coach: {
          // can_create_plans, is_admin,
          profile_pic_url,
        },
        onboarding_complete,
      } = user;
      set_profile_image(profile_pic_url);
      // set_permission_status(is_admin);
      // set_show_plan_button(can_create_plans);
      setOnboarding(onboarding_complete);
    }
  }, [settings, user]);

  return user ? (
    <div className="sidebar">
      <div className="logo-with-team-name">
        <img src={WildLogo} alt="wildai_logo" height={62} width={54} />
        <h3
          className="nav-link"
          style={{ color: "#FFFFFF", margin: "16px 0 0" }}
        >
          COACH
        </h3>
      </div>
      <div className="menus-container">
        {/* Athletes */}
        <StyledLink
          to="/dashboard/athletes"
          className="menu-item"
          style={{ pointerEvents: user.onboarding_complete ? "" : "none" }}
          onClick={() => {
            window.localStorage.setItem(
              "location",
              JSON.stringify({
                management_tab: 0,
              })
            );
            window.localStorage.setItem("open_week_tab", 0);
            window.localStorage.setItem("is_calendar", false);
          }}
          isActive={() =>
            [
              "/dashboard/athletes",
              "/dashboard/athlete",
              "/dashboard/alerts",
            ].includes(pathname)
          }
        >
          <div className="flex column alignCenter justifyCenter">
            {[
              "/dashboard/athletes",
              "/dashboard/athlete",
              "/dashboard/alerts",
            ].includes(pathname) ? (
              <img src={RunActiveIcon} alt="athletes" height={32} width={32} />
            ) : (
              <img src={RunIcon} alt="athletes" height={32} width={32} />
            )}
            <div className="nav-link">Athletes</div>
          </div>
        </StyledLink>

        <br />
        {/* Team Settings / Management */}

        <StyledLink
          to="/dashboard/management"
          className="menu-item"
          isActive={() =>
            [
              "/dashboard/management",
              "/dashboard/invite",
              "/dashboard/invite/success",
            ].includes(pathname)
          }
          style={{ pointerEvents: user.onboarding_complete ? "" : "none" }}
          key="management"
          onClick={() => {
            window.localStorage.setItem(
              "location",
              JSON.stringify({
                management_tab: 0,
              })
            );
            window.localStorage.setItem("open_week_tab", 0);
            window.localStorage.setItem("is_calendar", false);
          }}
        >
          <div className="flex column alignCenter justifyCenter">
            {[
              "/dashboard/management",
              "/dashboard/invite",
              "/dashboard/invite/success",
            ].includes(pathname) ? (
              <img
                src={PresentationLineActiveIcon}
                alt="athletes"
                height={32}
                width={32}
              />
            ) : (
              <img
                src={PresentationLineIcon}
                alt="athletes"
                height={32}
                width={32}
              />
            )}
            <div className="nav-link">
              Team <br /> Settings
            </div>
          </div>
        </StyledLink>
        <br />

        <StyledLink
          to="/dashboard/calendar/teams"
          className="menu-item"
          isActive={() =>
            [
              "/dashboard/calendar/teams",
              "/dashboard/calendar/athletes",
              "/dashboard/calendar",
            ].includes(pathname)
          }
          style={{ pointerEvents: user.onboarding_complete ? "" : "none" }}
          key="management"
          onClick={() => {
            window.localStorage.setItem(
              "location",
              JSON.stringify({
                management_tab: 0,
              })
            );
            window.localStorage.setItem("open_week_tab", 0);
          }}
        >
          <div className="flex column alignCenter justifyCenter">
            {[
              "/dashboard/calendar/teams",
              "/dashboard/calendar/athletes",
              "/dashboard/calendar",
            ].includes(pathname) ? (
              <img
                src={CalendarActiveIcon}
                alt="athletes"
                height={32}
                width={32}
              />
            ) : (
              <img src={CalendarIcon} alt="athletes" height={32} width={32} />
            )}
            <div className="nav-link">Calendar</div>
          </div>
        </StyledLink>

        <br />
        {/* {
          <>
            {onBoarding ? (
              <StyledLink
                to="/dashboard/session-builder"
                className="menu-item"
                key="training_plans"
                onClick={() => {
                  window.localStorage.setItem(
                    "location",
                    JSON.stringify({
                      management_tab: 0,
                    })
                  );
                  window.localStorage.setItem("open_week_tab", 0);
                }}
                isActive={() =>
                  [
                    "/dashboard/session-builder",
                    "/dashboard/plan/view",
                  ].includes(pathname)
                }
              >
                <div className="flex column alignCenter justifyCenter">
                  {[
                    "/dashboard/session-builder",
                    "/dashboard/plan/view",
                  ].includes(pathname) ? (
                    <img
                      src={CheckBoxListActiveIcon}
                      alt="training plans"
                      height={24}
                      width={24}
                    />
                  ) : (
                    <img
                      src={CheckBoxListIcon}
                      alt="training plans"
                      height={24}
                      width={24}
                    />
                  )}
                  <div className="nav-link">
                    Session <br /> Builder
                  </div>
                </div>
              </StyledLink>
            ) : (
              <div className="flex column alignCenter justifyCenter">
                <img
                  src={CheckBoxListDisableIcon}
                  alt="training plans"
                  height={24}
                  width={24}
                />
                <div className="nav-link-disabled">
                  Training <br /> Plans
                </div>
              </div>
            )}
          </>
        } */}
        {/* Training Plans */}
        {/* {show_plan_button && (
          <>
            {onBoarding ?
              <StyledLink to="/dashboard/plans"
                className="menu-item"
                key="training_plans"
                onClick={() => {
                  window.localStorage.setItem("location", JSON.stringify({
                    management_tab: 0,
                  }));
                  window.localStorage.setItem("open_week_tab", 0);
                }}
                isActive={() => [
                  '/dashboard/plans',
                  '/dashboard/plan/view',
                ].includes(pathname)}
              >
                <div className="flex column alignCenter justifyCenter">
                  {['/dashboard/plans', '/dashboard/plan/view'].includes(pathname) ?
                    <img src={CheckBoxListActiveIcon} alt="training plans" height={24} width={24} /> :
                    <img src={CheckBoxListIcon} alt="training plans" height={24} width={24} />}
                  <div className="nav-link">Training <br /> Plans</div>
                </div>
              </StyledLink> :
              <div className="flex column alignCenter justifyCenter">
                <img src={CheckBoxListDisableIcon} alt="training plans" height={24} width={24} />
                <div className='nav-link-disabled'>Training <br /> Plans</div>
              </div>}
          </>
        )}
        <br /> */}
        {/* Admin Training Plan Review Board */}
        {/* {permission_status && (
          <>
            {onBoarding ?
              <StyledLink to="/dashboard/admin/plans"
                className="menu-item"
                key="admin"
                onClick={() => {
                  window.localStorage.setItem("location", JSON.stringify({
                    management_tab: 0,
                  }));
                  window.localStorage.setItem("open_week_tab", 0);
                }}
                isActive={() => [
                  '/dashboard/admin/plans',
                  '/dashboard/admin/plan',
                ].includes(pathname)}
              >
                <div className="flex column alignCenter justifyCenter">
                  {['/dashboard/admin/plans', '/dashboard/admin/plan'].includes(pathname) ?
                    <img src={CalendarActiveIcon} alt="admin" height={24} width={24} />
                    : <img src={CalendarIcon} alt="admin" height={24} width={24} />}
                  <div className="nav-link">Admin</div>
                </div>
              </StyledLink> :
              <div className="flex column alignCenter justifyCenter">
                <img src={CalendarDisableIcon} alt="admin" height={24} width={24} />
                <div className='nav-link-disabled'>Admin</div>
              </div>}
          </>
        )} */}
      </div>
      <div className="bottom-navs">
        {/* <div style={{ color: '#FFFFFF', cursor: 'pointer' }}>
          <div className="nav-link" style={{ color: '#FFFFFF' }}>Help</div>
        </div> */}
        <StyledLink
          to="/dashboard/help"
          className="menu-item"
          key="help"
          onClick={() => {
            window.localStorage.setItem(
              "location",
              JSON.stringify({
                management_tab: 0,
              })
            );
            window.localStorage.setItem("open_week_tab", 0);
          }}
          isActive={() => ["/dashboard/help"].includes(pathname)}
        >
          <div className="flex column alignCenter justifyCenter">
            <div
              className="nav-link"
              style={
                ["/dashboard/help"].includes(pathname) ? { color: "green" } : {}
              }
            >
              Help
            </div>
          </div>
        </StyledLink>
        <div style={{ height: 12 }} />

        <div
          style={{ color: "#FFFFFF", cursor: "pointer" }}
          onClick={() => dispatch(logout())}
        >
          <div className="nav-link" style={{ color: "#FFFFFF" }}>
            Sign out
          </div>
        </div>

        <div style={{ height: 24 }} />

        <StyledLink
          to="/dashboard/settings"
          key="settings"
          className="flex column alignCenter justifyCenter _profile"
          onClick={() => {
            window.localStorage.setItem(
              "location",
              JSON.stringify({
                management_tab: 0,
              })
            );
            window.localStorage.setItem("is_calendar", false);
            window.localStorage.setItem("open_week_tab", 0);
          }}
        >
          <div className="flex column alignCenter justifyCenter">
            {profile_image ? (
              <img
                src={profile_image}
                alt="setting-icon"
                height={50}
                width={50}
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            ) : (
              <div style={{ width: 50, height: 50 }} />
            )}
            <div className="nav-link" style={{ color: "#FFFFFF" }}>
              Profile
            </div>
          </div>
        </StyledLink>
      </div>
      {/* <p
        style={{
          letterSpacing: 2,
          fontSize: 10,
          color: "#ffffff",
          opacity: "0.5",
          position: "absolute",
          bottom: 20,
          left: 64,
        }}
      >
        version 2.0.12 (improvements)
      </p> */}
    </div>
  ) : null;
};

export default withRouter(Sidebar);
