import React from "react";
import CrossIcon from "../assets/icons/closs-tab.svg";

const Popup403 = (props) => {
    const { show, onCancel } = props;

    return show && (
        <div className="flex column alignCenter justifyCenter model_popup">
            <div className="lex column alignCenter justifyCennter w-100" style={{ background: '#ffffff', height: 320, width: 500, borderRadius: 12, padding: 24 }}>
                <div className="flex justifyEnd w-100">
                    <img className="pointer" src={CrossIcon} alt="close_icon" height={32} width={32} onClick={onCancel} />
                </div>
                <div className="flex column alignCenter justifyCenter w-100 h-100">
                    <p className="title"> Welcome to Wild.AI coach!</p>
                    <br />
                    <p className="description" style={{ textAlign: 'center', width: '70%' }}> We are currently in a closed beta. Leave us your email and we’ll let you know when you can Join us!</p>
                    <br />
                    <a href="mailto:support@wild.ai?subject=I%20want%20Beta%20Access">support@wild.ai</a>
                </div>
            </div>
        </div>
    )

}

export default Popup403;