import React, { useEffect, useLayoutEffect } from "react";
// Providers
import { ConnectedRouter } from "connected-react-router";
import { hot } from "react-hot-loader/root";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
// Screens
import Dashboard from "./components/Dashboard";
import Onboarding from "./components/Onboarding";
import Invite from "./containers/Invite";
import Support from "./containers/Support";
import Login from "./containers/authentication/SignIn";
import Register from "./containers/authentication/Signup";
// Components
import Error404 from "./components/404";
// Actions
import { history } from "./index";
import { fetchInviteData, initialize } from "./store/app/actions";

const App = () => {
  const isInitialized = useSelector((state) => state.app.isInitialized);
  const user = useSelector((state) => state.app.user);
  const location = useSelector((state) => state.router.location);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize());
  }, []);

  const MainView = React.memo(() => (
    <ConnectedRouter history={history}>
      <Layout />
    </ConnectedRouter>
  ));

  const PrivateRoute = ({ component: Component, ...rest }) =>
    user ? (
      <Route
        location={location}
        render={(props) => <Component {...props} />}
        {...rest}
      />
    ) : (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )}
      />
    );

  const Layout = () => {
    const { pathname, search } = location;

    useLayoutEffect(() => {
      window.scrollTo(0, 0);
      let has_location = window.localStorage.hasOwnProperty("location");
      if (has_location) {
        window.localStorage.setItem(
          "location",
          JSON.stringify({
            management_tab: JSON.parse(window.localStorage.getItem("location"))[
              "management_tab"
            ],
          })
        );
      } else {
        window.localStorage.setItem(
          "location",
          JSON.stringify({
            management_tab: 0,
          })
        );
      }
    }, [location.pathname]);

    if (!user && pathname === "/") {
      return <Redirect to="/login" />;
    }

    if (
      (user && pathname === "/") ||
      (user && pathname === "/login") ||
      (user && pathname === "/signup")
    ) {
      if (user.onboarding_complete) {
        return <Redirect to="/dashboard/athletes" />;
      } else if (!user.onboarding_complete && user.team_num) {
        return <Redirect to="/onboarding/addAthletes" />;
      } else {
        return <Redirect to="/onboarding/guide" />;
      }
    }

    // if ((user && pathname === "/dashboard/") || (user && pathname === "/dashboard")) {
    //   if (user.onboarding_complete) {
    //     return <Redirect to="/dashboard/athletes" />;
    //   } else if (!user.onboarding_complete && user.team_num) {
    //     return <Redirect to="/onboarding/addAthletes" />;
    //   } else {
    //     return <Redirect to="/onboarding/createTeam" />;
    //   }
    // }

    // if user is not logged in, but pathname is invite
    if (!user && pathname === "/invite") {
      if (search !== "") {
        const urlDecoded = decodeURIComponent(search).replace("?", "");
        const json = JSON.parse(
          '{"' +
            decodeURI(urlDecoded)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
        window.localStorage.setItem("invitation", JSON.stringify(json));
        return <Redirect to="/login" />;
      } else if (
        search === "" &&
        window.localStorage.getItem("invitation") !== null
      ) {
        return <Redirect to="/login" />;
      } else if (
        search === "" &&
        window.localStorage.getItem("invitation") === null
      ) {
        return <Redirect to="/404" />;
      }
    }

    // if user is logged in, and pathname is invite
    if (user && pathname === "/invite") {
      if (search !== "") {
        dispatch(fetchInviteData(location.search));
      } else if (
        search === "" &&
        window.localStorage.getItem("invitation") === null
      ) {
        return <Redirect to="/404" />;
      }
    }

    return (
      <Switch>
        <PrivateRoute
          user={user}
          exact
          path="/dashboard/*"
          component={(props) => <Dashboard {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/onboarding/*"
          component={(props) => <Onboarding {...props} />}
        />
        <PrivateRoute
          user={user}
          exact
          path="/invite"
          component={(props) => <Invite {...props} />}
        />
        <Route
          exact
          path="/login"
          location={location}
          component={(props) => <Login {...props} />}
        />
        <Route
          exact
          path="/signup"
          location={location}
          component={(props) => <Register {...props} />}
        />
        <Route
          exact
          path="/support"
          location={location}
          component={(props) => <Support {...props} />}
        />
        <Route component={() => <Error404 />} />
      </Switch>
    );
  };

  if (!isInitialized) {
    return (
      <div className="App">
        <div className="flex column justifyCenter alignCenter reports_loading_container">
          <div className="custom_loader" />
          <p style={{ marginTop: 32 }}>{`Please wait a moment`}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <MainView />
    </div>
  );
};

export default hot(App);
