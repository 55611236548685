import mixpanel from "mixpanel-browser";

let token = process.env.REACT_APP_MIXPANEL_TOKEN
mixpanel.init(token);

let actions = {
  identify: (id) => mixpanel.identify(id),
  track: (name, props) => mixpanel.track(name, props),
};

export let Mixpanel = actions;
