import React from "react";
// Icons
import BlackClose from "../../assets/icons/dark-cross.svg";

const TagsInput = (props) => {
  const list = props.list;
  const checkEvent = (event) => {
    switch (event.key) {
      case " ":
        return true;
      default:
        return false;
    }
  };
  return (
    <div className="flex column alignStart w-100">
      {list.length > 0 ?
        <ul id="tags">
          {list.map((item, index) =>
            <div key={index + 1}>
              <li key={`key-${index + 1}`} className="tag" style={{ background: `${item.color}50` || '#ffffff' }}>
                <span className="tag-title">{item.email || item.tag}</span>
                <span style={{ marginLeft: "16px" }} onClick={() => props.onDeleteTag(index)}>
                  <img className="pointer" src={BlackClose} alt="black-close" height={16} width={16} />
                </span>
              </li>
            </div>
          )}
        </ul>
        : null}

      <input
        type="text"
        className="tagInput"
        id="tagInputField"
        placeholder={props.placeholderText}
        autoComplete="off"
        onPaste={(e) => {
          e.preventDefault();
          var input = e.clipboardData.getData("Text");
          e.value = input;
          props.onPasteTags(input);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            props.onError("Enter key is not allowed");
            e.preventDefault();
          } else if (checkEvent(e)) {
            if (!e.target.value.replace(/\s/g, '').length) {
              props.onError("Empty space is not allowed");
              e.preventDefault();
            } else {
              props.onAddTag(e);
            }
          }
        }}
      />
    </div>

  );
};

export default TagsInput;
