import React, { useState } from "react";
import DownloadIcon from "../../assets/icons/download-arrow.svg";
import EyeIcon from "../../assets/icons/eye.svg";
import "./InvoiceTable.css";

const InvoiceTable = ({
  data,
  selectedInvoices,
  setSelectedInvoices,
  showInvoiceDetails,
}) => {
  const getColorClass = (inputString) => {
    switch (inputString) {
      case "paid":
        return "#17C964";
      case "past due":
        return "#F5A524";
      case "failed":
        return "white";
      default:
        return "white";
    }
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "gbp":
        return "£";
      case "usd":
        return "$";
      case "eur":
        return "EUR";
      default:
        return "£";
    }
  };

  // const handleSelect = (invoiceNumber) => {
  //   setSelectedInvoices((prevSelected) =>
  //     prevSelected.includes(invoiceNumber)
  //       ? prevSelected.filter((num) => num !== invoiceNumber)
  //       : [...prevSelected, invoiceNumber]
  //   );
  // };

  // const handleSelectAll = () => {
  //   if (selectedInvoices.length === data.length) {
  //     setSelectedInvoices([]);
  //   } else {
  //     setSelectedInvoices(data.map((invoice) => invoice.invoiceNumber));
  //   }
  // };

  return (
    <table>
      <thead>
        <tr className="stripe-row">
          {/* <th className="rounded-edges-left">
            <label className="flex custom-checkbox">
              <input type="checkbox" style={{ width: "10%" }} />
              <span className="checkmark" onClick={handleSelectAll} />
            </label>
          </th> */}
          <th className="rounded-edges-left">Invoice Number</th>
          <th className="left-border">Status</th>
          <th className="left-border">Amount</th>
          <th className="left-border">Date</th>
          <th className="rounded-edges-right left-border">Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((invoice, index) => (
          <tr
            key={invoice.invoiceNumber}
            className={index % 2 === 0 ? "every-second-row" : "stripe-row"}
          >
            {/* <td className="rounded-edges-left">
              <label className="flex custom-checkbox">
                <input
                  type="checkbox"
                  style={{ width: "10%" }}
                  checked={selectedInvoices.includes(invoice.invoiceNumber)}
                  onChange={() => handleSelect(invoice.invoiceNumber)}
                />
                <span className="checkmark" />
              </label>
            </td> */}
            <td className="rounded-edges-left">{invoice.invoiceNumber}</td>
            <td>
              <div
                className="status-container"
                style={{ borderColor: getColorClass(invoice.status) }}
              >
                <span
                  className="circle"
                  style={{
                    backgroundColor: getColorClass(invoice.status),
                  }}
                />
                <span
                  style={{
                    color: getColorClass(invoice.status),
                    textTransform: "capitalize",
                  }}
                >
                  {invoice.status}
                </span>
              </div>
            </td>
            <td>
              {getCurrencySymbol(invoice.currency)}
              {invoice.amount / 100}
            </td>
            <td>{invoice.date}</td>
            <td className="rounded-edges-right">
              <a href={invoice.downloadLink} download>
                <img
                  src={DownloadIcon}
                  alt="download-icon"
                  height={24}
                  width={24}
                  className="pointer"
                  style={{ marginRight: "10px" }}
                />
              </a>
              <img
                src={EyeIcon}
                alt="eye-icon"
                height={24}
                width={24}
                className="pointer"
                onClick={() => showInvoiceDetails(invoice.id)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvoiceTable;
