import React, { useState } from "react";
import "./SwitchButton.css";

function SwitchButton({ isChecked = false, label, onChange }) {
  const [isSwitchOn, setSwitchOn] = useState(isChecked);

  const handleSwitchChange = () => {
    setSwitchOn(!isSwitchOn);
    onChange();
  };

  return (
    <div className="switch-container">
      <label className="switch">
        <input
          type="checkbox"
          checked={isSwitchOn}
          onChange={handleSwitchChange}
        />
        <span className="slider round"></span>
      </label>
      {label && <span className="switch-label">{label}</span>}
    </div>
  );
}

export default SwitchButton;
