import React, { useState } from "react";
// Providers 
import { split } from "lodash";
import moment from "moment";
// Icons 
import BinIcon from "../../assets/icons/bin.svg";
import EditIcon from "../../assets/icons/edit-pen-line.svg";
import TransferIcon from "../../assets/icons/redo-line.svg";


import DeleteIcon from "../../assets/icons/bin.svg";
import NextIcon from "../../assets/icons/chevron-right-light.svg";
import SureDeleteIcon from "../../assets/icons/sure-delete.svg";
// Components 
import NoData from "../common/NoData";
// Actions
import { history } from "../../index";

const ManagementTable = ({
  source, deleteData, deleteInvitedMember,
  tab_index, setSortedRes, permissions,
  loadingState, searchedText, searchStatus, tabAction
}) => {

  const { edit, view, admin } = permissions || {};
  const [showRedDeleteIcon, setShowRedDeleteIcon] = useState({});
  let [ascending_index, set_ascending_index] = useState(0);
  let [ascending_highlight, set_ascending_highlight] = useState(true);
  let [decensing_index, set_decensing_index] = useState(-1);
  let [decensing_highlight, set_decensing_highlight] = useState(false);

  const sortList = (list, filterOn, type, method) => {
    let sorted_list = [];

    if (type === 'alphabet') {
      if (method === "ascending") {
        sorted_list = list.sort().reverse();
      } else if (method === "descending") {
        sorted_list = list.sort((a, b) => b[filterOn].localeCompare(a[filterOn]))
      }
    }

    if (type === 'date') {
      if (method === "ascending") {
        sorted_list = list.sort((a, b) => {
          a = split(a[filterOn], "@", 1)[0]
          b = split(b[filterOn], "@", 1)[0];
          return new Date(b) - new Date(a)
        });
      } else if (method === "descending") {
        sorted_list = list.sort((a, b) => {
          a = split(a[filterOn], "@", 1)[0]
          b = split(b[filterOn], "@", 1)[0];
          return new Date(a) - new Date(b)
        });
      }
    }

    setSortedRes(sorted_list);
  };

  const getFLFromNames = (name) => {
    const spiltName = name.split(" ");
    if (spiltName.length > 1) {
      return spiltName[0].charAt(0) + spiltName[1].charAt(0);
    } else if (spiltName.length === 1) {
      return spiltName[0].charAt(0);
    }
  }

  const setPath = (id) => history.push("/dashboard/athlete?id=" + id);

  const teamColumnsLabel = [
    { title: "Team Name", width: 240 },
    { title: "Athletes", width: 120 },
    { title: "Owner", width: 200 },
    { title: "Coaches", width: 120 },
    { title: "Action", width: 120, textAlign: 'center' }
  ]

  const athletesColumnsLabel = [
    { title: "Athlete", field: 'name', type: "alphabet", width: 240, sorting: true, textAlign: 'flex-start' },
    { title: "Email", field: 'email', type: "alphabet", width: 240, sorting: true, textAlign: 'flex-start' },
    { title: "Invited", field: 'member_since', type: "date", width: 100, sorting: true, textAlign: 'center' },
    { title: "Status", field: 'status', type: "alphabet", width: 100, sorting: true, textAlign: 'center' },
    { title: "", width: 80, textAlign: 'center' }
  ];

  const coachesColumnsLabel = [
    { title: "Coach", field: 'name', type: "alphabet", width: 240, sorting: true, textAlign: 'flex-start' },
    { title: "Email", field: 'email', type: "alphabet", width: 240, sorting: true, textAlign: 'flex-start' },
    { title: "Invited", field: 'member_since', type: "date", width: 100, sorting: true, textAlign: 'center' },
    { title: "Status", field: 'status', type: "alphabet", width: 100, sorting: true, textAlign: 'center' },
    { title: "", width: 80, textAlign: 'center' }
  ];

  const columns = tab_index === 0 ? teamColumnsLabel : tab_index === 1 ? athletesColumnsLabel : coachesColumnsLabel;


  return (
    <div className="flex column alignCenter w-100">
      {/* Columns */}
      {(source && source.length > 0) ?
        <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0px 0px", padding: "0 12px" }}>
          {columns.map((column, index) => (
            <div className="flex justifyStart alignCenter" key={index + 1} style={{ minWidth: column.width }}>
              <p style={{ fontSize: 13 }}>{column.title}</p>
              {column.sorting && (
                <div className="flex column sorting_arrows">
                  <span onClick={() => {
                    sortList(source, column.field, column.type, "ascending");
                    set_ascending_index(index);
                    set_decensing_index(-1);
                    set_ascending_highlight(true);
                  }}>
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path fill={ascending_index === index && ascending_highlight ? "#ffffff" : "#ffffff50"} d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                    </svg>
                  </span>
                  <span onClick={() => {
                    sortList(source, column.field, column.type, "descending");
                    set_ascending_index(-1);
                    set_decensing_index(index);
                    set_decensing_highlight(true);
                  }}>
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path fill={decensing_index === index && decensing_highlight ? "#ffffff" : "#ffffff50"} d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                    </svg>
                  </span>
                </div>
              )}
            </div>
          ))}
        </div> : null}

      {/* Rows: All Teams */}
      {tab_index === 0 && source && source.map((item, index) => (
        <div key={index + 1} className="flex justifyBetween athlete_list_item w-100" style={{ marginTop: 16, cursor: "default" }}>
          {/* Field: profile_image & name */}
          <div className="flex justifyCenter alignCenter pointer"
            onClick={() => {
              window.localStorage.setItem("team_Id", item.deletePK);
              window.localStorage.setItem("team_name", split(item.name, "@")[1]);
              history.push("/dashboard/athletes");
            }}
            style={{ minWidth: 240, maxWidth: 240 }}>
            {split(item.name, "@")[0] === "null" ? (
              <div className="placeholder_name_box">
                <span className="name_letter">
                  {(getFLFromNames(split(item.name, "@")[1]))}
                </span>
              </div>
            ) : (
              <img src={`${split(item.name, "@")[0]}`} alt="overview-icon" className="athletes-profile-image" />
            )}

            {split(item.name, "@")[1] === " " ? (
              <span className="athlete-name" style={{ marginLeft: 12 }}>{`-`}</span>
            ) : (
              <span className="athlete-name" style={{ marginLeft: 12 }}>{split(item.name, "@")[1]}</span>
            )}
          </div>

          {/* Field: Athletes count */}
          <div className="flex justifyStart alignCenter pointer" style={{ minWidth: 120 }}
            onClick={() => {
              window.localStorage.setItem("team_Id", item.deletePK);
              window.localStorage.setItem("team_name", split(item.name, "@")[1]);
              history.push("/dashboard/athletes");
            }}>
            <span className="athlete-member-since">{item.athlete_count}</span>
          </div>

          {/* Field: Owner name */}
          <div className="flex justifyStart alignCenter pointer" style={{ minWidth: 200 }}
            onClick={() => {
              window.localStorage.setItem("team_Id", item.deletePK);
              window.localStorage.setItem("team_name", split(item.name, "@")[1]);
              history.push("/dashboard/athletes");
            }}>
            <span className="athlete-member-since">{item.owner_name}</span>
          </div>

          {/* Field: Coaches count */}
          <div className="flex justifyStart alignCenter pointer" style={{ minWidth: 120 }}
            onClick={() => {
              window.localStorage.setItem("team_Id", item.deletePK);
              window.localStorage.setItem("team_name", split(item.name, "@")[1]);
              history.push("/dashboard/athletes");
            }}>
            <span className="athlete-member-since">{item.coach_count}</span>
          </div>

          {/* Field: delete */}
          {(item.deletePK && item.status) ?
            <div className="flex justifyCenter alignCenter pointer" style={{ minWidth: 120, maxWidth: 120 }}>
              <img src={EditIcon} alt="edit icon" height={24} width={24} onClick={() => tabAction(item.deletePK, split(item.name, "@")[1], "edit")} />
              <img src={TransferIcon} alt="transfer ownership icon" height={24} width={24} onClick={() => tabAction(item.deletePK, split(item.name, "@")[1], "transfer")} style={{ margin: "0 20px" }} />
              <img src={BinIcon} alt="bin icon" height={20} width={20} onClick={() => tabAction(item.deletePK, split(item.name, "@")[1], "delete")} />
            </div>
            : <div style={{ width: 120 }} />}
        </div>
      ))}

      {/* Rows: All Athletes + All Coaches */}
      {(tab_index === 1 || tab_index === 2) && source && source.map((item, index) => (
        <div key={index + 1} className="flex justifyBetween athlete_list_item w-100" style={{ marginTop: 16, cursor: (tab_index === 1 && split(item.status, "@")[0] === "Approved") ? 'pointer' : "default" }}>
          {/* Field: profile_image & name */}
          {split(item.name, "@")[0] === `-` ? (
            <div className="flex justifyCenter alignCenter" style={{ minWidth: 240, maxWidth: 240 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <div className="placeholder_name_box">
                <span className="name_letter"> {getFLFromNames(split(item.name, "@")[1])}</span>
              </div>
              <span className="coach-name" style={{ marginLeft: 12 }}>{split(item.name, "@")[1]}</span>
            </div>
          ) : view ? (
            <div className="flex justifyCenter alignCenter" style={{ minWidth: 240, maxWidth: 240 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              {split(item.name, "@")[0].endsWith("default_profile_pic.png") ? (
                <div className="placeholder_name_box">
                  <span className="name_letter">
                    {(getFLFromNames(split(item.name, "@")[1]))}
                  </span>
                </div>
              ) : (
                <img src={`${split(item.name, "@")[0]}`} alt="overview-icon" className="athletes-profile-image" />
              )}

              {split(item.name, "@")[1] === " " ? (
                <span className="athlete-name" style={{ marginLeft: 12 }}>{`-`}</span>
              ) : (
                <span className="athlete-name" style={{ marginLeft: 12 }}>{split(item.name, "@")[1]}</span>
              )}
            </div>
          ) : (
            <div className="flex justifyCenter alignCenter" style={{ minWidth: 240, maxWidth: 240 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              {split(item.name, "@")[0].endsWith("default_profile_pic.png") ? (
                <div className="placeholder_name_box">
                  <span className="name_letter">
                    {(getFLFromNames(split(item.name, "@")[1]))}
                  </span>
                </div>
              ) : (
                <img src={`${split(item.name, "@")[0]}`} alt="overview-icon" className="athletes-profile-image" />
              )}

              {split(item.name, "@")[1] === " " ? (
                <span className="athlete-name" style={{ marginLeft: 12 }}>{`-`}</span>
              ) : (
                <span className="athlete-name" style={{ marginLeft: 12 }}>{split(item.name, "@")[1]}</span>
              )}
            </div>
          )}

          {/* Field: email address */}
          {split(item.email, "/")[1] === `-` ? (
            <div className="flex justifyStart alignCenter" style={{ minWidth: 240, maxWidth: 240 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <span className="athlete-email">
                {split(split(item.email, "/")[0], ",")[0] !== null
                  ? split(split(item.email, "/")[0], ",")[0]
                  : split(item.email, "/")[0]}
              </span>
            </div>
          ) : view ? (
            <div className="flex justifyStart alignCenter" style={{ minWidth: 240, maxWidth: 240 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <span className="athlete-email">
                {split(split(item.email, "/")[0], ",")[0] !== null
                  ? split(split(item.email, "/")[0], ",")[0]
                  : split(item.email, "/")[0]}
              </span>
            </div>
          ) : (
            <div className="flex justifyStart alignCenter" style={{ minWidth: 240, maxWidth: 240 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <span className="athlete-email">
                {split(split(item.email, "/")[0], ",")[0] !== null
                  ? split(split(item.email, "/")[0], ",")[0]
                  : split(item.email, "/")[0]}
              </span>
            </div>
          )}

          {/* Field: member_since / invited */}
          {split(item.member_since, "@")[1] === "-" ? (
            <div className="flex justifyStart alignCenter" style={{ minWidth: 100 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <span className="athlete-member-since">
                {moment(split(item.member_since, "@")[0]).format("DD.MM.YYYY")}
              </span>
            </div>
          ) : view ? (
            <div className="flex justifyStart alignCenter" style={{ minWidth: 100 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <span className="athlete-member-since">
                {moment(split(item.member_since, "@")[0]).format("DD.MM.YYYY")}
              </span>
            </div>
          ) : (
            <div className="flex justifyStart alignCenter" style={{ minWidth: 100 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <span className="athlete-member-since">
                {moment(split(item.member_since, "@")[0]).format("DD.MM.YYYY")}
              </span>
            </div>
          )}

          {/* Field: status */}
          {split(item.status, "@")[1] === "-" ? (
            <div className="flex justifyStart alignCenter" style={{ minWidth: 100 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <span className="athlete-status">{split(item.status, "@")[0]}</span>
            </div>
          ) : view ? (
            <div className="flex justifyStart alignCenter" style={{ minWidth: 100 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <span className="athlete-status"> {split(item.status, "@")[0]}</span>
            </div>
          ) : (
            <div className="flex justifyStart alignCenter" style={{ minWidth: 100 }}
              onClick={() => { if (tab_index === 1 && split(item.status, "@")[0] === "Approved") { setPath(item.id) } }}>
              <span className="athlete-status"> {split(item.status, "@")[0]}</span>
            </div>
          )}

          {/* Field: delete */}
          {item.deletePK ?
            <div className="flex justifyCenter alignCenter pointer" style={{ minWidth: 80, maxWidth: 80 }}>
              {/* Delete pending */}
              {split(item.deletePK, "=")[0] === "pending" && (
                <div className="flex justifyCenter alignCenter pointer">
                  {showRedDeleteIcon["id"] === split(item.deletePK, "=")[1] &&
                    showRedDeleteIcon["open"] ? (
                    <img src={SureDeleteIcon} alt="delete-red"
                      onClick={() => {
                        setShowRedDeleteIcon(false);
                        deleteInvitedMember(split(item.deletePK, "=")[1]);
                      }}
                    />
                  ) : (
                    <img src={DeleteIcon} alt="delete-grey" height={20} width={20}
                      onClick={() => {
                        setShowRedDeleteIcon({
                          id: split(item.deletePK, "=")[1],
                          open: true,
                        });
                      }}
                    />
                  )}
                </div>
              )}
              {/* Delete athlete */}
              {split(item.deletePK, "=")[0] === "athlete" && (
                <div className="flex justifyCenter alignCenter pointer">
                  {showRedDeleteIcon["id"] === split(item.deletePK, "=")[1] &&
                    showRedDeleteIcon["open"] ? (
                    <img src={SureDeleteIcon} alt="delete-red"
                      onClick={() => {
                        setShowRedDeleteIcon(false);
                        deleteData(split(item.deletePK, "=")[1]);
                      }}
                    />
                  ) : (
                    <img src={DeleteIcon} alt="delete-grey" height={20} width={20}
                      onClick={() => {
                        setShowRedDeleteIcon({
                          id: split(item.deletePK, "=")[1],
                          open: true,
                        });
                      }}
                    />
                  )}
                </div>
              )}
              {/* Delete invited */}
              {split(item.deletePK, "/")[1] === "Invited" && (
                <div className="flex justifyCenter alignCenter pointer">
                  {showRedDeleteIcon["id"] === split(item.deletePK, "/")[0] &&
                    showRedDeleteIcon["open"] ? (
                    <img src={SureDeleteIcon} alt="delete-red"
                      onClick={() => {
                        setShowRedDeleteIcon(false);
                        deleteInvitedMember(split(item.deletePK, "/")[0]);
                      }}
                    />
                  ) : (
                    <img src={DeleteIcon} alt="delete-grey" height={20} width={20}
                      onClick={() => {
                        setShowRedDeleteIcon({
                          id: split(item.deletePK, "/")[0],
                          open: true,
                        });
                      }}
                    />
                  )}
                </div>
              )}
              {/* Delete approved */}
              {(split(item.deletePK, "@")[1] === "Approved" && admin) && (
                <div className="flex justifyCenter alignCenter pointer">
                  {showRedDeleteIcon["id"] === split(item.deletePK, "@")[0] &&
                    showRedDeleteIcon["open"] ? (
                    <img src={SureDeleteIcon} alt="delete-red"
                      onClick={() => {
                        setShowRedDeleteIcon(false);
                        deleteData(split(item.deletePK, "@")[0]);
                      }}
                    />
                  ) : (
                    <img src={DeleteIcon} alt="delete-grey" height={20} width={20}
                      onClick={() => {
                        setShowRedDeleteIcon({
                          id: split(item.deletePK, "@")[0],
                          open: true,
                        });
                      }}
                    />
                  )}
                </div>
              )}
              {(split(item.deletePK, "@")[0] !== "-" && (
                split(item.deletePK, "=")[0] !== "pending") &&
                split(item.deletePK, "=")[0] === "athlete" &&
                split(item.deletePK, "/")[1] === "Invited" &&
                split(item.deletePK, "@")[1] === "Approved"
              ) && (
                  <>
                    {(split(item.deletePK, "@")[1] === "You" && edit) ?
                      <div className="flex justifyCenter alignCenter pointer">
                        {showRedDeleteIcon["id"] === split(item.deletePK, "@")[0] &&
                          showRedDeleteIcon["open"] ? (
                          <img src={SureDeleteIcon} alt="delete-red"
                            onClick={() => {
                              setShowRedDeleteIcon(false);
                              deleteData(split(item.deletePK, "@")[0]);
                            }}
                          />
                        ) : (
                          <img src={DeleteIcon} alt="delete-grey" height={20} width={20}
                            onClick={() => {
                              setShowRedDeleteIcon({
                                id: split(item.deletePK, "@")[0],
                                open: true,
                              });
                            }}
                          />
                        )}
                      </div>
                      : null}

                    {(split(item.deletePK, "@")[1] === "Approved" && edit) ?
                      <div className="flex justifyCenter alignCenter pointer">
                        {showRedDeleteIcon["id"] === split(item.deletePK, "@")[0] &&
                          showRedDeleteIcon["open"] ? (
                          <img src={SureDeleteIcon} alt="delete-red"
                            onClick={() => {
                              setShowRedDeleteIcon(false);
                              deleteData(split(item.deletePK, "@")[0]);
                            }}
                          />
                        ) : (
                          <img src={DeleteIcon} alt="delete-grey" height={20} width={20}
                            onClick={() => {
                              setShowRedDeleteIcon({
                                id: split(item.deletePK, "@")[0],
                                open: true,
                              })
                            }}
                          />
                        )}
                      </div>
                      : null}
                  </>
                )}
            </div>
            : split(item.status, "@")[0] !== "Invited" ? <div className="flex justifyCenter alignCenter pointer" style={{ minWidth: 80, maxWidth: 80 }}>
              <img src={NextIcon} alt="next-icon" height={16} width={16} />
            </div> : <div style={{ width: 80 }} />}
        </div>
      ))}


      {(tab_index === 1 && source && source.length === 0) &&
        <div className="no_record_found">
          <div className="flex column alignCenter justifyCenter">
            <h1>{`There are no athletes in the team`}</h1>
          </div>
        </div>
      }


      {/* If source is empty after searching for input entered, show this message below  */}
      {searchStatus && source && source.length === 0 && (<NoData loadingState={loadingState} searchedText={searchedText} />)}
    </div>
  );
};

export default ManagementTable;
