import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Camera from "../../assets/icons/camera.svg";
// Actions
import { createNewTeam } from "../../_helpers/Requests";
import { history } from "../../index";
import { createNotification } from "../../store/app/actions";

const CreateTeam = () => {
  const { user } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [show_form, setShowForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [team, set_team] = useState({
    image: "",
    name: "",
  });

  // onError
  const onError = (error) => {
    setSubmitting(false);
    dispatch(createNotification("error-toast", error));
  };

  // onSuccess
  const onSuccess = () => {
    setSubmitting(false);
    dispatch(createNotification("success-toast", "Team has been created."));
    history.push("/onboarding/congrats");
  };

  // Set enable status
  const checkEnabled = (team) => {
    if (team.name === "") {
      setEnabled(true);
    } else if (team.name !== "") {
      setEnabled(false);
    }
  };

  // Upload team image
  const onUploadImage = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      var totalBytes = files[0].size;
      if (files[0].type === "image/jpeg" || files[0].type === "image/png") {
        if (totalBytes <= 2000000) {
          set_team({ ...team, image: reader.result });
        } else {
          dispatch(
            createNotification(
              "error-toast",
              "Please upload image file of size 2MB or below"
            )
          );
        }
      } else {
        dispatch(
          createNotification("error-toast", "Please select an image file")
        );
      }
    };
    reader.readAsDataURL(files[0]);
  };

  const onCreateTeam = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const values = {
      img_file: team["image"] ? team["image"] : "",
      name: team["name"],
    };
    createNewTeam(values, onSuccess, onError, user);
  };

  useEffect(() => {
    checkEnabled(team);
  }, [team]);

  return (
    <div className="flex column w-100 h-100">
      {!show_form ? (
        <div
          className="flex column alignCenter justifyCenter"
          style={{ minHeight: "100vh" }}
        >
          <h1 style={{ color: "inherit" }}>Hey {user["coach"].first_name}!</h1>
          <p style={{ margin: "24px auto", width: 380, textAlign: "center" }}>
            Let’s create your first team, which will help you organize and
            streamline communication between you and your athletes.
          </p>
          <div
            className="white-button"
            style={{ maxWidth: 180 }}
            onClick={() => setShowForm(!show_form)}
          >
            <p>Create Team</p>
          </div>
        </div>
      ) : (
        <div
          className="settings-main"
          style={{ maxWidth: 376, height: "80vh", margin: "0 auto" }}
        >
          <form
            className="flex column alignCenter justifyCenter w-100"
            style={{ marginTop: 30 }}
            onSubmit={(e) => onCreateTeam(e)}
          >
            {/* Team picture */}
            <div className="flex relative" style={{ margin: "0 auto" }}>
              {team.image === "" ? (
                <div
                  style={{
                    width: 200,
                    height: 200,
                    background: "#ffffff",
                    borderRadius: "50%",
                  }}
                >
                  <h1
                    className="flex column justifyCenter alignCenter h-100 w-100"
                    style={{ fontSize: 72, color: "#000000" }}
                  >
                    TN
                  </h1>
                </div>
              ) : (
                <img
                  src={team.image}
                  alt="profile-icon"
                  height={200}
                  width={200}
                  style={{
                    padding: 4,
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              )}

              <div
                style={{
                  position: "absolute",
                  right: 8,
                  marginTop: 16,
                  width: 51.26,
                  height: 51.26,
                  bottom: 0,
                  background: "#08AFA8",
                  borderRadius: "50%",
                  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.25)",
                }}
              >
                <input
                  type="file"
                  id="logo_url"
                  accept="image/x-png,image/jpeg"
                  onChange={onUploadImage}
                  hidden
                />
                <label
                  htmlFor="logo_url"
                  className="flex column justifyCenter alignCenter pointer h-100"
                >
                  <img src={Camera} alt="camera-icon" height={24} width={24} />
                </label>
              </div>
            </div>
            {/* Team name */}
            <div className="flex justifyBetween alignCenter w-100">
              <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                <label className="settings-label">Team name</label>
                <input
                  type="text"
                  required
                  id="name"
                  placeholder="Team name"
                  className="settings-value"
                  onChange={(e) => set_team({ ...team, name: e.target.value })}
                />
              </div>
            </div>
            {/* Create team */}
            <div
              className="flex justifyCenter alignCenter w-100"
              style={{ marginTop: 24 }}
            >
              <button
                htmltype="submit"
                disabled={enabled}
                className={enabled ? "disable-button" : "white-button"}
              >
                {submitting ? (
                  <div className="flex justifyCenter alignCenter">
                    <div className="custom_button_loader" />
                  </div>
                ) : (
                  <p style={{ margin: 0 }}>Create Team</p>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
export default CreateTeam;
