import React, { useRef, useState, useEffect } from "react";
import ArrowUp from "../../assets/icons/chevron-up-dark.svg";
import ArrowDown from "../../assets/icons/chevron-down-dark.svg";

const TeamsSelector = (props) => {
  let [show, setShow] = useState(false);
  let closeSelector = () => setShow(false);

  function handleChange(team_id, team_name) {
    window.localStorage.setItem("team_Id", team_id);
    window.localStorage.setItem("team_name", team_name);
    props.callAction(team_id);
    closeSelector();
  }


  function useOutsideAlerter(ref, closeSelector) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeSelector();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, closeSelector]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, closeSelector);

  return (
    props.isLoading ?
      <div style={{ minWidth: 224 }}>
        <div className="custom_button_loader" />
      </div>
      : props.teams ?
        <div className="team_selector" ref={wrapperRef}>
          {/* Team name */}
          <div className="flex justifyBetween alignCenter w-100"
            style={{ cursor: props.teams.length > 1 ? 'pointer' : 'default' }}
            onClick={() => props.teams.length > 1 ? setShow(true) : null}>
            <p className="selected_team">
              {window.localStorage.getItem('team_name') || ""}
            </p>
            {/* arrows */}
            {props.teams.length > 1 && (
              <div className="selectory_arrow_component">
                {show ? (
                  <img src={ArrowUp} alt="arrow-up" height={24} width={24} />
                ) : (
                  <img src={ArrowDown} alt="arrow-down" height={24} width={24} />
                )}
              </div>
            )}
          </div>

          {/* dropdown box */}
          {(show && props.teams.length > 1) ? (
            <div className="listing">
              <div className="teams-list">
                {props.teams && props.teams.map((team, subIndex) =>
                  team['pk'] === Number(window.localStorage.getItem("team_Id")) ? (
                    <p className="team-names active" key={subIndex + 1}
                      onClick={() => handleChange(team.pk, team.name)}>
                      {team.name}
                    </p>
                  ) : (
                    <p className="team-names" key={subIndex + 1}
                      onClick={() => handleChange(team.pk, team.name)} >
                      {team.name}
                    </p>
                  )
                )}
              </div>
            </div>
          ) : null}
        </div>
        : <div style={{ minWidth: 224 }} />
  );
};

export default TeamsSelector;
