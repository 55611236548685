import api from "../services/api/ApiService";
import { createNotification } from "../store/app/actions";
import { history } from "../index";

const wait = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

// Send invites to athletes
// Duplication check for emails (removed duplicating emails before sending to backed)
export const sendAthletesInvites = async (
  team_id,
  emails,
  onSuccess,
  onError
) => {
  try {
    emails = emails.filter((item, pos) => emails.indexOf(item) === pos);
    if (team_id) {
      const response = await api.post(`coach/team/${team_id}/invite/athletes`, {
        emails: emails,
      });
      if (response["data"]) {
        const { invited, rejected } = response["data"];
        if (invited && invited.length > 0) {
          emails.forEach((email) =>
            mixpanelEventLog(
              "Invite athlete",
              email,
              "Invite Athletes",
              "Invite Athletes Onboarding"
            )
          );
          onSuccess(invited);
        } else if (rejected && rejected.length > 0) {
          onError(rejected);
        }
      }
    } else {
      onError(
        "Apologies, but it seems that the team details is either incorrect or not found in our records against your account!"
      );
    }
  } catch (err) {
    onError(getErrorMessage(err.response));
  }
};

// Send invites to coaches
// Duplication check for emails (removed duplicating emails before sending to backed)
export const sendCoachesInvite = async (
  team_id,
  emails,
  onSuccess,
  onError
) => {
  try {
    emails = emails.filter((item, pos) => emails.indexOf(item) === pos);
    if (team_id) {
      const response = await api.post(`coach/team/${team_id}/invite/coaches`, {
        emails: emails,
      });
      if (response["data"]) {
        const { invited, rejected } = response["data"];
        if (invited && invited.length > 0) {
          onSuccess(invited);
        } else if (rejected && rejected.length > 0) {
          onError(rejected);
        }
      }
    } else {
      onError(
        "Apologies, but it seems that the team details is either incorrect or not found in our records against your account!"
      );
    }
  } catch (err) {
    onError(getErrorMessage(err.response));
  }
};

// Update onboarding status (Skip Onboarding Button, Let Go button)
export const updateOnboardingStatus = async (onSuccess, onError) => {
  try {
    const response = await api.patch(`coach/update`, {
      onboarding_complete: true,
    });
    if (response.status === 200) {
      onSuccess(response.data);
      window.localStorage.removeItem("teams");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      onError(getErrorMessage(err.response));
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        onError(details);
      }
    }
  }
};

// Create team while onBoarding
export const createNewTeam = async (values, onSuccess, onError, coach) => {
  try {
    const response = await api.post(`coach/team/createteam`, values);
    if (response.status === 201) {
      onSuccess();
      mixpanelEventLog(
        "Create a team",
        coach.coach.email,
        "Create",
        "Create a team"
      );
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      onError(getErrorMessage(err.response));
    } else {
      if (err.response) {
        const {
          data: { logo_url, name },
        } = err.response;
        if (logo_url) {
          onError(logo_url.join(" "));
        } else if (name) {
          onError(name.toString());
        }
      }
    }
  }
};

// Create team from team settings / athletes overview
export const createNewTeamManagement = async (
  values,
  onSuccess,
  onError,
  coach
) => {
  try {
    const response = await api.post(`coach/team/createteam`, values);
    if (response.status === 201) {
      onSuccess();
      mixpanelEventLog(
        "Create a team",
        coach.coach.email,
        "Create",
        "Create a team"
      );
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      onError(getErrorMessage(err.response));
    } else {
      if (err.response) {
        const {
          data: { logo_url, name },
        } = err.response;
        if (logo_url) {
          onError(logo_url.join(" "));
        } else if (name) {
          onError(name.toString());
        }
      }
    }
  }
};

// Delete team, clear team data from storage and move back to the management screen
export const deleteTeam = async (team_id, onError) => {
  try {
    const response = await api.delete(`/coach/team/management/${team_id}`);
    if (response.status === 200) {
      window.localStorage.removeItem("team_Id");
      window.localStorage.removeItem("team_name");
      window.localStorage.setItem(
        "location",
        JSON.stringify({ management_tab: 0 })
      );
      createNotification("success-toast", response.data.details);
      await wait(2000);
      history.push("/dashboard/management");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      onError(getErrorMessage(err.response));
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        onError(details);
      }
    }
  }
};

// get management team data on initial load
export const getManagementTeam = async (
  team_id,
  onSuccess,
  onSuccessWithoutData,
  onError
) => {
  try {
    const { data } = await api.get(`/coach/team/management`);
    const sortedManagementTeams =
      data && data.sort((a, b) => (a.pk > b.pk ? 1 : -1));
    if (sortedManagementTeams && sortedManagementTeams.length) {
      if (team_id) {
        const { data: team } = await api.get(
          `/coach/team/management/${team_id}`
        );
        if (team) {
          onSuccess(sortedManagementTeams, team);
        } else {
          onError(
            "Apologies, but we couldn't find any details for the team you are referring to."
          );
        }
      } else {
        const { data: team } = await api.get(
          `/coach/team/management/${sortedManagementTeams[0].pk}`
        );
        if (team) {
          window.localStorage.setItem("team_Id", team["pk"]);
          window.localStorage.setItem("team_name", team["name"]);
          onSuccess(sortedManagementTeams, team);
        } else {
          onError(
            "Apologies, but we couldn't find any details for the team you are referring to."
          );
        }
      }
    } else {
      onSuccessWithoutData();
    }
  } catch (err) {
    onError(getErrorMessage(err.response));
  }
};

// Error Message Handler
export const getErrorMessage = (response) => {
  if (response.status === 400) {
    return "Something went wrong, please contact to support@wild.ai";
  } else if (response.status === 401) {
    return "Authentication Failed. Please input the correct password and username.";
  } else if (response.status === 403) {
    return "Something went wrong. You either don't have a subscription or haven't created any teams.";
  } else if (response.status === 404) {
    return "It seems that the resource you're trying to access is currently unavailable. Please double-check the URL or contact our support team at support@wild.ai for assistance.";
  } else if (response.status === 500) {
    return "Oops! Something went wrong on our end. We apologize for the inconvenience. Our technical team has been notified of this issue, and they're working diligently to resolve it. Please try again later or contact our support team at support@wild.ai for further assistance. Thank you for your patience.";
  } else {
    return "Something went wrong, please contact to support@wild.ai";
  }
};

export const mixpanelEventLog = async (eventName, email, btnName, pageName) => {
  try {
    await api.post("/mixpanel-proxy/", {
      event: eventName,
      properties: {
        distinct_id: email,
        button_name: btnName,
        page: pageName,
        source: "Coach Dashboard",
      },
    });
  } catch (err) {}
};
