import React from 'react';
import { history } from '../index';

const PieChart = ({ data, isEmpty }) => {
    const total = data.reduce((acc, { value }) => acc + value, 0);
    let cumulativePercent = 0;
    const slices = data.map(({ value, background }, i) => {
        const [startX, startY] = polarToCartesian(50, 50, 50, cumulativePercent);
        const [endX, endY] = polarToCartesian(50, 50, 50, cumulativePercent + (value / total) * 360);
        const largeArcFlag = value / total <= 0.5 ? 0 : 1;
        const pathData = `M ${startX} ${startY}  A 50 50 0 ${largeArcFlag} 1 ${endX} ${endY}  L 50 50 Z`;
        cumulativePercent += (value / total) * 360;
        return (
            <path key={i} d={pathData} fill={`${background}`} />
        );
    });

    return isEmpty ?
        <div className="flex alignCenter justifyCenter w-100" style={{ height: 200, maxWidth: '70%', background: 'rgba(255, 255, 255, 0.1)', borderRadius: 12 }}>
            <h2 style={{ color: '#FFFFFF', textAlign: 'center' }}>No athlete had a checkin score above 0.</h2>
        </div>
        : <div className='flex column alignCenter justifyCenter relative pointer' onClick={() => history.push('/dashboard/alerts')}>
            <svg viewBox="0 0 100 100" style={{ height: 380, width: 380 }}>
                {slices}
            </svg>
            <div className='absolute' style={{ background: 'linear-gradient(142.27deg, #01213A 0%, #000000 100%)', height: 260, width: 260, top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: '100%' }} />
        </div>
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return [
        centerX + radius * Math.cos(angleInRadians),
        centerY + radius * Math.sin(angleInRadians)
    ];
}

export default PieChart;
