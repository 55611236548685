import { put, takeEvery, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import api from "../../services/api/ApiService";
import {
  FETCH_SETTINGS,
  FETCH_TEAM_DETAILS,
  UPDATE_PROFILE_PICTURE,
  UPDATE_USER_PASSWORD,
  UPDATE_SETTINGS,
  UPDATE_TEAM_LOGO,
  UPDATE_TEAM_NAME,
  setSettings,
  setTeamDetails,
  TRANSFER_OWNERSHIP,
} from "./actions";
import { createNotification } from "../app/actions";

function updatePassword(old_password, new_password) {
  const resp = api.post(`/user/update_password`, {
    old_password,
    new_password,
  });
  return resp;
}

function* updateUserPassword({ payload: { form, successMessage, error } }) {
  try {
    const { old_password, new_password } = form;
    const response = yield call(updatePassword, old_password, new_password);
    const {
      data: { success },
    } = response;
    if (success) {
      yield call(successMessage);
      yield put(
        createNotification(
          "success-toast",
          "Your password has been updated."
        )
      );
      yield put(push("/dashboard/settings"));
    }
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(error, details);
    }
  }
}

function updateProfileImage(pictureURL) {
  const resp = api.post(`/coach/settings`, {
    profile_img: pictureURL,
  });
  return resp;
}

function* updatePicture({ payload: { pictureURL, success, error } }) {
  try {
    const response = yield call(updateProfileImage, pictureURL);
    const { data } = response;
    yield put(setSettings(null));
    yield put(setSettings(data));
    yield call(success);
    yield put(
      createNotification(
        "success-toast",
        "Profile picture has been updated."
      )
    );
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(error, details);
    }
  }
}

function updateTeamImage(picture, team_id) {
  const resp = api.post(`/coach/team/management`, {
    logo_img: picture,
    team_id: team_id,
  });
  return resp;
}

function* updateTeamPicture({ payload: { team_id, picture, onSuccess, onError } }) {
  try {
    const response = yield call(updateTeamImage, team_id, picture);
    if (response) {
      const { data: { logo_url } } = response;
      yield call(onSuccess, "logo_url", logo_url);
      yield put(createNotification("success-toast", "Team picture has been updated."));
    }
  } catch (err) {
    if (err.response) {
      const { data: { details } } = err.response;
      yield call(onError, details);
    }
  }
}

function updateTeamName(values) {
  const resp = api.post(`/coach/team/management`, values);
  return resp;
}

function* updateTeamNameDetails({ payload: { form, onSuccess, onError } }) {
  try {
    const response = yield call(updateTeamName, form);
    if (response) {
      const { data: { name } } = response;
      yield call(onSuccess, "name", name);
      yield put(createNotification("success-toast", "Team name has been updated."));
    }
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(onError, details);
    }
  }
}

function getSettings() {
  const resp = api.get(`/coach/settings`);
  return resp;
}

function* fetchSettings({ payload: { success, error } }) {
  try {
    const response = yield call(getSettings);
    const { data } = response;
    yield put(setSettings(data));
    yield call(success);
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(error, details);
    }
  }
}

function updateSettings(values) {
  const resp = api.post(`/coach/settings`, values);
  return resp;
}

function* updateNewSettings({ payload: { form, success, error } }) {
  try {
    const response = yield call(updateSettings, form);
    const { data } = response;
    yield put(setSettings(data));
    yield call(success);
    yield put(
      createNotification(
        "success-toast",
        "Profile has been updated."
      )
    );
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(error, details);
    }
  }
}

function teamDetails(teamPK) {
  const resp = api.get(`/coach/team/management/${teamPK}`);
  return resp;
}

function* fetchTeam({ payload: { teamPK, onError } }) {
  try {
    const response = yield call(teamDetails, teamPK);
    const {
      data: { name, logo_url, primary_coach, coaches },
    } = response;
    const details = {
      name,
      logo_url,
      primary_coach,
      coaches,
    };
    yield put(setTeamDetails(details));
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(onError, details);
    }
  }
}

function ownershipTransferEndpoint(form, teamPK) {
  const resp = api.post(`/coach/team/management/${teamPK}/owner`, form);
  return resp;
}

function* transferOwnershipByPrimaryCoach({ payload: { form, onSuccess, onError } }) {
  try {
    const teamId = window.localStorage.getItem("team_Id");
    const resp = yield call(ownershipTransferEndpoint, form, teamId);
    if (resp.status === 200) {
      const { data: { name, logo_url, primary_coach, coaches } } = yield call(teamDetails, teamId);
      const details = { name, logo_url, primary_coach, coaches };
      yield put(setTeamDetails(details));
      yield call(onSuccess);
      yield put(createNotification("success-toast", "Ownership has been transfered.")
      );
    }
  } catch (err) {
    if (err.response) {
      const { data: { details }, } = err.response;
      yield call(onError, details);
    }
  }
}

function* settingsSaga() {
  yield takeEvery(FETCH_SETTINGS, fetchSettings);
  yield takeEvery(UPDATE_SETTINGS, updateNewSettings);
  yield takeEvery(UPDATE_PROFILE_PICTURE, updatePicture);
  yield takeEvery(UPDATE_TEAM_LOGO, updateTeamPicture);
  yield takeEvery(UPDATE_TEAM_NAME, updateTeamNameDetails);
  yield takeEvery(UPDATE_USER_PASSWORD, updateUserPassword);
  yield takeEvery(FETCH_TEAM_DETAILS, fetchTeam);
  yield takeEvery(TRANSFER_OWNERSHIP, transferOwnershipByPrimaryCoach);
}

export default settingsSaga;
