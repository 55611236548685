import React from "react";
// Icons
import BackwardIcon from "../../assets/icons/backward-black.svg";
import Tick from "../../assets/icons/tick.svg";
// Actions
import { history } from "../../index";

const InvitedResponseModal = () => {
  const { title, message, emails, status, buttonText, buttonPath } = JSON.parse(window.localStorage.getItem("inviteResponse"));

  return (
    <div className="flex column justifyStart alignCenter w-100 h-100">
      {/* Header */}
      <div className="flex justifyStart alignCenter pointer w-100" style={{ padding: '56px 0 0 40px' }}
        onClick={() => {
          window.localStorage.removeItem("inviteResponse");
          history.push("/dashboard/management")
        }}
      >
        <img src={BackwardIcon} alt="close_icon" height={15} width={14} />
        <p style={{ fontSize: 16, color: "rgba(0,0,0,0.66)", margin: "0 0 0 12px" }}> Back</p>
      </div>


      {/* Content */}
      <div className="invite_field_box">
        {title ? <h1> {title} </h1> : null}
        {message ? <p> {message} </p> : null}
        {/* emails list */}
        <div className="emails-wrapper">
          {(emails && emails.length > 0) && emails.map((tag, index) => (
            <ul id="tags">
              <li key={`key-${index + 1}`} className="tag">
                <p className="tag-title">{tag.email}
                  <span style={{ marginLeft: 16 }}>
                    <img src={Tick} alt="tick-icon" height={16} width={16} />
                  </span>
                </p>
              </li>
            </ul>
          ))}
          {/* status */}
          {status ? <p style={{ fontWeight: 700, fontSize: 16, color: "#2A2A2A" }}> {status}  </p> : null}
        </div>
        <button className="white-button" onClick={() => {
          window.localStorage.removeItem("inviteResponse");
          history.push(buttonPath);
        }}
          style={{ width: 320, margin: "24px 0 0" }}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default InvitedResponseModal;
