import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// Icons + images

// Actions
import { isArray } from "lodash";
import { getErrorMessage } from "../../_helpers/Requests";
import { chunk } from "../../_helpers/chunks";
import { history } from "../../index";
import api from "../../services/api/ApiService";
import { createNotification } from "../../store/app/actions";
import MissingSubscriptionPage from "../../components/common/MissingSubscriptionPage";

const TeamsList = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true);

  const [teams, setTeams] = useState([]);
  // on Error
  const onError = (error) => {
    if (
      error ===
      "Something went wrong. You either don't have a subscription or haven't created any teams."
    ) {
      setIsSubscriptionActive(false);
    }
    dispatch(createNotification("error-toast", error, 30000));
  };
  async function getTeams() {
    try {
      const response = await api.get(`/coach/team/management`);
      if (response["status"] === 200) {
        if (response["data"] && isArray(response["data"])) {
          if (response["data"].length > 0) {
            response["data"] = response["data"].sort((a, b) =>
              a.pk > b.pk ? 1 : -1
            );
            setTeams(chunk(response["data"], 4));
            setLoading(false);
          }
        }
      } else {
        getErrorMessage(response);
        setLoading(false);
      }
    } catch (error) {
      onError(
        "Something went wrong. You either don't have a subscription or haven't created any teams."
      );
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    getTeams();
  }, []);

  if (!isSubscriptionActive) {
    return <MissingSubscriptionPage />;
  }

  return loading ? (
    <div className="flex column justifyCenter alignCenter w-100 h-100vh">
      <div className="custom_loader" />
      <p style={{ marginTop: 32 }}>{`Please wait a moment`}</p>
    </div>
  ) : (
    <div
      className="flex column w-100 h-100"
      style={{
        margin: "40px 40px 0",
        width: "calc(100vw - 240px)",
        maxHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <div className="flex alignCenter justifyStart w-100">
        <div className="flex alignCenter">
          <p>Teams</p>
        </div>
      </div>

      {teams.length > 0 && (
        <div
          className="flex column justifyCenter alignCenter w-100 h-100"
          style={{ marginTop: 40, marginBottom: 80 }}
        >
          {teams.map((item, index) => (
            <div className="flex alignCenter w-100" key={index + 1}>
              {item.length > 0 &&
                item.map((team, idx) => (
                  <div
                    key={idx + 1}
                    className="flex column alignCenter justifyCenter w-100 pointer"
                    style={{
                      width: 270,
                      height: 180,
                      background: "rgba(255, 255, 255, 0.15)",
                      margin: "0 8px",
                      borderRadius: 12,
                      boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 10px",
                    }}
                    onClick={() =>
                      history.push(
                        `/dashboard/calendar/athletes?team=${team.pk}`
                      )
                    }
                  >
                    <p style={{ fontSize: 20, textAlign: "center" }}>
                      Team "{team.name}"
                    </p>
                    <p style={{ margin: "16px 0 0", fontSize: 20 }}>
                      {team.athlete_count}{" "}
                      {team.athlete_count > 1 ? "Athletes" : "Athlete"}
                    </p>
                  </div>
                ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamsList;
