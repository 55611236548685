import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RightArrow from "../../assets/icons/chevron-right-light.svg";
import ArrowDownIcon from "../../assets/icons/down.svg";
import DownloadIcon from "../../assets/icons/download-arrow.svg";
import ArrowRight from "../../assets/icons/forward-black.svg";
import Minus from "../../assets/icons/minus.svg";
import NoAthleteData from "../../assets/icons/no_data_athlete.svg";
import RightPointingArrow from "../../assets/icons/right-pointing-arrow.svg";
import AthletesSelector from "../../components/dropdowns/AthletesSelector";
import AthleteTabs from "../../components/tabs/TabLayout";
import { history } from "../../index";
import api from "../../services/api/ApiService";
import { createNotification } from "../../store/app/actions";
import { colors } from "./Athletes";
// PHASES ICONS - INACTIVE
import { chunk } from "../../_helpers/chunks";
import { checkins } from "../../_helpers/constants";
import Phase1Icon from "../../assets/cyclephases/1.svg";
import Phase10Icon from "../../assets/cyclephases/10.svg";
import Phase2Icon from "../../assets/cyclephases/2.svg";
import Phase3Icon from "../../assets/cyclephases/3.svg";
import Phase4Icon from "../../assets/cyclephases/4.svg";
import Phase5Icon from "../../assets/cyclephases/5.svg";
import Phase6Icon from "../../assets/cyclephases/6.svg";
import Phase7Icon from "../../assets/cyclephases/7.svg";
import Phase8Icon from "../../assets/cyclephases/8.svg";
import Phase9Icon from "../../assets/cyclephases/9.svg";
import AthleteCalendar from "../../components/calendar/AthleteCalendar";
import { trackGAEvent } from "../../services/analytics/GoogleAnalytics";
import { Mixpanel } from "../../services/analytics/Mixpanel";
import Graph from "./Graph";
import SeparatedGraphs from "./SeparatedGraphs";
import { useLayoutEffect } from "react";

const AthleteGeneral = () => {
  const {
    location: { search },
  } = history;
  const dispatch = useDispatch();
  const athleteid = Number(search.replace("?id=", ""));
  const teamid = window.localStorage.getItem("team_Id");
  const isCalendar = window.localStorage.getItem("is_calendar");

  const { user } = useSelector((state) => state.app);
  let [symptomsGraphStorage, setSymptomsGraphStorage] = useState(null);

  let [tabIndex, setTabIndex] = useState(isCalendar === "true" ? 1 : 0);
  // States
  const [initLoading, setInitLoading] = useState(false);
  const [dateLoader, setDateLoader] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showCycleGraph, setCycleGraph] = useState(true);
  const [prompt, setPrompt] = useState(false);
  const [symptomsGraph, setSymptomsGraph] = useState(null);
  const [generatedDates, setGeneratedDates] = useState([]);
  const [displayGraphsData, setDisplayGraphsData] = useState([]);

  const [separatedGraphs, setSeparatedGraphs] = useState(false);
  const [athlete_details, setAthleteDetails] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [phaseIcon, setPhaseIcon] = useState(null);
  const [checkinsData, setCheckins] = useState([]);
  let [graph_pixel_width, setGraphPixelWidth] = useState(0);
  const [widgetWidth, setWidth] = useState(0);
  const [defaultActive, setDefaultActive] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [datesList, setDates] = useState([]);
  const [reportEndpoint, setReportEndpoint] = useState(null);
  const [checkinTimes, setCheckinTimes] = useState([
    { id: "today", label: "Today", active: true, width: 60 },
    { id: "last_week", label: "Last Week", active: false, width: 80 },
    { id: "last_month", label: "Last Month", active: false, width: 80 },
    { id: "last_year", label: "Last Year", active: false, width: 74 },
    { id: "all_time", label: "All Time", active: false, width: 70 },
  ]);

  // DATA
  // get Alert based on Max severity
  const getAlertData = (alerts) => {
    const max_severity = Math.max(...alerts.map((o) => o.severity));
    return alerts.filter((item) => item.severity === max_severity);
  };
  // Get phase icon
  const getPhaseIcon = (data) => {
    if (data) {
      if (
        data.phase.toLowerCase().trimStart().trimEnd() ===
        "early follicular phase"
      ) {
        return Phase1Icon;
      } else if (
        data.phase.toLowerCase().trimStart().trimEnd() ===
        "mid follicular phase"
      ) {
        return Phase2Icon;
      } else if (
        data.phase.toLowerCase().trimStart().trimEnd() === "ovulatory phase"
      ) {
        return Phase3Icon;
      } else if (
        data.phase.toLowerCase().trimStart().trimEnd() === "mid luteal phase"
      ) {
        return Phase4Icon;
      } else if (
        data.phase.toLowerCase().trimStart().trimEnd() === "late luteal phase"
      ) {
        return Phase5Icon;
      } else if (
        data.phase.toLowerCase().trimStart().trimEnd() === "period phase"
      ) {
        return Phase6Icon;
      } else if (
        data.phase.toLowerCase().trimStart().trimEnd() === "menopause"
      ) {
        return Phase7Icon;
      } else if (
        data.phase.toLowerCase().trimStart().trimEnd() === "active phase"
      ) {
        return Phase8Icon;
      } else if (
        data.phase.toLowerCase().trimStart().trimEnd() === "inactive phase"
      ) {
        return Phase10Icon;
      } else if (
        data.phase.toLowerCase().trimStart().trimEnd() ===
        "progestin-only contraceptive phase"
      ) {
        return Phase9Icon;
      }
    }
  };

  const showGraphs = () => {
    const activeStatus = checkinTimes.filter(
      (item) => item.id !== "today" && item.active
    );
    if (activeStatus.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getReadinessScore = (scores) => {
    if (scores && scores.length > 0) {
      const permittedValue = scores.filter(
        (value) => value.text === "Readiness Score"
      )[0];
      return Number(permittedValue["value"]);
    } else {
      return "";
    }
  };

  const getBackgroundColor = (red_to_green, number) => {
    if (red_to_green) {
      return `${
        number > 0 && number < 33
          ? "#F8484E"
          : number >= 33 && number <= 65
          ? "#EFE47F"
          : number > 65 && number <= 100
          ? "#70C089"
          : null
      }`;
    } else {
      return `${
        number > 0 && number < 33
          ? "#70C089"
          : number >= 33 && number <= 65
          ? "#EFE47F"
          : number > 65 && number <= 100
          ? "#F8484E"
          : null
      }`;
    }
  };

  useEffect(() => {
    if (symptomsGraph && Object.keys(symptomsGraph.symptom_data).length !== 0) {
      const dataLength =
        symptomsGraph.symptom_data[Object.keys(symptomsGraph.symptom_data)[0]]
          .data.length;
      if (dataLength) {
        const dates = Array.from({ length: dataLength }, (empty, index) =>
          moment()
            .subtract(index, "days")
            .format(dataLength > 31 ? "YYYY-MM-DD" : "MMM DD")
        ).reverse();
        setGeneratedDates(dates);
      }
    }
  }, [symptomsGraph, checkinTimes]);

  useEffect(() => {
    if (generatedDates.length > 0) {
      const newArrOfObjects = [];
      generatedDates.forEach((date, i) => {
        let dateWithData = { date };
        for (const key in symptomsGraph.symptom_data) {
          dateWithData = {
            ...dateWithData,
            [symptomsGraph.symptom_data[key].label.toLowerCase()]: symptomsGraph
              .symptom_data[key].data[i]
              ? symptomsGraph.symptom_data[key].data[i]
              : 0,
          };
        }
        newArrOfObjects.push(dateWithData);
      });
      setDisplayGraphsData(newArrOfObjects);
    }
  }, [generatedDates]);

  const getDateRange = (timer) => {
    setDateLoader(true);
    setSymptomsGraph(null);
    setSymptomsGraphStorage(null);
    setSeparatedGraphs(false);
    setDefaultActive(
      timer === "last_week" || timer === "last_month" || timer === "last_year"
        ? false
        : true
    );
    api
      .get(`coach/team/checkin-scores/${athleteid}?date_range=${timer}`)
      .then((result) => {
        const checkinResponse = removeZeroValues(result.data);
        for (const key in checkinResponse) {
          if (
            Array.isArray(checkinResponse[key]) &&
            checkinResponse[key][0] === 0
          )
            delete checkinResponse[key];
        }
        const KEYS = Object.keys(checkinResponse);
        const VALUES = Object.values(checkinResponse);
        const response = KEYS.map((key, index) => {
          const obj = checkins.filter(
            (checkinEntity) => checkinEntity.id === key
          );
          return {
            ...obj[0],
            value: VALUES[index]["score"],
            active:
              timer === "last_week" ||
              timer === "last_month" ||
              timer === "last_year"
                ? false
                : true,
            red_to_green: VALUES[index]["red_to_green"],
          };
        });
        setCheckins(response);
        api
          .get(`coach/team/checkin-graph-data/${athleteid}?date_range=${timer}`)
          .then((res) => {
            setDateLoader(false);
            setSymptomsGraph(res.data);
            setSymptomsGraphStorage(res.data);
          })
          .catch((error) => {
            setDateLoader(false);
            onFailure(error.message);
          });
      })
      .catch((error) => {
        setDateLoader(false);
        onFailure(error.message);
      });
  };

  useLayoutEffect(() => {
    getDateRange("today");
  }, []);

  const onChangeAthlete = (athleteId) =>
    isCalendar
      ? history.push(`/dashboard/calendar?id=${athleteId}`)
      : history.push(`/dashboard/athlete?id=${athleteId}`); // onChange athlete id
  const onFailure = (error) =>
    dispatch(createNotification("error-toast", error)); // onFailure

  function removeZeroValues(obj) {
    for (var key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (Object.keys(obj[key]).length > 0) {
          if (
            Number(obj[key]["score"]) === 0 ||
            obj[key]["score"] === "0" ||
            obj[key]["score"] === "0%"
          ) {
            delete obj[key]; // Remove values that are 0 or 0%
          }
        }
      }
    }
    return obj;
  }

  useEffect(() => {
    if (user.coach.email) {
      Mixpanel.identify(user.coach.email);
      Mixpanel.track("View Athlete", {
        coach: user.coach.seg_id,
        team: teamid,
        athlete: athleteid,
        timestamp: moment().format("YYYY-MM-DDTHH:mm:ss"),
      });
      trackGAEvent.identify(user.coach.email);
      trackGAEvent.track("View Athlete", {
        coach: user.coach.seg_id,
        team: teamid,
        athlete: athleteid,
        timestamp: moment().format("YYYY-MM-DDTHH:mm:ss"),
      });
    }
    let parentContainer = document.getElementById("athlete_details");
    if (parentContainer) {
      setWidth((parentContainer.clientWidth - 32) * 0.33 - 48 || 0);
    }
    const calendar = moment(new Date()).format("DD/MM/YYYY");
    if (athleteid && teamid && calendar) {
      window.localStorage.setItem("calendar_date", new Date());
      setInitLoading(true);
      api
        .get(`coach/team/${teamid}/blocks/overview?date=${calendar}`)
        .then((response) => {
          const { overview } = response.data;
          if (overview && overview.length) {
            const allAthletes = overview.map((value) => value.athlete);
            setAthletes(allAthletes);
            api
              .get(`coach/team/${teamid}/athlete/${athleteid}?date=${calendar}`)
              .then((result) => {
                setPhaseIcon(getPhaseIcon(result.data.cycle));
                setAthleteDetails(result.data);
                setInitLoading(false);
                api
                  .get(
                    `coach/team/checkin-scores/${athleteid}?date_range=today`
                  )
                  .then((result) => {
                    const checkinResponse = removeZeroValues(result.data);
                    const KEYS = Object.keys(checkinResponse);
                    const VALUES = Object.values(checkinResponse);
                    const response = KEYS.map((key, index) => {
                      const obj = checkins.filter(
                        (checkinEntity) => checkinEntity.id === key
                      );
                      return {
                        ...obj[0],
                        value: VALUES[index]["score"]
                          ? VALUES[index]["score"]
                          : "0%",
                        active: true,
                        red_to_green: VALUES[index]["red_to_green"]
                          ? VALUES[index]["red_to_green"]
                          : true,
                      };
                    });
                    setCheckins(response);
                  })
                  .catch((error) => {
                    setDateLoader(false);
                    onFailure(error.message);
                  });
              })
              .catch((error) => {
                setInitLoading(false);
                onFailure(error.message);
              });
          } else {
            setInitLoading(false);
            onFailure("No athletes found in this team.");
          }
        })
        .catch((error) => {
          setInitLoading(false);
          onFailure(error.message);
        });
    }
    window.addEventListener(
      "resize",
      () => {
        let offsetWidth = document.getElementById("right_graph_area");
        if (offsetWidth) {
          setWidth(offsetWidth.clientWidth - 48 || 0);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (tabIndex === 2) {
      setReportLoading(true);
      api
        .get(`coach/team/${teamid}/athlete/${athleteid}/blocks/reports`)
        .then((result) => {
          setReportLoading(false);
          setDates(chunk(result.data.reports.data, 5));
          setReportEndpoint(result.data.reports.endpoint);
        })
        .catch((error) => {
          setReportLoading(false);
          onFailure(error.message);
        });
    }
  }, [tabIndex]);

  useEffect(() => {
    if (widgetWidth > 0 && athlete_details) {
      let graphWidth =
        widgetWidth / athlete_details["cycle"]["cycle_length_phases"];
      setGraphPixelWidth(graphWidth.toFixed(2));
    }
  }, [athlete_details, widgetWidth]);

  const tiledPosition = () => {
    if (widgetWidth > 0 && athlete_details) {
      let graphWidth =
        widgetWidth / athlete_details["cycle"]["cycle_length_phases"];
      return (athlete_details["cycle"]["current_day"] * graphWidth).toFixed(2);
    }
  };

  const textPosition = () => {
    if (widgetWidth > 0 && athlete_details) {
      let graphWidth =
        widgetWidth / athlete_details["cycle"]["cycle_length_phases"];
      let half_phase = athlete_details["cycle"]["cycle_length_phases"] / 2;
      if (athlete_details["cycle"]["current_day"] > half_phase) {
        const remain_length =
          athlete_details["cycle"]["cycle_length_phases"] -
          athlete_details["cycle"]["current_day"];
        return { right: (remain_length * graphWidth).toFixed(2), left: 0 };
      } else if (athlete_details["cycle"]["current_day"] < half_phase) {
        return {
          left: (athlete_details["cycle"]["current_day"] * graphWidth).toFixed(
            2
          ),
          right: 0,
        };
      } else if (
        athlete_details["cycle"]["current_day"] ===
        athlete_details["cycle"]["cycle_length_phases"]
      ) {
        return { left: 0, right: 0 };
      } else {
        return { left: 0, right: 0 };
      }
    } else {
      return { left: 0, right: 0 };
    }
  };

  function removeSymbol(value) {
    if (typeof value === "string") {
      // eslint-disable-next-line
      if (/[$-/:-?{-~!"^_`\[\]]/.test(value)) {
        value = value.replace("%", "");
      }
    }

    return value;
  }

  function getCurrentActiveDateRange() {
    if (symptomsGraph) {
      const datesArray = Object.values(symptomsGraph["phases"]).flat(1);
      const dateRange = checkinTimes.filter((item) => item.active);
      if (dateRange && dateRange.length > 0) {
        if (dateRange[0]["id"] === "last_week") return true;
        else if (dateRange[0]["id"] === "all_time" && datesArray.length < 8)
          return true;
      } else {
        return false;
      }
    }
  }

  const getSleepHours = (score) => Math.round((score / 100) * 8).toFixed(1);

  return (
    <div
      className="flex column relative w-100 h-100 athletes_container"
      id="athlete_details"
    >
      {/* Header */}
      <div className="flex justifyBetween alignCenter header">
        {/* Athletes Selector */}
        <AthletesSelector
          isLoading={initLoading}
          athletes={athletes && athletes.length > 0 ? athletes : []}
          selectedId={athleteid}
          callAction={onChangeAthlete}
        />
        {/* Breadcrumb */}
        <div className="flex alignCenter">
          {isCalendar ? (
            <p
              className="pointer"
              onClick={() => history.push("/dashboard/calendar/teams")}
            >
              Teams
            </p>
          ) : (
            <p
              className="pointer"
              onClick={() => history.push("/dashboard/athletes")}
            >
              Home
            </p>
          )}
          <img
            src={ArrowRight}
            alt="arrow right"
            height={12}
            width={12}
            style={{ margin: "0 4px" }}
          />
          <p
            onClick={() =>
              isCalendar &&
              history.push(`/dashboard/calendar/athletes?team=${teamid}`)
            }
            style={{ cursor: isCalendar ? "pointer" : "default" }}
          >
            {" "}
            {isCalendar
              ? "Athletes"
              : window.localStorage.getItem("team_name") || ""}
          </p>
        </div>
      </div>

      {/* Table Loading */}
      {initLoading && (
        <div
          className="flex column alignCenter justifyCenter w-100 h-100"
          style={{ marginTop: 103, minHeight: "calc(100vh - 103px)" }}
        >
          <div className="custom_loader" />
          <p style={{ marginTop: 32 }}>{`Please wait a moment`}</p>
        </div>
      )}

      {!initLoading && athlete_details && (
        <div
          className="flex justifyBetween alignStart w-100"
          style={{ margin: "120px auto 0", padding: 32 }}
        >
          {/* Left */}
          <div
            className="flex column justifyStart alignCenter w-100"
            style={{ width: "65%" }}
          >
            {/* Athlete Tabs */}
            <div
              className="flex alignCenter justifyStart w-100"
              style={{ margin: "8px 0 24px" }}
            >
              <AthleteTabs
                activeIndex={tabIndex}
                tabs={["Athlete Data", "Calendar", "Reports"]}
                callAction={(tab_index) => setTabIndex(tab_index)}
                activeColor="#08AFA8"
              />
            </div>
            {/* Profile Card */}
            {tabIndex === 0 && (
              <div
                className="flex justifyBetween alignCenter w-100 h-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 24,
                }}
              >
                <div className="flex alignCenter">
                  <img
                    src={athlete_details["athlete"]["profile_img_url"]}
                    alt="athlete profile"
                    height={100}
                    width={100}
                    style={{
                      borderRadius: "50%",
                      border: "5px solid #70C089",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    className="flex column alignStart justifyStart"
                    style={{ marginLeft: 24 }}
                  >
                    <p>{window.localStorage.getItem("team_name")}</p>
                    <p
                      style={{ margin: "4px 0 12px" }}
                    >{`${athlete_details["athlete"]["first_name"]} ${athlete_details["athlete"]["last_name"]}`}</p>
                    {athlete_details["alert"] &&
                      athlete_details["alert"].length > 0 && (
                        <p>{`${
                          getAlertData(athlete_details["alert"])[0][
                            "severity"
                          ] === 1
                            ? "Mild"
                            : getAlertData(athlete_details["alert"])[0][
                                "severity"
                              ] === 3
                            ? "Moderate"
                            : getAlertData(athlete_details["alert"])[0][
                                "severity"
                              ] === 5
                            ? "Severe"
                            : null
                        } ${
                          getAlertData(athlete_details["alert"])[0]["name"]
                        }`}</p>
                      )}
                  </div>
                </div>
                <div
                  className="flex column alignCenter justifyBetween h-100"
                  style={{ minHeight: 94 }}
                >
                  {getReadinessScore(athlete_details.scores) > 0 ? (
                    <div className="flex column alignCenter justifyCenter relative progressCircle">
                      <div className="outer">
                        <div className="flex alignCenter justifyCenter inter">
                          <p style={{ color: "#FFFFFF" }}>
                            {getReadinessScore(athlete_details.scores).toFixed(
                              0
                            )}
                          </p>
                        </div>
                        <svg
                          xmlns="https://w3.org/2000/svg"
                          version="1.1"
                          width="60px"
                          height="60px"
                        >
                          <circle
                            cx="30"
                            cy="30"
                            r="25"
                            strokeLinecap="round"
                            style={{
                              stroke:
                                getReadinessScore(athlete_details.scores) > 0 &&
                                getReadinessScore(athlete_details.scores) <= 30
                                  ? colors[0]
                                  : getReadinessScore(athlete_details.scores) >
                                      32 &&
                                    getReadinessScore(athlete_details.scores) <=
                                      65
                                  ? colors[1]
                                  : getReadinessScore(athlete_details.scores) >
                                      65 &&
                                    getReadinessScore(athlete_details.scores) <=
                                      100
                                  ? colors[2]
                                  : null,
                              strokeDashoffset:
                                150 -
                                (getReadinessScore(athlete_details.scores) *
                                  150) /
                                  100,
                              opacity:
                                150 -
                                  (getReadinessScore(athlete_details.scores) *
                                    150) /
                                    100 ===
                                150
                                  ? 0
                                  : 1,
                            }}
                          ></circle>
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                  <svg
                    width="45"
                    height="23"
                    viewBox="0 0 45 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5 4.5L7 20H38L22.5 4.5Z"
                      fill="#70C089"
                      stroke="#70C089"
                      strokewidth="5"
                      strokelinecap="round"
                    />
                  </svg>
                </div>
              </div>
            )}
            {/* Checkins */}
            {checkinsData && checkinsData.length > 0 && tabIndex === 0 ? (
              <div
                className="flex justifyBetween alignStart w-100"
                style={{ marginTop: 24 }}
              >
                <div
                  className="flex column justifyCenter alignCenter w-100"
                  style={{
                    width: "25%",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: 12,
                    padding: 20,
                  }}
                >
                  {checkinsData.map((checkin, index) => (
                    <div
                      key={index + 1}
                      className="flex alignCenter justifyCenter w-100 pointer"
                      onClick={() => {
                        let updatedCheckins = checkinsData.map(
                          (checkin, idx) => {
                            return {
                              ...checkin,
                              active:
                                idx === index
                                  ? !checkin["active"]
                                  : checkin["active"],
                            };
                          }
                        );
                        setCheckins(updatedCheckins);
                        setDefaultActive(true);
                        let hideCheckins = updatedCheckins.filter(
                          (checkin) => checkin.active === false
                        );
                        if (hideCheckins.length > 0 && symptomsGraphStorage) {
                          let ids = hideCheckins.map((item) => item.id);
                          const graphsDataInit = symptomsGraphStorage;
                          let phasesList = graphsDataInit["phases"];
                          let symptomsDataList = graphsDataInit["symptom_data"];
                          for (var i = 0; i < ids.length; i++) {
                            symptomsDataList = Object.keys(symptomsDataList)
                              // eslint-disable-next-line
                              .filter((key) => key !== ids[i])
                              // eslint-disable-next-line
                              .reduce((obj, key) => {
                                obj[key] = symptomsDataList[key];
                                return obj;
                              }, {});
                          }
                          setSymptomsGraph({
                            phases: phasesList,
                            symptom_data: symptomsDataList,
                          });
                        } else {
                          setSymptomsGraph(symptomsGraphStorage);
                        }
                      }}
                      style={{
                        height: 40,
                        background: checkin["active"]
                          ? checkin["backgroundColor"]
                          : null,
                        border: checkin["active"]
                          ? `2px solid ${checkin["backgroundColor"]}`
                          : "2px solid #FFFFFF",
                        margin: "12px 0",
                        borderRadius: 100,
                      }}
                    >
                      <p
                        style={{
                          color: checkin["active"]
                            ? checkin["textcolor"]
                            : "#FFFFFF",
                        }}
                      >
                        {checkin["label"]}
                      </p>
                    </div>
                  ))}
                </div>
                <div
                  className="flex column justifyCenter alignCenter w-100"
                  style={{
                    width: "73%",
                    background: "rgba(255, 255, 255, 0.1)",
                    borderRadius: 12,
                    padding: "24px 12px 16px",
                  }}
                >
                  {/* Timers */}
                  <div className="flex alignCenter justifyBetween w-100">
                    <div
                      className="flex alignCenter w-100"
                      style={{
                        border: "1px solid #FFFFFF",
                        borderRadius: 100,
                        minWidth: 365,
                        maxWidth: 365,
                      }}
                    >
                      {checkinTimes.map((timer, index) => (
                        <div
                          key={index + 1}
                          onClick={() => {
                            setCheckinTimes(
                              checkinTimes.map((timer, idx) => {
                                return {
                                  ...timer,
                                  active: idx === index ? true : false,
                                };
                              })
                            );
                            getDateRange(timer.id);
                          }}
                          className="flex alignCenter justifyCenter w-100 pointer"
                          style={{
                            height: 36,
                            minWidth: timer.width,
                            maxWidth: timer.width,
                            background: timer["active"] ? "#FFFFFF" : null,
                            borderTopLeftRadius:
                              timer["active"] && index === 0 ? 100 : 0,
                            borderBottomLeftRadius:
                              timer["active"] && index === 0 ? 100 : 0,
                            borderTopRightRadius:
                              timer["active"] &&
                              index + 1 === checkinTimes.length
                                ? 100
                                : 0,
                            borderBottomRightRadius:
                              timer["active"] &&
                              index + 1 === checkinTimes.length
                                ? 100
                                : 0,
                            borderLeft: index !== 0 ? "1px solid #FFFFFF" : 0,
                          }}
                        >
                          <p
                            style={{
                              color: timer["active"] ? "#000000" : "#FFFFFF",
                              fontSize: 13,
                            }}
                          >
                            {timer["label"]}
                          </p>
                        </div>
                      ))}
                    </div>
                    {showGraphs() && (
                      <div className="flex alignCenter justifyEnd w-100">
                        <div
                          className="flex alignCenter"
                          onClick={() => setSeparatedGraphs(!separatedGraphs)}
                        >
                          <p
                            className="pointer"
                            style={{
                              textTransform: "uppercase",
                              marginRight: 8,
                              wordSpacing: 4,
                              letterSpacing: 0.5,
                            }}
                          >
                            {!separatedGraphs ? "View Details" : "See Overview"}
                          </p>
                          <img
                            src={RightPointingArrow}
                            alt="right pointing arrow"
                            height={24}
                            width={24}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Weekly, Monthly, Yearly, All time Graphs */}
                  {/* {showGraphs() && <div className="flex column alignCenter justifyStart w-100" style={{ margin: '24px 0 16px' }}>
                                        <p style={{ margin: '0px 8px 0 10px' }}>Data collected over time is calculated by average, and based on the frequency of check-ins</p>
                                    </div>} */}

                  {/* {showGraphs() && <div className="w-100" style={{ height: 40 }} />} */}

                  {/* Graphs Loader */}
                  {dateLoader && (
                    <div
                      className="flex column alignCenter justifyCenter w-100 h-100"
                      style={{ height: 400 }}
                    >
                      <div className="custom_loader" />
                      <p style={{ marginTop: 32 }}>{`Please wait a moment`}</p>
                    </div>
                  )}

                  {/* Stackline Weekly Graph */}
                  {!dateLoader &&
                  !separatedGraphs &&
                  symptomsGraph &&
                  getCurrentActiveDateRange() &&
                  showGraphs() ? (
                    <Graph
                      checkinsData={checkinsData}
                      displayGraphsData={displayGraphsData}
                    />
                  ) : null}
                  {/* Stackline Monthly, Yearly Graph */}
                  {!dateLoader &&
                  !separatedGraphs &&
                  symptomsGraph &&
                  !getCurrentActiveDateRange() &&
                  showGraphs() ? (
                    <Graph
                      checkinsData={checkinsData}
                      displayGraphsData={displayGraphsData}
                    />
                  ) : null}
                  {/* Separated Graphs */}
                  {!dateLoader && separatedGraphs && symptomsGraph && (
                    <SeparatedGraphs
                      showGraph={defaultActive}
                      data={symptomsGraph.symptom_data}
                      displayGraphsData={displayGraphsData}
                      checkinsData={checkinsData}
                    />
                  )}

                  {/* Progress */}
                  {!dateLoader && !separatedGraphs && (
                    <div
                      className="flex column alignStart justifyStart w-100"
                      style={{ marginTop: 32 }}
                    >
                      {checkinsData.map(
                        (checkin, index) =>
                          checkin.active && (
                            <div
                              key={index + 1}
                              className="flex alignCenter justifyBetween w-100"
                              style={{ margin: "8px 0" }}
                            >
                              <p
                                style={{
                                  color: "inheirt",
                                  fontSize: 12,
                                  width: 160,
                                }}
                              >
                                {checkin["label"]}
                              </p>
                              <div
                                className="relative w-100"
                                style={{
                                  height: 32,
                                  background: "rgba(255, 255, 255, 0.2)",
                                  borderRadius: 100,
                                }}
                              >
                                <div
                                  className="absolute"
                                  style={{
                                    width: checkin["value"],
                                    borderRadius: 100,
                                    padding:
                                      Number(removeSymbol(checkin["value"])) ===
                                      0
                                        ? null
                                        : "0.01em 10px",
                                    height: "100%",
                                    background: getBackgroundColor(
                                      checkin.red_to_green,
                                      Number(removeSymbol(checkin["value"]))
                                    ),
                                  }}
                                ></div>
                                <div
                                  className="flex alignCenter absolute h-100"
                                  style={{ zIndex: 100, marginLeft: 24 }}
                                >
                                  <p
                                    style={{
                                      color:
                                        Number(removeSymbol(checkin["value"])) >
                                        0
                                          ? "#000000"
                                          : "#FFFFFF",
                                      fontSize: 12,
                                    }}
                                  >
                                    {checkin.id === "sleep_hours"
                                      ? getSleepHours(
                                          Number(removeSymbol(checkin["value"]))
                                        ) > 0
                                        ? `${
                                            getSleepHours(
                                              Number(
                                                removeSymbol(checkin["value"])
                                              )
                                            ) > 1
                                              ? getSleepHours(
                                                  Number(
                                                    removeSymbol(
                                                      checkin["value"]
                                                    )
                                                  )
                                                ) + " hours"
                                              : getSleepHours(
                                                  Number(
                                                    removeSymbol(
                                                      checkin["value"]
                                                    )
                                                  )
                                                ) + " hour"
                                          }`
                                        : "None"
                                      : Number(removeSymbol(checkin["value"])) >
                                        0
                                      ? checkin["value"]
                                      : "None"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : tabIndex === 0 ? (
              <div
                className="flex column justifyStart alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  marginTop: 24,
                  padding: "24px 12px 16px",
                  minHeight: 800,
                }}
              >
                {/* Timers */}
                <div className="flex alignStart w-100">
                  <div
                    className="flex alignCenter"
                    style={{
                      border: "1px solid #FFFFFF",
                      borderRadius: 100,
                      width: 365,
                      maxWidth: 365,
                    }}
                  >
                    {checkinTimes.map((timer, index) => (
                      <div
                        key={index + 1}
                        onClick={() => {
                          setCheckinTimes(
                            checkinTimes.map((timer, idx) => {
                              return {
                                ...timer,
                                active: idx === index ? true : false,
                              };
                            })
                          );
                          getDateRange(timer.id);
                        }}
                        className="flex alignCenter justifyCenter w-100 pointer"
                        style={{
                          height: 36,
                          minWidth: timer.width,
                          background: timer["active"] ? "#FFFFFF" : null,
                          borderTopLeftRadius:
                            timer["active"] && index === 0 ? 100 : 0,
                          borderBottomLeftRadius:
                            timer["active"] && index === 0 ? 100 : 0,
                          borderTopRightRadius:
                            timer["active"] && index + 1 === checkinTimes.length
                              ? 100
                              : 0,
                          borderBottomRightRadius:
                            timer["active"] && index + 1 === checkinTimes.length
                              ? 100
                              : 0,
                          borderLeft: index !== 0 ? "1px solid #FFFFFF" : 0,
                        }}
                      >
                        <p
                          style={{
                            color: timer["active"] ? "#000000" : "#FFFFFF",
                            fontSize: 13,
                          }}
                        >
                          {timer["label"]}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <img
                  src={NoAthleteData}
                  alt="no data athlete"
                  height={220}
                  width={217}
                  style={{ margin: "64px auto 0" }}
                />
                <h2 style={{ color: "inherit", margin: "32px auto 0px" }}>
                  Oh no!
                </h2>
                <p
                  style={{ color: "inherit", margin: "24px auto 4px" }}
                >{`${athlete_details["athlete"]["first_name"]} ${athlete_details["athlete"]["last_name"]} has not checked in today`}</p>
                <p style={{ color: "inherit", margin: "4px auto 32px" }}>
                  Prompt them, to see their data
                </p>
                {!submitting && prompt && (
                  <p
                    style={{
                      padding: "12px 24px",
                      borderRadius: 12,
                      background: "#27AE60",
                    }}
                  >{`You prompted ${athlete_details["athlete"]["first_name"]} ${athlete_details["athlete"]["last_name"]}`}</p>
                )}
                {!prompt && (
                  <div
                    className="white-button"
                    onClick={() => {
                      setSubmitting(true);
                      api
                        .post(`coach/team/checkin-prompt/${athleteid}`)
                        .then((response) => {
                          if (response.status === 200) {
                            setSubmitting(false);
                            setPrompt(true);
                          } else {
                            setSubmitting(false);
                            onFailure(
                              "Something went wrong, please contact to support@wild.ai"
                            );
                          }
                        })
                        .catch((error) => {
                          setSubmitting(false);
                          onFailure(error.message);
                        });
                    }}
                    style={{ maxWidth: 287 }}
                  >
                    {submitting ? (
                      <div className="flex justifyCenter alignCenter">
                        <div className="custom_button_loader" />
                      </div>
                    ) : (
                      <div className="flex alignCenter">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ cursor: "pointer" }}
                        >
                          <path
                            d="M13 15V6.99998M13 15L18.5039 18.145C19.1705 18.526 20 18.0446 20 17.2768V4.72316C20 3.95533 19.1705 3.47397 18.5039 3.85491L13 6.99998M13 15H10M13 6.99998H7C4.79086 6.99998 3 8.79084 3 11C3 13.2091 4.79086 15 7 15M7 15V19.5C7 20.3284 7.67157 21 8.5 21C9.32843 21 10 20.3284 10 19.5V15M7 15H10"
                            stroke="black"
                            strokewidth="2"
                            strokelinecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p
                          style={{ marginLeft: 24 }}
                        >{`Prompt ${athlete_details["athlete"]["first_name"]} ${athlete_details["athlete"]["last_name"]}`}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {/* Right */}
          <div
            className="flex column justifyStart alignCenter w-100"
            id="right_graph_area"
            style={{ width: "33%" }}
          >
            {/* Checkins Card */}
            {tabIndex === 0 && (
              <div
                className="flex column justifyCenter alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: 20,
                }}
              >
                <div className="flex  alignCenter justifyBetween w-100">
                  {/* Cycle phase */}
                  <div
                    className="flex column alignCenter justifyCenter"
                    style={{
                      padding: 12,
                      background: "rgba(0, 0, 0, 0.3)",
                      width: "100%",
                      minHeight: 150,
                      borderRadius: 12,
                    }}
                  >
                    {phaseIcon && (
                      <img
                        src={phaseIcon}
                        alt="cycle phase icon"
                        height={68}
                        width={68}
                      />
                    )}
                    <p
                      style={{
                        fontSize: 14,
                        textTransform: "uppercase",
                        margin: "8px 0 0",
                        textAlign: "center",
                      }}
                    >
                      {athlete_details.cycle.phase}
                    </p>
                  </div>
                  <div style={{ width: 32 }} />
                  {/* Checkin completed / total checkins */}
                  <div
                    className="flex column alignCenter justifyCenter"
                    style={{
                      padding: 12,
                      background: "rgba(0, 0, 0, 0.3)",
                      width: "100%",
                      minHeight: 150,
                      borderRadius: 12,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 16,
                        textTransform: "uppercase",
                        textAlign: "center",
                        margin: "8px 0 0",
                      }}
                    >{`${athlete_details.check_ins.completed_checkins} / ${athlete_details.check_ins.total_checkins}`}</p>
                    <p
                      style={{
                        textTransform: "uppercase",
                        textAlign: "center",
                        margin: "8px 0 0",
                      }}
                    >
                      Check-Ins
                    </p>
                  </div>
                </div>
                <div className="w-100" style={{ height: 16 }} />
                <div className="flex alignCenter justifyBetween w-100">
                  {/* Days to next phase */}
                  <div
                    className="flex column alignCenter justifyCenter"
                    style={{
                      padding: 12,
                      background: "rgba(0, 0, 0, 0.3)",
                      width: "100%",
                      minHeight: 150,
                      borderRadius: 12,
                    }}
                  >
                    <p style={{ fontSize: 16 }}>
                      {athlete_details.cycle.days_to_next_phase > 1
                        ? `${athlete_details.cycle.days_to_next_phase} days`
                        : `${
                            athlete_details.cycle.days_to_next_phase || 0
                          } day`}
                    </p>
                    <p
                      style={{
                        fontSize: 16,
                        textTransform: "uppercase",
                        textAlign: "center",
                        margin: "8px 0 0",
                      }}
                    >
                      To next phase
                    </p>
                  </div>
                  <div style={{ width: 32 }} />
                  {/* Average cycle length */}
                  <div
                    className="flex column alignCenter justifyCenter"
                    style={{
                      padding: 12,
                      background: "rgba(0, 0, 0, 0.3)",
                      width: "100%",
                      minHeight: 150,
                      borderRadius: 12,
                    }}
                  >
                    <p style={{ fontSize: 16 }}>
                      {athlete_details.cycle.average_cycle_length > 1
                        ? `${athlete_details.cycle.average_cycle_length} days`
                        : `${
                            athlete_details.cycle.average_cycle_length || 0
                          } day`}
                    </p>
                    <p
                      style={{
                        textTransform: "uppercase",
                        textAlign: "center",
                        margin: "8px 0 0",
                      }}
                    >
                      Average Cycle Length
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* Athlete Information */}
            {/* {tabIndex === 2 &&
                            <div className="flex column justifyCenter alignCenter w-100" style={{ background: 'rgba(255, 255, 255, 0.1)', borderRadius: 12, padding: 20 }}>
                                <div className="flex justifyBetween alignCenter w-100">
                                    <p style={{ fontSize: 16 }}>Athlete Info</p>
                                    {showCycleGraph ?
                                        <img src={Minus} alt="minus icon" width={24} height={24} onClick={() => setCycleGraph(!showCycleGraph)} className="pointer" />
                                        : <img src={ArrowDownIcon} alt="arrow up icon" width={16} height={16} onClick={() => setCycleGraph(!showCycleGraph)} className="pointer" />}
                                </div>
                                <br />
                                <div className="flex column alignStart w-100">
                                    <div className="flex alignCenter justifyBetween w-100" style={{ padding: '16px 12px', background: 'rgba(0, 0, 0, 0.3)', borderRadius: 12 }}>
                                        <p style={{ fontSize: 14, margin: "8px 0 0", textAlign: 'center', color: '#3BF6EE' }}>Member since</p>
                                        <p style={{ fontSize: 14, margin: "8px 0 0", textAlign: 'center' }}>{''}</p>
                                    </div>
                                    <br />
                                    <div className="flex alignCenter justifyBetween w-100" style={{ padding: '16px 12px', background: 'rgba(0, 0, 0, 0.3)', borderRadius: 12 }}>
                                        <p style={{ fontSize: 14, margin: "8px 0 0", textAlign: 'center', color: '#3BF6EE' }}>Email</p>
                                        <p style={{ fontSize: 14, margin: "8px 0 0", textAlign: 'center' }}>{''}</p>
                                    </div>
                                    <br />
                                    <div className="flex alignCenter justifyBetween w-100" style={{ padding: '16px 12px', background: 'rgba(0, 0, 0, 0.3)', borderRadius: 12 }}>
                                        <p style={{ fontSize: 14, margin: "8px 0 0", textAlign: 'center', color: '#3BF6EE' }}>Status</p>
                                        <p style={{ fontSize: 14, margin: "8px 0 0", textAlign: 'center' }}>{''}</p>
                                    </div>
                                </div>
                            </div>} */}
            {/* Cycle information */}
            {tabIndex === 0 && (
              <div
                className="flex column justifyCenter alignCenter w-100"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: 12,
                  padding: "20px 16px",
                  marginTop: 32,
                }}
              >
                <div className="flex justifyBetween alignCenter w-100">
                  <p style={{ fontSize: 16 }}>Cycle Information</p>
                  {showCycleGraph ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      width={24}
                      height={24}
                      onClick={() => setCycleGraph(!showCycleGraph)}
                      className="pointer"
                    />
                  ) : (
                    <img
                      src={ArrowDownIcon}
                      alt="arrow up icon"
                      width={16}
                      height={16}
                      onClick={() => setCycleGraph(!showCycleGraph)}
                      className="pointer"
                    />
                  )}
                </div>
                {showCycleGraph &&
                  athlete_details &&
                  athlete_details["cycle"]["cycle_phases"] !== null &&
                  athlete_details["cycle"]["cycle_phases"] !== undefined && (
                    <div
                      className="flex w-100"
                      style={{ marginTop: 16, marginBottom: 12 }}
                    >
                      {athlete_details["cycle"]["cycle_phases"] &&
                        athlete_details["cycle"]["cycle_phases"].length > 3 && (
                          <div className="infoContainer graphContainer">
                            {athlete_details["cycle"]["cycle_phases"] &&
                              athlete_details["cycle"]["cycle_phases"].map(
                                (graphItem, index) => (
                                  <img
                                    key={index + 1}
                                    src={graphItem.image_url}
                                    alt={graphItem.phase}
                                    width={
                                      graphItem.duration
                                        ? graph_pixel_width * graphItem.duration
                                        : 0
                                    }
                                  />
                                )
                              )}
                            <div
                              style={{
                                position: "absolute",
                                left: 8,
                                top: 0,
                                textAlign: "left",
                              }}
                            >
                              <p className="estrogen-dark">Estrogen</p>
                              <p className="progesterone-light">Progesterone</p>
                            </div>
                            {athlete_details["cycle"]["cycle_phases"].length >
                              1 && (
                              <div
                                className="blackTiled"
                                style={{ left: `${tiledPosition()}px` }}
                              />
                            )}
                            <p
                              style={{
                                position: "absolute",
                                bottom: -40,
                                left: `${textPosition()["left"]}px`,
                                right: `${textPosition()["right"]}px`,
                                fontSize: 12,
                              }}
                            >
                              {athlete_details["cycle"]["phase"]}
                            </p>
                          </div>
                        )}

                      {athlete_details["cycle"]["cycle_phases"] &&
                        athlete_details["cycle"]["cycle_phases"].length ===
                          1 && (
                          <div className="infoContainer graphContainerForSingle">
                            {athlete_details["cycle"]["cycle_phases"] &&
                              athlete_details["cycle"]["cycle_phases"].map(
                                (graphItem, index) => (
                                  <img
                                    key={index + 1}
                                    src={graphItem.image_url}
                                    alt={graphItem.phase}
                                    width={
                                      graphItem.duration
                                        ? graph_pixel_width * graphItem.duration
                                        : 0
                                    }
                                  />
                                )
                              )}

                            <div
                              style={{
                                position: "absolute",
                                right: 8,
                                top: 0,
                                textAlign: "right",
                              }}
                            >
                              <p className="estrogen">Estrogen</p>
                              <p className="progesterone">Progesterone</p>
                            </div>

                            <div className="flex justifyBetween w-100 alignCenter">
                              {" "}
                              <p className="stagName">
                                {athlete_details["cycle"]["phase"]}
                              </p>
                            </div>
                          </div>
                        )}

                      {athlete_details["cycle"]["phase"] === "Active Phase" && (
                        <div
                          className="infoContainer graphContainer w-100"
                          style={{ minWidth: 293 }}
                        >
                          {athlete_details["cycle"]["cycle_phases"] &&
                            athlete_details["cycle"]["cycle_phases"].map(
                              (graphItem, index) => (
                                <img
                                  key={index + 1}
                                  src={graphItem.image_url}
                                  alt={graphItem.phase}
                                  width={
                                    graphItem.duration
                                      ? graph_pixel_width * graphItem.duration
                                      : 0
                                  }
                                />
                              )
                            )}
                          <div
                            style={{
                              position: "absolute",
                              right: 8,
                              top: 0,
                              textAlign: "right",
                            }}
                          >
                            <p className="estrogen">Estrogen</p>
                            <p className="progesterone">Progesterone</p>
                          </div>
                          <div
                            className="blackTiled"
                            style={{ left: `${tiledPosition()}px` }}
                          />
                          <p
                            className="phase stagName"
                            style={{ left: `${tiledPosition() - 33}px` }}
                          >
                            {athlete_details["cycle"]["phase"]}
                          </p>
                          <p className="phase stagName" style={{ right: 0 }}>
                            Inactive Phase{" "}
                          </p>
                        </div>
                      )}

                      {athlete_details["cycle"]["phase"] ===
                        "Inactive Phase" && (
                        <div
                          className="infoContainer graphContainer w-100"
                          style={{ minWidth: 293 }}
                        >
                          {athlete_details["cycle"]["cycle_phases"] &&
                            athlete_details["cycle"]["cycle_phases"].map(
                              (graphItem, index) => (
                                <img
                                  key={index + 1}
                                  src={graphItem.image_url}
                                  alt={graphItem.phase}
                                  width={
                                    graphItem.duration
                                      ? graph_pixel_width * graphItem.duration
                                      : 0
                                  }
                                />
                              )
                            )}
                          <div
                            style={{
                              position: "absolute",
                              right: 8,
                              top: 0,
                              textAlign: "right",
                            }}
                          >
                            <p className="estrogen">Estrogen</p>
                            <p className="progesterone">Progesterone</p>
                          </div>
                          <p className="phase stagName" style={{ left: 80 }}>
                            Active Phase{" "}
                          </p>
                          <div
                            className="blackTiled"
                            style={{ left: `${tiledPosition()}px` }}
                          />
                          <p className="phase stagName" style={{ right: 4 }}>
                            {athlete_details["cycle"]["phase"]}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
              </div>
            )}
            {/* {tabIndex === 0 && (
              <div className="flex column w-100 relative">
                <div
                  className="flex column justifyCenter alignCenter w-100"
                  style={{
                    background: "rgba(255, 255, 255, 0.1)",
                    opacity: 0.2,
                    borderRadius: 12,
                    padding: 20,
                    marginTop: 32,
                  }}
                >
                  <div className="flex justifyBetween alignCenter w-100">
                    <p style={{ fontSize: 16 }}>Last Training</p>
                    <p style={{ fontSize: 16 }}>Yesterday</p>
                  </div>
                </div>
                <p
                  className="w-100"
                  style={{
                    fontSize: 16,
                    marginTop: 32,
                    opacity: 0.2,
                    textAlign: "left",
                  }}
                >
                  Scheduled Sessions
                </p>
                <div
                  className="flex column justifyCenter alignCenter w-100"
                  style={{
                    background: "rgba(255, 255, 255, 0.1)",
                    opacity: 0.2,
                    borderRadius: 12,
                    padding: 20,
                    marginTop: 16,
                  }}
                >
                  <div className="flex justifyBetween alignCenter w-100">
                    <div className="flex column alignStart">
                      <p style={{ fontSize: 12 }}>Coach Session</p>
                      <p style={{ fontSize: 16 }}>Weight Training</p>
                    </div>
                    <img
                      src={RightArrow}
                      alt="chevron right"
                      height={16}
                      width={16}
                    />
                  </div>
                </div>
                <div
                  className="white-button"
                  style={{
                    height: 56,
                    pointerEvents: "none",
                    opacity: 0.2,
                    marginTop: 24,
                  }}
                >
                  Add new session
                </div>
                <div
                  className="flex column alignCenter justifyCenter w-100"
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%, -40%)",
                  }}
                >
                  <p style={{ fontSize: 28 }}>Coming Soon</p>
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}

      {!initLoading && athlete_details && tabIndex === 1 && (
        <AthleteCalendar athlete={athleteid} padding="0 32px 32px" />
      )}

      {tabIndex === 2 && !reportLoading && (
        <div
          className="flex column justifyCenter alignCenter w-100"
          style={{ padding: "0 32px 32px" }}
        >
          {datesList.length > 0
            ? datesList.map((report_list, index) => (
                <div
                  className="flex justifyCenter alignCenter w-100"
                  key={index + 1}
                >
                  {report_list &&
                    report_list.length > 0 &&
                    report_list.map((report, sub_index) => (
                      <div
                        className="flex column justifyCenter alignCenter relative pointer w-100"
                        key={sub_index + 1}
                        style={{
                          background: "#00000050",
                          borderRadius: 12,
                          margin: 8,
                          padding: 16,
                          minWidth: 200,
                          maxWidth: 200,
                        }}
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_ATHLETE_APP_URL}/cycle-report?user_access=${reportEndpoint["params"]["user_access"]}&date=${report["request_date"]}`,
                            "_blank"
                          );
                        }}
                      >
                        <p style={{ fontSize: 18 }}>
                          {moment(report["request_date"], "DD-MM-YYYY").format(
                            "MMMM"
                          )}
                        </p>
                        <p style={{ fontSize: 20, marginTop: 10 }}>
                          {moment(report["request_date"], "DD-MM-YYYY").format(
                            "YYYY"
                          )}
                        </p>
                        <div className="flex justifyEnd w-100">
                          <img
                            src={DownloadIcon}
                            alt="download icon"
                            height={20}
                            width={20}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              ))
            : datesList.length === 0 && (
                <div
                  className="flex column justifyCenter alignCenter w-100"
                  style={{ minHeight: 320 }}
                >
                  <p style={{ fontSize: 28 }}>No reports found</p>
                </div>
              )}
        </div>
      )}
    </div>
  );
};

export default AthleteGeneral;
