import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// Actions
import { createNotification, deleteAccount } from "../../store/app/actions";


const DeleteAccount = () => {
    const dispatch = useDispatch();
    const { settings } = useSelector((state) => state.settings);

    const [submitting, setSubmitting] = useState(false);
    const [checked, setChecked] = useState(false);
    const [enabled, setEnabled] = useState(false);

    // Error Handler
    const onError = async (error) => { setSubmitting(false); dispatch(createNotification("error-toast", error)) }

    // Delete team
    const onDeleteAccount = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        dispatch(deleteAccount(settings.email, onError));
    };


    return (
        <div className="settings-main" style={{ maxWidth: 444, height: "80vh", margin: '0 auto' }}>
            <p className="main-title">Are you sure?</p>
            <p className="main-decription">You are about to delete your Wild.AI Coach Account.</p>
            <p className="main-description">All teams you own will automatically be removed and your team members and athletes won’t be able to access the team anymore.</p>
            <p className="main-decription"> Please confirm with<strong>{` “DELETE MY COACH ACCOUNT”`}</strong></p>
            <form className="flex column alignCenter justifyCenter w-100" style={{ width: "100%", marginTop: 30 }} onSubmit={(e) => onDeleteAccount(e)} >
                <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                    <label className="settings-label">Confirm deletion</label>
                    <input type="text"
                        required
                        placeholder="DELETE MY COACH ACCOUNT"
                        className="settings-value"
                        onChange={(e) => {
                            if (e.target.value === "DELETE MY COACH ACCOUNT") {
                                setEnabled(true)
                            } else {
                                setEnabled(false)
                            }
                        }}
                        defaultValue=""
                    />
                </div>

                <div className="flex justifyStart alignCenter w-100" style={{ marginTop: 24 }}>
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        style={{ alignItems: "center", height: 24, width: 24 }}
                    />
                    <p className={checked ? "small-decription" : "small-decription-disabled"}>
                        I aknowledge that all data will be removed and can’t be
                        accessed anymore
                    </p>
                </div>


                <div className="flex justifyCenter alignCenter w-100" style={{ marginTop: 24 }} >
                    <button htmltype="submit" disabled={(!enabled && !checked)} className={(enabled && checked) ? "white-button" : "disable-button"}>
                        {submitting ?
                            <div className="flex justifyCenter alignCenter">
                                <div className="custom_button_loader" />
                            </div>
                            : <p style={{ margin: 0 }}>DELETE</p>}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default DeleteAccount;
