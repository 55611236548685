import React, { useEffect, useState } from "react";
import "./SchedulingSessionPopup.css";
import SwitchButton from "../../components/ui/SwitchButton";
import moment from "moment";
import Dropdown from "../../components/ui/Dropdown";
import { weekdayNames, weekdays } from "../../_helpers/constants";
import DatePickerCalendar from "../../components/ui/DatePickerCalendar";

const SchedulingSessionPopup = ({
  scheduleSessionPopup,
  setScheduleSessionPopup,
  submitTrainingSessions,
  isSesssionValid,
  errorToast,
}) => {
  const [endDate, setEndDate] = useState(false);

  const isWeekdayPicked = (index) => {
    if (scheduleSessionPopup.initial_weekdays) {
      return scheduleSessionPopup.initial_weekdays.some((day) => day === index);
    }
    return false;
  };
  const addRecurringWeekdays = (weekdayIndex) => {
    if (isWeekdayPicked(weekdayIndex)) {
      const updatedWeekdays = scheduleSessionPopup.initial_weekdays.filter(
        (day) => day !== weekdayIndex
      );
      setScheduleSessionPopup({
        ...scheduleSessionPopup,
        initial_weekdays: updatedWeekdays,
      });
    } else {
      setScheduleSessionPopup({
        ...scheduleSessionPopup,
        initial_weekdays: [
          ...scheduleSessionPopup.initial_weekdays,
          weekdayIndex,
        ],
      });
    }
  };
  useEffect(() => {
    setScheduleSessionPopup({
      ...scheduleSessionPopup,
      startDate: moment().format("YYYY-MM-DD"),
    });
  }, []);

  useEffect(() => {
    if (
      scheduleSessionPopup.recurring &&
      !endDate &&
      scheduleSessionPopup.startDate
    ) {
      setScheduleSessionPopup({
        ...scheduleSessionPopup,
        endDate: moment(scheduleSessionPopup.startDate)
          .add(1, "year")
          .format("YYYY-MM-DD"),
      });
    } else {
      setScheduleSessionPopup({
        ...scheduleSessionPopup,
        endDate: scheduleSessionPopup.startDate,
      });
    }
  }, [scheduleSessionPopup.recurring, scheduleSessionPopup.startDate, endDate]);
  useEffect(() => {
    if (!scheduleSessionPopup.recurring) {
      setEndDate(false);
    }
  }, [scheduleSessionPopup.recurring]);

  if (!scheduleSessionPopup.show) {
    return <></>;
  }
  return (
    <div
      className="sessionBuilder_sessionPopup"
      onClick={(e) => e.stopPropagation()}
    >
      <span className="sessionBuilder_popupHeading">Schedule Session</span>
      <div className="flex row" style={{ gap: "30px" }}>
        <div>
          <DatePickerCalendar
            startDate={scheduleSessionPopup.startDate}
            setStartDate={(startDate) => {
              setScheduleSessionPopup({
                ...scheduleSessionPopup,
                startDate,
              });
            }}
            endDate={scheduleSessionPopup.endDate}
            setEndDate={(endDate) =>
              setScheduleSessionPopup({
                ...scheduleSessionPopup,
                endDate,
              })
            }
            isRange={endDate}
            calendarId="sessionBuilderDatePicker"
          />
        </div>
        <div
          className={`flex column ${
            scheduleSessionPopup.recurring ? "" : "justifyStart"
          }`}
          style={{ margin: "10px 10px 10px 0", gap: "22px" }}
        >
          <div className="flex column" style={{ gap: "23px" }}>
            <SwitchButton
              label={
                scheduleSessionPopup.recurring
                  ? "Recurring"
                  : "Set as Recurring"
              }
              onChange={() =>
                setScheduleSessionPopup({
                  ...scheduleSessionPopup,
                  recurring: !scheduleSessionPopup.recurring,
                  days: [],
                  weeksBetweenEvents: 0,
                  endDate: null,
                })
              }
            />
          </div>
          {scheduleSessionPopup.recurring ? (
            <>
              <div className="flex " style={{ gap: "24px" }}>
                {weekdays.map((weekday, index) => (
                  <div
                    key={index}
                    className="sessionBuilder_weekday"
                    onClick={() => addRecurringWeekdays(index)}
                    style={{
                      backgroundColor: isWeekdayPicked(index)
                        ? "rgba(255, 255, 255, 0.30)"
                        : "",
                    }}
                  >
                    {weekday}
                  </div>
                ))}
              </div>
              <div className="flex row alignCenter" style={{ gap: "24px" }}>
                <span>Every</span>
                <div
                  style={{ width: "30%", position: "relative", height: "35px" }}
                >
                  <Dropdown
                    options={[1, 2, 3, 4]}
                    defaultTitle="Number"
                    selectedOption={scheduleSessionPopup.weeksBetweenEvents}
                    onOptionChange={(value) =>
                      setScheduleSessionPopup({
                        ...scheduleSessionPopup,
                        weeksBetweenEvents: value,
                      })
                    }
                    customStyles={{
                      width: "100%",
                      position: "absolute",
                    }}
                    customOptionStyles={{ backgroundColor: "#818181" }}
                    areElementsBehind={true}
                  />
                </div>
                <span>Week</span>
              </div>
              <div className="flex column justifyCenter alignStart">
                <label className="flex custom-checkbox">
                  <input
                    type="checkbox"
                    style={{ width: "10%" }}
                    onChange={() => setEndDate(!endDate)}
                    value={endDate}
                  />
                  <span className="checkmark" />
                  Set End Date
                </label>
                <span>
                  {endDate
                    ? !scheduleSessionPopup.endDate && "(Select a range)"
                    : "(Sessions without an end date will expire in 1 year)"}
                </span>
              </div>
              <div className="sesionBuilder_separator" />
              {scheduleSessionPopup.endDate && (
                <div>
                  <span>
                    Ends{" "}
                    {moment(scheduleSessionPopup.endDate).format(
                      "dddd, MMMM DD, YYYY"
                    )}
                  </span>
                </div>
              )}
              <div>
                {scheduleSessionPopup.initial_weekdays.length > 0 && (
                  <>
                    <span>Every </span>
                    <span style={{ fontSize: "16px" }}>
                      {scheduleSessionPopup.initial_weekdays.map((day, i) => (
                        <>
                          {weekdayNames[day]}
                          {scheduleSessionPopup.initial_weekdays.length ===
                          i + 1
                            ? ""
                            : ", "}
                        </>
                      ))}
                    </span>
                  </>
                )}
              </div>
            </>
          ) : (
            scheduleSessionPopup.startDate && (
              <span>
                {moment(scheduleSessionPopup.startDate).format(
                  "dddd, MMMM DD, YYYY"
                )}
              </span>
            )
          )}
          <div
            className={`white-button ${!isSesssionValid && "disabledDiv"}`}
            style={{
              fontWeight: 600,
              width: "258px",
              height: "40px",
              border: "none",
              alignSelf: "flex-end",
              position: "absolute",
              bottom: "24px",
              right: "24px",
            }}
            onClick={() =>
              isSesssionValid ? submitTrainingSessions() : errorToast()
            }
          >
            <p>Schedule Session</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulingSessionPopup;
