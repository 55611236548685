import React, { useRef, useState, useEffect } from "react";
import ArrowUp from "../../assets/icons/chevron-up-dark.svg";
import ArrowDown from "../../assets/icons/chevron-down-dark.svg";

const AthletesSelector = ({ athletes, callAction, isLoading, selectedId }) => {
    let [show, setShow] = useState(false);
    let closeSelector = () => setShow(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, closeSelector);

    function getAthleteName() {
        if (athletes && athletes.length > 0 && selectedId) {
            const athlete = athletes.filter((item) => item.pk === selectedId)[0];
            return `${athlete['first_name']} ${athlete['last_name']}`;
        } else {
            return "";
        }
    }
    function handleChange(athleteId) { callAction(athleteId); closeSelector(); }
    function useOutsideAlerter(ref, closeSelector) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    closeSelector();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, closeSelector]);
    }



    return isLoading ? <div style={{ minWidth: 224 }}><div className="custom_button_loader" /></div> :
        athletes ?
            <div className="team_selector" ref={wrapperRef}>
                {/* name */}
                <div className="flex justifyBetween alignCenter pointer w-100" onClick={() => setShow(true)}>
                    <p className="selected_team">{getAthleteName()} </p>
                    {/* arrows */}
                    {athletes.length > 1 &&
                        <div className="selectory_arrow_component">
                            {show ? <img src={ArrowUp} alt="arrow-up" height={24} width={24} />
                                : <img src={ArrowDown} alt="arrow-down" height={24} width={24} />}
                        </div>}
                </div>

                {/* dropdown box */}
                {show ?
                    <div className="listing">
                        <div className="teams-list">
                            {athletes && athletes.map((athlete, subIndex) => athlete['pk'] === selectedId ?
                                <p className="team-names active" key={subIndex + 1} onClick={() => handleChange(athlete.pk)}>
                                    {`${athlete['first_name']} ${athlete['last_name']}`}
                                </p> : <p className="team-names" key={subIndex + 1} onClick={() => handleChange(athlete.pk)} >
                                    {`${athlete['first_name']} ${athlete['last_name']}`}
                                </p>
                            )}
                        </div>
                    </div>
                    : null}
            </div>
            : <div style={{ minWidth: 224 }} />;
};

export default AthletesSelector;
