import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/en-gb";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment";
import "./DatePickerCalendar.css";

const DatePickerCalendar = ({
  startDate,
  setStartDate,
  setEndDate,
  isRange = false,
  calendarId,
}) => {
  const today = new Date();
  const [range, setRange] = useState({ from: new Date(startDate), to: null });
  const [selected, setSelected] = useState(today);

  useEffect(() => {
    if (selected && !isRange)
      setStartDate(
        moment(selected.toLocaleDateString("en-US"), "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        )
      );
    if (isRange && range.from)
      setStartDate(
        moment(range.from.toLocaleDateString("en-US"), "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        )
      );
    if (isRange && range.to)
      setEndDate(
        moment(range.to.toLocaleDateString("en-US"), "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        )
      );
    // if (!selected) {
    //   setStartDate(null);
    // }
  }, [range, selected]);

  useEffect(() => {
    setRange({ ...range, from: new Date(startDate) });
  }, [startDate, isRange]);

  useEffect(() => {
    if (!isRange && range.from && range.to) {
      setSelected(range.from);
      setRange({ from: null, to: null });
      setEndDate(null);
    }
  }, [isRange]);

  const isDateDisabled = (date) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0);
  };

  return (
    <DayPicker
      id={calendarId}
      mode={isRange ? "range" : "single"}
      selected={isRange ? range : selected}
      onSelect={(value) => {
        if (isRange) {
          if (value) {
            setRange(value);
          } else {
            setRange({ from: null, to: null });
          }
        } else {
          setSelected(value);
        }
      }}
      weekStartsOn={1}
      disabled={isDateDisabled}
    />
  );
};

export default DatePickerCalendar;
