import React from "react";

const NoData = (props) => {
    return (
        <div className="no_record_found" style={{ opacity: props.loadingState && 0.2, margin: "72px auto 0" }}>
            <div className="flex column alignCenter justifyCenter">
                <h1>{`Sorry, we couldn’t find anything regarding ${props.searchedText}`}</h1>
                <p>
                    Try using another search term or contact support at support@wild.ai.
                </p>
            </div>
        </div>
    );
};

export default NoData;
