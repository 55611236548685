import React, { useEffect, useState } from "react";
// Provider
import { useDispatch, useSelector } from "react-redux";
// Components
import LoadingTable from "../../components/common/LoadingTable";
import SearchCard from "../../components/common/SearchCard";
import AdminPlansTable from "../../components/tables/AdminPlansTable";
import AdminTabs from "../../components/tabs/AdminTabs";
// Actions
import { createNotification } from "../../store/app/actions";
import {
    fetchAdminPublishPlans,
    fetchAdminPublishedPlans,
    fetchAdminRejectPlans,
    fetchAdminReviewPlans,
    fetchSportTypes,
    fetchTrainingLevels,
    setAdminPlans,
    setSportTypes,
    setTrainingLevels
} from "../../store/trainings/actions";


const AdminPlans = () => {
    const { user } = useSelector((state) => state.app);
    const { admin_plans } = useSelector((state) => state.training_plans);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    // Search bar
    const [isSearchActive, setSearchActive] = useState(false);
    const [searchedText, setSearchText] = useState("");
    const [searchedDataSource, setSearchedDataSource] = useState([]);
    const [showCross, setShowCross] = useState(false);
    const [showLoader, setLoader] = useState(false);
    const [activeTabName, setActiveTabName] = useState(null);
    const [permission_level, set_permission_level] = useState(null);
    const [tabs, set_tabs] = useState([]);

    const onError = (error) => {
        setLoading(false);
        dispatch(createNotification("error-toast", error));
    };

    const onSuccess = () => setLoading(false);


    useEffect(() => {
        if (admin_plans && admin_plans.length > 0) {
            setSearchedDataSource(admin_plans);
        }
    }, [admin_plans]);

    useEffect(() => {
        if (activeTabName) {
            if (activeTabName === "Review") {
                setLoading(true);
                dispatch(setAdminPlans([]));
                dispatch(fetchAdminReviewPlans(onSuccess, onError));
            } else if (activeTabName === "Rejected") {
                setLoading(true);
                dispatch(setAdminPlans([]));
                dispatch(fetchAdminRejectPlans(onSuccess, onError));
            } else if (activeTabName === "Publish") {
                setLoading(true);
                dispatch(setAdminPlans([]));
                dispatch(fetchAdminPublishPlans(onSuccess, onError));
            } else if (activeTabName === "Published") {
                setLoading(true);
                dispatch(setAdminPlans([]));
                dispatch(fetchAdminPublishedPlans(onSuccess, onError));
            }
        }
    }, [activeTabName]);

    useEffect(() => {
        if (permission_level) {
            if (permission_level === "review_and_publish") {
                if (window.localStorage.getItem("tab") === "undefined") {
                    window.localStorage.setItem("tab", "Review");
                }
                set_tabs(["Review", "Rejected", "Publish", "Published"]);
            } else if (permission_level === "reviewer") {
                if (window.localStorage.getItem("tab") === "undefined") {
                    window.localStorage.setItem("tab", "Review");
                }
                set_tabs(["Review", "Rejected", "Published"]);
            } else if (permission_level === "publisher") {
                if (window.localStorage.getItem("tab") === "undefined") {
                    window.localStorage.setItem("tab", "Publish");
                }
                set_tabs(["Publish", "Published"]);
            }
        }
    }, [permission_level]);
    useEffect(() => {
        if (user) {
            const { coach: { admin_permissions } } = user;
            if (admin_permissions['can_review'] && admin_permissions['can_publish']) {
                set_permission_level("review_and_publish");
            } else if (admin_permissions['can_review'] && !admin_permissions['can_publish']) {
                set_permission_level("reviewer");
            } else if (!admin_permissions['can_review'] && admin_permissions['can_publish']) {
                set_permission_level("publisher");
            } else if (!admin_permissions['can_review'] && !admin_permissions['can_publish']) {
                set_permission_level(null);
            } else {
                set_permission_level(null);
            }
        }
    }, [user]);

    // useEffects
    // 1- onLoad
    useEffect(() => {
        setLoading(true);
        dispatch(setAdminPlans([]));
        dispatch(setSportTypes([]));
        dispatch(setTrainingLevels([]));
        dispatch(fetchSportTypes());
        dispatch(fetchTrainingLevels());
        setSearchedDataSource(admin_plans);
        
    }, []);


    return (
        <div className="flex column w-100 h-100">
            {/* Header + Breadcrumb */}
            <div className="flex column justifyBetween alignCenter"
                style={{
                    position: 'fixed',
                    top: 0,
                    height: 120,
                    zIndex: 10,
                    background: 'rgba(1, 33, 58, 0.7)',
                    padding: '40px 24px 16px',
                    width: 'calc(100vw - 150px)'
                }}>
                {/* Header */}
                <SearchCard
                    is_margin={false}
                    searchFor="training_plans_screen"
                    setSearch={(status) => setSearchActive(status)}
                    searchedData={(source) => setSearchedDataSource(source)}
                    dataSource={admin_plans}
                    searchedText={searchedText}
                    setText={(text) => setSearchText(text)}
                    status={admin_plans && admin_plans.length > 0 ? true : false}
                    showCross={showCross}
                    setCrossStatus={(status) => setShowCross(status)}
                    showLoader={showLoader}
                    setLoaderStatus={(flag) => setLoader(flag)}
                />
            </div>

            <div style={{ padding: "0 32px", marginTop: 160 }}>
                {/* tabs */}
                <AdminTabs tabs={tabs} setTabName={(name) => setActiveTabName(name)} />

                {/* Loading state */}
                {loading ?
                    <div style={{ marginTop: 56 }}>
                        <LoadingTable screenName="admin_training_plans" />
                    </div> : null}
                {/* Table after loading */}
                {(!loading && admin_plans && admin_plans.length > 0) ?
                    <AdminPlansTable
                        data_list={searchedDataSource}
                        searchStatus={isSearchActive}
                        searchedText={searchedText}
                        loadingState={loading}
                        screen={activeTabName}
                    />
                    : null}

                {(!loading && admin_plans.length === 0) ?
                    <div className="no_record_found">
                        <div className="flex column alignCenter justifyCenter">
                            <h1 style={{ color: '#FFFFFF' }}>No Plans to show!</h1>
                            {activeTabName === "Review" && (<p style={{ margin: '16px 0 0', color: '#FFFFFF' }}>Training plans for review are not available yet. </p>)}
                            {activeTabName === "Rejected" && (<p style={{ margin: '16px 0 0', color: '#FFFFFF' }}>There are no rejected training plans yet. </p>)}
                            {activeTabName === "Publish" && (<p style={{ margin: '16px 0 0', color: '#FFFFFF' }}>There are not training plans yet. </p>)}
                            {activeTabName === "Published" && (<p style={{ margin: '16px 0 0', color: '#FFFFFF' }}>There are no published training plans yet. </p>)}
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    );
};

export default AdminPlans;
