import { all, fork } from "redux-saga/effects";
import appSagas from "./app/sagas";
import athletesSaga from "./athletes/sagas";
import settingsSaga from "./settings/sagas";
import managementsSaga from "./management/sagas";
import trainingsSagas from "./trainings/sagas";
import athleteCalendarSaga from "./calendar/sagas";

export default function* rootSaga(services = {}) {
  yield all([
    fork(appSagas, services),
    fork(athletesSaga, services),
    fork(settingsSaga, services),
    fork(managementsSaga, services),
    fork(settingsSaga, services),
    fork(trainingsSagas, services),
    fork(athleteCalendarSaga, services),
  ]);
}
