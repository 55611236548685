import React, { useState, useEffect } from "react";
// Providers 
import { useDispatch } from "react-redux";
// Image 
import AlertIcon from "../assets/icons/alert.svg";
import Placeholder from "../assets/icons/placeholder-default.svg";
// Actions
import { acceptInvite, ignoreInvite } from "../store/app/actions";

const Invite = () => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  let [error_message, setError] = useState(null);
  const inviteJSON = JSON.parse(window.localStorage.getItem("invitation")) || null;
  const onFailure = (error) => { setSubmitting(false); setError(error) };
  const acceptToJoinTeam = async () => {
    setSubmitting(true);
    dispatch(acceptInvite(inviteJSON.team_name, inviteJSON.team_code, onFailure));
  };

  useEffect(() => {
    if (error_message) {
      setTimeout(() => setError(null), 5000);
    }
  }, [error_message]);

  const onIgnore = async () => dispatch(ignoreInvite());

  const LoadingBlackButton = ({
    label,
    click,
    submitting,
    className,
    status,
    ...rest
  }) => (
    <button
      htmltype="submit"
      disabled={status}
      className={className}
      onClick={click}
      {...rest}
    >
      {submitting ? (
        <div className="flex justifyCenter alignCenter">
          <div className="custom_button_loader" />
        </div>
      ) : (
        <p style={{ margin: 0 }}>{label}</p>
      )}
    </button>
  );

  return (
    <div className="flex column justifyCenter alignCenter App" style={{ background: "#010101" }}>
      {error_message ?
        <div className="notification_popup error-toast" style={{ width: '100%', left: 0 }}>
          <img src={AlertIcon} alt="notification-icon" height={24} width={32} />
          <p> {error_message} </p>
        </div>
        : null}

      <div className="flex column justifyCenter alignCenter"
        style={{
          backgroundColor: "#ffffff",
          height: 500,
          borderRadius: 10,
          width: 446,
          padding: "0px 52px",
          position: "relative",
        }}
      >
        <img
          src={
            inviteJSON &&
              inviteJSON !== null &&
              inviteJSON.team_logo_url !== null &&
              inviteJSON.team_logo_url !== undefined &&
              inviteJSON.team_logo_url !== "" &&
              inviteJSON.team_logo_url !== "None" &&
              inviteJSON.team_logo_url !== "none"
              ? inviteJSON.team_logo_url
              : Placeholder
          }
          alt="team-profile"
          height={109}
          width={109}
          style={{
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />

        <div style={{ textAlign: "center" }}>
          <p
            style={{
              padding: 0,
              marginTop: 30,
              color: "#010101",
              fontSize: 32,
              lineHeight: "120%",
              fontWeight: 700,
            }}
          >
            {`Join ${inviteJSON && inviteJSON !== null ? inviteJSON.team_name : ""
              }
            ?`}
          </p>
          <p className="invite-decription">
            {`You got an invitation from ${inviteJSON && inviteJSON !== null ? inviteJSON.coach_name : ""
              } to
            join
            ${inviteJSON && inviteJSON !== null ? inviteJSON.team_name : ""}.`}
          </p>

          <LoadingBlackButton
            submitting={submitting}
            onClick={acceptToJoinTeam}
            label={`Join
              ${inviteJSON && inviteJSON !== null ? inviteJSON.team_name : ""}`}
            style={{ width: "100% !important", marginTop: 32 }}
            className="white-button"
          />
          <p
            onClick={onIgnore}
            style={{
              color: "#2a2a2a",
              fontSize: 12,
              lineHeight: "120%",
              marginTop: 20,
              fontWeight: 700,
              cursor: "pointer",
              width: "100%",
              letterSpacing: 2,
              textTransform: "uppercase",
            }}
          >
            Ignore invitation
          </p>
        </div>
      </div>
    </div>
  );
};

export default Invite;
