import { push } from "connected-react-router";
import moment from "moment";
import {
  call,
  delay,
  fork,
  put,
  race,
  spawn,
  takeEvery,
} from "redux-saga/effects";
import { getErrorMessage } from "../../_helpers/Requests";
import api from "../../services/api/ApiService";
import {
  CLEAR_INVITE,
  DELETE_ACCOUNT,
  FETCH_INVITE_URL_DATA,
  FETCH_USER,
  FORGOT_PASSWORD,
  INITIALIZE,
  JOIN_TEAM_BY_ACCEPTING_INVITE,
  LOGIN_MANUAL,
  LOGIN_GOOGLE,
  LOGOUT_USER,
  SET_LOGGED_USER,
  SIGNUP_USER,
  createNotification,
  initialized,
  setUser,
} from "./actions";

function getRefreshToken() {
  return String(window.localStorage.getItem("authRefreshToken"));
}

function setRefreshToken(token) {
  window.localStorage.setItem("authRefreshToken", token);
}

function removeRefreshToken() {
  window.localStorage.removeItem("authRefreshToken");
}

async function reset() {
  window.localStorage.clear();
  const response = await api.get("/logout");
  return response;
}

function refreshToken(token) {
  const resp = api.post("/api/jwt/token/refresh", { refresh: String(token) });
  return resp;
}

const setApiAuth = (token) =>
  (api.defaults.headers.common.Authorization = `JWT ${token}`);

function unsetAuth() {
  api.defaults.headers.common.Authorization = null;
}

function* getUser() {
  const response = yield call(coachProfile);
  return response;
}

function* logout() {
  const response = yield call(reset);
  if (response.status === 200) {
    yield call(unsetAuth);
    yield put(setUser(null));
    yield put(push("/login"));
  }
}

function removeInvite() {
  window.localStorage.removeItem("invitation");
}

function* clearInvite() {
  yield call(removeInvite);
  yield put(push("/dashboard/athletes"));
}

function* refreshLoop(refresher) {
  let userSignedOut;
  while (!userSignedOut) {
    const { expired } = yield race({
      expired: delay(840000), // Wait 14 min before refreshing
    });

    // token expired first
    if (expired) {
      try {
        const {
          data: { access },
        } = yield call(refreshToken, refresher);
        yield call(setApiAuth, access);
      } catch (e) {
        yield put(
          createNotification("error-toast", getErrorMessage(e.response))
        );
      }
    }
  }
}

function* setupAuth(access, refresh) {
  setApiAuth(access);
  setRefreshToken(refresh);
  yield spawn(refreshLoop, refresh);
}

function* initializeApp() {
  const token = yield call(getRefreshToken);
  try {
    if (
      token &&
      token !== null &&
      token !== undefined &&
      token !== "null" &&
      token !== "undefined"
    ) {
      const {
        data: { access },
      } = yield call(refreshToken, token);
      if (access) {
        yield call(setApiAuth, access);
        const response = yield call(getUser);
        if (response.status === 200) {
          yield put(setUser(response["data"]));
        }
        yield fork(refreshLoop, token);
      } else {
        yield call(removeRefreshToken);
      }
    }
  } catch (e) {
    yield call(unsetAuth);
    yield call(removeRefreshToken);
  }
  yield put(initialized(true));
}

function* setLoggedUser(user, access, refresh) {
  yield call(setupAuth, access, refresh);
  yield put(setUser(user));
}

async function coachProfile() {
  try {
    return await api.get("/coach");
  } catch ({ response }) {
    return response;
  }
}

async function isCoachGET() {
  try {
    return await api.get("/user/is_coach");
  } catch ({ response }) {
    return response;
  }
}

function checkInvitation() {
  const invite = JSON.parse(window.localStorage.getItem("invitation")) || null;
  return invite;
}

async function getTokens(values) {
  try {
    const { username, password } = values;
    return await api.post("/api/jwt/token/auth", { username, password });
  } catch ({ response }) {
    return response;
  }
}

function* login({
  payload: { form, onSuccess, onError, showResend, subscriptionFailure },
}) {
  try {
    const response = yield call(getTokens, form);
    window.localStorage.clear();
    if (response.status === 200) {
      const { access, refresh } = response["data"];
      setApiAuth(access);
      const isCoachResponse = yield call(isCoachGET);
      if (isCoachResponse.status === 200) {
        // If is Coach Active
        if (isCoachResponse.data.is_coach) {
          const coachProfileResponse = yield call(coachProfile);
          if (coachProfileResponse.status === 200) {
            yield call(
              setLoggedUser,
              coachProfileResponse.data,
              access,
              refresh
            );
            const invitation = yield call(checkInvitation);
            yield call(onSuccess);
            const {
              data: { onboarding_complete, team_num },
            } = coachProfileResponse;
            if (invitation === null) {
              if (onboarding_complete) {
                yield put(push("/dashboard/athletes"));
              } else if (!onboarding_complete && team_num) {
                yield put(push("/onboarding/addAthletes"));
              } else {
                yield put(push("/onboarding/welcome"));
              }
            } else {
              yield put(push("/invite"));
            }
          } else {
            yield call(onError, getErrorMessage(coachProfileResponse));
          }
        } else {
          // If is Coach not Active
          window.localStorage.setItem("email_address", form["email"]);
          yield call(showResend);
        }
      } else if (isCoachResponse.status === 402) {
        // If payment hasn't been done
        yield call(subscriptionFailure);
      } else {
        yield call(onError, getErrorMessage(isCoachResponse));
      }
    } else {
      yield call(onError, getErrorMessage(response));
    }
  } catch (err) {
    yield call(onError, getErrorMessage(err.response));
  }
}

async function signupUser({ first_name, last_name, email, password }) {
  try {
    return await api.post("sign_up", {
      first_name,
      last_name,
      email,
      username: `wa${moment().format("x")}`,
      password,
      source: "wildai_coach_app_web",
    });
  } catch ({ response }) {
    return response;
  }
}

function* signup({
  payload: { form, onSuccess, onError, showResend, subscriptionFailure },
}) {
  try {
    const response = yield call(signupUser, form);
    window.localStorage.clear();
    if (response.status === 200 || response.status === 201) {
      const {
        jwt: { access, refresh },
      } = response["data"];
      setApiAuth(access);
      const isCoachResponse = yield call(isCoachGET);
      if (isCoachResponse.status === 200) {
        // If is Coach Active
        if (isCoachResponse.data.is_coach) {
          const coachProfileResponse = yield call(coachProfile);
          if (coachProfileResponse.status === 200) {
            yield call(
              setLoggedUser,
              coachProfileResponse.data,
              access,
              refresh
            );
            const invitation = yield call(checkInvitation);
            yield call(onSuccess);
            const {
              data: { onboarding_complete, team_num },
            } = coachProfileResponse;
            if (invitation === null) {
              if (onboarding_complete) {
                yield put(push("/dashboard/athletes"));
              } else if (!onboarding_complete && team_num) {
                yield put(push("/onboarding/addAthletes"));
              } else {
                yield put(push("/onboarding/welcome"));
              }
            } else {
              yield put(push("/invite"));
            }
          } else {
            yield call(onError, getErrorMessage(coachProfileResponse));
          }
        } else {
          // If is Coach not Active
          window.localStorage.setItem("email_address", form["email"]);
          yield call(showResend);
        }
      } else if (isCoachResponse.status === 402) {
        // If payment hasn't been done
        yield call(subscriptionFailure);
      } else {
        yield call(onError, getErrorMessage(isCoachResponse));
      }
    } else {
      if (response.data.details) {
        yield call(onError, response.data.details);
      } else {
        yield call(onError, getErrorMessage(response));
      }
    }
  } catch (err) {
    yield call(onError, getErrorMessage(err.response));
  }
}

function getResult(params) {
  const resp = api.post("/google/auth", params);
  return resp;
}

function* loginGoogle({ payload: { params, onSuccess, onError } }) {
  try {
    let location = window.localStorage.hasOwnProperty("location");
    const { data } = yield call(getResult, params);
    const { access_token, refresh_token } = data["jwt_pair"];
    setApiAuth(access_token);
    const response = yield call(coachProfile);
    yield call(setLoggedUser, response.data, access_token, refresh_token);
    yield call(onSuccess);
    // Same mechanics as the login function
    const isCoachResponse = yield call(isCoachGET);
    if (isCoachResponse.status === 200) {
      // If is Coach Active
      if (isCoachResponse.data.is_coach) {
        const coachProfileResponse = yield call(coachProfile);
        if (coachProfileResponse.status === 200) {
          yield call(
            setLoggedUser,
            coachProfileResponse.data,
            access_token,
            refresh_token
          );
          const invitation = yield call(checkInvitation);
          yield call(onSuccess);
          const {
            data: { onboarding_complete, team_num },
          } = coachProfileResponse;
          if (invitation === null) {
            if (onboarding_complete) {
              yield put(push("/dashboard/athletes"));
            } else if (!onboarding_complete && team_num) {
              yield put(push("/onboarding/addAthletes"));
            } else {
              yield put(push("/onboarding/createTeam"));
            }
          } else {
            yield put(push("/invite"));
          }
        } else {
          yield call(onError, getErrorMessage(coachProfileResponse));
        }
      } else {
        // If is Coach not Active
        // window.localStorage.setItem("email_address", form["email"]);
        // yield call(showResend);
      }
    } else if (isCoachResponse.status === 402) {
      // If payment hasn't been done
      yield call("Payment hasn't been done");
    } else {
      yield call(onError, getErrorMessage(isCoachResponse));
    }
    // Same mechanics as the login function
  } catch (err) {
    if (err.response) {
      const {
        data: { details, detail },
      } = err.response;
      if (details) {
        yield call(onError, details);
      } else if (detail) {
        yield call(onError, detail);
      }
    }
  }
}

function forgotRequest(emailAddress) {
  const response = api.post(
    `/user/password/forgot/request/?email=${emailAddress}`
  );
  return response;
}

function* forgotPassword({ payload: { email, successMessage, failure } }) {
  try {
    yield call(forgotRequest, email);
    yield call(successMessage);
    yield put(
      createNotification(
        "success-toast",
        "A link to reset your password has been sent to the email address you provided!"
      )
    );
    yield put(push("/dashboard/settings"));
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(failure, details);
    }
  }
}

function* fetchingInviteData({ payload: { dataString } }) {
  try {
    const urlDecoded = decodeURIComponent(dataString).replace("?", "");
    const json = JSON.parse(
      '{"' +
        decodeURI(urlDecoded)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
    window.localStorage.setItem("invitation", JSON.stringify(json));
    yield put(push("/invite"));
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield put(createNotification("error-toast", details));
    }
  }
}

function sendRequestToAcceptInvite(teamCode) {
  const response = api.post(`/coach/user/teams`, {
    code: teamCode,
  });

  return response;
}

function* acceptInvite({ payload: { teamName, teamCode, onError } }) {
  try {
    const { data } = yield call(sendRequestToAcceptInvite, teamCode);
    if (data !== null) {
      window.localStorage.removeItem("invitation");
      yield put(push("/dashboard/athletes"));
      yield put(
        createNotification(
          "success-toast",
          `Congratulation! Your request to join team ${teamName} is approved.`
        )
      );
    }
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(onError, details);
    }
  }
}

function deleteUserAccount(coach_email) {
  const response = api.post(`/user/delete_account`, {
    email: coach_email,
    confirm: true,
  });

  return response;
}

function* deleteAccount({ payload: { coach_email, onError } }) {
  try {
    yield call(deleteUserAccount, coach_email);
    yield put(
      createNotification(
        "success-toast",
        `Your account has been deleted succesfully.`
      )
    );
    yield call(logout());
  } catch (err) {
    if (err.response) {
      const {
        data: { details },
      } = err.response;
      yield call(onError, details);
    }
  }
}

function* appSaga() {
  yield takeEvery(INITIALIZE, initializeApp);
  yield takeEvery(LOGOUT_USER, logout);
  yield takeEvery(FETCH_USER, getUser);
  yield takeEvery(SET_LOGGED_USER, setLoggedUser);
  yield takeEvery(LOGIN_MANUAL, login);
  yield takeEvery(LOGIN_GOOGLE, loginGoogle);
  yield takeEvery(SIGNUP_USER, signup);
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(FETCH_INVITE_URL_DATA, fetchingInviteData);
  yield takeEvery(JOIN_TEAM_BY_ACCEPTING_INVITE, acceptInvite);
  yield takeEvery(CLEAR_INVITE, clearInvite);
  yield takeEvery(DELETE_ACCOUNT, deleteAccount);
}

export default appSaga;
