import React, { useState } from "react";
import { useDispatch } from "react-redux";
// Icons
import DeleteBinIcon from "../assets/icons/delete-bin-line.svg";
import PlusIcon from "../assets/icons/plus-line.svg";
// Actions
import { createNotification } from "../store/app/actions";
import {
  sendAthletesInvites,
  updateOnboardingStatus,
} from "../_helpers/Requests";
import { history } from "../index";

function InviteAthletes({ teamId, screenName }) {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const [inputFields, setInputFields] = useState([
    {
      emailAddress: "",
    },
  ]);

  const addInputField = () =>
    setInputFields([...inputFields, { emailAddress: "" }]);

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  // onError
  const onError = (error) => {
    setSubmitting(false);
    dispatch(createNotification("error-toast", error));
  };

  const onSuccess = () => {
    setSubmitting(false);
    dispatch(createNotification("success-toast", "Athletes has been invited."));
    if (screenName === "athletes") {
      history.push("/dashboard/athletes");
    } else if (screenName === "invite") {
      history.push("/dashboard/management");
    } else {
      history.push("/dashboard/management");
    }
  };
  // onSuccessInvite
  const onSuccessInvite = () => updateOnboardingStatus(onSuccess, onError);

  const getEmails = () => {
    let email_list = [];
    for (var i = 0; i < inputFields.length; i++) {
      if (inputFields[i].emailAddress !== "") {
        email_list[i] = inputFields[i].emailAddress;
      }
    }
    return email_list;
  };

  return (
    <div className="flex column alignCenter w-100">
      {/* Multiple Inputs */}
      {inputFields.map((data, index) => (
        <div
          className="flex alignCenter justifyStart w-100"
          style={{ margin: "6px 0 0", gap: "12px" }}
          key={index}
        >
          <div
            className="flex column alignCenter justifyCenter"
            style={{ width: "75%" }}
          >
            <input
              type="email"
              onChange={(evnt) => handleChange(index, evnt)}
              value={data["emailAddress"]}
              name="emailAddress"
              placeholder="Athlete's email"
              className="input-redesign"
            />
          </div>

          {inputFields.length !== 1 ? (
            <div
              className="flex alignCenter justifyCenter"
              style={{
                height: "46px",
                width: "46px",
                backgroundColor: "grey",
                borderRadius: "50%",
              }}
            >
              <img
                src={DeleteBinIcon}
                alt="delete bin icon"
                height={22}
                width={22}
                onClick={() => removeInputFields(index)}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          ) : null}
          {inputFields.length === index + 1 ? (
            <div
              className="flex alignCenter justifyCenter"
              style={{
                height: "46px",
                width: "46px",
                backgroundColor: "grey",
                borderRadius: "50%",
              }}
            >
              <img
                src={PlusIcon}
                alt="plus icon"
                height={22}
                width={22}
                onClick={addInputField}
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : null}
        </div>
      ))}

      {/* Add Athletes/Coaches button */}
      <div className="flex alignCenter w-100" style={{ marginTop: 24 }}>
        <button
          onClick={() => {
            const emails = getEmails();
            if (emails && emails.length > 0) {
              setSubmitting(true);
              sendAthletesInvites(teamId, emails, onSuccessInvite, onError);
            } else {
              onError(
                "To proceed with sending the invites, please make sure to include the necessary email addresses."
              );
            }
          }}
          className={inputFields.length > 0 ? "white-button" : ""}
          style={{ maxWidth: 220 }}
        >
          {submitting ? (
            <div className="flex alignStart">
              <div className="custom_button_loader" />
            </div>
          ) : (
            <p style={{ margin: 0 }}>Invite Athletes</p>
          )}
        </button>
      </div>
    </div>
  );
}
export default InviteAthletes;
