import React, { useEffect, useState } from "react";
import MinusIcon from "../../../assets/icons/minus.svg";
import PlusIcon from "../../../assets/icons/plus-icon.svg";
import "./Congrats.css";
import { history } from "../../../index";
import api from "../../../services/api/ApiService";
import { useDispatch } from "react-redux";
import { createNotification } from "../../../store/app/actions";

const BasicPricing = ({
  coachSeats,
  setCoachSeats,
  athleteSeats,
  setAthleteSeats,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onError = (error, timeout = 6000) =>
    dispatch(createNotification("error-toast", error, timeout));

  const addSubscription = async () => {
    setLoading(true);
    if (athleteSeats) {
      try {
        const {
          data: { checkout_session_url },
        } = await api.get(
          `coach/subscription/checkout?athlete_seats=${athleteSeats}&coach_seats=${
            coachSeats - 1
          }`
        );
        window.open(checkout_session_url, "_self");
        setLoading(false);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          onError(
            "This route does not exist. please contact with support@wild.ai"
          );
          setLoading(false);
        } else {
          onError("Something went wrong. Please contact with support@wild.ai");
          setLoading(false);
        }
      }
    } else {
      onError("You should add at least one athlete.");
      setLoading(false);
    }
  };
  return (
    <div className="pricing-container">
      <span style={{ fontSize: "32px", alignSelf: "center" }}>
        Calculate your pricing
      </span>
      <div
        className="flex row justifyBetween coaches-container"
        style={{ gap: "15px", alignSelf: "flex-start" }}
      >
        <div className="flex column">
          <span style={{ fontSize: "18px" }}>Athletes</span>
          <span style={{ fontSize: "14px" }}>£4.00 per seat</span>
        </div>
        <div className="flex alignCenter" style={{ gap: "16px" }}>
          <img
            src={MinusIcon}
            alt="minus-icon"
            height={32}
            width={32}
            className="pointer minus-icon"
            onClick={() => {
              if (athleteSeats !== 0) {
                setAthleteSeats(athleteSeats - 1);
              }
            }}
          />
          <div className="number-box">{athleteSeats}</div>
          <img
            src={PlusIcon}
            alt="plus-icon"
            height={32}
            width={32}
            className="pointer plus-icon"
            onClick={() => setAthleteSeats(athleteSeats + 1)}
          />
        </div>
      </div>
      <div
        className="flex row justifyBetween coaches-container"
        style={{ gap: "15px", alignSelf: "flex-start" }}
      >
        <div className="flex column">
          <span style={{ fontSize: "18px" }}>Coaches</span>
          <span style={{ fontSize: "14px" }}>£4.00 per seat</span>
        </div>
        <div className="flex alignCenter" style={{ gap: "16px" }}>
          <img
            src={MinusIcon}
            alt="minus-icon"
            height={32}
            width={32}
            className="pointer minus-icon"
            onClick={() => {
              if (coachSeats !== 1) {
                setCoachSeats(coachSeats - 1);
              }
            }}
          />
          <div className="number-box">{coachSeats}</div>
          <img
            src={PlusIcon}
            alt="plus-icon"
            height={32}
            width={32}
            className="pointer plus-icon"
            onClick={() => setCoachSeats(coachSeats + 1)}
          />
        </div>
      </div>
      <span
        style={{
          fontSize: "14px",
          alignSelf: "flex-end",
          marginTop: "-15px",
          color: "grey",
        }}
      >
        First coach (you) free of charge
      </span>
      <div style={{ height: "1px", width: "100%", backgroundColor: "grey" }} />
      <div className="flex row" style={{ gap: "12px", alignSelf: "flex-end" }}>
        <span
          style={{
            fontSize: "20px",
            alignSelf: "flex-end",
          }}
        >
          Total:
        </span>
        <span
          style={{
            fontSize: "20px",
            alignSelf: "flex-end",
          }}
        >
          £ {athleteSeats * 4 + (coachSeats * 4 - 4)}.00
        </span>
      </div>
      <div
        className="white-button alignCenter justifyCenter flex"
        style={{ maxWidth: 220, gap: "8px", alignSelf: "flex-end" }}
        onClick={addSubscription}
      >
        <span
          style={{
            fontWeight: "700",
            lineHeight: 0,
            fontSize: "16px",
            letterSpacing: 0,
          }}
        >
          Start my free trial
        </span>
      </div>
      <span
        style={{
          fontSize: "14px",
          alignSelf: "flex-end",
          marginTop: "-15px",
          color: "grey",
        }}
      >
        You won't be charged until February 1st 2024
      </span>
    </div>
  );
};
export default BasicPricing;
