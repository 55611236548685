import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowRight from "../../assets/icons/forward-black.svg";
// Teams List View
import TeamsSelector from "../../components/dropdowns/TeamsSelector";
// Actions
import { history } from "../..";
import api from "../../services/api/ApiService";
import { createNotification } from "../../store/app/actions";
import { fetchAthletes, setAthletes } from "../../store/athletes/actions";
// COLORS
import { colors } from "./Athletes";
import LeftArrow from "../../assets/icons/arrow-left.svg";

const Athletes = () => {
  const { team_list, athletes } = useSelector((state) => state.athletes);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formatted_date, setFormattedDate] = useState();
  const [alertsData, setAlertsData] = useState(null);

  const onFailure = (error) => {
    setLoading(false);
    dispatch(createNotification("error-toast", error));
  }; // when request failed
  const onSuccess = () => setLoading(false); // when request got success

  // OnSend Checkin Prompt
  function onSendCheckinPrompt(checkinsList, athleteId) {
    if (athleteId) {
      let redAlerts = checkinsList.map((item) => {
        return { ...item, loading: item.id === athleteId ? true : false };
      });
      setAlertsData({ ...alertsData, notCheckinAlerts: redAlerts });
      api
        .post(`coach/team/checkin-prompt/${athleteId}`)
        .then((response) => {
          if (response.status === 200) {
            let redAlerts = checkinsList.map((item) => {
              return {
                ...item,
                loading: false,
                promptText:
                  item.id === athleteId
                    ? `You prompted ${item.name}`
                    : item["promptText"],
              };
            });
            setAlertsData({ ...alertsData, notCheckinAlerts: redAlerts });
          } else {
            onFailure(
              "Something went wrong, please contact to support@wild.ai"
            );
          }
        })
        .catch((error) => {
          let redAlerts = checkinsList.map((item) => {
            return { ...item, loading: false, promptText: null };
          });
          setAlertsData({ ...alertsData, notCheckinAlerts: redAlerts });
          onFailure(error.message);
        });
    } else {
      onFailure("Athlete ID is not correct!");
    }
  }
  // onChange Team name
  function onTeamChange(teamId) {
    setLoading(true);
    dispatch(
      setAthletes({
        athletes: [],
        barChart: [],
        pieChart: [],
        barMin: 0,
        barMax: 0,
      })
    );
    dispatch(fetchAthletes(teamId, formatted_date, onSuccess, onFailure));
  }

  // Actional effects: On Fetched/Update athletes list and Other details
  useEffect(() => {
    if (athletes && athletes.length > 0) {
      setAlertsData(getAletrsStructuredData(athletes));
    }
  }, [athletes]);

  useEffect(() => {
    setLoading(true);
    setFormattedDate(
      moment(window.localStorage.getItem("calendar_date")).format("DD/MM/YYYY")
    );
    dispatch(
      fetchAthletes(
        Number(window.localStorage.getItem("team_Id")),
        moment(window.localStorage.getItem("calendar_date")).format(
          "DD/MM/YYYY"
        ),
        onSuccess,
        onFailure
      )
    );
  }, []);

  function getAletrsStructuredData(list) {
    if (list && list.length > 0) {
      let restructured_alerts_data = list.map((source, index) => {
        const max_severity = Math.max(...source.alerts.map((o) => o.severity));
        return Number(source["readiness"].replace(/%/g, ""))
          ? {
              key: `athlete ${index + 1} `,
              id: source.athlete.pk,
              profile_image: source.athlete.profile_img_url,
              name: `${source.athlete.first_name} ${source.athlete.last_name} `,
              border:
                Number(source["readiness"].replace(/%/g, "")) > 0 &&
                Number(source["readiness"].replace(/%/g, "")) <= 33
                  ? `3px solid ${colors[0]} `
                  : Number(source["readiness"].replace(/%/g, "")) > 33 &&
                    Number(source["readiness"].replace(/%/g, "")) <= 66
                  ? `3px solid ${colors[1]} `
                  : Number(source["readiness"].replace(/%/g, "")) > 66 &&
                    Number(source["readiness"].replace(/%/g, "")) <= 100
                  ? `3px solid ${colors[2]} `
                  : null,
              readiness_score: Number(source["readiness"].replace(/%/g, "")),
              checkinCompliance: source["check_ins"]["compliance"],
              strokeColor:
                Number(source["readiness"].replace(/%/g, "")) > 0 &&
                Number(source["readiness"].replace(/%/g, "")) <= 33
                  ? colors[0]
                  : Number(source["readiness"].replace(/%/g, "")) > 33 &&
                    Number(source["readiness"].replace(/%/g, "")) <= 66
                  ? colors[1]
                  : Number(source["readiness"].replace(/%/g, "")) > 66 &&
                    Number(source["readiness"].replace(/%/g, "")) <= 100
                  ? colors[2]
                  : null,
              alert: source.alerts.filter(
                (item) => item.severity === max_severity
              ),
              backgroundColor: "rgba(255, 255, 255, 0.2)",
            }
          : {
              key: `athlete ${index + 1} `,
              id: source.athlete.pk,
              profile_image: source.athlete.profile_img_url,
              name: `${source.athlete.first_name} ${source.athlete.last_name} `,
              readiness_score: Number(source["readiness"].replace(/%/g, "")),
              checkinCompliance: source["check_ins"]["compliance"],
              border: null,
              alert: null,
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              loading: false,
              promptText: null,
            };
      });
      restructured_alerts_data = restructured_alerts_data.filter(
        (item) => item
      );
      if (restructured_alerts_data && restructured_alerts_data.length) {
        let notCheckinAlerts = restructured_alerts_data.filter(
          (item) => item.readiness_score === 0
        );
        let redAlerts = restructured_alerts_data.filter(
          (item) => item.readiness_score > 0 && item.readiness_score <= 33
        );
        let yellowAlerts = restructured_alerts_data.filter(
          (item) => item.readiness_score > 33 && item.readiness_score <= 66
        );
        let greenAlerts = restructured_alerts_data.filter(
          (item) => item.readiness_score > 66 && item.readiness_score <= 100
        );
        return {
          notCheckinAlerts,
          red: redAlerts,
          yellow: yellowAlerts,
          green: greenAlerts,
        };
      } else {
        return null;
      }
    }
    return null;
  }

  console.log("alertsData", alertsData);

  return (
    <div className="flex column relative w-100 h-100 athletes_container">
      {/* Top Header */}
      <div className="flex justifyBetween alignCenter header">
        <div
          className="pointer absolute flex alignCenter"
          style={{ fontSize: "14px", top: "20px" }}
          onClick={() => history.push("/dashboard/athletes")}
        >
          <img
            src={LeftArrow}
            alt="arrow-left"
            height={14}
            width={14}
            className="pointer"
            style={{ marginRight: "4px" }}
          />
          <span>Back to Athletes</span>
        </div>
        <TeamsSelector
          isLoading={loading}
          teams={team_list && team_list.length > 0 ? team_list : []}
          callAction={onTeamChange}
        />
        <div className="flex alignCenter">
          <p
            className="pointer"
            onClick={() => history.push("/dashboard/athletes")}
          >
            Home
          </p>
          <img
            src={ArrowRight}
            alt="arrow right"
            height={12}
            width={12}
            style={{ margin: "0 4px" }}
          />
          <p>Active Users</p>
        </div>
      </div>

      {/* Table Loading */}
      {loading && (
        <div
          className="flex column alignCenter justifyCenter w-100 h-100"
          style={{ marginTop: 103, minHeight: "calc(100vh - 103px)" }}
        >
          <div className="custom_loader" />
          <p style={{ marginTop: 32 }}>{`Please wait a moment`}</p>
        </div>
      )}

      {!loading && alertsData && (
        <div
          className="flex justifyBetween alignStart w-100 h-100"
          style={{ margin: "120px auto 0" }}
        >
          <div
            className="flex column justifyStart alignStart h-100"
            style={{ width: "45%", padding: "32px 24px 24px" }}
          >
            {/* Title */}
            <p style={{ color: "inherit", marginLeft: 16 }}>Checked in</p>
            <div className="flex column alignCenter w-100">
              {/* Red Alerts */}
              {alertsData["red"].length > 0 && (
                <div
                  className="flex column alignCenter w-100"
                  style={{
                    background: "rgba(0, 0, 0, 0.2)",
                    border: "1px solid #F8484E",
                    padding: 12,
                    margin: "16px 0",
                    borderRadius: 12,
                  }}
                >
                  {alertsData["red"].length &&
                    alertsData["red"].map((redAlert, index) => (
                      <div
                        className="flex justifyBetween alignStart w-100"
                        key={index + 1}
                        style={{
                          border: redAlert["border"],
                          margin: index === 0 ? 0 : "16px 0 0",
                          minHeight: 100,
                          background: "rgba(255, 255, 255, 0.2)",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                          borderRadius: 12,
                          padding: 12,
                        }}
                      >
                        <div className="flex alignCenter">
                          <img
                            src={redAlert["profile_image"]}
                            alt="profile pick"
                            height={60}
                            width={60}
                            style={{ borderRadius: "50%", objectFit: "cover" }}
                          />
                          <div
                            className="flex column alignStart justifyCenter"
                            style={{ marginLeft: 16 }}
                          >
                            <p>{window.localStorage.getItem("team_name")}</p>
                            <p
                              className="pointer"
                              style={{ margin: "0 0 8px" }}
                              onClick={() =>
                                history.push(
                                  `/dashboard/athlete?id=${redAlert["id"]}`
                                )
                              }
                            >
                              {redAlert["name"]}
                            </p>
                            {redAlert["alert"] &&
                              redAlert["alert"].length > 0 && (
                                <p>{`${
                                  redAlert["alert"][0]["severity"] === 1
                                    ? "Mild"
                                    : redAlert["alert"][0]["severity"] === 3
                                    ? "Moderate"
                                    : redAlert["alert"][0]["severity"] === 5
                                    ? "Severe"
                                    : ""
                                } ${redAlert["alert"][0]["name"]}`}</p>
                              )}
                          </div>
                        </div>
                        {redAlert["alert"] && redAlert["alert"].length > 0 && (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13 7.66667V14.3333M13 18.3333V19M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
                              stroke="#F8484E"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    ))}
                </div>
              )}

              {/* Yellow Alerts */}
              {alertsData["yellow"].length > 0 && (
                <div
                  className="flex column alignCenter w-100"
                  style={{
                    background: "rgba(0, 0, 0, 0.2)",
                    border: "1px solid #EFE47F",
                    padding: 12,
                    margin: "16px 0",
                    borderRadius: 12,
                  }}
                >
                  {alertsData["yellow"].length &&
                    alertsData["yellow"].map((yellowAlert, index) => (
                      <div
                        className="flex justifyBetween alignStart w-100"
                        key={index + 1}
                        style={{
                          border: yellowAlert["border"],
                          margin: index === 0 ? 0 : "16px 0 0",
                          minHeight: 100,
                          background: "rgba(255, 255, 255, 0.2)",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                          borderRadius: 12,
                          padding: 12,
                        }}
                      >
                        <div className="flex alignCenter">
                          <img
                            src={yellowAlert["profile_image"]}
                            alt="profile pick"
                            height={60}
                            width={60}
                            style={{ borderRadius: "50%", objectFit: "cover" }}
                          />
                          <div
                            className="flex column alignStart justifyCenter"
                            style={{ marginLeft: 16 }}
                          >
                            <p>{window.localStorage.getItem("team_name")}</p>
                            <p
                              style={{ margin: "0 0 8px" }}
                              className="pointer"
                              onClick={() =>
                                history.push(
                                  `/dashboard/athlete?id=${yellowAlert["id"]}`
                                )
                              }
                            >
                              {" "}
                              {yellowAlert["name"]}
                            </p>
                            {yellowAlert["alert"] &&
                              yellowAlert["alert"].length > 0 && (
                                <p>{`${
                                  yellowAlert["alert"][0]["severity"] === 1
                                    ? "Mild"
                                    : yellowAlert["alert"][0]["severity"] === 3
                                    ? "Moderate"
                                    : yellowAlert["alert"][0]["severity"] === 5
                                    ? "Severe"
                                    : ""
                                } ${yellowAlert["alert"][0]["name"]}`}</p>
                              )}
                          </div>
                        </div>
                        {yellowAlert["alert"] &&
                          yellowAlert["alert"].length > 0 && (
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 26 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 7.66667V14.3333M13 18.3333V19M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
                                stroke="#F8484E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                      </div>
                    ))}
                </div>
              )}

              {/* Green Alerts */}
              {alertsData["green"].length > 0 && (
                <div
                  className="flex column alignCenter w-100"
                  style={{
                    background: "rgba(0, 0, 0, 0.2)",
                    border: "1px solid #70C089",
                    padding: 12,
                    margin: "16px 0",
                    borderRadius: 12,
                  }}
                >
                  {alertsData["green"].length &&
                    alertsData["green"].map((greenAlert, index) => (
                      <div
                        className="flex justifyBetween alignStart w-100"
                        key={index + 1}
                        style={{
                          border: greenAlert["border"],
                          margin: index === 0 ? 0 : "16px 0 0",
                          minHeight: 100,
                          background: "rgba(255, 255, 255, 0.2)",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                          borderRadius: 12,
                          padding: 12,
                        }}
                      >
                        <div className="flex alignCenter">
                          <img
                            src={greenAlert["profile_image"]}
                            alt="profile pick"
                            height={60}
                            width={60}
                            style={{ borderRadius: "50%", objectFit: "cover" }}
                          />
                          <div
                            className="flex column alignStart justifyCenter"
                            style={{ marginLeft: 16 }}
                          >
                            <p>{window.localStorage.getItem("team_name")}</p>
                            <p
                              className="pointer"
                              style={{ margin: "0 0 8px" }}
                              onClick={() =>
                                history.push(
                                  `/dashboard/athlete?id=${greenAlert["id"]}`
                                )
                              }
                            >
                              {greenAlert["name"]}
                            </p>
                            {greenAlert["alert"] &&
                              greenAlert["alert"].length > 0 && (
                                <p>{`${
                                  greenAlert["alert"][0]["severity"] === 1
                                    ? "Mild"
                                    : greenAlert["alert"][0]["severity"] === 3
                                    ? "Moderate"
                                    : greenAlert["alert"][0]["severity"] === 5
                                    ? "Severe"
                                    : ""
                                } ${greenAlert["alert"][0]["name"]}`}</p>
                              )}
                          </div>
                        </div>
                        {greenAlert["alert"] &&
                          greenAlert["alert"].length > 0 && (
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 26 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 7.66667V14.3333M13 18.3333V19M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
                                stroke="#F8484E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                      </div>
                    ))}
                </div>
              )}

              {alertsData["red"].length === 0 &&
                alertsData["yellow"].length === 0 &&
                alertsData["green"].length === 0 && (
                  <p style={{ textAlign: "center", margin: "32px auto 0" }}>
                    No checkin exist at the moment!
                  </p>
                )}
            </div>
          </div>
          <div
            className="flex column justifyStart alignStart h-100"
            style={{ width: "45%", padding: "32px 24px 24px" }}
          >
            {/* Title */}
            <p style={{ color: "inherit", marginLeft: 16 }}>Not Checked in</p>
            <div className="flex column alignCenter w-100">
              {/* No Check-in Alerts */}
              {alertsData["notCheckinAlerts"].length > 0 && (
                <div
                  className="flex column alignCenter w-100"
                  style={{
                    background: "rgba(0, 0, 0, 0.2)",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    padding: 12,
                    margin: "16px 0",
                    borderRadius: 12,
                  }}
                >
                  {alertsData["notCheckinAlerts"].length &&
                    alertsData["notCheckinAlerts"].map(
                      (notCheckinAlert, index) => (
                        <div
                          className="flex justifyBetween alignStart w-100"
                          key={index + 1}
                          style={{
                            margin: index === 0 ? 0 : "16px 0 0",
                            minHeight: 100,
                            background: "rgba(255, 255, 255, 0.2)",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                            borderRadius: 12,
                            padding: 12,
                          }}
                        >
                          <div className="flex alignCenter">
                            <img
                              src={notCheckinAlert["profile_image"]}
                              alt="profile pick"
                              height={60}
                              width={60}
                              style={{
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                            <div
                              className="flex column alignStart justifyCenter"
                              style={{ marginLeft: 16 }}
                            >
                              <p>{window.localStorage.getItem("team_name")}</p>
                              <p
                                className="pointer"
                                style={{ margin: "0 0 8px" }}
                                onClick={() =>
                                  history.push(
                                    `/dashboard/athlete?id=${notCheckinAlert["id"]}`
                                  )
                                }
                              >
                                {notCheckinAlert["name"]}
                              </p>
                              {notCheckinAlert["alert"] &&
                                notCheckinAlert["alert"].length > 0 && (
                                  <p>{`${
                                    notCheckinAlert["alert"][0]["severity"] ===
                                    1
                                      ? "Mild"
                                      : notCheckinAlert["alert"][0][
                                          "severity"
                                        ] === 3
                                      ? "Moderate"
                                      : notCheckinAlert["alert"][0][
                                          "severity"
                                        ] === 5
                                      ? "Severe"
                                      : ""
                                  } ${notCheckinAlert["alert"][0]["name"]}`}</p>
                                )}
                            </div>
                          </div>

                          {notCheckinAlert["loading"] ? (
                            <div className="custom_button_loader" />
                          ) : notCheckinAlert["promptText"] ? (
                            <p style={{ color: "#B0B0AD", fontSize: 14 }}>
                              {notCheckinAlert["promptText"]}
                            </p>
                          ) : (
                            <svg
                              onClick={() =>
                                !notCheckinAlert["loading"] &&
                                onSendCheckinPrompt(
                                  alertsData["notCheckinAlerts"],
                                  notCheckinAlert.id
                                )
                              }
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ cursor: "pointer" }}
                            >
                              <path
                                d="M13 15V6.99998M13 15L18.5039 18.145C19.1705 18.526 20 18.0446 20 17.2768V4.72316C20 3.95533 19.1705 3.47397 18.5039 3.85491L13 6.99998M13 15H10M13 6.99998H7C4.79086 6.99998 3 8.79084 3 11C3 13.2091 4.79086 15 7 15M7 15V19.5C7 20.3284 7.67157 21 8.5 21C9.32843 21 10 20.3284 10 19.5V15M7 15H10"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      )
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Athletes;
