import React, { useState } from "react";
import moment from "moment";
// import CalendarAction from "../calendar/CalendarAction";
import ArrowLeft from "../../assets/icons/backward-black.svg";
import ArrowRight from "../../assets/icons/forward-black.svg";
import DisableArrowLeft from "../../assets/icons/backward-gray.svg";
import DisableArrowRight from "../../assets/icons/forward-gray.svg";
import ArrowDown from "../../assets/icons/down-toggle.svg";
import ArrowUp from "../../assets/icons/up-toggle.svg";
import CalendarWidget from "../calendar";

export function previousDate(date) {
  var currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() - 1);
  return currentDate;
}

export function nextDate(date) {
  var currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate;
}

export function checkDate(myDate) {
  let date = moment(myDate);
  if (date.calendar().split(" ")[0] === "Yesterday") {
    return "Yesterday";
  } else if (date.calendar().split(" ")[0] === "Today") {
    return "Today";
  } else if (date.calendar().split(" ")[0] === "Tomorrow") {
    return "Tomorrow";
  } else {
    return false;
  }
}

const DateView = ({ date, status, callAction }) => {
  let [show, setShow] = useState(false);

  return (
    <div className="flex justifyBetween alignCenter date_selector">
      {/* left arrow */}
      {status ? (
        <img src={ArrowLeft} alt="arrow-left" height={20} width={20}
          onClick={() => callAction(previousDate(date))}
          style={{ marginTop: 4 }}
        />
      ) : (
        <img className="pointer_not_allowed" src={DisableArrowLeft} alt="arrow-left" height={20} width={20} style={{ marginTop: 4 }} />
      )}

      <div className={`flex justifyCenter alignCenter w-100 pointer ${status ? null : "pointer_not_allowed"}`}
        onClick={() => {
          if (status) {
            setShow(!show)
          }
        }}
      >
        {/* <CalendarAction date={date} /> */}

        <div className="flex column alignCenter">
          {checkDate(date) ?
            <p className="date" style={{ color: status ? '#FFFFFF' : '#8f8f8f' }}>{checkDate(date)}</p> :
            <p className="date" style={{ color: status ? '#FFFFFF' : '#8f8f8f' }}> {moment(date).format("DD-MM-YYYY")}</p>}

          {(status && show) ?
            <img src={ArrowUp} alt="arrow-up" height={4.24} width={6.24} />
            : (status && !show) ?
              <img src={ArrowDown} alt="arrow-down" height={4.24} width={6.24} />
              : null}
        </div>
      </div>

      {/* right arrow */}
      {status ? (
        <img src={ArrowRight} alt="arrow-right" height={20} width={20} style={{ marginTop: 4 }}
          onClick={() => callAction(nextDate(date))}
        />
      ) : (
        <img className="pointer_not_allowed" src={DisableArrowRight} alt="arrow-right" height={19} width={19} style={{ marginTop: 4 }} />
      )}

      {show && (
        <CalendarWidget
          hide={() => setShow(false)}
          onClickToViewCalendar={() => setShow(false)}
          onDateChange={(newDate) => callAction(newDate)}
        />
      )}
    </div>
  );
};

export default DateView;
