import { call, put, takeEvery } from "redux-saga/effects";
import { getErrorMessage } from "../../_helpers/Requests";
import api from "../../services/api/ApiService";
import { FETCH_CALENDAR, setCalendar } from "./actions";

function calendarFetch(athleteId, startDate, endDate) {
  return api.get(
    `user/calendar_scroll?athlete_id=${athleteId}&from=${startDate}&to=${endDate}`
  );
}

function* getAthleteCalendar({ payload: { athleteId, startDate, endDate, onError } }) {
  const cacheKey = `${athleteId}_${startDate}_${endDate}`;
  try {
    const response = yield call(calendarFetch, athleteId, startDate, endDate);
    if (response['status'] === 200) {
      if (response['data']) {
        yield put(setCalendar(response, cacheKey));
      } else {
        yield call(onError, 'It seems that no cycle data is found');
      }
    } else {
      yield call(onError, getErrorMessage(response));
    }
  } catch (error) {
    yield call(onError, error.message);
  }

}

function* athleteCalendarSaga() {
  yield takeEvery(FETCH_CALENDAR, getAthleteCalendar);
}

export default athleteCalendarSaga;
