import { put, takeEvery, call } from "redux-saga/effects";
import api from "../../services/api/ApiService";
import {
  FETCH_TRAINING_PLANS,
  setTrainingPlans,
  FETCH_TRAINING_PLAN_SESSIONS,
  setTrainingPlanSessions,
  FETCH_SPORT_TYPES,
  FETCH_SESSION_SPORT_TYPES,
  setSessionSportTypes,
  setSportTypes,
  FETCH_TRAINING_INTENSITIES,
  setTrainingIntensities,
  FETCH_TRAINING_LEVELS,
  setTrainingLevels,
  EDIT_TRAINING_PLAN,
  setTrainingPlan,
  EDIT_TRAINING_PLAN_SESSION,
  setTrainingPlanSession,
  SUBMIT_TRAINING_PLAN,
  SUBMIT_TRAINING_PLAN_SESSION,
  UPDATE_TRAINING_PLAN,
  UPDATE_TRAINING_PLAN_WITH_POST,
  UPDATE_TRAINING_PLAN_SESSION,
  UPDATE_TRAINING_PLAN_SESSION_WITH_POST,
  DELETE_TRAINING_PLAN,
  DELETE_TRAINING_PLAN_SESSIONS,
  DELETE_TRAINING_PLAN_SESSION,
  UPDATE_TRAINING_PLAN_SESSIONS,
  SUBMIT_TRAINING_PLAN_FOR_SPREADSHEET,
  SUBMIT_TRAINING_PLAN_SESSION_FOR_SPREADSHEET,
  setPlanId,
  setErrorMessage,
  ON_FAILED_DELETE_TRAINING_PLAN,
  FETCH_REVIEW_PLANS,
  FETCH_REJECTED_PLANS,
  FETCH_PUBLISH_PLANS,
  FETCH_PUBLISHED_PLANS,
  setAdminPlans,
  SEND_REJECT_PLAN_CALL,
  SEND_ACCEPT_PLAN_CALL,
  SEND_PUBLISH_PLAN_CALL,
  GET_ADMIN_TRAINING_PLAN,
  GET_ADMIN_TRAINING_SESSIONS,
  GET_ADMIN_TRAINING_SESSION,
  UPDATE_PLAN_TAGS,
  FETCH_PLANS
} from "./actions";

// get training published plans
function get_published_plans_list() {
  const resp = api.get(`coach/admin/published/plans`);
  return resp;
}

function* fetchPublishedPlans({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(get_published_plans_list);
    yield put(setAdminPlans(data));
    yield call(onSuccess);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// get training publish plans
function get_publish_plans_list() {
  const resp = api.get(`coach/admin/publish/plans`);
  return resp;
}

function* fetchPublishPlans({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(get_publish_plans_list);
    yield put(setAdminPlans(data));
    yield call(onSuccess);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// get training rejected plans
function get_rejected_plans_list() {
  const resp = api.get(`coach/admin/rejected/plans`);
  return resp;
}

function* fetchRejectedPlans({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(get_rejected_plans_list);
    yield put(setAdminPlans(data));
    yield call(onSuccess);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// get training review plans
function get_review_plans_list() {
  const resp = api.get(`coach/admin/review/plans`);
  return resp;
}

function* fetchReviewPlans({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(get_review_plans_list);
    yield put(setAdminPlans(data));
    yield call(onSuccess);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// get Training Plans
function get_plans_list() {
  const resp = api.get(`coach/plans`);
  return resp;
}

function* fetchTrainingPlans({ payload: { onSuccess, onError } }) {
  try {
    const { data } = yield call(get_plans_list);
    yield put(setTrainingPlans(data));
    yield call(onSuccess);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

function* fetchPlans({ payload: { onError } }) {
  try {
    const { data } = yield call(get_plans_list);
    yield put(setTrainingPlans(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

function get_plan_sessions_list(id) {
  const resp = api.get(`coach/plan/${id}/sessions`);
  return resp;
}

function* fetchTrainingPlanSessions({ payload: { id, onSuccess, onError } }) {
  try {
    const { data } = yield call(get_plan_sessions_list, id);
    yield put(setTrainingPlanSessions(data));
    yield call(onSuccess);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const { data: { details } } = err.response;
        yield call(onError, details);
        yield put(setTrainingPlanSessions(null));
      }
    }
  }
}

// get sport types for creating training plans
function get_sessions_sport_types() {
  const resp = api.get(`activity/available_sport_types`);
  return resp;
}

function* fetchSportTypesForSessions({ payload: { onError } }) {
  try {
    const { data } = yield call(get_sessions_sport_types);
    yield put(setSessionSportTypes(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// get sport types for creating training plans
function get_training_sport_types() {
  const resp = api.get(`training_plans/available_sport_types`);
  return resp;
}

function* fetchSportTypes({ payload: { onError } }) {
  try {
    const { data } = yield call(get_training_sport_types);
    yield put(setSportTypes(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// get sport types for creating training plans
function get_training_intensities() {
  const resp = api.get(`training_plans/available_intensities`);
  return resp;
}

function* fetchTrainingIntensities({ payload: { onError } }) {
  try {
    const { data } = yield call(get_training_intensities);
    yield put(setTrainingIntensities(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// get training levels for creating training plans
function get_training_level_types() {
  const resp = api.get(`training_plans/available_levels`);
  return resp;
}

function* fetchTrainingLevels({ payload: { onError } }) {
  try {
    const { data } = yield call(get_training_level_types);
    yield put(setTrainingLevels(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// get training plan from the list of plans
function get_training_plan(id) {
  const resp = api.get(`coach/plan/${id}`);
  return resp;
}

function* fetchTrainingPlan({ payload: { id, onError } }) {
  try {
    const { data } = yield call(get_training_plan, id);
    yield put(setTrainingPlan(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const { data: { details } } = err.response;
        yield call(onError, details);
        yield put(setTrainingPlan(null));
      }
    }
  }
}

// get training plan from the list of plans
function get_training_plan_session(plan_pk, id) {
  const resp = api.get(`coach/plan/${plan_pk}/session/${id}`);
  return resp;
}

function* fetchTrainingPlanSession({ payload: { plan_pk, id, onError } }) {
  try {
    const { data } = yield call(get_training_plan_session, plan_pk, id);
    yield put(setTrainingPlanSession(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

function set_training_plan_for_spreadsheet(values) {
  const resp = api.post("coach/plans", values);
  return resp;
}

function* submitPlanSpreadsheet({ payload: { values, onSuccess, onError } }) {
  try {
    const { data } = yield call(set_training_plan_for_spreadsheet, values);
    if (data) {
      yield call(onSuccess, "Successfully created new plan", data.pk);
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      const { data: { details } } = err.response;
      if (err.response) {
        if (err.response.status === 400) {
          if (typeof details === 'string') {
            yield call(onError, details);
          } else if (typeof details === 'object') {
            let errorsKeys = Object.keys(details);
            let errors = Object.values(details);
            if (typeof errors[0] === 'string') {
              yield call(onError, `${errorsKeys[0]}, ${errors[0]}`);
            } else {
              yield call(onError, `${errorsKeys[0]}, ${errors[0][0]}`);
            }
          }
        } else {
          yield call(onError, details);
        }
      }
    }
  }
}

// create new training plan list
function set_training_plan(values) {
  const resp = api.post("coach/plans", values, {
    headers: { 'content-type': 'multipart/form-data' }
  });
  return resp;
}

function* submitPlan({ payload: { values, onSuccess, onError } }) {
  try {
    const { data } = yield call(set_training_plan, values);
    if (data) {
      yield call(onSuccess, "Successfully created new plan");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      const { data: { details } } = err.response;
      if (err.response) {
        if (err.response.status === 400) {
          if (typeof details === 'string') {
            yield call(onError, details);
          } else if (typeof details === 'object') {
            let errorsKeys = Object.keys(details);
            let errors = Object.values(details);
            if (typeof errors[0] === 'string') {
              yield call(onError, `${errorsKeys[0]}, ${errors[0]}`);
            } else {
              yield call(onError, `${errorsKeys[0]}, ${errors[0][0]}`);
            }
          }
        } else {
          yield call(onError, details);
        }
      }
    }
  }
}

function set_training_plan_session_for_spreadsheet(id, values) {
  const resp = api.post(`coach/plan/${id}/sessions`, values);
  return resp;
}

function* submitPlanSessionForSpreadsheet({ payload: { id, values, onSuccess, onError, index } }) {
  try {
    const { data } = yield call(set_training_plan_session_for_spreadsheet, id, values);
    if (data) {
      yield call(onSuccess, "Successfully created new plan session");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      const { data: { details } } = err.response;
      if (err.response) {
        if (err.response.status === 400) {
          yield put(setPlanId(id));
          window.localStorage.setItem('delete_plan_id', id);
          if (typeof details === 'string') {
            yield put(setErrorMessage(details));
          } else if (typeof details === 'object') {
            let errorsKeys = Object.keys(details);
            let errors = Object.values(details);
            if (typeof errors[0] === 'string') {
              yield put(setErrorMessage(errors[0]));
            } else {
              yield put(setErrorMessage(`${errorsKeys[0]}: ${errors[0][0]}`));
            }
          }
        } else {
          yield call(onError, details);
        }
      }
    }
  }
}

// create new training plan session list
function set_training_plan_session(id, values) {
  const resp = api.post(`coach/plan/${id}/sessions`, values
    //  {
    //   headers: { 'content-type': 'multipart/form-data' }
    // }
  );
  return resp;
}

function* submitPlanSession({ payload: { id, values, onSuccess, onError } }) {
  try {
    const { data } = yield call(set_training_plan_session, id, values);
    if (data) {
      yield call(onSuccess, "Successfully created new plan session");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      const { data: { details } } = err.response;
      if (err.response) {
        if (err.response.status === 400) {
          if (typeof details === 'string') {
            yield call(onError, details);
          } else if (typeof details === 'object') {
            let errorsKeys = Object.keys(details);
            let errors = Object.values(details);
            if (typeof errors[0] === 'string') {
              yield call(onError, `${errorsKeys[0]}, ${errors[0]}`);
            } else {
              yield call(onError, `${errorsKeys[0]}, ${errors[0][0]}`);
            }
          }
        } else {
          yield call(onError, details);
        }
      }
    }
  }
}

// update particular training plan
function update_training_plan(id, values) {
  const resp = api.put(`coach/plan/${id}`, values, {
    headers: { 'content-type': 'multipart/form-data' }
  });
  return resp;
}

function* updatePlan({ payload: { id, values, onSuccess, onError } }) {
  try {
    const { data } = yield call(update_training_plan, id, values);
    if (data) {
      yield call(onSuccess, "Your changes have been saved!!");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// update particular training plan session
function update_training_plan_session(plan_id, id, values) {
  const resp = api.put(`coach/plan/${plan_id}/session/${id}`, values
    // {
    // headers: { 'content-type': 'multipart/form-data' }
    // }
  );
  return resp;
}

function* updatePlanSession({ payload: { plan_id, session_id, values, onSuccess, onError } }) {
  try {
    const { data } = yield call(update_training_plan_session, plan_id, session_id, values,);
    if (data) {
      yield call(onSuccess, "Successfully update your plan session!");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// update sessions to submit for review
function update_training_plan_sessions(id, values) {
  const resp = api.put(`coach/plan/${id}`, values);
  return resp;
}

function* updatePlanSessions({ payload: { id, onSuccess, onError } }) {
  try {
    const { data } = yield call(update_training_plan_sessions, id, {});
    if (data) {
      yield call(onSuccess, "Successfully submitted for review!");
      yield put("/dashboard/training_plans");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// Update plan with post request
function update_training_plan_with_post_req(id, values) {
  const resp = api.post(`coach/plan/${id}`, values, {
    headers: { 'content-type': 'multipart/form-data' }
  });
  return resp;
}

function* updatePlanWithPost({ payload: { id, values, onSuccess, onError } }) {
  try {
    const { data } = yield call(update_training_plan_with_post_req, id, values);
    if (data) {
      yield put(setTrainingPlans(data));
      yield call(onSuccess, "Your changes have been saved!!");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// Update plan session with post request
function update_training_plan_session_with_post_req(plan_id, id, values) {
  const resp = api.post(`coach/plan/${plan_id}/session/${id}`, values, {
    headers: { 'content-type': 'multipart/form-data' }
  });
  return resp;
}

function* updatePlanSessionWithPost({ payload: { plan_id, id, values, onSuccess, onError } }) {
  try {
    const { data } = yield call(update_training_plan_session_with_post_req, plan_id, id, values);
    if (data) {
      yield call(onSuccess, "Your changes have been saved! session!");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// delete training plan from the list of plans
function delete_training_plan(id) {
  const resp = api.delete(`coach/plan/${id}`);
  return resp;
}

function* deletePlan({ payload: { id, onSuccess, onError } }) {
  try {
    yield call(delete_training_plan, id);
    yield call(onSuccess, "Training plan has been deleted.");
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// on failed delete plan
function delete_training_plan_on_failed(id) {
  const resp = api.delete(`coach/plan/${id}`);
  return resp;
}

function* deletePlanOnFailed({ payload: { id, onError, onSuccess } }) {
  try {
    yield call(delete_training_plan_on_failed, id);
    window.localStorage.removeItem('delete_plan_id');
    yield call(onSuccess);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const { data: { details } } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// delete training plan sessions list entirly
function delete_training_plan_sessions(id) {
  const resp = api.delete(`coach/plan/${id}/sessions`);
  return resp;
}

function* deletePlanSessions({ payload: { id, onSuccess, onError } }) {
  try {
    yield call(delete_training_plan_sessions, id);
    yield call(onSuccess, "Training plan sessions has been deleted.");
    yield put("/dashboard/training_plans");
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// delete training plan from the list of plans
function delete_training_plan_session(plan_id, id) {
  const resp = api.delete(`coach/plan/${plan_id}/session/${id}`);
  return resp;
}

function* deletePlanSession({ payload: { plan_id, id, onSuccess, onError } }) {
  try {
    yield call(delete_training_plan_session, plan_id, id);
    yield call(onSuccess, "Training plan session has been deleted.");
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// Reject plan
function send_reject_plan_request(id, values) {
  const resp = api.post(`coach/admin/review/plan/${id}`, values);
  return resp;
}

function* rejectPlan({ payload: { id, values, onSuccess, onError } }) {
  try {
    yield call(send_reject_plan_request, id, values);
    yield call(onSuccess, "Training plan has been rejected.");
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// Accept Plan
function send_accept_plan_request(id, values) {
  const resp = api.post(`coach/admin/review/plan/${id}`, values);
  return resp;
}

function* acceptPlan({ payload: { id, values, onSuccess, onError } }) {
  try {
    yield call(send_accept_plan_request, id, values);
    yield call(onSuccess, "Training plan has been accepted.");
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// Publish plan
function send_publish_plan_request(id) {
  const resp = api.post(`coach/admin/publish/plan/${id}`);
  return resp;
}

function* publishPlan({ payload: { id, onSuccess, onError } }) {
  try {
    yield call(send_publish_plan_request, id);
    yield call(onSuccess, "Training plan has been published.");
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// Get admin plan detail
function get_admin_training_plan_detail(id, tab) {
  let resp;
  if (tab === "Review") {
    resp = api.get(`coach/admin/review/plan/${id}`);
  } else if (tab === "Rejected") {
    resp = api.get(`coach/admin/rejected/plan/${id}`);
  } else if (tab === "Publish") {
    resp = api.get(`coach/admin/publish/plan/${id}`);
  } else if (tab === "Published") {
    resp = api.get(`coach/admin/published/plan/${id}`);
  }
  return resp;
}

function* findTrainingPlan({ payload: { id, onError } }) {
  try {
    const active_tab = window.localStorage.getItem("tab");
    const { data } = yield call(get_admin_training_plan_detail, id, active_tab);
    yield put(setTrainingPlan(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// Get admin plan sessions
function get_admin_training_plan_sessions(id, tab) {
  let resp;
  if (tab === "Review") {
    resp = api.get(`coach/admin/review/plan/${id}/sessions`);
  } else if (tab === "Rejected") {
    resp = api.get(`coach/admin/rejected/plan/${id}/sessions`);
  } else if (tab === "Publish") {
    resp = api.get(`coach/admin/publish/plan/${id}/sessions`);
  } else if (tab === "Published") {
    resp = api.get(`coach/admin/published/plan/${id}/sessions`);
  }
  return resp;
}

function* findTrainingPlanSessions({ payload: { id, onSuccess, onError } }) {
  try {
    const active_tab = window.localStorage.getItem("tab");
    const { data } = yield call(get_admin_training_plan_sessions, id, active_tab);
    yield put(setTrainingPlanSessions(data));
    yield call(onSuccess);
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

// Get admin plan session detail
function get_admin_training_plan_session(id, session_id, tab) {
  let resp;
  if (tab === "Review") {
    resp = api.get(`coach/admin/review/plan/${id}/session/${session_id}`);
  } else if (tab === "Rejected") {
    resp = api.get(`coach/admin/rejected/plan/${id}/session/${session_id}`);
  } else if (tab === "Publish") {
    resp = api.get(`coach/admin/publish/plan/${id}/session/${session_id}`);
  } else if (tab === "Published") {
    resp = api.get(`coach/admin/published/plan/${id}/session/${session_id}`);
  }
  return resp;
}

function* findTrainingPlanSession({ payload: { id, session_id, onError } }) {
  try {
    const active_tab = window.localStorage.getItem("tab");
    const { data } = yield call(get_admin_training_plan_session, id, session_id, active_tab);
    yield put(setTrainingPlanSession(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

function update_training_plan_tags(id, values) {
  const resp = api.post(`coach/plan/${id}`, values);
  return resp;
}

function* updateTags({ payload: { id, values, onSuccess, onError } }) {
  try {
    const { data } = yield call(update_training_plan_tags, id, values);
    if (data) {
      yield call(onSuccess, "Your changes have been saved!!");
    }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield call(onError, "This route is not exists. please contact with support@wild.ai");
    } else {
      if (err.response) {
        const {
          data: { details },
        } = err.response;
        yield call(onError, details);
      }
    }
  }
}

function* trainingsSagas() {
  yield takeEvery(FETCH_TRAINING_PLANS, fetchTrainingPlans);
  yield takeEvery(FETCH_PLANS, fetchPlans);
  yield takeEvery(FETCH_TRAINING_PLAN_SESSIONS, fetchTrainingPlanSessions)
  yield takeEvery(FETCH_SPORT_TYPES, fetchSportTypes);
  yield takeEvery(FETCH_SESSION_SPORT_TYPES, fetchSportTypesForSessions);
  yield takeEvery(FETCH_TRAINING_INTENSITIES, fetchTrainingIntensities);
  yield takeEvery(FETCH_TRAINING_LEVELS, fetchTrainingLevels);
  yield takeEvery(SUBMIT_TRAINING_PLAN, submitPlan);
  yield takeEvery(SUBMIT_TRAINING_PLAN_SESSION, submitPlanSession);
  yield takeEvery(EDIT_TRAINING_PLAN, fetchTrainingPlan);
  yield takeEvery(EDIT_TRAINING_PLAN_SESSION, fetchTrainingPlanSession);
  yield takeEvery(UPDATE_TRAINING_PLAN, updatePlan);
  yield takeEvery(UPDATE_TRAINING_PLAN_WITH_POST, updatePlanWithPost);
  yield takeEvery(UPDATE_TRAINING_PLAN_SESSION, updatePlanSession);
  yield takeEvery(UPDATE_TRAINING_PLAN_SESSION_WITH_POST, updatePlanSessionWithPost);
  yield takeEvery(DELETE_TRAINING_PLAN, deletePlan);
  yield takeEvery(ON_FAILED_DELETE_TRAINING_PLAN, deletePlanOnFailed);
  yield takeEvery(DELETE_TRAINING_PLAN_SESSIONS, deletePlanSessions);
  yield takeEvery(DELETE_TRAINING_PLAN_SESSION, deletePlanSession);
  yield takeEvery(UPDATE_TRAINING_PLAN_SESSIONS, updatePlanSessions);
  yield takeEvery(SUBMIT_TRAINING_PLAN_FOR_SPREADSHEET, submitPlanSpreadsheet);
  yield takeEvery(SUBMIT_TRAINING_PLAN_SESSION_FOR_SPREADSHEET, submitPlanSessionForSpreadsheet);
  // admin
  yield takeEvery(FETCH_REVIEW_PLANS, fetchReviewPlans);
  yield takeEvery(FETCH_REJECTED_PLANS, fetchRejectedPlans);
  yield takeEvery(FETCH_PUBLISH_PLANS, fetchPublishPlans);
  yield takeEvery(FETCH_PUBLISHED_PLANS, fetchPublishedPlans);
  yield takeEvery(SEND_REJECT_PLAN_CALL, rejectPlan);
  yield takeEvery(SEND_ACCEPT_PLAN_CALL, acceptPlan);
  yield takeEvery(SEND_PUBLISH_PLAN_CALL, publishPlan);
  yield takeEvery(GET_ADMIN_TRAINING_PLAN, findTrainingPlan);
  yield takeEvery(GET_ADMIN_TRAINING_SESSIONS, findTrainingPlanSessions);
  yield takeEvery(GET_ADMIN_TRAINING_SESSION, findTrainingPlanSession);
  yield takeEvery(UPDATE_PLAN_TAGS, updateTags);
}

export default trainingsSagas;
