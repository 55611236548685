import React, { useEffect, useRef } from "react";
// Provider
import useCalendar from "./useCalendar";
// Icons
import LeftArrow from "../../assets/icons/chevron-left-light.svg";
import RightArrow from "../../assets/icons/chevron-right-light.svg";

const Calendar = ({ onClickToViewCalendar, onDateChange, hide }) => {
  const {
    calendarRows,
    selectedDate,
    todayFormatted,
    daysShort,
    monthNames,
    getNextMonth,
    shortMonthNames,
    getPrevMonth,
    changeDate,
  } = useCalendar();

  function useOutsideAlerter(ref, hide) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          hide();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, hide]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hide);

  const dateClickHandler = (date) => {
    var dateString = date;
    var dateParts = dateString.split("/");
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    changeDate(dateObject);
    onDateChange(dateObject);
    onClickToViewCalendar(false);
    // window.localStorage.setItem("calendar_date", dateObject);
  };

  const previousMonthIndex = (index) => {
    if (index === -1) {
      return 11;
    }
    return index;
  };

  const nextMonthPrefix = (index) => {
    if (index === 12) {
      index = 0;
    }
    return index;
  };

  return (
    <div className="calendar_container" ref={wrapperRef}>
      <div className="flex justifyBetween alignCenter" style={{ padding: "20px 20px 12px" }}>
        {/* Previous Month */}
        <div className="flex alignCenter" onClick={getPrevMonth}>
          <img src={LeftArrow} alt="arrow-left" height={26} width={26} className="pointer" style={{ marginRight: 4 }} />
          <p className="short-names">
            {`${shortMonthNames[previousMonthIndex(selectedDate.getMonth() - 1)]}`}
          </p>
        </div>

        {/* Current Month */}
        <p className="month-view">
          {`${monthNames[selectedDate.getMonth()]}, ${selectedDate.getFullYear()}`}
        </p>

        {/* Next Month */}
        <div className="flex alignCenter" onClick={getNextMonth}>
          <p className="short-names">
            {`${shortMonthNames[nextMonthPrefix(selectedDate.getMonth() + 1)]}`}
          </p>
          <img src={RightArrow} alt="arrow-right" height={18} width={20} className="pointer" style={{ marginLeft: 4 }} />
        </div>
      </div>
      <div className="calendar-table">
        {/* Days */}
        <div className="flex alignCenter justifyBetween w-100">
          {daysShort.map((day) => <h4 className="th" key={day}>{day}</h4>)}
        </div>
        {/* Dates */}
        <div className="flex column alignCenter justifyStart w-100" >
          {Object.values(calendarRows).map((cols, index) => (
            <div className="flex justifyBetween alignCenter w-100" key={cols[0].date} style={{ marginTop: index === 0 ? 0 : 4 }}>
              {cols.map((col) => col.date === todayFormatted ?
                <p key={col.date} className='today td' onClick={() => dateClickHandler(col.date)}>{col.value}</p>
                : <p key={col.date} className={`${col.classes} td`} onClick={() => dateClickHandler(col.date)}>{col.value}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
