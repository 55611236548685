import React, { useState } from "react";
import axios from "axios";
import CrossIcon from "../assets/icons/closs-tab.svg";

const ResendVerificationPopup = (props) => {
    const [submit, setSubmitting] = useState(false);
    const resendEmail = async () => {
        const email_address = window.localStorage.getItem('email_address');
        setSubmitting(true);
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/resend_email_verification`,
            { email: email_address });
        if (response.status === 200) {
            setSubmitting(false);
            props.onSuccess();
        } else {
            setSubmitting(false);
            props.onError(`Failed to send verification email. Please try again or contact to support support@wild.ai`);
        }
    }

    return props.show && (
        <div className="flex column alignCenter justifyCenter model_popup">
            <div className="lex column alignCenter justifyCennter w-100" style={{ background: '#ffffff', height: 320, width: 576, borderRadius: 12, padding: 24 }}>
                <div className="flex justifyEnd w-100">
                    <img className="pointer" src={CrossIcon} alt="close_icon"
                        height={32}
                        width={32}
                        onClick={() => { setSubmitting(false); props.onCancel() }}
                    />
                </div>
                <div className="flex column alignCenter justifyCenter w-100 h-100">
                    <p className="title"> Welcome to Wild.AI coach!</p>
                    <br />
                    <p className="description"> please check your email to activate your account.</p>
                    <br /><br />
                    <p className="description">Haven't received anything?</p>
                    <div className="flex justifyCenter w-100" onClick={resendEmail}>
                        {submit ?
                            <div className="flex justifyCenter alignCenter" style={{ margin: "8px 0 0" }}>
                                <div className="custom_button_loader" style={{
                                    height: 32, width: 32,
                                    border: '4px solid #f3f3f3',
                                    borderTop: '4px solid #010101'
                                }} />
                            </div>
                            :
                            <p className="description" style={{ color: '#3f51b5', cursor: "pointer", textDecoration: 'underline' }}>Resend verification email</p>
                        }
                    </div>
                </div>

            </div>
        </div>
    )

}

export default ResendVerificationPopup;