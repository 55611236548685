import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const Graph = ({ checkinsData, displayGraphsData }) => {
  return (
    <div
      style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }}
      className="scrollable_horizontal"
    >
      <LineChart
        width={
          displayGraphsData.length < 8 ? 780 : displayGraphsData.length * 64
        }
        height={400}
        data={displayGraphsData}
        margin={{
          top: 45,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis ticks={[0, 25, 50, 75, 100]} />
        <Tooltip />
        <Legend />
        {checkinsData.map(
          (filter) =>
            filter.active && (
              <Line
                type="monotone"
                dataKey={filter.label.toLowerCase()}
                stroke={filter.backgroundColor}
                strokeWidth={3}
              />
            )
        )}
      </LineChart>
    </div>
  );
};

export default Graph;
