export const chunk = (myArray, chunk_size) => {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    for (index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index + chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
};

export const getShortDescription = (desc, max_limit) => {
    if (!desc) {
        return "";
    } else {
        if (desc.length <= max_limit) {
            return desc;
        } else if (desc.length > max_limit) {
            return desc.slice(0, max_limit) + "..";
        }
    }
};

export const secondsToHm = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hr" : " hrs") : 0;
    var mDisplay = m > 0 ? m + (m === 1 ? " min" : " mins") : 0;

    if (hDisplay === 0 && mDisplay === 0) {
        return "0 mins";
    }
    if (hDisplay === 0 && mDisplay !== 0) {
        return mDisplay;
    }
    if (hDisplay !== 0 && mDisplay === 0) {
        return hDisplay;
    }
    if (hDisplay !== 0 && mDisplay !== 0) {
        return hDisplay + " : " + mDisplay;
    }
}