import Minus from "../../assets/icons/minus.svg";
import ArrowDown from "../../assets/icons/chevron-down-dark.svg";
import React, { useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const SeparatedGraphs = ({
  showGraph,
  data,
  displayGraphsData,
  checkinsData,
}) => {
  const [activeAccordions, setActiveAccordions] = useState([]);

  const getColorByLabel = (label) => {
    const foundItem = checkinsData.find(
      (item) => item.label.toLowerCase() === label.toLowerCase()
    );
    if (foundItem) {
      return foundItem.backgroundColor;
    } else {
      return "#FFFFFF";
    }
  };

  return showGraph ? (
    <div
      style={{
        width: "800px",
        overflowX: "auto",
        overflowY: "hidden",
      }}
    >
      {Object.values(data).map((symptom, index) => {
        return (
          <div
            style={{
              padding: "15px 0",
              border: "0.5px solid white",
              margin: "30px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: "10px",
            }}
          >
            <LineChart
              width={700}
              height={220}
              data={displayGraphsData}
              margin={{
                top: 25,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis ticks={[0, 25, 50, 75, 100]} />
              <Tooltip />
              <Line
                type="monotone"
                dataKey={symptom.label.toLowerCase()}
                strokeWidth={3}
                stroke={getColorByLabel(symptom.label)}
              />
            </LineChart>
            <div
              className="flex column pointer"
              style={{
                padding: "12px 16px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: 4,
                width: "680px",
                marginLeft: "24px",
              }}
            >
              <div className="flex alignCenter justifyBetween">
                <p style={{ color: "inherit" }}>{symptom.label}</p>
                <div className="selectory_arrow_component">
                  {activeAccordions.includes(index) ? (
                    <img
                      src={Minus}
                      alt="minus icon"
                      height={24}
                      width={24}
                      onClick={() =>
                        setActiveAccordions(
                          activeAccordions.filter((number) => number !== index)
                        )
                      }
                    />
                  ) : (
                    <img
                      src={ArrowDown}
                      alt="arrow down icon"
                      height={24}
                      width={24}
                      onClick={() =>
                        setActiveAccordions([...activeAccordions, index])
                      }
                    />
                  )}
                </div>
              </div>
              {/* Details */}
              {activeAccordions.includes(index) && (
                <div className="flex column" style={{ margin: "8px 0 0" }}>
                  <p>{symptom.what_is_it}</p>
                  <br />
                  <p>{symptom.information}</p>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

export default SeparatedGraphs;
