import React, { useEffect, useState } from "react";
// Providers
import { useDispatch } from "react-redux";
import moment from "moment";
// Icons + images 
import NextIcon from "../../assets/icons/chevron-right-light.svg";
import PlayButtonIcon from "../../assets/icons/play-button.svg";
// Components
import NoData from "../common/NoData";
// Actions
import { setTrainingPlans } from "../../store/trainings/actions";
import { history } from "../../index";

const PlansTable = ({ data_list, searchStatus, searchedText, loadingState }) => {
    const dispatch = useDispatch();
    let [dataSource, setDataSource] = useState([]);
    let [ascending_index, set_ascending_index] = useState(-1);
    let [ascending_highlight, set_ascending_highlight] = useState(true);
    let [decensing_index, set_decensing_index] = useState(-1);
    let [decensing_highlight, set_decensing_highlight] = useState(false);


    // const setPath = (id) => history.push("/dashboard/plan/update?id=" + id);
    const setPath = (id) => history.push("/dashboard/plan/view?id=" + id);

    const plansColumns = [
        { title: "Plan name", field: 'name', type: "alphabet", replace_action: false, width: 240, sorting: true },
        { title: "Author", field: 'author', type: "alphabet", replace_action: false, width: 160, sorting: false },
        { title: "Promo video", field: 'promo_video', type: "alphabet", replace_action: false, width: 120, sorting: false },
        { title: "Sport type", field: 'sport_type', type: "alphabet", replace_action: false, width: 220, sorting: true },
        { title: "Created", field: 'created', type: "date", replace_action: false, width: 160, sorting: true },
        { title: "Reviewed", field: 'reviewed', type: "boolean", replace_action: true, width: 120, sorting: true },
        { title: "Draft", field: 'is_draft', type: "boolean", replace_action: false, width: 120, sorting: true },
        { title: "Published", field: 'published', type: "boolean", replace_action: false, width: 120, sorting: true },
        { title: "Views", field: 'views', type: "number", replace_action: false, width: 120, sorting: true },
        { title: "Unique views", field: 'unique_views', type: "number", replace_action: false, width: 120, sorting: true },
        { title: "Purchases", field: 'purchases', type: "number", replace_action: false, width: 120, sorting: true },
        { title: "Active", field: 'athletes', type: "number", replace_action: false, width: 120, sorting: true },
        { title: "", width: 48 }
    ];

    const sortList = (list, filterOn, type, method, action) => {
        let sorted_list = [];
        if (type === 'alphabet') {
            if (method === "ascending") {
                sorted_list = list.sort().reverse();
            } else if (method === "descending") {
                sorted_list = list.sort((a, b) => b[filterOn].localeCompare(a[filterOn]))
            }
        }

        if (type === 'number') {
            if (method === "ascending") {
                if (action && filterOn === "cycleLength") {
                    sorted_list = list.sort((a, b) => Number(a[filterOn].replace(' days', '')) - Number(b[filterOn].replace(' days', '')));
                } else if (action && filterOn === "readiness") {
                    sorted_list = list.sort((a, b) => Number(a[filterOn].replace('%', '')) - Number(b[filterOn].replace('%', '')));
                } else {
                    sorted_list = list.sort((a, b) => a[filterOn] - b[filterOn]);
                }
            }

            if (method === "descending") {
                if (action && filterOn === "cycleLength") {
                    sorted_list = list.sort((a, b) => Number(b[filterOn].replace(' days', '')) - Number(a[filterOn].replace(' days', '')));
                } else if (action && filterOn === "readiness") {
                    sorted_list = list.sort((a, b) => Number(b[filterOn].replace('%', '')) - Number(a[filterOn].replace('%', '')));
                } else {
                    sorted_list = list.sort((a, b) => b[filterOn] - a[filterOn]);
                }
            }
        }

        if (type === 'date') {
            if (method === "ascending") {
                sorted_list = list.sort((a, b) => new moment(a[filterOn]).format('YYYYMMDD') - new moment(b[filterOn]).format('YYYYMMDD'))
            } else if (method === "descending") {
                sorted_list = list.sort((a, b) => new moment(b[filterOn]).format('YYYYMMDD') - new moment(a[filterOn]).format('YYYYMMDD'))
            }
        }

        if (type === "boolean") {
            if (method === "ascending") {
                sorted_list = list.sort((x, y) => Number(x[filterOn]) - Number(y[filterOn]));
            } else if (method === "descending") {
                sorted_list = list.sort((x, y) => Number(y[filterOn]) - Number(x[filterOn]));
            }
        }
        dispatch(setTrainingPlans(sorted_list));
    };

    useEffect(() => {
        if (data_list && data_list.length > 0) {
            setDataSource(data_list);
        } else {
            setDataSource([]);
        }
    }, [data_list]);


    return (
        <div className="w-100" style={{ overflowX: 'auto' }}>
            <div className="flex column alignCenter w-100" style={{ minWidth: dataSource.length > 0 ? 1840 : '100%', padding: "0 4px 28px" }}>
                {/* Columns */}
                <div className="flex justifyBetween alignCenter w-100" style={{ margin: 0, padding: "0 16px" }}>
                    {plansColumns.map((column, index) => (
                        <div className="flex justifyStart alignCenter" key={index + 1} style={{
                            justifyContent: 'flex-start',
                            minWidth: column.width,
                            maxWidth: column.width,
                        }}>
                            <p style={{ fontSize: 12 }}>{column.title}</p>
                            {column.sorting && dataSource && dataSource.length > 0 && (
                                <div className="flex column sorting_arrows">
                                    <span onClick={() => {
                                        sortList(dataSource, column.field, column.type, "ascending", column.replace_action);
                                        set_ascending_index(index);
                                        set_decensing_index(-1);
                                        set_ascending_highlight(true);
                                    }}>
                                        <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                            <path fill={ascending_index === index && ascending_highlight ? "#000000bf" : "#d5d5d5"} d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
                                        </svg>
                                    </span>
                                    <span onClick={() => {
                                        sortList(dataSource, column.field, column.type, "descending", column.replace_action);
                                        set_ascending_index(-1);
                                        set_decensing_index(index);
                                        set_decensing_highlight(true);
                                    }}>
                                        <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                            <path fill={decensing_index === index && decensing_highlight ? "#000000bf" : "#d5d5d5"} d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                                        </svg>
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {/* Rows */}
                {dataSource && dataSource.length > 0 && dataSource.map((item, index) => {
                    return (
                        <div key={index + 1} className="flex justifyBetween athlete_list_item w-100"
                            style={{ marginTop: 16 }}
                        >
                            {/* Field: image & name */}
                            <div className="flex justifyCenter alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 240, maxWidth: 240 }}>
                                {item.plan_image ? (
                                    <img className="training_plan_image" src={item.plan_image} alt="plan_profile_image" />
                                ) : (
                                    <div className="placeholder_name_box">
                                        <span className="name_letter">
                                            {item.name[0]}
                                        </span>
                                    </div>
                                )}
                                <span className="training_plan_name" style={{ margin: '0 8px' }}>{item.name}</span>
                            </div>
                            {/* Field: Author */}
                            <div className="flex justifyStart alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 160, maxWidth: 160 }}>
                                <span className="sport_type" >{item.author || ""}</span>
                            </div>
                            {/* Field: promo_video */}
                            {item.promo_video ? (
                                <div onClick={() => window.open(item.promo_video, "_blank")} className="flex justifyCenter alignCenter pointer w-100" style={{ minWidth: 120, maxWidth: 120 }}>
                                    <img src={PlayButtonIcon} alt="play button icon" height={40} width={40} style={{ marginLeft: -44 }} />
                                </div>
                            ) : (
                                <div className="flex justifyStart alignCenter pointer" style={{ minWidth: 120, maxWidth: 120 }} />
                            )}
                            {/* Field: sport_type */}
                            <div className="flex justifyStart alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 220, maxWidth: 220, }}>
                                <span className="sport_type" >{item.sport_type || ""}</span>
                            </div>
                            {/* Field: created */}
                            <div className="flex justifyCenter alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 160, maxWidth: 160 }}>
                                <span className="created_date">{moment(item.created).format("MMM Do, YYYY")}</span>
                            </div>
                            {/* Field: reviewed */}
                            <div className="flex justifyCenter alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 120, maxWidth: 120 }}>
                                <span className="reviewed_status" >{item.reviewed ? 'Yes' : 'No'}</span>
                            </div>
                            {/* Field: draft */}
                            <div className="flex justifyCenter alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 120, maxWidth: 120 }}>
                                <span className="draft_status">{item.is_draft ? 'Yes' : 'No'}</span>
                            </div>
                            {/* Field: published */}
                            <div className="flex justifyCenter alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 120, maxWidth: 120 }}>
                                <span className="published_status" >{item.published ? 'Yes' : 'No'}</span>
                            </div>
                            {/* Field: views */}
                            <div className="flex justifyCenter alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 120, maxWidth: 120 }}>
                                <span className="views_count">{item.views || 0}</span>
                            </div>
                            {/* Field: unique views */}
                            <div className="flex justifyCenter alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 120, maxWidth: 120 }}>
                                <span className="views_count">{item.unique_views || 0}</span>
                            </div>
                            {/* Field: purchases */}
                            <div className="flex justifyCenter alignCenter pointer w-100" onClick={() => setPath(item.pk)} style={{ minWidth: 120, maxWidth: 120 }}>
                                <span className="plan_purchases" >{item.purchases || 0}</span>
                            </div>
                            {/* Field: active */}
                            <div className="flex justifyCenter alignCenter pointer" onClick={() => setPath(item.pk)} style={{ minWidth: 120, maxWidth: 120 }}>
                                <span className="plan_athletes_count">{item.athletes || 0}</span>
                            </div>
                            {/* Field: forward_arrow */}
                            <div className="flex justifyEnd alignCenter pointer" onClick={() => setPath(item.pk)} style={{ minWidth: 48, maxWidth: 48 }}>
                                <img src={NextIcon} alt="next-icon" height={16} width={16} style={{ marginRight: 8 }} />
                            </div>
                        </div>
                    )
                })}
            </div>
            {(searchStatus && dataSource && dataSource.length === 0) ?
                <NoData loadingState={loadingState} searchedText={searchedText} />
                : null}
        </div >
    );
};

export default PlansTable;
