import React, {
    useEffect, useState,
} from "react";
import { useDispatch } from "react-redux";
// Icons
import CurlyArrowPointingRight from "../..//assets/icons/CurlyArrow.svg";
import CurlPointingArrowLeft from "../../assets/icons/CurlPointingArrowLeft.svg";
import Doctor from "../../assets/icons/Doctor.svg";
import GreenArrowRight from "../../assets/icons/GreenArrowRight.svg";
import AthletesMockup from "../../assets/icons/Mockup.svg";
import Runner from "../../assets/icons/Runner.svg";
import GreenCrossIcon from "../../assets/icons/cross-green.svg";
import ArrowRight from "../../assets/icons/forward-black.svg";
import SearchIcon from "../../assets/icons/search-icon.svg";
// Actions
import { updateOnboardingStatus } from "../../_helpers/Requests";
import { history } from "../../index";
import api from "../../services/api/ApiService";
import { createNotification, setUser } from "../../store/app/actions";


const AthletesList = () => {
    const [team_list, setTeamList] = useState([]);
    const dispatch = useDispatch();
    const [show_form, setShowForm] = useState({
        step1: true,
        step2: false
    });
    const [submitting, setSubmitting] = useState(false);

    const onError = (error) => { setSubmitting(false); dispatch(createNotification("error-toast", error)) }; // onError
    const onSuccess = (user) => { setSubmitting(false); dispatch(setUser(user)); history.push("/dashboard/athletes") }; // onSuccess

    useEffect(() => {
        api.get(`coach/blocks/teams`)
            .then((result) => {
                if (result.status === 200) {
                    const { teams } = result.data
                    if (teams && teams.length > 0) {
                        setTeamList(teams);
                    } else {
                        onError("Please Create a Team First!");
                        history.push("/onboarding/createTeam");
                    }
                } else {
                    onError("Something went wrong, please contact to support@wild.ai");
                }
            }).catch((error) => {
                onError(error.message);
            });
    }, []);
    return (
        <div className="flex column w-100 h-100" style={{ margin: '40px 40px 0', width: 'calc(100vw - 240px)', maxHeight: '100vh' }}>
            {/* Header */}
            <div className="flex justifyBetween alignCenter">
                <div className="team_selector">
                    <div className="flex justifyBetween alignCenter">
                        <p className="selected_team" style={{ cursor: 'default' }}>{(team_list && team_list.length > 0) && team_list[0].name} </p>
                    </div>
                </div>

                {/* Breadcrumb */}
                <div className="flex alignCenter">
                    <p>Home</p>
                    <img src={ArrowRight} alt="arrow right" height={12} width={12} style={{ margin: '0 4px' }} />
                    <p>Teams</p>
                    <img src={ArrowRight} alt="arrow right" height={12} width={12} style={{ margin: '0 4px' }} />
                    <p>{(team_list && team_list.length > 0) && team_list[0].name}</p>
                </div>

            </div>

            <div className="management-tab-section">
                {/* Management Tabs */}
                <div className="flex justifyBetween alignStart">
                    {["All Teams", "Athletes", "Coaches", "Settings"].map((tab, index) => (
                        <p key={`tab-${index}`} style={{ paddingBottom: 4, minWidth: 100, textAlign: 'center', borderBottom: index === (show_form['step1'] ? 1 : show_form['step2'] && 2) ? "2px solid #ffffff" : null }}>
                            {tab}
                        </p>
                    ))}
                </div>

                {/* Invite Tabs */}
                <div className="flex alignCenter h-100">
                    {/* Search bar */}
                    <div className="relative" style={{ minWidth: 400 }}>
                        <input type="text" className="pointer_not_allowed" disabled={true} placeholder="Search..."
                            style={{
                                borderRadius: 40,
                                height: 46,
                                fontSize: 14,
                                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                border: "none",
                                color: "#ffffff",
                                padding: "10px 48px 10px 24px",
                            }}
                        />
                        <img
                            src={SearchIcon}
                            alt="search-icon"
                            style={{
                                height: 18,
                                width: 18,
                                position: "absolute",
                                right: 22,
                                top: 2,
                                zIndex: 100,
                                cursor: "pointer",
                                opacity: 0.5,
                                marginLeft: "0.75rem",
                                marginTop: "0.75rem",
                            }}
                        />
                    </div>
                    {/* Add Athletes/Coaches Tab */}
                    <button className="white-button onBoarding_button">
                        <p style={{ margin: 0 }}>Add Athletes</p>
                    </button>
                </div>
            </div>

            {show_form['step1'] && (
                <div className="flex column alignCenter justifyStart relative" style={{ minHeight: 520, marginTop: 16 }}>
                    <div className="absolute" style={{ top: 96, right: 132, zIndex: 1000 }}>
                        <div className="flex column relative" style={{ width: 348, background: '#08AFA8', border: '1px solid #01213a', borderRadius: 12, padding: 16 }}>
                            <img className="absolute" src={Runner} alt="runner" height={116} width={149} style={{ top: -90, right: 8 }} />
                            <div className="flex alignCenter justifyBetween w-100">
                                <h3 style={{ color: '#01213A', fontSize: 18 }}>Amazing!</h3>
                                <img src={GreenCrossIcon} alt="cross icon" height={16} width={16} style={{ cursor: 'pointer' }} onClick={() => setShowForm({ ...show_form, step1: false, step2: true })} />
                            </div>
                            <h3 style={{ color: '#01213A', fontSize: 18, marginTop: 12 }}>You have added your first athlete</h3>
                            <p style={{ color: '#01213A', marginTop: 16 }}>You can add as many athletes as you need by using this button.</p>
                            <div className="flex alignCenter justifyEnd w-100" style={{ cursor: 'pointer' }} onClick={() => setShowForm({ ...show_form, step1: false, step2: true })}>
                                <p style={{ color: '#01213A', textTransform: 'uppercase', fontWeight: 600 }}>Next</p>
                                <img src={GreenArrowRight} alt="cross icon" height={20} width={20} style={{ marginLeft: 12 }} />
                            </div>
                            <img src={CurlyArrowPointingRight} alt="curl pointing arrow" height={75.13} width={75.13} style={{ position: 'absolute', top: '-66%', right: '-24%' }} />
                        </div>
                    </div>
                    <div className="relative w-100" style={{ padding: "32px 0", opacity: 0.3 }}>
                        <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0, background: '#00000050', borderRadius: 16 }} />
                        <img src={AthletesMockup} alt="athlete mockup" height="100%" width="100%" style={{ padding: 8 }} />
                    </div>
                </div>
            )}

            {show_form['step2'] &&
                <div className="flex column alignCenter justifyStart relative" style={{ minHeight: 520, marginTop: 16 }}>
                    <div className="absolute" style={{ top: 108, left: 108, zIndex: 1000 }}>
                        <div className="flex column relative" style={{ width: 348, background: '#08AFA8', border: '1px solid #01213a', borderRadius: 12, padding: '10px 16px' }}>
                            <img className="absolute" src={Doctor} alt="runner" height={116} width={149} style={{ top: -90, right: 32 }} />
                            <div className="flex alignCenter justifyBetween w-100">
                                <h3 style={{ color: '#01213A', fontSize: 18 }}>Not alone?</h3>
                                <img src={GreenCrossIcon} alt="cross icon" height={16} width={16} style={{ cursor: 'pointer' }} onClick={() => setShowForm({ ...show_form, step2: false })} />
                            </div>
                            <p style={{ color: '#01213A', marginTop: 16 }}>You can add additional coaches to your team and manage your team members. </p>
                            <div className="flex alignCenter justifyEnd w-100" style={{ cursor: 'pointer', marginTop: 16 }} onClick={() => history.push("/onboarding/addCoaches")}>
                                <p style={{ color: '#01213A', textTransform: 'uppercase', fontWeight: 600 }}>Add Coaches</p>
                                <img src={GreenArrowRight} alt="cross icon" height={20} width={20} style={{ marginLeft: 12 }} />
                            </div>
                            <img src={CurlPointingArrowLeft} alt="curl pointing arrow" height={75.13} width={75.13} style={{ position: 'absolute', top: '-84%', left: '34%', transform: 'rotate(90deg)' }} />
                        </div>

                    </div>

                    <div className="relative w-100" style={{ padding: "32px 0", opacity: 0.3 }}>
                        <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0, background: '#00000050', borderRadius: 16 }} />
                        <img src={AthletesMockup} alt="athlete mockup" height="100%" width="100%" style={{ padding: 8 }} />
                    </div>
                </div>}

            {(!show_form["step1"] && !show_form['step2']) &&
                <div className="flex column alignCenter justifyStart relative" style={{ minHeight: 520, marginTop: 16 }}>
                    <div className="relative w-100" style={{ padding: "32px 0",  opacity: 0.3 }}>
                        <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0, background: '#00000050', borderRadius: 16 }} />
                        <img src={AthletesMockup} alt="athlete mockup" height="100%" width="100%" style={{ padding: 8 }} />
                    </div>
                </div>}

            {/* Skip */}
            <div className="flex justifyCenter alignCenter w-100 absolute" style={{ bottom: '10%', left: '50%', transform: 'translate(-50%, -10%)' }} >
                <button onClick={() => { setSubmitting(true); updateOnboardingStatus(onSuccess, onError) }} className="white-button" style={{ maxWidth: 248 }} >
                    {submitting ?
                        <div className="flex justifyCenter alignCenter">
                            <div className="custom_button_loader" />
                        </div>
                        : <p style={{ margin: 0 }}>Skip Onboarding</p>}
                </button>
            </div>
        </div>
    );
};
export default AthletesList;
