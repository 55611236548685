import React, {
    useEffect, useState,
} from "react";
import { useDispatch } from "react-redux";
// Icons
import ArrowRight from "../../assets/icons/forward-black.svg";
// Components
import InviteCoaches from "../../components/InviteCoaches";
import { history } from "../../index";
import api from "../../services/api/ApiService";
import { createNotification } from "../../store/app/actions";

const AddAthletes = () => {
    const [team_list, setTeamList] = useState([]);
    const dispatch = useDispatch();
    const onError = (error) => dispatch(createNotification("error-toast", error)); // onError

    useEffect(() => {
        api.get(`coach/blocks/teams`)
            .then((result) => {
                if (result.status === 200) {
                    const { teams } = result.data
                    if (teams && teams.length > 0) {
                        setTeamList(teams);
                    } else {
                        onError("Please Create a Team First!");
                        history.push("/onboarding/createTeam");
                    }
                } else {
                    onError("Something went wrong, please contact to support@wild.ai");
                }
            }).catch((error) => {
                onError(error.message);
            });
    }, []);

    return (
        <div className="flex column w-100 h-100">
            {/* Header */}
            <div className="flex justifyBetween alignCenter" style={{ position: 'fixed', top: 0, zIndex: 10, margin: '40px 40px 0', width: 'calc(100vw - 240px)' }}>
                <div className="team_selector">
                    <div className="flex justifyBetween alignCenter" style={{ minWidth: 224 }}>
                        <p className="selected_team" style={{ cursor: 'default' }}>{(team_list && team_list.length > 0) && team_list[0].name} </p>
                    </div>
                </div>

                {/* Breadcrumb */}
                <div className="flex alignCenter">
                    <p>Home</p>
                    <img src={ArrowRight} alt="arrow right" height={12} width={12} style={{ margin: '0 4px' }} />
                    <p>Teams</p>
                    <img src={ArrowRight} alt="arrow right" height={12} width={12} style={{ margin: '0 4px' }} />
                    <p>{(team_list && team_list.length > 0) && team_list[0].name}</p>
                </div>
            </div>

            {/* Add Coaches */}
            <div className="settings-main" style={{ maxWidth: 520, height: "100vh", margin: '0 auto' }}>
                <h3 style={{ fontSize: 32, lineHeight: '120%', color: '#FFFFFF' }}>Invite Coaches to Wild.AI Coach</h3>
                <div className="flex column alignCenter justifyCenter w-100" style={{ margin: '32px auto 0' }}>
                    {/* Add/Remove Multiple Input Fields */}
                    {(team_list && team_list.length > 0) && <InviteCoaches teamId={team_list[0].pk} />}
                    <br />
                    {/* Skip */}
                    <p style={{ fontSize: 14, letterSpacing: 1.25, textTransform: 'uppercase', cursor: 'pointer' }} onClick={() => history.push("/onboarding/overviewToolip")}>Skip</p>
                </div>
            </div>
        </div >
    );
};
export default AddAthletes;
