import initialState from "../initialState";
import {
  INITIALIZED,
  SET_USER,
  SET_REDIRECT_TO,
  CREATE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SET_MANAGE_BUTTON_STATE,
} from "./actions";

export default function appReducer(
  state = initialState.app,
  { type, payload }
) {
  switch (type) {
    case SET_USER: {
      return {
        ...state,
        user: payload.user,
      };
    }
    case SET_MANAGE_BUTTON_STATE: {
      return { ...state, manageButtonState: payload };
    }
    case CREATE_NOTIFICATION: {
      return { ...state, notification: payload };
    }
    case REMOVE_NOTIFICATION: {
      return { ...state, notification: { open: false } };
    }
    case INITIALIZED: {
      return { ...state, isInitialized: payload.isInitialized };
    }
    case SET_REDIRECT_TO: {
      return { ...state, redirectTo: payload.to };
    }
    default:
      return state;
  }
}
