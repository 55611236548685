import React from "react";
import BackwardIcon from "../../assets/icons/backward-black.svg";
import InviteAthletes from "../../components/InviteAthletes";
import InviteCoaches from "../../components/InviteCoaches";
import { history } from "../../index";
import CreateTeamManagement from "./CreateTeamManagement";

const InviteScreen = (props) => {
  let { location: { search } } = props;
  let invitation = search.replace("?", "");
  const team_Id = window.localStorage.getItem("team_Id");

  return (
    <div className="flex column justifyStart alignCenter w-100 h-100">
      {/* Header */}
      <div className="flex justifyStart alignCenter pointer w-100" style={{ padding: '56px 0 0 40px' }} onClick={() => history.push("/dashboard/management")}>
        <img src={BackwardIcon} alt="close_icon" height={15} width={14} />
        <p style={{ fontSize: 16, color: "rgba(255,255,255,1)", margin: "0 0 0 12px" }}> Back</p>
      </div>

      {/* Content */}
      <div className="settings-main" style={{ maxWidth: 520, transform: 'translate(0%, -50%)', position: 'absolute', top: '50%', margin: 0 }}>
        {(invitation === "athletes" || invitation === "coaches") ? <h3 style={{ fontSize: 32, lineHeight: '120%', color: '#FFFFFF' }}>Invite {invitation === "athletes" ? 'Athletes' : 'Coaches'} to My Wild Team</h3> : null}
        <div className="flex column alignCenter justifyCenter w-100" style={{ margin: '32px auto 0' }}>
          {/* Add/Remove Multiple Input Fields */}
          {(invitation === "athletes" || invitation === "coaches") ? (invitation === "athletes" ? <InviteAthletes screenName="invite" teamId={team_Id} /> : <InviteCoaches screenName="invite" teamId={team_Id} />) : null}
          {/* Create Team Form */}
          {invitation === "createTeam" ? <CreateTeamManagement /> : null}
        </div>
      </div>
    </div>
  )
}

export default InviteScreen;