export const INITIALIZE = "app/INITIALIZE";
export const INITIALIZED = "app/INITIALIZED";
export const FETCH_USER = "app/FETCH_USER";
export const SET_USER = "app/SET_USER";
export const SIGNUP_USER = "app/SIGNUP_USER";
export const LOGOUT_USER = "app/LOGOUT_USER";
export const SET_ERROR = "app/ERROR";
export const SET_REDIRECT_TO = "app/SET_REDIRECT_TO";
export const SET_LOGGED_USER = "app/SET_LOGGED_USER";
export const LOGIN_MANUAL = "app/LOGIN_MANUAL";
export const LOGIN_GOOGLE = "app/LOGIN_GOOGLE";
export const FORGOT_PASSWORD = "app/FORGOT_PASSWORD";
export const CREATE_NOTIFICATION = "app/CREATE_NOTIFICATION";
export const REMOVE_NOTIFICATION = "app/REMOVE_NOTIFICATION";
export const SET_MANAGE_BUTTON_STATE = "app/SET_MANAGE_BUTTON_STATE";
export const FETCH_INVITE_URL_DATA = "app/FETCH_INVITE_URL_DATA";
export const JOIN_TEAM_BY_ACCEPTING_INVITE =
  "app/JOIN_TEAM_BY_ACCEPTING_INVITE";
export const CLEAR_INVITE = "app/CLEAR_INVITE";
export const DELETE_ACCOUNT = "app/DELETE_ACCOUNT";

export const initialize = () => ({
  type: INITIALIZE,
  payload: {},
});

export const initialized = (isInitialized) => ({
  type: INITIALIZED,
  payload: {
    isInitialized,
  },
});

export const fetchUser = () => ({
  type: FETCH_USER,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: { user },
});

export const signup = (
  form,
  onSuccess,
  onError,
  showResend,
  subscriptionFailure
) => ({
  type: SIGNUP_USER,
  payload: { form, onSuccess, onError, showResend, subscriptionFailure },
});

export const logout = () => ({
  type: LOGOUT_USER,
});

export const setError = (error) => ({
  type: SET_ERROR,
  payload: { error },
});

export const setRedirectTo = (to) => ({
  type: SET_REDIRECT_TO,
  payload: { to },
});

export const setLoggedUser = (user, access, refresh) => ({
  type: SET_LOGGED_USER,
  payload: { user, access, refresh },
});

export const login = (
  form,
  onSuccess,
  onError,
  showResend,
  subscriptionFailure
) => ({
  type: LOGIN_MANUAL,
  payload: { form, onSuccess, onError, showResend, subscriptionFailure },
});

export const loginGoogle = (params, success, failure) => ({
  type: LOGIN_GOOGLE,
  payload: { params, success, failure },
});

export const forgotPassword = (email, successMessage, failure) => ({
  type: FORGOT_PASSWORD,
  payload: { email, successMessage, failure },
});

export const createNotification = (type, message, timeout = 6000) => ({
  type: CREATE_NOTIFICATION,
  payload: { type, message, open: true, timeout },
});

export const removeNotification = () => ({
  type: REMOVE_NOTIFICATION,
});

export const setManageButtonStatus = (status) => ({
  type: SET_MANAGE_BUTTON_STATE,
  payload: {
    status,
  },
});

export const fetchInviteData = (dataString) => ({
  type: FETCH_INVITE_URL_DATA,
  payload: { dataString },
});

export const acceptInvite = (teamName, teamCode, onError) => ({
  type: JOIN_TEAM_BY_ACCEPTING_INVITE,
  payload: { teamName, teamCode, onError },
});

export const ignoreInvite = () => ({
  type: CLEAR_INVITE,
});

export const deleteAccount = (coach_email, onError) => ({
  type: DELETE_ACCOUNT,
  payload: { coach_email, onError },
});
