import React, { useState } from "react";
import "./ExercisesContainer.css";

const ExercisesContainer = ({
  moduleId,
  exercises,
  onExerciseContainerDrop,
  onExerciseDragOver,
  startExerciseEdit,
  updateExercises,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [draggingElementIndex, setDraggingElementIndex] = useState();

  const handleDragStart = (e, index) => {
    setIsDragging(true);
    setDraggingElementIndex(index);
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    const sourceIndex = e.dataTransfer.getData("text/plain");
    const draggedItem = exercises[sourceIndex];

    const updatedItems = [...exercises];
    updatedItems.splice(sourceIndex, 1);
    updatedItems.splice(targetIndex, 0, draggedItem);

    updateExercises(moduleId, updatedItems);
    setIsDragging(false);
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className={
        exercises.length
          ? "sesionBuilder_exerciseContainer"
          : "sesionBuilder_emptyExerciseContainer"
      }
      id="exercise-section"
      onDrop={(e) => !isDragging && onExerciseContainerDrop(e, moduleId)}
      onDragOver={onExerciseDragOver}
    >
      {exercises.length ? (
        exercises.map((exercise, index) => (
          <div
            className={`flex column justifyCenter alignCenter sessionBuilder_exercise pointer ${
              isDragging && draggingElementIndex === index ? "dragging" : ""
            }`}
            style={{ width: exercise.name.length > 14 ? "130px" : "100px" }}
            key={index}
            onClick={() => {
              if (!isDragging) {
                startExerciseEdit(exercise, index, moduleId);
              }
            }}
            onDragStart={(e) => handleDragStart(e, index)}
            onDrop={(e) => isDragging && handleDrop(e, index)}
            onDragOver={allowDrop}
            draggable
          >
            <div className="flex column justifyCenter alignCenter">
              <span style={{ textAlign: "center" }}>{exercise.name}</span>
              <span style={{ fontSize: "15px" }}>
                {exercise.duration} seconds
              </span>
            </div>
          </div>
        ))
      ) : (
        <span>Drag & Drop at least one exercise here</span>
      )}
    </div>
  );
};

export default ExercisesContainer;
