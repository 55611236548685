import React from "react";
import DownloadIcon from "../../assets/icons/download-arrow.svg";
import RightArrow from "../../assets/icons/arrow-right-line.svg";
import RightArrowGreen from "../../assets/icons/arrow-right-line-green.svg";
import "./SingleInvoice.css";
import moment from "moment";

const SingleInvoice = ({ goBack, invoiceDetails, paymentInformation }) => {
  const getColorClass = (inputString) => {
    switch (inputString) {
      case "paid":
        return "#17C964";
      case "past due":
        return "#F5A524";
      case "failed":
        return "white";
      default:
        return "white";
    }
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "gbp":
        return "£";
      case "usd":
        return "$";
      case "eur":
        return "EUR";
      default:
        return "£";
    }
  };

  return (
    <div id="single-invoice">
      <div className="flex column" style={{ margin: "10px 0", gap: "10px" }}>
        <div
          className="transparent-button"
          style={{
            width: "92px",
            textTransform: "none",
            letterSpacing: "0",
            height: 24,
          }}
          onClick={goBack}
        >
          Go back
        </div>
        <span style={{ fontSize: "20px" }}>Summary</span>
      </div>
      <div className="flex row justifyBetween" style={{ marginTop: "16px" }}>
        <div className="flex row" style={{ gap: "16px" }}>
          <div className="card" style={{ width: "400px" }}>
            <div
              className="flex row justifyBetween"
              style={{ gap: "16px", padding: "8px 16px" }}
            >
              <span style={{ fontSize: "16px", fontWeight: 700 }}>
                Billing contact
              </span>
              <span>{paymentInformation.name}</span>
            </div>
            <div className="content-divider" />
            <div
              className="flex row justifyBetween"
              style={{ gap: "16px", padding: "8px 16px" }}
            >
              <span style={{ fontSize: "16px", fontWeight: 700 }}>
                Billing email
              </span>
              <span>{paymentInformation.email}</span>
            </div>
          </div>
          <div className="vertical-divider" />
          <div className="card" style={{ width: "600px" }}>
            <div
              className="flex row justifyBetween"
              style={{ gap: "16px", padding: "8px 16px" }}
            >
              <span style={{ fontSize: "16px", fontWeight: 700 }}>
                Invoice Number
              </span>
              <span>{invoiceDetails.number}</span>
            </div>
            <div className="content-divider" />
            <div
              className="flex row justifyBetween"
              style={{ gap: "16px", padding: "8px 16px" }}
            >
              <span style={{ fontSize: "16px", fontWeight: 700 }}>
                Amount Due
              </span>
              <span>
                {getCurrencySymbol(invoiceDetails.currency)}
                {invoiceDetails.amount_due / 100}
              </span>
            </div>
            <div className="content-divider" />
            <div
              className="flex row justifyBetween"
              style={{ gap: "16px", padding: "8px 16px" }}
            >
              <span style={{ fontSize: "16px", fontWeight: 700 }}>
                Billing Method
              </span>
              <div className="flex row" style={{ gap: "16px" }}>
                <span> 💳 •••• •••• •••• {paymentInformation.last4digits}</span>
                <a
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    textDecoration: "underline",
                    color: "white",
                  }}
                  className="pointer"
                  href={paymentInformation.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Edit
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="status-container"
          style={{
            borderColor: getColorClass(invoiceDetails.status),
            height: "fit-content",
            alignSelf: "flex-end",
            justifySelf: "flex-end",
          }}
        >
          <span
            className="circle"
            style={{
              backgroundColor: getColorClass(invoiceDetails.status),
            }}
          />
          <span
            style={{
              color: getColorClass(invoiceDetails.status),
              textTransform: "capitalize",
            }}
          >
            {invoiceDetails.status}
          </span>
        </div>
      </div>
      <table style={{ borderCollapse: "collapse", borderSpacing: 0 }}>
        <thead>
          <tr className="stripe-row">
            <th
              className="rounded-edges-left"
              style={{ width: "60%", padding: "25px" }}
            >
              Description
            </th>
            <th className="left-border">Quantity</th>
            <th className="left-border">Unit Price</th>
            <th className="left-border rounded-edges-right">Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetails.lines.data.map((line, i) => (
            <tr style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.20)" }}>
              <td
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.10)",
                  borderTopLeftRadius: i === 0 ? "12px" : "0",
                  padding: "25px",
                }}
              >
                <p
                  style={{ fontSize: "14px", marginBottom: "4px", padding: 0 }}
                >
                  {line.description}
                </p>
                <br />
                {moment.unix(line.period.start).format("MMM DD YYYY")} -{" "}
                {moment.unix(line.period.end).format("MMM DD YYYY")}
              </td>
              <td
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.10)",
                  padding: "25px",
                }}
              >
                {line.quantity}
              </td>
              <td
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.10)",
                  padding: "25px",
                }}
              >
                {getCurrencySymbol(invoiceDetails.currency)}
                {parseInt(line.unit_amount_excluding_tax) / 100}
              </td>
              <td
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.10)",
                  borderTopRightRadius: "12px",
                  padding: "25px",
                }}
              >
                {getCurrencySymbol(invoiceDetails.currency)}
                {line.amount / 100}
              </td>
            </tr>
          ))}
          <tr
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.10)",
            }}
          >
            <td style={{ padding: "25px" }} />
            <td style={{ padding: "25px" }} />
            <td
              style={{
                padding: "25px",
                borderRight: "1px solid rgba(255, 255, 255, 0.20)",
              }}
            >
              Subtotal
            </td>
            <td style={{ padding: "25px" }}>
              {getCurrencySymbol(invoiceDetails.currency)}
              {invoiceDetails.subtotal / 100}
            </td>
          </tr>
          <tr
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.10)",
            }}
          >
            <td style={{ padding: "25px" }} />
            <td />
            <td
              style={{
                padding: "25px",
                borderRight: "1px solid rgba(255, 255, 255, 0.20)",
              }}
            >
              Total excluding tax
            </td>
            <td style={{ padding: "25px" }}>
              {getCurrencySymbol(invoiceDetails.currency)}
              {invoiceDetails.subtotal_excluding_tax / 100}
            </td>
          </tr>
          <tr
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.10)",
            }}
          >
            <td style={{ padding: "25px" }} />
            <td style={{ padding: "25px" }} />
            <td
              style={{
                padding: "25px",
                borderRight: "1px solid rgba(255, 255, 255, 0.20)",
              }}
            >
              Tax %
            </td>
            <td style={{ padding: "25px" }}>
              {invoiceDetails.tax ? invoiceDetails.tax : 0}%
            </td>
          </tr>
          <tr
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.10)",
            }}
          >
            <td style={{ padding: "25px", borderBottomLeftRadius: "12px" }} />
            <td style={{ padding: "25px" }} />
            <td
              style={{
                padding: "25px",
                borderRight: "1px solid rgba(255, 255, 255, 0.20)",
              }}
            >
              Total
            </td>
            <td style={{ borderBottomRightRadius: "12px", padding: "25px" }}>
              {getCurrencySymbol(invoiceDetails.currency)}
              {invoiceDetails.total / 100}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="flex row justifyBetween">
        <a
          href={invoiceDetails.invoice_pdf}
          download
          style={{ width: "100%", color: "white", textDecoration: "none" }}
        >
          <div
            className="transparent-button"
            style={{
              width: "10%",
              textTransform: "none",
              letterSpacing: "0",
              marginTop: "10px",
            }}
          >
            Download
            <img
              src={DownloadIcon}
              alt="download-icon"
              height={24}
              width={24}
              className="pointer"
            />
          </div>
        </a>

        {invoiceDetails.status === "past due" ||
          (invoiceDetails.status === "failed" && (
            <div className="flex row" style={{ width: "40%", gap: "25px" }}>
              <a
                href={paymentInformation.url}
                style={{
                  width: "100%",
                  color: "white",
                  textDecoration: "none",
                }}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className="transparent-button"
                  style={{
                    height: 42,
                    width: "100%",
                    borderColor: "#75fb8d",
                    color: "#75fb8d",
                    fontWeight: 700,
                    textTransform: "none",
                    letterSpacing: 0,
                    marginTop: "10px",
                  }}
                >
                  Edit Payment Method
                  <img
                    src={RightArrowGreen}
                    alt="download-icon"
                    height={24}
                    width={24}
                    className="pointer"
                  />
                </div>
              </a>
              <a
                href={paymentInformation.url}
                style={{
                  width: "100%",
                  color: "white",
                  textDecoration: "none",
                }}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className="white-button"
                  style={{
                    height: 42,

                    border: "none",
                    backgroundColor: "#75fb8d",
                    fontWeight: 700,
                    textTransform: "none",
                    letterSpacing: 0,
                    marginTop: "10px",
                  }}
                >
                  Pay Invoice
                  <img
                    src={RightArrow}
                    alt="download-icon"
                    height={24}
                    width={24}
                    className="pointer"
                  />
                </div>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SingleInvoice;
