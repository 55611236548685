import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import app from "./app/reducer";
import athletes from "./athletes/reducer";
import managements from "./management/reducer";
import settings from "./settings/reducer";
import loading from "./loading/reducer";
import training_plans from "./trainings/reducer";
import calendarData from "./calendar/reducer";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    athletes,
    managements,
    settings,
    loading,
    training_plans,
    calendarData,
  });

export default rootReducer;
