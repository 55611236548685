import React from "react";
import { history } from "../../index";
import MissingSubscriptionIcon from "../../assets/icons/missing-subscription.svg";

const MissingSubscriptionPage = () => {
  return (
    <div
      className="flex column alignCenter justifyCenter"
      style={{ padding: "60px 0", gap: "56px" }}
    >
      <img src={MissingSubscriptionIcon} />
      <div className="flex column alignCenter" style={{ gap: "24px" }}>
        <span style={{ fontSize: "32px" }}>Ooops!</span>
        <span style={{ fontSize: "16px" }}>
          To add members to your team, you need a subscription!
        </span>
        <div
          className="white-button"
          style={{ maxWidth: 220 }}
          onClick={() => history.push("/dashboard/settings?tab=subscription")}
        >
          <p style={{ fontWeight: "700" }}>Manage subscription</p>
        </div>
      </div>
    </div>
  );
};

export default MissingSubscriptionPage;
