import React from "react";
// Athletes
import Alerts from "../containers/athletes/Alerts";
import AthleteGeneral from "../containers/athletes/AthleteGeneral";
import Athletes from "../containers/athletes/Athletes";
// Management
import InviteScreen from "../containers/management/InviteScreen";
import InvitedResponseModal from "../containers/management/InvitedResponseModal";
import Management from "../containers/management/Management";
// Calendar
import AthletesList from "../containers/calendar/AthletesList";
import TeamsList from "../containers/calendar/TeamsList";
// Training Plans
import ViewTrainingPlan from "../containers/plans/Plan";
import TrainingPlans from "../containers/plans/Plans";
// Admin
import ViewAdminTrainingPlan from "../containers/admin/AdminPlan";
import AdminPlans from "../containers/admin/AdminPlans";
// Settings
import Settings from "../containers/settings/Settings";
import SessionBuilder from "../containers/sessions/SessionBuilder";
import Billing from "../containers/billing/Billing";
import Help from "../containers/help/Help";

// Athlete Screens
const AthletesOverview = {
  id: "athletes",
  step: {
    id: "athletes",
    path: "/dashboard/athletes",
    label: "Athletes",
    Component: (props) => <Athletes {...props} />,
  },
};

const AthleteGeneralPage = {
  id: "athlete",
  step: {
    id: "athlete",
    path: "/dashboard/athlete",
    label: "Athlete",
    Component: (props) => <AthleteGeneral {...props} />,
  },
};
const AlertsDetail = {
  id: "alerts",
  step: {
    id: "alerts",
    path: "/dashboard/alerts",
    label: "Alerts",
    Component: (props) => <Alerts {...props} />,
  },
};

// Management Screens
const ManageTeam = {
  id: "management",
  step: {
    id: "management",
    path: "/dashboard/management",
    label: "Management",
    Component: (props) => <Management {...props} />,
  },
};
const Invite = {
  id: "invite",
  step: {
    id: "invite",
    path: "/dashboard/invite",
    label: "Invite",
    Component: (props) => <InviteScreen {...props} />,
  },
};
const InviteSuccess = {
  id: "invite",
  step: {
    id: "invite",
    path: "/dashboard/invite/success",
    label: "Invite",
    Component: (props) => <InvitedResponseModal {...props} />,
  },
};
const InviteFailure = {
  id: "invite",
  step: {
    id: "invite",
    path: "/dashboard/invite/failure",
    label: "Invite",
    Component: (props) => <InvitedResponseModal {...props} />,
  },
};

// Calendar
const teamsList = {
  id: "teams_list",
  step: {
    id: "teams_list",
    path: "/dashboard/calendar/teams",
    label: "Teams List",
    Component: (props) => <TeamsList {...props} />,
  },
};
const athletesList = {
  id: "athletes_list",
  step: {
    id: "athletes_list",
    path: "/dashboard/calendar/athletes",
    label: "Athletes List",
    Component: (props) => <AthletesList {...props} />,
  },
};
const fullCalendarWidget = {
  id: "calendar",
  step: {
    id: "calendar",
    path: "/dashboard/calendar",
    label: "Full Calendar",
    Component: (props) => <AthleteGeneral {...props} />,
  },
};
// Training Plans
const TrainingPlansOveriew = {
  id: "training_plans",
  step: {
    id: "training_plans",
    path: "/dashboard/plans",
    label: "Training Plans",
    Component: (props) => <TrainingPlans {...props} />,
  },
};
const TrainingPlanDetails = {
  id: "training_plans",
  step: {
    id: "training_plans",
    path: "/dashboard/plan/view",
    label: "Training Plan",
    Component: (props) => <ViewTrainingPlan {...props} />,
  },
};

// Session Builder
const SessionBuilderDetails = {
  id: "session_builder",
  step: {
    id: "session_builder",
    path: "/dashboard/session-builder",
    label: "Session Builder",
    Component: (props) => <SessionBuilder {...props} />,
  },
};

// Help
const HelpDetails = {
  id: "help",
  step: {
    id: "help",
    path: "/dashboard/help",
    label: "Help",
    Component: (props) => <Help {...props} />,
  },
};

// Billing
const BillingDetails = {
  id: "billing",
  step: {
    id: "billing",
    path: "/dashboard/billing",
    label: "Billing",
    Component: (props) => <Billing {...props} />,
  },
};

// Admin Screens
const AdminPlansOverview = {
  id: "admin",
  step: {
    id: "admin",
    path: "/dashboard/admin/plans",
    label: "admin",
    Component: (props) => <AdminPlans {...props} />,
  },
};

const AdminTrainingPlanDetails = {
  id: "training_plans",
  step: {
    id: "training_plans",
    path: "/dashboard/admin/plan",
    label: "Training Plan",
    Component: (props) => <ViewAdminTrainingPlan {...props} />,
  },
};

// Settings
const GeneralSettings = {
  id: "settings",
  step: {
    id: "settings",
    path: "/dashboard/settings",
    label: "Settings",
    Component: (props) => <Settings {...props} />,
  },
};

const AllSteps = [
  // athletes
  AthletesOverview,
  AthleteGeneralPage,
  AlertsDetail,
  // management
  ManageTeam,
  Invite,
  InviteSuccess,
  InviteFailure,
  // calendar
  teamsList,
  athletesList,
  fullCalendarWidget,
  // training plans
  TrainingPlansOveriew,
  TrainingPlanDetails,
  // admin
  AdminPlansOverview,
  AdminTrainingPlanDetails,
  // settings
  GeneralSettings,
  SessionBuilderDetails,
  BillingDetails,
  HelpDetails,
];

export default AllSteps;
