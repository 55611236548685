import React from "react";

const TabLayout = ({
  activeIndex,
  tabs,
  callAction,
  activeColor,
  disableAllTabsExceptFirst = false,
}) => {
  const checkIndex = (tabIndex) => {
    if (activeIndex === tabIndex && activeColor) {
      return "2px solid #08AFA8";
    } else if (activeIndex === tabIndex) {
      return "2px solid #ffffff";
    } else {
      return "2px solid #FFFFFF50";
    }
  };

  const disableAllTabs = (index) => {
    if (disableAllTabsExceptFirst) {
      if (index !== 0) {
        return { pointerEvents: "none", cursor: "not-allowed" };
      }
    }
    return {};
  };

  return (
    <div className="flex justifyBetween alignStart ">
      {tabs &&
        tabs.length > 0 &&
        tabs.map((tab, index) => (
          <p
            key={`tab-${index}`}
            onClick={() => callAction(index)}
            style={{
              color:
                activeIndex === index ? activeColor || "#FFFFFF" : "#FFFFFF50",
              marginRight: 16,
              paddingBottom: 8,
              minWidth: 100,
              textAlign: "center",
              borderBottom: checkIndex(index),
              ...disableAllTabs(index),
            }}
            className="pointer"
          >
            {tab}
          </p>
        ))}
    </div>
  );
};

export default TabLayout;
