import initialState from "../initialState";
import { SET_ATHLETES, SET_TEAMS } from "./actions";

export default function overviewReducer(
  state = initialState.app, { type, payload }
) {
  switch (type) {
    case SET_ATHLETES: {
      return { ...state, athletes: payload['athletes'], overview_details: { barChart: payload['barChart'], pieChart: payload['pieChart'], barMin: payload['barMin'], barMax: payload['barMax'] } };
    }
    case SET_TEAMS: {
      return { ...state, team_list: payload['teams'] };
    }
    default:
      return state;
  }
}
