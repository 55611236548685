import React, { useState } from "react";
// Provider
import axios from "axios";
// Icon
import CrossIcon from "../assets/icons/closs-tab.svg";
// Action
import { validateEmail } from "../_helpers/validation";

const ForgetPassword = (props) => {
    const [email_address, set_email_address] = useState('');
    const [submit, setSubmitting] = useState(false);

    const forgotEmailPassword = async () => {
        if (email_address && validateEmail(email_address)) {
            setSubmitting(true);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/password/forgot/request/?email=${email_address}`);
            if (resp) {
                setSubmitting(false);
                const { data: { details }, status } = resp;
                if (status === 200) {
                    props.onSuccess(email_address);
                } else {
                    props.onError(details);
                }
            } else {
                props.onError(`Failed to submit reset password request. Please try again or contact to support support@wild.ai`);
            }
        } else {
            props.onError(`Please enter valid email address to submit reset password request.`);
        }

    }

    return props.show && (
        <div className="flex column alignCenter justifyCenter model_popup">
            <div className="forgot_password_container">
                <div className="flex justifyBetween alignCenter w-100">
                    <h2>Forgot Password</h2>
                    <img className="pointer" src={CrossIcon} alt="close icon" height={32} width={32}
                        onClick={() => { setSubmitting(false); props.onCancel() }}
                    />
                </div>
                <input
                    type="email"
                    placeholder="Email Address"
                    className="forget_password_input"
                    onChange={(event) => set_email_address(event.target.value)}
                    onKeyUp={(event) => event.keyCode === 13 && forgotEmailPassword()}
                />

                <div className="white-button" style={{ maxWidth: 220, margin: "24px 0 0" }} onClick={() => forgotEmailPassword()} >
                    {submit ? <div className="flex justifyCenter alignCenter"> <div className="custom_button_loader" /></div> : <p> SUBMIT</p>}
                </div>
            </div>
        </div>
    );
}

export default ForgetPassword;