import initialState from "../initialState";
import { SET_CALENDAR } from "./actions";

export default function calendarReducer(
  state = initialState.app,
  { type, payload }
) {
  switch (type) {
    case SET_CALENDAR: {
      return {
        ...state,
        calendarData: {
          ...state.calendarData,
          [payload.key]: payload.calData.data,
        },
      };
    }
    default:
      return state;
  }
}
