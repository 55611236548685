import initialState from "../initialState";
import { SET_MANAGEMENT_TEAM, SET_MANAGEMENT_TEAMS } from "./actions";

export default function managementsReducer(
  state = initialState.app,
  { type, payload }
) {
  switch (type) {
    case SET_MANAGEMENT_TEAMS: {
      return { ...state, team_list: payload };
    }
    case SET_MANAGEMENT_TEAM: {
      return { ...state, team: payload };
    }
    default:
      return state;
  }
}
