import React from "react";

const Loading = () => {
  return (
    <div className="flex column justifyCenter alignCenter w-100 ">
      <div className="custom_loader" />
      <p style={{ marginTop: 32 }}>{`Please wait a moment`}</p>
    </div>
  );
};

export default Loading;
