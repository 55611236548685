export const FETCH_SETTINGS = "settings/FETCH_SETTINGS";
export const UPDATE_SETTINGS = "settings/UPDATE_SETTINGS";
export const SET_SETTINGS = "settings/SET_SETTINGS";
export const UPDATE_PROFILE_PICTURE = "settings/UPDATE_PROFILE_PICTURE";
export const UPDATE_USER_PASSWORD = "settings/UPDATE_USER_PASSWORD";
export const FETCH_TEAM_DETAILS = "settings/FETCH_TEAM_DETAILS";
export const SET_TEAM_DETAILS = "settings/SET_TEAM_DETAILS";
export const UPDATE_TEAM_LOGO = "settings/UPDATE_TEAM_LOGO";
export const UPDATE_TEAM_NAME = "settings/UPDATE_TEAM_NAME";
export const TRANSFER_OWNERSHIP = "settings/TRANSFER_OWNERSHIP";

export const fetchSettings = (success, error) => ({
  type: FETCH_SETTINGS,
  payload: { success, error },
});

export const fetchTeamDetails = (teamPK, onError) => ({
  type: FETCH_TEAM_DETAILS,
  payload: { teamPK, onError },
});

export const updateProfileSettings = (form, success, error) => ({
  type: UPDATE_SETTINGS,
  payload: { form, success, error },
});

export const updateTeamLogo = (team_id, picture, onSuccess, onError) => ({
  type: UPDATE_TEAM_LOGO,
  payload: { team_id, picture, onSuccess, onError },
});

export const updateTeamName = (form, onSuccess, onError) => ({
  type: UPDATE_TEAM_NAME,
  payload: { form, onSuccess, onError },
});

export const setSettings = (payload) => ({
  type: SET_SETTINGS,
  payload,
});

export const setTeamDetails = (payload) => ({
  type: SET_TEAM_DETAILS,
  payload,
});

export const updateProfilePicture = (pictureURL, success, error) => ({
  type: UPDATE_PROFILE_PICTURE,
  payload: {
    pictureURL,
    success,
    error,
  },
});

export const updateUserPassword = (form, successMessage, error) => ({
  type: UPDATE_USER_PASSWORD,
  payload: {
    form,
    successMessage,
    error,
  },
});

export const transferOwnership = (form, onSuccess, onError) => ({
  type: TRANSFER_OWNERSHIP,
  payload: {
    form,
    onSuccess,
    onError,
  },
});
