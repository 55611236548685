import React, { useState, useEffect } from "react";

const AdminTabs = ({ tabs, setTabName }) => {
    const [tabIndex, setIndex] = useState(0);

    const checkIndex = (activeIndex) => {
        if (tabIndex === activeIndex) {
            return "2px solid #ffffff";
        }
        return null;
    };

    useEffect(() => {
        if (window.localStorage.getItem("tab") === null) {
            window.localStorage.setItem("tab", tabs[0]);
            setIndex(0);
            setTabName(tabs[0]);
        } else {
            for (let i = 0; i < tabs.length; i++) {
                if (window.localStorage.getItem("tab") === tabs[i]) {
                    setIndex(i);
                    setTabName(tabs[i]);
                }
            }
        }
    }, [tabs]);

    const handleChange = (tab_name) => {
        for (let i = 0; i < tabs.length; i++) {
            if (tab_name === tabs[i]) {
                window.localStorage.setItem("tab", tabs[i]);
                setIndex(i);
                setTabName(tabs[i]);
            }
        }
    };
    return (
        <div className="flex alignStart w-100">
            {tabs &&
                tabs.length > 0 &&
                tabs.map((tab, index) => (
                    <p className="editTab" key={`tab-${index}`}
                        onClick={() => handleChange(tab)}
                        style={{ borderBottom: checkIndex(index), minWidth: 100, textAlign: 'center' }}
                    >
                        {tab}
                    </p>
                ))}
        </div>
    );
};

export default AdminTabs;
