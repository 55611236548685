import React, { useState, useEffect, useRef } from "react";
// Providers
import { useSelector, useDispatch } from "react-redux";
import { split } from "lodash";
// Icons 
import WhiteClose from "../assets/icons/light-cross.svg";
import InfoIcon from "../assets/icons/information.svg";
import CrossTab from "../assets/icons/closs-tab.svg";
// Components
import TagsInput from "./inputs/TagInput";
// Actions
import { createNotification } from "../store/app/actions";
import { fetchSportTypes, fetchTrainingLevels, createPlan, fetchPlans } from "../store/trainings/actions";

const CreatePlan = ({ show, closePopup }) => {
    const { sport_types, training_levels } = useSelector((state) => state.training_plans);
    const dispatch = useDispatch();
    let currency_list = ["USD", "EUR", "JPY", "GBP"];
    let [image_file, set_image_file] = useState({
        file: "",
        preview: null
    });
    const [submitting, setSubmitting] = useState(false);
    const [infos, setInfos] = useState({});
    const [errors, setErrors] = useState({});
    const [newTags, tagsCollector] = useState([]);
    const [field_count, setFieldCount] = useState({
        summary: 0,
        summary_rows: 3,
        description: 0,
        description_rows: 3,
        coach_bio: 0,
        coach_bio_rows: 3,
    });

    function useOutsideAlerter(ref, closeSelector) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    closeSelector();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref, closeSelector]);
    }

    const closeSelector = () => setInfos({});
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, closeSelector);

    let checkCharcCount = (field, str) => {
        const field_row = field + "_rows";
        if (str >= 0 && str < 150) {
            setFieldCount({ ...field_count, [field_row]: 3, [field]: str });
        } else if (str >= 150 && str < 300) {
            setFieldCount({ ...field_count, [field_row]: 6, [field]: str });
        } else if (str >= 300 && str < 450) {
            setFieldCount({ ...field_count, [field_row]: 9, [field]: str });
        } else if (str >= 450 && str < 600) {
            setFieldCount({ ...field_count, [field_row]: 12, [field]: str });
        } else if (str >= 600) {
            setFieldCount({ ...field_count, [field_row]: 17, [field]: str });
        }
    }

    function generateRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const setTags = (tags) => {
        newTags.push(tags);
        tagsCollector([...newTags]);
    };

    const setPastedTags = (value) => {
        if (value !== null && value !== "") {
            const tags = split(value, " ");
            if (tags.length > 0) {
                tags.map((tag) => {
                    addCustomTags(tag);
                    return true;
                });
                document.getElementById("tagInputField")["value"] = "";
            }
        }
    };

    const addCustomTags = (value) => {
        if (value !== "") {
            setTags({
                tag: value.trim(),
                status: "new",
                color: generateRandomColor()
            });
        }
    };

    const onInsertTag = (event) => {
        setTags({
            tag: event.target.value.trim(),
            status: "new",
            color: generateRandomColor()
        });
        event.target.value = "";
    };

    const onRemoveTag = (indexToRemove) => {
        if (indexToRemove > -1) {
            tagsCollector([
                ...newTags.filter((_, index) => index !== indexToRemove),
            ]);
        }
    };

    const onImageUpload = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            var totalBytes = files[0].size;
            if (totalBytes <= 2000000) {
                if (files[0].type === "image/jpeg" || files[0].type === "image/png") {
                    set_image_file({
                        file: files[0],
                        preview: reader.result
                    })
                }
            } else {
                dispatch(
                    createNotification(
                        "error-toast",
                        "Please upload image file of size 2MB or below"
                    )
                );
            }
        };
        reader.readAsDataURL(files[0]);
    };

    const validateInputs = (e) => {
        if (e.target.value === "")
            setErrors({ ...errors, [e.target.name]: true });
        else setErrors({ ...errors, [e.target.name]: false });
    };

    const onError = (error) => { setSubmitting(false); dispatch(createNotification("error-toast", error)) }
    const onSuccess = (message) => { setSubmitting(false); closePopup(); dispatch(fetchPlans(onError)); dispatch(createNotification("success-toast", message)) }
    const onFinish = async (e) => {
        e.preventDefault();
        const { name, short_title, sport_type, level, plan_length_weeks, coach_bio, summary, description, video, price, currency, equipment_required } = e.target.elements;
        let tags_list = [];
        if (newTags && newTags.length > 0) {
            for (let i in newTags) {
                tags_list.push(newTags[i].tag);
            }
        } else {
            dispatch(createNotification("error-toast", "Please add at least one tag that best describe your plan. To add a tag, enter the words separated by a space."));
            return;
        }

        setSubmitting(true);
        let formData = new FormData();
        formData.append('name', name['value']);
        formData.append('short_title', short_title['value']);
        formData.append('summary', summary['value']);
        formData.append('sport_type', sport_type['value']);
        formData.append('level', level['value']);
        formData.append('plan_length', plan_length_weeks['value']);
        formData.append('plan_image', image_file['file']);
        formData.append('promo_video', video['value']);
        formData.append('description', description['value']);
        formData.append('equipment_required', equipment_required['value'])
        if (tags_list && tags_list.length > 0) {
            formData.append('tags', tags_list.join());
        } else {
            formData.append('tags', null);
        }
        formData.append('coach_bio', coach_bio['value']);
        formData.append('price', price['value']);
        formData.append('currency', currency['value']);
        dispatch(createPlan(formData, onSuccess, onError));
    };

    useEffect(() => {
        dispatch(fetchSportTypes());
        dispatch(fetchTrainingLevels());
        
    }, []);

    return show ?
        <div className="model_popup_sidebar">
            <div className="container">
                {/* Header */}
                <div className="flex justifyBetween alignCenter w-100">
                    <h3>Create Plan </h3>
                    <img className="pointer" src={CrossTab} alt="close icon" height={24} width={24} onClick={() => closePopup()} />
                </div>

                {/* Content Area : Form */}
                <div className="flex column justifyStart alignCenter w-100" ref={wrapperRef} style={{ marginTop: 40 }}>
                    <form className="flex column alignCenter justifyCenter w-100" onSubmit={(e) => onFinish(e)}>
                        {/* Field: short_title  */}
                        <div className="flex column justifyStart alignStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Your title</label>
                                    <img className="pointer" onClick={() => setInfos({ short_title: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                    {infos.short_title ?
                                        <div className="absolute_info flex alignStart">
                                            <p>Please enter a short title for your training plan </p>
                                            <img className="pointer" onClick={() => setInfos({ short_title: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                        </div>
                                        : null}
                                </div>
                                <input
                                    id="short_title"
                                    type="text"
                                    name="short_title"
                                    required
                                    maxLength={250}
                                    className={errors.short_title ? "settings-background border-bottom-red" : "settings-background"}
                                    onBlur={validateInputs}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                            {errors.short_title && (<span style={{ color: '#EB5757', fontSize: 12 }}>Please enter a short title for your training plan.</span>)}
                        </div>
                        {/* Field: coach_bio */}
                        <div className="flex column justifyStart alignStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Your Bio( max 1500 characters)</label>
                                    <div className="flex alignCenter">
                                        <label className="settings-label" style={{ marginRight: 16 }}>({field_count['coach_bio']} / 1500)</label>
                                        <img className="pointer" onClick={() => setInfos({ coach_bio: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />

                                    </div>
                                    {infos.coach_bio && (
                                        <div className="absolute_info flex alignStart">
                                            <p> A description of yourself, your qualifications and experience</p>
                                            <img className="pointer" onClick={() => setInfos({ coach_bio: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                        </div>
                                    )}
                                </div>
                                <textarea id="coach_bio"
                                    name="plan_coach_bio"
                                    required
                                    type="text"
                                    rows={field_count['coach_bio_rows']}
                                    maxLength={1500}
                                    onBlur={validateInputs}
                                    className={errors.plan_coach_bio ? "settings-background border-bottom-red" : "settings-background"}
                                    onChange={(e) => checkCharcCount('coach_bio', e.target.value.length)}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                            {errors.plan_coach_bio && (<span style={{ color: '#EB5757', fontSize: 12 }}>Please add a description of yourself, your qualifications and experience.</span>)}
                        </div>
                        {/* Field: name  */}
                        <div className="flex column justifyStart alignStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "0 0 12px" }}>
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Plan name ( max 250 characters)</label>
                                    <img className="pointer" onClick={() => setInfos({ name: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                    {infos.name ?
                                        <div className="absolute_info flex alignStart">
                                            <p>Please enter a name for your training plan </p>
                                            <img className="pointer" onClick={() => setInfos({ name: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                        </div>
                                        : null}
                                </div>
                                <input
                                    id="name"
                                    type="text"
                                    required
                                    name="name"
                                    maxLength={250}
                                    className={errors.name ? "settings-background border-bottom-red" : "settings-background"}
                                    onBlur={validateInputs}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                            {errors.name && (<span style={{ color: '#EB5757', fontSize: 12 }}>Please enter a name for your training plan.</span>)}
                        </div>
                        {/* Field: sport_types */}
                        <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                            <div className="flex justifyBetween alignCenter w-100 relative">
                                <label className="settings-label">Sport Type</label>
                                <img className="pointer" onClick={() => setInfos({ sport_type: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                {infos.sport_type ?
                                    <div className="absolute_info flex alignStart">
                                        <p>Choose the sport type that best describes your training plan</p>
                                        <img className="pointer" onClick={() => setInfos({ sport_type: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                    </div>
                                    : null}
                            </div>
                            <select id="sport_type" required className="model_popup_selector">
                                {(sport_types && sport_types.length > 0) ? sport_types.map((option, index) =>
                                    <option value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                        {option.display_name}
                                    </option>
                                ) : null}
                            </select>
                        </div>
                        {/* Field: level */}
                        <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                            <div className="flex justifyBetween alignCenter w-100 relative">
                                <label className="settings-label">Level</label>
                                <img className="pointer" onClick={() => setInfos({ level: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                {infos.level ?
                                    <div className="absolute_info flex alignStart">
                                        <p>Choose the training level your plan is most suited to</p>
                                        <img className="pointer" onClick={() => setInfos({ level: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                    </div>
                                    : null}
                            </div>
                            <select id="level" required className="model_popup_selector">
                                {(training_levels && training_levels.length > 0) ? training_levels.map(
                                    (option, index) =>
                                        <option value={option.pk} key={`option-${index + 1}`} className="settings-value settings-background">
                                            {option.display_name}
                                        </option>
                                ) : null}
                            </select>
                        </div>
                        {/* Field: plan length in weeks */}
                        <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                            <div className="flex justifyBetween alignCenter w-100 relative">
                                <label className="settings-label">Plan length in weeks</label>
                                <img className="pointer" onClick={() => setInfos({ weeks: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                {infos.weeks ?
                                    <div className="absolute_info flex alignStart">
                                        <p>Enter a duration your plan is intended to run for</p>
                                        <img className="pointer" onClick={() => setInfos({ weeks: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                    </div>
                                    : null}
                            </div>
                            <input
                                id="plan_length_weeks"
                                type="number"
                                min={1}
                                max={56}
                                defaultValue={4}
                                required
                                className="settings-value settings-background"
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            />
                        </div>
                        {/* Field: plan description */}
                        <div className="flex column justifyStart alignStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Description( max 1500 characters)</label>
                                    <div className="flex alignCenter">
                                        <label className="settings-label" style={{ marginRight: 16 }}>({field_count['description']} / 1500)</label>
                                        <img className="pointer" onClick={() => setInfos({ description: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                    </div>
                                    {infos.description && (
                                        <div className="absolute_info flex alignStart">
                                            <p>Please enter a description of your training plan. This could include how the plan progresses and/or what type of training it includes.</p>
                                            <img className="pointer" onClick={() => setInfos({ description: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                        </div>
                                    )}
                                </div>
                                <textarea
                                    id="description"
                                    required
                                    name="plan_description"
                                    type="text"
                                    rows={field_count['description_rows']}
                                    maxLength={1500}
                                    onBlur={validateInputs}
                                    className={errors.plan_description ? "settings-background border-bottom-red" : "settings-background"}
                                    onChange={(e) => checkCharcCount('description', e.target.value.length)}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                            {errors.plan_description && (<span style={{ color: '#EB5757', marginTop: 8, fontSize: 12 }}>
                                Please enter a description of your training plan. This could include how the plan progresses and/or what type of training it includes
                            </span>)}
                        </div>
                        {/* Field: summary */}
                        <div className="flex column justifyStart alignStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Summary (max 1000 characters)</label>
                                    <div className="flex alignCenter">
                                        <label className="settings-label" style={{ marginRight: 16 }}>({field_count['summary']} / 1000)</label>
                                        <img className="pointer" onClick={() => setInfos({ summary: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                    </div>
                                    {infos.summary ?
                                        <div className="absolute_info flex alignStart">
                                            <p> Please enter a description of your training plan. This could include how the plan progresses and/or what type of training it includes</p>
                                            <img className="pointer" onClick={() => setInfos({ summary: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                        </div>
                                        : null}
                                </div>
                                <textarea
                                    id="summary"
                                    name="summary"
                                    required
                                    type="text"
                                    rows={field_count['summary_rows']}
                                    maxLength={1000}
                                    onBlur={validateInputs}
                                    className={errors.summary ? "settings-background border-bottom-red" : "settings-background"}
                                    onChange={(e) => checkCharcCount('summary', e.target.value.length)}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                            {errors.summary && (<span style={{ color: '#EB5757', fontSize: 12 }}> Please enter a description of your training plan. This could include how the plan progresses and/or what type of training it includes. </span>)}
                        </div>
                        {/* Field: equipment_required  */}
                        <div className="flex column justifyStart alignStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Equipment</label>
                                    {/* <img className="pointer" onClick={() => setInfos({ video: true })} src={InfoIcon} alt="info_icon" height={16} width={16} /> */}
                                </div>
                                <input
                                    id="equipment_required"
                                    type="text"
                                    name="equipment_required"
                                    required
                                    className="settings-background"
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                        </div>
                        {/* Field: tags */}
                        <div className="flex column justifyStart alignStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Tags</label>
                                    <img className="pointer" onClick={() => setInfos({ tags: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                    {infos.tags && (
                                        <div className="absolute_info flex alignStart">
                                            <p>Add tags that best describe your plan. To add a tag, enter the word separated by a space.</p>
                                            <img className="pointer" onClick={() => setInfos({ tags: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                        </div>
                                    )}
                                </div>
                                <TagsInput
                                    className="plan_tags"
                                    submitting={submitting}
                                    list={newTags}
                                    name="tags"
                                    placeholderText="Add tags"
                                    onValidate={validateInputs}
                                    onPasteTags={(tags) => setPastedTags(tags)}
                                    onAddTag={(tagFieldEvent) => onInsertTag(tagFieldEvent)}
                                    onDeleteTag={(index) => onRemoveTag(index)}
                                    onError={(message) => dispatch(createNotification("error-toast", message))}
                                />
                            </div>
                            {errors.tags && newTags.length === 0 && (<span style={{ color: '#EB5757', fontSize: 12 }}>Please add at least one tag that best describe your plan. To add a tag, enter the words separated by a space. </span>)}
                        </div>
                        {/* Field: price  */}
                        <div className="flex column alignStart justifyStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Price</label>
                                    <img className="pointer" onClick={() => setInfos({ price: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                    {infos.price && (
                                        <div className="absolute_info flex alignStart">
                                            <p>Enter a total price for your plan</p>
                                            <img className="pointer" onClick={() => setInfos({ price: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                        </div>
                                    )}
                                </div>
                                <input
                                    id="price"
                                    required
                                    name="plan_price"
                                    type="number"
                                    min={0}
                                    defaultValue={0}
                                    onBlur={validateInputs}
                                    className={errors.plan_price ? "settings-background border-bottom-red" : "settings-background"}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                            {errors.plan_price && (<span style={{ color: '#EB5757', fontSize: 12 }}> Please enter a total price for your plan.  </span>)}
                        </div>
                        {/* Field: currency */}
                        <div className="flex column alignStart justifyStart sidebar-settings-form-item">
                            <label className="settings-label">Currency</label>
                            <select id="currency" required className="model_popup_selector">
                                {currency_list && currency_list.map((option, index) =>
                                    <option value={option} key={`option-${index + 1}`} className="settings-value settings-background">
                                        {option}
                                    </option>
                                )}
                            </select>
                        </div>
                        {/* Field: image  */}
                        <div className="flex column justifyStart alignStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Plan image</label>
                                    <img className="pointer" onClick={() => setInfos({ image: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                    {infos.image ?
                                        <div className="absolute_info flex alignStart" style={{ zIndex: 1 }}>
                                            <p>Choose an image that will be displayed for your plan. For maximum engagements, we suggest using an image of yourself.</p>
                                            <img className="pointer" onClick={() => setInfos({ image: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                        </div>
                                        : null}
                                </div>
                                {image_file['preview'] ?
                                    <div className="flex relative" style={{ marginTop: 12 }}>
                                        <img src={image_file['preview']} alt="plan_image" height={200} width={425} style={{ borderRadius: 12, objectFit: 'cover' }} />
                                    </div>
                                    : <div className="name_letter" style={{ borderRadius: 12, height: 200, width: 425, marginTop: 12, background: 'rgba(0,0,0,0.5)', boxShadow: 'none' }}>
                                        No image selected
                                    </div>}
                                <input id="image" required type="file" accept="image/x-png,image/jpeg" className="settings-value pointer"
                                    onChange={onImageUpload}
                                />
                            </div>
                        </div>
                        {/* Field: plan_video  */}
                        <div className="flex column justifyStart alignStart w-100">
                            <div className="flex column alignStart justifyStart sidebar-settings-form-item" style={{ margin: "16px 0 12px" }}>
                                <div className="flex justifyBetween alignCenter w-100 relative">
                                    <label className="settings-label">Plan promo video</label>
                                    <img className="pointer" onClick={() => setInfos({ video: true })} src={InfoIcon} alt="info_icon" height={16} width={16} />
                                    {infos.video && (
                                        <div className="absolute_info flex alignStart">
                                            <p>Enter a URL of a video describing this training plan. Youtube works best!</p>
                                            <img className="pointer" onClick={() => setInfos({ video: false })} style={{ marginLeft: 16, marginTop: 6 }} src={WhiteClose} alt="close_icon" height={12} width={11} />
                                        </div>
                                    )}
                                </div>
                                <input
                                    id="video"
                                    type="text"
                                    name="plan_promo_video"
                                    required
                                    onBlur={validateInputs}
                                    className={errors.plan_promo_video ? "settings-background border-bottom-red" : "settings-background"}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                            </div>
                            {errors.plan_promo_video && (<span style={{ color: '#EB5757', fontSize: 12 }}>Please enter a URL of a video for this movement.</span>)}
                        </div>
                        {/* Buttons */}
                        <div className="flex justifyBetween alignCenter w-100" style={{ margin: "24px 0 0" }}>
                            {/* Cancel */}
                            <button className="white-button" onClick={() => closePopup()} style={{ maxWidth: 180 }}>
                                <p className="button_label">Cancel</p>
                            </button>
                            {/* Save plan */}
                            <button className="white-button" htmltype="submit" style={{ maxWidth: 180 }}>
                                {submitting ?
                                    <div className="flex justifyCenter alignCenter">
                                        <div className="custom_button_loader" />
                                    </div>
                                    : <p className="button_label">Save Plan</p>}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        : null;
};

export default CreatePlan;
