import React from "react";
import MobileAlertSVG from "../assets/icons/mobile.svg";


const MobileAlert = ({ setMobileStatus }) => (
    <div className="flex column justifyCenter alignCenter alert_container" style={{ background: 'linear-gradient(142.27deg, #01213A 0%, #000000 100%)' }}>
        <div className="flex column justifyCenter alignCenter w-100" style={{ background: 'transparent' }}>
            <img src={MobileAlertSVG} alt="alert-icon" height={300} width={300} />
            <h2 style={{ color: '#FFFFFF', width: '80%', fontSize: 24 }}> We need a little bit more space </h2>
            <p style={{ color: '#FFFFFF', width: '80%', margin: "24px auto" }}>  Coach Dashboard requires a larger screen to be able to operate. </p>
            <p style={{ color: '#FFFFFF', width: '80%', margin: "0 auto 24px" }}> We recommend using a laptop or desktop computer </p>
            <div className="white-button pointer" style={{ maxWidth: '80%', height: 56 }} onClick={() => { window.localStorage.setItem("is_mobile", false); setMobileStatus(); }}>
                View anyway
            </div>
        </div>
    </div >
);
export default MobileAlert;
