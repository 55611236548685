import React, { useState } from "react";
import "./Slider.css";

const Slider = ({ value, setValue }) => {
  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value);
    setValue(newValue);
  };

  return (
    <div className="slider-container flex column">
      <div
        className="checkpoints flex row justifyBetween"
        style={{ marginBottom: "12px" }}
      >
        <div className="checkpoint">1</div>
        <div className="checkpoint">10</div>
        <div className="checkpoint">20</div>
        <div className="checkpoint">30</div>
        <div className="checkpoint">40+</div>
      </div>
      <input
        type="range"
        min={1}
        max={40}
        step={1}
        value={value}
        onChange={handleSliderChange}
        className="slider-input"
        style={{ outline: "none" }}
      />
    </div>
  );
};

export default Slider;
