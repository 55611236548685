import React, { useEffect, useState } from "react";
import video2 from "../../assets/videos/Wild_CD_AthletesPage.mp4";
import video3 from "../../assets/videos/Wild_CD_Calendar.mp4";
import video1 from "../../assets/videos/Wild_CD_Overview.mp4";
import ArrowRight from "../../assets/icons/arrow-right-white.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import "./Guide.css";
import { history } from "../../index";

const Guide = () => {
  const [screens, setScreens] = useState([
    {
      video: video1,
      headline: "Organize your female athletes like never before",
      description:
        "Create teams and fill them with athletes to improve their performance based on their cycle and hormonal data!",
      bullets: [
        "See your athlete’s check-ins",
        "Prompt your athletes to check in",
        "Get suggestions tailored to your athlete’s cycle phase",
      ],
    },
    {
      video: video2,
      headline: "Analyze your athlete’s data over time",
      description:
        "You can see trends in your athlete’s check-ins, that over time can give you clues about their performance and how to improve it.",
      bullets: [
        "See athlete’s check-ins over time",
        "Analyze single check-ins or how they are related",
        "See their current cycle information in real time",
      ],
    },
    {
      video: video3,
      headline: "Predict your athlete’s hormonal cycles",
      description:
        "With the Wild.AI calendar view, you can predict where your athlete will be at a specific point in the future.",
      bullets: [
        "See your athlete’s hormonal cycles",
        "Get predictions on their future cycles",
        "View your athlete’s monthly reports",
      ],
    },
  ]);
  const [activeScreen, setActiveScreen] = useState(0);

  return (
    <div className="flex column alignCenter justifyCenter w-100 h-100">
      {screens.map(
        (screen, index) =>
          activeScreen === index && (
            <div className="explainer">
              <video
                height="423"
                width="684"
                style={{ marginTop: "32px" }}
                autoPlay
                loop
              >
                <source src={screen.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div class="explainer_wrapper">
                <span className="explainer_heading">{screen.headline} </span>
                <span className="explainer_description">
                  {screen.description}
                </span>
                <span className="explainer_description">
                  <ul style={{ paddingLeft: "22px" }}>
                    {screen.bullets.map((bullet) => (
                      <li>{bullet}</li>
                    ))}
                  </ul>
                </span>
              </div>
              <div className="explainer_navigation">
                <div
                  className="flex row justifyCenter alignCenter pointer"
                  onClick={() => setActiveScreen(activeScreen - 1)}
                >
                  {index !== 0 ? (
                    <img
                      src={ArrowLeft}
                      alt="arrow left"
                      height={24}
                      width={24}
                      style={{ margin: "0 4px" }}
                    />
                  ) : (
                    <div style={{ width: "32px" }} />
                  )}
                </div>

                <div
                  className="explainer_dots"
                  style={{ marginLeft: index === 2 ? "165px" : 0 }}
                >
                  <div
                    class="dot_1"
                    style={{
                      backgroundColor: "#08afa8",
                      width: (index === 1 && "32px") || (index === 2 && "52px"),
                    }}
                    onClick={() => setActiveScreen(0)}
                  />
                  {index !== 1 && index !== 2 && (
                    <div class="dot" onClick={() => setActiveScreen(1)} />
                  )}
                  {index !== 2 && (
                    <div class="dot" onClick={() => setActiveScreen(2)} />
                  )}
                </div>
                {index === 2 ? (
                  <div
                    className="white-button"
                    style={{ maxWidth: 240 }}
                    onClick={() => history.push("/onboarding/createTeam")}
                  >
                    <span
                      style={{
                        fontWeight: "700",
                        lineHeight: 0,
                        fontSize: "16px",
                        letterSpacing: 0,
                      }}
                    >
                      I’m ready, Let’s go!
                    </span>
                  </div>
                ) : (
                  <div
                    className="flex row justifyCenter alignCenter pointer"
                    onClick={() =>
                      setActiveScreen(activeScreen !== 2 ? activeScreen + 1 : 2)
                    }
                  >
                    <span>NEXT</span>
                    <img
                      src={ArrowRight}
                      alt="arrow right"
                      height={24}
                      width={24}
                      style={{ margin: "0 4px" }}
                    />
                  </div>
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
};
export default Guide;
