import React from "react";
// Onboarding Steps
import CreateTeam from "../containers/onboarding/CreateTeam";
import AddAthletes from "../containers/onboarding/AddAthletes";
import AddCoaches from "../containers/onboarding/AddCoaches";
import AthletesList from "../containers/onboarding/AthletesList";
import OverviewToolip from "../containers/onboarding/OverviewTooltip";
import Guide from "../containers/onboarding/Guide";
import Welcome from "../containers/onboarding/Welcome";
import Congrats from "../containers/onboarding/congrats-screen/Congrats";

const Step1 = {
  id: "welcome",
  step: {
    id: "welcome",
    path: "/onboarding/welcome",
    Component: (props) => <Welcome {...props} />,
  },
};

const Step2 = {
  id: "guide",
  step: {
    id: "guide",
    path: "/onboarding/guide",
    Component: (props) => <Guide {...props} />,
  },
};

const Step3 = {
  id: "createTeam",
  step: {
    id: "createTeam",
    path: "/onboarding/createTeam",
    Component: (props) => <CreateTeam {...props} />,
  },
};

const Step4 = {
  id: "congrats",
  step: {
    id: "congrats",
    path: "/onboarding/congrats",
    Component: (props) => <Congrats {...props} />,
  },
};

const Step5 = {
  id: "addAthletes",
  step: {
    id: "addAthletes",
    path: "/onboarding/addAthletes",
    Component: (props) => <AddAthletes {...props} />,
  },
};

const Step6 = {
  id: "athletesList",
  step: {
    id: "athletesList",
    path: "/onboarding/athletesList",
    Component: (props) => <AthletesList {...props} />,
  },
};

const Step7 = {
  id: "addCoaches",
  step: {
    id: "addCoaches",
    path: "/onboarding/addCoaches",
    Component: (props) => <AddCoaches {...props} />,
  },
};

const Step8 = {
  id: "overviewToolip",
  step: {
    id: "overviewToolip",
    path: "/onboarding/overviewToolip",
    Component: (props) => <OverviewToolip {...props} />,
  },
};

const OnboardingSteps = [
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
];

export default OnboardingSteps;
