import React, { useState } from "react";
import api from "../../services/api/ApiService";
import WhiteCross from "../../assets/icons/white-cross.svg";
import "./ContactFormPopup.css";
import { useDispatch } from "react-redux";
import { createNotification } from "../../store/app/actions";

const ContactFormPopup = ({ show, hide }) => {
  const dispatch = useDispatch();
  const onError = (error) => dispatch(createNotification("error-toast", error));
  const contactFormInitialState = {
    name: "",
    email_address: "",
    phone_number: "",
    number_of_athletes: 0,
    organization: "",
  };

  const [details, setDetails] = useState(contactFormInitialState);

  const submitContactForm = async () => {
    if (details.name && details.email_address && details.number_of_athletes) {
      try {
        if (
          details.name &&
          details.email_address &&
          details.number_of_athletes
        ) {
          let body = details;
          if (!details.phone_number) delete body.phone_number;
          if (!details.organization) delete body.organization;
          await api.post("coach/contact-form", body);
          dispatch(
            createNotification("success-toast", "Thank you for contacting us.")
          );
          hide();
          setDetails(contactFormInitialState);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          onError(
            "This route does not exist. please contact with support@wild.ai"
          );
        } else {
          if (err.response) {
            const {
              data: { details },
            } = err.response;
            onError(details);
          }
        }
      }
    } else {
      onError("You should fill all required fields.");
    }
  };

  if (!show) {
    return <></>;
  }
  return (
    <div className="contactform-popup" onClick={(e) => e.stopPropagation()}>
      <div className="flex row justifyBetween alignCenter">
        <div>
          <p style={{ fontSize: "27px" }}>Get in touch!</p>
          <p>We'll get back to you within 48 hours.</p>
        </div>
        <img
          className="pointer"
          src={WhiteCross}
          alt="close icon"
          height={16}
          width={16}
          onClick={hide}
        />
      </div>
      <form method="post">
        <label htmlFor="name">
          Your name <span style={{ color: "#F8484E" }}>*</span>
        </label>
        <input
          type="text"
          id="name"
          name="name"
          required
          value={details.name}
          onChange={(e) => setDetails({ ...details, name: e.target.value })}
        />
        <br />
        <label htmlFor="email">
          Email Address <span style={{ color: "#F8484E" }}>*</span>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          required
          value={details.email_address}
          onChange={(e) =>
            setDetails({ ...details, email_address: e.target.value })
          }
        />
        <br />
        <label htmlFor="phone">Phone number</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={details.phone_number}
          onChange={(e) =>
            setDetails({ ...details, phone_number: e.target.value })
          }
        />
        <br />
        <label htmlFor="athletes">
          Number of Athletes <span style={{ color: "#F8484E" }}>*</span>
        </label>
        <input
          type="number"
          id="athletes"
          name="athletes"
          required
          value={details.number_of_athletes}
          onChange={(e) =>
            setDetails({
              ...details,
              number_of_athletes: parseInt(e.target.value),
            })
          }
        />
        <br />
        <label htmlFor="organization">Organization</label>
        <input
          type="text"
          id="organization"
          name="organization"
          value={details.organization}
          onChange={(e) =>
            setDetails({ ...details, organization: e.target.value })
          }
        />
        <br />
        <div
          className="white-button"
          style={{
            height: 48,
            marginTop: 24,
            border: "none",
            fontWeight: 600,
            backgroundColor: "#75FB8D",
          }}
          onClick={submitContactForm}
        >
          SUBMIT
        </div>
      </form>
    </div>
  );
};

export default ContactFormPopup;
