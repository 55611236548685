import React from "react";
import ChevronUp from "../../assets/icons/chevron-up-white.svg";
import ChevronDown from "../../assets/icons/chevron-down-white.svg";
import "./Dropdown.css";
import { useState } from "react";

const Dropdown = ({
  options,
  defaultTitle = "Select an option",
  selectedOption,
  onOptionChange,
  customStyles,
  customOptionStyles,
  multipleChoices,
  selectedOptions,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedOptions || []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (value) => {
    if (multipleChoices) {
      const updatedSelected = selected.includes(value)
        ? selected.filter((item) => item !== value)
        : [...selected, value];
      setSelected(updatedSelected);
      onOptionChange(updatedSelected);
    } else {
      onOptionChange(value);
      setIsOpen(false);
    }
  };

  const isOptionSelected = (value) => {
    return selected.includes(value);
  };

  return (
    <div
      className={`custom-dropdown ${isOpen && "dropdown-open"} ${
        disabled && "disabled"
      }`}
      style={customStyles}
    >
      <div className="dropdown-header" onClick={toggleDropdown}>
        {multipleChoices
          ? selected.length > 0
            ? `${selected.length} athlete${selected.length === 1 ? "" : "s"}`
            : defaultTitle
          : selectedOption
          ? selectedOption
          : defaultTitle}
      </div>
      {isOpen && (
        <div className="dropdown-options scrollable_vertical">
          {options.map((option) => (
            <div
              key={option}
              className="option"
              onClick={() => handleOptionSelect(option)}
              style={customOptionStyles}
            >
              {multipleChoices && (
                <>
                  <input
                    type="checkbox"
                    style={{ width: "10%" }}
                    checked={isOptionSelected(option)}
                    onChange={() => handleOptionSelect(option)}
                  />
                  <span className="checkmark" />
                </>
              )}
              <span>{option}</span>
            </div>
          ))}
        </div>
      )}
      <img
        src={isOpen ? ChevronUp : ChevronDown}
        className="chevron"
        alt="chevron"
        height={24}
        width={24}
      />
    </div>
  );
};

export default Dropdown;
