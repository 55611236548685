import React, { useEffect } from "react";
import TickIcon from "../../assets/icons/done.svg";
import AlertIcon from "../../assets/icons/alert.svg";

const ErrorDialog = ({ open, onClose, type, message }) => {
    useEffect(() => {
        if (open) {
            setTimeout(onClose, 3000);
        }
    }, [open]);

    return (
        open && (
            <div className={`error_block ${type}`}>
                <img
                    src={type === "success-toast" ? TickIcon : AlertIcon}
                    alt="notification-icon"
                    height={24}
                    width={type === "success-toast" ? 24 : 32}
                />
                <p className="error_block_message">{message}</p>
            </div>
        )
    );
};

export default ErrorDialog;
