export const FETCH_ATHLETES = "athletes/FETCH_ATHLETES";
export const SET_ATHLETES = "athletes/SET_ATHLETES";
export const SET_TEAMS = "athletes/SET_TEAMS";

export const fetchAthletes = (teamId, date, onSuccess, onSuccessWithoutData, onError) => ({
  type: FETCH_ATHLETES,
  payload: { teamId, date, onSuccess, onSuccessWithoutData, onError },
});

export const setAthletes = (payload) => ({
  type: SET_ATHLETES,
  payload,
});

export const setTeams = (teams) => ({
  type: SET_TEAMS,
  payload: { teams },
});
