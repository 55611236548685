export default {
  app: {
    isInitialized: false,
    user: null,
    athletes: [],
    overview_details: null,
    team_list: [],
    inviteDetails: null,
    teamDetails: null,
    managementTeamsList: null,
    settings: null,
    team: null,
    notification: { open: false },
    training_plans: [],
    training_plan_sessions: [],
    sport_types: [],
    session_sport_types: [],
    training_levels: [],
    training_intensities: [],
    plan_detail: null,
    plan_session_detail: null,
    sessions_saved_count: 0,
    errorMessage: null,
    deletePlanId: 0,
    admin_plans: [],
    calendarData: {},
  },
  loading: {},
};
