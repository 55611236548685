import { push } from "connected-react-router";
import moment from "moment";
import { call, put, takeEvery } from "redux-saga/effects";
import { getErrorMessage } from "../../_helpers/Requests";
import api from "../../services/api/ApiService";
import { FETCH_ATHLETES, setAthletes, setTeams } from "./actions";

async function athletesForTeam(team_id, date) {
  try {
    return await api.get(`coach/team/${team_id}/blocks/overview?date=${date}`);
  } catch ({ response }) {
    return response;
  }
}

async function getTeams() {
  try {
    return await api.get(`coach/blocks/teams`);
  } catch ({ response }) {
    return response;
  }
}

function* athletes({ payload: { teamId, date, onSuccess, onSuccessWithoutData, onError } }) {
  try {
    const response1 = yield call(getTeams);
    if (response1['status'] === 200) {
      const { data: { teams } } = response1;
      if (teams && teams.length > 0) {
        const sorted_teams = teams && teams.sort((a, b) => (a.pk > b.pk ? 1 : -1));
        yield put(setTeams(sorted_teams));
        if (teamId && date) {
          const response2 = yield call(athletesForTeam, teamId, date);
          if (response2['status'] === 200) {
            const { overview, athlete_bar_chart, pie_chart_list, readiness_min, readiness_max } = response2['data'];
            if (overview && overview.length) {
              yield put(setAthletes({ athletes: overview, barChart: athlete_bar_chart, pieChart: pie_chart_list, barMin: readiness_min, barMax: readiness_max }));
              yield call(onSuccess);
            } else {
              yield put(setAthletes({ athletes: [], barChart: [], pieChart: [], barMin: 0, barMax: 0 }));
              yield call(onSuccess);
            }
          } else {
            yield call(onSuccess);
            yield call(onError, getErrorMessage(response2));
          }
        } else {
          window.localStorage.setItem("team_Id", sorted_teams[0].pk);
          window.localStorage.setItem("team_name", sorted_teams[0].name);
          window.localStorage.setItem('calendar_date', new Date().toUTCString());
          const response3 = yield call(athletesForTeam, sorted_teams[0].pk, moment(new Date().toUTCString()).format("DD/MM/YYYY"));
          if (response3['status'] === 200) {
            const { overview, athlete_bar_chart, pie_chart_list, readiness_min, readiness_max } = response3['data'];
            if (overview && overview.length) {
              yield put(setAthletes({ athletes: overview, barChart: athlete_bar_chart, pieChart: pie_chart_list, barMin: readiness_min, barMax: readiness_max }));
              yield call(onSuccess);
            } else {
              yield put(setAthletes({ athletes: [], barChart: [], pieChart: [], barMin: 0, barMax: 0 }));
              yield call(onSuccess);
            }
          } else {
            yield call(onSuccess);
            yield call(onError, getErrorMessage(response3));
          }
        }
      } else {
        window.localStorage.removeItem("team_Id");
        window.localStorage.removeItem("team_name");
        window.localStorage.removeItem('calendar_date');
        yield call(onSuccessWithoutData);
      }
    } else {
      yield call(onSuccess);
      yield call(onError, getErrorMessage(response1));
    }
  } catch (e) {
    if (e.response && e.response.status === 404) {
      yield put(push("/not-found"));
    } else {
      yield call(onError, e.message);
    }
  }
}

function* athletesSaga() {
  yield takeEvery(FETCH_ATHLETES, athletes);
}

export default athletesSaga;
