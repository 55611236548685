import React, { useState } from "react";
import MinusIcon from "../../assets/icons/minus.svg";
import PlusIcon from "../../assets/icons/plus-icon-white.svg";
import "./Accordion.css";

const Accordion = ({ sections }) => {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (index) => {
    if (activeSection === index) {
      setActiveSection(null);
    } else {
      setActiveSection(index);
    }
  };

  return (
    <div className="accordion w-100">
      {sections.map((section, index) => (
        <div
          className={`accordion-section ${activeSection === index && "active"}`}
          key={index}
        >
          <div
            className="accordion-header"
            onClick={() => toggleSection(index)}
          >
            <span style={{ fontSize: "22px" }}>{section.header}</span>
            <img
              src={activeSection === index ? MinusIcon : PlusIcon}
              width={32}
              height={32}
              className="pointer"
            />
          </div>
          <div className="divider" />
          {activeSection === index && (
            <div className="accordion-content">{section.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
