import React, { useEffect, useState } from "react";
import "./Module.css";
import Dropdown from "../../components/ui/Dropdown";
import BinIcon from "../../assets/icons/delete-bin-light.svg";
import ExercisesContainer from "./ExercisesContainer";
import ConfirmationPopup from "./ConfirmationPopup";

const Module = ({
  id,
  exercises,
  onExerciseContainerDrop,
  onExerciseDragOver,
  onModuleDelete,
  setModules,
  startExerciseEdit,
  updateExercises,
}) => {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [repeat, setRepeat] = useState("");
  const [moduleDetails, setModuleDetails] = useState({
    name: "",
    description: "",
    intensity: "",
    equipment: "",
    activity: "",
    repeat: 0,
    exercises: [],
  });

  const exampleModuleData = {
    name: "module_1",
    description: "module description 1", // Note
    intensity: "Low",
    equipment: "Gym (Full equipment)",
    activity: "Cardio",
    exercises: [
      { name: "Squats", duration: 0, reps: 5 },
      { name: "Rest", duration: 30, laps: 3 },
      { name: "High Knees", duration: 45, laps: 3 },
    ],
  };

  useEffect(() => {
    setModules((prevState) => {
      return prevState.map((module) => {
        if (module.id === id) {
          if (module.moduleDetails.exercises) {
            return {
              ...module,
              moduleDetails: {
                ...module.moduleDetails,
                name: moduleDetails.name,
                description: moduleDetails.description,
                intensity: moduleDetails.intensity,
                equipment: moduleDetails.equipment,
                activity: moduleDetails.activity,
                repeat: moduleDetails.repeat,
              },
            };
          }
          return {
            ...module,
            moduleDetails,
          };
        }
        return module;
      });
    });
  }, [moduleDetails]);

  const repeatTimesToNumber = (repeatTimes) => {
    switch (repeatTimes) {
      case "No repeat":
        return 1;
      case "2 times":
        return 2;
      case "3 times":
        return 3;
      case "5 times":
        return 5;
      default:
        return 0;
    }
  };

  return (
    <div className="flex column sesionBuilder_moduleBuilder">
      <div className="flex" style={{ gap: "10px" }}>
        <div className="flex column" style={{ gap: "12px", width: "50%" }}>
          <input
            type="text"
            placeholder="Module Name"
            className="sesionBuilder_textInput"
            value={moduleDetails.name}
            onChange={(e) =>
              setModuleDetails({ ...moduleDetails, name: e.target.value })
            }
          />
          {/* <Dropdown
            options={["Duration", "Repetition"]}
            defaultTitle="Type"
            selectedOption={moduleDetails.type}
            onChange={(value) =>
              setModuleDetails({ ...moduleDetails, name: e.target.value })
            }
          /> */}
          <Dropdown
            options={[
              "Stretching",
              "Strength",
              "Cardio",
              "Circuit",
              "Swimming",
            ]}
            defaultTitle="Activity"
            selectedOption={moduleDetails.activity}
            onOptionChange={(activity) =>
              setModuleDetails({ ...moduleDetails, activity })
            }
          />
        </div>
        <div className="flex column" style={{ gap: "12px", width: "50%" }}>
          <Dropdown
            options={["Low", "Medium", "High", "All out", "N/A"]}
            defaultTitle="Intensity"
            selectedOption={moduleDetails.intensity}
            onOptionChange={(intensity) =>
              setModuleDetails({ ...moduleDetails, intensity })
            }
          />
          <Dropdown
            options={["No repeat", "2 times", "3 times", "5 times"]}
            defaultTitle="Repeat"
            selectedOption={repeat}
            onOptionChange={(value) => {
              setRepeat(value);
              setModuleDetails({
                ...moduleDetails,
                repeat: repeatTimesToNumber(value),
              });
            }}
          />
          <Dropdown
            options={[
              "No equipment",
              "Gym (Full equipment)",
              "Limited Equipment",
            ]}
            defaultTitle="Equipment"
            selectedOption={moduleDetails.equipment}
            onOptionChange={(equipment) =>
              setModuleDetails({ ...moduleDetails, equipment })
            }
          />
          <textarea
            placeholder="Note"
            rows="4"
            cols="50"
            className="sesionBuilder_textareaInput"
            onChange={(e) =>
              setModuleDetails({
                ...moduleDetails,
                description: e.target.value,
              })
            }
          />
        </div>
      </div>
      <ExercisesContainer
        moduleId={id}
        exercises={exercises}
        onExerciseContainerDrop={onExerciseContainerDrop}
        onExerciseDragOver={onExerciseDragOver}
        startExerciseEdit={startExerciseEdit}
        updateExercises={updateExercises}
      />
      <div
        className="sessionBuilder_deleteIcon"
        onClick={() => setShowConfirmationPopup(true)}
      >
        <img src={BinIcon} alt="plus-icon" height={24} width={24} />
      </div>
      <ConfirmationPopup
        show={showConfirmationPopup}
        hide={() => setShowConfirmationPopup(false)}
        onConfirm={() => onModuleDelete(id)}
      />
      {showConfirmationPopup && <div className="overlay" />}
    </div>
  );
};

export default Module;
