import React from "react";
import CrossIcon from "../../assets/icons/black-cross.svg";
import SearchIcon from "../../assets/icons/search_icon.svg";

const SearchCard = ({
  searchFor, setSearch,
  searchedData, dataSource,
  searchedText, setText,
  showCross, setCrossStatus,
  showLoader, setLoaderStatus,
  is_margin, customStyles,
  teamsSource,
  status }) => {

  // clear search input and hide cross icon
  const clearSearch = () => {
    setSearch(false);
    setCrossStatus(false);
    setText("");
    searchedData(getData());
  };

  const searchItem = (items, query) => {
    var result = items.filter((item) => {
      return Object.values(item).some(function (value) {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some(function (subValue) {
            return String(subValue).toLowerCase().includes(query.toLowerCase());
          });
        } else {
          return String(value).toLowerCase().includes(query.toLowerCase());
        }
      })
    });
    return result;
  };

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setLoaderStatus(true);
      setCrossStatus(false);
      const searchText = e.target.value;
      setSearch(true);
      setText(searchText);
      const data = getData();
      const rs = searchItem(data, searchText);
      setTimeout(() => {
        setLoaderStatus(false);
        searchedData(rs);
        setCrossStatus(true);
      }, 500);
    } else {
      clearSearch();
    }
  };

  const getData = () => {
    if (searchFor === 'teams') {
      const source = teamsSource.map((source, index) => ({
        key: `team-${index}`,
        name: `${source.logo_url}@${source.name}`,
        athlete_count: `${source.athlete_count}`,
        owner_name: `${source.permissions.admin ? 'Me' : `${source.primary_coach.first_name} ${source.primary_coach.last_name}`}`,
        coach_count: `${source.coach_count}`,
        deletePK: `${source.pk}`,
        status: source.permissions.admin
      }));
      return source;
    } else if (searchFor === "athletes") {
      const { athletes, pending_athletes, permissions } = dataSource;
      const { edit } = permissions;
      let athletesData = [];
      let pendingAthletesData = [];
      if (
        pending_athletes &&
        pending_athletes !== null &&
        pending_athletes !== undefined &&
        pending_athletes.length > 0
      ) {
        pendingAthletesData = pending_athletes.map((source, index) => ({
          key: `pending-athlete-${index + 1}`,
          name: `-@${source.email.charAt(0)}`,
          email: `${source.email}/-`,
          member_since: `${source.created}@-`,
          status: `${source.status}@-`,
          deletePK: false,
        }));
      }
      athletesData = athletes.map((source, index) => ({
        key: `athlete-${index + 1}`,
        name: `${source.profile_img_url}@${source.name}@${source.pk}`,
        email: `${source.email}/${source.pk}`,
        member_since: `${source.member_since}@${source.pk}`,
        status: `${source.status}@${source.pk}`,
        deletePK: edit ? `${source.pk}` : false,
      }));
      return pendingAthletesData.concat(athletesData);
    } else if (searchFor === "coaches") {
      const { coaches, pending_coaches } = dataSource;
      let coachesData = [];
      let pendingCoachesData = [];
      if (
        pending_coaches &&
        pending_coaches !== null &&
        pending_coaches !== undefined &&
        pending_coaches.length > 0
      ) {
        pendingCoachesData = pending_coaches.map((source, index) => ({
          key: `pending-coach-${index + 1}`,
          name: `-@${source.email.charAt(0)}`,
          email: `${source.email}`,
          coach_type: `-`,
          member_since: `${source.created}`,
          status: `${source.status}`,
          deletePK: `-`,
        }));
      }
      coachesData = coaches.map((source, index) => ({
        key: `coach-${index + 1}`,
        name: `${source.profile_img_url}@${source.name}`,
        email: `${source.email}`,
        coach_type: `${source.coach_type}`,
        member_since: `${source.member_since}`,
        status: `${source.status}`,
        deletePK: `${source.pk}@${source.status}`,
      }));
      return pendingCoachesData.concat(coachesData);
    }
  };

  return status ? (
    <div className="search-field" style={{ margin: is_margin ? ' 0 1.6rem 0 1rem' : 0 }}>
      <input
        type="text"
        value={searchedText}
        onChange={handleChange}
        placeholder="Search"
        style={{
          borderRadius: 40,
          backgroundColor: "#FFFFFF",
          border: "none",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
          color: "#010101",
          padding: "10px 48px 10px 24px",
        }}
      />


      {showLoader ?
        <div className="custom_search_loader" /> :
        showCross ?
          <img src={CrossIcon} alt="cross-icon" className="pointer absolute" height={18} width={18} onClick={clearSearch}
            style={{ right: 22, top: 3, zIndex: 100, marginLeft: "0.75rem", marginTop: "0.75rem" }}
          />
          :
          <img src={SearchIcon} alt="search-icon" className="pointer absolute" height={18} width={18} onClick={clearSearch}
            style={{ right: 22, top: 2, marginLeft: "0.75rem", marginTop: "0.75rem" }}
          />}
    </div>
  ) : (
    <div className="search-field" style={{ margin: is_margin ? ' 0 1.6rem 0 1rem' : 0 }}>
      <input
        type="text"
        className="pointer_not_allowed"
        value={searchedText}
        disabled={true}
        placeholder="Search"
        style={{
          borderRadius: 40,
          border: "none",
          padding: "10px 48px 10px 24px",
          ...customStyles,
        }}
      />
      <img
        src={SearchIcon}
        alt="search-icon"
        style={{
          height: 18,
          width: 18,
          position: "absolute",
          right: 22,
          top: 2,
          cursor: "pointer",
          opacity: 0.5,
          marginLeft: "0.75rem",
          marginTop: "0.75rem",
        }}
        onClick={clearSearch}
      />
    </div>
  );
};

export default SearchCard;
