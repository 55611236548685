import React from "react";
// Icon
import AlertIcon from "../assets/icons/alert-dark.svg";
// Action
import { history } from "../index";

const Error404 = () => (
  <div className="flex column justifyCenter alignCenter alert_container">
    <div className="flex column justifyCenter alignCenter w-100">
      <img src={AlertIcon} alt="alert-icon" height={60} width={60} />
      <p className="title">
        Wrong Place
      </p>
      <p className="description">
        Not sure how you got here but this place doesn&apos;t exist
      </p>
      <div className="black-button pointer" style={{ maxWidth: '80%', height: 56 }}
        onClick={() => history.push("/dashboard/athletes")}>
        Go Back
      </div>
    </div>
  </div>
);
export default Error404;
