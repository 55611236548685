import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// Icons
import OverviewArrow from "../..//assets/icons/OverviewArrow.svg";
import GreenArrowRight from "../../assets/icons/GreenArrowRight.svg";
import AthletesMockup from "../../assets/icons/Mockup.svg";
import Runner from "../../assets/icons/Runner.svg";
import RunningPosture from "../../assets/icons/RunningPosture.svg";
import GreenCrossIcon from "../../assets/icons/cross-green.svg";
import ArrowRight from "../../assets/icons/forward-black.svg";
import SearchIcon from "../../assets/icons/search_icon.svg";
// Actions
import { updateOnboardingStatus } from "../../_helpers/Requests";
import { history } from "../../index";
import api from "../../services/api/ApiService";
import { createNotification, setUser } from "../../store/app/actions";

const OverviewToolip = () => {
  const [team_list, setTeamList] = useState([]);
  const dispatch = useDispatch();
  const [show_form, setShowForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [letsGoLoader, setLetsGoLoader] = useState(false);

  const onError = (error) => {
    setSubmitting(false);
    dispatch(createNotification("error-toast", error));
  }; // onError
  const onSuccess = (user) => {
    setSubmitting(false);
    dispatch(setUser(user));
    history.push("/dashboard/athletes");
  }; // onSuccess

  useEffect(() => {
    api
      .get(`coach/blocks/teams`)
      .then((result) => {
        if (result.status === 200) {
          const { teams } = result.data;
          if (teams && teams.length > 0) {
            setTeamList(teams);
          } else {
            onError("Please Create a Team First!");
            history.push("/onboarding/createTeam");
          }
        } else {
          onError("Something went wrong, please contact to support@wild.ai");
        }
      })
      .catch((error) => {
        onError(error.message);
      });
  }, []);

  return (
    <div
      className="flex column w-100 h-100"
      style={{
        margin: "40px 40px 0",
        width: "calc(100vw - 240px)",
        maxHeight: "100vh",
      }}
    >
      {/* Header */}
      <div className="flex justifyBetween alignCenter" style={{}}>
        <div className="team_selector">
          <div className="flex justifyBetween alignCenter">
            <p className="selected_team" style={{ cursor: "default" }}>
              {team_list && team_list.length > 0 && team_list[0].name}{" "}
            </p>
          </div>
        </div>

        {/* Breadcrumb */}
        <div className="flex alignCenter">
          <p>Home</p>
          <img
            src={ArrowRight}
            alt="arrow right"
            height={12}
            width={12}
            style={{ margin: "0 4px" }}
          />
          <p>Teams</p>
          <img
            src={ArrowRight}
            alt="arrow right"
            height={12}
            width={12}
            style={{ margin: "0 4px" }}
          />
          <p>{team_list && team_list.length > 0 && team_list[0].name}</p>
        </div>
      </div>

      <div className="management-tab-section">
        {/* Management Tabs */}
        <div className="flex justifyBetween alignStart">
          {["All Teams", "Athletes", "Coaches", "Settings"].map(
            (tab, index) => (
              <p
                key={`tab-${index}`}
                style={{
                  paddingBottom: 4,
                  minWidth: 100,
                  textAlign: "center",
                  borderBottom: index === 1 ? "2px solid #ffffff" : null,
                  opacity: 0.2,
                }}
              >
                {tab}
              </p>
            )
          )}
        </div>

        {/* Invite Tabs */}
        <div className="flex alignCenter h-100">
          {/* Search bar */}
          <div className="search-field" style={{ minWidth: 400 }}>
            <input
              type="text"
              className="pointer_not_allowed"
              disabled={true}
              placeholder="Search"
              style={{
                borderRadius: 40,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                backgroundColor:
                  "linear-gradient(103.82deg, #01213A 0%, #000000 100%)",
                border: "none",
                opacity: 0.7,
                color: "#010101",
                padding: "10px 48px 10px 24px",
              }}
            />
            <img
              src={SearchIcon}
              alt="search-icon"
              style={{
                height: 18,
                width: 18,
                position: "absolute",
                right: 22,
                top: 2,
                zIndex: 100,
                cursor: "pointer",
                opacity: 0.5,
                marginLeft: "0.75rem",
                marginTop: "0.75rem",
              }}
            />
          </div>
          {/* Add Athletes/Coaches Tab */}
          <button
            className="disable-button"
            style={{ minWidth: 180, maxWidth: 180, marginLeft: 16 }}
          >
            <p style={{ margin: 0 }}>Add Athletes</p>
          </button>
        </div>
      </div>

      {!show_form ? (
        <div
          className="flex column alignCenter justifyStart relative"
          style={{ minHeight: 520, marginTop: 16 }}
        >
          <div className="absolute" style={{ top: 32, left: 68, zIndex: 1000 }}>
            <div
              className="flex column relative"
              style={{
                width: 348,
                background: "#08AFA8",
                border: "1px solid #01213a",
                borderRadius: 12,
                padding: 16,
              }}
            >
              <img
                className="absolute"
                src={Runner}
                alt="runner"
                height={116}
                width={149}
                style={{ top: -90, right: 8 }}
              />
              <div className="flex alignCenter justifyBetween w-100">
                <h3 style={{ color: "#01213A", fontSize: 18 }}>
                  Your Overview!
                </h3>
                <img
                  src={GreenCrossIcon}
                  alt="cross icon"
                  height={16}
                  width={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowForm(!show_form)}
                />
              </div>
              <p style={{ color: "#01213A", marginTop: 16, marginBottom: 24 }}>
                You can easily see all your athletes in one spot.
              </p>
              <div
                className="flex alignCenter justifyEnd w-100"
                style={{ cursor: "pointer" }}
                onClick={() => setShowForm(!show_form)}
              >
                <p
                  style={{
                    color: "#01213A",
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                >
                  Next
                </p>
                <img
                  src={GreenArrowRight}
                  alt="cross icon"
                  height={20}
                  width={20}
                  style={{ marginLeft: 12 }}
                />
              </div>
              <img
                src={OverviewArrow}
                alt="curl pointing arrow"
                height={75.13}
                width={75.13}
                style={{ position: "absolute", top: "-26%", left: "-18%" }}
              />
            </div>
          </div>

          <div
            className="relative w-100"
            style={{ padding: "32px 0", opacity: 0.3 }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                background: "#00000050",
                borderRadius: 16,
              }}
            />
            <img
              src={AthletesMockup}
              alt="athlete mockup"
              height="100%"
              width="100%"
              style={{ padding: 8 }}
            />
          </div>
        </div>
      ) : (
        <div
          className="flex column alignCenter justifyStart relative"
          style={{ minHeight: 520, marginTop: 16 }}
        >
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          >
            <div
              className="flex column relative"
              style={{
                width: 348,
                background: "#08AFA8",
                border: "1px solid #01213a",
                borderRadius: 12,
                padding: "10px 16px",
              }}
            >
              <img
                className="absolute"
                src={RunningPosture}
                alt="runner"
                height={146}
                width={179}
                style={{ top: -120, right: "15%" }}
              />
              <div className="flex alignCenter justifyBetween w-100">
                <h3 style={{ color: "#01213A", fontSize: 18 }}>Well done!</h3>
                {/* <img src={GreenCrossIcon} alt="cross icon" height={16} width={16} style={{ cursor: 'pointer' }} onClick={() => setShowForm(!show_form)} /> */}
              </div>
              <p style={{ color: "#01213A", marginTop: 16 }}>
                You’re all set. You can now navigate to your athletes tab, where
                you can see all your athletes in one place.
              </p>
              {letsGoLoader ? (
                <div className="flex justifyEnd alignCenter">
                  <div className="custom_button_loader" />
                </div>
              ) : (
                <div
                  className="flex alignCenter justifyEnd w-100"
                  style={{ cursor: "pointer", marginTop: 16 }}
                  onClick={() => {
                    setLetsGoLoader(true);
                    updateOnboardingStatus(onSuccess, onError);
                  }}
                >
                  <p
                    style={{
                      color: "#01213A",
                      textTransform: "uppercase",
                      fontWeight: 600,
                    }}
                  >
                    Let’s go!
                  </p>
                  <img
                    src={GreenArrowRight}
                    alt="cross icon"
                    height={20}
                    width={20}
                    style={{ marginLeft: 12 }}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            className="relative w-100"
            style={{ padding: "32px 0", opacity: 0.3 }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                background: "#00000050",
                borderRadius: 16,
              }}
            />
            <img
              src={AthletesMockup}
              alt="athlete mockup"
              height="100%"
              width="100%"
              style={{ padding: 8 }}
            />
          </div>
        </div>
      )}

      {/* Skip */}
      <div
        className="flex justifyCenter alignCenter w-100 absolute"
        style={{
          bottom: "10%",
          left: "50%",
          transform: "translate(-50%, -10%)",
        }}
      >
        <button
          onClick={() => {
            setSubmitting(true);
            updateOnboardingStatus(onSuccess, onError);
          }}
          className="white-button"
          style={{ maxWidth: 248 }}
        >
          {submitting ? (
            <div className="flex justifyCenter alignCenter">
              <div className="custom_button_loader" />
            </div>
          ) : (
            <p style={{ margin: 0 }}>Skip Onboarding</p>
          )}
        </button>
      </div>
    </div>
  );
};
export default OverviewToolip;
