import React, { useState } from "react";
import AthleteTabs from "../../components/tabs/TabLayout";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import "./Help.css";

const Help = () => {
  const introductionVideos = [
    {
      link: "https://www.youtube.com/embed/iP1OQwMLLz0?si=4v50iuMSHqPpNcGX&amp;controls=0",
      heading: "Overview Page Team Alerts and Readiness Score",
    },
    {
      link: "https://www.youtube.com/embed/UmZPYwt6QYc?si=8t7E3XI9OetU-8Rv&amp;controls=0",
      heading: "Overview Page Hormone Filtering Specific Suggestions",
    },
  ];
  const allVideos = [
    {
      link: "https://www.youtube.com/embed/iP1OQwMLLz0?si=4v50iuMSHqPpNcGX&amp;controls=0",
      heading: "Overview Page Team Alerts and Readiness Score",
    },
    {
      link: "https://www.youtube.com/embed/UmZPYwt6QYc?si=8t7E3XI9OetU-8Rv&amp;controls=0",
      heading: "Overview Page Hormone Filtering Specific Suggestions",
    },
    {
      link: "https://www.youtube.com/embed/Dxo4x-M6lDw?si=sBGu2fB1y9h2IWat",
      heading: "Athlete Calendar Predictions and Tracking",
    },
    {
      link: "https://www.youtube.com/embed/tAVjQ6F2qO8?si=YjTkQZFgB_R6pCXz",
      heading: "Athletes Page Trends and Predictions Deep Dive",
    },
  ];
  const [tabIndex, setTabIndex] = useState(0);
  const [numOfVideosToShow, setNumOfVideosToShow] = useState(4);

  return (
    <div id="help-page">
      <div className="header">
        <span style={{ fontSize: "32px" }}>&#60; &nbsp; Help</span>
        <Breadcrumbs pagesNames={["Home", "Help"]} fontSize="20px" />
      </div>
      <div className="flex ">
        <AthleteTabs
          activeIndex={tabIndex}
          tabs={["Video Library"]}
          callAction={(tab_index) => setTabIndex(tab_index)}
          activeColor="#08AFA8"
          disableAllTabsExceptFirst
        />
      </div>
      <div className="video-container flex column" style={{ gap: "16px" }}>
        <span style={{ fontSize: "22px" }}>🎉 Getting Started!</span>
        <div className="videos-row flex row" style={{ gap: "16px" }}>
          {introductionVideos.map((video) => (
            <div className="flex column" style={{ gap: "16px" }}>
              <iframe
                src={video.link}
                frameborder="0"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
                width="400"
                height="220"
              />
              <span>{video.heading}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="video-container flex column" style={{ gap: "16px" }}>
        <span style={{ fontSize: "22px" }}>All Videos</span>
        <div
          className="videos-row flex row"
          style={{ gap: "16px", flexWrap: "wrap" }}
        >
          {allVideos.map((video, i) =>
            i + 1 <= numOfVideosToShow ? (
              <div className="flex column" style={{ gap: "16px" }}>
                <iframe
                  src={video.link}
                  frameborder="0"
                  webkitAllowFullScreen
                  mozAllowFullScreen
                  allowFullScreen
                  width="400"
                  height="220"
                />
                <span>{video.heading}</span>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
        {allVideos.length > 4 && allVideos.length + 1 > numOfVideosToShow && (
          <div
            className="transparent-button pointer"
            style={{ maxWidth: 160, alignSelf: "center", marginTop: "40px" }}
            onClick={() => setNumOfVideosToShow(numOfVideosToShow + 4)}
          >
            <p className="button_label">LOAD MORE</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Help;
