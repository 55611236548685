import React, { useState } from "react";
import { useDispatch } from "react-redux";
// Icons
import DeleteBinIcon from "../assets/icons/delete-bin-line.svg";
import PlusIcon from "../assets/icons/plus-line.svg";
// Actions
import { createNotification } from "../store/app/actions";
import { sendCoachesInvite } from "../_helpers/Requests";
import { history } from "../index";


function InviteCoaches({ teamId, screenName }) {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const [inputFields, setInputFields] = useState([{
        emailAddress: '',
    }]);

    const addInputField = () => setInputFields([...inputFields, { emailAddress: '' }]);

    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }

    // onError
    const onError = (error) => {
        setSubmitting(false);
        dispatch(createNotification("error-toast", error));
    };

    // onSuccess
    const onSuccess = () => {
        setSubmitting(false);
        dispatch(createNotification("success-toast", "Coaches has been invited."));
        if (screenName === "athletes") {
            history.push("/dashboard/athletes");
        } else if (screenName === "invite") {
            history.push("/dashboard/management");
        } else {
            history.push("/onboarding/overviewToolip");
        }
    };

    const getEmails = () => {
        let email_list = [];
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].emailAddress !== "") {
                email_list[i] = inputFields[i].emailAddress;
            }
        }
        return email_list;
    }


    return (
        <div className="flex column alignCenter w-100">
            {/* Multiple Inputs */}
            {inputFields.map((data, index) => (
                <div className="flex alignCenter justifyBetween w-100" style={{ margin: "6px 0 0" }} key={index}>
                    <div className="flex column alignStart justifyStart settings-form-item border-bottom">
                        <label className="settings-label" style={{ margin: "0 0 4px" }}>Coach’s email</label>
                        <input type="email" onChange={(evnt) => handleChange(index, evnt)} value={data['emailAddress']} name="emailAddress" />
                    </div>

                    <div className="flex alignCenter justifyEnd" style={{ width: 100 }}>
                        {(inputFields.length !== 1) ? <img src={DeleteBinIcon} alt="delete bin icon" height={24} width={24} onClick={() => removeInputFields(index)} style={{ cursor: 'pointer', marginRight: inputFields.length === index + 1 ? 16 : 0 }} /> : null}
                        {(inputFields.length === index + 1) ? <img src={PlusIcon} alt="delete bin icon" height={24} width={24} onClick={addInputField} style={{ cursor: 'pointer' }} /> : null}
                    </div>
                </div>
            ))}

            {/* Add Athletes/Coaches button */}
            <div className="flex justifyCenter alignCenter w-100" style={{ marginTop: 24 }} >
                <button onClick={() => {
                    const emails = getEmails();
                    if (emails && emails.length > 0) {
                        setSubmitting(true);
                        sendCoachesInvite(teamId, emails, onSuccess, onError)
                    } else {
                        onError("To proceed with sending the invites, please make sure to include the necessary email addresses.")
                    }
                }} className={inputFields.length > 0 ? "white-button" : ""} style={{ maxWidth: 220 }} >
                    {submitting ?
                        <div className="flex justifyCenter alignCenter"><div className="custom_button_loader" /></div>
                        : <p style={{ margin: 0 }}>Invite Coaches</p>}
                </button>
            </div>
        </div>
    )
}
export default InviteCoaches;