import React, { useEffect, useState } from "react";
import video2 from "../../assets/videos/Wild_CD_AthletesPage.mp4";
import video3 from "../../assets/videos/Wild_CD_Calendar.mp4";
import video1 from "../../assets/videos/Wild_CD_Overview.mp4";
import ArrowRight from "../../assets/icons/arrow-right-white.svg";
import ArrowRightDark from "../../assets/icons/arrow-right-line.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import "./Welcome.css";
import { history } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api/ApiService";
import { createNotification } from "../../store/app/actions";

const Welcome = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.app);
  const [coachType, setCoachType] = useState("");
  const [monitoringType, setMonitoringType] = useState("");
  const coachTypes = [
    "Personal Trainer",
    "Strength & Conditioning",
    "Team Coach",
    "Physiotherapist",
    "Nutritionist",
    "Other",
  ];
  const monitoringTypes = [
    "1-10 Athletes",
    "11-50 Athletes",
    "51-100 Athletes",
    "100+ Athletes",
  ];
  const onError = (error, timeout = 6000) =>
    dispatch(createNotification("error-toast", error, timeout));

  const onSubmit = async () => {
    try {
      const coachTypeRes = await api.post("coach/onboarding-questions", {
        question: "I am a?",
        answer: coachType,
      });
      const monitoringTypeRes = await api.post("coach/onboarding-questions", {
        question: "I am monitoring?",
        answer: monitoringType,
      });

      if (coachTypeRes && monitoringTypeRes) {
        history.push("/onboarding/guide");
      }
    } catch (err) {
      onError(
        "Something went wrong. Make sure you've filled in all the fields."
      );
    }
  };

  return (
    <div className="flex row alignCenter justifyCenter w-100 h-100">
      <div
        className="flex column w-100 h-100 desktop"
        style={{ margin: "80px", gap: "40px" }}
      >
        <div
          className="flex column"
          style={{ gap: "16px", marginBottom: "24px" }}
        >
          <span style={{ fontSize: "32px" }}>
            Welcome {user.coach.first_name}
          </span>
          <span style={{ fontSize: "16px" }}>
            Let’s get to know each other a little bit better
          </span>
        </div>
        <div className="iam-block">
          <span style={{ fontSize: "20px" }}>I am a...</span>
          <div className="select-container">
            {coachTypes.map((type) => (
              <div
                className="select-tab"
                style={{
                  backgroundColor: coachType === type ? "white" : "",
                  color: coachType === type ? "#1a2d3b" : "",
                }}
                onClick={() => setCoachType(type)}
              >
                {type}
              </div>
            ))}
          </div>
        </div>
        <div className="iam-block">
          <span style={{ fontSize: "20px" }}>I am monitoring...</span>
          <div className="select-container">
            {monitoringTypes.map((type) => (
              <div
                className="select-tab"
                style={{
                  backgroundColor: monitoringType === type ? "white" : "",
                  color: monitoringType === type ? "#1a2d3b" : "",
                }}
                onClick={() => setMonitoringType(type)}
              >
                {type}
              </div>
            ))}
          </div>
        </div>
        <div
          className="white-button alignCenter justifyCenter flex"
          style={{
            maxWidth: 180,
            gap: "8px",
            pointerEvents: monitoringType && coachType ? "" : "none",
            backgroundColor: monitoringType && coachType ? "" : "grey",
          }}
          onClick={onSubmit}
        >
          <span
            style={{
              fontWeight: "700",
              lineHeight: 0,
              fontSize: "16px",
              letterSpacing: 0,
            }}
          >
            Get Started
          </span>
          <img src={ArrowRightDark} alt="arrow-right" height={20} width={20} />
        </div>
      </div>
      <div className="flex column justifyCenter alignCenter w-100 desktop">
        <img
          src="https://wild-blog-strapi.s3.amazonaws.com/image_72_e4e5bd6e8c.jpg?610431.8000000007"
          alt="wild.ai_poster"
          style={{
            width: "50vw",
            height: "100%",
            minHeight: "100vh",
            objectFit: "cover",
            opacity: 0.35,
          }}
        />
        <div className="absolute">
          <p
            style={{
              textTransform: "uppercase",
              fontSize: "24px",
              width: "400px",
              fontWeight: 600,
            }}
          >
            Why you'll love training your athletes with wild.ai
          </p>
          <div
            className="flex column"
            style={{ gap: "10px", marginTop: "16px" }}
          >
            <div className="flex row alignCenter">
              <img
                src={ArrowRight}
                alt="arrow-right"
                height={20}
                width={20}
                style={{ marginRight: 16 }}
              />
              <p style={{ fontSize: "16px" }}>
                See your athletes by cycle phase
              </p>
            </div>
            <div className="flex row alignCenter">
              <img
                src={ArrowRight}
                alt="arrow-right"
                height={20}
                width={20}
                style={{ marginRight: 16 }}
              />
              <p style={{ fontSize: "16px" }}>
                Analyze your athlete's data over time
              </p>
            </div>
            <div className="flex row alignCenter">
              <img
                src={ArrowRight}
                alt="arrow-right"
                height={20}
                width={20}
                style={{ marginRight: 16 }}
              />
              <p style={{ fontSize: "16px" }}>Predict their hormonal cycles</p>
            </div>
            <div className="flex row alignCenter">
              <img
                src={ArrowRight}
                alt="arrow-right"
                height={20}
                width={20}
                style={{ marginRight: 16 }}
              />
              <p style={{ fontSize: "16px" }}>
                Receive tailored recommendations
              </p>
            </div>
            <div className="flex row alignCenter">
              <img
                src={ArrowRight}
                alt="arrow-right"
                height={20}
                width={20}
                style={{ marginRight: 16 }}
              />
              <p style={{ fontSize: "16px" }}>
                Remind your athletes to check-in
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Welcome;
