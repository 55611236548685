import React, { useEffect, useState } from "react";
// Provider
import { useDispatch, useSelector } from "react-redux";
// Images
import BinActiveIcon from "../../assets/icons/delete-circle-active.svg";
import BinIcon from "../../assets/icons/delete-circle-dark.svg";
import EditActiveIcon from "../../assets/icons/edit-circle-active.svg";
import EditIcon from "../../assets/icons/edit-circle-dark.svg";
import ArrowRight from "../../assets/icons/forward-black.svg";
import TransferActiveIcon from "../../assets/icons/transfer-circle-active.svg";
import TransferIcon from "../../assets/icons/transfer-circle-dark.svg";

// Components
import LoadingTable from "../../components/common/LoadingTable";
import SearchCard from "../../components/common/SearchCard";
import TeamsSelector from "../../components/dropdowns/TeamsSelector";
import ManagementTable from "../../components/tables/ManagementTable";
import ManagementTabs from "../../components/tabs/TabLayout";
import CreateTeamManagement from "./CreateTeamManagement";
import UpdateTeam from "./UpdateTeam";
// Actions
import { isEqual } from "lodash";
import { getManagementTeam } from "../../_helpers/Requests";
import { history } from "../../index";
import { createNotification } from "../../store/app/actions";
import {
  deleteInvitedAthlete,
  deleteInvitedForCoach,
  deleteMember,
  fetchManagementTeam,
  setManagementTeam,
  setManagementTeams,
} from "../../store/management/actions";
import MissingSubscriptionPage from "../../components/common/MissingSubscriptionPage";

const Management = () => {
  const { team_list, team } = useSelector((state) => state.managements);
  const dispatch = useDispatch();
  // States
  let [show_screen, setShowScreen] = useState(false);
  let [loading, setLoading] = useState(false);
  let [tab_index, setTabIndex] = useState(0);
  let [settings_tab, setSettingsTab] = useState({
    show: false,
    tab_name: "",
  });
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true);

  // Search
  const [isSearchActive, setSearchActive] = useState(false);
  const [searchedDataSource, setSearchedDataSource] = useState([]);
  const [searchedText, setSearchText] = useState("");
  const [showCross, setShowCross] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [createTeamForm, showCreateTeamForm] = useState(false);

  const onError = (error) => {
    if (
      error ===
      "Something went wrong. You either don't have a subscription or haven't created any teams."
    ) {
      setIsSubscriptionActive(false);
    }
    setLoading(false);
    dispatch(createNotification("error-toast", error, 30000));
  };
  const onSuccess = () => setLoading(false);
  const onSuccessWithData = (teams, team) => {
    setLoading(false);
    dispatch(setManagementTeams(teams));
    dispatch(setManagementTeam(team));
  };

  const onSuccessWithoutData = () => {
    setLoading(false);
    setTabIndex(0);
    setSettingsTab({
      show: false,
      tab_name: "",
    });
    setSearchActive(false);
    setSearchedDataSource([]);
    setSearchText("");
    setShowCross(false);
    setLoader(false);
    showCreateTeamForm(true);
    dispatch(setManagementTeams([]));
    dispatch(setManagementTeam(null));
  };

  const onDeleteSuccess = () => {
    setLoading(true);
    getManagementTeam(
      Number(window.localStorage.getItem("team_Id")),
      onSuccessWithData,
      onSuccessWithoutData,
      onError
    );
  };

  // Delete athlete / coach when admin want to do
  const onDeleteMember = (member_id) =>
    dispatch(
      deleteMember(
        member_id,
        tab_index === 1 ? "athlete" : tab_index === 2 && "coach",
        onDeleteSuccess,
        onError
      )
    );

  // Delete invited athlete/coach
  const onDeleteInvitedMember = (email_address) => {
    if (tab_index === 1) {
      dispatch(
        deleteInvitedAthlete(
          Number(window.localStorage.getItem("team_Id")),
          email_address,
          onDeleteSuccess,
          onError
        )
      );
    } else if (tab_index === 2) {
      dispatch(
        deleteInvitedForCoach(
          Number(window.localStorage.getItem("team_Id")),
          email_address,
          onDeleteSuccess,
          onError
        )
      );
    }
  };

  useEffect(() => {
    let mergedList = null;
    setTabIndex(
      Number(
        JSON.parse(window.localStorage.getItem("location"))["management_tab"]
      )
    );
    if (team && isEqual(team, { name: "", logo_url: "", code: "" })) {
      setShowScreen(false);
    } else {
      if (tab_index === 0 && team_list) {
        mergedList = team_list.map((source, index) => ({
          key: `team-${index}`,
          name: `${source.logo_url}@${source.name}`,
          athlete_count: `${source.athlete_count}`,
          owner_name: `${
            source.permissions.admin
              ? "Me"
              : `${source.primary_coach.first_name} ${source.primary_coach.last_name}`
          }`,
          coach_count: `${source.coach_count}`,
          deletePK: `${source.pk}`,
          status: source.permissions.admin,
        }));
        setSearchedDataSource(
          mergedList.sort((a, b) => (a.pk > b.pk ? 1 : -1))
        );
      } else if (tab_index === 1 && team) {
        let {
          athletes,
          pending_athletes,
          permissions: { admin, edit },
        } = team;
        let mappedAthletes,
          mappedPendingAthletes = [];
        if (pending_athletes) {
          if (pending_athletes.length > 0) {
            mappedPendingAthletes = pending_athletes.map((source, index) => ({
              key: `pending-athlete-${index}`,
              name: `-@${source.email.charAt(0)}`,
              email: `${source.email}/-`,
              member_since: `${source.created}@-`,
              status: `${source.status}@-`,
              deletePK: admin ? `pending=${source.email}` : false,
            }));
          }
        }
        mappedAthletes = athletes.map((source, index) => ({
          id: source.pk,
          key: `athlete-${index}`,
          name: `${source.profile_img_url}@${source.name}@${source.pk}`,
          email: `${source.email}/${source.pk}`,
          member_since: `${source.member_since}@${source.pk}`,
          status: `${source.status}@${source.pk}`,
          deletePK: edit ? `athlete=${source.pk}` : false,
        }));
        mergedList = mappedAthletes.concat(mappedPendingAthletes);
        setSearchedDataSource(
          mergedList.sort((a, b) => (a.pk > b.pk ? 1 : -1))
        );
      } else if (tab_index === 2 && team) {
        let {
          coaches,
          pending_coaches,
          permissions: { admin },
        } = team;
        let mappedCoaches,
          mappedPendingCoaches = [];
        if (pending_coaches) {
          if (pending_coaches.length > 0) {
            mappedPendingCoaches = pending_coaches.map((source, index) => ({
              key: `pending-coach-${index}`,
              name: `-@${source.email.charAt(0)}`,
              email: `${source.email}`,
              coach_type: `-`,
              member_since: `${source.created}`,
              status: `${source.status}`,
              deletePK: admin ? `${source.email}/${source.status}` : "-",
            }));
          }
        }
        mappedCoaches = coaches.map((source, index) => ({
          key: `coach-${index}`,
          name: `${source.profile_img_url}@${source.name}`,
          email: `${source.email}`,
          coach_type: `${source.coach_type}`,
          member_since: `${source.member_since}`,
          status: `${source.status}`,
          deletePK: `${source.pk}@${source.status}`,
        }));
        mergedList = mappedCoaches.concat(mappedPendingCoaches);
        setSearchedDataSource(
          mergedList.sort((a, b) => (a.pk > b.pk ? 1 : -1))
        );
      }
      setShowScreen(true);
    }
  }, [team_list, tab_index, team]);

  useEffect(() => {
    setLoading(true);
    getManagementTeam(
      Number(window.localStorage.getItem("team_Id")),
      onSuccessWithData,
      onSuccessWithoutData,
      onError
    );
    setTabIndex(
      Number(
        JSON.parse(window.localStorage.getItem("location"))["management_tab"]
      )
    );
  }, []);

  function getSearchStatus() {
    if (team && isEqual(team, { name: "", logo_url: "", code: "" })) {
      return false;
    } else if (tab_index === 0 && team_list) {
      if (team_list.length) {
        return true;
      }
    } else if (tab_index === 1 && team) {
      let { athletes, pending_athletes } = team;
      const athletes_list = athletes.concat(pending_athletes);
      if (athletes_list && athletes_list.length) {
        return true;
      } else {
        return false;
      }
    } else if (tab_index === 2 && team) {
      let { coaches, pending_coaches } = team;
      let coaches_list = coaches.concat(pending_coaches);
      if (coaches_list && coaches_list.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  if (!isSubscriptionActive) {
    return <MissingSubscriptionPage />;
  }

  return show_screen ? (
    <div className="flex column w-100 h-100">
      {/* Header + Tabs */}
      <div
        className="flex column justifyStart alignCenter"
        style={{
          pointerEvents: team ? null : "none",
          position: "fixed",
          top: 0,
          background: "rgba(1, 33, 58, 0.7)",
          backdropFilter: "blur(25px)",
          padding: "40px 32px 0px",
          width: "calc(100vw - 140px)",
        }}
      >
        {/* Header */}
        <div className="flex justifyBetween alignCenter w-100">
          {/* Team selector */}
          {tab_index === 0 ? (
            <div
              className="flex alignCenter"
              style={{ minWidth: 224, height: 45 }}
            >
              <p style={{ fontSize: 20 }}> All Teams </p>
            </div>
          ) : (
            <TeamsSelector
              teams={team_list && team_list.length > 0 ? team_list : []}
              callAction={(team_Id) => {
                setLoading(true);
                setSearchActive(false);
                setShowCross(false);
                setSearchText("");
                setSearchedDataSource([]);
                dispatch(setManagementTeam(null));
                dispatch(fetchManagementTeam(team_Id, onSuccess, onError));
              }}
            />
          )}

          {/* Breadcrumb */}
          <div className="flex alignCenter">
            <p>Home</p>
            <img
              src={ArrowRight}
              alt="arrow right"
              height={12}
              width={12}
              style={{ margin: "0 4px" }}
            />
            <p>Teams</p>
            {window.localStorage.getItem("team_name") && (
              <img
                src={ArrowRight}
                alt="arrow right"
                height={12}
                width={12}
                style={{ margin: "0 4px" }}
              />
            )}
            <p>{window.localStorage.getItem("team_name")}</p>
          </div>
        </div>

        {/* Tabs + Invites */}
        <div className="management-tab-section">
          {/* Management Tabs */}
          <ManagementTabs
            activeIndex={tab_index}
            tabs={
              team && team["permissions"] && team["permissions"]["admin"]
                ? ["All Teams", "Athletes", "Coaches", "Settings"]
                : ["All Teams", "Athletes", "Coaches"]
            }
            callAction={(tab_index) => {
              setTabIndex(tab_index);
              window.localStorage.setItem(
                "location",
                JSON.stringify({
                  management_tab: tab_index,
                })
              );
              if (tab_index !== 3) {
                setSearchActive(false);
                setShowCross(false);
                setSearchText("");
              }
            }}
          />

          {/* Invite Tabs */}
          {tab_index === 0 ? (
            <div className="flex alignCenter h-100">
              {/* Search bar */}
              <SearchCard
                searchFor="teams"
                setSearch={(status) => setSearchActive(status)}
                searchedData={(source) => setSearchedDataSource(source)}
                dataSource={team}
                teamsSource={team_list}
                searchedText={searchedText}
                setText={(text) => setSearchText(text)}
                status={getSearchStatus()}
                showCross={showCross}
                setCrossStatus={(status) => setShowCross(status)}
                showLoader={showLoader}
                setLoaderStatus={(flag) => setLoader(flag)}
                is_margin={false}
              />
              {/* Add Athletes/Coaches Tab */}
              <button
                className="white-button"
                style={{ minWidth: 180, maxWidth: 180, marginLeft: 16 }}
                onClick={() => history.push(`/dashboard/invite?createTeam`)}
              >
                <p style={{ margin: 0 }}>Create Team</p>
              </button>
            </div>
          ) : (
            <></>
          )}
          {team &&
          team["permissions"] &&
          team["permissions"]["admin"] &&
          (tab_index === 1 || tab_index === 2) ? (
            <div className="flex alignCenter h-100">
              {/* Search bar */}
              <SearchCard
                searchFor={
                  tab_index === 1
                    ? "athletes"
                    : tab_index === 2
                    ? "coaches"
                    : ""
                }
                setSearch={(status) => setSearchActive(status)}
                searchedData={(source) => setSearchedDataSource(source)}
                dataSource={team}
                teamsSource={team_list}
                searchedText={searchedText}
                setText={(text) => setSearchText(text)}
                status={getSearchStatus()}
                showCross={showCross}
                setCrossStatus={(status) => setShowCross(status)}
                showLoader={showLoader}
                setLoaderStatus={(flag) => setLoader(flag)}
                is_margin={false}
              />
              {/* Add Athletes/Coaches Tab */}
              <button
                className="white-button"
                style={{ minWidth: 180, maxWidth: 180, marginLeft: 16 }}
                onClick={() =>
                  history.push(
                    `/dashboard/invite?${
                      tab_index === 1 ? "athletes" : "coaches"
                    }`
                  )
                }
              >
                <p style={{ margin: 0 }}>
                  {tab_index === 1
                    ? " Add Athletes"
                    : tab_index === 2
                    ? " Add Coaches"
                    : null}
                </p>
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* Content Area */}
      <div className="w-100" style={{ padding: "0 32px 64px", marginTop: 160 }}>
        {/* Table Loader */}
        {loading && (tab_index === 0 || tab_index === 1 || tab_index === 2) ? (
          <div style={{ marginTop: 56 }}>
            <LoadingTable
              screenName={
                tab_index === 0
                  ? "teams"
                  : tab_index === 1
                  ? "management-athletes"
                  : "management-coaches"
              }
            />
          </div>
        ) : null}
        {/* Settings Loader */}
        {loading && tab_index === 3 ? (
          <div
            className="flex column justifyCenter alignCenter w-100"
            style={{ height: "calc(100vh - 220px)" }}
          >
            <div className="custom_loader" />
            <p style={{ marginTop: 32 }}>{`Please wait a moment`}</p>
          </div>
        ) : null}

        {/* Management Table */}
        {team &&
        !loading &&
        (tab_index === 0 || tab_index === 1 || tab_index === 2) ? (
          <ManagementTable
            source={searchedDataSource}
            tab_index={tab_index}
            deleteData={(pk) => onDeleteMember(pk)}
            setSortedRes={(res) => setSearchedDataSource(res)}
            deleteInvitedMember={(email_address) =>
              onDeleteInvitedMember(email_address)
            }
            permissions={team.permissions}
            searchStatus={isSearchActive}
            searchedText={searchedText}
            loadingState={loading}
            tabAction={(teamId, teamName, tabname) => {
              setLoading(true);
              setTabIndex(3);
              window.localStorage.setItem(
                "location",
                JSON.stringify({
                  management_tab: 3,
                })
              );
              setSettingsTab({ show: true, tab_name: tabname });
              window.localStorage.setItem("team_Id", teamId);
              window.localStorage.setItem("team_name", teamName);
              setSearchActive(false);
              setShowCross(false);
              setSearchText("");
              setSearchedDataSource([]);
              dispatch(setManagementTeam(null));
              dispatch(fetchManagementTeam(teamId, onSuccess, onError));
            }}
          />
        ) : null}

        {/* Settings Tab */}
        {team && !loading && tab_index === 3 ? (
          <div
            className="flex column alignCenter justifyCenter w-100"
            style={{
              height: settings_tab.show ? "100%" : "calc(100vh - 220px)",
              marginTop: settings_tab.show ? 56 : 0,
            }}
          >
            <div className="flex alignCenter justifyCenter w-100">
              <div
                onClick={() => setSettingsTab({ show: true, tab_name: "edit" })}
                className="flex column alignCenter justifyCenter pointer"
                style={{
                  width: 300,
                  height: 200,
                  background:
                    settings_tab.tab_name === "edit"
                      ? "none"
                      : "rgba(255, 255, 255, 0.15)",
                  borderRadius: 12,
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                  border:
                    settings_tab.tab_name === "edit"
                      ? "2px solid #08AFA8"
                      : null,
                }}
              >
                <img
                  src={
                    settings_tab.tab_name === "edit" ? EditActiveIcon : EditIcon
                  }
                  alt="edit icon"
                  height={100}
                  width={100}
                />
                <br />
                <p
                  style={{
                    textTransform: "uppercase",
                    letterSpacing: 1.25,
                    wordSpacing: 2,
                    fontWeight: 600,
                    color:
                      settings_tab.tab_name === "edit" ? "#08AFA8" : "#FFFFFF",
                  }}
                >
                  Edit Team
                </p>
              </div>
              <div
                onClick={() =>
                  setSettingsTab({ show: true, tab_name: "transfer" })
                }
                className="flex column alignCenter justifyCenter pointer"
                style={{
                  width: 300,
                  height: 200,
                  margin: "0 16px",
                  background:
                    settings_tab.tab_name === "transfer"
                      ? "none"
                      : "rgba(255, 255, 255, 0.15)",
                  borderRadius: 12,
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                  border:
                    settings_tab.tab_name === "transfer"
                      ? "2px solid #08AFA8"
                      : null,
                }}
              >
                <img
                  src={
                    settings_tab.tab_name === "transfer"
                      ? TransferActiveIcon
                      : TransferIcon
                  }
                  alt="edit icon"
                  height={100}
                  width={100}
                />
                <br />
                <p
                  style={{
                    textTransform: "uppercase",
                    letterSpacing: 1.25,
                    wordSpacing: 2,
                    fontWeight: 600,
                    color:
                      settings_tab.tab_name === "transfer"
                        ? "#08AFA8"
                        : "#FFFFFF",
                  }}
                >
                  Transfer Ownership
                </p>
              </div>
              <div
                onClick={() =>
                  setSettingsTab({ show: true, tab_name: "delete" })
                }
                className="flex column alignCenter justifyCenter pointer"
                style={{
                  width: 300,
                  height: 200,
                  background:
                    settings_tab.tab_name === "delete"
                      ? "none"
                      : "rgba(255, 255, 255, 0.15)",
                  borderRadius: 12,
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                  border:
                    settings_tab.tab_name === "delete"
                      ? "2px solid #E8494E"
                      : null,
                }}
              >
                <img
                  src={
                    settings_tab.tab_name === "delete" ? BinActiveIcon : BinIcon
                  }
                  alt="edit icon"
                  height={100}
                  width={100}
                />
                <br />
                <p
                  style={{
                    textTransform: "uppercase",
                    letterSpacing: 1.25,
                    wordSpacing: 2,
                    fontWeight: 600,
                    color:
                      settings_tab.tab_name === "delete"
                        ? "#E8494E"
                        : "#FFFFFF",
                  }}
                >
                  Delete Team
                </p>
              </div>
            </div>
            {settings_tab.show && (
              <UpdateTeam tabName={settings_tab["tab_name"]} />
            )}
          </div>
        ) : null}

        {/* Show see more coaches message when there is only one coach in the coaches */}
        {team &&
        team.coaches &&
        team.coaches.length === 1 &&
        tab_index === 2 ? (
          <div
            className="flex justifyCenter alignCenter w-100"
            style={{ marginTop: 66 }}
          >
            <div
              className="flex column justifyCenter alignCenter"
              style={{ maxWidth: 425, margin: "auto" }}
            >
              <h3
                style={{
                  fontSize: 22,
                  margin: 0,
                  lineHeight: "160%",
                  fontWeight: 700,
                  color: "inherit",
                }}
              >
                Why can’t I see more coaches?
              </h3>
              <p className="main-decription" style={{ marginTop: 16 }}>
                You can currently only view coaches in your own team.
              </p>
            </div>
          </div>
        ) : null}

        {/* Open invite modal for athletes / coaches if permissions for admin is true */}
        {/* {(team && team['coaches'].length === 0 && team['pending_coaches'].length === 0 &&
          team['permissions'] && team['permissions']['admin']) ?
          <div className="no_record_found" style={{ margin: "72px auto 0" }}>
            <img src={AthletesPoster} alt="athletes-card" height={440} width={440} />
            <div className="flex column alignCenter justifyCenter">
              <h1>{tab_index === 1 ? 'Invite Athletes' : 'Invite Coaches'}</h1>
              <p>
                {team['permissions']['admin']
                  ? `You haven’t got any ${tab_index === 1 ? 'athletes' : 'coaches'} in your team yet. Start inviting them now.`
                  : `You haven’t got any ${tab_index === 1 ? 'athletes' : 'coaches'} in your team yet. Start inviting them now. Only the owner of the team can add ${tab_index === 1 ? 'athletes' : 'coaches'}`
                }
              </p>
              <button className="white-button" style={{ maxWidth: 230 }} onClick={() => history.push(tab_index === 1 ? "/dashboard/invite?athletes" : "/dashboard/invite?coaches")}>
                {tab_index === 1 ? 'Add Athletes' : 'Add Coaches'}
              </button>
            </div>
          </div>
          : null} */}

        {/* Create Team Form */}
        {createTeamForm && <CreateTeamManagement />}
      </div>
    </div>
  ) : (
    <div
      className="flex column justifyCenter alignCenter"
      style={{ margin: "auto" }}
    >
      <h2 style={{ textAlign: "center", color: "inherit", width: 576 }}>
        Access Denied
      </h2>
      <br />
      <p
        style={{
          fontSize: 16,
          textAlign: "center",
          color: "inherit",
          width: 576,
        }}
      >
        You do not have the necessary permissions to view this screen. Please
        contact our support team at{" "}
        <a
          href="mailto:support@wild.ai"
          target="_blank"
          rel="noreferrer"
          style={{ color: "#03A9F4" }}
        >
          support@wild.ai
        </a>{" "}
        for assistance or reach out to your system administrator for the
        appropriate access rights. Thank you for your understanding.
      </p>
    </div>
  );
};

export default Management;
