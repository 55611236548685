import React from "react";
// Icons
import AlertIcon from "../assets/icons/alert-dark.svg";

const Support = (props) => (
  <div className="flex column justifyCenter alignCenter alert_container">
    <div className="flex column justifyCenter alignCenter w-100">
      <img src={AlertIcon} alt="alert-icon" height={60} width={60} />
      <p className="title">
        Thanks for signing up
      </p>
      <p className="description">
        Check your email to activate your account, instructions on how to join
        and onboard your team. If you don’t receive this email in a few minutes,
        please email
        <span>
          <a href="mailto:support@wild.ai" target="_blank" rel="noreferrer">  support@wild.ai </a>
        </span>
      </p>
      {/* <div className="black-button pointer" style={{ maxWidth: '80%', height: 56 }}
        onClick={() => props.history.push("/login")}>
         Go Back
      </div> */}
    </div>
  </div>
);

export default Support;
