import React, { useState } from "react";
import "./PricingPopup.css";
import Slider from "./Slider";
import MinusIcon from "../../assets/icons/minus.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createNotification } from "../../store/app/actions";
import api from "../../services/api/ApiService";

const PricingPopup = ({
  show,
  hide,
  initialAthletesNumber,
  initialCoachesNumber,
  initialBetaSeatsNumber,
  showContactFormPopup,
  subscriptionId,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const onError = (error) =>
    dispatch(createNotification("error-toast", error, 15000));

  const [isCoachSubscribed, setIsCoachSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coachSeats, setCoachSeats] = useState(0);
  const [athleteSeats, setAthleteSeats] = useState(0);
  const [pricePerAthlete, setPricePerAthlete] = useState(4);

  useEffect(() => {
    setAthleteSeats(initialAthletesNumber);
    setCoachSeats(initialCoachesNumber);
    if (
      initialAthletesNumber ||
      initialCoachesNumber ||
      initialBetaSeatsNumber
    ) {
      setIsCoachSubscribed(true);
    }
  }, [initialAthletesNumber, initialCoachesNumber, initialBetaSeatsNumber]);

  const addSubscription = async () => {
    setLoading(true);
    if (athleteSeats) {
      try {
        const {
          data: { checkout_session_url },
        } = await api.get(
          `coach/subscription/checkout?athlete_seats=${athleteSeats}&coach_seats=${coachSeats}`
        );
        window.open(checkout_session_url);
        setLoading(false);
        hide();
      } catch (err) {
        if (err.response && err.response.status === 404) {
          onError(
            "This route does not exist. please contact with support@wild.ai"
          );
          setLoading(false);
        } else {
          onError("Something went wrong. Please contact with support@wild.ai");
          setLoading(false);
        }
      }
    } else {
      onError("You should add at least one athlete.");
      setLoading(false);
    }
  };

  const updateSubscriptionDetails = async () => {
    setLoading(true);
    if (
      athleteSeats &&
      (coachSeats !== initialCoachesNumber ||
        athleteSeats !== initialAthletesNumber)
    ) {
      try {
        const seats = { coach_seats: coachSeats, athlete_seats: athleteSeats };
        if (seats.coach_seats === 0) {
          delete seats["coach_seats"];
        }
        if (seats.athlete_seats === 0) {
          delete seats["athlete_seats"];
        }
        const { data } = await api.patch(
          `coach/subscriptions/${subscriptionId}`,
          seats
        );
        if (data) {
          dispatch(
            createNotification(
              "success-toast",
              "You have updated your subscription successfully."
            )
          );
          setLoading(false);
          onSuccess();
          hide();
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          onError(
            "This route does not exist. please contact with support@wild.ai"
          );
        } else {
          if (err.response) {
            const { data } = err.response;
            onError(data.details || data[0]);
            setLoading(false);
          }
        }
      }
    } else {
      onError("You should make at least one change to the athlete or coaches.");
      setLoading(false);
    }
  };

  if (!show) {
    return <></>;
  }
  return (
    <div className="pricing-popup" onClick={(e) => e.stopPropagation()}>
      <span style={{ fontSize: "28px", marginBottom: "58px" }}>
        Calculate your pricing
      </span>
      <Slider value={athleteSeats} setValue={setAthleteSeats} />
      <div
        className="flex row"
        style={{ gap: "15px", alignSelf: "flex-start" }}
      >
        <div className="number-box">{athleteSeats}</div>
        <div className="flex column">
          <span style={{ fontSize: "18px" }}>Athletes</span>
          <span style={{ fontSize: "14px" }}>
            £{pricePerAthlete}.00 per seat
          </span>
        </div>
      </div>
      <div
        className="flex row justifyBetween coaches-container"
        style={{ gap: "15px", alignSelf: "flex-start" }}
      >
        <div className="flex column">
          <span style={{ fontSize: "18px" }}>Additional Coaches</span>
          <span style={{ fontSize: "14px" }}>
            £4.00 per seat (first seat is already included)
          </span>
        </div>
        <div className="flex alignCenter" style={{ gap: "16px" }}>
          <img
            src={MinusIcon}
            alt="minus-icon"
            height={32}
            width={32}
            className="pointer minus-icon"
            onClick={() => {
              if (coachSeats !== 0) {
                setCoachSeats(coachSeats - 1);
              }
            }}
          />
          <div className="number-box">{coachSeats}</div>
          <img
            src={PlusIcon}
            alt="plus-icon"
            height={32}
            width={32}
            className="pointer plus-icon"
            onClick={() => setCoachSeats(coachSeats + 1)}
          />
        </div>
      </div>
      <div style={{ gap: "16px", alignSelf: "flex-end" }}>
        <span style={{ fontSize: "20px" }}>
          Total: £{athleteSeats * pricePerAthlete + coachSeats * 4}.00
        </span>
      </div>
      <div className="content-divider" style={{ width: "100%" }} />
      {athleteSeats !== 40 && (
        <div
          className="white-button"
          style={{
            height: 48,
            width: "50%",
            alignSelf: "flex-end",
            border: "none",
            backgroundColor: "#75fb8d",
            fontWeight: 700,
            pointerEvents: loading ? "none" : "auto",
          }}
          onClick={() => {
            if (isCoachSubscribed) {
              updateSubscriptionDetails();
            } else {
              addSubscription();
            }
          }}
        >
          {loading ? (
            <div
              className="custom_loader"
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            "FINALIZE"
          )}
        </div>
      )}
      <div className="flex column alignCenter inside-container">
        <span style={{ fontSize: "27px" }}>More athletes?</span>
        <span style={{ fontSize: "16px" }}>
          Let’s talk about a plan tailored to you and your team
        </span>
        <div
          className="white-button"
          style={{
            height: 38,
            marginTop: 24,
            width: "35%",
            border: "none",
            fontWeight: 600,
          }}
          onClick={() => {
            showContactFormPopup();
            hide();
          }}
        >
          GET IN TOUCH
        </div>
      </div>
    </div>
  );
};

export default PricingPopup;
